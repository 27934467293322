import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK= theme.palette.primary.dark;
 
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080 1080">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
		<g viewBox="0 0 980.000000 980.000000" >

 
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M1.000000,282.000000 
	C1.000000,188.010788 1.000000,94.521576 1.000000,1.016184 
	C360.947662,1.016184 720.895325,1.016184 1080.921509,1.016184 
	C1080.921509,360.906128 1080.921509,720.812256 1080.921509,1080.859131 
	C721.000061,1080.859131 361.000031,1080.859131 1.000000,1080.859131 
	C1.000000,814.770813 1.000000,548.635437 1.000000,282.000000 
M737.856201,993.004883 
	C744.518799,993.391907 749.031311,989.709473 753.834351,985.003601 
	C755.472656,984.839783 757.285339,985.072571 758.718750,984.442566 
	C764.497437,981.902649 769.354858,977.909546 775.763000,976.028992 
	C782.019409,974.192871 787.310669,969.133606 793.092224,965.598267 
	C801.646118,960.367798 810.318848,955.329590 818.821228,950.017822 
	C823.027405,947.390076 827.560242,944.883545 830.859192,941.317505 
	C834.196167,937.710388 838.403748,935.694580 842.034729,932.748901 
	C843.769958,931.341248 844.706604,928.949219 846.000000,927.000000 
	C846.000000,927.000000 846.004883,927.004883 846.803345,927.003052 
	C854.711792,925.553345 858.156738,918.207642 863.958923,913.974426 
	C864.123596,913.854370 863.999329,913.338257 864.504883,913.005005 
	C864.504883,913.005005 865.000000,912.997559 865.707825,912.929993 
	C869.711487,909.972534 874.036865,907.357361 877.638062,903.971863 
	C882.229431,899.655640 886.220032,894.702637 890.497131,890.049377 
	C898.285156,881.576599 906.104675,873.132812 913.888245,864.656006 
	C917.418335,860.811584 920.902405,856.924683 924.386230,853.038147 
	C924.932800,852.428467 925.373291,851.722412 925.851807,851.053040 
	C928.666626,847.114990 931.496826,843.187866 934.282898,839.229614 
	C935.709534,837.202698 936.941528,835.030396 938.459595,833.077881 
	C942.418701,827.985535 946.730835,823.150330 950.444763,817.889648 
	C954.334167,812.380371 957.671387,806.482666 961.287476,800.778503 
	C963.751892,796.891052 966.596497,793.208374 968.708008,789.141785 
	C971.998901,782.804077 974.632080,776.126831 977.866150,769.757080 
	C983.954224,757.766235 990.768616,746.117554 996.321045,733.890686 
	C1000.075134,725.623840 1002.173340,716.606689 1005.053284,707.938843 
	C1005.352722,707.037476 1005.996216,706.256042 1006.367126,705.371704 
	C1007.279724,703.195496 1008.249878,701.030701 1008.959229,698.786255 
	C1009.493164,697.096985 1009.473816,695.231567 1010.018250,693.547180 
	C1013.617859,682.410767 1017.877319,671.450317 1020.795715,660.141846 
	C1023.586731,649.327209 1025.015991,638.160767 1027.035278,627.147400 
	C1027.703491,623.503174 1028.209229,619.822815 1029.036255,616.215576 
	C1029.968506,612.149048 1031.185669,608.148071 1032.263916,604.114563 
	C1032.470093,603.343628 1033.021240,602.274292 1032.711914,601.817017 
	C1029.751221,597.439941 1032.896362,593.092346 1032.713257,588.730286 
	C1032.431763,582.020386 1032.923828,575.277466 1033.104126,568.549011 
	C1033.157227,566.566040 1033.107788,564.562378 1033.384277,562.607605 
	C1033.791748,559.726624 1034.876831,556.889221 1034.932129,554.018982 
	C1035.367676,531.434021 1035.144775,508.864655 1031.967041,486.437286 
	C1031.147095,480.649872 1030.171387,474.882477 1029.156738,469.125305 
	C1028.221924,463.821350 1026.985596,458.567383 1026.158569,453.248383 
	C1025.030762,445.994690 1024.258301,438.685883 1023.143311,431.429962 
	C1022.674072,428.376984 1021.806458,425.373993 1020.965881,422.391663 
	C1019.132263,415.885254 1017.187195,409.410339 1015.314087,402.914948 
	C1014.508972,400.123260 1013.820557,397.297394 1012.989014,394.513947 
	C1011.361694,389.066620 1010.837158,382.809204 1007.710999,378.412292 
	C1003.824158,372.945465 1004.201660,366.512299 1001.241455,360.913269 
	C997.408508,353.663361 995.324158,345.510315 992.092224,337.914001 
	C989.859741,332.666656 986.392700,327.903778 984.504578,322.561340 
	C982.301636,316.328125 975.642273,312.382629 975.880676,305.008026 
	C975.901917,304.350525 974.972351,303.671234 974.505737,302.987457 
	C972.674805,300.304016 969.578613,297.741089 969.296875,294.900879 
	C968.741455,289.301056 965.221008,285.628540 962.572021,281.414490 
	C958.754639,275.341949 954.542419,269.506744 950.300842,263.715149 
	C946.931458,259.114410 943.215820,254.768646 939.759216,250.229462 
	C938.641846,248.762054 937.957520,246.971710 936.903259,245.447968 
	C934.329285,241.727966 931.833557,237.931183 928.973022,234.438263 
	C925.858032,230.634644 921.323914,227.645752 919.261536,223.401291 
	C915.234985,215.114120 908.329651,209.715378 902.158997,203.510544 
	C896.556824,197.877319 890.981506,192.214142 885.240234,186.725372 
	C884.214539,185.744751 882.437500,185.550018 881.004883,184.126373 
	C881.004883,181.994141 881.004883,179.861923 881.004883,178.193115 
	C878.313904,177.472046 875.697021,177.481216 874.100952,176.209305 
	C869.407410,172.468903 865.128601,168.209427 860.669495,164.173004 
	C855.427612,159.428024 850.458557,154.318695 844.842407,150.065536 
	C837.365906,144.403427 829.341736,139.469009 821.628357,134.113144 
	C817.992188,131.588287 814.638672,128.650772 810.963379,126.189445 
	C807.024963,123.551880 802.983337,121.007484 798.757996,118.876045 
	C794.314758,116.634705 789.600647,114.930519 784.900635,112.308388 
	C779.712708,108.955681 774.722229,105.231369 769.287354,102.343552 
	C762.372986,98.669670 755.074707,95.723679 747.987183,92.368477 
	C742.545532,89.792419 737.205200,87.001610 731.754028,84.446762 
	C729.823059,83.541740 727.697876,83.050545 725.660767,82.372414 
	C719.807007,80.423828 713.945496,78.498199 708.105774,76.508446 
	C707.028748,76.141479 706.077271,75.411491 705.003784,75.028305 
	C699.288452,72.988091 693.574585,70.939354 687.814148,69.032501 
	C685.322266,68.207626 682.678955,67.844612 680.176453,67.046135 
	C675.245728,65.472855 670.471741,63.243725 665.444092,62.169781 
	C650.323303,58.939854 635.125854,56.059586 619.928711,53.200569 
	C613.798645,52.047337 607.616455,51.051628 601.413208,50.436989 
	C593.784119,49.681076 586.103088,49.466335 578.453979,48.891491 
	C571.203430,48.346596 563.974304,47.267693 556.719238,47.085758 
	C544.565674,46.780987 532.390686,46.728458 520.240356,47.092316 
	C511.123657,47.365334 502.016296,48.309410 492.933411,49.235519 
	C486.129547,49.929264 479.387939,51.222763 472.587677,51.966019 
	C466.586304,52.621952 460.421143,52.284256 454.545227,53.464901 
	C445.834900,55.215061 437.330963,57.976238 428.711090,60.198086 
	C422.116913,61.897785 415.466553,63.378525 408.864471,65.048683 
	C401.848877,66.823456 394.695404,68.239166 387.896179,70.643234 
	C375.084320,75.173248 362.463013,80.248070 349.813171,85.225220 
	C347.434143,86.161255 345.386566,87.926353 343.020386,88.910789 
	C337.480286,91.215736 331.607880,92.823853 326.287048,95.533112 
	C314.375641,101.598129 302.633087,108.011154 290.989380,114.579521 
	C283.801453,118.634315 276.880219,123.170418 269.918060,127.613968 
	C265.064667,130.711639 260.336090,134.005692 255.568192,137.236328 
	C251.283081,140.139832 249.086044,146.488358 242.253708,145.290726 
	C241.953079,145.238037 241.516266,146.600449 240.980637,147.143692 
	C238.139786,150.024994 235.437866,153.086990 232.326797,155.647766 
	C227.843552,159.337967 222.854706,162.423126 218.449234,166.195770 
	C214.769089,169.347305 211.720520,173.223541 208.208954,176.587494 
	C201.168228,183.332230 193.947281,189.889511 186.946945,196.675018 
	C185.892471,197.697128 185.626450,199.532669 184.262802,201.003769 
	C180.300781,202.267258 177.498734,204.585846 176.258560,209.004211 
	C171.740311,212.966660 166.575806,216.402649 162.833115,220.996902 
	C153.988968,231.853271 145.567169,243.079971 137.448456,254.493973 
	C128.654419,266.857391 119.776680,279.175873 113.407692,293.123901 
	C109.684799,301.277008 104.837547,308.911743 100.623520,316.847351 
	C97.888191,321.998322 95.258553,327.213806 92.806915,332.504822 
	C90.048950,338.456909 87.456612,344.490173 84.951897,350.554047 
	C81.875259,358.002533 78.906990,365.497253 75.997887,373.013031 
	C73.369446,379.803711 70.451019,386.524994 68.441658,393.501862 
	C65.771095,402.774597 63.770397,412.245789 61.651009,421.669739 
	C60.253216,427.885162 59.157837,434.168427 57.917377,440.419403 
	C57.376232,443.146423 57.664585,447.406097 56.084576,448.283478 
	C52.426037,450.315033 53.100685,453.050110 53.007851,455.918549 
	C52.860241,460.479584 53.041531,465.071869 52.570030,469.597443 
	C51.639610,478.527893 50.206074,487.405945 49.279804,496.336700 
	C48.761227,501.336670 49.110237,506.427277 48.579025,511.425049 
	C48.028084,516.608459 46.067398,521.742249 46.150417,526.874695 
	C46.445782,545.135132 47.237587,563.390930 48.093788,581.636658 
	C48.332932,586.732910 49.652905,591.770325 50.088295,596.867920 
	C50.477882,601.429199 49.355587,606.336914 50.653259,610.552124 
	C52.557880,616.738953 53.806141,622.747803 53.039730,629.170837 
	C52.946411,629.952881 53.028046,631.273010 53.482029,631.524048 
	C58.649921,634.382446 56.639381,639.790039 57.932068,643.973694 
	C58.628578,646.227905 58.093365,648.844116 58.675896,651.155396 
	C60.960899,660.221436 63.381737,669.256042 65.926399,678.252991 
	C67.572655,684.073364 69.482864,689.820679 71.346985,695.577393 
	C72.844147,700.200867 74.620049,704.740173 75.965584,709.405212 
	C77.767509,715.652710 82.179787,721.097412 81.715134,728.039917 
	C81.706802,728.164551 81.925507,728.291504 82.001877,728.436584 
	C85.912552,735.863708 89.947594,743.229004 93.686546,750.741760 
	C96.786400,756.970337 99.434898,763.422302 102.476494,769.681519 
	C104.640076,774.133850 106.937759,778.543640 109.504364,782.772888 
	C115.099831,791.993103 120.779648,801.167664 126.665123,810.204529 
	C131.235886,817.222717 136.115814,824.043030 140.988434,830.858276 
	C141.980606,832.246033 144.234848,832.980591 144.739120,834.424316 
	C146.785309,840.282593 149.743057,845.334839 154.548645,849.413452 
	C157.291641,851.741577 159.577209,854.641418 161.899933,857.424744 
	C166.306519,862.705200 170.269989,868.394958 175.011002,873.346313 
	C180.593689,879.176819 186.984695,884.225525 192.702530,889.936340 
	C200.553604,897.777893 207.903107,906.126831 215.853912,913.861145 
	C220.632599,918.509644 226.169098,922.375000 231.319077,926.647644 
	C233.525848,928.478516 235.349731,930.953430 237.812881,932.255554 
	C243.249268,935.129333 248.467529,938.019348 253.086655,942.310669 
	C257.545624,946.453186 263.083588,949.438293 268.166473,952.904053 
	C271.427521,955.127563 274.732666,957.286377 278.000153,959.500610 
	C281.131805,961.622742 284.122955,963.981201 287.385101,965.875488 
	C293.030853,969.153809 298.841736,972.147461 304.574646,975.276550 
	C305.537689,975.802124 306.407898,976.519775 307.405151,976.956665 
	C310.130615,978.150574 313.374390,978.679504 315.543121,980.505066 
	C318.832214,983.273743 321.806213,985.869690 326.480988,985.074280 
	C327.167114,984.957581 328.035187,985.462219 328.719604,985.853455 
	C335.032501,989.461731 341.106781,993.577454 347.680756,996.614014 
	C354.486359,999.757507 361.748291,1001.929260 368.858337,1004.388000 
	C371.169952,1005.187378 373.745728,1005.268127 376.002014,1006.172852 
	C381.202698,1008.258423 386.120850,1011.177063 391.446136,1012.794495 
	C400.341644,1015.496338 409.432526,1017.590210 418.502289,1019.674072 
	C429.715973,1022.250366 440.954803,1024.748657 452.251862,1026.921631 
	C463.087616,1029.005981 473.998993,1030.713867 484.906616,1032.396118 
	C487.427429,1032.784790 490.089539,1032.166870 492.650085,1032.405151 
	C500.875458,1033.170288 509.079102,1034.818237 517.299438,1034.872559 
	C547.296143,1035.070923 577.323669,1035.924072 607.061035,1030.134888 
	C621.447632,1027.334229 636.166260,1026.238037 650.551941,1023.433472 
	C659.583862,1021.672546 667.796082,1016.789673 677.422607,1016.407959 
	C681.992737,1016.226807 686.504028,1013.572510 690.945618,1011.797607 
	C692.145264,1011.318176 692.993103,1009.958252 694.866089,1009.003052 
	C698.520508,1008.192261 702.306152,1007.749207 705.804199,1006.500366 
	C715.575317,1003.011841 725.242371,999.228027 734.901062,995.434753 
	C735.784180,995.087891 736.312073,993.836365 737.856201,993.004883 
z"/>
<path fill="#F4F3F4" opacity="1.000000" stroke="none" 
	d="
M694.002441,1009.002441 
	C692.993103,1009.958252 692.145264,1011.318176 690.945618,1011.797607 
	C686.504028,1013.572510 681.992737,1016.226807 677.422607,1016.407959 
	C667.796082,1016.789673 659.583862,1021.672546 650.551941,1023.433472 
	C636.166260,1026.238037 621.447632,1027.334229 607.061035,1030.134888 
	C577.323669,1035.924072 547.296143,1035.070923 517.299438,1034.872559 
	C509.079102,1034.818237 500.875458,1033.170288 492.650085,1032.405151 
	C490.089539,1032.166870 487.427429,1032.784790 484.906616,1032.396118 
	C473.998993,1030.713867 463.087616,1029.005981 452.251862,1026.921631 
	C440.954803,1024.748657 429.715973,1022.250366 418.502289,1019.674072 
	C409.432526,1017.590210 400.341644,1015.496338 391.446136,1012.794495 
	C386.120850,1011.177063 381.202698,1008.258423 376.002014,1006.172852 
	C373.745728,1005.268127 371.169952,1005.187378 368.858337,1004.388000 
	C361.748291,1001.929260 354.486359,999.757507 347.680756,996.614014 
	C341.106781,993.577454 335.032501,989.461731 328.719604,985.853455 
	C328.035187,985.462219 327.167114,984.957581 326.480988,985.074280 
	C321.806213,985.869690 318.832214,983.273743 315.543121,980.505066 
	C313.374390,978.679504 310.130615,978.150574 307.405151,976.956665 
	C306.407898,976.519775 305.537689,975.802124 304.574646,975.276550 
	C298.841736,972.147461 293.030853,969.153809 287.385101,965.875488 
	C284.122955,963.981201 281.131805,961.622742 278.000153,959.500610 
	C274.732666,957.286377 271.427521,955.127563 268.166473,952.904053 
	C263.083588,949.438293 257.545624,946.453186 253.086655,942.310669 
	C248.467529,938.019348 243.249268,935.129333 237.812881,932.255554 
	C235.349731,930.953430 233.525848,928.478516 231.319077,926.647644 
	C226.169098,922.375000 220.632599,918.509644 215.853912,913.861145 
	C207.903107,906.126831 200.553604,897.777893 192.702530,889.936340 
	C186.984695,884.225525 180.593689,879.176819 175.011002,873.346313 
	C170.269989,868.394958 166.306519,862.705200 161.899933,857.424744 
	C159.577209,854.641418 157.291641,851.741577 154.548645,849.413452 
	C149.743057,845.334839 146.785309,840.282593 144.739120,834.424316 
	C144.234848,832.980591 141.980606,832.246033 140.988434,830.858276 
	C136.115814,824.043030 131.235886,817.222717 126.665123,810.204529 
	C120.779648,801.167664 115.099831,791.993103 109.504364,782.772888 
	C106.937759,778.543640 104.640076,774.133850 102.476494,769.681519 
	C99.434898,763.422302 96.786400,756.970337 93.686546,750.741760 
	C89.947594,743.229004 85.912552,735.863708 82.001877,728.436584 
	C81.925507,728.291504 81.706802,728.164551 81.715134,728.039917 
	C82.179787,721.097412 77.767509,715.652710 75.965584,709.405212 
	C74.620049,704.740173 72.844147,700.200867 71.346985,695.577393 
	C69.482864,689.820679 67.572655,684.073364 65.926399,678.252991 
	C63.381737,669.256042 60.960899,660.221436 58.675896,651.155396 
	C58.093365,648.844116 58.628578,646.227905 57.932068,643.973694 
	C56.639381,639.790039 58.649921,634.382446 53.482029,631.524048 
	C53.028046,631.273010 52.946411,629.952881 53.039730,629.170837 
	C53.806141,622.747803 52.557880,616.738953 50.653259,610.552124 
	C49.355587,606.336914 50.477882,601.429199 50.088295,596.867920 
	C49.652905,591.770325 48.332932,586.732910 48.093788,581.636658 
	C47.237587,563.390930 46.445782,545.135132 46.150417,526.874695 
	C46.067398,521.742249 48.028084,516.608459 48.579025,511.425049 
	C49.110237,506.427277 48.761227,501.336670 49.279804,496.336700 
	C50.206074,487.405945 51.639610,478.527893 52.570030,469.597443 
	C53.041531,465.071869 52.860241,460.479584 53.007851,455.918549 
	C53.100685,453.050110 52.426037,450.315033 56.084576,448.283478 
	C57.664585,447.406097 57.376232,443.146423 57.917377,440.419403 
	C59.157837,434.168427 60.253216,427.885162 61.651009,421.669739 
	C63.770397,412.245789 65.771095,402.774597 68.441658,393.501862 
	C70.451019,386.524994 73.369446,379.803711 75.997887,373.013031 
	C78.906990,365.497253 81.875259,358.002533 84.951897,350.554047 
	C87.456612,344.490173 90.048950,338.456909 92.806915,332.504822 
	C95.258553,327.213806 97.888191,321.998322 100.623520,316.847351 
	C104.837547,308.911743 109.684799,301.277008 113.407692,293.123901 
	C119.776680,279.175873 128.654419,266.857391 137.448456,254.493973 
	C145.567169,243.079971 153.988968,231.853271 162.833115,220.996902 
	C166.575806,216.402649 171.740311,212.966660 176.629211,209.002029 
	C176.999847,208.999847 177.001831,209.001831 177.001343,209.336349 
	C177.003189,210.117065 177.005508,210.563248 177.003906,211.004730 
	C177.000000,211.000000 177.009903,211.007431 176.657196,211.040741 
	C168.829330,219.754486 159.169540,226.706894 155.001343,238.002106 
	C155.000000,238.000000 155.004272,238.002609 154.658707,238.054504 
	C153.877609,239.073746 153.442078,240.041092 153.003265,241.004211 
	C153.000000,241.000000 153.008514,241.006119 152.685577,241.045319 
	C151.910965,241.728439 151.459320,242.372375 150.886536,243.298599 
	C150.177750,244.387665 149.590088,245.194443 148.656830,246.053833 
	C147.876709,247.075623 147.442169,248.044785 146.881012,249.288452 
	C146.171326,250.377487 145.588257,251.191986 144.666977,252.052063 
	C143.556030,253.401138 142.783325,254.704605 142.008575,256.256042 
	C142.006546,256.504028 142.000000,257.000000 141.643661,257.039337 
	C140.524689,258.385773 139.762039,259.692902 138.999695,261.000000 
	C139.000000,261.000000 138.999390,261.000000 138.647964,261.035400 
	C135.204437,265.718445 132.112335,270.366058 129.016052,275.260864 
	C129.011871,275.508087 129.001831,276.002441 128.650330,276.039246 
	C125.870644,280.054169 123.442436,284.032288 120.812897,288.289246 
	C116.741135,295.378693 112.870720,302.189362 108.731461,309.188080 
	C107.977211,310.585815 107.491791,311.795471 107.005089,313.253845 
	C107.003807,313.502563 107.000305,314.000000 106.679001,314.041687 
	C105.911331,314.726715 105.464966,315.370087 105.015541,316.261292 
	C105.012482,316.509155 105.004272,317.004883 104.648102,317.060699 
	C102.255585,320.706329 100.166649,324.268555 98.251762,327.922028 
	C98.021431,328.361450 98.734367,329.295319 98.987373,330.298309 
	C98.646042,331.068237 98.327446,331.539825 97.657440,332.045532 
	C94.919182,333.423859 93.014961,334.955475 95.003136,338.337952 
	C94.666954,339.451813 94.334999,340.227692 93.636017,341.046082 
	C92.518143,342.729706 91.767303,344.370789 90.871140,346.333374 
	C89.480507,350.433594 88.235191,354.212280 86.644585,358.046997 
	C85.216858,359.495819 83.769257,360.740051 83.129372,362.312897 
	C81.867630,365.414368 81.125145,368.722504 80.037651,371.900574 
	C78.421555,376.623474 76.692406,381.307678 75.008705,386.407288 
	C75.003555,387.870422 75.001068,388.934601 74.640312,390.063843 
	C73.859184,391.421692 73.436333,392.714478 73.007355,394.381165 
	C73.000580,395.503113 72.999924,396.251190 72.645561,397.059418 
	C71.860847,399.413055 71.429840,401.706512 70.996902,404.331390 
	C70.990685,405.104614 70.986397,405.546448 70.625656,406.050049 
	C65.067375,411.449951 66.709557,418.693878 65.003899,425.003326 
	C65.000000,425.000000 65.007294,425.007141 64.620377,425.087891 
	C62.498703,433.787445 60.763962,442.406281 59.027054,451.354187 
	C59.023434,452.122009 59.021969,452.560760 58.673729,453.189758 
	C57.224945,460.259644 56.122932,467.139252 55.020779,474.348755 
	C55.022327,475.118469 55.024017,475.558289 54.759186,476.204803 
	C54.663792,477.607666 54.834923,478.803833 55.005077,480.436890 
	C54.997299,482.912323 54.990498,484.950867 54.614555,487.065979 
	C53.496712,489.414062 52.231430,491.652740 52.084629,493.962494 
	C51.243874,507.190918 50.240372,520.432251 50.115623,533.676880 
	C49.970455,549.090027 50.613514,564.512878 51.054932,579.927490 
	C51.153889,583.383179 51.610584,586.871094 52.344067,590.248230 
	C52.712967,591.946777 54.072285,593.430115 54.986763,595.438477 
	C54.995529,597.577393 55.001259,599.288696 54.725204,601.249878 
	C56.088760,613.553223 57.610397,625.626343 59.505440,637.640442 
	C59.809082,639.565491 61.782867,641.227112 62.987427,643.437866 
	C62.998508,645.575378 63.006741,647.285461 62.734520,649.242188 
	C63.306843,651.988831 64.159622,654.488770 65.304016,657.220703 
	C65.974518,658.183777 66.485954,658.876282 66.712593,659.651917 
	C70.521225,672.687500 74.298737,685.732117 78.073334,698.777649 
	C79.732010,704.510132 81.368561,710.249146 83.008972,716.393860 
	C85.004082,720.866150 87.005371,724.929688 89.003326,728.996643 
	C89.000000,729.000000 89.006958,728.993835 89.003586,729.407104 
	C90.327248,733.907837 88.832710,739.503967 94.989792,741.341187 
	C95.001884,742.108765 95.008156,742.547424 95.008942,743.372375 
	C96.335129,745.839172 97.666809,747.919556 99.001488,750.371948 
	C99.011551,751.487732 99.018623,752.231628 99.021896,753.356323 
	C100.008385,755.901672 100.763382,758.220276 102.052917,760.188660 
	C103.436203,762.300171 105.322166,764.082336 106.994110,766.337463 
	C107.005264,767.105652 107.011086,767.544617 107.010536,768.366821 
	C108.333527,770.501953 109.662880,772.253845 110.996185,774.252441 
	C111.000145,774.499146 111.006805,774.992676 111.044022,775.314453 
	C111.717789,776.093018 112.354332,776.549744 112.995728,777.253662 
	C113.000587,777.500793 113.005402,777.995117 113.001625,778.395508 
	C112.901505,783.225891 114.984474,786.240662 118.988800,788.340698 
	C119.001373,789.108459 119.007774,789.547119 119.015823,790.354614 
	C120.344170,792.483093 121.670868,794.242798 122.998779,796.001221 
	C123.000000,796.000000 122.997902,796.002441 123.001945,796.375488 
	C125.673470,800.496277 128.340958,804.243958 131.004211,807.995850 
	C131.000000,808.000000 131.011047,807.991638 131.007263,808.384155 
	C130.776947,811.651062 131.942001,813.215576 134.993546,813.254944 
	C134.999268,813.500122 135.010193,813.990417 135.011322,814.345337 
	C135.674179,815.467102 136.335892,816.233887 136.998810,817.000366 
	C137.000000,817.000000 136.998779,817.002441 137.003143,817.356323 
	C137.670242,818.474243 138.332977,819.238342 139.214722,820.111206 
	C139.706955,820.419861 139.894379,820.681213 140.000946,821.378906 
	C143.735870,827.425293 147.430923,833.120239 151.209961,838.758850 
	C153.225784,841.766663 154.063660,846.099609 158.994537,846.366699 
	C159.664993,847.482361 160.327164,848.246033 160.995331,849.374878 
	C161.560379,850.885071 161.899963,852.223511 162.712296,853.145325 
	C170.936859,862.478455 179.161774,871.814148 187.572296,880.978699 
	C189.704193,883.301758 192.496124,885.018982 195.024292,887.364624 
	C202.906403,895.404480 210.457413,903.423950 218.672592,910.692749 
	C227.073288,918.125793 236.007874,924.977112 244.944931,931.772461 
	C249.029861,934.878479 253.681473,937.239197 258.311798,939.940918 
	C258.547516,939.943970 259.018311,939.969788 259.049194,940.338745 
	C259.687347,941.870178 259.995728,943.467224 260.950745,944.124023 
	C266.717316,948.090088 272.517487,952.032410 278.522797,955.620483 
	C288.332397,961.481506 298.265778,967.140259 308.242737,972.712830 
	C310.787354,974.134216 313.829590,974.653687 316.406555,976.030396 
	C325.465942,980.870178 334.279694,986.189697 343.469452,990.758301 
	C349.286194,993.649902 355.575073,995.614502 361.711639,997.830200 
	C364.781982,998.938782 368.014587,999.589417 371.122437,1000.604492 
	C377.444611,1002.669434 383.731903,1004.841248 390.446045,1006.973633 
	C392.237762,1006.987854 393.617004,1006.997620 395.051849,1007.358948 
	C397.016510,1008.847717 398.792267,1010.519775 400.856506,1011.034119 
	C413.267609,1014.126343 425.755463,1016.908752 438.196564,1019.882874 
	C447.377747,1022.077759 456.188629,1026.235718 466.068420,1025.371460 
	C468.429199,1026.485596 470.720093,1027.237915 473.477173,1027.991699 
	C478.961639,1027.998657 483.979919,1028.004272 489.067688,1028.371582 
	C493.094818,1029.485596 497.052521,1030.237915 501.478210,1030.991699 
	C528.294250,1030.992188 554.642212,1030.991211 581.359497,1030.989990 
	C583.159302,1030.952759 584.680481,1031.214478 586.000793,1030.813843 
	C588.397522,1030.087036 590.674744,1028.966187 593.466309,1028.007812 
	C598.283081,1028.000610 602.635437,1027.995361 607.399414,1027.985107 
	C610.874023,1026.986572 613.937012,1025.993286 617.333252,1025.000000 
	C618.110840,1025.000000 618.555115,1025.000000 619.352600,1024.995117 
	C620.471008,1024.332397 621.236206,1023.674805 622.375977,1023.011719 
	C623.499512,1023.008362 624.248535,1023.010254 625.066040,1023.373230 
	C626.409363,1023.954590 627.760864,1024.562256 628.945557,1024.327393 
	C633.846924,1023.356384 638.704773,1022.159851 643.565552,1020.991882 
	C653.728088,1018.549927 663.876343,1016.048401 674.042175,1013.620728 
	C680.683289,1012.034790 687.345032,1010.535645 693.998779,1008.998779 
	C694.000000,1009.000000 694.002441,1009.002441 694.002441,1009.002441 
z"/>
<path fill="#F4F3F4" opacity="1.000000" stroke="none" 
	d="
M881.007324,184.992432 
	C882.437500,185.550018 884.214539,185.744751 885.240234,186.725372 
	C890.981506,192.214142 896.556824,197.877319 902.158997,203.510544 
	C908.329651,209.715378 915.234985,215.114120 919.261536,223.401291 
	C921.323914,227.645752 925.858032,230.634644 928.973022,234.438263 
	C931.833557,237.931183 934.329285,241.727966 936.903259,245.447968 
	C937.957520,246.971710 938.641846,248.762054 939.759216,250.229462 
	C943.215820,254.768646 946.931458,259.114410 950.300842,263.715149 
	C954.542419,269.506744 958.754639,275.341949 962.572021,281.414490 
	C965.221008,285.628540 968.741455,289.301056 969.296875,294.900879 
	C969.578613,297.741089 972.674805,300.304016 974.505737,302.987457 
	C974.972351,303.671234 975.901917,304.350525 975.880676,305.008026 
	C975.642273,312.382629 982.301636,316.328125 984.504578,322.561340 
	C986.392700,327.903778 989.859741,332.666656 992.092224,337.914001 
	C995.324158,345.510315 997.408508,353.663361 1001.241455,360.913269 
	C1004.201660,366.512299 1003.824158,372.945465 1007.710999,378.412292 
	C1010.837158,382.809204 1011.361694,389.066620 1012.989014,394.513947 
	C1013.820557,397.297394 1014.508972,400.123260 1015.314087,402.914948 
	C1017.187195,409.410339 1019.132263,415.885254 1020.965881,422.391663 
	C1021.806458,425.373993 1022.674072,428.376984 1023.143311,431.429962 
	C1024.258301,438.685883 1025.030762,445.994690 1026.158569,453.248383 
	C1026.985596,458.567383 1028.221924,463.821350 1029.156738,469.125305 
	C1030.171387,474.882477 1031.147095,480.649872 1031.967041,486.437286 
	C1035.144775,508.864655 1035.367676,531.434021 1034.932129,554.018982 
	C1034.876831,556.889221 1033.791748,559.726624 1033.384277,562.607605 
	C1033.107788,564.562378 1033.157227,566.566040 1033.104126,568.549011 
	C1032.923828,575.277466 1032.431763,582.020386 1032.713257,588.730286 
	C1032.896362,593.092346 1029.751221,597.439941 1032.711914,601.817017 
	C1033.021240,602.274292 1032.470093,603.343628 1032.263916,604.114563 
	C1031.185669,608.148071 1029.968506,612.149048 1029.036255,616.215576 
	C1028.209229,619.822815 1027.703491,623.503174 1027.035278,627.147400 
	C1025.015991,638.160767 1023.586731,649.327209 1020.795715,660.141846 
	C1017.877319,671.450317 1013.617859,682.410767 1010.018250,693.547180 
	C1009.473816,695.231567 1009.493164,697.096985 1008.959229,698.786255 
	C1008.249878,701.030701 1007.279724,703.195496 1006.367126,705.371704 
	C1005.996216,706.256042 1005.352722,707.037476 1005.053284,707.938843 
	C1002.173340,716.606689 1000.075134,725.623840 996.321045,733.890686 
	C990.768616,746.117554 983.954224,757.766235 977.866150,769.757080 
	C974.632080,776.126831 971.998901,782.804077 968.708008,789.141785 
	C966.596497,793.208374 963.751892,796.891052 961.287476,800.778503 
	C957.671387,806.482666 954.334167,812.380371 950.444763,817.889648 
	C946.730835,823.150330 942.418701,827.985535 938.459595,833.077881 
	C936.941528,835.030396 935.709534,837.202698 934.282898,839.229614 
	C931.496826,843.187866 928.666626,847.114990 925.851807,851.053040 
	C925.373291,851.722412 924.932800,852.428467 924.386230,853.038147 
	C920.902405,856.924683 917.418335,860.811584 913.888245,864.656006 
	C906.104675,873.132812 898.285156,881.576599 890.497131,890.049377 
	C886.220032,894.702637 882.229431,899.655640 877.638062,903.971863 
	C874.036865,907.357361 869.711487,909.972534 865.358643,912.593201 
	C867.314880,909.362732 869.400757,906.254333 871.974548,903.623291 
	C876.377197,899.122864 881.170532,895.007019 885.639343,890.568237 
	C889.486145,886.747131 893.029419,882.621460 896.851990,878.774658 
	C901.594177,874.002258 906.690430,869.564636 911.212891,864.599243 
	C913.045959,862.586670 913.769226,859.563354 915.006348,856.629761 
	C916.005249,855.499817 916.995300,854.740173 918.343506,853.947388 
	C919.462952,852.605957 920.224182,851.297607 920.988281,849.741943 
	C920.991211,849.494629 921.000000,849.000000 921.321167,848.959595 
	C922.094849,848.280273 922.547424,847.641357 923.108948,846.783325 
	C923.417297,846.290771 923.678040,846.101929 924.321106,845.957397 
	C925.091736,845.276550 925.541260,844.635864 925.995361,843.997559 
	C926.000000,844.000000 925.995117,843.990234 926.371948,843.984985 
	C930.417725,842.524292 931.143433,839.495667 931.117432,835.788513 
	C931.424561,835.296448 931.685303,835.108826 932.364258,834.940552 
	C933.152771,833.589722 933.584412,832.304688 934.262085,831.015625 
	C934.508057,831.011597 935.000000,831.003113 935.110413,831.221924 
	C935.428467,831.711182 935.697876,831.887024 936.233887,831.867798 
	C936.694336,831.581177 936.868347,831.337097 937.005371,830.684692 
	C937.673950,829.406372 938.318054,828.491028 938.918701,827.548035 
	C940.946838,824.363831 942.959534,821.169800 944.979797,817.733521 
	C944.981506,817.487427 944.993103,816.995483 945.298218,816.852173 
	C949.027954,813.909607 952.041992,810.865662 951.995605,805.997192 
	C952.000000,806.000000 951.992126,805.990845 952.334290,805.959473 
	C953.451782,804.285461 954.227112,802.642761 955.005737,800.669617 
	C955.013550,799.898743 955.018188,799.458191 955.392456,799.008423 
	C957.774109,797.052856 960.341980,795.427734 961.688660,793.097107 
	C965.079651,787.228455 967.919739,781.041443 970.984009,774.652100 
	C970.992432,773.882263 970.997437,773.442383 971.359009,772.963501 
	C972.742859,771.687561 973.770203,770.450623 974.797546,769.213684 
	C973.869141,768.805847 972.940796,768.398010 972.002197,767.730591 
	C971.977417,767.298462 971.962891,767.125854 972.055481,766.735352 
	C972.357483,766.245056 972.612122,766.051392 973.309570,765.951294 
	C975.080933,764.977661 977.111084,764.294739 977.751526,762.949890 
	C981.352661,755.388062 984.619263,747.666870 988.390869,739.988647 
	C990.180420,736.313538 991.579224,732.649719 993.124390,728.663818 
	C994.852905,724.692627 996.672546,721.125427 997.965332,717.376526 
	C1000.311279,710.573364 1002.570496,703.723511 1004.450134,696.780640 
	C1006.850586,687.913940 1008.838928,678.935669 1011.002441,670.002747 
	C1011.000000,670.000000 1011.005493,670.003540 1011.333618,669.829224 
	C1013.091614,663.423584 1014.521362,657.192383 1015.957092,650.634766 
	C1015.980286,649.873413 1015.997559,649.438538 1016.420898,648.991333 
	C1017.522705,648.415894 1018.685608,647.955872 1018.836731,647.272217 
	C1019.689331,643.414062 1020.384155,639.515137 1020.976440,635.606384 
	C1023.067261,621.807251 1025.437134,608.038330 1027.052124,594.182373 
	C1028.754028,579.580322 1030.326660,564.912720 1030.833618,550.235046 
	C1031.416260,533.365845 1031.336182,516.473145 1028.179565,499.686462 
	C1026.324707,489.822418 1026.079468,479.661438 1024.954956,469.651428 
	C1024.529663,465.865692 1023.754333,462.111176 1022.987915,458.373352 
	C1020.588684,446.671539 1018.309692,434.939880 1015.608521,423.306976 
	C1013.718506,415.167267 1011.381775,407.119049 1008.942505,399.122070 
	C1005.503540,387.847443 1002.022644,376.574310 998.102356,365.461884 
	C995.524780,358.155670 992.414551,351.002563 989.058044,344.013733 
	C983.308289,332.041962 977.177551,320.253174 971.208923,308.386444 
	C967.794678,301.598145 964.384827,294.807617 960.941528,287.694824 
	C960.273376,286.914764 959.636658,286.458008 958.781372,285.891785 
	C958.290039,285.582306 958.102478,285.321136 957.999512,284.613678 
	C956.163452,280.478333 956.120911,275.501282 950.996399,273.751404 
	C950.992798,273.504028 950.987793,273.009247 950.988525,272.643066 
	C949.660278,270.850433 948.331360,269.424011 946.961548,267.641693 
	C944.608948,263.527313 942.297180,259.768799 939.965515,255.687500 
	C939.294617,254.578644 938.643616,253.792557 937.852417,252.718582 
	C936.477234,251.618607 935.242249,250.806503 934.005249,249.618988 
	C933.336304,248.161850 932.669373,247.080139 931.894287,245.740326 
	C931.520142,244.991180 931.254150,244.500137 930.957153,243.658508 
	C928.283325,240.539551 925.640442,237.771179 922.950684,234.677414 
	C921.931335,232.905472 920.958862,231.458954 919.846069,229.707611 
	C918.137085,227.935394 916.568542,226.468002 915.000000,225.000305 
	C915.000000,225.000000 915.000000,225.000610 914.958984,224.680145 
	C914.281067,223.904205 913.644165,223.448761 913.001648,222.628448 
	C910.475647,219.093643 908.124573,215.769073 905.399414,212.786377 
	C899.908813,206.776932 893.850098,201.247452 888.755310,194.931534 
	C886.137207,191.685913 880.838623,190.395920 880.994385,185.005249 
	C880.998535,185.001587 881.007324,184.992432 881.007324,184.992432 
z"/>
<path fill="#F2F2F2" opacity="1.000000" stroke="none" 
	d="
M184.995728,200.991928 
	C185.626450,199.532669 185.892471,197.697128 186.946945,196.675018 
	C193.947281,189.889511 201.168228,183.332230 208.208954,176.587494 
	C211.720520,173.223541 214.769089,169.347305 218.449234,166.195770 
	C222.854706,162.423126 227.843552,159.337967 232.326797,155.647766 
	C235.437866,153.086990 238.139786,150.024994 240.980637,147.143692 
	C241.516266,146.600449 241.953079,145.238037 242.253708,145.290726 
	C249.086044,146.488358 251.283081,140.139832 255.568192,137.236328 
	C260.336090,134.005692 265.064667,130.711639 269.918060,127.613968 
	C276.880219,123.170418 283.801453,118.634315 290.989380,114.579521 
	C302.633087,108.011154 314.375641,101.598129 326.287048,95.533112 
	C331.607880,92.823853 337.480286,91.215736 343.020386,88.910789 
	C345.386566,87.926353 347.434143,86.161255 349.813171,85.225220 
	C362.463013,80.248070 375.084320,75.173248 387.896179,70.643234 
	C394.695404,68.239166 401.848877,66.823456 408.864471,65.048683 
	C415.466553,63.378525 422.116913,61.897785 428.711090,60.198086 
	C437.330963,57.976238 445.834900,55.215061 454.545227,53.464901 
	C460.421143,52.284256 466.586304,52.621952 472.587677,51.966019 
	C479.387939,51.222763 486.129547,49.929264 492.933411,49.235519 
	C502.016296,48.309410 511.123657,47.365334 520.240356,47.092316 
	C532.390686,46.728458 544.565674,46.780987 556.719238,47.085758 
	C563.974304,47.267693 571.203430,48.346596 578.453979,48.891491 
	C586.103088,49.466335 593.784119,49.681076 601.413208,50.436989 
	C607.616455,51.051628 613.798645,52.047337 619.928711,53.200569 
	C635.125854,56.059586 650.323303,58.939854 665.444092,62.169781 
	C670.471741,63.243725 675.245728,65.472855 680.176453,67.046135 
	C682.678955,67.844612 685.322266,68.207626 687.814148,69.032501 
	C693.574585,70.939354 699.288452,72.988091 705.003784,75.028305 
	C706.077271,75.411491 707.028748,76.141479 708.105774,76.508446 
	C713.945496,78.498199 719.807007,80.423828 725.660767,82.372414 
	C727.697876,83.050545 729.823059,83.541740 731.754028,84.446762 
	C737.205200,87.001610 742.545532,89.792419 747.987183,92.368477 
	C755.074707,95.723679 762.372986,98.669670 769.287354,102.343552 
	C774.722229,105.231369 779.712708,108.955681 784.950317,112.987335 
	C784.994690,114.115807 784.989502,114.565331 784.628906,114.975349 
	C782.516541,114.289604 780.759460,113.643372 778.960022,112.676010 
	C778.274109,111.908401 777.630615,111.461929 776.637512,111.011070 
	C775.191956,110.671120 774.095947,110.335564 772.960815,109.643265 
	C771.611023,108.528412 770.300537,107.770302 768.642029,107.006874 
	C767.193726,106.336555 766.093445,105.671555 764.940796,104.654404 
	C762.257263,103.204102 759.626038,102.105927 756.613647,101.004150 
	C753.155090,99.666679 750.077515,98.332817 746.958374,96.677658 
	C746.273743,95.909111 745.630798,95.461853 744.615845,95.011757 
	C742.830078,94.671379 741.416260,94.333847 739.957764,93.629974 
	C738.270996,92.514557 736.628967,91.765465 734.656189,91.013504 
	C733.884460,91.006470 733.443420,91.002296 732.960083,90.676895 
	C732.273926,89.909317 731.630188,89.462975 730.608643,89.005920 
	C724.157043,86.992638 718.083191,84.990074 711.964111,82.667816 
	C711.275452,81.903160 710.632141,81.458191 709.740967,81.011185 
	C709.493164,81.009140 708.997559,81.003273 708.933838,80.644287 
	C707.577515,79.859978 706.284851,79.434647 704.619385,79.007401 
	C703.500671,78.999550 702.754822,78.993629 701.950806,78.640289 
	C699.590454,77.201195 697.288269,76.109505 694.590820,75.008011 
	C691.130432,74.331825 688.065186,73.665459 684.940491,72.646553 
	C682.247314,71.205353 679.613708,70.116699 676.616699,69.062439 
	C674.836426,69.396652 673.419434,69.696465 671.941895,69.637589 
	C671.303894,68.725723 670.813965,67.855919 670.135376,67.668175 
	C663.102112,65.722420 656.038391,63.886604 648.540283,62.015785 
	C645.733276,62.005219 643.369080,61.999092 640.941772,61.630852 
	C638.580322,60.519817 636.282043,59.770901 633.534912,59.018799 
	C630.391541,59.009098 627.696960,59.002590 624.936279,58.637821 
	C623.575012,57.859840 622.279846,57.440117 620.524780,57.017166 
	C617.088013,56.991875 614.086121,57.209564 611.139160,56.901718 
	C603.076355,56.059456 595.036621,54.995899 586.613647,54.014626 
	C585.490295,54.013412 584.741089,54.017548 583.725830,53.728920 
	C571.261597,52.626057 559.072815,51.570747 546.861511,51.101124 
	C539.934631,50.834721 532.963318,51.402527 526.025269,51.816170 
	C512.730408,52.608803 499.412659,53.234768 486.166443,54.553310 
	C476.598846,55.505676 467.110870,57.347378 457.624908,59.007019 
	C446.214722,61.003319 434.762756,62.872074 423.482422,65.464233 
	C414.389435,67.553757 405.502106,70.562508 396.564301,73.296715 
	C385.925568,76.551254 375.192810,79.572876 364.758270,83.397804 
	C355.822357,86.673409 347.202240,90.837837 338.555359,94.852264 
	C336.776642,95.678047 335.497681,97.580246 333.744049,98.992340 
	C333.499725,98.998367 333.011200,99.013779 332.607788,99.007843 
	C327.667725,100.658356 322.923615,101.904076 318.642609,104.066620 
	C312.067078,107.388268 305.804962,111.331741 299.418823,115.026764 
	C292.319794,119.134254 284.914154,122.804161 278.204865,127.473328 
	C265.232147,136.501404 252.598587,146.022339 239.934052,155.485657 
	C232.451950,161.076538 224.910507,166.640045 217.885025,172.776062 
	C209.967896,179.690796 202.649490,187.287247 194.970596,194.480347 
	C191.783218,197.466064 188.336487,200.174896 185.005249,202.673401 
	C185.000443,201.888763 184.998077,201.440338 184.995728,200.991928 
z"/>
<path fill="#F4F3F4" opacity="1.000000" stroke="none" 
	d="
M784.984253,115.014862 
	C784.989502,114.565331 784.994690,114.115807 785.002441,113.328934 
	C789.600647,114.930519 794.314758,116.634705 798.757996,118.876045 
	C802.983337,121.007484 807.024963,123.551880 810.963379,126.189445 
	C814.638672,128.650772 817.992188,131.588287 821.628357,134.113144 
	C829.341736,139.469009 837.365906,144.403427 844.842407,150.065536 
	C850.458557,154.318695 855.427612,159.428024 860.669495,164.173004 
	C865.128601,168.209427 869.407410,172.468903 874.100952,176.209305 
	C875.697021,177.481216 878.313904,177.472046 881.004883,178.193115 
	C881.004883,179.861923 881.004883,181.994141 881.006104,184.559402 
	C881.007324,184.992432 880.998535,185.001587 880.647949,184.968292 
	C873.938477,179.530930 867.670105,174.014114 861.167786,168.788452 
	C859.099426,167.126190 856.408508,166.238480 853.730103,164.801086 
	C850.972656,162.403076 848.487549,160.200333 845.964844,157.642609 
	C843.281738,155.195496 840.636230,153.103363 837.694580,150.986115 
	C836.933167,150.640060 836.467773,150.319122 835.946289,149.657120 
	C834.590210,149.200378 833.290222,149.084686 831.745667,148.967484 
	C831.501038,148.965973 831.011780,148.973206 830.799866,148.870514 
	C830.320740,148.578537 830.143433,148.326096 830.304321,147.808563 
	C830.032959,146.406570 829.513428,145.206497 828.842163,143.698883 
	C826.460388,142.260468 824.230164,141.129623 821.715454,139.863861 
	C820.286499,138.820084 819.142029,137.911240 817.962280,136.653259 
	C814.698364,132.650864 811.991638,128.145737 805.890869,128.781464 
	C805.577515,128.294586 805.315613,128.109589 804.855835,127.717697 
	C803.808777,126.954117 802.899536,126.482559 801.659424,126.008232 
	C800.887512,126.001671 800.446411,125.997887 799.970215,125.654053 
	C798.954163,124.546089 797.973083,123.778191 796.662170,123.006332 
	C795.892395,122.996819 795.452515,122.991264 794.967529,122.666046 
	C794.279175,121.901352 793.635864,121.456322 792.996277,121.005798 
	C793.000000,121.000305 792.990784,121.007965 792.853760,120.716309 
	C791.809570,119.951439 790.902405,119.478233 789.720642,118.877426 
	C788.630737,118.163986 787.815430,117.578148 786.957947,116.671646 
	C786.272339,115.905472 785.629028,115.459961 784.990845,115.008667 
	C784.995911,115.002884 784.984253,115.014862 784.984253,115.014862 
z"/>
<path fill="#F2F2F2" opacity="1.000000" stroke="none" 
	d="
M914.997559,857.000000 
	C913.769226,859.563354 913.045959,862.586670 911.212891,864.599243 
	C906.690430,869.564636 901.594177,874.002258 896.851990,878.774658 
	C893.029419,882.621460 889.486145,886.747131 885.639343,890.568237 
	C881.170532,895.007019 876.377197,899.122864 871.974548,903.623291 
	C869.400757,906.254333 867.314880,909.362732 865.004761,912.626953 
	C865.000000,912.997559 864.504883,913.005005 863.886841,913.007690 
	C856.125549,915.962097 850.798035,921.153870 846.004883,927.004883 
	C846.004883,927.004883 846.000000,927.000000 845.688843,927.154541 
	C838.651855,931.976440 831.981689,936.726929 825.177734,941.277344 
	C821.564270,943.694031 817.718872,945.763794 814.019775,947.641235 
	C818.042053,943.928650 821.910706,940.420532 826.025696,937.230225 
	C836.155396,929.376709 846.478149,921.770264 856.534119,913.825012 
	C858.763367,912.063782 860.211731,909.314087 862.010864,907.012695 
	C862.002014,907.005798 862.012390,907.018860 862.364868,907.012085 
	C863.488342,906.343018 864.259216,905.680786 865.326538,904.868530 
	C868.288147,902.212830 870.997009,899.751587 873.610779,897.193359 
	C883.904968,887.118103 894.176880,877.020020 904.416931,866.889893 
	C904.829590,866.481750 904.836243,865.663086 905.321899,864.995972 
	C905.759888,864.301086 905.908203,863.645935 906.054260,862.668457 
	C906.053711,861.917114 906.055420,861.488159 906.378906,861.042969 
	C907.129822,861.006897 907.558960,860.987000 908.173950,861.222778 
	C910.572449,859.985657 912.785034,858.492859 914.997559,857.000000 
z"/>
<path fill="#F4F3F4" opacity="1.000000" stroke="none" 
	d="
M813.978210,947.990234 
	C817.718872,945.763794 821.564270,943.694031 825.177734,941.277344 
	C831.981689,936.726929 838.651855,931.976440 845.691284,927.156067 
	C844.706604,928.949219 843.769958,931.341248 842.034729,932.748901 
	C838.403748,935.694580 834.196167,937.710388 830.859192,941.317505 
	C827.560242,944.883545 823.027405,947.390076 818.821228,950.017822 
	C810.318848,955.329590 801.646118,960.367798 793.092224,965.598267 
	C787.310669,969.133606 782.019409,974.192871 775.763000,976.028992 
	C769.354858,977.909546 764.497437,981.902649 758.718750,984.442566 
	C757.285339,985.072571 755.472656,984.839783 753.417175,985.001831 
	C753.000000,985.000000 752.997559,984.997559 753.052246,984.648804 
	C755.176880,983.127502 757.377808,982.133606 759.283447,980.736877 
	C761.650269,979.002014 763.772949,976.933960 766.370728,975.002319 
	C767.480164,974.989807 768.219849,974.984741 769.327148,974.951294 
	C776.801147,971.110229 784.000732,967.457886 790.969604,963.408508 
	C793.899170,961.706238 796.341187,959.164795 799.378906,956.961304 
	C805.020752,955.212158 810.143555,953.291077 813.244507,947.997192 
	C813.489075,947.991882 813.978210,947.990234 813.978210,947.990234 
z"/>
<path fill="#F4F3F4" opacity="1.000000" stroke="none" 
	d="
M737.004883,993.004883 
	C736.312073,993.836365 735.784180,995.087891 734.901062,995.434753 
	C725.242371,999.228027 715.575317,1003.011841 705.804199,1006.500366 
	C702.306152,1007.749207 698.520508,1008.192261 694.434265,1009.002747 
	C694.002441,1009.002441 694.000000,1009.000000 694.063843,1008.637329 
	C695.066528,1007.694763 695.940369,1006.787903 696.954468,1006.586121 
	C705.939209,1004.797913 714.076355,1000.812622 722.414551,997.255310 
	C727.039368,995.282349 732.121521,994.381592 736.998779,992.998779 
	C737.000000,993.000000 737.004883,993.004883 737.004883,993.004883 
z"/>
<path fill="#F4F3F4" opacity="1.000000" stroke="none" 
	d="
M846.404114,927.003967 
	C850.798035,921.153870 856.125549,915.962097 863.639343,913.008545 
	C863.999329,913.338257 864.123596,913.854370 863.958923,913.974426 
	C858.156738,918.207642 854.711792,925.553345 846.404114,927.003967 
z"/>
<path fill="#F4F3F4" opacity="1.000000" stroke="none" 
	d="
M753.001221,985.001221 
	C749.031311,989.709473 744.518799,993.391907 737.430542,993.004883 
	C737.004883,993.004883 737.000000,993.000000 737.052612,992.652222 
	C742.402649,989.868835 747.700134,987.433167 752.997559,984.997559 
	C752.997559,984.997559 753.000000,985.000000 753.001221,985.001221 
z"/>
<path fill="#F4F3F4" opacity="1.000000" stroke="none" 
	d="
M184.629272,200.997849 
	C184.998077,201.440338 185.000443,201.888763 185.002045,202.668732 
	C185.001266,203.000275 185.009613,203.007217 184.663940,203.038147 
	C182.547653,205.048889 180.777023,207.028717 179.003204,209.004272 
	C179.000000,209.000000 179.008453,209.006760 178.673981,209.004257 
	C177.893600,209.001785 177.447723,209.001816 177.001831,209.001831 
	C177.001831,209.001831 176.999847,208.999847 176.997177,208.997177 
	C177.498734,204.585846 180.300781,202.267258 184.629272,200.997849 
z"/>
<path fill="#E2E2E2" opacity="1.000000" stroke="none" 
	d="
M185.004486,203.004959 
	C188.336487,200.174896 191.783218,197.466064 194.970596,194.480347 
	C202.649490,187.287247 209.967896,179.690796 217.885025,172.776062 
	C224.910507,166.640045 232.451950,161.076538 239.934052,155.485657 
	C252.598587,146.022339 265.232147,136.501404 278.204865,127.473328 
	C284.914154,122.804161 292.319794,119.134254 299.418823,115.026764 
	C305.804962,111.331741 312.067078,107.388268 318.642609,104.066620 
	C322.923615,101.904076 327.667725,100.658356 332.564514,99.365646 
	C332.135345,100.481529 331.465271,101.456902 330.538635,101.952164 
	C317.716461,108.805183 304.431885,114.905174 292.114319,122.562881 
	C275.301117,133.015518 259.186829,144.597229 242.863388,155.826385 
	C240.960663,157.135284 239.631500,159.277985 238.030762,161.026886 
	C238.025116,161.019669 238.032715,161.034317 237.664093,161.023254 
	C235.528015,162.672089 233.760574,164.332001 231.697296,166.019409 
	C230.941345,166.374191 230.481247,166.701492 229.675781,167.055084 
	C228.579727,168.084396 227.829056,169.087418 227.061707,170.404938 
	C227.024628,171.138763 227.004227,171.558136 226.663528,172.016785 
	C225.871841,172.686951 225.400467,173.317856 224.706329,174.058716 
	C224.203934,174.362213 223.992386,174.613144 223.465652,174.936142 
	C221.408066,175.450775 219.376953,175.512619 218.125443,176.531799 
	C214.170471,179.752563 210.316376,183.149551 206.799683,186.837357 
	C204.520691,189.227234 202.899506,192.244400 200.794708,195.252808 
	C198.396027,197.676971 196.186218,199.828827 193.613159,201.985428 
	C183.973587,207.634918 178.025009,216.352631 171.731506,225.191025 
	C170.873550,226.184113 170.304031,227.001556 169.693665,227.787262 
	C159.056839,241.479736 148.337997,255.109619 137.828552,268.899109 
	C135.208160,272.337341 133.259857,276.287781 130.710480,280.139343 
	C129.945129,281.183105 129.471954,282.090363 128.966400,283.322083 
	C128.281906,284.757263 127.629784,285.867981 126.637756,287.035400 
	C124.532097,290.394714 122.766350,293.697357 121.000305,297.000000 
	C121.000000,297.000000 120.999695,297.001221 120.661430,297.046661 
	C119.553497,298.398376 118.783836,299.704681 118.006149,301.254669 
	C117.998123,301.498383 117.984962,301.985901 117.641022,302.042816 
	C116.536911,303.737396 115.776741,305.375061 115.009987,307.257263 
	C115.003403,307.501770 114.991165,307.990784 114.670059,308.033203 
	C113.904022,308.720856 113.459084,309.366089 113.008057,310.256531 
	C113.001961,310.501801 112.992416,310.992340 112.643097,311.049011 
	C110.533005,315.074036 108.772240,319.042358 107.000389,323.251953 
	C106.989311,323.493225 106.967796,323.975769 106.626671,324.034821 
	C105.852043,325.057526 105.418541,326.021179 104.991806,326.992432 
	C104.998581,327.000000 104.980141,326.985748 104.632156,327.044922 
	C101.181450,334.394165 98.078735,341.684296 94.984581,348.987518 
	C94.993134,349.000641 94.966492,348.977234 94.612839,349.042755 
	C93.516174,350.291687 92.677391,351.426605 92.048500,352.667847 
	C90.315392,356.088501 88.670158,359.553650 86.740936,362.791565 
	C86.657768,361.051605 86.823822,359.521271 86.989876,357.990967 
	C88.235191,354.212280 89.480507,350.433594 91.235649,346.285767 
	C92.498001,344.278931 93.250526,342.641235 94.003052,341.003571 
	C94.334999,340.227692 94.666954,339.451813 95.310287,338.178528 
	C96.417389,335.791199 97.213112,333.901337 98.008850,332.011444 
	C98.327446,331.539825 98.646042,331.068237 99.261749,330.096832 
	C101.374008,325.399658 103.189140,321.202271 105.004272,317.004883 
	C105.004272,317.004883 105.012482,316.509155 105.334488,316.216431 
	C106.104431,315.282471 106.552368,314.641235 107.000305,314.000000 
	C107.000305,314.000000 107.003807,313.502563 107.329735,313.208832 
	C108.103874,311.610077 108.552094,310.305023 109.000305,309.000000 
	C112.870720,302.189362 116.741135,295.378693 121.154358,288.235107 
	C124.132057,283.935577 126.566948,279.968994 129.001831,276.002441 
	C129.001831,276.002441 129.011871,275.508087 129.353241,275.207794 
	C132.796219,270.271667 135.897812,265.635834 138.999390,261.000000 
	C138.999390,261.000000 139.000000,261.000000 139.297302,260.858154 
	C140.396393,259.477509 141.198196,258.238739 142.000000,257.000000 
	C142.000000,257.000000 142.006546,256.504028 142.344727,256.205933 
	C143.457001,254.607391 144.231079,253.306946 145.005188,252.006485 
	C145.588257,251.191986 146.171326,250.377487 147.169189,249.144745 
	C148.056793,247.818069 148.529617,246.909637 149.002441,246.001221 
	C149.590088,245.194443 150.177750,244.387665 151.206573,243.253418 
	C152.101334,242.285995 152.554932,241.646057 153.008514,241.006119 
	C153.008514,241.006119 153.000000,241.000000 153.347351,240.949860 
	C154.131210,239.934006 154.567749,238.968307 155.004272,238.002609 
	C155.004272,238.002609 155.000000,238.000000 155.356476,237.963486 
	C160.554779,232.470352 165.567352,227.152908 170.172241,221.503174 
	C172.795349,218.284882 174.752731,214.523987 177.009918,211.007431 
	C177.009903,211.007431 177.000000,211.000000 177.323578,210.961182 
	C178.100937,210.283844 178.554703,209.645309 179.008453,209.006760 
	C179.008453,209.006760 179.000000,209.000000 179.347321,208.969543 
	C181.466309,206.961792 183.237961,204.984512 185.009613,203.007233 
	C185.009613,203.007217 185.001266,203.000275 185.004486,203.004959 
z"/>
<path fill="#DEDEDE" opacity="1.000000" stroke="none" 
	d="
M54.983692,486.989410 
	C54.990498,484.950867 54.997299,482.912323 55.265713,480.227173 
	C55.360119,478.386444 55.192913,477.192261 55.025711,475.998108 
	C55.024017,475.558289 55.022327,475.118469 55.398659,474.262207 
	C56.851425,469.706512 58.175037,465.610962 58.912037,461.412384 
	C59.390663,458.685760 59.013977,455.809021 59.020508,452.999542 
	C59.021969,452.560760 59.023434,452.122009 59.394264,451.273285 
	C61.511524,442.244598 63.259411,433.625885 65.007294,425.007141 
	C65.007294,425.007141 65.000000,425.000000 65.376297,424.926117 
	C67.495773,418.564240 69.238937,412.276276 70.982101,405.988281 
	C70.986397,405.546448 70.990685,405.104614 71.323280,404.156067 
	C72.102417,401.766815 72.553246,399.884247 73.339241,398.001068 
	C74.122177,397.999542 74.569954,397.998657 75.270668,398.105591 
	C76.004791,398.476776 76.485977,398.740173 76.969528,399.331146 
	C76.967964,400.095459 76.964043,400.532227 76.618416,401.031677 
	C75.862190,402.071655 75.447670,403.048920 75.025276,404.353149 
	C75.007118,405.116180 74.996834,405.552185 74.641449,406.044556 
	C73.880173,407.079224 73.463997,408.057465 73.039124,409.447388 
	C73.009964,411.231415 72.989502,412.603821 72.610306,414.047607 
	C70.830223,420.412720 69.408882,426.706390 67.986115,433.371094 
	C67.976593,434.484070 67.968483,435.226044 67.597549,436.022400 
	C66.505508,437.729492 65.776291,439.382233 65.072250,441.372498 
	C65.378242,442.792542 65.659050,443.875031 65.581085,445.043091 
	C64.472252,449.085876 63.722195,453.042999 62.970032,457.327881 
	C62.963463,458.092590 62.959007,458.529572 62.580376,459.058624 
	C61.152065,465.444458 60.097935,471.738159 59.037621,478.445862 
	C59.028217,480.239868 59.025002,481.619934 58.730293,483.245087 
	C57.974800,487.338013 57.510799,491.185883 57.079163,495.393463 
	C57.393620,497.158173 57.675713,498.563110 57.607903,500.042664 
	C57.172119,501.744812 57.086239,503.372406 56.998402,505.427185 
	C56.989651,507.563354 56.982857,509.272339 56.582912,511.078918 
	C55.793556,512.669189 55.095856,514.153870 55.055996,515.656006 
	C54.920216,520.773560 55.016262,525.897278 55.021404,531.485840 
	C55.022423,538.294373 55.025860,544.635742 55.016037,551.447876 
	C55.002384,556.352539 54.746437,560.804993 55.065773,565.215759 
	C55.472103,570.828125 56.344299,576.406799 57.011246,582.372131 
	C57.014423,583.488403 57.022015,584.232605 57.018333,585.373413 
	C57.652142,587.193665 58.297230,588.617371 58.958729,590.407349 
	C58.972065,591.506836 58.968990,592.240112 58.626404,593.036987 
	C58.531891,595.067078 58.776882,597.033508 59.015709,599.414185 
	C59.021137,601.207886 59.032726,602.587524 59.028519,604.358459 
	C59.351479,606.166565 59.690235,607.583252 60.022408,609.328857 
	C60.022243,610.096191 60.028656,610.534729 60.021412,611.385376 
	C60.661446,613.539795 61.315132,615.282043 61.976646,617.353516 
	C61.981182,618.121887 61.977890,618.560913 61.724174,619.200317 
	C61.965450,620.279785 62.457138,621.158936 62.964485,622.434204 
	C62.980007,623.886841 62.979870,624.943420 62.706482,626.247070 
	C62.637665,629.068054 62.458675,631.748962 63.139503,634.190002 
	C64.079521,637.560364 65.667923,640.749939 66.986763,644.428101 
	C67.021439,646.209473 67.047966,647.579773 67.050728,649.366089 
	C68.004684,654.067810 68.830673,658.396057 70.016991,662.623169 
	C70.729828,665.163147 72.004539,667.545410 73.025246,670.368652 
	C73.034561,671.478821 73.045364,672.218933 73.042953,673.316406 
	C73.683449,674.451904 74.337158,675.230042 75.000099,676.403870 
	C76.343140,681.529968 77.676956,686.260315 79.010017,691.380249 
	C80.332481,694.518982 81.655701,697.268127 82.990257,700.384399 
	C83.337563,702.164917 83.673523,703.578369 84.004913,705.368286 
	C84.662552,706.833679 85.324768,707.922424 85.994629,709.375244 
	C86.337563,710.823792 86.672844,711.908264 87.003494,713.393677 
	C87.666588,716.862305 88.334305,719.929932 88.646378,722.940613 
	C87.527313,721.592957 86.870995,720.220825 85.969475,719.035095 
	C85.118919,717.916443 84.010262,716.993958 83.015160,715.985168 
	C81.368561,710.249146 79.732010,704.510132 78.073334,698.777649 
	C74.298737,685.732117 70.521225,672.687500 66.712593,659.651917 
	C66.485954,658.876282 65.974518,658.183777 65.302460,656.788208 
	C64.344521,653.747742 63.679749,651.371704 63.014977,648.995605 
	C63.006741,647.285461 62.998508,645.575378 62.993416,643.029541 
	C60.938744,629.784485 58.916298,617.368896 56.773647,604.974121 
	C56.534218,603.589050 55.612373,602.321838 55.006996,601.000000 
	C55.001259,599.288696 54.995529,597.577393 54.993809,594.983154 
	C54.663734,589.148499 54.331066,584.196655 53.995190,579.244995 
	C53.603096,573.464539 53.050938,567.689758 52.848892,561.902649 
	C52.484272,551.458862 51.843071,540.991028 52.202374,530.563721 
	C52.703377,516.024292 54.017529,501.512848 54.983692,486.989410 
z"/>
<path fill="#DEDEDE" opacity="1.000000" stroke="none" 
	d="
M135.010193,813.990417 
	C135.010193,813.990417 134.999268,813.500122 134.964142,812.901611 
	C133.623016,810.865906 132.317032,809.428772 131.011047,807.991638 
	C131.011047,807.991638 131.000000,808.000000 131.002686,807.608704 
	C128.336227,803.479065 125.667068,799.740784 122.997902,796.002441 
	C122.997902,796.002441 123.000000,796.000000 123.000679,795.619263 
	C121.672295,793.487671 120.343231,791.736755 119.014175,789.985840 
	C119.007774,789.547119 119.001373,789.108459 118.989975,787.965698 
	C116.991783,784.172791 114.998596,781.083923 113.005402,777.995117 
	C113.005402,777.995117 113.000587,777.500793 112.958458,776.932007 
	C112.279823,775.906433 111.643311,775.449524 111.006805,774.992676 
	C111.006805,774.992676 111.000145,774.499146 110.962212,773.889954 
	C109.621819,771.515015 108.319351,769.749329 107.016891,767.983582 
	C107.011086,767.544617 107.005264,767.105652 106.961151,765.979370 
	C105.509689,762.029907 104.280357,758.667725 102.588341,755.557190 
	C101.955994,754.394653 100.244911,753.818848 99.025688,752.975464 
	C99.018623,752.231628 99.011551,751.487732 98.998932,749.997437 
	C97.667053,747.162659 96.340744,745.074341 95.014427,742.986023 
	C95.008156,742.547424 95.001884,742.108765 94.994881,740.948486 
	C92.998421,736.482483 91.002686,732.738159 89.006958,728.993835 
	C89.006958,728.993835 89.000000,729.000000 89.003586,728.579529 
	C89.009399,726.767822 89.011635,725.376587 89.382240,723.989868 
	C90.488068,724.006409 91.225517,724.018433 91.974915,724.355347 
	C91.998886,725.113770 92.010910,725.547363 92.018990,726.349243 
	C92.995560,728.156372 93.976074,729.595093 94.965103,731.360229 
	C94.985329,732.121643 94.997040,732.556824 95.043945,733.312134 
	C95.705429,734.099426 96.331734,734.566528 96.966202,735.432068 
	C98.616188,739.324463 100.281883,742.807495 101.894623,746.314819 
	C104.769890,752.568054 107.338020,758.981262 110.544510,765.058411 
	C113.992477,771.593262 118.044632,777.808289 121.800911,784.181763 
	C126.631195,792.377563 131.162292,800.764343 136.311508,808.753601 
	C142.273575,818.004028 148.767075,826.912048 155.018768,836.308838 
	C155.001694,837.102600 154.995712,837.556152 154.638702,837.972595 
	C152.860504,836.462341 151.327560,835.076599 150.026810,833.499268 
	C146.628937,829.378967 143.332733,825.174805 139.996002,821.004089 
	C139.894379,820.681213 139.706955,820.419861 139.212112,819.756958 
	C138.326584,818.530029 137.662689,817.766235 136.998779,817.002441 
	C136.998779,817.002441 137.000000,817.000000 136.995163,816.646729 
	C136.330292,815.525818 135.670258,814.758118 135.010193,813.990417 
z"/>
<path fill="#F2F2F2" opacity="1.000000" stroke="none" 
	d="
M753.052246,984.648804 
	C747.700134,987.433167 742.402649,989.868835 737.051392,992.651001 
	C732.121521,994.381592 727.039368,995.282349 722.414551,997.255310 
	C714.076355,1000.812622 705.939209,1004.797913 696.954468,1006.586121 
	C695.940369,1006.787903 695.066528,1007.694763 694.062622,1008.636108 
	C687.345032,1010.535645 680.683289,1012.034790 674.042175,1013.620728 
	C663.876343,1016.048401 653.728088,1018.549927 643.565552,1020.991882 
	C638.704773,1022.159851 633.846924,1023.356384 628.945557,1024.327393 
	C627.760864,1024.562256 626.409363,1023.954590 625.451904,1023.362793 
	C628.522827,1021.670715 631.276489,1020.350037 634.426025,1019.024658 
	C635.881287,1019.014893 636.940613,1019.009888 638.169861,1019.233032 
	C638.905701,1018.986206 639.471680,1018.511108 640.433655,1018.033386 
	C641.885620,1018.022522 642.941528,1018.014343 644.237305,1018.280884 
	C645.116638,1018.680908 645.814148,1019.038330 646.386597,1018.895325 
	C651.631653,1017.585693 656.919617,1016.388489 662.053894,1014.723328 
	C664.212585,1014.023193 666.032898,1012.279846 668.380371,1011.009155 
	C669.502136,1011.006409 670.250549,1011.005371 671.234741,1011.277649 
	C676.225586,1010.310303 681.045227,1009.268372 685.709900,1007.750183 
	C688.274963,1006.915344 690.579163,1005.278931 693.333618,1004.002625 
	C694.110352,1003.999451 694.554443,1003.997498 695.405701,1003.997803 
	C703.133667,1001.543274 710.541809,999.312134 717.736328,996.528687 
	C721.045166,995.248718 723.935242,992.886292 727.341125,991.007385 
	C728.101074,990.983337 728.535034,990.966492 729.366333,990.965454 
	C733.605225,989.696655 737.598083,988.741272 741.257751,987.060608 
	C749.592346,983.232727 757.763489,979.048950 766.000977,975.009766 
	C763.772949,976.933960 761.650269,979.002014 759.283447,980.736877 
	C757.377808,982.133606 755.176880,983.127502 753.052246,984.648804 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M390.033569,1006.968994 
	C383.731903,1004.841248 377.444611,1002.669434 371.122437,1000.604492 
	C368.014587,999.589417 364.781982,998.938782 361.711639,997.830200 
	C355.575073,995.614502 349.286194,993.649902 343.469452,990.758301 
	C334.279694,986.189697 325.465942,980.870178 316.406555,976.030396 
	C313.829590,974.653687 310.787354,974.134216 308.242737,972.712830 
	C298.265778,967.140259 288.332397,961.481506 278.522797,955.620483 
	C272.517487,952.032410 266.717316,948.090088 260.950745,944.124023 
	C259.995728,943.467224 259.687347,941.870178 259.402008,940.378784 
	C264.200989,942.726501 268.661133,945.432129 273.161346,948.069275 
	C276.067139,949.772156 279.031677,951.374878 282.020996,953.340576 
	C282.717072,954.100403 283.362122,954.542053 284.253113,954.990234 
	C284.499023,954.996826 284.990997,955.005249 285.032593,955.364746 
	C287.144653,957.099121 289.116241,958.660583 291.301544,959.818970 
	C306.920074,968.097961 322.466309,976.527161 338.283722,984.410645 
	C345.225494,987.870483 352.748413,990.164429 360.249207,992.994629 
	C360.490936,993.001465 360.974152,993.022095 361.041260,993.376099 
	C366.989349,996.145813 372.932709,998.422729 378.729401,1001.026123 
	C382.605347,1002.766785 386.272827,1004.971802 390.033569,1006.968994 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M281.969910,953.022400 
	C279.031677,951.374878 276.067139,949.772156 273.161346,948.069275 
	C268.661133,945.432129 264.200989,942.726501 259.371094,940.009827 
	C259.018311,939.969788 258.547516,939.943970 258.256592,939.601562 
	C255.240952,936.658447 252.647919,933.901001 249.769333,931.483521 
	C235.224365,919.268799 220.605042,907.142578 205.978027,894.647095 
	C204.292160,892.543030 202.640045,890.777222 200.744141,888.846558 
	C200.312943,888.136047 200.125519,887.590515 199.957870,886.784912 
	C199.984085,886.351562 199.990524,886.178223 200.340637,885.999146 
	C201.142899,885.974976 201.601440,885.956604 202.270920,886.056519 
	C202.739075,886.381714 202.929337,886.638367 203.081726,887.284546 
	C214.807800,898.307983 226.489243,909.008850 238.250214,919.621460 
	C238.807739,920.124512 240.071121,919.845337 241.364807,919.963196 
	C243.140411,921.007446 244.557007,922.018860 246.015839,923.380005 
	C249.260635,926.439636 252.350891,929.300476 255.709946,931.799988 
	C257.577332,933.189514 259.892914,933.976746 262.338196,935.041382 
	C263.112152,935.042053 263.554352,935.036743 264.213013,935.045959 
	C264.429443,935.060486 264.862854,935.079590 264.851318,935.303223 
	C264.839752,935.526917 264.793518,935.972351 264.580200,936.199829 
	C265.087646,937.612732 265.535004,939.190979 266.576385,939.915771 
	C271.121246,943.079224 276.077850,945.691467 280.409119,949.107117 
	C283.287933,951.377441 288.380981,950.964478 288.694855,955.948059 
	C287.227051,955.618713 286.109039,955.311951 284.990997,955.005249 
	C284.990997,955.005249 284.499023,954.996826 284.209320,954.669312 
	C283.269714,953.901978 282.619812,953.462158 281.969910,953.022400 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M580.990234,1030.990234 
	C554.642212,1030.991211 528.294250,1030.992188 501.401215,1030.626099 
	C498.901154,1029.179199 496.946075,1028.099365 495.456604,1027.010254 
	C511.269379,1027.665771 526.616333,1028.841187 541.963928,1028.849976 
	C556.978882,1028.858521 571.994446,1027.677979 586.933350,1027.385620 
	C584.901428,1028.831177 582.945801,1029.910645 580.990234,1030.990234 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M54.614555,487.065979 
	C54.017529,501.512848 52.703377,516.024292 52.202374,530.563721 
	C51.843071,540.991028 52.484272,551.458862 52.848892,561.902649 
	C53.050938,567.689758 53.603096,573.464539 53.995190,579.244995 
	C54.331066,584.196655 54.663734,589.148499 54.990768,594.555542 
	C54.072285,593.430115 52.712967,591.946777 52.344067,590.248230 
	C51.610584,586.871094 51.153889,583.383179 51.054932,579.927490 
	C50.613514,564.512878 49.970455,549.090027 50.115623,533.676880 
	C50.240372,520.432251 51.243874,507.190918 52.084629,493.962494 
	C52.231430,491.652740 53.496712,489.414062 54.614555,487.065979 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M199.938095,887.044922 
	C200.125519,887.590515 200.312943,888.136047 200.750092,889.211243 
	C202.670471,891.489014 204.341125,893.237183 206.011780,894.985352 
	C220.605042,907.142578 235.224365,919.268799 249.769333,931.483521 
	C252.647919,933.901001 255.240952,936.658447 258.020874,939.598511 
	C253.681473,937.239197 249.029861,934.878479 244.944931,931.772461 
	C236.007874,924.977112 227.073288,918.125793 218.672592,910.692749 
	C210.457413,903.423950 202.906403,895.404480 195.030609,887.358643 
	C194.997375,887.002686 195.004272,886.995117 195.416656,886.996338 
	C197.198715,887.013306 198.568405,887.029114 199.938095,887.044922 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M465.998505,1025.009644 
	C456.188629,1026.235718 447.377747,1022.077759 438.196564,1019.882874 
	C425.755463,1016.908752 413.267609,1014.126343 400.856506,1011.034119 
	C398.792267,1010.519775 397.016510,1008.847717 395.474243,1007.353516 
	C398.237579,1007.494873 400.649414,1007.929932 403.028168,1008.502563 
	C414.692535,1011.310852 426.348083,1014.155823 438.338867,1016.992798 
	C439.113983,1016.999207 439.557007,1017.000732 440.190094,1017.339966 
	C448.976868,1019.451477 457.584442,1021.175354 466.159027,1023.050171 
	C467.520721,1023.347961 468.733826,1024.325073 469.613953,1024.993286 
	C468.141052,1025.002197 467.069763,1025.005981 465.998505,1025.009644 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M438.006775,1016.987793 
	C426.348083,1014.155823 414.692535,1011.310852 403.028168,1008.502563 
	C400.649414,1007.929932 398.237579,1007.494873 395.418640,1007.001892 
	C393.617004,1006.997620 392.237762,1006.987854 390.446045,1006.973572 
	C386.272827,1004.971802 382.605347,1002.766785 378.729401,1001.026123 
	C372.932709,998.422729 366.989349,996.145813 361.436157,993.369751 
	C364.861816,993.985840 367.959686,994.962341 371.423218,995.951233 
	C372.521973,995.983093 373.255066,996.002563 374.055969,996.381104 
	C378.415894,998.159302 382.707947,999.578430 387.331726,1000.999512 
	C388.105743,1001.007385 388.548065,1001.013367 389.064819,1001.384338 
	C392.424896,1002.510071 395.710480,1003.270813 399.325256,1004.024414 
	C400.093140,1004.031982 400.531830,1004.046692 401.022644,1004.377563 
	C401.728119,1005.118958 402.381409,1005.544189 403.428711,1005.977295 
	C404.872864,1006.008545 405.922943,1006.032043 407.032990,1006.432129 
	C407.743927,1007.485107 408.283142,1008.544556 409.064972,1008.772827 
	C412.798523,1009.862732 416.583069,1010.795654 420.385895,1011.615967 
	C421.176086,1011.786438 422.120544,1011.241760 423.323792,1011.031128 
	C424.093353,1011.049622 424.532990,1011.063782 425.052795,1011.429688 
	C427.751984,1012.203674 430.371033,1012.625854 433.320160,1013.045837 
	C434.089691,1013.053894 434.529205,1013.064148 435.030762,1013.407471 
	C436.064148,1014.822937 437.035461,1015.905334 438.006775,1016.987793 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M194.991058,887.008667 
	C192.496124,885.018982 189.704193,883.301758 187.572296,880.978699 
	C179.161774,871.814148 170.936859,862.478455 162.712296,853.145325 
	C161.899963,852.223511 161.560379,850.885071 161.009201,849.361267 
	C161.017044,848.982483 161.044556,848.955383 161.372864,848.961792 
	C162.140335,848.973633 162.579498,848.979126 163.045105,849.326965 
	C164.046494,850.447266 165.021439,851.225220 166.151016,852.246338 
	C166.866745,852.996094 167.427856,853.502625 167.994141,854.373657 
	C169.331436,856.160339 170.663574,857.582642 172.326920,859.009888 
	C173.099503,859.017212 173.540878,859.019531 174.197205,859.133789 
	C174.680267,859.445007 174.868622,859.702698 174.977341,860.388794 
	C174.993149,861.499268 175.008850,862.239685 175.056168,863.303223 
	C175.722977,864.085999 176.358170,864.545715 177.023575,865.300171 
	C177.375458,866.057251 177.697098,866.519592 178.155319,867.285278 
	C179.859833,868.726562 181.427765,869.864502 183.259949,871.191650 
	C184.690598,872.914429 185.857010,874.448059 187.049103,876.324341 
	C188.053314,877.440674 189.031815,878.214233 190.144760,879.269287 
	C191.189468,880.364258 192.099731,881.177734 193.038971,882.335571 
	C193.713409,884.118286 194.358841,885.556702 195.004272,886.995117 
	C195.004272,886.995117 194.997375,887.002686 194.991058,887.008667 
z"/>
<path fill="#F2F2F2" opacity="1.000000" stroke="none" 
	d="
M176.657211,211.040741 
	C174.752731,214.523987 172.795349,218.284882 170.172241,221.503174 
	C165.567352,227.152908 160.554779,232.470352 155.357819,237.965591 
	C159.169540,226.706894 168.829330,219.754486 176.657211,211.040741 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M54.725208,601.249878 
	C55.612373,602.321838 56.534218,603.589050 56.773647,604.974121 
	C58.916298,617.368896 60.938744,629.784485 62.990562,642.602173 
	C61.782867,641.227112 59.809082,639.565491 59.505440,637.640442 
	C57.610397,625.626343 56.088760,613.553223 54.725208,601.249878 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M140.000946,821.378906 
	C143.332733,825.174805 146.628937,829.378967 150.026810,833.499268 
	C151.327560,835.076599 152.860504,836.462341 154.932495,838.098572 
	C156.374634,838.519226 157.171951,838.776733 158.210876,839.199890 
	C158.635803,839.910339 158.819122,840.455200 158.988098,840.986206 
	C158.973740,840.972351 158.934082,840.975952 158.641449,841.020996 
	C158.560791,841.714539 158.772766,842.362915 158.992065,843.005676 
	C158.999390,843.000000 158.988297,843.011353 158.989624,843.384460 
	C158.995392,844.504028 158.999832,845.250488 159.000885,845.999695 
	C158.997498,846.002441 158.986237,846.014648 158.986237,846.014648 
	C154.063660,846.099609 153.225784,841.766663 151.209961,838.758850 
	C147.430923,833.120239 143.735870,827.425293 140.000946,821.378906 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M86.644585,358.046997 
	C86.823822,359.521271 86.657768,361.051605 86.740875,363.121399 
	C86.979889,364.100647 86.969742,364.540405 86.601593,364.991730 
	C85.168930,366.338013 84.094261,367.672760 83.015083,369.340393 
	C83.007408,370.117249 83.004242,370.561218 82.733093,371.193359 
	C81.980171,372.589050 81.495232,373.796570 81.008118,375.336426 
	C81.003258,376.111938 81.000587,376.555084 80.708954,377.139771 
	C79.951210,378.189270 79.482437,379.097290 78.857506,380.354858 
	C78.465492,382.469208 78.229637,384.233978 77.620712,385.999329 
	C76.502220,386.002686 75.756798,386.005524 75.011375,386.008331 
	C76.692406,381.307678 78.421555,376.623474 80.037651,371.900574 
	C81.125145,368.722504 81.867630,365.414368 83.129372,362.312897 
	C83.769257,360.740051 85.216858,359.495819 86.644585,358.046997 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M488.998230,1028.009766 
	C483.979919,1028.004272 478.961639,1027.998657 473.473633,1027.618164 
	C473.002594,1026.495361 473.001312,1025.747681 473.467224,1024.996094 
	C480.290100,1025.666260 486.645721,1026.340454 492.768311,1027.285400 
	C491.356262,1027.707397 490.177246,1027.858521 488.998230,1028.009766 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M64.620377,425.087891 
	C63.259411,433.625885 61.511524,442.244598 59.396427,450.944214 
	C60.763962,442.406281 62.498703,433.787445 64.620377,425.087891 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M606.987793,1027.990234 
	C602.635437,1027.995361 598.283081,1028.000610 593.128296,1028.002930 
	C591.550293,1027.670044 590.774597,1027.340210 590.248413,1026.720703 
	C594.994568,1025.949097 599.491150,1025.467163 604.365112,1024.990723 
	C605.496887,1025.000732 606.251404,1025.005249 607.004395,1025.382446 
	C606.997925,1026.500122 606.992859,1027.245239 606.987793,1027.990234 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M58.673733,453.189758 
	C59.013977,455.809021 59.390663,458.685760 58.912037,461.412384 
	C58.175037,465.610962 56.851425,469.706512 55.398800,473.932343 
	C56.122932,467.139252 57.224945,460.259644 58.673733,453.189758 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M70.625656,406.050049 
	C69.238937,412.276276 67.495773,418.564240 65.380203,424.929443 
	C66.709557,418.693878 65.067375,411.449951 70.625656,406.050049 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M138.647980,261.035400 
	C135.897812,265.635834 132.796219,270.271667 129.357437,274.960571 
	C132.112335,270.366058 135.204437,265.718445 138.647980,261.035400 
z"/>
<path fill="#E2E2E2" opacity="1.000000" stroke="none" 
	d="
M634.030151,1019.029297 
	C631.276489,1020.350037 628.522827,1021.670715 625.383423,1023.001831 
	C624.248535,1023.010254 623.499512,1023.008362 622.122131,1022.999756 
	C621.324585,1022.985474 621.155396,1022.977905 621.052002,1022.628052 
	C622.415894,1021.206665 623.714050,1020.127625 625.396790,1019.035278 
	C628.184326,1019.010681 630.587219,1018.999268 633.250244,1018.995117 
	C633.683655,1019.011414 633.856873,1019.020325 634.030151,1019.029297 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M89.003586,729.407104 
	C91.002686,732.738159 92.998421,736.482483 94.989067,740.619507 
	C88.832710,739.503967 90.327248,733.907837 89.003586,729.407104 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M89.013870,723.985352 
	C89.011635,725.376587 89.009399,726.767822 89.006912,728.576172 
	C87.005371,724.929688 85.004082,720.866150 83.008972,716.393860 
	C84.010262,716.993958 85.118919,717.916443 85.969475,719.035095 
	C86.870995,720.220825 87.527313,721.592957 88.649002,723.187561 
	C89.007263,723.491455 89.013878,723.985352 89.013870,723.985352 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M99.021896,753.356323 
	C100.244911,753.818848 101.955994,754.394653 102.588341,755.557190 
	C104.280357,758.667725 105.509689,762.029907 106.955811,765.650146 
	C105.322166,764.082336 103.436203,762.300171 102.052917,760.188660 
	C100.763382,758.220276 100.008385,755.901672 99.021896,753.356323 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M123.001945,796.375488 
	C125.667068,799.740784 128.336227,803.479065 131.006912,807.604492 
	C128.340958,804.243958 125.673470,800.496277 123.001945,796.375488 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M104.648109,317.060699 
	C103.189140,321.202271 101.374008,325.399658 99.284485,329.798523 
	C98.734367,329.295319 98.021431,328.361450 98.251762,327.922028 
	C100.166649,324.268555 102.255585,320.706329 104.648109,317.060699 
z"/>
<path fill="#F2F2F2" opacity="1.000000" stroke="none" 
	d="
M589.998901,1027.010376 
	C590.774597,1027.340210 591.550293,1027.670044 592.663879,1028.004883 
	C590.674744,1028.966187 588.397522,1030.087036 586.000793,1030.813843 
	C584.680481,1031.214478 583.159302,1030.952759 581.359436,1030.989990 
	C582.945801,1029.910645 584.901428,1028.831177 587.306763,1027.379883 
	C588.504028,1027.008911 589.251465,1027.009644 589.998901,1027.010376 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M113.001633,778.395508 
	C114.998596,781.083923 116.991783,784.172791 118.983795,787.636658 
	C114.984474,786.240662 112.901505,783.225891 113.001633,778.395508 
z"/>
<path fill="#F2F2F2" opacity="1.000000" stroke="none" 
	d="
M607.399414,1027.985107 
	C606.992859,1027.245239 606.997925,1026.500122 607.341858,1025.377930 
	C608.455200,1024.670410 609.229675,1024.340088 610.378540,1024.008423 
	C611.502014,1024.007935 612.250977,1024.008789 613.171753,1024.250977 
	C614.562317,1024.661377 615.781128,1024.830688 617.000000,1025.000000 
	C613.937012,1025.993286 610.874023,1026.986572 607.399414,1027.985107 
z"/>
<path fill="#F2F2F2" opacity="1.000000" stroke="none" 
	d="
M489.067688,1028.371582 
	C490.177246,1027.858521 491.356262,1027.707397 493.099976,1027.281494 
	C494.106781,1027.010986 494.548920,1027.015259 494.991028,1027.019531 
	C496.946075,1028.099365 498.901154,1029.179199 500.933197,1030.624634 
	C497.052521,1030.237915 493.094818,1029.485596 489.067688,1028.371582 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M128.650330,276.039246 
	C126.566948,279.968994 124.132057,283.935577 121.355698,287.956299 
	C123.442436,284.032288 125.870644,280.054169 128.650330,276.039246 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M62.734520,649.242188 
	C63.679749,651.371704 64.344521,653.747742 65.010849,656.556274 
	C64.159622,654.488770 63.306843,651.988831 62.734520,649.242188 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M73.004089,398.001709 
	C72.553246,399.884247 72.102417,401.766815 71.325211,403.824677 
	C71.429840,401.706512 71.860847,399.413055 72.646393,397.310059 
	C73.001991,397.667603 73.003044,397.834656 73.004089,398.001709 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M95.008942,743.372375 
	C96.340744,745.074341 97.667053,747.162659 98.995926,749.625488 
	C97.666809,747.919556 96.335129,745.839172 95.008942,743.372375 
z"/>
<path fill="#F2F2F2" opacity="1.000000" stroke="none" 
	d="
M473.000000,1025.000000 
	C473.001312,1025.747681 473.002594,1026.495361 473.007446,1027.616699 
	C470.720093,1027.237915 468.429199,1026.485596 466.068420,1025.371460 
	C467.069763,1025.005981 468.141052,1025.002197 469.986694,1024.994141 
	C471.507416,1024.993286 472.253693,1024.996704 473.000000,1025.000000 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M107.010529,768.366821 
	C108.319351,769.749329 109.621819,771.515015 110.958252,773.643188 
	C109.662880,772.253845 108.333527,770.501953 107.010529,768.366821 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M131.007263,808.384155 
	C132.317032,809.428772 133.623016,810.865906 134.958405,812.656372 
	C131.942001,813.215576 130.776947,811.651062 131.007263,808.384155 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M97.657440,332.045532 
	C97.213112,333.901337 96.417389,335.791199 95.314514,337.840546 
	C93.014961,334.955475 94.919182,333.423859 97.657440,332.045532 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M119.015823,790.354614 
	C120.343231,791.736755 121.672295,793.487671 122.999466,795.620483 
	C121.670868,794.242798 120.344170,792.483093 119.015823,790.354614 
z"/>
<path fill="#E2E2E2" opacity="1.000000" stroke="none" 
	d="
M75.008705,386.407288 
	C75.756798,386.005524 76.502220,386.002686 77.873383,386.000061 
	C78.667511,386.000519 78.835907,386.000854 78.980362,386.320496 
	C78.641586,387.427979 78.326744,388.216217 77.765717,389.155273 
	C77.013893,389.870422 76.508263,390.434692 75.782959,390.890930 
	C75.290367,390.582550 75.102135,390.321167 74.998589,389.998779 
	C75.001068,388.934601 75.003555,387.870422 75.008705,386.407288 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M617.333252,1025.000000 
	C615.781128,1024.830688 614.562317,1024.661377 613.222290,1023.930542 
	C615.399963,1023.245178 617.698792,1023.121338 619.891968,1023.257324 
	C619.524048,1024.011475 619.261780,1024.505737 618.999512,1025.000000 
	C618.555115,1025.000000 618.110840,1025.000000 617.333252,1025.000000 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M93.636017,341.046082 
	C93.250526,342.641235 92.498001,344.278931 91.380966,345.964233 
	C91.767303,344.370789 92.518143,342.729706 93.636017,341.046082 
z"/>
<path fill="#E2E2E2" opacity="1.000000" stroke="none" 
	d="
M73.339249,398.001068 
	C73.003044,397.834656 73.001991,397.667603 73.000107,397.249908 
	C72.999924,396.251190 73.000580,395.503113 73.264145,394.272156 
	C74.019630,393.529449 74.512207,393.269592 75.003960,393.388428 
	C75.013832,394.526245 75.024536,395.285431 75.032135,396.370148 
	C75.025276,397.129730 75.021500,397.563782 75.017731,397.997803 
	C74.569954,397.998657 74.122177,397.999542 73.339249,398.001068 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M75.004791,393.009766 
	C74.512207,393.269592 74.019630,393.529449 73.270264,393.898285 
	C73.436333,392.714478 73.859184,391.421692 74.640312,390.063843 
	C75.102135,390.321167 75.290367,390.582550 75.761047,391.189178 
	C75.640816,392.066864 75.322807,392.538330 75.004791,393.009766 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M141.643646,257.039337 
	C141.198196,258.238739 140.396393,259.477509 139.296997,260.858154 
	C139.762039,259.692902 140.524689,258.385773 141.643646,257.039337 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M108.731468,309.188080 
	C108.552094,310.305023 108.103874,311.610077 107.331017,312.960114 
	C107.491791,311.795471 107.977211,310.585815 108.731468,309.188080 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M144.666962,252.052063 
	C144.231079,253.306946 143.457001,254.607391 142.346756,255.957977 
	C142.783325,254.704605 143.556030,253.401138 144.666962,252.052063 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M54.759186,476.204803 
	C55.192913,477.192261 55.360119,478.386444 55.266689,479.790283 
	C54.834923,478.803833 54.663792,477.607666 54.759186,476.204803 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M135.011322,814.345337 
	C135.670258,814.758118 136.330292,815.525818 136.993973,816.647095 
	C136.335892,816.233887 135.674179,815.467102 135.011322,814.345337 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M137.003143,817.356323 
	C137.662689,817.766235 138.326584,818.530029 138.993103,819.648132 
	C138.332977,819.238342 137.670242,818.474243 137.003143,817.356323 
z"/>
<path fill="#F2F2F2" opacity="1.000000" stroke="none" 
	d="
M154.658707,238.054504 
	C154.567749,238.968307 154.131210,239.934006 153.350616,240.954071 
	C153.442078,240.041092 153.877609,239.073746 154.658707,238.054504 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M161.003174,848.996094 
	C160.327164,848.246033 159.664993,847.482361 158.994537,846.366699 
	C158.986237,846.014648 158.997498,846.002441 159.260345,846.107300 
	C160.021347,846.468689 160.519516,846.725159 161.022690,847.310913 
	C161.033295,848.078613 161.038925,848.517029 161.044556,848.955383 
	C161.044556,848.955383 161.017044,848.982483 161.003174,848.996094 
z"/>
<path fill="#F2F2F2" opacity="1.000000" stroke="none" 
	d="
M148.656845,246.053833 
	C148.529617,246.909637 148.056793,247.818069 147.295807,248.870209 
	C147.442169,248.044785 147.876709,247.075623 148.656845,246.053833 
z"/>
<path fill="#F2F2F2" opacity="1.000000" stroke="none" 
	d="
M619.352661,1024.995117 
	C619.261780,1024.505737 619.524048,1024.011475 620.139160,1023.251892 
	C620.492004,1022.986633 620.986206,1022.970276 620.986206,1022.970276 
	C621.155396,1022.977905 621.324585,1022.985474 621.747559,1023.005127 
	C621.236206,1023.674805 620.471008,1024.332397 619.352661,1024.995117 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M106.679001,314.041687 
	C106.552368,314.641235 106.104431,315.282471 105.337547,315.968567 
	C105.464966,315.370087 105.911331,314.726715 106.679001,314.041687 
z"/>
<path fill="#F2F2F2" opacity="1.000000" stroke="none" 
	d="
M152.685577,241.045319 
	C152.554932,241.646057 152.101334,242.285995 151.327698,242.971100 
	C151.459320,242.372375 151.910965,241.728439 152.685577,241.045319 
z"/>
<path fill="#F2F2F2" opacity="1.000000" stroke="none" 
	d="
M178.673981,209.004257 
	C178.554703,209.645309 178.100937,210.283844 177.327499,210.965912 
	C177.005508,210.563248 177.003189,210.117065 177.001343,209.336349 
	C177.447723,209.001816 177.893600,209.001785 178.673981,209.004257 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M111.044022,775.314453 
	C111.643311,775.449524 112.279823,775.906433 112.953598,776.684875 
	C112.354332,776.549744 111.717789,776.093018 111.044022,775.314453 
z"/>
<path fill="#E2E2E2" opacity="1.000000" stroke="none" 
	d="
M934.007324,249.994415 
	C935.242249,250.806503 936.477234,251.618607 937.858276,253.071472 
	C938.664734,254.478287 939.325012,255.244293 939.985352,256.010315 
	C942.297180,259.768799 944.608948,263.527313 946.994873,267.988464 
	C948.375305,270.130493 949.681519,271.569855 950.987793,273.009247 
	C950.987793,273.009247 950.992798,273.504028 951.032471,274.105286 
	C953.381470,278.137268 955.690735,281.568024 958.000000,284.998779 
	C958.102478,285.321136 958.290039,285.582306 958.820923,286.211334 
	C959.710388,287.099579 960.341675,287.558807 960.973022,288.018066 
	C964.384827,294.807617 967.794678,301.598145 971.208923,308.386444 
	C977.177551,320.253174 983.308289,332.041962 989.058044,344.013733 
	C992.414551,351.002563 995.524780,358.155670 998.102356,365.461884 
	C1002.022644,376.574310 1005.503540,387.847443 1008.942505,399.122070 
	C1011.381775,407.119049 1013.718506,415.167267 1015.608521,423.306976 
	C1018.309692,434.939880 1020.588684,446.671539 1022.987915,458.373352 
	C1023.754333,462.111176 1024.529663,465.865692 1024.954956,469.651428 
	C1026.079468,479.661438 1026.324707,489.822418 1028.179565,499.686462 
	C1031.336182,516.473145 1031.416260,533.365845 1030.833618,550.235046 
	C1030.326660,564.912720 1028.754028,579.580322 1027.052124,594.182373 
	C1025.437134,608.038330 1023.067261,621.807251 1020.976440,635.606384 
	C1020.384155,639.515137 1019.689331,643.414062 1018.836731,647.272217 
	C1018.685608,647.955872 1017.522705,648.415894 1016.143433,648.753052 
	C1015.444153,647.742065 1015.292603,646.931763 1015.433472,646.175964 
	C1017.249451,636.436462 1019.106995,626.704712 1020.967773,616.544800 
	C1020.994080,614.408264 1021.006836,612.697937 1021.078125,610.643188 
	C1021.727539,608.847046 1022.318298,607.395325 1022.927795,605.564941 
	C1022.881958,604.429016 1022.817505,603.671753 1022.791992,602.611084 
	C1022.852966,601.901672 1022.874939,601.495605 1023.071289,600.731567 
	C1023.822388,593.908020 1024.398926,587.442444 1024.979004,580.604614 
	C1024.981201,579.486816 1024.980225,578.741333 1025.270752,577.735107 
	C1026.039917,574.390930 1026.911133,571.310059 1026.931030,568.223694 
	C1027.057129,548.633240 1027.066772,529.040894 1026.905884,509.450958 
	C1026.885498,506.963928 1025.648682,504.486908 1024.979004,501.632568 
	C1024.979858,500.514771 1024.977173,499.769592 1024.987183,498.598999 
	C1024.363892,496.113892 1023.727783,494.054199 1023.074036,491.558014 
	C1023.049805,489.083954 1023.043213,487.046387 1023.318726,484.726776 
	C1023.400513,481.014435 1023.486694,477.538544 1022.948975,474.162018 
	C1020.933533,461.505646 1019.184082,448.778351 1016.433044,436.276093 
	C1013.923096,424.868958 1010.303284,413.702087 1007.063354,402.460815 
	C1004.419800,393.288727 1001.729065,384.125946 998.774109,375.051910 
	C998.253601,373.453613 996.328003,372.312897 995.038940,370.634521 
	C995.016479,369.872406 995.006287,369.436646 995.250122,368.785919 
	C993.723389,363.441437 992.175354,358.213989 990.073425,353.219635 
	C988.839172,350.286957 986.723877,347.725067 984.992371,344.754578 
	C984.979797,344.512756 984.967346,344.028625 984.976562,343.644226 
	C981.484497,334.904175 978.167664,326.462921 974.355835,318.251465 
	C973.370483,316.128784 970.832153,314.726929 969.003296,312.667297 
	C968.986206,311.905304 968.973022,311.470062 968.970337,310.667664 
	C967.991699,309.196655 967.002563,308.092712 966.004761,306.747986 
	C965.996033,306.507233 965.971924,306.026031 965.979187,305.656250 
	C964.991760,303.856873 963.997131,302.427338 962.992310,300.757568 
	C962.982117,300.517365 962.955933,300.037231 962.960327,299.659302 
	C959.301880,293.028503 955.694885,286.741394 951.908508,280.564209 
	C951.411255,279.752869 949.995728,279.504364 949.001282,278.751373 
	C948.997070,278.507568 948.978271,278.019745 948.983337,277.662933 
	C948.321838,276.540863 947.655273,275.775604 946.742371,275.005676 
	C946.496033,275.001007 946.003296,274.998352 945.968140,274.630493 
	C943.819702,268.079468 940.875061,262.490509 934.979370,258.632843 
	C932.959351,255.191162 930.974670,252.102005 928.991089,248.646393 
	C928.331421,246.517166 927.670593,244.754395 927.353638,243.025757 
	C928.794434,243.376312 929.891296,243.692703 930.988098,244.009094 
	C931.254150,244.500137 931.520142,244.991180 931.895264,246.089661 
	C932.672058,247.796204 933.339661,248.895325 934.007324,249.994415 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M907.988098,860.967102 
	C907.558960,860.987000 907.129822,861.006897 906.035278,861.063843 
	C896.821533,869.993164 888.310486,878.921875 879.685120,887.738708 
	C878.377991,889.074829 876.538635,889.890320 874.988281,890.606689 
	C876.393311,888.627808 877.694885,886.933655 879.127502,885.358765 
	C889.481201,873.977539 899.901306,862.655945 910.156982,851.187195 
	C910.973999,850.273376 910.710510,848.393311 911.094971,846.680298 
	C912.463318,845.237671 913.685791,844.076965 915.255798,842.906921 
	C918.773254,839.397278 922.346924,836.180481 925.039917,832.345215 
	C934.401733,819.012695 943.986389,805.789001 952.480469,791.908386 
	C960.815979,778.287109 967.908264,763.906067 975.581543,749.877869 
	C977.965942,745.518860 980.466248,741.223328 982.939575,737.308228 
	C982.977112,738.811829 982.988586,739.905884 982.690125,741.157349 
	C981.594055,743.218628 980.807861,745.122620 980.017273,747.352844 
	C979.998413,748.113831 979.983948,748.548645 979.613525,749.029297 
	C977.510864,752.723816 975.764160,756.372437 973.857788,760.366455 
	C973.441040,762.453369 973.183838,764.194885 972.926575,765.936401 
	C972.612122,766.051392 972.357483,766.245056 971.704590,766.790649 
	C970.300415,768.118652 969.132263,769.046509 968.445129,770.249207 
	C964.893127,776.465942 961.320984,782.679260 958.045959,789.043091 
	C956.075073,792.872925 954.633972,796.975342 952.609985,801.003235 
	C949.938660,803.921021 947.401306,806.647156 945.326782,809.688904 
	C940.086731,817.372009 935.054932,825.197144 929.706116,832.973145 
	C929.472595,832.981018 929.004883,833.011047 928.683350,833.050232 
	C927.914795,833.729797 927.467712,834.370178 927.011536,835.005249 
	C927.002441,835.000000 927.011047,835.017883 926.717773,835.151184 
	C925.954590,836.192078 925.484619,837.099731 924.746826,838.128052 
	C923.990906,838.840942 923.502747,839.433289 922.720459,840.157104 
	C921.952515,841.192871 921.478699,842.097046 920.995605,843.243408 
	C920.986267,843.485596 920.978149,843.970154 920.635254,844.029297 
	C919.853210,845.056274 919.414062,846.024109 918.772217,847.262329 
	C915.711304,850.685791 912.853271,853.838867 909.674194,857.033508 
	C908.905457,857.718384 908.457703,858.361572 907.999268,859.331909 
	C907.988403,860.095093 907.988281,860.531128 907.988098,860.967102 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M983.000000,741.000000 
	C982.988586,739.905884 982.977112,738.811829 982.955078,736.954346 
	C982.980347,735.478516 983.016174,734.766174 983.367920,733.994751 
	C984.098083,733.276611 984.512268,732.617493 984.948120,731.594116 
	C984.994080,730.500610 985.018372,729.771240 985.387939,728.970581 
	C986.005737,728.722717 986.435791,728.605774 986.528137,728.361328 
	C992.678345,712.081299 998.869507,695.815857 1004.835327,679.468201 
	C1005.537415,677.544678 1004.987976,675.164307 1005.027832,672.672363 
	C1005.046448,671.917786 1005.056030,671.486572 1005.457520,671.025024 
	C1006.527954,668.969910 1007.206482,666.945007 1007.906799,664.539429 
	C1008.260193,662.435181 1008.591736,660.711609 1009.231018,658.750610 
	C1011.327271,649.996765 1013.115784,641.480408 1014.930298,632.636841 
	C1014.972900,631.872070 1014.989563,631.434448 1015.289917,630.771484 
	C1015.668518,629.346313 1015.763245,628.146545 1015.881897,626.620605 
	C1015.930420,625.858826 1015.955078,625.423279 1016.276428,624.961853 
	C1016.704651,624.287292 1016.836182,623.638611 1016.980408,622.661621 
	C1017.004089,621.895630 1017.015076,621.457886 1017.371338,620.954407 
	C1018.795837,619.582703 1019.874939,618.276855 1020.954102,616.971008 
	C1019.106995,626.704712 1017.249451,636.436462 1015.433472,646.175964 
	C1015.292603,646.931763 1015.444153,647.742065 1015.737305,648.765320 
	C1015.997559,649.438538 1015.980286,649.873413 1015.588196,650.725647 
	C1013.810730,657.429810 1012.408142,663.716675 1011.005493,670.003540 
	C1011.005493,670.003540 1011.000000,670.000000 1010.628784,670.075195 
	C1009.708557,671.945190 1009.209778,673.757202 1008.602539,675.532104 
	C1004.069519,688.782410 999.690186,702.089111 994.902466,715.247070 
	C991.949707,723.361877 988.393921,731.259216 985.013000,739.213501 
	C984.695862,739.959412 983.687500,740.411560 983.000000,741.000000 
z"/>
<path fill="#E2E2E2" opacity="1.000000" stroke="none" 
	d="
M983.000000,741.000000 
	C983.687500,740.411560 984.695862,739.959412 985.013000,739.213501 
	C988.393921,731.259216 991.949707,723.361877 994.902466,715.247070 
	C999.690186,702.089111 1004.069519,688.782410 1008.602539,675.532104 
	C1009.209778,673.757202 1009.708557,671.945190 1010.631226,670.077942 
	C1008.838928,678.935669 1006.850586,687.913940 1004.450134,696.780640 
	C1002.570496,703.723511 1000.311279,710.573364 997.965332,717.376526 
	C996.672546,721.125427 994.852905,724.692627 992.770264,728.733398 
	C990.846497,732.750000 989.423279,736.375000 988.000061,740.000000 
	C984.619263,747.666870 981.352661,755.388062 977.751526,762.949890 
	C977.111084,764.294739 975.080933,764.977661 973.309570,765.951294 
	C973.183838,764.194885 973.441040,762.453369 974.204224,760.306152 
	C976.463318,756.261475 978.216431,752.622437 979.969482,748.983398 
	C979.983948,748.548645 979.998413,748.113831 980.372253,747.286987 
	C981.487793,744.929932 982.243896,742.964966 983.000000,741.000000 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M854.002563,164.996323 
	C856.408508,166.238480 859.099426,167.126190 861.167786,168.788452 
	C867.670105,174.014114 873.938477,179.530930 880.643799,184.971954 
	C880.838623,190.395920 886.137207,191.685913 888.755310,194.931534 
	C893.850098,201.247452 899.908813,206.776932 905.399414,212.786377 
	C908.124573,215.769073 910.475647,219.093643 912.994568,222.635239 
	C912.993164,223.006897 912.980408,223.019547 912.609741,223.014954 
	C911.496765,223.001572 910.754456,222.992767 910.004700,222.621613 
	C909.000549,221.170898 908.003906,220.082504 906.955383,218.659317 
	C900.088745,210.926285 893.346680,203.458710 886.398193,196.188370 
	C885.008179,194.733917 882.831665,194.031189 880.977051,192.631836 
	C879.554382,190.488907 878.391174,188.458054 876.749146,186.947968 
	C871.934937,182.520493 866.930115,178.300217 861.966309,173.658447 
	C860.954285,172.546432 859.975891,171.774750 858.779297,170.893463 
	C858.289062,170.583908 858.101196,170.323212 857.860535,169.710052 
	C856.483154,167.944290 855.242859,166.470306 854.002563,164.996323 
z"/>
<path fill="#DEDEDE" opacity="1.000000" stroke="none" 
	d="
M952.954834,800.955322 
	C954.633972,796.975342 956.075073,792.872925 958.045959,789.043091 
	C961.320984,782.679260 964.893127,776.465942 968.445129,770.249207 
	C969.132263,769.046509 970.300415,768.118652 971.597534,767.008606 
	C971.962891,767.125854 971.977417,767.298462 971.992310,768.101135 
	C971.662537,770.154968 971.332458,771.578674 971.002441,773.002441 
	C970.997437,773.442383 970.992432,773.882263 970.641357,774.708923 
	C969.213013,776.498230 968.000854,777.820129 967.071045,779.317444 
	C963.010193,785.856445 959.031372,792.446289 955.022766,799.017700 
	C955.018188,799.458191 955.013550,799.898743 954.674255,800.669739 
	C953.897888,801.005066 953.456299,801.009888 952.999878,800.999878 
	C952.985046,800.985107 952.954834,800.955322 952.954834,800.955322 
z"/>
<path fill="#DEDEDE" opacity="1.000000" stroke="none" 
	d="
M929.939636,832.965332 
	C935.054932,825.197144 940.086731,817.372009 945.326782,809.688904 
	C947.401306,806.647156 949.938660,803.921021 952.610046,801.003235 
	C952.954834,800.955322 952.985046,800.985107 952.990234,801.367065 
	C952.660950,803.162903 952.326538,804.576904 951.992126,805.990845 
	C951.992126,805.990845 952.000000,806.000000 951.689941,806.153259 
	C949.250977,809.869507 947.122009,813.432495 944.993042,816.995483 
	C944.993103,816.995483 944.981506,817.487427 944.643677,817.788391 
	C942.472534,820.111511 940.450806,821.997253 938.860229,824.195068 
	C937.340149,826.295532 936.268677,828.720703 935.000000,831.003113 
	C935.000000,831.003113 934.508057,831.011597 933.991333,831.127197 
	C932.981445,831.827759 932.488281,832.412659 931.652832,832.996948 
	C930.853638,832.986023 930.396606,832.975647 929.939636,832.965332 
z"/>
<path fill="#E2E2E2" opacity="1.000000" stroke="none" 
	d="
M909.995117,856.991821 
	C912.853271,853.838867 915.711304,850.685791 919.067993,847.126343 
	C920.037109,845.803345 920.507629,844.886719 920.978149,843.970154 
	C920.978149,843.970154 920.986267,843.485596 921.338806,843.191895 
	C922.132446,841.940674 922.573486,840.983154 923.014526,840.025635 
	C923.502747,839.433289 923.990906,838.840942 925.000183,838.133301 
	C925.690002,838.026306 925.858826,838.034790 926.021973,838.468140 
	C926.009277,840.592041 926.002197,842.291138 925.995117,843.990234 
	C925.995117,843.990234 926.000000,844.000000 925.675598,844.039795 
	C924.900757,844.718872 924.450378,845.358215 924.000000,845.997559 
	C923.678040,846.101929 923.417297,846.290771 922.787842,846.823120 
	C921.905273,847.721436 921.452698,848.360718 921.000000,849.000000 
	C921.000000,849.000000 920.991211,849.494629 920.634033,849.784546 
	C919.513062,851.376465 918.749207,852.678528 917.985352,853.980530 
	C916.995300,854.740173 916.005249,855.499817 915.006470,856.629761 
	C912.785034,858.492859 910.572449,859.985657 908.173950,861.222778 
	C907.988281,860.531128 907.988403,860.095093 908.320068,859.290588 
	C909.099426,858.278625 909.547241,857.635254 909.995117,856.991821 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M955.392456,799.008423 
	C959.031372,792.446289 963.010193,785.856445 967.071045,779.317444 
	C968.000854,777.820129 969.213013,776.498230 970.637939,775.038818 
	C967.919739,781.041443 965.079651,787.228455 961.688660,793.097107 
	C960.341980,795.427734 957.774109,797.052856 955.392456,799.008423 
z"/>
<path fill="#E2E2E2" opacity="1.000000" stroke="none" 
	d="
M919.986450,230.012421 
	C920.958862,231.458954 921.931335,232.905472 922.949707,235.011688 
	C922.993774,236.117172 922.992004,236.562973 922.647217,236.974579 
	C921.210510,236.622971 920.116699,236.305557 918.719849,235.838928 
	C915.948669,232.460052 913.480469,229.230377 911.271301,225.899139 
	C912.016113,225.531998 912.501953,225.266434 913.323242,225.001160 
	C914.105713,225.001190 914.552856,225.000900 915.000000,225.000610 
	C915.000000,225.000610 915.000000,225.000000 915.004517,225.373779 
	C916.668213,227.169174 918.327332,228.590790 919.986450,230.012421 
z"/>
<path fill="#E2E2E2" opacity="1.000000" stroke="none" 
	d="
M1011.333679,669.829224 
	C1012.408142,663.716675 1013.810730,657.429810 1015.582275,651.052063 
	C1014.521362,657.192383 1013.091614,663.423584 1011.333679,669.829224 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M935.110413,831.221924 
	C936.268677,828.720703 937.340149,826.295532 938.860229,824.195068 
	C940.450806,821.997253 942.472534,820.111511 944.641968,818.034424 
	C942.959534,821.169800 940.946838,824.363831 938.918701,827.548035 
	C938.318054,828.491028 937.673950,829.406372 936.799927,830.783569 
	C936.293945,831.419495 936.120239,831.664673 936.028992,831.968323 
	C935.697876,831.887024 935.428467,831.711182 935.110413,831.221924 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M922.990234,237.008759 
	C922.992004,236.562973 922.993774,236.117172 922.996582,235.337097 
	C925.640442,237.771179 928.283325,240.539551 930.957153,243.658508 
	C929.891296,243.692703 928.794434,243.376312 927.328369,242.729721 
	C926.638611,241.933823 926.318115,241.468140 925.964355,240.662170 
	C924.956482,239.545624 923.981934,238.769363 923.003479,237.746994 
	C922.999634,237.500900 922.990234,237.008759 922.990234,237.008759 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M926.371948,843.984985 
	C926.002197,842.291138 926.009277,840.592041 926.266724,838.455566 
	C926.517029,838.018188 927.007324,838.004883 927.333130,837.976196 
	C928.775818,837.300903 929.892822,836.654358 931.009766,836.007812 
	C931.143433,839.495667 930.417725,842.524292 926.371948,843.984985 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M957.999512,284.613708 
	C955.690735,281.568024 953.381470,278.137268 951.036133,274.352661 
	C956.120911,275.501282 956.163452,280.478333 957.999512,284.613708 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M988.390869,739.988647 
	C989.423279,736.375000 990.846497,732.750000 992.623901,729.055420 
	C991.579224,732.649719 990.180420,736.313538 988.390869,739.988647 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M945.298218,816.852173 
	C947.122009,813.432495 949.250977,809.869507 951.685547,806.150513 
	C952.041992,810.865662 949.027954,813.909607 945.298218,816.852173 
z"/>
<path fill="#E2E2E2" opacity="1.000000" stroke="none" 
	d="
M931.117432,835.788574 
	C929.892822,836.654358 928.775818,837.300903 927.331177,837.602478 
	C927.006042,836.510986 927.008545,835.764404 927.011047,835.017883 
	C927.011047,835.017883 927.002441,835.000000 927.329590,834.961914 
	C928.106140,834.286255 928.555542,833.648621 929.004883,833.011047 
	C929.004883,833.011047 929.472595,832.981018 929.706116,832.973145 
	C930.396606,832.975647 930.853638,832.986023 931.654541,833.331787 
	C932.001465,834.113525 932.004395,834.559998 932.007324,835.006409 
	C931.685303,835.108826 931.424561,835.296448 931.117432,835.788574 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M919.846069,229.707611 
	C918.327332,228.590790 916.668213,227.169174 915.004517,225.374084 
	C916.568542,226.468002 918.137085,227.935394 919.846069,229.707611 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M971.359009,772.963501 
	C971.332458,771.578674 971.662537,770.154968 972.002502,768.360718 
	C972.940796,768.398010 973.869141,768.805847 974.797546,769.213684 
	C973.770203,770.450623 972.742859,771.687561 971.359009,772.963501 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M952.334290,805.959473 
	C952.326538,804.576904 952.660950,803.162903 953.005005,801.381836 
	C953.456299,801.009888 953.897888,801.005066 954.671021,801.000122 
	C954.227112,802.642761 953.451782,804.285461 952.334290,805.959473 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M950.988525,272.643066 
	C949.681519,271.569855 948.375305,270.130493 947.035767,268.344360 
	C948.331360,269.424011 949.660278,270.850433 950.988525,272.643066 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M918.343506,853.947388 
	C918.749207,852.678528 919.513062,851.376465 920.631104,850.031860 
	C920.224182,851.297607 919.462952,852.605957 918.343506,853.947388 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M932.364258,834.940552 
	C932.004395,834.559998 932.001465,834.113525 931.996826,833.332336 
	C932.488281,832.412659 932.981445,831.827759 933.745361,831.131287 
	C933.584412,832.304688 933.152771,833.589722 932.364258,834.940552 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M934.005249,249.619019 
	C933.339661,248.895325 932.672058,247.796204 932.003418,246.347748 
	C932.669373,247.080139 933.336304,248.161850 934.005249,249.619019 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M939.965454,255.687531 
	C939.325012,255.244293 938.664734,254.478287 937.998535,253.359375 
	C938.643616,253.792557 939.294617,254.578644 939.965454,255.687531 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M960.941528,287.694824 
	C960.341675,287.558807 959.710388,287.099579 959.039551,286.320770 
	C959.636658,286.458008 960.273376,286.914764 960.941528,287.694824 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M914.958984,224.680130 
	C914.552856,225.000900 914.105713,225.001190 913.323059,224.670761 
	C912.985168,223.899872 912.982788,223.459717 912.980408,223.019547 
	C912.980408,223.019547 912.993164,223.006897 913.000244,223.000092 
	C913.644165,223.448761 914.281067,223.904205 914.958984,224.680130 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M924.321106,845.957397 
	C924.450378,845.358215 924.900757,844.718872 925.671021,844.037354 
	C925.541260,844.635864 925.091736,845.276550 924.321106,845.957397 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M921.321167,848.959595 
	C921.452698,848.360718 921.905273,847.721436 922.678955,847.042358 
	C922.547424,847.641357 922.094849,848.280273 921.321167,848.959595 
z"/>
<path fill="#E2E2E2" opacity="1.000000" stroke="none" 
	d="
M936.233887,831.867798 
	C936.120239,831.664673 936.293945,831.419495 936.755554,831.133850 
	C936.868347,831.337097 936.694336,831.581177 936.233887,831.867798 
z"/>
<path fill="#E2E2E2" opacity="1.000000" stroke="none" 
	d="
M333.988373,98.986305 
	C335.497681,97.580246 336.776642,95.678047 338.555359,94.852264 
	C347.202240,90.837837 355.822357,86.673409 364.758270,83.397804 
	C375.192810,79.572876 385.925568,76.551254 396.564301,73.296715 
	C405.502106,70.562508 414.389435,67.553757 423.482422,65.464233 
	C434.762756,62.872074 446.214722,61.003319 457.624908,59.007019 
	C467.110870,57.347378 476.598846,55.505676 486.166443,54.553310 
	C499.412659,53.234768 512.730408,52.608803 526.025269,51.816170 
	C532.963318,51.402527 539.934631,50.834721 546.861511,51.101124 
	C559.072815,51.570747 571.261597,52.626057 583.449341,54.014336 
	C580.959595,54.728146 578.480286,54.863777 575.625854,54.998955 
	C574.500488,54.994808 573.750305,54.991123 572.731812,54.706188 
	C569.976013,54.610664 567.488403,54.796387 564.532166,54.984894 
	C548.042236,54.985542 532.020996,54.983406 515.814453,54.735210 
	C514.752747,54.655708 513.876404,54.822266 512.600525,54.991943 
	C511.135651,54.998898 510.070282,55.002728 508.540344,55.002663 
	C504.976654,54.999901 501.859985,54.809711 498.786682,55.091976 
	C497.472168,55.212711 496.248016,56.317856 494.651611,56.979332 
	C493.878601,56.980152 493.436768,56.977333 492.799744,56.722950 
	C491.403137,56.647385 490.201691,56.823387 488.625793,57.000229 
	C487.502472,57.002232 486.753693,57.003395 485.561279,57.001953 
	C482.407104,57.660057 479.696533,58.320766 476.745178,59.142479 
	C476.313354,59.834557 476.122284,60.365631 475.566681,60.911171 
	C474.472748,60.921219 473.743317,60.916805 472.945190,60.563980 
	C471.591949,59.819157 470.307404,59.422752 468.700806,59.040867 
	C467.585815,59.370014 466.792908,59.684639 465.601318,60.000435 
	C464.139404,60.005726 463.076202,60.009842 461.693054,60.047997 
	C460.905914,60.704731 460.438660,61.327427 459.544525,61.958366 
	C456.074249,62.307434 453.030853,62.648254 449.555420,62.990204 
	C446.741180,63.646973 444.358917,64.302605 441.579376,64.966057 
	C440.122437,64.987587 439.062775,65.001305 437.627197,65.011230 
	C436.164001,65.670403 435.076782,66.333366 433.754974,67.189117 
	C432.015564,67.264244 430.510712,67.146576 428.600098,67.030624 
	C420.287140,69.135651 412.318054,71.033539 404.488312,73.393715 
	C394.451233,76.419273 384.409119,79.501053 374.587372,83.146538 
	C365.522339,86.511154 356.796021,90.777534 347.820526,94.398956 
	C343.324768,96.212914 338.606171,97.474754 333.988373,98.986305 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M333.744049,98.992340 
	C338.606171,97.474754 343.324768,96.212914 347.820526,94.398956 
	C356.796021,90.777534 365.522339,86.511154 374.587372,83.146538 
	C384.409119,79.501053 394.451233,76.419273 404.488312,73.393715 
	C412.318054,71.033539 420.287140,69.135651 428.598389,67.395798 
	C428.999908,68.491264 428.997345,69.223259 428.662415,69.972549 
	C427.886322,69.991920 427.442566,69.994003 426.830383,69.769135 
	C426.105957,70.007401 425.550049,70.472618 424.653168,70.953110 
	C422.880005,70.980133 421.447815,70.991890 419.638672,71.002502 
	C418.173889,71.660835 417.086060,72.320328 415.607788,72.995026 
	C410.975983,74.316795 406.692505,75.501541 402.500824,76.951698 
	C387.134491,82.267853 371.699432,87.408867 356.487732,93.139969 
	C347.014618,96.709030 337.729309,100.885956 328.671173,105.413208 
	C321.216644,109.138954 314.220490,113.781891 306.724548,118.047241 
	C305.961212,118.396027 305.494537,118.715927 304.647339,119.024857 
	C300.167358,121.664833 296.067963,124.315773 291.732391,126.980713 
	C291.496246,126.994720 291.024414,127.029366 290.665283,127.047035 
	C286.221344,129.499527 282.040466,131.791107 278.097656,134.437469 
	C276.448364,135.544449 275.304535,137.404556 273.697937,138.940140 
	C273.464996,138.958023 272.998169,138.997726 272.624268,138.997086 
	C268.152496,141.985931 264.054626,144.975433 259.706970,148.083893 
	C258.981628,148.487198 258.506042,148.771530 257.678955,149.074371 
	C255.549255,150.726212 253.771057,152.359528 251.754211,154.008850 
	C251.515564,154.024826 251.040131,154.077362 250.688904,154.087128 
	C246.237244,156.409302 242.136826,158.721710 238.036423,161.034119 
	C239.631500,159.277985 240.960663,157.135284 242.863388,155.826385 
	C259.186829,144.597229 275.301117,133.015518 292.114319,122.562881 
	C304.431885,114.905174 317.716461,108.805183 330.538635,101.952164 
	C331.465271,101.456902 332.135345,100.481529 332.967957,99.371582 
	C333.011200,99.013779 333.499725,98.998367 333.744049,98.992340 
z"/>
<path fill="#E2E2E2" opacity="1.000000" stroke="none" 
	d="
M800.005310,125.994110 
	C800.446411,125.997887 800.887512,126.001671 801.661865,126.380478 
	C801.999146,127.500618 802.003235,128.245728 801.745972,128.998535 
	C801.310669,129.013718 801.136597,129.021225 800.593140,129.017624 
	C796.652466,127.010216 793.077454,125.020462 789.510498,123.016251 
	C781.025330,118.248474 772.712708,113.132294 763.998535,108.828575 
	C758.671936,106.197884 752.730835,104.811333 747.043701,102.508530 
	C743.694702,100.420303 740.367615,98.689568 736.970215,96.609619 
	C730.023682,93.245422 723.272034,89.894493 716.244385,87.287651 
	C702.963135,82.361076 689.533997,77.830994 676.124451,73.258759 
	C675.266907,72.966362 674.051147,73.724312 672.672974,73.990387 
	C671.905090,73.978714 671.466248,73.970284 670.971252,73.588646 
	C668.955261,72.312874 667.079468,71.048157 665.021057,70.570824 
	C656.387512,68.568748 647.699219,66.802429 639.021484,64.623093 
	C639.008057,63.859993 639.004028,63.423576 639.168579,62.743248 
	C639.893127,62.330547 640.448975,62.161755 641.004883,61.992966 
	C643.369080,61.999092 645.733276,62.005219 648.558228,62.411308 
	C649.403442,63.477383 649.670410,64.608223 650.190002,64.739525 
	C657.440613,66.571777 664.725708,68.267372 672.002441,69.996277 
	C673.419434,69.696465 674.836426,69.396652 676.681335,69.410843 
	C679.739502,70.816254 682.369751,71.907669 685.000000,72.999084 
	C688.065186,73.665459 691.130432,74.331825 694.650146,75.354790 
	C697.406128,76.803497 699.707581,77.895607 702.009033,78.987701 
	C702.754822,78.993629 703.500671,78.999550 704.686218,79.364624 
	C706.416504,80.150269 707.707031,80.576775 708.997559,81.003281 
	C708.997559,81.003273 709.493164,81.009140 709.785461,81.331032 
	C710.721619,82.097786 711.365479,82.542641 712.009338,82.987503 
	C718.083191,84.990074 724.157043,86.992638 730.654602,89.324783 
	C731.719666,90.102287 732.361084,90.550217 733.002441,90.998138 
	C733.443420,91.002296 733.884460,91.006470 734.715088,91.360611 
	C736.737366,92.472488 738.369873,93.234390 740.002441,93.996300 
	C741.416260,94.333847 742.830078,94.671379 744.660767,95.331024 
	C745.718384,96.101730 746.359192,96.550339 747.000000,96.998947 
	C750.077515,98.332817 753.155090,99.666679 756.777832,101.314468 
	C759.879761,102.754433 762.436401,103.880478 764.993103,105.006538 
	C766.093445,105.671555 767.193726,106.336555 768.693054,107.342918 
	C770.394714,108.456184 771.697327,109.228096 773.000000,110.000008 
	C774.095947,110.335564 775.191956,110.671120 776.682556,111.330368 
	C777.718933,112.101753 778.360718,112.549446 779.002441,112.997147 
	C780.759460,113.643372 782.516541,114.289604 784.628906,114.975349 
	C784.984253,115.014862 784.995911,115.002884 785.033875,115.328659 
	C785.714600,116.100395 786.357422,116.546349 787.000183,116.992310 
	C787.815430,117.578148 788.630737,118.163986 789.774353,119.221146 
	C791.065430,120.130974 792.028076,120.569473 792.990784,121.007965 
	C792.990784,121.007965 793.000000,121.000305 793.039673,121.326141 
	C793.723755,122.096550 794.368164,122.541122 795.012634,122.985703 
	C795.452515,122.991264 795.892395,122.996819 796.698242,123.345795 
	C798.044556,124.457520 799.024902,125.225815 800.005310,125.994110 
z"/>
<path fill="#E2E2E2" opacity="1.000000" stroke="none" 
	d="
M625.002441,58.996082 
	C627.696960,59.002590 630.391541,59.009098 633.590820,59.353695 
	C635.070435,60.784988 636.045288,61.878197 636.763489,62.982056 
	C636.335693,62.997326 636.164673,63.001934 635.545288,63.004280 
	C632.409668,62.979301 629.722351,62.956585 626.992920,62.649033 
	C626.300781,62.232185 625.650757,62.100178 624.626953,61.970345 
	C623.505737,61.958847 622.758301,61.945175 621.976624,61.647026 
	C621.296570,61.232372 620.650757,61.102196 619.606934,60.975868 
	C618.147522,60.969933 617.086121,60.960152 615.961914,60.610870 
	C614.927002,59.853493 613.955078,59.435612 612.611267,59.016037 
	C611.154480,59.646523 610.069458,60.278706 608.620056,60.922066 
	C607.526733,60.921013 606.797791,60.908783 606.032471,60.610996 
	C605.668823,59.877460 605.341553,59.429478 604.924316,58.595062 
	C601.887878,57.807270 598.941467,57.405914 595.620850,57.003765 
	C594.497681,57.001781 593.748840,57.000584 592.698730,56.868492 
	C591.266602,56.816566 590.135803,56.895535 588.673584,56.977783 
	C587.900513,56.979115 587.458862,56.977158 587.013306,56.605553 
	C587.002136,55.497261 586.994995,54.758621 586.987793,54.019978 
	C595.036621,54.995899 603.076355,56.059456 611.139160,56.901718 
	C614.086121,57.209564 617.088013,56.991875 620.594849,57.373093 
	C622.417419,58.153526 623.709900,58.574806 625.002441,58.996082 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M671.941895,69.637589 
	C664.725708,68.267372 657.440613,66.571777 650.190002,64.739525 
	C649.670410,64.608223 649.403442,63.477383 649.001099,62.415749 
	C656.038391,63.886604 663.102112,65.722420 670.135376,67.668175 
	C670.813965,67.855919 671.303894,68.725723 671.941895,69.637589 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M586.613647,54.014626 
	C586.994995,54.758621 587.002136,55.497261 586.555664,56.614098 
	C582.813416,55.930527 578.661865,59.242664 576.000977,54.999413 
	C578.480286,54.863777 580.959595,54.728146 583.715454,54.307098 
	C584.741089,54.017548 585.490295,54.013412 586.613647,54.014626 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M637.020203,62.971401 
	C636.045288,61.878197 635.070435,60.784988 634.039673,59.356880 
	C636.282043,59.770901 638.580322,60.519817 640.941772,61.630852 
	C640.448975,62.161755 639.893127,62.330547 638.838501,62.744484 
	C637.899902,62.983551 637.460022,62.977478 637.020203,62.971401 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M764.940796,104.654404 
	C762.436401,103.880478 759.879761,102.754433 757.158936,101.318069 
	C759.626038,102.105927 762.257263,103.204102 764.940796,104.654404 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M684.940491,72.646545 
	C682.369751,71.907669 679.739502,70.816254 677.044678,69.376442 
	C679.613708,70.116699 682.247314,71.205353 684.940491,72.646545 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M701.950806,78.640289 
	C699.707581,77.895607 697.406128,76.803497 695.045410,75.364609 
	C697.288269,76.109505 699.590454,77.201195 701.950806,78.640289 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M739.957764,93.629974 
	C738.369873,93.234390 736.737366,92.472488 735.045898,91.363480 
	C736.628967,91.765465 738.270996,92.514557 739.957764,93.629974 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M772.960815,109.643265 
	C771.697327,109.228096 770.394714,108.456184 769.041016,107.348236 
	C770.300537,107.770302 771.611023,108.528412 772.960815,109.643265 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M624.936279,58.637825 
	C623.709900,58.574806 622.417419,58.153526 621.054810,57.376320 
	C622.279846,57.440117 623.575012,57.859840 624.936279,58.637825 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M708.933838,80.644287 
	C707.707031,80.576775 706.416504,80.150269 705.059082,79.366547 
	C706.284851,79.434647 707.577515,79.859978 708.933838,80.644287 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M711.964111,82.667816 
	C711.365479,82.542641 710.721619,82.097786 710.033264,81.333076 
	C710.632141,81.458191 711.275452,81.903160 711.964111,82.667816 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M732.960083,90.676903 
	C732.361084,90.550217 731.719666,90.102287 731.032349,89.335495 
	C731.630188,89.462975 732.273926,89.909317 732.960083,90.676903 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M778.960022,112.676010 
	C778.360718,112.549446 777.718933,112.101753 777.032166,111.334755 
	C777.630615,111.461929 778.274109,111.908401 778.960022,112.676010 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M746.958374,96.677658 
	C746.359192,96.550339 745.718384,96.101730 745.032715,95.333862 
	C745.630798,95.461853 746.273743,95.909111 746.958374,96.677658 
z"/>
<path fill="#E2E2E2" opacity="1.000000" stroke="none" 
	d="
M853.730164,164.801071 
	C855.242859,166.470306 856.483154,167.944290 857.561768,169.731583 
	C856.933167,170.368912 856.466370,170.692947 855.706970,170.879135 
	C853.945190,169.489166 852.475891,168.237030 850.963379,166.663849 
	C850.271484,165.901093 849.622803,165.459396 848.676392,164.883270 
	C846.919312,163.498856 845.459961,162.248886 844.000610,160.624161 
	C844.000305,159.499725 844.000000,158.750061 844.333496,158.000153 
	C845.112305,157.999130 845.557373,157.998337 846.002441,157.997559 
	C848.487549,160.200333 850.972656,162.403076 853.730164,164.801071 
z"/>
<path fill="#E2E2E2" opacity="1.000000" stroke="none" 
	d="
M817.997559,137.002396 
	C819.142029,137.911240 820.286499,138.820084 821.715149,140.236633 
	C822.004578,141.489761 822.009888,142.235214 821.680359,142.986008 
	C820.899536,142.992538 820.453552,142.993698 819.786499,142.890594 
	C819.286987,142.592667 819.096130,142.334351 818.948975,141.671234 
	C817.591431,140.562881 816.277588,139.794662 814.723022,139.022354 
	C814.482239,139.018265 814.001160,138.997238 813.948608,138.652466 
	C812.929626,137.874237 811.963074,137.440781 810.998291,137.003662 
	C811.000000,137.000000 810.992676,137.005615 810.954346,136.682068 
	C810.268555,135.911499 809.621094,135.464462 808.968750,134.780273 
	C808.963928,134.543121 808.980469,134.068970 809.185669,133.794250 
	C812.259827,134.680466 815.128662,135.841431 817.997559,137.002396 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M817.962280,136.653259 
	C815.128662,135.841431 812.259827,134.680466 808.936768,133.780579 
	C808.482544,134.041641 807.983826,134.021301 807.869568,133.721008 
	C807.500305,132.616516 807.245239,131.812286 806.886841,130.747070 
	C806.523193,129.990097 806.262817,129.494125 806.002441,128.998169 
	C811.991638,128.145737 814.698364,132.650864 817.962280,136.653259 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M845.964844,157.642593 
	C845.557373,157.998337 845.112305,157.999130 844.333618,157.999969 
	C844.000000,158.000000 843.997559,158.002472 843.845703,157.700928 
	C842.226501,155.964508 840.816284,154.929260 839.718506,157.818024 
	C838.976196,157.129990 838.497803,156.553482 838.019287,155.647705 
	C838.017334,154.879135 838.015442,154.439880 838.205688,153.820984 
	C838.262085,152.764633 838.126404,151.887924 837.990723,151.011230 
	C840.636230,153.103363 843.281738,155.195496 845.964844,157.642593 
z"/>
<path fill="#E2E2E2" opacity="1.000000" stroke="none" 
	d="
M837.694580,150.986115 
	C838.126404,151.887924 838.262085,152.764633 837.829956,153.820114 
	C836.511780,153.996765 835.761414,153.994644 834.729736,153.864212 
	C833.624329,152.836121 832.800171,151.936340 831.878052,150.768555 
	C831.523987,149.991440 831.267883,149.482315 831.011780,148.973206 
	C831.011780,148.973206 831.501038,148.965973 832.065002,149.115784 
	C833.753479,149.509796 834.877930,149.753983 836.002441,149.998169 
	C836.467773,150.319122 836.933167,150.640060 837.694580,150.986115 
z"/>
<path fill="#E2E2E2" opacity="1.000000" stroke="none" 
	d="
M828.993896,144.006439 
	C829.513428,145.206497 830.032959,146.406570 829.921082,147.804352 
	C828.526428,148.001373 827.763245,148.000687 826.670288,147.995483 
	C825.901611,147.974289 825.462585,147.957626 825.148071,147.662018 
	C825.181580,146.255920 825.090515,145.128784 825.398987,144.001282 
	C826.863647,144.002762 827.928772,144.004593 828.993896,144.006439 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M828.842224,143.698868 
	C827.928772,144.004593 826.863647,144.002762 825.080750,143.974014 
	C823.580383,143.624954 822.797791,143.302811 822.015198,142.980652 
	C822.009888,142.235214 822.004578,141.489761 821.999634,140.371552 
	C824.230164,141.129623 826.460388,142.260468 828.842224,143.698868 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M827.000000,148.000000 
	C827.763245,148.000687 828.526428,148.001373 829.672913,148.006271 
	C830.143433,148.326096 830.320740,148.578537 830.799866,148.870514 
	C831.267883,149.482315 831.523987,149.991440 831.526855,150.757050 
	C829.849121,150.009018 828.424561,149.004501 827.000000,148.000000 
z"/>
<path fill="#E2E2E2" opacity="1.000000" stroke="none" 
	d="
M805.890869,128.781448 
	C806.262817,129.494125 806.523193,129.990097 806.539062,130.743073 
	C805.196167,130.334183 804.097656,129.668320 803.257263,128.895721 
	C804.008057,128.529236 804.500793,128.269485 804.993530,128.009735 
	C805.315613,128.109589 805.577515,128.294586 805.890869,128.781448 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M799.970215,125.654053 
	C799.024902,125.225815 798.044556,124.457520 797.028137,123.349762 
	C797.973083,123.778191 798.954163,124.546089 799.970215,125.654053 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M804.855835,127.717705 
	C804.500793,128.269485 804.008057,128.529236 803.009277,128.893021 
	C802.503296,128.997055 802.007324,128.990845 802.007324,128.990845 
	C802.003235,128.245728 801.999146,127.500618 801.992676,126.383255 
	C802.899536,126.482559 803.808777,126.954117 804.855835,127.717705 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M835.946289,149.657120 
	C834.877930,149.753983 833.753479,149.509796 832.309631,149.117310 
	C833.290222,149.084686 834.590210,149.200378 835.946289,149.657120 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M792.853760,120.716309 
	C792.028076,120.569473 791.065430,120.130974 790.049011,119.348755 
	C790.902405,119.478233 791.809570,119.951439 792.853760,120.716309 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M794.967529,122.666039 
	C794.368164,122.541122 793.723755,122.096550 793.035889,121.331635 
	C793.635864,121.456322 794.279175,121.901352 794.967529,122.666039 
z"/>
<path fill="#F2F2F2" opacity="1.000000" stroke="none" 
	d="
M786.957947,116.671646 
	C786.357422,116.546349 785.714600,116.100395 785.028809,115.334442 
	C785.629028,115.459961 786.272339,115.905472 786.957947,116.671646 
z"/>
<path fill="#DEDEDE" opacity="1.000000" stroke="none" 
	d="
M814.019775,947.641235 
	C813.978210,947.990234 813.489075,947.991882 812.879944,948.034058 
	C808.519287,949.722656 804.767700,951.368958 801.010010,952.636353 
	C800.998047,951.500305 800.992310,950.743164 800.992310,949.992188 
	C800.997986,949.998230 800.989868,949.987061 801.344238,949.989258 
	C802.469543,949.333557 803.240417,948.675659 804.322144,947.874207 
	C810.730103,943.403259 816.827271,939.075867 823.139893,934.672607 
	C823.621887,934.424683 823.808350,934.189270 824.273926,933.895996 
	C832.036438,928.484375 839.463684,923.099304 846.834656,917.638306 
	C851.570984,914.129333 856.224670,910.508667 861.191467,906.958496 
	C861.651550,906.992493 861.835632,907.006042 862.019653,907.019531 
	C860.211731,909.314087 858.763367,912.063782 856.534119,913.825012 
	C846.478149,921.770264 836.155396,929.376709 826.025696,937.230225 
	C821.910706,940.420532 818.042053,943.928650 814.019775,947.641235 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M862.010864,907.012695 
	C861.835632,907.006042 861.651550,906.992493 861.216553,906.613159 
	C861.296448,905.145569 861.627319,904.043884 862.169067,902.826111 
	C862.642029,902.508362 862.829224,902.252075 863.292297,901.931763 
	C866.412598,899.260254 869.182251,896.598206 872.227539,893.800110 
	C873.317322,892.758301 874.131470,891.852539 874.945557,890.946655 
	C876.538635,889.890320 878.377991,889.074829 879.685120,887.738708 
	C888.310486,878.921875 896.821533,869.993164 905.713501,861.080017 
	C906.055420,861.488159 906.053711,861.917114 905.795776,862.833618 
	C905.370422,863.892578 905.201294,864.464172 905.032104,865.035706 
	C904.836243,865.663086 904.829590,866.481750 904.416931,866.889893 
	C894.176880,877.020020 883.904968,887.118103 873.610779,897.193359 
	C870.997009,899.751587 868.288147,902.212830 865.000977,904.887329 
	C863.590210,905.710327 862.801331,906.364624 862.012390,907.018860 
	C862.012390,907.018860 862.002014,907.005798 862.010864,907.012695 
z"/>
<path fill="#DEDEDE" opacity="1.000000" stroke="none" 
	d="
M862.364868,907.012085 
	C862.801331,906.364624 863.590210,905.710327 864.704590,905.037231 
	C864.259216,905.680786 863.488342,906.343018 862.364868,907.012085 
z"/>
<path fill="#E2E2E2" opacity="1.000000" stroke="none" 
	d="
M905.321899,864.995972 
	C905.201294,864.464172 905.370422,863.892578 905.798035,863.155884 
	C905.908203,863.645935 905.759888,864.301086 905.321899,864.995972 
z"/>
<path fill="#DEDEDE" opacity="1.000000" stroke="none" 
	d="
M766.370728,975.002319 
	C757.763489,979.048950 749.592346,983.232727 741.257751,987.060608 
	C737.598083,988.741272 733.605225,989.696655 729.430969,990.619690 
	C729.979553,989.570312 730.770508,988.692139 731.756226,988.224365 
	C741.518799,983.591431 751.291260,978.977905 761.109558,974.464478 
	C765.348022,972.516113 769.699768,970.814026 773.957092,969.366333 
	C773.002625,970.487122 772.006958,971.162476 771.202759,972.017944 
	C770.359253,972.915222 769.699524,973.985229 768.959473,974.979736 
	C768.219849,974.984741 767.480164,974.989807 766.370728,975.002319 
z"/>
<path fill="#F2F2F2" opacity="1.000000" stroke="none" 
	d="
M769.327148,974.951294 
	C769.699524,973.985229 770.359253,972.915222 771.202759,972.017944 
	C772.006958,971.162476 773.002625,970.487122 774.201172,969.358276 
	C774.488159,968.983887 774.976379,968.967224 775.364380,968.979858 
	C777.108826,968.547485 778.569946,968.295288 779.804138,967.625122 
	C786.228882,964.136292 792.595886,960.541138 798.988281,956.988281 
	C798.993591,956.993591 799.004883,957.004883 799.004883,957.004883 
	C796.341187,959.164795 793.899170,961.706238 790.969604,963.408508 
	C784.000732,967.457886 776.801147,971.110229 769.327148,974.951294 
z"/>
<path fill="#F2F2F2" opacity="1.000000" stroke="none" 
	d="
M801.016174,953.015320 
	C804.767700,951.368958 808.519287,949.722656 812.635376,948.039368 
	C810.143555,953.291077 805.020752,955.212158 799.378906,956.961304 
	C799.004883,957.004883 798.993591,956.993591 799.037109,956.660767 
	C799.725159,955.223999 800.369690,954.119995 801.009399,953.009949 
	C801.004456,953.003845 801.016174,953.015320 801.016174,953.015320 
z"/>
<path fill="#F2F2F2" opacity="1.000000" stroke="none" 
	d="
M184.663956,203.038147 
	C183.237961,204.984512 181.466309,206.961792 179.350525,208.973816 
	C180.777023,207.028717 182.547653,205.048889 184.663956,203.038147 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M67.960373,435.967987 
	C67.968483,435.226044 67.976593,434.484070 68.272507,433.133240 
	C70.029900,426.341614 71.499481,420.158905 72.969055,413.976196 
	C72.989502,412.603821 73.009964,411.231415 73.378891,409.382751 
	C74.147087,407.933685 74.566818,406.960938 74.986549,405.988190 
	C74.996834,405.552185 75.007118,405.116180 75.367455,404.291473 
	C76.131714,402.924835 76.545921,401.946899 76.960129,400.968964 
	C76.964043,400.532227 76.967964,400.095459 77.250275,399.110535 
	C78.006050,396.698273 78.483429,394.834167 78.969406,392.642609 
	C78.981743,391.878143 78.985489,391.441162 79.200523,390.720276 
	C79.943756,388.960358 80.475708,387.484283 81.284729,385.756775 
	C83.376038,380.005219 85.190269,374.505127 87.288506,368.788727 
	C89.313698,364.526367 91.157211,360.520233 92.757133,356.419037 
	C93.694260,354.016724 94.243027,351.462891 94.966492,348.977234 
	C94.966492,348.977234 94.993134,349.000641 95.302635,348.835419 
	C97.577011,344.759552 99.701187,340.918762 101.456413,336.916199 
	C102.863312,333.707916 103.822342,330.303253 104.980141,326.985748 
	C104.980141,326.985748 104.998581,327.000000 105.285896,326.857361 
	C106.038078,325.801727 106.502937,324.888733 106.967796,323.975769 
	C106.967796,323.975769 106.989311,323.493225 107.349312,323.195251 
	C109.460098,320.579468 111.210884,318.261658 112.975159,315.961975 
	C112.988640,315.980133 113.013733,316.017761 113.007370,316.397125 
	C112.334404,319.193390 111.667809,321.610260 110.985901,324.253601 
	C110.970589,324.480072 110.945053,324.933319 110.608124,325.002869 
	C109.172951,327.722473 108.074715,330.372589 106.951332,333.343628 
	C106.279556,334.761719 105.632927,335.858826 104.635056,337.019409 
	C103.880104,338.090729 103.476395,339.098602 103.058060,340.321167 
	C103.043419,340.535858 102.993202,340.963287 102.643433,341.024597 
	C99.649002,346.500977 96.980759,351.904907 94.410568,357.355072 
	C94.231415,357.734955 94.779747,358.457916 94.984299,359.341125 
	C94.967804,360.087189 94.956787,360.513458 94.581558,360.997620 
	C93.680511,361.408630 92.820267,361.650238 92.654488,362.131256 
	C89.098145,372.449341 85.619560,382.794220 82.116180,393.440308 
	C82.102364,394.155457 82.098244,394.564178 81.787354,395.179382 
	C79.359474,402.632599 77.238380,409.879333 75.113197,417.433899 
	C75.107552,418.152252 75.105988,418.562805 74.807381,419.186584 
	C74.047485,421.312561 73.584633,423.225311 73.111809,425.442322 
	C73.102341,426.152527 73.102852,426.558472 72.806335,427.188660 
	C71.323257,433.947052 70.137192,440.481171 68.951286,447.337738 
	C68.933258,448.089722 68.915070,448.519226 68.538353,449.033752 
	C66.451279,457.573303 64.501747,465.991302 63.095890,474.499176 
	C62.477234,478.243134 62.964657,482.169861 62.951900,486.334595 
	C62.948235,487.083557 62.942551,487.511505 62.557484,488.043915 
	C60.324699,494.685608 58.753826,501.239380 61.003956,508.409241 
	C60.973854,509.846130 60.955433,510.891174 60.549576,512.046204 
	C59.770576,515.313599 59.521015,518.498169 58.927139,521.617126 
	C58.633152,523.161072 57.705132,524.584351 57.041393,525.592651 
	C57.004562,520.409180 56.990311,515.695251 56.976059,510.981354 
	C56.982857,509.272339 56.989651,507.563354 57.279419,505.178101 
	C57.694202,502.990601 57.826008,501.479340 57.957809,499.968079 
	C57.675713,498.563110 57.393620,497.158173 57.459763,495.295715 
	C58.212593,490.892181 58.617188,486.946075 59.021782,483.000000 
	C59.025002,481.619934 59.028217,480.239868 59.417976,478.349731 
	C60.854530,471.548584 61.904541,465.257568 62.954552,458.966522 
	C62.959007,458.529572 62.963463,458.092590 63.257065,457.086487 
	C64.344093,452.664062 65.141975,448.810760 65.939857,444.957489 
	C65.659050,443.875031 65.378242,442.792542 65.412201,441.307007 
	C66.471436,439.258606 67.215897,437.613281 67.960373,435.967987 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M114.991173,307.990784 
	C114.991165,307.990784 115.003403,307.501770 115.357773,307.200134 
	C116.469749,305.260956 117.227356,303.623413 117.984955,301.985901 
	C117.984962,301.985901 117.998123,301.498383 118.361176,301.213501 
	C119.482719,299.619507 120.241211,298.310364 120.999695,297.001221 
	C120.999695,297.001221 121.000000,297.000000 121.349281,296.946716 
	C123.458260,293.588501 125.217949,290.283600 126.977654,286.978699 
	C127.629784,285.867981 128.281906,284.757263 129.311615,283.271301 
	C130.127014,281.931610 130.564835,280.967224 131.002655,280.002808 
	C133.259857,276.287781 135.208160,272.337341 137.828552,268.899109 
	C148.337997,255.109619 159.056839,241.479736 169.693665,227.787262 
	C170.304031,227.001556 170.873550,226.184113 172.064056,225.193054 
	C173.109390,225.002060 173.552765,224.999466 173.964722,225.343811 
	C170.624435,230.129700 167.315582,234.568649 163.735214,239.240448 
	C163.314484,240.652786 163.165253,241.832306 163.008331,243.005920 
	C163.000610,243.000000 163.012115,243.012573 162.693512,243.128815 
	C160.927933,245.185776 159.480957,247.126511 158.008514,249.279785 
	C157.983032,249.492294 157.935013,249.917679 157.588837,249.966110 
	C155.824646,251.340591 154.406631,252.666641 152.639404,254.032608 
	C151.233292,255.782867 150.176407,257.493195 149.116013,259.404877 
	C149.112488,259.606201 149.042984,260.002899 148.696045,260.045685 
	C147.881653,261.029694 147.414200,261.970947 146.655411,263.090088 
	C139.961258,272.743744 133.278366,282.046204 127.266045,291.763550 
	C123.933250,297.150116 121.721352,303.230194 119.002411,308.998840 
	C119.000603,309.000000 118.998909,309.004486 118.643448,309.034424 
	C116.529907,311.382141 114.771828,313.699951 113.013733,316.017761 
	C113.013733,316.017761 112.988640,315.980133 112.984634,315.551697 
	C112.984558,313.746307 112.988487,312.369324 112.992416,310.992340 
	C112.992416,310.992340 113.001961,310.501801 113.328171,310.212738 
	C114.099983,309.279388 114.545578,308.635101 114.991173,307.990784 
z"/>
<path fill="#BAB9B8" opacity="1.000000" stroke="none" 
	d="
M164.006714,239.007599 
	C167.315582,234.568649 170.624435,230.129700 174.112274,225.043793 
	C176.988388,221.307175 179.677399,218.210388 182.384628,215.129578 
	C186.241547,210.740448 190.111832,206.363068 193.976425,201.980667 
	C196.186218,199.828827 198.396027,197.676971 201.103302,195.120102 
	C203.015366,193.385620 204.342957,191.946060 205.860519,190.746918 
	C210.872177,186.786911 215.952454,182.913742 220.981049,179.334061 
	C220.316040,180.787445 219.676376,181.914032 219.020462,183.024261 
	C219.004196,183.007919 219.039505,183.046600 218.708160,183.070602 
	C217.945389,183.760696 217.513977,184.426804 216.869202,185.352722 
	C211.769974,190.405502 206.884094,195.198456 201.675629,200.030518 
	C200.899429,200.713028 200.445816,201.356430 199.841644,202.303940 
	C188.775772,214.722336 177.860458,226.836624 166.578384,238.962097 
	C165.476669,238.984711 164.741684,238.996155 164.006714,239.007599 
z"/>
<path fill="#BAB9B8" opacity="1.000000" stroke="none" 
	d="
M238.030762,161.026886 
	C242.136826,158.721710 246.237244,156.409302 250.646179,154.422333 
	C250.626953,155.825500 250.299210,156.903214 249.730560,158.105194 
	C249.024506,158.505402 248.559341,158.781342 247.757874,159.072479 
	C246.955383,159.741547 246.489166,160.395416 245.826782,161.318329 
	C240.110474,166.074875 234.590347,170.562347 228.697113,175.061768 
	C226.888840,175.387894 225.453690,175.702072 223.986359,175.740967 
	C223.919083,175.284271 223.883987,175.102875 223.848892,174.921463 
	C223.992386,174.613144 224.203934,174.362213 225.038452,174.039398 
	C226.056854,173.265930 226.520355,172.621704 226.983841,171.977493 
	C227.004227,171.558136 227.024628,171.138763 227.283813,170.403137 
	C227.522583,170.086853 227.968491,170.089035 228.073517,170.244415 
	C228.458237,170.277939 228.737946,170.156113 229.237732,169.920654 
	C229.732407,169.604080 229.925766,169.341309 230.313293,168.884567 
	C231.399872,167.849716 232.211075,166.949036 233.375549,166.000153 
	C235.163452,164.312744 236.598083,162.673538 238.032715,161.034332 
	C238.032715,161.034317 238.025116,161.019669 238.030762,161.026886 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M223.465652,174.936142 
	C223.883987,175.102875 223.919083,175.284271 223.932968,176.072769 
	C222.943298,177.455658 221.974854,178.231461 221.006409,179.007263 
	C215.952454,182.913742 210.872177,186.786911 205.860519,190.746918 
	C204.342957,191.946060 203.015366,193.385620 201.292175,194.847778 
	C202.899506,192.244400 204.520691,189.227234 206.799683,186.837357 
	C210.316376,183.149551 214.170471,179.752563 218.125443,176.531799 
	C219.376953,175.512619 221.408066,175.450775 223.465652,174.936142 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M193.613159,201.985428 
	C190.111832,206.363068 186.241547,210.740448 182.384628,215.129578 
	C179.677399,218.210388 176.988388,221.307175 174.143677,224.696838 
	C173.552765,224.999466 173.109390,225.002060 172.333481,225.002640 
	C178.025009,216.352631 183.973587,207.634918 193.613159,201.985428 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M104.632156,327.044922 
	C103.822342,330.303253 102.863312,333.707916 101.456413,336.916199 
	C99.701187,340.918762 97.577011,344.759552 95.294083,348.822296 
	C98.078735,341.684296 101.181450,334.394165 104.632156,327.044922 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M94.612839,349.042755 
	C94.243027,351.462891 93.694260,354.016724 92.757133,356.419037 
	C91.157211,360.520233 89.313698,364.526367 87.275688,368.534668 
	C86.970894,368.327484 86.962929,368.158112 86.966515,367.613068 
	C86.971909,366.484985 86.965752,365.732574 86.959595,364.980164 
	C86.969742,364.540405 86.979889,364.100647 86.990097,363.331055 
	C88.670158,359.553650 90.315392,356.088501 92.048500,352.667847 
	C92.677391,351.426605 93.516174,350.291687 94.612839,349.042755 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M112.643097,311.049011 
	C112.988487,312.369324 112.984558,313.746307 112.971146,315.533569 
	C111.210884,318.261658 109.460098,320.579468 107.360390,322.953979 
	C108.772240,319.042358 110.533005,315.074036 112.643097,311.049011 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M126.637741,287.035400 
	C125.217949,290.283600 123.458260,293.588501 121.349579,296.946716 
	C122.766350,293.697357 124.532097,290.394714 126.637741,287.035400 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M237.664093,161.023254 
	C236.598083,162.673538 235.163452,164.312744 233.118286,165.983185 
	C232.336212,166.006897 232.164658,165.999390 231.993134,165.991913 
	C233.760574,164.332001 235.528015,162.672089 237.664093,161.023254 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M117.641014,302.042816 
	C117.227356,303.623413 116.469749,305.260956 115.364357,306.955627 
	C115.776741,305.375061 116.536911,303.737396 117.641014,302.042816 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M120.661438,297.046661 
	C120.241211,298.310364 119.482719,299.619507 118.369202,300.969788 
	C118.783836,299.704681 119.553497,298.398376 120.661438,297.046661 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M227.968491,170.089035 
	C227.968491,170.089035 227.522583,170.086853 227.300476,170.088654 
	C227.829056,169.087418 228.579727,168.084396 229.673630,167.386292 
	C230.023865,168.133728 230.030869,168.576233 230.037888,169.018738 
	C229.925766,169.341309 229.732407,169.604080 228.974686,169.921188 
	C228.317230,170.053223 228.142868,170.071136 227.968491,170.089035 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M230.313293,168.884567 
	C230.030869,168.576233 230.023865,168.133728 230.018997,167.360016 
	C230.481247,166.701492 230.941345,166.374191 231.697281,166.019394 
	C232.164658,165.999390 232.336212,166.006897 232.765030,166.031387 
	C232.211075,166.949036 231.399872,167.849716 230.313293,168.884567 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M106.626671,324.034821 
	C106.502937,324.888733 106.038078,325.801727 105.279129,326.849762 
	C105.418541,326.021179 105.852043,325.057526 106.626671,324.034821 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M130.710480,280.139343 
	C130.564835,280.967224 130.127014,281.931610 129.343994,282.946838 
	C129.471954,282.090363 129.945129,281.183105 130.710480,280.139343 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M226.663528,172.016785 
	C226.520355,172.621704 226.056854,173.265930 225.261230,173.929443 
	C225.400467,173.317856 225.871841,172.686951 226.663528,172.016785 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M114.670074,308.033203 
	C114.545578,308.635101 114.099983,309.279388 113.334267,309.967468 
	C113.459084,309.366089 113.904022,308.720856 114.670074,308.033203 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M87.008133,712.992676 
	C86.672844,711.908264 86.337563,710.823792 85.999969,708.998291 
	C85.334938,707.168701 84.672218,706.080261 84.009491,704.991760 
	C83.673523,703.578369 83.337563,702.164917 82.998291,699.986694 
	C81.666916,696.478149 80.338837,693.734375 79.010765,690.990662 
	C77.676956,686.260315 76.343140,681.529968 75.002129,676.050293 
	C74.348679,674.520325 73.702415,673.739685 73.056168,672.958984 
	C73.045364,672.218933 73.034561,671.478821 73.274315,670.146851 
	C72.334015,664.265930 71.280724,658.939880 69.873878,653.708923 
	C69.413071,651.995605 68.033760,650.529297 67.074493,648.950012 
	C67.047966,647.579773 67.021439,646.209473 66.996216,644.039917 
	C67.322533,641.825623 67.647530,640.410583 68.259636,639.253296 
	C69.356232,643.288269 69.988640,647.117554 71.073242,650.814148 
	C71.442711,652.073303 72.949989,652.998718 73.951416,654.478882 
	C74.003357,656.221985 74.041260,657.562927 74.047951,659.327881 
	C74.998352,662.517395 75.979965,665.282898 76.983002,668.402832 
	C77.033577,669.466675 77.062744,670.176086 77.054565,671.297119 
	C78.333176,674.821533 79.649124,677.934448 80.974121,681.366577 
	C80.994095,682.111572 81.005013,682.537354 81.003677,683.379761 
	C82.425575,688.914429 83.828804,694.041565 85.349945,699.133606 
	C85.478020,699.562256 86.397263,699.754578 86.970276,700.365112 
	C87.017906,701.084900 87.044205,701.496216 87.042099,702.313538 
	C88.330307,707.153748 89.646919,711.588013 90.627670,715.964600 
	C89.197250,714.935547 88.102692,713.964111 87.008133,712.992676 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M58.965912,592.973328 
	C58.968990,592.240112 58.972065,591.506836 58.978344,590.008301 
	C58.330902,587.820923 57.680260,586.398804 57.029610,584.976746 
	C57.022015,584.232605 57.014423,583.488403 57.300240,582.086609 
	C57.420822,572.929688 57.247997,564.430359 57.459480,556.047363 
	C58.218788,556.841370 58.876129,557.499023 58.926361,558.200073 
	C59.665237,568.512634 60.262547,578.835815 61.074471,589.142273 
	C61.180599,590.489441 62.290203,591.757568 62.943855,593.472534 
	C62.964378,595.250366 62.976067,596.618042 62.703438,598.253418 
	C63.263824,602.034180 64.108536,605.547302 64.966324,609.380371 
	C64.987183,610.127258 64.994965,610.554077 64.735283,611.202026 
	C64.962875,612.633057 65.457932,613.843018 65.957916,615.375366 
	C65.963516,616.127808 65.964188,616.557800 65.688370,617.247925 
	C65.983604,620.682861 66.555328,623.857727 67.115326,627.383057 
	C67.121506,628.434570 67.139404,629.135498 67.102470,630.234680 
	C67.014832,631.432007 66.982025,632.231140 66.592560,632.954834 
	C65.150497,630.586304 64.065109,628.293152 62.979725,626.000000 
	C62.979870,624.943420 62.980007,623.886841 62.980148,622.090332 
	C62.644974,620.566956 62.309788,619.783508 61.974602,619.000000 
	C61.977890,618.560913 61.981182,618.121887 61.989326,616.941406 
	C61.341141,614.457703 60.688099,612.715454 60.035065,610.973206 
	C60.028656,610.534729 60.022243,610.096191 60.289005,609.089050 
	C60.056229,607.002625 59.550274,605.484863 59.044319,603.967102 
	C59.032726,602.587524 59.021137,601.207886 59.279724,599.188354 
	C59.355232,596.690063 59.160572,594.831665 58.965912,592.973328 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M55.029305,550.977051 
	C55.025860,544.635742 55.022423,538.294373 55.344963,531.436523 
	C56.132843,530.261230 56.594746,529.602478 57.445152,529.053101 
	C58.193439,529.675781 58.860073,530.185364 58.866642,530.703369 
	C58.954945,537.668152 59.015144,544.635376 58.886341,551.598389 
	C58.870926,552.431885 57.704159,553.244141 57.043732,553.709106 
	C56.354038,552.560242 55.691673,551.768677 55.029305,550.977051 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M55.016045,551.447876 
	C55.691673,551.768677 56.354038,552.560242 57.037041,554.019531 
	C57.063507,555.101807 57.069340,555.516479 57.075172,555.931091 
	C57.247997,564.430359 57.420822,572.929688 57.304653,581.714478 
	C56.344299,576.406799 55.472103,570.828125 55.065773,565.215759 
	C54.746437,560.804993 55.002384,556.352539 55.016045,551.447876 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M62.706474,626.247070 
	C64.065109,628.293152 65.150497,630.586304 66.606400,633.282959 
	C66.976379,634.125183 66.975845,634.563782 66.751236,635.172119 
	C66.988937,635.909912 67.450699,636.477966 67.930130,637.370789 
	C67.956055,638.128906 67.964302,638.562256 67.972534,638.995605 
	C67.647530,640.410583 67.322533,641.825623 66.988083,643.628784 
	C65.667923,640.749939 64.079521,637.560364 63.139503,634.190002 
	C62.458675,631.748962 62.637665,629.068054 62.706474,626.247070 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M67.050735,649.366089 
	C68.033760,650.529297 69.413071,651.995605 69.873878,653.708923 
	C71.280724,658.939880 72.334015,664.265930 73.275810,669.776733 
	C72.004539,667.545410 70.729828,665.163147 70.016991,662.623169 
	C68.830673,658.396057 68.004684,654.067810 67.050735,649.366089 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M57.056656,528.943787 
	C56.594746,529.602478 56.132843,530.261230 55.347382,530.969238 
	C55.016262,525.897278 54.920216,520.773560 55.055996,515.656006 
	C55.095856,514.153870 55.793556,512.669189 56.582909,511.078918 
	C56.990311,515.695251 57.004562,520.409180 57.034389,525.952271 
	C57.052193,527.502197 57.054424,528.223022 57.056656,528.943787 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M72.610313,414.047607 
	C71.499481,420.158905 70.029900,426.341614 68.273933,432.762207 
	C69.408882,426.706390 70.830223,420.412720 72.610313,414.047607 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M62.580376,459.058624 
	C61.904541,465.257568 60.854530,471.548584 59.424164,477.935760 
	C60.097935,471.738159 61.152065,465.444458 62.580376,459.058624 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M87.003502,713.393677 
	C88.102692,713.964111 89.197250,714.935547 90.647873,716.328613 
	C91.354179,718.148193 91.704430,719.546143 92.036453,721.329224 
	C91.999802,722.486450 91.981384,723.258423 91.962975,724.030457 
	C91.225517,724.018433 90.488068,724.006409 89.382248,723.989868 
	C89.013878,723.985352 89.007263,723.491455 89.004639,723.244507 
	C88.334305,719.929932 87.666588,716.862305 87.003502,713.393677 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M58.730289,483.245087 
	C58.617188,486.946075 58.212593,490.892181 57.427399,494.935974 
	C57.510799,491.185883 57.974800,487.338013 58.730289,483.245087 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M65.581085,445.043091 
	C65.141975,448.810760 64.344093,452.664062 63.259171,456.758728 
	C63.722195,453.042999 64.472252,449.085876 65.581085,445.043091 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M79.010010,691.380249 
	C80.338837,693.734375 81.666916,696.478149 82.986954,699.619568 
	C81.655701,697.268127 80.332481,694.518982 79.010010,691.380249 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M78.960815,392.970062 
	C78.483429,394.834167 78.006050,396.698273 77.247925,398.782959 
	C76.485977,398.740173 76.004791,398.476776 75.270660,398.105591 
	C75.021500,397.563782 75.025276,397.129730 75.365410,396.305359 
	C76.788124,394.933380 77.874474,393.951721 78.960815,392.970062 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M60.021408,611.385376 
	C60.688099,612.715454 61.341141,614.457703 61.981503,616.612122 
	C61.315132,615.282043 60.661446,613.539795 60.021408,611.385376 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M58.626404,593.036987 
	C59.160572,594.831665 59.355232,596.690063 59.285881,598.774170 
	C58.776882,597.033508 58.531891,595.067078 58.626404,593.036987 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M67.597549,436.022400 
	C67.215897,437.613281 66.471436,439.258606 65.387024,440.969421 
	C65.776291,439.382233 66.505508,437.729492 67.597549,436.022400 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M59.028522,604.358459 
	C59.550274,605.484863 60.056229,607.002625 60.295586,608.760193 
	C59.690235,607.583252 59.351479,606.166565 59.028522,604.358459 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M57.018333,585.373413 
	C57.680260,586.398804 58.330902,587.820923 58.961933,589.641968 
	C58.297230,588.617371 57.652142,587.193665 57.018333,585.373413 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M57.607903,500.042664 
	C57.826008,501.479340 57.694202,502.990601 57.281380,504.750916 
	C57.086239,503.372406 57.172119,501.744812 57.607903,500.042664 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M84.004913,705.368286 
	C84.672218,706.080261 85.334938,707.168701 85.992325,708.634155 
	C85.324768,707.922424 84.662552,706.833679 84.004913,705.368286 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M74.641449,406.044556 
	C74.566818,406.960938 74.147087,407.933685 73.387581,408.971069 
	C73.463997,408.057465 73.880173,407.079224 74.641449,406.044556 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M76.618423,401.031677 
	C76.545921,401.946899 76.131714,402.924835 75.375328,403.964478 
	C75.447670,403.048920 75.862190,402.071655 76.618423,401.031677 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M73.042946,673.316406 
	C73.702415,673.739685 74.348679,674.520325 74.992905,675.654541 
	C74.337158,675.230042 73.683449,674.451904 73.042946,673.316406 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M61.724182,619.200317 
	C62.309788,619.783508 62.644974,620.566956 62.964493,621.694275 
	C62.457138,621.158936 61.965450,620.279785 61.724182,619.200317 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M91.974915,724.355347 
	C91.981384,723.258423 91.999802,722.486450 92.374329,721.399963 
	C94.153641,722.728577 95.576828,724.371582 97.018867,726.242493 
	C97.037720,726.470337 97.046860,726.927490 97.054092,727.255615 
	C97.673302,728.065674 98.285278,728.547607 98.909943,729.356140 
	C98.934677,730.118164 98.946716,730.553711 98.700256,731.208740 
	C98.377960,732.062927 98.060921,732.812927 98.289421,733.314514 
	C100.665329,738.529785 102.893471,743.833557 105.633347,748.855957 
	C108.472511,754.060364 111.831268,758.981323 114.977654,764.013123 
	C114.992538,764.000000 114.972054,764.028992 114.969513,764.419495 
	C118.252464,771.324036 121.366608,777.933167 124.901825,784.308716 
	C126.471252,787.139160 128.890900,789.498230 130.940887,792.296936 
	C130.957169,792.522644 131.002762,792.973022 130.994370,793.360718 
	C132.992081,796.503784 134.998169,799.259216 137.027557,802.237000 
	C137.050842,802.459351 137.078934,802.905640 137.055145,803.282227 
	C139.684860,807.108521 142.338394,810.558289 144.995956,814.004028 
	C145.000000,814.000000 144.991562,814.007690 144.988922,814.379150 
	C147.655258,818.167053 150.324203,821.583557 152.996887,825.000000 
	C153.000610,825.000000 152.999466,825.006592 152.995148,825.375366 
	C157.641434,831.509216 162.292053,837.274292 166.956558,843.032715 
	C166.970459,843.026123 166.965271,843.041138 166.970215,843.391479 
	C167.634705,844.513733 168.294266,845.285645 169.084534,846.332275 
	C169.804886,847.405212 170.394501,848.203430 171.019531,849.345459 
	C172.681885,851.147583 174.308853,852.606018 175.951508,854.426025 
	C178.960648,858.208801 181.954086,861.630066 184.992859,865.393311 
	C186.017899,867.494690 186.997635,869.254089 187.646896,870.982544 
	C185.873703,869.973511 184.430984,868.995300 182.881104,867.795898 
	C182.574478,867.298767 182.312103,867.110474 181.957855,866.664185 
	C179.611649,864.218689 177.294418,862.118713 174.977203,860.018799 
	C174.868622,859.702698 174.680267,859.445007 174.168365,858.809692 
	C173.293381,857.907837 172.662170,857.442017 172.024338,856.979736 
	C172.017715,856.983276 172.024887,856.975708 172.019531,856.610474 
	C167.676910,851.163452 163.339661,846.081726 159.002441,841.000000 
	C158.819122,840.455200 158.635803,839.910339 158.191559,838.855652 
	C156.963715,837.553406 155.996796,836.761047 155.029861,835.968628 
	C148.767075,826.912048 142.273575,818.004028 136.311508,808.753601 
	C131.162292,800.764343 126.631195,792.377563 121.800911,784.181763 
	C118.044632,777.808289 113.992477,771.593262 110.544510,765.058411 
	C107.338020,758.981262 104.769890,752.568054 101.894623,746.314819 
	C100.281883,742.807495 98.616188,739.324463 96.941505,735.106750 
	C96.275360,733.919312 95.642067,733.455627 95.008774,732.991943 
	C94.997040,732.556824 94.985329,732.121643 94.945206,730.997681 
	C93.952179,728.866150 92.987564,727.423584 92.022942,725.980957 
	C92.010910,725.547363 91.998886,725.113770 91.974915,724.355347 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M92.018997,726.349243 
	C92.987564,727.423584 93.952179,728.866150 94.936691,730.671326 
	C93.976074,729.595093 92.995560,728.156372 92.018997,726.349243 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M155.018784,836.308838 
	C155.996796,836.761047 156.963715,837.553406 157.949951,838.690002 
	C157.171951,838.776733 156.374634,838.519226 155.283524,838.135742 
	C154.995712,837.556152 155.001694,837.102600 155.018784,836.308838 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M95.043953,733.312134 
	C95.642067,733.455627 96.275360,733.919312 96.933342,734.708313 
	C96.331734,734.566528 95.705429,734.099426 95.043953,733.312134 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M774.976379,968.967224 
	C774.976379,968.967224 774.488159,968.983887 774.244080,968.991943 
	C769.699768,970.814026 765.348022,972.516113 761.109558,974.464478 
	C751.291260,978.977905 741.518799,983.591431 731.756226,988.224365 
	C730.770508,988.692139 729.979553,989.570312 729.033691,990.603882 
	C728.535034,990.966492 728.101074,990.983337 726.942017,990.999268 
	C720.360779,993.050964 714.538757,995.209045 708.637268,997.121826 
	C704.144165,998.578186 699.550110,999.723145 694.995117,1000.989380 
	C694.989197,1000.972107 694.977478,1000.938904 695.046570,1000.581726 
	C696.635315,999.480652 698.153198,998.733093 699.675049,997.993713 
	C704.457703,995.670044 709.241821,993.349243 714.388184,991.013489 
	C715.475037,990.960632 716.199036,990.921570 717.306641,990.878418 
	C719.705811,990.187561 721.797607,989.667236 723.724304,988.786011 
	C733.632935,984.254211 743.582031,979.798340 753.356812,974.990234 
	C763.258606,970.119873 772.990295,964.901367 782.763977,959.773499 
	C788.869263,956.570312 794.914429,953.252502 800.986572,949.986084 
	C800.992310,950.743164 800.998047,951.500305 801.010010,952.636353 
	C801.016174,953.015320 801.004456,953.003845 800.624634,953.004517 
	C799.297363,953.207886 798.223450,953.189392 797.422546,953.647888 
	C790.616211,957.544128 783.816406,961.456909 777.133240,965.557983 
	C776.104370,966.189331 775.680237,967.806152 774.976379,968.967224 
z"/>
<path fill="#DEDEDE" opacity="1.000000" stroke="none" 
	d="
M695.000977,1001.006653 
	C699.550110,999.723145 704.144165,998.578186 708.637268,997.121826 
	C714.538757,995.209045 720.360779,993.050964 726.616089,991.006470 
	C723.935242,992.886292 721.045166,995.248718 717.736328,996.528687 
	C710.541809,999.312134 703.133667,1001.543274 695.405884,1003.624268 
	C694.999634,1002.501221 695.000305,1001.753906 695.000977,1001.006653 
z"/>
<path fill="#DEDEDE" opacity="1.000000" stroke="none" 
	d="
M693.000977,1004.003906 
	C690.579163,1005.278931 688.274963,1006.915344 685.709900,1007.750183 
	C681.045227,1009.268372 676.225586,1010.310303 671.235229,1010.947021 
	C671.003235,1009.903137 671.006470,1009.463135 671.380188,1009.015503 
	C672.492126,1008.997559 673.233582,1008.987427 674.290161,1008.950562 
	C675.398254,1008.287476 676.191162,1007.651062 677.284607,1007.119019 
	C678.955017,1006.862915 680.328979,1006.517578 681.693787,1006.139038 
	C685.462952,1005.093689 689.228271,1004.034607 692.997437,1003.236572 
	C693.000061,1003.662842 693.000488,1003.833374 693.000977,1004.003906 
z"/>
<path fill="#DEDEDE" opacity="1.000000" stroke="none" 
	d="
M668.007019,1011.010864 
	C666.032898,1012.279846 664.212585,1014.023193 662.053894,1014.723328 
	C656.919617,1016.388489 651.631653,1017.585693 646.386597,1018.895325 
	C645.814148,1019.038330 645.116638,1018.680908 644.436646,1018.018799 
	C645.264954,1016.656311 646.133667,1015.830627 647.413330,1015.002441 
	C650.893250,1013.685120 653.962280,1012.370239 657.360046,1011.043457 
	C658.127319,1011.029785 658.565857,1011.028076 659.254456,1011.302307 
	C662.334412,1011.380615 665.164246,1011.183044 667.996826,1010.992065 
	C667.999573,1010.998779 668.007019,1011.010803 668.007019,1011.010864 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M647.002441,1015.004883 
	C646.133667,1015.830627 645.264954,1016.656311 644.196777,1017.744141 
	C642.941528,1018.014343 641.885620,1018.022522 640.169006,1018.014893 
	C639.331909,1017.991028 639.155518,1017.982971 638.987061,1017.606323 
	C639.000793,1016.500000 639.006470,1015.762207 639.343018,1015.015625 
	C640.115112,1015.000122 640.556335,1014.993469 641.143982,1015.217896 
	C641.863708,1015.281311 642.437012,1015.113770 643.330688,1014.946533 
	C644.078369,1014.945496 644.505554,1014.944092 645.277710,1014.963257 
	C646.082520,1014.990845 646.542480,1014.997864 647.002441,1015.004883 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M634.426025,1019.024658 
	C633.856873,1019.020325 633.683655,1019.011414 633.252930,1018.657166 
	C632.996460,1017.860962 632.997437,1017.410034 633.321533,1016.957581 
	C634.075317,1016.952942 634.505981,1016.949829 635.280579,1016.967407 
	C636.415283,1017.317688 637.206177,1017.647339 637.997009,1018.234009 
	C637.997986,1018.662292 637.998962,1018.833557 638.000000,1019.004883 
	C636.940613,1019.009888 635.881287,1019.014893 634.426025,1019.024658 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M693.333618,1004.002625 
	C693.000488,1003.833374 693.000061,1003.662842 692.998657,1002.902344 
	C692.998169,1001.865723 692.998779,1001.419128 693.329224,1000.969360 
	C694.098572,1000.957092 694.538025,1000.947998 694.977478,1000.938904 
	C694.977478,1000.938904 694.989197,1000.972107 694.995056,1000.989380 
	C695.000305,1001.753906 694.999634,1002.501221 694.998779,1003.622009 
	C694.554443,1003.997498 694.110352,1003.999451 693.333618,1004.002625 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M671.009766,1009.023193 
	C671.006470,1009.463135 671.003235,1009.903137 670.999390,1010.673706 
	C670.250549,1011.005371 669.502136,1011.006409 668.380371,1011.009155 
	C668.007019,1011.010803 667.999573,1010.998779 668.052368,1010.649170 
	C669.073364,1009.874084 670.041565,1009.448669 671.009766,1009.023193 
z"/>
<path fill="#E2E2E2" opacity="1.000000" stroke="none" 
	d="
M638.169861,1019.233032 
	C637.998962,1018.833557 637.997986,1018.662292 638.242554,1018.235840 
	C638.488159,1017.980774 638.979126,1017.974854 638.979126,1017.974854 
	C639.155518,1017.982971 639.331909,1017.991028 639.772949,1018.017578 
	C639.471680,1018.511108 638.905701,1018.986206 638.169861,1019.233032 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M285.032593,955.364746 
	C286.109039,955.311951 287.227051,955.618713 289.007812,956.099121 
	C294.059265,958.789673 298.315033,961.582214 302.860168,963.774841 
	C321.191193,972.617981 339.611481,981.276001 358.172638,990.263916 
	C358.900146,991.346252 359.453827,992.166992 360.007507,992.987732 
	C352.748413,990.164429 345.225494,987.870483 338.283722,984.410645 
	C322.466309,976.527161 306.920074,968.097961 291.301544,959.818970 
	C289.116241,958.660583 287.144653,957.099121 285.032593,955.364746 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M360.249207,992.994629 
	C359.453827,992.166992 358.900146,991.346252 358.384644,990.013306 
	C359.968323,989.653076 361.513763,989.805054 363.375732,989.970703 
	C364.113922,990.019592 364.535675,990.054749 365.019348,990.426514 
	C367.073364,992.488403 369.065460,994.213623 371.057587,995.938843 
	C367.959686,994.962341 364.861816,993.985840 361.369019,993.015747 
	C360.974152,993.022095 360.490936,993.001465 360.249207,992.994629 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M282.020996,953.340576 
	C282.619812,953.462158 283.269714,953.901978 283.963379,954.662720 
	C283.362122,954.542053 282.717072,954.100403 282.020996,953.340576 
z"/>
<path fill="#BDBEBF" opacity="1.000000" stroke="none" 
	d="
M363.059235,989.957031 
	C361.513763,989.805054 359.968323,989.653076 358.210815,989.751709 
	C339.611481,981.276001 321.191193,972.617981 302.860168,963.774841 
	C298.315033,961.582214 294.059265,958.789673 289.357544,956.121765 
	C288.380981,950.964478 283.287933,951.377441 280.409119,949.107117 
	C276.077850,945.691467 271.121246,943.079224 266.576385,939.915771 
	C265.535004,939.190979 265.087646,937.612732 264.921753,936.340210 
	C268.012421,937.979492 270.489105,939.801514 273.096558,941.411865 
	C278.363525,944.664673 283.701508,947.802551 289.210144,951.259888 
	C293.484344,954.023193 297.438965,956.741638 301.655487,958.962769 
	C313.883148,965.403870 326.143738,971.791870 338.539978,977.899231 
	C344.191895,980.683838 350.266510,982.599487 356.002197,985.230225 
	C358.542725,986.395447 360.718079,988.356995 363.059235,989.957031 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M241.005783,919.930420 
	C240.071121,919.845337 238.807739,920.124512 238.250214,919.621460 
	C226.489243,909.008850 214.807800,898.307983 203.428497,887.299072 
	C204.844711,887.307739 205.943268,887.641663 207.196991,888.217834 
	C207.882446,888.653931 208.412720,888.847839 208.993561,889.356445 
	C209.669174,890.140991 210.294235,890.610901 210.970825,891.422607 
	C213.667908,893.857788 216.313477,895.951172 219.077087,898.255371 
	C219.396866,898.729614 219.652679,898.920532 220.003250,899.390625 
	C222.315292,901.199890 224.586700,902.657532 227.001801,904.350220 
	C227.422745,905.058411 227.700043,905.531677 228.009781,906.341553 
	C229.366745,907.784607 230.691269,908.891052 232.178650,910.235596 
	C232.869293,910.675293 233.397079,910.876831 233.979095,911.426880 
	C235.665085,912.875061 237.296875,913.974854 238.976746,915.411560 
	C239.685135,917.142456 240.345459,918.536438 241.005783,919.930420 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M207.041840,887.975586 
	C205.943268,887.641663 204.844711,887.307739 203.399384,886.959229 
	C202.929337,886.638367 202.739075,886.381714 202.221466,885.714111 
	C199.967880,883.177551 197.974640,881.101685 195.686081,878.867554 
	C192.919632,876.144043 190.448502,873.578735 187.977386,871.013428 
	C186.997635,869.254089 186.017899,867.494690 185.019714,865.366882 
	C185.001266,864.998413 185.054718,864.945190 185.420776,864.957581 
	C186.522552,864.982422 187.258286,864.994873 188.299225,865.031372 
	C189.010895,865.073303 189.417389,865.091248 189.860687,865.103394 
	C189.897491,865.097656 189.900711,865.172119 189.939804,865.492798 
	C190.973633,867.212830 191.968353,868.612183 192.979919,870.380859 
	C193.993607,871.514282 194.990479,872.278259 196.189056,873.127808 
	C196.891541,873.279846 197.392334,873.346191 198.156082,873.505859 
	C198.885422,874.191345 199.351807,874.783569 199.872726,875.641602 
	C200.301483,876.268005 200.675705,876.628662 201.256531,877.079224 
	C201.463165,877.169128 201.825058,877.437744 201.890472,877.726257 
	C202.831223,878.734314 203.706589,879.453796 204.795471,880.362061 
	C205.289978,880.806519 205.570969,881.062256 205.895493,881.606079 
	C206.274200,882.601868 206.609375,883.309448 206.957001,884.413330 
	C206.993576,885.864868 207.017700,886.920227 207.041840,887.975586 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M289.010193,950.987793 
	C283.701508,947.802551 278.363525,944.664673 273.096558,941.411865 
	C270.489105,939.801514 268.012421,937.979492 265.135071,936.112793 
	C264.793518,935.972351 264.839752,935.526917 265.164764,935.253845 
	C265.995087,934.659912 266.500427,934.339050 267.334717,934.058350 
	C269.681274,935.121582 271.698853,936.144653 273.773499,937.173462 
	C273.830566,937.179138 273.874359,937.285217 273.963806,937.600342 
	C278.018372,940.630493 281.983521,943.345520 286.020081,946.394897 
	C287.047699,947.168396 288.003876,947.607605 288.975739,948.280151 
	C288.991425,948.513428 289.027985,948.979675 289.022278,949.314392 
	C289.014465,950.095337 289.012329,950.541565 289.010193,950.987793 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M241.364807,919.963257 
	C240.345459,918.536438 239.685135,917.142456 238.998810,915.388306 
	C238.972839,915.028198 239.017639,914.982361 239.388794,914.985840 
	C240.503098,915.003723 241.246216,915.018127 242.291840,915.196228 
	C246.017197,916.330811 246.469727,921.807495 251.292099,921.018005 
	C251.493515,921.075012 251.854523,921.286987 251.878937,921.624329 
	C253.607056,923.638916 255.310745,925.316223 257.011322,927.324829 
	C257.012177,928.097595 257.016144,928.539001 256.996582,929.003784 
	C256.973083,929.027161 256.926727,929.074402 256.562805,929.056519 
	C252.790436,927.035889 249.382004,925.033081 245.973587,923.030273 
	C244.557007,922.018860 243.140411,921.007446 241.364807,919.963257 
z"/>
<path fill="#BDBEBF" opacity="1.000000" stroke="none" 
	d="
M246.015839,923.380066 
	C249.382004,925.033081 252.790436,927.035889 256.633850,929.377686 
	C258.714722,931.489563 260.360626,933.262451 262.006531,935.035400 
	C259.892914,933.976746 257.577332,933.189514 255.709946,931.799988 
	C252.350891,929.300476 249.260635,926.439636 246.015839,923.380066 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M262.338226,935.041382 
	C260.360626,933.262451 258.714722,931.489563 256.997803,929.395508 
	C256.926727,929.074402 256.973083,929.027161 257.368713,929.008179 
	C258.509857,928.990723 259.255402,928.992310 260.336365,929.014709 
	C261.119415,929.026367 261.567047,929.017212 262.223938,929.060425 
	C262.433258,929.112793 262.813293,929.317200 262.800201,929.456177 
	C262.787109,929.595215 262.802368,929.874084 262.661652,930.011963 
	C262.662231,930.453979 262.803497,930.758057 263.066742,931.271851 
	C263.402802,931.738220 263.670532,931.912476 263.996582,932.382385 
	C263.999695,933.517395 263.998138,934.274414 263.996582,935.031433 
	C263.554352,935.036743 263.112152,935.042053 262.338226,935.041382 
z"/>
<path fill="#DEDEDE" opacity="1.000000" stroke="none" 
	d="
M195.416656,886.996338 
	C194.358841,885.556702 193.713409,884.118286 193.381607,882.371216 
	C195.131226,882.707581 196.567200,883.352600 198.245972,884.152588 
	C198.991501,884.873352 199.494217,885.439087 199.996948,886.004883 
	C199.990524,886.178223 199.984085,886.351562 199.957870,886.784912 
	C198.568405,887.029114 197.198715,887.013306 195.416656,886.996338 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M200.340637,885.999146 
	C199.494217,885.439087 198.991501,884.873352 198.213409,883.809692 
	C197.285843,881.883118 196.633636,880.454468 195.981415,879.025818 
	C197.974640,881.101685 199.967880,883.177551 202.010559,885.595825 
	C201.601440,885.956604 201.142899,885.974976 200.340637,885.999146 
z"/>
<path fill="#DEDEDE" opacity="1.000000" stroke="none" 
	d="
M205.978027,894.647095 
	C204.341125,893.237183 202.670471,891.489014 200.993866,889.376099 
	C202.640045,890.777222 204.292160,892.543030 205.978027,894.647095 
z"/>
<path fill="#8B92A5" opacity="1.000000" stroke="none" 
	d="
M264.213013,935.045959 
	C263.998138,934.274414 263.999695,933.517395 264.319946,932.405396 
	C265.427643,932.706360 266.216705,933.362305 267.005737,934.018188 
	C266.500427,934.339050 265.995087,934.659912 265.176331,935.030151 
	C264.862854,935.079590 264.429443,935.060486 264.213013,935.045959 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M473.467224,1024.996094 
	C472.253693,1024.996704 471.507416,1024.993286 470.388367,1024.989014 
	C468.733826,1024.325073 467.520721,1023.347961 466.159027,1023.050171 
	C457.584442,1021.175354 448.976868,1019.451477 440.294495,1017.084656 
	C440.473785,1016.009033 440.738739,1015.526550 441.331665,1015.040405 
	C442.096802,1015.050415 442.533997,1015.063965 443.020020,1015.366333 
	C443.394257,1015.962219 443.719604,1016.269104 444.044983,1016.575989 
	C444.373230,1016.077393 444.701477,1015.578857 445.422852,1015.069458 
	C446.864044,1015.080261 447.912170,1015.101868 449.026428,1015.455322 
	C450.062958,1016.189880 451.033325,1016.592468 452.418457,1017.000061 
	C454.211548,1017.013184 455.589844,1017.021240 457.039856,1017.394531 
	C462.083557,1018.820984 467.055542,1019.882080 472.441650,1020.960449 
	C474.238068,1020.976685 475.620422,1020.975647 477.207581,1021.240845 
	C478.609619,1021.346069 479.806793,1021.184998 481.398560,1021.020508 
	C482.844360,1021.029053 483.895569,1021.041077 485.040497,1021.417725 
	C487.421265,1021.870422 489.708313,1021.958435 492.321411,1022.035522 
	C493.082153,1022.031982 493.516937,1022.039307 494.050659,1022.425659 
	C501.322174,1025.229126 508.586395,1026.019043 516.432617,1024.026611 
	C518.557983,1024.028809 520.257263,1024.035278 522.059937,1024.434570 
	C522.841370,1025.210205 523.517273,1025.922119 524.197754,1025.926514 
	C535.504944,1025.999756 546.813171,1026.032715 558.119263,1025.904053 
	C559.098083,1025.892944 560.063232,1024.684326 561.438843,1024.031250 
	C564.220520,1024.337036 566.598083,1024.643433 569.426758,1024.970581 
	C572.932739,1024.341064 575.987671,1023.690979 579.437988,1023.038574 
	C580.888123,1023.032227 581.942749,1023.028198 583.230835,1023.289368 
	C584.974609,1023.359253 586.484863,1023.163940 588.392822,1022.973816 
	C589.850891,1022.966553 590.911194,1022.954102 592.348511,1022.961487 
	C593.836060,1022.339844 594.946594,1021.698303 596.425537,1021.052368 
	C597.530640,1021.046814 598.267395,1021.045593 599.236450,1021.306580 
	C600.978882,1022.032349 602.488953,1022.495789 603.998474,1023.296997 
	C603.994568,1024.084961 603.991211,1024.535156 603.987793,1024.985352 
	C599.491150,1025.467163 594.994568,1025.949097 590.248413,1026.720703 
	C589.251465,1027.009644 588.504028,1027.008911 587.383179,1027.013916 
	C571.994446,1027.677979 556.978882,1028.858521 541.963928,1028.849976 
	C526.616333,1028.841187 511.269379,1027.665771 495.456604,1027.010254 
	C494.548920,1027.015259 494.106781,1027.010986 493.333008,1027.010742 
	C486.645721,1026.340454 480.290100,1025.666260 473.467224,1024.996094 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M441.003723,1015.043945 
	C440.738739,1015.526550 440.473785,1016.009033 440.104401,1016.746948 
	C439.557007,1017.000732 439.113983,1016.999207 438.338867,1016.992798 
	C437.035461,1015.905334 436.064148,1014.822937 435.400482,1013.418457 
	C437.473328,1013.745667 439.238525,1014.394836 441.003723,1015.043945 
z"/>
<path fill="#BAB9B8" opacity="1.000000" stroke="none" 
	d="
M441.331665,1015.040405 
	C439.238525,1014.394836 437.473328,1013.745667 435.338409,1013.085449 
	C434.529205,1013.064148 434.089691,1013.053894 433.081024,1012.754639 
	C429.998779,1012.003113 427.485718,1011.540466 424.972656,1011.077881 
	C424.532990,1011.063782 424.093353,1011.049622 423.173157,1010.774536 
	C422.136230,1009.976929 421.579834,1009.440369 421.285400,1008.635620 
	C423.426453,1008.441956 425.319336,1008.680359 427.179047,1008.524231 
	C428.164795,1008.441467 429.093079,1007.673767 430.398804,1007.184937 
	C431.453247,1007.190918 432.156158,1007.227112 432.902039,1007.625488 
	C434.614685,1009.005188 436.186127,1010.265564 437.974701,1010.989014 
	C441.595215,1012.453308 445.333466,1013.626587 449.008057,1014.970947 
	C448.992981,1015.021973 448.960266,1015.123474 448.960266,1015.123474 
	C447.912170,1015.101868 446.864044,1015.080261 445.079895,1015.043335 
	C443.886292,1015.044495 443.428741,1015.061035 442.971161,1015.077515 
	C442.533997,1015.063965 442.096802,1015.050415 441.331665,1015.040405 
z"/>
<path fill="#BDBEBF" opacity="1.000000" stroke="none" 
	d="
M371.423218,995.951233 
	C369.065460,994.213623 367.073364,992.488403 365.418793,990.385254 
	C366.415222,990.039307 367.129730,989.915100 367.724609,990.126953 
	C375.459717,992.882202 383.195465,995.638489 390.877197,998.537170 
	C391.795685,998.883789 392.397125,1000.070740 392.731659,1000.907959 
	C391.208618,1000.971680 390.099487,1000.995544 388.990356,1001.019348 
	C388.548065,1001.013367 388.105743,1001.007385 387.267700,1000.639587 
	C382.577332,998.859192 378.282745,997.440613 373.988159,996.022034 
	C373.255066,996.002563 372.521973,995.983093 371.423218,995.951233 
z"/>
<path fill="#BDBEBF" opacity="1.000000" stroke="none" 
	d="
M406.973053,1006.055542 
	C405.922943,1006.032043 404.872864,1006.008545 403.376160,1005.659668 
	C402.276520,1004.910034 401.623505,1004.485779 400.970459,1004.061523 
	C400.531830,1004.046692 400.093140,1004.031982 399.128357,1003.758362 
	C397.402863,1002.629272 396.203430,1001.759094 395.243103,1000.631653 
	C397.058136,1000.645874 398.646729,1000.859375 400.207642,1001.199768 
	C404.476715,1002.130615 408.734161,1003.114868 413.190399,1004.337280 
	C414.612610,1005.710876 415.840607,1006.825134 416.674500,1007.917236 
	C413.177948,1007.281860 410.075500,1006.668701 406.973053,1006.055542 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M407.032990,1006.432129 
	C410.075500,1006.668701 413.177948,1007.281860 416.894836,1008.192749 
	C418.680664,1008.628235 419.852051,1008.766052 421.023438,1008.903809 
	C421.579834,1009.440369 422.136230,1009.976929 422.843262,1010.770142 
	C422.120544,1011.241760 421.176086,1011.786438 420.385895,1011.615967 
	C416.583069,1010.795654 412.798523,1009.862732 409.064972,1008.772827 
	C408.283142,1008.544556 407.743927,1007.485107 407.032990,1006.432129 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M374.055969,996.381104 
	C378.282745,997.440613 382.577332,998.859192 386.935974,1000.637695 
	C382.707947,999.578430 378.415894,998.159302 374.055969,996.381104 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M395.004028,1000.888916 
	C396.203430,1001.759094 397.402863,1002.629272 398.799164,1003.765503 
	C395.710480,1003.270813 392.424896,1002.510071 389.064819,1001.384338 
	C390.099487,1000.995544 391.208618,1000.971680 393.039551,1000.924805 
	C394.175568,1000.897461 394.589783,1000.893188 395.004028,1000.888916 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M425.052795,1011.429688 
	C427.485718,1011.540466 429.998779,1012.003113 432.750977,1012.756836 
	C430.371033,1012.625854 427.751984,1012.203674 425.052795,1011.429688 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M401.022644,1004.377625 
	C401.623505,1004.485779 402.276520,1004.910034 402.982117,1005.651855 
	C402.381409,1005.544189 401.728119,1005.118958 401.022644,1004.377625 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M195.686096,878.867554 
	C196.633636,880.454468 197.285843,881.883118 197.970612,883.654663 
	C196.567200,883.352600 195.131226,882.707581 193.352615,882.026917 
	C192.099731,881.177734 191.189468,880.364258 190.109894,878.930542 
	C188.968201,877.534180 187.995819,876.757935 187.023438,875.981628 
	C185.857010,874.448059 184.690598,872.914429 183.261887,870.818237 
	C182.995819,869.509460 182.992050,868.763245 182.988266,868.017090 
	C184.430984,868.995300 185.873703,869.973511 187.646912,870.982544 
	C190.448502,873.578735 192.919632,876.144043 195.686096,878.867554 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M158.988083,840.986206 
	C163.339661,846.081726 167.676910,851.163452 171.727844,856.487915 
	C170.290665,855.823547 169.139801,854.916382 167.988953,854.009216 
	C167.427856,853.502625 166.866745,852.996094 166.120117,851.905762 
	C164.962601,850.542969 163.990631,849.763855 163.018677,848.984680 
	C162.579498,848.979126 162.140335,848.973633 161.372864,848.961792 
	C161.038925,848.517029 161.033295,848.078613 161.017334,846.963745 
	C160.334076,845.195312 159.661194,844.103333 158.988297,843.011353 
	C158.988297,843.011353 158.999390,843.000000 158.996506,842.666199 
	C158.973770,841.880249 158.953934,841.428101 158.934082,840.975952 
	C158.934082,840.975952 158.973740,840.972351 158.988083,840.986206 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M174.977325,860.388794 
	C177.294418,862.118713 179.611649,864.218689 181.561142,866.662354 
	C180.135178,866.997986 179.076950,866.989929 178.018738,866.981934 
	C177.697098,866.519592 177.375458,866.057251 176.984863,864.979736 
	C176.285461,863.902954 175.655014,863.441528 175.024536,862.980103 
	C175.008850,862.239685 174.993149,861.499268 174.977325,860.388794 
z"/>
<path fill="#DEDEDE" opacity="1.000000" stroke="none" 
	d="
M178.155304,867.285278 
	C179.076950,866.989929 180.135178,866.997986 181.590118,867.007935 
	C182.312103,867.110474 182.574478,867.298767 182.881104,867.795898 
	C182.992050,868.763245 182.995819,869.509460 182.997650,870.629028 
	C181.427765,869.864502 179.859833,868.726562 178.155304,867.285278 
z"/>
<path fill="#DEDEDE" opacity="1.000000" stroke="none" 
	d="
M167.994125,854.373657 
	C169.139801,854.916382 170.290665,855.823547 171.733215,856.853149 
	C172.024887,856.975708 172.017715,856.983276 172.013168,857.317993 
	C172.004318,858.103394 172.000031,858.554138 171.995728,859.004883 
	C170.663574,857.582642 169.331436,856.160339 167.994125,854.373657 
z"/>
<path fill="#DEDEDE" opacity="1.000000" stroke="none" 
	d="
M187.049118,876.324341 
	C187.995819,876.757935 188.968201,877.534180 189.975433,878.649170 
	C189.031815,878.214233 188.053314,877.440674 187.049118,876.324341 
z"/>
<path fill="#DEDEDE" opacity="1.000000" stroke="none" 
	d="
M163.045105,849.326965 
	C163.990631,849.763855 164.962601,850.542969 165.965485,851.662659 
	C165.021439,851.225220 164.046494,850.447266 163.045105,849.326965 
z"/>
<path fill="#DEDEDE" opacity="1.000000" stroke="none" 
	d="
M175.056183,863.303223 
	C175.655014,863.441528 176.285461,863.902954 176.954636,864.684937 
	C176.358170,864.545715 175.722977,864.085999 175.056183,863.303223 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M172.326935,859.009888 
	C172.000031,858.554138 172.004318,858.103394 172.019791,857.314392 
	C172.662170,857.442017 173.293381,857.907837 173.953415,858.697754 
	C173.540878,859.019531 173.099503,859.017212 172.326935,859.009888 
z"/>
<path fill="#DEDEDE" opacity="1.000000" stroke="none" 
	d="
M158.989624,843.384460 
	C159.661194,844.103333 160.334076,845.195312 161.012329,846.634460 
	C160.519516,846.725159 160.021347,846.468689 159.263733,846.104614 
	C158.999832,845.250488 158.995392,844.504028 158.989624,843.384460 
z"/>
<path fill="#DEDEDE" opacity="1.000000" stroke="none" 
	d="
M158.641449,841.020996 
	C158.953934,841.428101 158.973770,841.880249 158.989182,842.671875 
	C158.772766,842.362915 158.560791,841.714539 158.641449,841.020996 
z"/>
<path fill="#DEDEDE" opacity="1.000000" stroke="none" 
	d="
M86.954956,367.988739 
	C86.962929,368.158112 86.970894,368.327484 86.991684,368.750916 
	C85.190269,374.505127 83.376038,380.005219 80.950729,385.752197 
	C79.894539,385.999756 79.449425,386.000488 79.004303,386.001221 
	C78.835907,386.000854 78.667511,386.000519 78.246445,385.999512 
	C78.229637,384.233978 78.465492,382.469208 79.200378,380.299469 
	C80.132248,378.929108 80.565086,377.963684 80.997917,376.998260 
	C81.000587,376.555084 81.003258,376.111938 81.364944,375.270081 
	C82.149666,373.582642 82.575363,372.293915 83.001068,371.005157 
	C83.004242,370.561218 83.007408,370.117249 83.355499,369.306519 
	C84.785271,368.622711 85.870117,368.305725 86.954956,367.988739 
z"/>
<path fill="#E2E2E2" opacity="1.000000" stroke="none" 
	d="
M86.966507,367.613068 
	C85.870117,368.305725 84.785271,368.622711 83.360008,368.973572 
	C84.094261,367.672760 85.168930,366.338013 86.601593,364.991730 
	C86.965752,365.732574 86.971909,366.484985 86.966507,367.613068 
z"/>
<path fill="#E2E2E2" opacity="1.000000" stroke="none" 
	d="
M82.733086,371.193359 
	C82.575363,372.293915 82.149666,373.582642 81.367134,374.937744 
	C81.495232,373.796570 81.980171,372.589050 82.733086,371.193359 
z"/>
<path fill="#E2E2E2" opacity="1.000000" stroke="none" 
	d="
M80.708954,377.139771 
	C80.565086,377.963684 80.132248,378.929108 79.356537,379.949921 
	C79.482437,379.097290 79.951210,378.189270 80.708954,377.139771 
z"/>
<path fill="#E2E2E2" opacity="1.000000" stroke="none" 
	d="
M604.365112,1024.990723 
	C603.991211,1024.535156 603.994568,1024.084961 604.324097,1023.301208 
	C605.083679,1022.965393 605.517090,1022.963135 606.321045,1022.973145 
	C607.795715,1023.326904 608.899963,1023.668335 610.004150,1024.009766 
	C609.229675,1024.340088 608.455200,1024.670410 607.343323,1025.005371 
	C606.251404,1025.005249 605.496887,1025.000732 604.365112,1024.990723 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M610.378540,1024.008423 
	C608.899963,1023.668335 607.795715,1023.326904 606.335938,1022.609863 
	C606.003296,1021.505188 606.026306,1020.776062 606.416992,1020.041260 
	C607.521423,1020.035400 608.258301,1020.035400 609.230225,1020.298828 
	C610.974915,1020.362366 612.484497,1020.162354 614.365845,1019.968750 
	C615.481079,1019.962463 616.224487,1019.949768 617.368164,1019.912476 
	C620.178955,1019.588684 622.589478,1019.289429 625.001343,1019.004456 
	C625.002686,1019.018738 625.012207,1019.048584 625.012207,1019.048584 
	C623.714050,1020.127625 622.415894,1021.206665 621.052002,1022.628052 
	C620.986206,1022.970276 620.492004,1022.986633 620.244751,1022.992065 
	C617.698792,1023.121338 615.399963,1023.245178 613.050598,1023.689392 
	C612.250977,1024.008789 611.502014,1024.007935 610.378540,1024.008423 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M632.998413,1016.959106 
	C632.997437,1017.410034 632.996460,1017.860962 632.992798,1018.649902 
	C630.587219,1018.999268 628.184326,1019.010681 625.396851,1019.035339 
	C625.012207,1019.048584 625.002686,1019.018738 625.001099,1018.675171 
	C625.003357,1017.892517 625.007202,1017.453308 625.417969,1017.008301 
	C628.216003,1016.987976 630.607178,1016.973572 632.998413,1016.959106 
z"/>
<path fill="#DEDEDE" opacity="1.000000" stroke="none" 
	d="
M78.969406,392.642609 
	C77.874474,393.951721 76.788124,394.933380 75.368500,395.979797 
	C75.024536,395.285431 75.013832,394.526245 75.003967,393.388428 
	C75.322807,392.538330 75.640816,392.066864 75.980728,391.297180 
	C76.508263,390.434692 77.013893,389.870422 77.784561,389.451721 
	C78.362816,390.066254 78.676025,390.535217 78.989235,391.004150 
	C78.985489,391.441162 78.981743,391.878143 78.969406,392.642609 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M79.200523,390.720276 
	C78.676025,390.535217 78.362816,390.066254 78.030754,389.300873 
	C78.326744,388.216217 78.641586,387.427979 78.980362,386.320496 
	C79.449425,386.000488 79.894539,385.999756 80.673660,386.003632 
	C80.475708,387.484283 79.943756,388.960358 79.200523,390.720276 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M995.051270,370.960876 
	C996.328003,372.312897 998.253601,373.453613 998.774109,375.051910 
	C1001.729065,384.125946 1004.419800,393.288727 1007.063354,402.460815 
	C1010.303284,413.702087 1013.923096,424.868958 1016.433044,436.276093 
	C1019.184082,448.778351 1020.933533,461.505646 1022.948975,474.162018 
	C1023.486694,477.538544 1023.400513,481.014435 1023.034119,484.479187 
	C1021.655640,482.665894 1020.843872,480.818085 1020.027771,478.642517 
	C1020.024048,477.877014 1020.024597,477.439240 1020.286438,476.778870 
	C1020.049988,475.356049 1019.552307,474.155823 1019.044922,472.586487 
	C1019.032654,471.478912 1019.030151,470.740509 1019.305481,469.743256 
	C1018.732788,465.646423 1017.882202,461.808411 1017.020142,457.548828 
	C1016.493164,454.531616 1018.450073,450.951141 1014.112183,449.518860 
	C1014.103027,448.452393 1014.101807,447.741760 1014.361816,446.767822 
	C1013.328186,438.936249 1012.204651,431.331696 1010.614441,423.826019 
	C1010.231262,422.017181 1008.302368,420.535767 1007.082336,418.586151 
	C1007.080322,417.844055 1007.080322,417.419617 1007.331665,416.789490 
	C1007.706665,415.734375 1007.830139,414.884918 1007.966553,413.618591 
	C1006.339539,409.118103 1004.699524,405.034485 1003.045776,400.582642 
	C1003.021301,399.475891 1003.010681,398.737366 1003.004211,397.586334 
	C1002.722839,395.568665 1002.615295,393.906342 1002.121216,392.368134 
	C999.823181,385.213562 997.418030,378.093384 995.051270,370.960876 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M995.038940,370.634521 
	C997.418030,378.093384 999.823181,385.213562 1002.121216,392.368134 
	C1002.615295,393.906342 1002.722839,395.568665 1002.743530,397.484375 
	C1001.988953,397.525879 1001.499390,397.256805 1000.963623,396.605164 
	C995.722168,382.292694 990.871826,368.221008 985.223694,354.477142 
	C980.593567,343.210327 974.982605,332.348114 969.897034,321.264923 
	C969.305664,319.976166 969.293091,318.421844 969.361328,317.045654 
	C973.412842,323.997620 977.066345,330.921478 980.845764,337.775879 
	C982.047302,339.955139 983.583984,341.949615 984.967346,344.028625 
	C984.967346,344.028625 984.979797,344.512756 984.988281,345.149384 
	C987.193298,351.401001 989.316711,357.046234 991.622375,362.616058 
	C992.539001,364.830383 993.859497,366.877625 994.996094,369.000916 
	C995.006287,369.436646 995.016479,369.872406 995.038940,370.634521 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M1024.975586,502.005249 
	C1025.648682,504.486908 1026.885498,506.963928 1026.905884,509.450958 
	C1027.066772,529.040894 1027.057129,548.633240 1026.931030,568.223694 
	C1026.911133,571.310059 1026.039917,574.390930 1024.984009,577.452881 
	C1024.265381,574.616699 1024.125000,571.802002 1023.988159,568.589111 
	C1023.989624,567.127197 1023.987549,566.063599 1024.281738,564.712585 
	C1024.698608,560.602051 1024.819214,556.778931 1024.948120,552.490723 
	C1024.949707,546.365356 1024.943115,540.705017 1024.957275,534.573669 
	C1024.661255,524.943665 1024.293579,515.785889 1024.076782,506.624512 
	C1024.040649,505.092712 1024.659424,503.545410 1024.975586,502.005249 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M1024.979126,501.632599 
	C1024.659424,503.545410 1024.040649,505.092712 1024.076782,506.624512 
	C1024.293579,515.785889 1024.661255,524.943665 1024.633545,534.562622 
	C1023.854797,535.016541 1023.420837,535.010498 1022.989441,534.550415 
	C1022.671082,528.729980 1022.350281,523.363647 1022.309631,517.724121 
	C1022.089783,514.947754 1021.589722,512.444519 1021.076416,509.500916 
	C1021.052979,506.704742 1021.042725,504.349030 1021.278931,501.808105 
	C1022.005615,500.750977 1022.485779,499.879028 1023.300781,499.006409 
	C1024.081909,499.011932 1024.528198,499.018188 1024.974365,499.024445 
	C1024.977173,499.769592 1024.979858,500.514771 1024.979126,501.632599 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M984.976562,343.644226 
	C983.583984,341.949615 982.047302,339.955139 980.845764,337.775879 
	C977.066345,330.921478 973.412842,323.997620 969.349243,316.806824 
	C968.987976,316.513031 968.965515,316.035156 968.982666,315.655334 
	C969.002197,314.515045 969.004639,313.754517 969.007141,312.994019 
	C970.832153,314.726929 973.370483,316.128784 974.355835,318.251465 
	C978.167664,326.462921 981.484497,334.904175 984.976562,343.644226 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M949.005493,278.995178 
	C949.995728,279.504364 951.411255,279.752869 951.908508,280.564209 
	C955.694885,286.741394 959.301880,293.028503 962.609802,299.610474 
	C959.844543,297.287048 957.434204,294.634552 955.004272,291.747986 
	C954.984741,291.513916 954.955627,291.044678 954.934326,290.720337 
	C954.291870,289.919739 953.670654,289.443512 953.012451,288.674255 
	C952.652710,287.917725 952.330017,287.454224 952.008179,286.615936 
	C951.345398,285.157532 950.681763,284.073914 949.902954,282.737183 
	C949.513062,282.001862 949.238281,281.519684 948.980347,280.697388 
	C948.999939,279.903229 949.002747,279.449219 949.005493,278.995178 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M995.250122,368.785919 
	C993.859497,366.877625 992.539001,364.830383 991.622375,362.616058 
	C989.316711,357.046234 987.193298,351.401001 985.000854,345.391174 
	C986.723877,347.725067 988.839172,350.286957 990.073425,353.219635 
	C992.175354,358.213989 993.723389,363.441437 995.250122,368.785919 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M935.014648,258.985352 
	C940.875061,262.490509 943.819702,268.079468 945.694702,274.635620 
	C945.274536,275.020630 945.092773,275.032593 944.658081,274.851654 
	C943.256958,273.127167 942.108643,271.595612 940.969116,269.702911 
	C940.314087,268.562103 939.650146,267.782440 938.928711,266.679443 
	C937.578918,264.904816 936.286743,263.453522 934.996582,261.627228 
	C934.995728,260.504822 934.992798,259.757477 934.995361,259.004578 
	C935.000854,258.999084 935.014648,258.985352 935.014648,258.985352 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M1024.975586,580.976929 
	C1024.398926,587.442444 1023.822388,593.908020 1022.841736,600.554199 
	C1022.260986,600.191528 1022.084290,599.648193 1021.921021,598.794067 
	C1021.629395,597.989502 1021.324219,597.495789 1021.019287,596.587769 
	C1021.032837,594.795959 1021.046143,593.418396 1021.436401,591.946777 
	C1022.199829,589.561707 1022.586365,587.270630 1022.978516,584.582581 
	C1022.982239,583.123718 1022.980347,582.061829 1023.311523,580.997925 
	C1024.088257,580.989563 1024.531860,580.983276 1024.975586,580.976929 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M1024.979004,580.604614 
	C1024.531860,580.983276 1024.088257,580.989563 1022.983521,580.999817 
	C1021.884338,581.002502 1021.446228,581.001221 1021.010254,580.539673 
	C1021.039307,576.407288 1021.066040,572.735229 1021.454102,569.041260 
	C1022.538513,569.008667 1023.261597,568.997986 1023.984619,568.987366 
	C1024.125000,571.802002 1024.265381,574.616699 1024.692505,577.713562 
	C1024.980225,578.741333 1024.981201,579.486816 1024.979004,580.604614 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M928.989990,249.012817 
	C930.974670,252.102005 932.959351,255.191162 934.979370,258.632843 
	C935.014648,258.985352 935.000854,258.999084 934.624939,259.001129 
	C933.505859,258.995209 932.762695,258.987274 931.977600,258.628906 
	C929.626648,254.516708 927.317627,250.754929 925.334473,247.018524 
	C926.770203,247.700180 927.880127,248.356506 928.989990,249.012817 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M1020.032104,478.970276 
	C1020.843872,480.818085 1021.655640,482.665894 1022.752075,484.761292 
	C1023.043213,487.046387 1023.049805,489.083954 1023.026245,491.560669 
	C1022.996155,491.999847 1022.903198,492.008545 1022.554077,491.935913 
	C1021.138611,490.239655 1020.072327,488.616089 1018.983398,486.760132 
	C1018.960754,486.527740 1018.931335,486.061646 1018.964844,485.638794 
	C1019.004272,483.811005 1019.010193,482.406128 1019.260681,480.829132 
	C1019.680908,480.094788 1019.856506,479.532532 1020.032104,478.970276 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M955.023865,291.982056 
	C957.434204,294.634552 959.844543,297.287048 962.605408,299.988403 
	C962.955933,300.037231 962.982117,300.517365 962.989197,301.156860 
	C963.002563,302.860291 963.008850,303.924286 962.995605,305.005371 
	C962.976013,305.022491 962.938232,305.058289 962.600098,304.988708 
	C959.849304,300.606812 957.436584,296.294464 955.023865,291.982056 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M969.003296,312.667297 
	C969.004639,313.754517 969.002197,314.515045 968.631348,315.606934 
	C966.487976,312.311615 964.713135,308.684967 962.938232,305.058289 
	C962.938232,305.058289 962.976013,305.022491 963.286499,305.132629 
	C964.388611,305.503876 965.180298,305.764954 965.971924,306.026031 
	C965.971924,306.026031 965.996033,306.507233 966.002808,307.112671 
	C966.992981,308.823700 967.976379,309.929230 968.959778,311.034790 
	C968.973022,311.470062 968.986206,311.905304 969.003296,312.667297 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M1020.967773,616.544800 
	C1019.874939,618.276855 1018.795837,619.582703 1017.343628,620.684814 
	C1016.951721,620.301514 1016.932922,620.121948 1016.932251,619.575439 
	C1016.949829,618.470398 1016.949219,617.732361 1017.236389,616.758789 
	C1018.033875,614.002197 1018.543518,611.481079 1019.289490,609.125610 
	C1020.023682,609.856689 1020.521606,610.422119 1021.019531,610.987610 
	C1021.006836,612.697937 1020.994080,614.408264 1020.967773,616.544800 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M928.991150,248.646393 
	C927.880127,248.356506 926.770203,247.700180 925.330383,247.021790 
	C925.000488,246.999710 925.006958,246.993240 925.006958,246.644012 
	C924.326965,245.206818 923.646973,244.118851 922.953247,242.712509 
	C922.917542,241.971725 922.895569,241.549316 922.909424,241.090927 
	C922.945129,241.054947 923.017090,240.983459 923.389465,240.987885 
	C924.507080,240.995682 925.252319,240.999054 925.997559,241.002441 
	C926.318115,241.468140 926.638611,241.933823 926.984436,242.695557 
	C927.670593,244.754395 928.331421,246.517166 928.991150,248.646393 
z"/>
<path fill="#B0B1B4" opacity="1.000000" stroke="none" 
	d="
M1021.907532,599.104858 
	C1022.084290,599.648193 1022.260986,600.191528 1022.667358,600.912231 
	C1022.874939,601.495605 1022.852966,601.901672 1022.474121,602.667725 
	C1021.068298,603.352295 1020.019226,603.676758 1018.637817,604.001221 
	C1017.861267,603.995361 1017.417053,603.989502 1016.975342,603.613037 
	C1016.988403,602.501099 1016.999084,601.759705 1017.332947,600.972229 
	C1018.120361,600.272583 1018.584595,599.619019 1019.265015,599.107727 
	C1020.289917,599.201599 1021.098755,599.153259 1021.907532,599.104858 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M944.910950,275.044586 
	C945.092773,275.032593 945.274536,275.020630 945.729858,275.003479 
	C946.003296,274.998352 946.496033,275.001007 946.749390,275.358246 
	C947.661316,276.483582 948.319763,277.251678 948.978271,278.019745 
	C948.978271,278.019745 948.997070,278.507568 949.001282,278.751373 
	C949.002747,279.449219 948.999939,279.903229 948.623413,280.651489 
	C947.136719,278.978638 946.023804,277.011627 944.910950,275.044586 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M1021.078125,610.643188 
	C1020.521606,610.422119 1020.023682,609.856689 1019.281250,608.798218 
	C1019.020264,607.867798 1019.003723,607.430481 1019.002197,606.751709 
	C1019.017151,606.510193 1019.057678,606.027954 1019.444458,606.023438 
	C1020.857178,605.993774 1021.883179,605.968689 1022.909119,605.943604 
	C1022.318298,607.395325 1021.727539,608.847046 1021.078125,610.643188 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M1024.987183,498.598999 
	C1024.528198,499.018188 1024.081909,499.011932 1023.308105,498.568359 
	C1022.954773,496.090179 1022.928955,494.049377 1022.903198,492.008545 
	C1022.903198,492.008545 1022.996155,491.999847 1023.043945,491.997192 
	C1023.727783,494.054199 1024.363892,496.113892 1024.987183,498.598999 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M965.979187,305.656250 
	C965.180298,305.764954 964.388611,305.503876 963.306030,305.115540 
	C963.008850,303.924286 963.002563,302.860291 962.999390,301.397034 
	C963.997131,302.427338 964.991760,303.856873 965.979187,305.656250 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M1022.927795,605.564941 
	C1021.883179,605.968689 1020.857178,605.993774 1019.416260,605.685974 
	C1018.990906,604.902466 1018.980530,604.451904 1018.970154,604.001282 
	C1020.019226,603.676758 1021.068298,603.352295 1022.435181,602.971130 
	C1022.817505,603.671753 1022.881958,604.429016 1022.927795,605.564941 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M968.970337,310.667664 
	C967.976379,309.929230 966.992981,308.823700 966.011475,307.353455 
	C967.002563,308.092712 967.991699,309.196655 968.970337,310.667664 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M948.983337,277.662933 
	C948.319763,277.251678 947.661316,276.483582 946.995728,275.362915 
	C947.655273,275.775604 948.321838,276.540863 948.983337,277.662933 
z"/>
<path fill="#8089A6" opacity="1.000000" stroke="none" 
	d="
M1017.009766,601.018372 
	C1016.999084,601.759705 1016.988403,602.501099 1016.700684,603.868347 
	C1016.255859,607.670776 1016.088013,610.847351 1015.922668,614.340210 
	C1015.924500,615.078186 1015.923889,615.499939 1015.573425,616.010803 
	C1014.826843,617.406128 1014.430054,618.712280 1014.020996,620.347168 
	C1013.996704,621.114075 1013.984802,621.552368 1013.690308,622.260620 
	C1012.633484,627.040161 1011.859131,631.549744 1011.064148,636.378357 
	C1011.027771,637.123474 1011.012024,637.549438 1010.778931,638.087524 
	C1010.290527,638.405823 1010.116882,638.674988 1010.022339,639.445068 
	C1009.639771,643.554382 1009.275696,647.225769 1008.598267,650.969604 
	C1008.156311,652.042358 1008.027893,653.042664 1007.906372,654.397095 
	C1007.897888,655.455444 1007.882446,656.159607 1007.502563,656.943115 
	C1006.366943,658.354492 1005.296997,659.598450 1004.873779,661.033142 
	C1002.311646,669.717407 999.913391,678.449768 997.428162,687.156921 
	C995.633789,693.443481 993.789307,699.715759 991.946655,706.226929 
	C991.926208,706.459473 991.919189,706.926208 991.711975,707.054077 
	C991.247192,707.400635 991.081726,707.674866 990.963501,708.379028 
	C988.914795,714.464905 986.911072,720.176514 984.564087,725.962769 
	C982.832336,728.438049 981.267639,730.755310 980.086487,733.253845 
	C975.367371,743.236694 971.372498,753.615295 965.986145,763.217163 
	C957.197266,778.884460 947.672913,794.148071 938.162354,809.397095 
	C934.346375,815.515686 929.664429,821.088135 925.526794,827.014038 
	C921.878601,832.238892 918.439575,837.609802 914.908264,842.916260 
	C913.685791,844.076965 912.463318,845.237671 910.799316,846.837158 
	C898.232239,860.138733 886.668335,873.596558 873.834106,885.708252 
	C858.973572,899.732300 843.055786,912.643494 827.428162,925.837891 
	C825.452332,927.505981 822.454163,927.963196 819.980835,928.639771 
	C820.828857,927.493530 821.555359,926.601074 822.433228,925.897583 
	C832.794006,917.595764 843.469238,909.659119 853.497803,900.975281 
	C879.052673,878.847290 902.530823,854.614563 922.213379,827.113342 
	C935.912170,807.972839 948.581604,788.034973 960.692322,767.842407 
	C967.479309,756.526123 972.637512,744.151062 977.726013,731.918762 
	C984.024963,716.776672 989.943359,701.441345 995.208374,685.912170 
	C1002.731750,663.722412 1008.673828,641.065125 1011.402954,617.721130 
	C1013.809143,597.138733 1016.417175,576.551514 1017.820068,555.890625 
	C1018.695740,542.995056 1018.095215,529.912659 1017.092346,516.996826 
	C1015.680969,498.820160 1013.714966,480.659973 1011.203796,462.601532 
	C1007.449463,435.603394 1000.347839,409.390839 991.302368,383.674408 
	C982.314697,358.122070 971.935059,333.208160 958.053101,309.896820 
	C949.590759,295.686401 941.511902,281.133087 931.763916,267.830627 
	C918.468811,249.687622 904.529602,231.882355 889.452026,215.211639 
	C867.445923,190.880310 842.715027,169.378464 815.515320,150.867630 
	C804.956543,143.681854 794.681458,136.002396 783.686646,129.554840 
	C771.836182,122.605446 759.489929,116.409943 747.003113,110.660629 
	C731.925842,103.718620 716.727356,96.884834 701.123474,91.278427 
	C678.332458,83.089699 654.917786,76.892227 631.086182,72.306404 
	C606.083740,67.495285 580.871338,64.606796 555.450256,64.042130 
	C540.826050,63.717297 526.106628,63.764816 511.554352,65.050713 
	C490.778473,66.886559 469.956573,69.120407 449.444885,72.802849 
	C421.501556,77.819481 394.123840,85.390556 367.961304,96.712326 
	C351.223938,103.955360 334.146881,110.622131 318.095428,119.184479 
	C301.041321,128.281693 284.823944,139.013351 268.593414,149.572632 
	C246.525238,163.929779 226.516541,180.952972 207.929474,199.528717 
	C196.423111,211.028061 185.229034,222.954163 174.987061,235.575104 
	C162.486557,250.979187 150.269043,266.756165 139.526138,283.402496 
	C129.033447,299.661102 120.215416,317.042725 111.270958,334.247803 
	C99.616066,356.666473 90.622307,380.263489 83.977501,404.593018 
	C78.699425,423.918365 74.881943,443.648041 70.598679,463.238159 
	C66.185081,483.424469 64.649879,503.978180 64.106651,524.550903 
	C63.711887,539.501160 63.875286,554.539429 65.047905,569.439392 
	C66.516418,588.099243 68.718994,606.745789 71.657539,625.230652 
	C73.812370,638.785583 77.075218,652.241089 80.898834,665.435303 
	C86.156540,683.578247 91.332100,701.877747 98.523674,719.292175 
	C106.614326,738.883850 116.436287,757.817017 126.414879,776.552063 
	C132.718552,788.387512 140.639343,799.367004 147.927490,810.670654 
	C152.127762,817.185120 156.512589,823.580505 160.505600,830.026855 
	C159.781464,830.026428 159.366058,830.028564 158.796402,829.732361 
	C156.761261,827.958191 154.880356,826.482422 152.999466,825.006592 
	C152.999466,825.006592 153.000610,825.000000 152.973801,824.645447 
	C150.295181,820.863159 147.643372,817.435425 144.991562,814.007690 
	C144.991562,814.007690 145.000000,814.000000 145.007172,813.633545 
	C142.369202,809.813232 139.724075,806.359436 137.078934,802.905640 
	C137.078934,802.905640 137.050842,802.459351 137.036880,801.862671 
	C135.016205,798.501648 133.009491,795.737366 131.002762,792.973022 
	C131.002762,792.973022 130.957169,792.522644 130.970718,791.919067 
	C127.886368,785.580750 124.902718,779.780029 121.648544,774.135315 
	C119.635773,770.643860 117.211563,767.389709 114.972054,764.028992 
	C114.972054,764.028992 114.992538,764.000000 114.995544,763.622681 
	C114.468994,761.725281 114.084084,760.133972 113.378601,758.700378 
	C112.062988,756.026978 110.392281,753.521118 109.171532,750.809509 
	C106.619934,745.141907 104.340584,739.351379 101.763374,733.696045 
	C101.262123,732.596130 99.915466,731.881531 98.958755,730.989197 
	C98.946716,730.553711 98.934677,730.118164 99.131432,729.195923 
	C98.575775,728.115295 97.811325,727.521362 97.046867,726.927490 
	C97.046860,726.927490 97.037720,726.470337 97.026855,725.871826 
	C94.162170,717.659119 91.320435,710.040344 88.410599,702.447693 
	C88.293236,702.141418 87.531845,702.081970 87.070511,701.907532 
	C87.044205,701.496216 87.017906,701.084900 86.998886,699.958862 
	C85.009430,693.817078 83.012680,688.390076 81.015930,682.963074 
	C81.005013,682.537354 80.994095,682.111572 81.001343,680.966309 
	C80.343460,677.235901 79.710052,674.213684 78.916588,671.234070 
	C78.847412,670.974365 77.725067,670.995056 77.091911,670.885559 
	C77.062744,670.176086 77.033577,669.466675 77.001526,668.001099 
	C76.740334,665.015564 76.629654,662.752686 76.136330,660.576538 
	C75.982574,659.898315 74.793999,659.454773 74.079163,658.903809 
	C74.041260,657.562927 74.003357,656.221985 73.947037,654.082764 
	C72.627953,646.674744 71.441154,640.038818 69.933052,633.476807 
	C69.621750,632.122253 68.113739,631.042725 67.157303,629.836426 
	C67.139404,629.135498 67.121506,628.434570 67.375740,627.117065 
	C67.086868,623.329590 66.525864,620.158691 65.964859,616.987793 
	C65.964188,616.557800 65.963516,616.127808 65.988647,615.001709 
	C65.677216,613.197388 65.339981,612.089172 65.002747,610.980957 
	C64.994965,610.554077 64.987183,610.127258 64.996887,608.939819 
	C64.338821,604.781433 63.663288,601.383606 62.987755,597.985718 
	C62.976067,596.618042 62.964378,595.250366 62.982883,593.016968 
	C62.321056,565.412964 61.629032,538.674622 60.937012,511.936249 
	C60.955433,510.891174 60.973854,509.846130 61.295357,508.155823 
	C62.044582,500.986908 62.490726,494.463165 62.936871,487.939453 
	C62.942551,487.511505 62.948235,487.083557 63.252148,486.089844 
	C64.317978,480.376343 65.098648,475.230499 65.849960,470.080383 
	C66.877296,463.038208 67.882301,455.992767 68.896881,448.948730 
	C68.915070,448.519226 68.933258,448.089722 69.235435,447.134583 
	C70.695000,441.747742 71.960701,436.905670 72.995178,432.014648 
	C73.337524,430.395996 73.084274,428.651398 73.103348,426.964386 
	C73.102852,426.558472 73.102341,426.152527 73.445755,425.349426 
	C74.227928,422.959259 74.666183,420.966278 75.104431,418.973328 
	C75.105988,418.562805 75.107552,418.152252 75.452866,417.341492 
	C78.176422,410.516998 80.606766,404.110352 82.864456,397.643463 
	C83.113564,396.929901 82.374756,395.871429 82.094131,394.972900 
	C82.098244,394.564178 82.102364,394.155457 82.458221,393.347534 
	C84.571014,388.772675 86.474037,384.650055 88.060020,380.408905 
	C90.470734,373.962341 92.661552,367.433594 94.945770,360.939758 
	C94.956787,360.513458 94.967804,360.087189 95.302956,359.190582 
	C98.082466,352.801300 100.537842,346.882294 102.993210,340.963287 
	C102.993202,340.963287 103.043419,340.535858 103.402077,340.244812 
	C104.169258,338.954529 104.577782,337.955231 104.986298,336.955963 
	C105.632927,335.858826 106.279556,334.761719 107.236115,333.150085 
	C108.679047,330.068176 109.812042,327.500763 110.945053,324.933319 
	C110.945053,324.933319 110.970589,324.480072 111.300049,324.100433 
	C114.085983,318.815369 116.542442,313.909912 118.998909,309.004486 
	C118.998909,309.004486 119.000603,309.000000 119.318802,308.862366 
	C120.815697,307.419739 122.229218,306.259308 123.135765,304.786774 
	C130.109192,293.459778 137.007950,282.086517 143.871246,270.692291 
	C145.373795,268.197784 146.641006,265.561554 148.279251,262.786285 
	C148.708939,261.722290 148.875961,260.862610 149.042984,260.002899 
	C149.042984,260.002899 149.112488,259.606201 149.427475,259.368652 
	C150.161209,259.097382 150.579941,259.063660 151.324799,258.991760 
	C152.094498,258.300049 152.538101,257.646545 152.988724,256.996674 
	C152.995758,257.000275 152.991455,256.981476 153.301392,256.848175 
	C155.052551,254.449142 156.493790,252.183411 157.935013,249.917679 
	C157.935013,249.917679 157.983032,249.492294 158.357788,249.235062 
	C160.159073,246.989410 161.585587,245.000992 163.012115,243.012573 
	C163.012115,243.012573 163.000610,243.000000 163.354218,242.985199 
	C164.786926,241.630569 165.866028,240.290741 166.945129,238.950928 
	C177.860458,226.836624 188.775772,214.722336 200.167145,202.269196 
	C201.094864,201.284012 201.546539,200.637711 201.998199,199.991394 
	C206.884094,195.198456 211.769974,190.405502 217.182617,185.293030 
	C218.152756,184.331207 218.596130,183.688904 219.039490,183.046600 
	C219.039505,183.046600 219.004196,183.007919 219.383972,183.020416 
	C222.865906,180.371902 225.968063,177.710861 229.070221,175.049835 
	C234.590347,170.562347 240.110474,166.074875 246.146057,161.269135 
	C247.139069,160.319702 247.616623,159.688507 248.094177,159.057312 
	C248.559341,158.781342 249.024506,158.505402 250.088486,158.084534 
	C252.791122,156.293854 254.894943,154.648102 256.999390,153.001175 
	C257.000000,153.000000 257.002441,152.998779 257.347565,152.974030 
	C259.170013,151.328781 260.647369,149.708282 262.426208,148.070557 
	C263.128784,148.018570 263.529877,147.983795 264.292542,147.946686 
	C266.463074,146.321869 268.272095,144.699387 270.310486,143.066315 
	C270.539856,143.055740 270.997223,143.014648 271.367371,143.015564 
	C275.517609,140.362991 279.297699,137.709534 283.302521,135.043488 
	C283.527252,135.030899 283.976135,134.997421 284.331757,134.973541 
	C286.153931,133.655319 287.620483,132.360992 289.311584,131.053680 
	C289.536163,131.040680 289.985077,131.011322 290.347595,130.985596 
	C296.728760,127.579704 302.796844,124.283318 308.740723,120.776436 
	C310.987335,119.450920 312.947052,117.639229 315.020020,116.032684 
	C315.002441,116.016212 315.037323,116.038559 315.427246,116.038246 
	C319.245728,114.057770 322.674286,112.077599 326.456421,110.043732 
	C329.868805,108.663628 332.927704,107.337227 336.191162,106.261887 
	C336.869476,106.535561 337.412689,106.723083 337.806854,106.556961 
	C351.160553,100.929268 364.511505,95.294563 377.822388,89.566833 
	C379.037598,89.043938 379.982788,87.893677 381.368317,87.022278 
	C382.103180,86.991318 382.523071,86.972839 383.351013,86.977798 
	C387.396362,85.972908 391.020508,84.895142 394.673218,83.924889 
	C409.164642,80.075554 423.583405,75.905182 438.187897,72.543709 
	C449.900604,69.847832 461.837830,68.121094 473.684967,66.018150 
	C479.786743,64.935028 485.909363,63.969433 492.340698,62.954208 
	C493.082977,62.951859 493.507141,62.946037 494.384827,62.970600 
	C502.567139,62.316463 510.295898,61.631943 518.434387,60.947548 
	C521.892029,60.648300 524.939880,60.348930 528.287292,60.340565 
	C536.725769,60.761692 544.864502,60.909237 553.003845,61.012028 
	C555.676941,61.045788 558.351562,60.963570 561.351135,60.946434 
	C562.111328,60.959660 562.545898,60.960835 563.264954,61.265472 
	C571.722473,62.026154 579.895508,62.483372 588.386475,62.946930 
	C589.128601,62.952362 589.552673,62.951454 590.250793,63.252876 
	C594.708374,64.053207 598.885193,64.623070 603.078491,65.017525 
	C605.042908,65.202316 607.039062,65.048630 609.409668,65.048729 
	C610.835144,65.059608 611.871826,65.070107 613.017090,65.453430 
	C613.635315,66.190102 614.100769,66.772919 614.660889,66.885872 
	C629.787842,69.935814 644.924438,72.937599 660.380371,75.956665 
	C661.129272,75.967453 661.557312,75.969070 662.162842,76.226669 
	C663.232178,76.356194 664.124023,76.229736 665.329346,76.095879 
	C666.060730,76.094009 666.478638,76.099541 666.970276,76.462494 
	C671.700867,78.223221 676.357788,79.626534 681.332336,81.033440 
	C682.073242,81.048920 682.496521,81.060814 682.996887,81.423706 
	C689.056091,83.544823 695.038269,85.314934 701.242615,87.084961 
	C701.464844,87.084869 701.909058,87.070282 702.082397,87.370621 
	C709.579346,91.088600 716.932861,94.443886 724.220642,97.936073 
	C742.993042,106.931396 762.056824,115.390457 780.379272,125.227776 
	C792.711792,131.849075 803.958984,140.484863 815.734680,148.153519 
	C820.424988,151.207947 825.230957,154.084732 830.139893,157.340698 
	C840.182983,165.766022 850.070496,173.893066 860.104858,182.313538 
	C861.477661,183.428253 862.703674,184.249527 863.987000,185.384857 
	C864.689209,186.145996 865.334045,186.593079 866.133545,187.336670 
	C873.646545,194.980392 881.138611,202.199692 888.325745,209.710815 
	C895.464417,217.171326 902.555298,224.707809 909.192444,232.609711 
	C916.389465,241.178268 923.038513,250.207077 929.982544,259.354218 
	C930.674133,260.149719 931.309692,260.626221 931.968384,261.326294 
	C931.991516,261.549896 931.997559,261.999390 931.997559,262.365936 
	C935.166199,267.497375 938.429749,272.202393 941.484619,277.039246 
	C948.622559,288.341370 956.110046,299.456635 962.600830,311.123322 
	C968.103210,321.013458 972.784668,331.412598 977.155090,341.868378 
	C984.379883,359.153412 992.627197,376.066895 997.285400,394.346588 
	C997.691345,395.939850 998.429749,397.448364 999.048645,399.316345 
	C999.706909,400.114777 1000.330200,400.593079 1000.954773,401.392090 
	C1000.961121,402.140442 1000.966125,402.568024 1000.969299,403.408722 
	C1002.270874,407.237946 1000.470642,411.853760 1004.939636,414.051422 
	C1004.956909,414.049957 1004.977722,414.022186 1004.964355,414.394043 
	C1004.960144,415.510559 1004.969299,416.255249 1004.973999,417.432831 
	C1006.980469,426.584167 1008.991455,435.302582 1011.008911,444.340210 
	C1011.037415,445.084503 1011.059509,445.509613 1011.052551,446.368286 
	C1012.183044,452.692169 1013.326721,458.585724 1014.551636,464.462311 
	C1014.609680,464.740601 1015.389709,464.868378 1015.871948,465.423889 
	C1015.903809,466.501251 1015.897217,467.221039 1015.639160,468.200714 
	C1015.899902,470.006683 1016.412109,471.552795 1016.930176,473.458313 
	C1016.935608,474.536499 1016.935120,475.255341 1016.696289,476.169189 
	C1016.631958,477.249664 1016.806030,478.135071 1016.981201,479.346008 
	C1016.992493,480.105469 1017.002625,480.539398 1016.893372,481.269348 
	C1017.493103,483.064117 1018.212219,484.562897 1018.931335,486.061646 
	C1018.931335,486.061646 1018.960754,486.527740 1018.960693,487.226624 
	C1018.997681,492.600586 1019.034668,497.275726 1019.048096,502.421265 
	C1019.028870,528.936646 1019.033264,554.981628 1019.026123,581.419556 
	C1019.014587,582.866089 1019.014648,583.919495 1018.992554,585.344360 
	C1018.956970,587.485046 1018.943542,589.254333 1018.933105,591.337769 
	C1018.937073,592.070923 1018.938049,592.489868 1018.563049,593.020264 
	C1017.794617,595.760559 1017.402222,598.389465 1017.009766,601.018372 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M819.929688,928.981384 
	C822.454163,927.963196 825.452332,927.505981 827.428162,925.837891 
	C843.055786,912.643494 858.973572,899.732300 873.834106,885.708252 
	C886.668335,873.596558 898.232239,860.138733 910.653442,847.119141 
	C910.710510,848.393311 910.973999,850.273376 910.156982,851.187195 
	C899.901306,862.655945 889.481201,873.977539 879.127502,885.358765 
	C877.694885,886.933655 876.393311,888.627808 874.988342,890.606750 
	C874.131470,891.852539 873.317322,892.758301 871.866516,893.811890 
	C868.467163,896.620178 865.704407,899.280762 862.941650,901.941284 
	C862.829224,902.252075 862.642029,902.508362 861.806641,902.837524 
	C860.084778,903.544678 858.801392,903.952515 857.809204,904.731262 
	C849.882507,910.952454 841.892639,917.102661 834.174072,923.575012 
	C830.473206,926.678223 827.317444,930.431580 823.914795,933.890442 
	C823.808350,934.189270 823.621887,934.424683 822.901245,934.450012 
	C818.940063,935.514648 815.433105,936.726013 811.933105,937.696899 
	C811.940186,937.456360 811.932068,936.973816 812.163635,936.868713 
	C812.589478,936.492371 812.783752,936.221191 813.318726,935.928833 
	C814.449097,934.943970 815.238953,933.980347 816.050720,932.745361 
	C816.103699,932.115479 816.134705,931.756958 816.368469,931.278259 
	C816.571228,931.158142 817.017517,931.006958 817.326782,930.998779 
	C818.127869,930.424744 818.619690,929.858826 819.306396,929.189880 
	C819.501343,929.086792 819.929688,928.981384 819.929688,928.981384 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M915.255798,842.906921 
	C918.439575,837.609802 921.878601,832.238892 925.526794,827.014038 
	C929.664429,821.088135 934.346375,815.515686 938.162354,809.397095 
	C947.672913,794.148071 957.197266,778.884460 965.986145,763.217163 
	C971.372498,753.615295 975.367371,743.236694 980.086487,733.253845 
	C981.267639,730.755310 982.832336,728.438049 984.598511,726.357788 
	C984.998352,727.466064 985.020447,728.253967 985.042603,729.041931 
	C985.018372,729.771240 984.994080,730.500610 984.632690,731.648926 
	C983.880981,732.729858 983.466492,733.391846 983.051941,734.053772 
	C983.016174,734.766174 982.980347,735.478516 982.928955,736.544800 
	C980.466248,741.223328 977.965942,745.518860 975.581543,749.877869 
	C967.908264,763.906067 960.815979,778.287109 952.480469,791.908386 
	C943.986389,805.789001 934.401733,819.012695 925.039917,832.345215 
	C922.346924,836.180481 918.773254,839.397278 915.255798,842.906921 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M979.613525,749.029297 
	C978.216431,752.622437 976.463318,756.261475 974.363770,759.960815 
	C975.764160,756.372437 977.510864,752.723816 979.613525,749.029297 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M982.690125,741.157349 
	C982.243896,742.964966 981.487793,744.929932 980.376648,746.960754 
	C980.807861,745.122620 981.594055,743.218628 982.690125,741.157349 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M926.717773,835.151184 
	C927.008545,835.764404 927.006042,836.510986 927.005432,837.631226 
	C927.007324,838.004883 926.517029,838.018188 926.272339,838.030762 
	C925.858826,838.034790 925.690002,838.026306 925.267944,838.012634 
	C925.484619,837.099731 925.954590,836.192078 926.717773,835.151184 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M922.720459,840.157104 
	C922.573486,840.983154 922.132446,841.940674 921.348145,842.949707 
	C921.478699,842.097046 921.952515,841.192871 922.720459,840.157104 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M920.635254,844.029297 
	C920.507629,844.886719 920.037109,845.803345 919.270752,846.855896 
	C919.414062,846.024109 919.853210,845.056274 920.635254,844.029297 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M928.683350,833.050232 
	C928.555542,833.648621 928.106140,834.286255 927.338745,834.967163 
	C927.467712,834.370178 927.914795,833.729797 928.683350,833.050232 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M909.674194,857.033447 
	C909.547241,857.635254 909.099426,858.278625 908.330750,858.963379 
	C908.457703,858.361572 908.905457,857.718384 909.674194,857.033447 
z"/>
<path fill="#BAB9B8" opacity="1.000000" stroke="none" 
	d="
M985.387939,728.970581 
	C985.020447,728.253967 984.998352,727.466064 984.941772,726.283081 
	C986.911072,720.176514 988.914795,714.464905 991.184448,708.265137 
	C991.731873,707.562378 991.888123,707.278809 991.919189,706.926208 
	C991.919189,706.926208 991.926208,706.459473 992.268494,706.076294 
	C993.506348,703.952087 994.601318,702.283508 995.263855,700.457947 
	C998.577759,691.326477 1001.778259,682.153870 1005.018677,672.995728 
	C1004.987976,675.164307 1005.537415,677.544678 1004.835327,679.468201 
	C998.869507,695.815857 992.678345,712.081299 986.528137,728.361328 
	C986.435791,728.605774 986.005737,728.722717 985.387939,728.970581 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M1005.027832,672.672363 
	C1001.778259,682.153870 998.577759,691.326477 995.263855,700.457947 
	C994.601318,702.283508 993.506348,703.952087 992.288940,705.843750 
	C993.789307,699.715759 995.633789,693.443481 997.428162,687.156921 
	C999.913391,678.449768 1002.311646,669.717407 1004.873779,661.033142 
	C1005.296997,659.598450 1006.366943,658.354492 1007.558594,657.253052 
	C1008.293762,657.985168 1008.608582,658.486633 1008.923340,658.988098 
	C1008.591736,660.711609 1008.260193,662.435181 1007.562378,664.625122 
	C1006.485901,667.079468 1005.775757,669.067383 1005.065613,671.055298 
	C1005.056030,671.486572 1005.046448,671.917786 1005.027832,672.672363 
z"/>
<path fill="#B0B1B4" opacity="1.000000" stroke="none" 
	d="
M1009.231018,658.750610 
	C1008.608582,658.486633 1008.293762,657.985168 1007.922974,657.173767 
	C1007.882446,656.159607 1007.897888,655.455444 1008.191528,654.187927 
	C1008.617004,652.715454 1008.764343,651.806335 1008.911682,650.897156 
	C1009.275696,647.225769 1009.639771,643.554382 1010.239136,639.333557 
	C1010.745117,638.577026 1010.919067,638.307495 1010.996277,637.975464 
	C1011.012024,637.549438 1011.027771,637.123474 1011.390015,636.307373 
	C1012.792480,634.932922 1013.848389,633.948486 1014.904297,632.964111 
	C1013.115784,641.480408 1011.327271,649.996765 1009.231018,658.750610 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M1014.930298,632.636841 
	C1013.848389,633.948486 1012.792480,634.932922 1011.410645,635.988281 
	C1011.859131,631.549744 1012.633484,627.040161 1013.951904,622.355286 
	C1014.975159,622.455505 1015.454285,622.731079 1015.943481,623.336853 
	C1015.962219,624.107239 1015.970947,624.547546 1015.979736,624.987793 
	C1015.955078,625.423279 1015.930420,625.858826 1015.558228,626.699829 
	C1015.142456,628.402527 1015.074341,629.699707 1015.006226,630.996887 
	C1014.989563,631.434448 1014.972900,631.872070 1014.930298,632.636841 
z"/>
<path fill="#BAB9B8" opacity="1.000000" stroke="none" 
	d="
M1005.457520,671.025024 
	C1005.775757,669.067383 1006.485901,667.079468 1007.540527,665.005859 
	C1007.206482,666.945007 1006.527954,668.969910 1005.457520,671.025024 
z"/>
<path fill="#B0B1B4" opacity="1.000000" stroke="none" 
	d="
M1015.289978,630.771484 
	C1015.074341,629.699707 1015.142456,628.402527 1015.534302,627.026123 
	C1015.763245,628.146545 1015.668518,629.346313 1015.289978,630.771484 
z"/>
<path fill="#B0B1B4" opacity="1.000000" stroke="none" 
	d="
M1015.933411,623.006714 
	C1015.454285,622.731079 1014.975159,622.455505 1014.234375,622.085266 
	C1013.984802,621.552368 1013.996704,621.114075 1014.381531,620.342773 
	C1015.474304,619.987305 1016.194153,619.964905 1016.914062,619.942444 
	C1016.932922,620.121948 1016.951721,620.301514 1016.998291,620.750610 
	C1017.015076,621.457886 1017.004089,621.895630 1016.721924,622.662720 
	C1016.278381,622.997009 1016.105896,623.001892 1015.933411,623.006714 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M1015.943481,623.336853 
	C1016.105896,623.001892 1016.278381,622.997009 1016.709229,622.991089 
	C1016.836182,623.638611 1016.704651,624.287292 1016.276367,624.961853 
	C1015.970947,624.547546 1015.962219,624.107239 1015.943481,623.336853 
z"/>
<path fill="#BAB9B8" opacity="1.000000" stroke="none" 
	d="
M983.367920,733.994751 
	C983.466492,733.391846 983.880981,732.729858 984.610962,732.013123 
	C984.512268,732.617493 984.098083,733.276611 983.367920,733.994751 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M881.017761,192.981873 
	C882.831665,194.031189 885.008179,194.733917 886.398193,196.188370 
	C893.346680,203.458710 900.088745,210.926285 906.953613,219.034698 
	C907.003357,220.495331 907.002869,221.245789 906.739014,221.893311 
	C905.968506,221.545746 905.461365,221.301117 904.840332,220.784546 
	C904.138611,220.018097 903.550842,219.523590 902.943237,218.681503 
	C900.952637,216.552261 898.981689,214.770599 896.973511,212.671021 
	C896.276489,211.580322 895.616882,210.807541 894.940186,209.686264 
	C892.938904,207.570435 890.954773,205.803101 888.710938,203.904907 
	C887.952820,203.194580 887.454224,202.615112 886.937744,201.687073 
	C884.931152,198.574310 882.942566,195.810135 880.969910,193.030029 
	C880.985901,193.014099 881.017761,192.981873 881.017761,192.981873 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M862.000000,173.998779 
	C866.930115,178.300217 871.934937,182.520493 876.749146,186.947968 
	C878.391174,188.458054 879.554382,190.488907 880.977051,192.631836 
	C881.017761,192.981873 880.985901,193.014099 880.629883,192.984558 
	C877.500610,190.991989 874.727417,189.028961 871.791321,186.821320 
	C871.086487,186.383255 870.544434,186.189789 869.968628,185.644714 
	C867.983704,182.321121 866.469727,178.797958 862.000366,179.000305 
	C862.000000,179.000000 862.000000,178.998932 861.973572,178.666229 
	C860.963928,176.889572 859.980713,175.445618 859.004517,173.995300 
	C859.011536,173.988922 859.029297,173.971268 859.400635,173.977783 
	C860.514648,173.989136 861.257324,173.993958 862.000000,173.998779 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M912.609741,223.014954 
	C912.982788,223.459717 912.985168,223.899872 912.987671,224.670441 
	C912.501953,225.266434 912.016113,225.531998 910.988342,225.770020 
	C909.631714,224.828033 908.817078,223.913605 908.337341,222.997162 
	C909.118896,222.991425 909.565552,222.987686 910.012207,222.983963 
	C910.754456,222.992767 911.496765,223.001572 912.609741,223.014954 
z"/>
<path fill="#E2E2E2" opacity="1.000000" stroke="none" 
	d="
M910.004700,222.621613 
	C909.565552,222.987686 909.118896,222.991425 908.117554,222.889099 
	C907.288635,222.583023 907.101868,222.320755 907.002441,221.996246 
	C907.002869,221.245789 907.003357,220.495331 907.005615,219.369507 
	C908.003906,220.082504 909.000549,221.170898 910.004700,222.621613 
z"/>
<path fill="#E2E2E2" opacity="1.000000" stroke="none" 
	d="
M861.966309,173.658447 
	C861.257324,173.993958 860.514648,173.989136 859.391968,173.607376 
	C859.007141,172.487976 859.002319,171.745514 858.997559,171.003052 
	C859.975891,171.774750 860.954285,172.546432 861.966309,173.658447 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M858.779297,170.893463 
	C859.002319,171.745514 859.007141,172.487976 859.020630,173.600861 
	C859.029297,173.971268 859.011536,173.988922 858.723877,173.866394 
	C857.623962,172.834915 856.811707,171.925934 855.999512,171.016968 
	C856.466370,170.692947 856.933167,170.368912 857.698730,170.023346 
	C858.101196,170.323212 858.289062,170.583908 858.779297,170.893463 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M906.739014,221.893311 
	C907.101868,222.320755 907.288635,222.583023 907.782593,222.891113 
	C908.817078,223.913605 909.631714,224.828033 910.729370,225.871582 
	C913.480469,229.230377 915.948669,232.460052 918.708130,236.180450 
	C918.982361,237.126114 918.965332,237.581070 918.607422,237.993576 
	C914.977112,234.595001 911.492371,231.402954 908.477356,227.816055 
	C906.881287,225.917328 906.102173,223.331924 904.954224,221.056519 
	C905.461365,221.301117 905.968506,221.545746 906.739014,221.893311 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M918.948303,238.036011 
	C918.965332,237.581070 918.982361,237.126114 919.011108,236.329651 
	C920.116699,236.305557 921.210510,236.622971 922.647278,236.974579 
	C922.990234,237.008759 922.999634,237.500900 923.001831,238.121155 
	C923.008362,239.488754 923.012695,240.236099 923.017090,240.983459 
	C923.017090,240.983459 922.945129,241.054947 922.592590,241.070740 
	C921.813477,241.068085 921.386902,241.049652 920.951538,240.705078 
	C920.283142,239.599014 919.623535,238.819092 918.967896,238.032181 
	C918.971741,238.025208 918.948303,238.036011 918.948303,238.036011 
z"/>
<path fill="#E2E2E2" opacity="1.000000" stroke="none" 
	d="
M923.389465,240.987885 
	C923.012695,240.236099 923.008362,239.488754 923.005615,238.367249 
	C923.981934,238.769363 924.956482,239.545624 925.964355,240.662170 
	C925.252319,240.999054 924.507080,240.995682 923.389465,240.987885 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M575.625854,54.998955 
	C578.661865,59.242664 582.813416,55.930527 586.559570,56.983746 
	C587.458862,56.977158 587.900513,56.979115 588.675171,57.305202 
	C589.015076,58.064316 589.021912,58.499298 588.575195,58.953682 
	C585.394958,59.009232 582.668457,59.045383 579.573120,59.067223 
	C578.463074,59.061024 577.721741,59.069134 576.705078,58.763741 
	C567.689941,57.966728 558.950806,57.103790 550.210022,57.086983 
	C536.461914,57.060547 522.712646,57.603851 508.970734,57.543453 
	C508.986694,56.456173 508.995789,55.731365 509.004883,55.006561 
	C510.070282,55.002728 511.135651,54.998898 512.783386,55.243427 
	C514.243713,55.321617 515.121765,55.151447 515.999756,54.981270 
	C532.020996,54.983406 548.042236,54.985542 564.796631,55.272133 
	C568.019836,55.366875 570.509949,55.177158 573.000061,54.987438 
	C573.750305,54.991123 574.500488,54.994808 575.625854,54.998955 
z"/>
<path fill="#BAB9B8" opacity="1.000000" stroke="none" 
	d="
M493.931274,62.940216 
	C493.507141,62.946037 493.082977,62.951859 492.047882,62.671867 
	C481.990051,63.412235 472.503632,64.167107 463.107086,65.538216 
	C452.996552,67.013504 442.970367,69.066711 432.974640,70.548477 
	C434.027222,69.484459 435.012543,68.739716 436.330566,67.995689 
	C437.106415,67.980782 437.549561,67.965149 438.390564,67.952667 
	C441.201111,66.976875 443.613770,65.997940 446.397461,65.019539 
	C447.511047,65.008354 448.253693,64.996628 449.428345,64.991844 
	C454.916840,64.234100 459.979034,63.502651 465.019226,62.642509 
	C465.428711,62.572628 465.687897,61.622257 466.388580,61.064129 
	C467.504761,61.043983 468.249298,61.040737 469.211304,61.295723 
	C470.623840,61.340099 471.818878,61.126247 473.013916,60.912392 
	C473.743317,60.916805 474.472748,60.921219 475.978088,60.947613 
	C478.499176,60.631035 480.244385,60.292480 482.387146,59.963448 
	C483.844910,59.955029 484.905090,59.937084 486.320129,59.943687 
	C487.785278,59.633438 488.895477,59.298641 490.376770,58.970436 
	C491.488953,58.962769 492.230042,58.948509 493.413574,58.953484 
	C495.922638,59.008026 497.989258,59.043335 499.963196,59.435051 
	C497.890778,60.841038 495.911011,61.890625 493.931274,62.940216 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M466.016937,61.081024 
	C465.687897,61.622257 465.428711,62.572628 465.019226,62.642509 
	C459.979034,63.502651 454.916840,64.234100 449.535339,64.734108 
	C449.469360,63.975979 449.728424,63.482529 449.987457,62.989075 
	C453.030853,62.648254 456.074249,62.307434 459.868164,61.934296 
	C461.083405,61.272640 461.548187,60.643299 462.012970,60.013958 
	C463.076202,60.009842 464.139404,60.005726 465.603638,60.270912 
	C466.008728,60.720490 466.012817,60.900757 466.016937,61.081024 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M508.540344,55.002666 
	C508.995789,55.731365 508.986694,56.456173 508.558472,57.567436 
	C506.758331,57.946911 505.377350,57.939922 503.707458,57.652840 
	C500.606689,57.240391 497.794739,57.108044 494.982819,56.975697 
	C496.248016,56.317856 497.472168,55.212711 498.786682,55.091976 
	C501.859985,54.809711 504.976654,54.999901 508.540344,55.002666 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M494.651611,56.979332 
	C497.794739,57.108044 500.606689,57.240391 503.711121,57.935352 
	C504.007355,58.685749 504.011078,58.873531 503.617828,59.050480 
	C502.165863,59.052647 501.110870,59.065647 500.055908,59.078651 
	C497.989258,59.043335 495.922638,59.008026 493.419891,58.627930 
	C492.987518,57.846935 492.991241,57.410725 492.994965,56.974518 
	C493.436768,56.977333 493.878601,56.980152 494.651611,56.979332 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M485.965271,59.919144 
	C484.905090,59.937084 483.844910,59.955029 482.134766,59.691910 
	C479.985168,59.267727 478.485565,59.124607 476.985962,58.981483 
	C479.696533,58.320766 482.407104,57.660057 485.558258,57.367172 
	C485.987640,58.463051 485.976440,59.191101 485.965271,59.919144 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M435.997864,67.994980 
	C435.012543,68.739716 434.027222,69.484459 432.615173,70.581497 
	C431.463440,70.936203 430.738495,70.938614 429.901093,70.724541 
	C429.575500,70.249916 429.310883,70.065651 428.994812,69.955261 
	C428.997345,69.223259 428.999908,68.491264 429.004181,67.394089 
	C430.510712,67.146576 432.015564,67.264244 434.053772,67.210861 
	C435.057404,67.358208 435.527618,67.676590 435.997864,67.994980 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M446.026459,65.019005 
	C443.613770,65.997940 441.201111,66.976875 438.392395,67.586754 
	C437.998627,66.483475 438.000885,65.749245 438.003143,65.015022 
	C439.062775,65.001305 440.122437,64.987587 441.984344,64.979492 
	C443.866577,64.996414 444.946503,65.007706 446.026459,65.019005 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M572.731812,54.706188 
	C570.509949,55.177158 568.019836,55.366875 565.265259,55.269352 
	C567.488403,54.796387 569.976013,54.610664 572.731812,54.706188 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M446.397461,65.019539 
	C444.946503,65.007706 443.866577,64.996414 442.381653,64.971680 
	C444.358917,64.302605 446.741180,63.646973 449.555420,62.990211 
	C449.728424,63.482529 449.469360,63.975979 449.103302,64.727165 
	C448.253693,64.996628 447.511047,65.008354 446.397461,65.019539 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M472.945190,60.563980 
	C471.818878,61.126247 470.623840,61.340099 469.214081,60.959839 
	C469.007202,59.919270 469.015015,59.472809 469.022827,59.026344 
	C470.307404,59.422752 471.591949,59.819157 472.945190,60.563980 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M476.745178,59.142487 
	C478.485565,59.124607 479.985168,59.267727 481.737183,59.682388 
	C480.244385,60.292480 478.499176,60.631035 476.342590,60.933147 
	C476.122284,60.365631 476.313354,59.834557 476.745178,59.142487 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M492.799744,56.722954 
	C492.991241,57.410725 492.987518,57.846935 492.977478,58.608696 
	C492.230042,58.948509 491.488953,58.962769 490.267639,58.716999 
	C489.525055,57.971107 489.262665,57.485241 489.000305,56.999382 
	C490.201691,56.823387 491.403137,56.647385 492.799744,56.722954 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M437.627197,65.011230 
	C438.000885,65.749245 437.998627,66.483475 437.994507,67.583611 
	C437.549561,67.965149 437.106415,67.980782 436.330566,67.995697 
	C435.527618,67.676590 435.057404,67.358208 434.288361,67.018082 
	C435.076782,66.333366 436.164001,65.670403 437.627197,65.011230 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M488.625793,57.000225 
	C489.262665,57.485241 489.525055,57.971107 489.896576,58.710407 
	C488.895477,59.298641 487.785278,59.633438 486.320160,59.943691 
	C485.976440,59.191101 485.987640,58.463051 486.001862,57.369781 
	C486.753693,57.003395 487.502472,57.002232 488.625793,57.000225 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M515.814453,54.735214 
	C515.121765,55.151447 514.243713,55.321617 513.182861,55.240303 
	C513.876404,54.822266 514.752747,54.655708 515.814453,54.735214 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M468.700775,59.040871 
	C469.015015,59.472809 469.007202,59.919270 468.996582,60.701611 
	C468.249298,61.040737 467.504761,61.043983 466.388580,61.064133 
	C466.012817,60.900757 466.008728,60.720490 466.002319,60.269745 
	C466.792908,59.684639 467.585815,59.370014 468.700775,59.040871 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M461.693054,60.047997 
	C461.548187,60.643299 461.083405,61.272640 460.295044,61.926052 
	C460.438660,61.327427 460.905914,60.704731 461.693054,60.047997 
z"/>
<path fill="#BAB9B8" opacity="1.000000" stroke="none" 
	d="
M428.662415,69.972549 
	C429.310883,70.065651 429.575500,70.249916 429.685913,71.028885 
	C423.706970,73.035210 417.778687,74.343132 411.966858,76.047897 
	C403.282135,78.595360 394.657654,81.356606 386.066498,84.204811 
	C384.854645,84.606560 383.975891,86.012970 382.942963,86.954361 
	C382.523071,86.972839 382.103180,86.991318 380.961731,87.006866 
	C378.638123,87.344971 376.948425,87.465889 375.448151,88.062790 
	C365.738770,91.925583 356.005493,95.741806 346.422272,99.901054 
	C342.751282,101.494301 339.454102,103.948814 335.986572,106.010849 
	C332.927704,107.337227 329.868805,108.663628 326.082611,110.040771 
	C321.915955,112.073860 318.476654,114.056213 315.037323,116.038559 
	C315.037323,116.038559 315.002441,116.016212 314.634216,116.017975 
	C311.851074,116.685928 309.436127,117.352135 307.021210,118.018341 
	C314.220490,113.781891 321.216644,109.138954 328.671173,105.413208 
	C337.729309,100.885956 347.014618,96.709030 356.487732,93.139969 
	C371.699432,87.408867 387.134491,82.267853 402.500824,76.951698 
	C406.692505,75.501541 410.975983,74.316795 415.983917,73.002510 
	C417.838837,72.331070 418.927216,71.667358 420.015594,71.003647 
	C421.447815,70.991890 422.880005,70.980133 424.950897,70.950058 
	C426.059326,70.619858 426.529083,70.307976 426.998840,69.996086 
	C427.442566,69.994003 427.886322,69.991920 428.662415,69.972549 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M306.724548,118.047226 
	C309.436127,117.352135 311.851074,116.685928 314.651794,116.034439 
	C312.947052,117.639229 310.987335,119.450920 308.740723,120.776436 
	C302.796844,124.283318 296.728760,127.579704 290.377808,130.639389 
	C290.371796,129.222412 290.698090,128.125885 291.024414,127.029366 
	C291.024414,127.029366 291.496246,126.994720 292.109741,126.993683 
	C296.824768,124.340378 300.926331,121.688110 305.027863,119.035835 
	C305.494537,118.715927 305.961212,118.396027 306.724548,118.047226 
z"/>
<path fill="#BAB9B8" opacity="1.000000" stroke="none" 
	d="
M290.665283,127.047035 
	C290.698090,128.125885 290.371796,129.222412 290.015259,130.665131 
	C289.985077,131.011322 289.536163,131.040680 288.948700,131.057922 
	C286.899536,132.382568 285.437836,133.690002 283.976135,134.997421 
	C283.976135,134.997421 283.527252,135.030899 282.935974,135.054001 
	C279.540100,136.358826 276.735474,137.640533 273.930878,138.922241 
	C275.304535,137.404556 276.448364,135.544449 278.097656,134.437469 
	C282.040466,131.791107 286.221344,129.499527 290.665283,127.047035 
z"/>
<path fill="#BAB9B8" opacity="1.000000" stroke="none" 
	d="
M270.997223,143.014648 
	C270.997223,143.014648 270.539856,143.055740 269.951660,143.069595 
	C267.552643,144.705307 265.741821,146.327164 263.930969,147.949020 
	C263.529877,147.983795 263.128784,148.018570 262.064423,148.037018 
	C260.919678,148.002090 260.438232,147.983505 259.956757,147.964920 
	C264.054626,144.975433 268.152496,141.985931 272.583679,139.328506 
	C272.277100,140.778595 271.637146,141.896622 270.997223,143.014648 
z"/>
<path fill="#BAB9B8" opacity="1.000000" stroke="none" 
	d="
M304.647339,119.024872 
	C300.926331,121.688110 296.824768,124.340378 292.345886,126.979675 
	C296.067963,124.315773 300.167358,121.664833 304.647339,119.024872 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M271.367371,143.015564 
	C271.637146,141.896622 272.277100,140.778595 272.957581,139.329147 
	C272.998169,138.997726 273.464996,138.958023 273.697937,138.940125 
	C276.735474,137.640533 279.540100,136.358826 282.711243,135.066589 
	C279.297699,137.709534 275.517609,140.362991 271.367371,143.015564 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M256.998779,153.002350 
	C254.894943,154.648102 252.791122,156.293854 250.329376,157.960266 
	C250.299210,156.903214 250.626953,155.825500 250.997406,154.412567 
	C251.040131,154.077362 251.515564,154.024826 252.120697,153.975037 
	C254.150131,153.617615 255.574463,153.309982 256.998779,153.002350 
z"/>
<path fill="#BAB9B8" opacity="1.000000" stroke="none" 
	d="
M256.999390,153.001175 
	C255.574463,153.309982 254.150131,153.617615 252.359344,153.959045 
	C253.771057,152.359528 255.549255,150.726212 257.640137,149.413452 
	C257.636017,150.822266 257.319214,151.910522 257.002441,152.998779 
	C257.002441,152.998779 257.000000,153.000000 256.999390,153.001175 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M257.347565,152.974030 
	C257.319214,151.910522 257.636017,150.822266 257.991638,149.394928 
	C258.506042,148.771530 258.981628,148.487198 259.706970,148.083893 
	C260.438232,147.983505 260.919678,148.002090 261.762939,148.054230 
	C260.647369,149.708282 259.170013,151.328781 257.347565,152.974030 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M419.638672,71.002495 
	C418.927216,71.667358 417.838837,72.331070 416.374329,72.987297 
	C417.086060,72.320328 418.173889,71.660835 419.638672,71.002495 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M426.830383,69.769135 
	C426.529083,70.307976 426.059326,70.619858 425.291840,70.934792 
	C425.550049,70.472618 426.105957,70.007401 426.830383,69.769135 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M807.983826,134.021301 
	C807.983826,134.021301 808.482544,134.041641 808.731506,134.055298 
	C808.980469,134.068970 808.963928,134.543121 809.012512,135.101105 
	C809.704956,136.107941 810.348816,136.556778 810.992676,137.005615 
	C810.992676,137.005615 811.000000,137.000000 811.137939,137.293777 
	C812.184326,138.057449 813.092712,138.527344 814.001160,138.997238 
	C814.001160,138.997238 814.482239,139.018265 814.781372,139.356781 
	C816.384521,140.467316 817.688660,141.239349 818.992737,142.011383 
	C819.096130,142.334351 819.286987,142.592667 819.927307,143.186951 
	C821.527771,145.056580 822.766296,146.525574 823.970764,148.030029 
	C823.936707,148.065491 823.868591,148.136429 823.498413,148.099854 
	C821.773010,147.564758 820.269470,147.296127 819.085876,146.531815 
	C808.311646,139.574249 797.859314,132.072678 786.788147,125.632217 
	C774.138550,118.273567 760.952820,111.836563 747.893799,104.739700 
	C747.525024,103.986496 747.262512,103.492645 747.015015,102.964897 
	C747.030029,102.931007 747.065735,102.866035 747.065735,102.866028 
	C752.730835,104.811333 758.671936,106.197884 763.998535,108.828575 
	C772.712708,113.132294 781.025330,118.248474 789.510498,123.016251 
	C793.077454,125.020462 796.652466,127.010216 800.653687,129.355225 
	C803.383667,131.143051 805.683716,132.582169 807.983826,134.021301 
z"/>
<path fill="#BAB9B8" opacity="1.000000" stroke="none" 
	d="
M701.909058,87.070282 
	C701.909058,87.070282 701.464844,87.084869 701.039307,86.783951 
	C694.715820,84.679596 688.817810,82.876152 682.919800,81.072701 
	C682.496521,81.060814 682.073242,81.048920 681.165649,80.707298 
	C676.086365,78.953400 671.491516,77.529236 666.896606,76.105072 
	C666.478638,76.099541 666.060730,76.094009 665.160767,75.823959 
	C663.780945,75.696518 662.883179,75.833595 661.985352,75.970680 
	C661.557312,75.969070 661.129272,75.967453 660.314819,75.584686 
	C659.424683,74.584328 659.022278,73.570480 658.400635,73.411026 
	C654.802734,72.488075 651.126099,71.872200 647.527649,70.951073 
	C642.654663,69.703659 637.828125,68.274429 633.235535,66.659683 
	C637.678894,67.103615 641.894409,67.685104 646.052368,68.545021 
	C654.394043,70.270164 662.704285,72.147408 671.027466,73.961868 
	C671.466248,73.970284 671.905090,73.978714 672.740112,74.357132 
	C673.619629,75.121918 674.042114,75.706009 674.595093,75.883476 
	C685.060669,79.242088 695.563599,82.486572 705.995178,85.946510 
	C707.510254,86.449028 708.699158,87.934830 709.660461,88.941757 
	C706.824280,88.302017 704.366699,87.686157 701.909058,87.070282 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M710.039001,88.965622 
	C708.699158,87.934830 707.510254,86.449028 705.995178,85.946510 
	C695.563599,82.486572 685.060669,79.242088 674.595093,75.883476 
	C674.042114,75.706009 673.619629,75.121918 673.069214,74.360382 
	C674.051147,73.724312 675.266907,72.966362 676.124451,73.258759 
	C689.533997,77.830994 702.963135,82.361076 716.244385,87.287651 
	C723.272034,89.894493 730.023682,93.245422 736.959106,96.621063 
	C737.018250,96.981705 736.997559,97.002281 736.622375,97.000618 
	C735.496521,97.001343 734.745789,97.003738 733.635010,96.964798 
	C729.168518,95.308701 725.062073,93.693932 720.569092,92.059448 
	C719.151367,91.994507 718.120117,91.949272 717.013123,91.576675 
	C715.612549,90.521217 714.287842,89.793121 712.597534,89.044159 
	C711.500977,89.004066 710.769958,88.984848 710.039001,88.965622 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M670.971252,73.588654 
	C662.704285,72.147408 654.394043,70.270164 646.052368,68.545021 
	C641.894409,67.685104 637.678894,67.103615 632.916748,66.668007 
	C631.919373,66.929649 631.494568,66.919456 630.973267,66.544113 
	C627.919434,65.100967 624.962158,64.022980 622.001831,62.691914 
	C622.002747,62.269726 622.006775,62.100613 622.010864,61.931503 
	C622.758301,61.945175 623.505737,61.958847 624.793518,62.222790 
	C625.900940,62.626663 626.468018,62.780266 627.035095,62.933868 
	C629.722351,62.956585 632.409668,62.979301 635.601685,63.348793 
	C637.081299,64.113640 638.056152,64.531700 639.031006,64.949768 
	C647.699219,66.802429 656.387512,68.568748 665.021057,70.570824 
	C667.079468,71.048157 668.955261,72.312874 670.971252,73.588654 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M737.029419,96.970268 
	C740.367615,98.689568 743.694702,100.420303 747.043701,102.508530 
	C747.065735,102.866035 747.030029,102.931007 746.680542,102.962662 
	C745.885071,102.999710 745.439087,103.005112 744.794067,102.739746 
	C743.011719,101.655533 741.428467,100.842079 740.028137,99.921761 
	C740.122681,99.617172 740.034302,99.419456 739.945862,99.221756 
	C739.646790,99.493500 739.347656,99.765244 738.788330,100.025627 
	C738.354797,100.002663 738.181580,99.991051 737.983643,99.662689 
	C737.638428,98.564728 737.317993,97.783508 736.997559,97.002289 
	C736.997559,97.002281 737.018250,96.981705 737.029419,96.970268 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M807.869568,133.721008 
	C805.683716,132.582169 803.383667,131.143051 801.023071,129.366318 
	C801.136597,129.021225 801.310669,129.013718 801.746033,128.998535 
	C802.007324,128.990845 802.503296,128.997055 802.751221,128.999756 
	C804.097656,129.668320 805.196167,130.334183 806.642456,131.004059 
	C807.245239,131.812286 807.500305,132.616516 807.869568,133.721008 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M639.021484,64.623093 
	C638.056152,64.531700 637.081299,64.113640 636.050049,63.351059 
	C636.164673,63.001934 636.335693,62.997326 636.763489,62.982056 
	C637.460022,62.977478 637.899902,62.983551 638.669861,62.988392 
	C639.004028,63.423576 639.008057,63.859993 639.021484,64.623093 
z"/>
<path fill="#BAB9B8" opacity="1.000000" stroke="none" 
	d="
M622.004883,62.945000 
	C624.962158,64.022980 627.919434,65.100967 630.530762,66.579742 
	C624.426025,66.347221 618.667297,65.713913 612.908508,65.080605 
	C611.871826,65.070107 610.835144,65.059608 609.321533,64.651779 
	C602.555359,63.819820 596.266052,63.385181 589.976746,62.950546 
	C589.552673,62.951454 589.128601,62.952362 588.283081,62.575275 
	C586.367432,61.796707 584.828918,61.511696 583.392761,60.960037 
	C582.180786,60.494465 581.087646,59.719532 579.941895,59.081532 
	C582.668457,59.045383 585.394958,59.009232 588.944946,58.971565 
	C590.510254,58.967960 591.252075,58.965874 592.313232,59.115730 
	C593.766663,59.484814 594.900696,59.701954 596.432007,59.935898 
	C597.889526,59.953037 598.949768,59.953377 600.264587,60.247986 
	C602.369080,60.660355 604.218994,60.778458 606.068909,60.896553 
	C606.797791,60.908783 607.526733,60.921013 608.841675,61.215820 
	C610.626709,61.647552 611.825684,61.796707 613.355225,61.960716 
	C614.127686,61.969219 614.569580,61.962872 615.254395,62.243622 
	C617.007568,62.666172 618.517700,62.801617 620.357056,62.952728 
	C621.125793,62.960598 621.565308,62.952801 622.004883,62.945000 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M600.010010,59.953720 
	C598.949768,59.953377 597.889526,59.953037 596.423828,59.573265 
	C596.010681,58.464077 596.002869,57.734318 595.995117,57.004555 
	C598.941467,57.405914 601.887878,57.807270 604.536865,58.601528 
	C602.829712,59.314194 601.419861,59.633957 600.010010,59.953720 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M613.024597,61.945862 
	C611.825684,61.796707 610.626709,61.647552 609.206177,61.204643 
	C610.069458,60.278706 611.154480,59.646523 612.617310,59.382324 
	C613.004944,60.482155 613.014771,61.214008 613.024597,61.945862 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M600.264587,60.247986 
	C601.419861,59.633957 602.829712,59.314194 604.626953,58.987961 
	C605.341553,59.429478 605.668823,59.877460 606.032471,60.611000 
	C604.218994,60.778458 602.369080,60.660355 600.264587,60.247986 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M620.027893,62.937061 
	C618.517700,62.801617 617.007568,62.666172 615.364990,62.025024 
	C615.442444,61.254139 615.706482,61.064487 616.024780,60.950371 
	C617.086121,60.960152 618.147522,60.969933 619.608154,61.304176 
	C620.014282,62.064781 620.021057,62.500919 620.027893,62.937061 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M591.993896,58.963791 
	C591.252075,58.965874 590.510254,58.967960 589.398621,58.952164 
	C589.021912,58.499298 589.015076,58.064316 589.006592,57.301918 
	C590.135803,56.895535 591.266602,56.816566 592.592896,57.124512 
	C592.523560,57.995544 592.258728,58.479668 591.993896,58.963791 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M592.313232,59.115734 
	C592.258728,58.479668 592.523560,57.995544 592.894165,57.255402 
	C593.748840,57.000584 594.497681,57.001781 595.620850,57.003769 
	C596.002869,57.734318 596.010681,58.464077 596.026611,59.556465 
	C594.900696,59.701954 593.766663,59.484814 592.313232,59.115734 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M615.961914,60.610870 
	C615.706482,61.064487 615.442444,61.254139 615.122070,61.737923 
	C614.569580,61.962872 614.127686,61.969219 613.355225,61.960716 
	C613.014771,61.214008 613.004944,60.482155 612.989136,59.384018 
	C613.955078,59.435612 614.927002,59.853493 615.961914,60.610870 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M620.357056,62.952728 
	C620.021057,62.500919 620.014282,62.064781 620.006226,61.300331 
	C620.650757,61.102196 621.296570,61.232372 621.976624,61.647026 
	C622.006775,62.100613 622.002747,62.269726 622.001831,62.691917 
	C621.565308,62.952801 621.125793,62.960598 620.357056,62.952728 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M626.992920,62.649033 
	C626.468018,62.780266 625.900940,62.626663 625.167358,62.220615 
	C625.650757,62.100178 626.300781,62.232185 626.992920,62.649033 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M855.706970,170.879120 
	C856.811707,171.925934 857.623962,172.834915 858.716858,173.872772 
	C859.980713,175.445618 860.963928,176.889572 861.612793,178.662811 
	C855.903076,174.547562 850.484924,170.153122 845.178711,165.627625 
	C843.609863,164.289566 842.356445,162.581772 840.974976,161.026215 
	C840.991455,161.008972 841.025024,160.974945 841.395996,160.987671 
	C842.511536,160.999893 843.256042,160.999420 844.000610,160.998932 
	C845.459961,162.248886 846.919312,163.498856 848.722656,165.203369 
	C849.713379,166.100235 850.359985,166.542572 851.006653,166.984909 
	C852.475891,168.237030 853.945190,169.489166 855.706970,170.879120 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M844.000610,160.624176 
	C843.256042,160.999420 842.511536,160.999893 841.386963,160.620178 
	C840.990967,159.503601 840.974854,158.767212 841.338989,158.019562 
	C842.478699,158.006363 843.238098,158.004410 843.997559,158.002472 
	C843.997559,158.002472 844.000000,158.000000 843.999878,158.000183 
	C844.000000,158.750061 844.000305,159.499725 844.000610,160.624176 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M850.963379,166.663849 
	C850.359985,166.542572 849.713379,166.100235 849.020447,165.337799 
	C849.622803,165.459396 850.271484,165.901093 850.963379,166.663849 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M824.004822,147.994568 
	C822.766296,146.525574 821.527771,145.056580 820.148438,143.291229 
	C820.453552,142.993698 820.899536,142.992538 821.680359,142.986023 
	C822.797791,143.302811 823.580383,143.624954 824.681213,143.974380 
	C825.090515,145.128784 825.181580,146.255920 824.893677,147.683563 
	C824.344727,147.987564 824.174805,147.991074 824.004822,147.994568 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M818.948975,141.671234 
	C817.688660,141.239349 816.384521,140.467316 815.022095,139.360870 
	C816.277588,139.794662 817.591431,140.562881 818.948975,141.671234 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M813.948608,138.652466 
	C813.092712,138.527344 812.184326,138.057449 811.136230,137.297424 
	C811.963074,137.440781 812.929626,137.874237 813.948608,138.652466 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M810.954346,136.682068 
	C810.348816,136.556778 809.704956,136.107941 809.017334,135.338257 
	C809.621094,135.464462 810.268555,135.911499 810.954346,136.682068 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M823.970764,148.030029 
	C824.174805,147.991074 824.344727,147.987564 824.769165,147.962509 
	C825.462585,147.957626 825.901611,147.974289 826.670288,147.995483 
	C828.424561,149.004501 829.849121,150.009018 831.624878,151.025040 
	C832.800171,151.936340 833.624329,152.836121 834.864746,154.158081 
	C836.193787,155.045837 837.106628,155.511414 838.019409,155.976990 
	C838.497803,156.553482 838.976196,157.129990 839.961792,157.852264 
	C840.469116,157.998047 840.958740,158.030823 840.958740,158.030823 
	C840.974854,158.767212 840.990967,159.503601 841.015991,160.607468 
	C841.025024,160.974945 840.991455,161.008972 840.629150,160.977692 
	C836.857666,158.951859 833.448425,156.957321 829.877991,154.667343 
	C827.767395,152.293411 825.817993,150.214935 823.868591,148.136444 
	C823.868591,148.136429 823.936707,148.065491 823.970764,148.030029 
z"/>
<path fill="#E2E2E2" opacity="1.000000" stroke="none" 
	d="
M841.338989,158.019562 
	C840.958740,158.030823 840.469116,157.998047 840.225830,157.963806 
	C840.816284,154.929260 842.226501,155.964508 843.845703,157.700928 
	C843.238098,158.004410 842.478699,158.006363 841.338989,158.019562 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M838.019287,155.647705 
	C837.106628,155.511414 836.193787,155.045837 835.145996,154.286392 
	C835.761414,153.994644 836.511780,153.996765 837.637817,153.999756 
	C838.015442,154.439880 838.017334,154.879135 838.019287,155.647705 
z"/>
<path fill="#BAB9B8" opacity="1.000000" stroke="none" 
	d="
M811.926086,937.937439 
	C815.433105,936.726013 818.940063,935.514648 822.685730,934.525879 
	C816.827271,939.075867 810.730103,943.403259 803.997925,947.886475 
	C802.571838,948.690613 801.780823,949.338867 800.989868,949.987061 
	C800.989868,949.987061 800.997986,949.998230 800.992310,949.992188 
	C794.914429,953.252502 788.869263,956.570312 782.763977,959.773499 
	C772.990295,964.901367 763.258606,970.119873 753.356812,974.990234 
	C743.582031,979.798340 733.632935,984.254211 723.724304,988.786011 
	C721.797607,989.667236 719.705811,990.187561 717.389404,990.546387 
	C719.723755,988.465454 722.358826,986.712402 725.330383,984.965210 
	C726.438721,984.643738 727.210510,984.316467 728.338867,983.962891 
	C730.469299,982.960449 732.243103,981.984314 734.343323,980.864014 
	C739.100098,978.796814 743.530396,976.873779 748.332886,974.934387 
	C754.124939,971.932922 759.544800,968.947815 765.180176,965.687195 
	C768.933167,963.277649 772.470581,961.143616 776.380615,959.005859 
	C777.662720,958.668823 778.659485,958.477661 779.468384,957.980652 
	C790.302979,951.324585 801.111267,944.625671 811.926086,937.937439 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M824.273926,933.895996 
	C827.317444,930.431580 830.473206,926.678223 834.174072,923.575012 
	C841.892639,917.102661 849.882507,910.952454 857.809204,904.731262 
	C858.801392,903.952515 860.084778,903.544678 861.595703,902.953613 
	C861.627319,904.043884 861.296448,905.145569 860.940430,906.592651 
	C856.224670,910.508667 851.570984,914.129333 846.834656,917.638306 
	C839.463684,923.099304 832.036438,928.484375 824.273926,933.895996 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M801.344238,949.989258 
	C801.780823,949.338867 802.571838,948.690613 803.687073,948.030151 
	C803.240417,948.675659 802.469543,949.333557 801.344238,949.989258 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M863.292358,901.931824 
	C865.704407,899.280762 868.467163,896.620178 871.590881,893.947876 
	C869.182251,896.598206 866.412598,899.260254 863.292358,901.931824 
z"/>
<path fill="#DEDEDE" opacity="1.000000" stroke="none" 
	d="
M775.364380,968.979858 
	C775.680237,967.806152 776.104370,966.189331 777.133240,965.557983 
	C783.816406,961.456909 790.616211,957.544128 797.422546,953.647888 
	C798.223450,953.189392 799.297363,953.207886 800.629517,953.010620 
	C800.369690,954.119995 799.725159,955.223999 799.031738,956.655457 
	C792.595886,960.541138 786.228882,964.136292 779.804138,967.625122 
	C778.569946,968.295288 777.108826,968.547485 775.364380,968.979858 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M60.549576,512.046204 
	C61.629032,538.674622 62.321056,565.412964 62.974049,592.606812 
	C62.290203,591.757568 61.180599,590.489441 61.074471,589.142273 
	C60.262547,578.835815 59.665237,568.512634 58.926361,558.200073 
	C58.876129,557.499023 58.218788,556.841370 57.459484,556.047363 
	C57.069340,555.516479 57.063507,555.101807 57.064369,554.376709 
	C57.704159,553.244141 58.870926,552.431885 58.886341,551.598389 
	C59.015144,544.635376 58.954945,537.668152 58.866642,530.703369 
	C58.860073,530.185364 58.193439,529.675781 57.445152,529.053101 
	C57.054424,528.223022 57.052193,527.502197 57.056969,526.421814 
	C57.705132,524.584351 58.633152,523.161072 58.927139,521.617126 
	C59.521015,518.498169 59.770576,515.313599 60.549576,512.046204 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M68.538345,449.033752 
	C67.882301,455.992767 66.877296,463.038208 65.849960,470.080383 
	C65.098648,475.230499 64.317978,480.376343 63.250134,485.768860 
	C62.964657,482.169861 62.477234,478.243134 63.095890,474.499176 
	C64.501747,465.991302 66.451279,457.573303 68.538345,449.033752 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M94.581551,360.997620 
	C92.661552,367.433594 90.470734,373.962341 88.060020,380.408905 
	C86.474037,384.650055 84.571014,388.772675 82.467926,393.041138 
	C85.619560,382.794220 89.098145,372.449341 92.654488,362.131256 
	C92.820267,361.650238 93.680511,361.408630 94.581551,360.997620 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M81.787354,395.179382 
	C82.374756,395.871429 83.113564,396.929901 82.864456,397.643463 
	C80.606766,404.110352 78.176422,410.516998 75.456955,417.033630 
	C77.238380,409.879333 79.359474,402.632599 81.787354,395.179382 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M102.643448,341.024597 
	C100.537842,346.882294 98.082466,352.801300 95.308441,358.870819 
	C94.779747,358.457916 94.231415,357.734955 94.410568,357.355072 
	C96.980759,351.904907 99.649002,346.500977 102.643448,341.024597 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M62.557484,488.043915 
	C62.490726,494.463165 62.044582,500.986908 61.307037,507.764008 
	C58.753826,501.239380 60.324699,494.685608 62.557484,488.043915 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M72.806335,427.188660 
	C73.084274,428.651398 73.337524,430.395996 72.995178,432.014648 
	C71.960701,436.905670 70.695000,441.747742 69.235275,446.812073 
	C70.137192,440.481171 71.323257,433.947052 72.806335,427.188660 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M118.643448,309.034424 
	C116.542442,313.909912 114.085983,318.815369 111.315369,323.873962 
	C111.667809,321.610260 112.334404,319.193390 113.007370,316.397125 
	C114.771828,313.699951 116.529907,311.382141 118.643448,309.034424 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M110.608116,325.002869 
	C109.812042,327.500763 108.679047,330.068176 107.261261,332.829163 
	C108.074715,330.372589 109.172951,327.722473 110.608116,325.002869 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M74.807388,419.186584 
	C74.666183,420.966278 74.227928,422.959259 73.455734,425.045135 
	C73.584633,423.225311 74.047485,421.312561 74.807388,419.186584 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M104.635056,337.019409 
	C104.577782,337.955231 104.169258,338.954529 103.416718,340.030151 
	C103.476395,339.098602 103.880104,338.090729 104.635056,337.019409 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M148.016586,262.990570 
	C146.641006,265.561554 145.373795,268.197784 143.871246,270.692291 
	C137.007950,282.086517 130.109192,293.459778 123.135765,304.786774 
	C122.229218,306.259308 120.815697,307.419739 119.320618,308.861206 
	C121.721352,303.230194 123.933250,297.150116 127.266045,291.763550 
	C133.278366,282.046204 139.961258,272.743744 146.922821,263.114990 
	C147.659912,262.971527 147.838257,262.981049 148.016586,262.990570 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M157.588837,249.966110 
	C156.493790,252.183411 155.052551,254.449142 153.297241,256.475006 
	C152.984970,255.487656 152.986801,254.740173 152.988632,253.992691 
	C154.406631,252.666641 155.824646,251.340591 157.588837,249.966110 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M162.693512,243.128815 
	C161.585587,245.000992 160.159073,246.989410 158.383270,249.022552 
	C159.480957,247.126511 160.927933,245.185776 162.693512,243.128815 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M166.578400,238.962097 
	C165.866028,240.290741 164.786926,241.630569 163.361938,242.991119 
	C163.165253,241.832306 163.314484,240.652786 163.735199,239.240433 
	C164.741684,238.996155 165.476669,238.984711 166.578400,238.962097 
z"/>
<path fill="#BAB9B8" opacity="1.000000" stroke="none" 
	d="
M152.639404,254.032608 
	C152.986801,254.740173 152.984970,255.487656 152.987305,256.608307 
	C152.991455,256.981476 152.995758,257.000275 152.664734,257.033752 
	C151.888718,257.721497 151.443710,258.375732 150.998688,259.029968 
	C150.579941,259.063660 150.161209,259.097382 149.431000,259.167328 
	C150.176407,257.493195 151.233292,255.782867 152.639404,254.032608 
z"/>
<path fill="#BAB9B8" opacity="1.000000" stroke="none" 
	d="
M148.279251,262.786285 
	C147.838257,262.981049 147.659912,262.971527 147.214157,262.937073 
	C147.414200,261.970947 147.881653,261.029694 148.696045,260.045685 
	C148.875961,260.862610 148.708939,261.722290 148.279251,262.786285 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M228.697113,175.061768 
	C225.968063,177.710861 222.865906,180.371902 219.400238,183.036774 
	C219.676376,181.914032 220.316040,180.787445 220.981049,179.334076 
	C221.974854,178.231461 222.943298,177.455658 223.965134,176.348053 
	C225.453690,175.702072 226.888840,175.387894 228.697113,175.061768 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M218.708160,183.070602 
	C218.596130,183.688904 218.152756,184.331207 217.395966,185.033203 
	C217.513977,184.426804 217.945389,183.760696 218.708160,183.070602 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M201.675629,200.030518 
	C201.546539,200.637711 201.094864,201.284012 200.317688,201.965088 
	C200.445816,201.356430 200.899429,200.713028 201.675629,200.030518 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M247.757889,159.072495 
	C247.616623,159.688507 247.139069,160.319702 246.342239,161.000092 
	C246.489166,160.395416 246.955383,159.741547 247.757889,159.072495 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M228.073517,170.244415 
	C228.142868,170.071136 228.317230,170.053223 228.754608,170.034790 
	C228.737946,170.156113 228.458237,170.277939 228.073517,170.244415 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M87.042099,702.313538 
	C87.531845,702.081970 88.293236,702.141418 88.410599,702.447693 
	C91.320435,710.040344 94.162170,717.659119 97.008003,725.643921 
	C95.576828,724.371582 94.153641,722.728577 92.392563,721.014771 
	C91.704430,719.546143 91.354179,718.148193 90.983734,716.386230 
	C89.646919,711.588013 88.330307,707.153748 87.042099,702.313538 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M67.102463,630.234680 
	C68.113739,631.042725 69.621750,632.122253 69.933052,633.476807 
	C71.441154,640.038818 72.627953,646.674744 73.932983,653.680542 
	C72.949989,652.998718 71.442711,652.073303 71.073242,650.814148 
	C69.988640,647.117554 69.356232,643.288269 68.259644,639.253296 
	C67.964302,638.562256 67.956055,638.128906 67.939941,637.065674 
	C67.613159,635.957947 67.294235,635.480225 66.975311,635.002441 
	C66.975845,634.563782 66.976379,634.125183 66.963074,633.358398 
	C66.982025,632.231140 67.014832,631.432007 67.102463,630.234680 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M81.003677,683.379761 
	C83.012680,688.390076 85.009430,693.817078 86.977562,699.650391 
	C86.397263,699.754578 85.478020,699.562256 85.349945,699.133606 
	C83.828804,694.041565 82.425575,688.914429 81.003677,683.379761 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M77.054565,671.297119 
	C77.725067,670.995056 78.847412,670.974365 78.916588,671.234070 
	C79.710052,674.213684 80.343460,677.235901 80.992287,680.647034 
	C79.649124,677.934448 78.333176,674.821533 77.054565,671.297119 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M74.047943,659.327881 
	C74.793999,659.454773 75.982574,659.898315 76.136330,660.576538 
	C76.629654,662.752686 76.740334,665.015564 76.980110,667.646729 
	C75.979965,665.282898 74.998352,662.517395 74.047943,659.327881 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M62.703438,598.253418 
	C63.663288,601.383606 64.338821,604.781433 64.983803,608.619873 
	C64.108536,605.547302 63.263824,602.034180 62.703438,598.253418 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M65.688370,617.247925 
	C66.525864,620.158691 67.086868,623.329590 67.387466,626.766479 
	C66.555328,623.857727 65.983604,620.682861 65.688370,617.247925 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M64.735283,611.202026 
	C65.339981,612.089172 65.677216,613.197388 65.983719,614.679321 
	C65.457932,613.843018 64.962875,612.633057 64.735283,611.202026 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M66.751251,635.172119 
	C67.294235,635.480225 67.613159,635.957947 67.922272,636.740845 
	C67.450699,636.477966 66.988937,635.909912 66.751251,635.172119 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M98.700256,731.208740 
	C99.915466,731.881531 101.262123,732.596130 101.763374,733.696045 
	C104.340584,739.351379 106.619934,745.141907 109.171532,750.809509 
	C110.392281,753.521118 112.062988,756.026978 113.378601,758.700378 
	C114.084084,760.133972 114.468994,761.725281 114.980667,763.635864 
	C111.831268,758.981323 108.472511,754.060364 105.633347,748.855957 
	C102.893471,743.833557 100.665329,738.529785 98.289421,733.314514 
	C98.060921,732.812927 98.377960,732.062927 98.700256,731.208740 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M114.969513,764.419495 
	C117.211563,767.389709 119.635773,770.643860 121.648544,774.135315 
	C124.902718,779.780029 127.886368,785.580750 130.954437,791.693359 
	C128.890900,789.498230 126.471252,787.139160 124.901825,784.308716 
	C121.366608,777.933167 118.252464,771.324036 114.969513,764.419495 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M152.995148,825.375366 
	C154.880356,826.482422 156.761261,827.958191 158.812454,830.082458 
	C159.649445,831.492249 160.316132,832.253601 160.991104,833.007446 
	C160.999390,833.000000 160.985336,833.012756 161.028229,833.329712 
	C161.693008,834.128601 162.314880,834.610657 163.085770,835.397705 
	C164.493317,837.466919 165.751877,839.231140 167.006073,841.336060 
	C166.982025,842.130981 166.962341,842.585144 166.942657,843.039368 
	C162.292053,837.274292 157.641434,831.509216 152.995148,825.375366 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M184.974396,865.024902 
	C181.954086,861.630066 178.960648,858.208801 175.974854,854.402588 
	C175.982498,854.017578 176.027710,853.972412 176.356018,853.978699 
	C177.122910,853.990784 177.561523,853.996643 178.294205,854.160156 
	C180.724686,856.878662 182.861069,859.439331 184.998383,862.369019 
	C185.017776,863.473694 185.036240,864.209473 185.054718,864.945190 
	C185.054718,864.945190 185.001266,864.998413 184.974396,865.024902 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M137.055145,803.282227 
	C139.724075,806.359436 142.369202,809.813232 145.003113,813.637512 
	C142.338394,810.558289 139.684860,807.108521 137.055145,803.282227 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M144.988922,814.379150 
	C147.643372,817.435425 150.295181,820.863159 152.970062,824.645447 
	C150.324203,821.583557 147.655258,818.167053 144.988922,814.379150 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M130.994370,793.360718 
	C133.009491,795.737366 135.016205,798.501648 137.013596,801.640320 
	C134.998169,799.259216 132.992081,796.503784 130.994370,793.360718 
z"/>
<path fill="#8B92A5" opacity="1.000000" stroke="none" 
	d="
M166.956558,843.032715 
	C166.962341,842.585144 166.982025,842.130981 167.342896,841.386597 
	C169.783585,843.742920 171.883102,846.389404 173.608063,849.026367 
	C172.483688,849.011780 171.733887,849.006775 170.984100,849.001709 
	C170.394501,848.203430 169.804886,847.405212 169.082993,846.002258 
	C168.288895,844.612122 167.627075,843.826660 166.965256,843.041138 
	C166.965271,843.041138 166.970459,843.026123 166.956558,843.032715 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M171.019516,849.345459 
	C171.733887,849.006775 172.483688,849.011780 173.617218,849.264526 
	C174.000961,849.512268 174.027512,849.988953 174.134216,850.260620 
	C174.827423,851.017273 175.413925,851.502258 176.000214,851.993652 
	C176.000000,852.000000 176.013168,851.999451 176.010376,852.328491 
	C176.014297,853.095764 176.020996,853.534119 176.027710,853.972412 
	C176.027710,853.972412 175.982498,854.017578 175.959152,854.041016 
	C174.308853,852.606018 172.681885,851.147583 171.019516,849.345459 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M166.970184,843.391479 
	C167.627075,843.826660 168.288895,844.612122 168.952271,845.727600 
	C168.294266,845.285645 167.634705,844.513733 166.970184,843.391479 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M97.054092,727.255615 
	C97.811325,727.521362 98.575775,728.115295 99.118744,728.869385 
	C98.285278,728.547607 97.673302,728.065674 97.054092,727.255615 
z"/>
<path fill="#BAB9B8" opacity="1.000000" stroke="none" 
	d="
M633.321533,1016.957581 
	C630.607178,1016.973572 628.216003,1016.987976 625.033081,1017.002319 
	C623.472473,1016.980103 622.703674,1016.958008 622.011230,1016.600342 
	C623.105774,1014.929932 624.123840,1013.595215 625.520020,1012.213745 
	C626.910950,1012.148438 627.923767,1012.129883 629.021851,1012.472046 
	C630.581787,1012.992126 632.134583,1013.531372 633.517456,1013.246094 
	C639.923767,1011.924988 646.325439,1010.536743 652.649963,1008.872803 
	C665.595764,1005.466919 678.526672,1001.993408 691.393555,998.303101 
	C699.006775,996.119568 706.485962,993.468262 714.025391,991.027344 
	C709.241821,993.349243 704.457703,995.670044 699.675049,997.993713 
	C698.153198,998.733093 696.635315,999.480652 695.046570,1000.581726 
	C694.538025,1000.947998 694.098572,1000.957092 693.119019,1000.723511 
	C688.093201,1001.695679 683.589294,1002.849670 679.138184,1004.180420 
	C678.316040,1004.426208 677.707458,1005.386536 676.628052,1006.013062 
	C675.512634,1006.025879 674.769348,1006.042847 673.635315,1006.047729 
	C670.317444,1006.926270 667.366028,1007.745605 664.472046,1008.733154 
	C662.605957,1009.369934 660.824036,1010.253479 659.004395,1011.026367 
	C658.565857,1011.028076 658.127319,1011.029785 656.946655,1011.024353 
	C652.447327,1012.325684 648.690063,1013.634216 644.932800,1014.942688 
	C644.505554,1014.944092 644.078369,1014.945496 643.178772,1014.727661 
	C642.136780,1014.667786 641.567200,1014.827271 640.997559,1014.986755 
	C640.556335,1014.993469 640.115112,1015.000122 638.966797,1015.003784 
	C637.152039,1015.649414 636.044373,1016.298035 634.936707,1016.946716 
	C634.505981,1016.949829 634.075317,1016.952942 633.321533,1016.957581 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M714.388184,991.013489 
	C706.485962,993.468262 699.006775,996.119568 691.393555,998.303101 
	C678.526672,1001.993408 665.595764,1005.466919 652.649963,1008.872803 
	C646.325439,1010.536743 639.923767,1011.924988 633.517456,1013.246094 
	C632.134583,1013.531372 630.581787,1012.992126 629.399780,1012.389038 
	C632.130615,1011.301147 634.568970,1010.656921 637.422241,1010.012390 
	C644.706482,1008.376404 651.521179,1006.445251 658.455627,1005.160645 
	C678.986572,1001.357178 698.418640,994.137024 717.794006,986.668091 
	C720.074585,985.788818 722.588257,985.514221 724.993896,984.959351 
	C722.358826,986.712402 719.723755,988.465454 717.005859,990.550537 
	C716.199036,990.921570 715.475037,990.960632 714.388184,991.013489 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M659.254456,1011.302368 
	C660.824036,1010.253479 662.605957,1009.369934 664.472046,1008.733154 
	C667.366028,1007.745605 670.317444,1006.926270 673.628418,1006.411438 
	C673.999878,1007.517212 673.987488,1008.247192 673.975098,1008.977234 
	C673.233582,1008.987427 672.492126,1008.997559 671.380188,1009.015503 
	C670.041565,1009.448669 669.073364,1009.874084 668.049683,1010.642456 
	C665.164246,1011.183044 662.334412,1011.380615 659.254456,1011.302368 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M677.000244,1006.017090 
	C677.707458,1005.386536 678.316040,1004.426208 679.138184,1004.180420 
	C683.589294,1002.849670 688.093201,1001.695679 692.789185,1000.726685 
	C692.998779,1001.419128 692.998169,1001.865723 692.996338,1002.646545 
	C689.228271,1004.034607 685.462952,1005.093689 681.693787,1006.139038 
	C680.328979,1006.517578 678.955017,1006.862915 677.288330,1006.869507 
	C676.991516,1006.515625 677.000244,1006.017029 677.000244,1006.017090 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M676.628052,1006.013000 
	C677.000244,1006.017029 676.991516,1006.515625 676.987793,1006.765137 
	C676.191162,1007.651062 675.398254,1008.287476 674.290161,1008.950562 
	C673.987488,1008.247192 673.999878,1007.517212 674.019165,1006.423462 
	C674.769348,1006.042847 675.512634,1006.025879 676.628052,1006.013000 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M645.277710,1014.963257 
	C648.690063,1013.634216 652.447327,1012.325684 656.617920,1011.036316 
	C653.962280,1012.370239 650.893250,1013.685120 647.413330,1015.002441 
	C646.542480,1014.997864 646.082520,1014.990845 645.277710,1014.963257 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M635.280579,1016.967407 
	C636.044373,1016.298035 637.152039,1015.649414 638.635925,1015.012573 
	C639.006470,1015.762207 639.000793,1016.500000 638.987061,1017.606323 
	C638.979126,1017.974854 638.488159,1017.980774 638.242554,1017.978882 
	C637.206177,1017.647339 636.415283,1017.317688 635.280579,1016.967407 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M641.143982,1015.217773 
	C641.567200,1014.827271 642.136780,1014.667786 642.858337,1014.727234 
	C642.437012,1015.113770 641.863708,1015.281311 641.143982,1015.217773 
z"/>
<path fill="#9195A4" opacity="1.000000" stroke="none" 
	d="
M395.243103,1000.631714 
	C394.589783,1000.893188 394.175568,1000.897461 393.453461,1000.884888 
	C392.397125,1000.070740 391.795685,998.883789 390.877197,998.537170 
	C383.195465,995.638489 375.459717,992.882202 367.724609,990.126953 
	C367.129730,989.915100 366.415222,990.039307 365.356873,990.048645 
	C364.535675,990.054749 364.113922,990.019592 363.375732,989.970703 
	C360.718079,988.356995 358.542725,986.395447 356.002197,985.230225 
	C350.266510,982.599487 344.191895,980.683838 338.539978,977.899231 
	C326.143738,971.791870 313.883148,965.403870 301.655487,958.962769 
	C297.438965,956.741638 293.484344,954.023193 289.210144,951.259888 
	C289.012329,950.541565 289.014465,950.095337 289.379883,949.357910 
	C292.284973,950.512878 294.760742,952.093933 297.382355,953.376892 
	C302.803436,956.029663 308.302216,958.523621 313.829224,961.085388 
	C313.889374,961.087891 313.948273,961.192932 314.024963,961.518738 
	C315.150665,962.602966 316.118896,963.523315 317.261749,964.093750 
	C325.374664,968.143188 333.396393,972.410095 341.691528,976.050903 
	C352.985962,981.008301 364.471619,985.536133 375.934174,990.100342 
	C378.941437,991.297791 382.165863,991.949829 385.577026,992.852112 
	C386.236725,992.929260 386.609955,993.006592 387.060669,993.438110 
	C387.964233,994.220520 388.751740,994.761475 389.622406,995.059326 
	C397.407013,997.722473 405.206268,1000.342834 413.000397,1003.251282 
	C412.998474,1003.710571 412.997345,1003.894287 412.996185,1004.078003 
	C408.734161,1003.114868 404.476715,1002.130615 400.207642,1001.199768 
	C398.646729,1000.859375 397.058136,1000.645874 395.243103,1000.631714 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M218.959045,898.044495 
	C216.313477,895.951172 213.667908,893.857788 210.998138,891.395264 
	C210.973923,891.026123 211.026550,890.973450 211.358398,890.976685 
	C212.133484,890.967346 212.576706,890.954773 213.354202,890.997986 
	C215.462677,892.700684 217.236877,894.347595 219.009308,896.336060 
	C218.991379,897.133301 218.975220,897.588867 218.959045,898.044495 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M226.858124,904.115234 
	C224.586700,902.657532 222.315292,901.199890 220.379852,899.373169 
	C221.467514,899.021912 222.219177,899.039673 223.159241,899.309082 
	C224.567154,900.705994 225.786652,901.851257 226.982727,903.277954 
	C226.925568,903.744690 226.891846,903.929993 226.858124,904.115234 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M238.950760,915.051392 
	C237.296875,913.974854 235.665085,912.875061 234.362671,911.394653 
	C235.457809,911.015076 236.223541,911.016113 237.249298,911.144531 
	C238.004959,911.848328 238.500595,912.424805 238.998367,913.331543 
	C239.006210,914.102051 239.011917,914.542175 239.017639,914.982361 
	C239.017639,914.982361 238.972839,915.028198 238.950760,915.051392 
z"/>
<path fill="#8B92A5" opacity="1.000000" stroke="none" 
	d="
M207.196991,888.217834 
	C207.017700,886.920227 206.993576,885.864868 207.265671,884.545959 
	C208.712753,885.839417 209.863602,887.396423 210.669434,888.965454 
	C209.863937,888.998840 209.403473,889.020264 208.943008,889.041687 
	C208.412720,888.847839 207.882446,888.653931 207.196991,888.217834 
z"/>
<path fill="#8B92A5" opacity="1.000000" stroke="none" 
	d="
M236.989288,911.017090 
	C236.223541,911.016113 235.457809,911.015076 234.308472,911.046204 
	C233.397079,910.876831 232.869293,910.675293 232.181900,909.900513 
	C232.024750,908.880493 232.027191,908.433655 232.379822,907.992004 
	C234.149765,909.003784 235.569519,910.010437 236.989288,911.017090 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M232.029633,907.986877 
	C232.027191,908.433655 232.024750,908.880493 232.019043,909.662354 
	C230.691269,908.891052 229.366745,907.784607 228.386520,906.336304 
	C229.482803,906.012329 230.234772,906.030273 231.225067,906.211792 
	C231.652115,906.912537 231.840881,907.449707 232.029633,907.986877 
z"/>
<path fill="#8B92A5" opacity="1.000000" stroke="none" 
	d="
M222.970856,899.057434 
	C222.219177,899.039673 221.467514,899.021912 220.339233,899.021545 
	C219.652679,898.920532 219.396866,898.729614 219.077087,898.255371 
	C218.975220,897.588867 218.991379,897.133301 219.353424,896.337769 
	C220.159103,895.994873 220.618912,895.991943 221.223694,896.144653 
	C221.565811,896.504639 221.773239,896.697937 222.089233,897.163818 
	C222.448608,897.984131 222.709732,898.520813 222.970856,899.057434 
z"/>
<path fill="#8B92A5" opacity="1.000000" stroke="none" 
	d="
M230.986771,906.048218 
	C230.234772,906.030273 229.482803,906.012329 228.354080,905.999634 
	C227.700043,905.531677 227.422745,905.058411 227.001785,904.350220 
	C226.891846,903.929993 226.925568,903.744690 227.312332,903.308594 
	C228.772507,904.054565 229.879639,905.051392 230.986771,906.048218 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M208.993561,889.356445 
	C209.403473,889.020264 209.863937,888.998840 210.671967,888.981018 
	C211.019547,888.984619 211.045776,888.987000 211.032700,889.318115 
	C211.021927,890.090698 211.024246,890.532043 211.026550,890.973450 
	C211.026550,890.973450 210.973923,891.026123 210.946609,891.053467 
	C210.294235,890.610901 209.669174,890.140991 208.993561,889.356445 
z"/>
<path fill="#1B41D9" opacity="1.000000" stroke="none" 
	d="
M221.990997,896.880066 
	C221.773239,896.697937 221.565811,896.504639 221.172241,895.803711 
	C215.934494,890.644104 210.893204,885.981079 205.851944,881.317993 
	C205.570969,881.062256 205.289978,880.806519 204.878571,880.023193 
	C203.773773,878.809631 202.799423,878.123718 201.825058,877.437744 
	C201.825058,877.437744 201.463165,877.169128 201.253571,876.778076 
	C200.635376,876.049927 200.226791,875.712891 199.818207,875.375793 
	C199.351807,874.783569 198.885422,874.191345 198.146210,873.129028 
	C195.215820,870.163269 192.558273,867.667725 189.900711,865.172119 
	C189.900711,865.172119 189.897491,865.097656 189.875458,864.753174 
	C182.100174,855.026733 174.381973,845.615417 166.565414,836.286621 
	C164.918671,834.321289 162.911133,832.658325 161.043015,830.637939 
	C161.016174,830.419861 160.814316,830.029541 160.814331,830.029541 
	C156.512589,823.580505 152.127762,817.185120 147.927490,810.670654 
	C140.639343,799.367004 132.718552,788.387512 126.414879,776.552063 
	C116.436287,757.817017 106.614326,738.883850 98.523674,719.292175 
	C91.332100,701.877747 86.156540,683.578247 80.898834,665.435303 
	C77.075218,652.241089 73.812370,638.785583 71.657539,625.230652 
	C68.718994,606.745789 66.516418,588.099243 65.047905,569.439392 
	C63.875286,554.539429 63.711887,539.501160 64.106651,524.550903 
	C64.649879,503.978180 66.185081,483.424469 70.598679,463.238159 
	C74.881943,443.648041 78.699425,423.918365 83.977501,404.593018 
	C90.622307,380.263489 99.616066,356.666473 111.270958,334.247803 
	C120.215416,317.042725 129.033447,299.661102 139.526138,283.402496 
	C150.269043,266.756165 162.486557,250.979187 174.987061,235.575104 
	C185.229034,222.954163 196.423111,211.028061 207.929474,199.528717 
	C226.516541,180.952972 246.525238,163.929779 268.593414,149.572632 
	C284.823944,139.013351 301.041321,128.281693 318.095428,119.184479 
	C334.146881,110.622131 351.223938,103.955360 367.961304,96.712326 
	C394.123840,85.390556 421.501556,77.819481 449.444885,72.802849 
	C469.956573,69.120407 490.778473,66.886559 511.554352,65.050713 
	C526.106628,63.764816 540.826050,63.717297 555.450256,64.042130 
	C580.871338,64.606796 606.083740,67.495285 631.086182,72.306404 
	C654.917786,76.892227 678.332458,83.089699 701.123474,91.278427 
	C716.727356,96.884834 731.925842,103.718620 747.003113,110.660629 
	C759.489929,116.409943 771.836182,122.605446 783.686646,129.554840 
	C794.681458,136.002396 804.956543,143.681854 815.515320,150.867630 
	C842.715027,169.378464 867.445923,190.880310 889.452026,215.211639 
	C904.529602,231.882355 918.468811,249.687622 931.763916,267.830627 
	C941.511902,281.133087 949.590759,295.686401 958.053101,309.896820 
	C971.935059,333.208160 982.314697,358.122070 991.302368,383.674408 
	C1000.347839,409.390839 1007.449463,435.603394 1011.203796,462.601532 
	C1013.714966,480.659973 1015.680969,498.820160 1017.092346,516.996826 
	C1018.095215,529.912659 1018.695740,542.995056 1017.820068,555.890625 
	C1016.417175,576.551514 1013.809143,597.138733 1011.402954,617.721130 
	C1008.673828,641.065125 1002.731750,663.722412 995.208374,685.912170 
	C989.943359,701.441345 984.024963,716.776672 977.726013,731.918762 
	C972.637512,744.151062 967.479309,756.526123 960.692322,767.842407 
	C948.581604,788.034973 935.912170,807.972839 922.213379,827.113342 
	C902.530823,854.614563 879.052673,878.847290 853.497803,900.975281 
	C843.469238,909.659119 832.794006,917.595764 822.433228,925.897583 
	C821.555359,926.601074 820.828857,927.493530 819.980835,928.639771 
	C819.929688,928.981384 819.501343,929.086792 818.980713,929.126953 
	C817.979248,929.780396 817.498413,930.393677 817.017517,931.006958 
	C817.017517,931.006958 816.571228,931.158142 816.050171,931.192993 
	C814.987183,931.798462 814.445190,932.369080 813.671509,932.973083 
	C813.439819,933.006531 813.036194,933.243652 812.691895,933.224487 
	C807.855835,936.450134 803.364014,939.695007 798.649780,942.971802 
	C798.427368,943.003845 798.021790,943.197693 797.710449,943.184082 
	C796.906372,943.765015 796.413635,944.359497 795.577942,944.972412 
	C794.508179,945.436768 793.781372,945.882629 792.708252,946.290161 
	C787.273560,949.510376 782.185120,952.768982 776.827332,956.113159 
	C776.391907,956.287659 776.225769,956.376648 775.715698,956.380005 
	C773.216492,957.849976 771.061340,959.405518 768.525208,960.985840 
	C766.880005,961.378174 765.535950,961.578918 764.364014,962.139404 
	C753.045471,967.552856 742.035156,973.735779 730.394531,978.322571 
	C711.315186,985.840515 691.944153,992.672241 672.475342,999.127136 
	C661.215332,1002.860352 649.522766,1005.289001 637.614990,1008.227600 
	C628.596497,1009.516052 619.977112,1010.817871 611.383301,1012.270508 
	C605.952332,1013.188477 600.559387,1014.331543 594.807617,1015.308472 
	C594.015076,1015.327576 593.564392,1015.411987 592.668701,1015.357178 
	C583.307434,1015.838623 574.392029,1016.960388 565.474548,1016.978210 
	C545.552002,1017.017944 525.628662,1016.487610 505.704865,1016.252075 
	C503.781464,1016.229309 501.852844,1016.643738 499.591858,1016.829102 
	C498.587158,1016.805908 497.917267,1016.808960 497.087769,1016.457153 
	C496.358612,1015.813110 495.815765,1015.354065 495.215515,1015.259521 
	C476.214935,1012.267151 457.210266,1009.300476 438.197601,1006.386169 
	C437.498413,1006.278931 436.712494,1006.737549 435.680908,1006.879150 
	C435.008789,1006.817017 434.622772,1006.808228 434.095093,1006.467957 
	C432.812988,1005.521851 431.739136,1004.658020 430.521454,1004.332886 
	C422.068237,1002.075195 413.585205,999.928894 405.111542,997.747864 
	C399.068939,996.192505 393.026001,994.638550 386.983185,993.083984 
	C386.609955,993.006592 386.236725,992.929260 385.430573,992.528564 
	C382.936279,991.223145 380.901428,990.180115 378.809235,989.268799 
	C361.708313,981.820129 344.599396,974.389771 327.486511,966.968689 
	C322.985352,965.016724 318.461639,963.116760 313.948273,961.192932 
	C313.948273,961.192932 313.889374,961.087891 313.709717,960.775391 
	C308.190491,957.261475 302.893829,953.985229 297.493164,950.890564 
	C294.826477,949.362488 291.967896,948.169312 289.100677,946.500854 
	C283.961578,943.213135 278.917938,940.249207 273.874329,937.285278 
	C273.874359,937.285217 273.830566,937.179138 273.718262,936.839355 
	C270.008423,934.105469 266.410858,931.711304 262.813293,929.317139 
	C262.813293,929.317200 262.433258,929.112793 262.195618,928.714111 
	C258.590149,925.972656 255.222351,923.629822 251.854523,921.286987 
	C251.854523,921.286987 251.493515,921.075012 251.249298,920.675415 
	C250.173721,919.261963 249.477631,918.086182 248.489288,917.260132 
	C241.520279,911.435730 234.534393,905.629456 227.448425,899.948853 
	C225.841751,898.660889 223.821075,897.889343 221.990997,896.880066 
M392.302979,766.097107 
	C391.868652,767.064758 391.434326,768.032349 390.991730,769.742554 
	C390.985199,770.485046 390.978638,771.227600 390.337158,772.072021 
	C389.894165,772.716064 389.451202,773.360107 389.000183,774.498413 
	C389.000183,774.498413 388.994293,774.992676 388.325714,775.092651 
	C386.882202,776.065063 385.438660,777.037415 383.899780,777.208191 
	C383.487152,770.992676 383.278107,764.753723 382.565033,758.572876 
	C382.265869,755.979492 380.899414,753.509277 380.000031,750.231628 
	C378.854065,748.526733 378.096924,746.096558 376.496826,745.238831 
	C368.251923,740.818909 360.384369,744.715332 352.551514,747.084656 
	C351.141357,747.511108 350.101288,749.167419 348.895508,750.261658 
	C347.246155,751.758301 345.601746,753.260376 343.447327,755.222778 
	C341.926422,749.357788 338.929504,746.921204 334.909576,744.588013 
	C329.888916,741.673950 325.582153,744.478699 320.970734,744.488403 
	C315.609009,744.499756 310.242493,744.464294 304.887421,744.232971 
	C302.982452,744.150635 301.072815,742.965759 299.220551,743.092407 
	C292.695923,743.538513 286.204742,744.685669 279.681824,744.862183 
	C276.025024,744.961060 272.309875,743.028320 268.657654,743.146118 
	C261.236481,743.385498 253.794937,744.112305 246.465134,745.284607 
	C244.607941,745.581604 241.781448,748.273804 241.737686,749.927246 
	C241.583374,755.757629 242.571243,761.609192 242.835297,767.465820 
	C242.905060,769.013123 241.930511,770.607483 241.435486,772.180237 
	C240.693695,772.211853 239.951904,772.243530 239.210114,772.275208 
	C236.758423,766.857178 234.405792,761.391357 231.818680,756.038757 
	C230.213181,752.717102 229.046265,748.087708 226.303055,746.615234 
	C217.761536,742.030396 208.339096,743.611633 199.173126,745.189331 
	C198.485687,745.307617 197.468826,746.089539 197.379410,746.680176 
	C196.855591,750.140259 195.523041,753.925110 196.397614,757.060913 
	C198.518143,764.664307 194.577255,771.376221 191.543289,776.494507 
	C186.744324,784.590393 186.987198,795.008972 179.388214,801.528687 
	C179.111649,801.766052 179.177139,802.465637 179.175598,802.950073 
	C179.161224,807.475037 179.167068,812.000061 179.167068,816.495972 
	C183.946442,817.035095 188.202499,817.524597 192.460831,817.993286 
	C198.521011,818.660217 204.312531,818.616089 209.126678,813.797729 
	C209.791855,813.132019 212.914856,813.758118 213.870483,814.764160 
	C216.760742,817.806885 219.831284,816.691711 223.658890,816.007690 
	C224.091644,816.024658 224.524399,816.041626 225.014984,816.877991 
	C226.264725,817.585022 227.507812,818.892761 228.765198,818.906677 
	C242.090515,819.054077 255.554977,820.119141 268.687225,818.519165 
	C275.243439,817.720459 281.487732,818.204529 287.852051,818.211853 
	C297.805695,818.223267 307.770020,819.073242 317.704926,818.765076 
	C320.566925,818.676331 325.052673,818.666626 326.466064,813.961121 
	C326.466064,813.961121 326.925232,813.917725 327.551178,814.241760 
	C334.880951,816.419800 342.506561,818.451599 349.920898,816.529114 
	C354.277313,815.399536 357.293701,817.509460 360.932220,817.944336 
	C362.185944,818.094177 363.390930,818.935120 364.622192,818.944702 
	C375.286804,819.027771 385.954742,819.123047 396.616333,818.926086 
	C400.567383,818.853149 404.003235,817.466919 405.213104,812.917297 
	C405.673920,811.184509 406.926880,809.662292 407.818817,808.044128 
	C408.288269,808.175476 408.757690,808.306824 409.227112,808.438171 
	C409.227112,810.688049 409.227112,812.937927 409.227112,815.187866 
	C409.607269,814.799011 409.987427,814.410217 410.367584,814.021423 
	C411.942291,815.017029 413.404114,816.540100 415.113586,816.906067 
	C419.623871,817.871338 424.232117,818.816101 428.818329,818.906067 
	C441.978149,819.164185 455.149719,819.163574 468.309448,818.901917 
	C472.879944,818.811096 477.429352,817.658081 482.839325,816.998108 
	C484.893524,816.332886 486.947693,815.667664 489.367737,814.338440 
	C493.944489,812.949707 498.455719,811.627380 501.340363,807.192322 
	C502.844757,804.879395 505.088013,803.046997 507.607300,800.695496 
	C509.039734,799.153198 510.472168,797.610840 511.904602,796.068542 
	C512.293457,796.320679 512.682312,796.572815 513.071106,796.824951 
	C512.509460,798.654968 511.880402,800.467407 511.398193,802.318176 
	C509.629120,809.108276 512.694580,819.274231 521.753052,819.191650 
	C528.364441,819.131409 534.983459,819.672241 541.595642,820.011597 
	C550.351746,820.460999 558.955994,820.031616 567.823730,816.018005 
	C571.884094,816.344238 575.944519,816.670471 580.752014,816.996460 
	C581.499084,816.996338 582.246216,816.996277 583.331848,817.456970 
	C583.901245,816.986023 584.470642,816.515076 585.827698,816.010681 
	C586.877563,815.994995 587.927368,815.979309 589.464050,816.527954 
	C595.270508,819.673157 594.412109,811.343933 598.482971,811.232239 
	C604.515625,817.178833 611.697998,819.254761 620.221558,819.204712 
	C629.074951,819.152710 637.750366,817.028381 646.852844,818.694946 
	C654.872986,820.163269 663.377319,819.095459 671.662292,818.907410 
	C673.123352,818.874268 674.557617,817.660889 676.752197,816.990173 
	C677.500183,816.987427 678.248230,816.984741 679.161804,817.759033 
	C687.060608,821.902649 691.426270,815.798584 696.889648,811.325317 
	C696.596130,813.185364 696.381958,814.542969 696.188721,815.767395 
	C698.209106,815.138916 700.127686,814.542114 702.004883,813.999878 
	C702.004883,813.999878 702.056213,813.953796 701.970398,814.618652 
	C701.917297,815.270386 701.667664,816.430176 701.840820,816.497131 
	C704.224670,817.419373 706.705261,818.971252 709.087646,818.854126 
	C716.387573,818.495300 723.745789,820.323364 731.069275,817.316833 
	C733.906311,816.152161 736.515869,815.776794 738.932678,813.291138 
	C740.122620,812.067383 744.219299,813.670105 747.723083,814.012756 
	C749.488586,815.003662 751.254028,815.994507 753.112122,817.774353 
	C754.569214,818.515076 756.035828,819.908752 757.481934,819.887756 
	C765.335205,819.774048 773.200012,819.498230 781.018005,818.798340 
	C782.449402,818.670166 783.682617,816.327637 785.590332,814.731934 
	C787.060181,813.487122 788.530090,812.242310 790.837463,811.003357 
	C794.329163,812.445251 797.783630,813.989563 801.330688,815.279297 
	C802.783691,815.807617 804.436707,815.785889 806.740845,816.018738 
	C807.481750,816.031128 808.222717,816.043579 809.015015,816.881104 
	C809.762512,817.580200 810.492798,818.862793 811.260315,818.885498 
	C817.079163,819.057739 822.913025,819.140381 828.722290,818.851074 
	C829.861755,818.794312 830.914734,817.001709 832.446045,815.793579 
	C832.719482,815.594482 832.907715,815.333862 833.801331,815.004822 
	C838.528503,815.342041 843.255737,815.679321 848.476746,816.024048 
	C848.476746,816.024048 848.970581,816.031738 849.096985,816.812256 
	C850.412903,817.539978 851.715271,818.867615 853.046936,818.897583 
	C861.475220,819.087646 869.924438,819.276855 878.335876,818.853638 
	C881.939636,818.672241 885.469299,817.019348 889.774475,816.020325 
	C890.517517,816.007996 891.260498,815.995605 892.445129,816.527100 
	C896.725037,814.129272 901.009460,811.739380 905.272888,809.312500 
	C905.511047,809.176880 905.626587,808.749146 905.712341,808.433716 
	C907.497009,801.870422 911.271973,796.108704 909.443787,788.303223 
	C908.274841,783.312439 907.258362,779.625977 902.710693,777.670654 
	C902.855530,775.676819 902.595642,773.888000 903.156921,772.411316 
	C906.436035,763.783691 901.636230,757.588135 896.678162,751.754578 
	C895.101562,749.899658 891.547852,749.881958 889.309631,748.402344 
	C883.358093,744.468140 876.700989,743.231995 869.855042,743.071472 
	C859.208740,742.821838 848.552124,742.986206 837.900146,743.032104 
	C836.437622,743.038452 834.660706,742.901794 833.569153,743.635010 
	C828.689880,746.912781 823.983337,750.336182 817.653320,745.338989 
	C816.999329,744.844727 816.384521,743.989014 815.685242,743.913391 
	C809.984985,743.296753 804.274292,742.712646 798.551392,742.439453 
	C797.741150,742.400757 796.848328,744.092224 795.174316,744.994141 
	C789.125732,745.320190 783.077209,745.646240 776.978333,745.146729 
	C774.755066,742.430908 763.167480,741.922424 761.153076,744.495972 
	C760.892700,744.828552 761.010986,745.457581 760.216248,745.960754 
	C759.478577,745.961792 758.740906,745.962891 757.563049,745.448608 
	C756.040466,745.960083 754.517822,746.471497 752.225525,746.992004 
	C748.441711,749.615051 744.396606,751.953125 741.034973,755.036499 
	C740.039551,755.949524 740.969971,758.962036 741.006592,761.865356 
	C740.997437,763.571594 740.988281,765.277832 740.220825,767.161377 
	C740.232239,769.850708 740.139221,772.545959 740.293274,775.227112 
	C740.389893,776.910095 741.278503,778.627075 741.067566,780.230957 
	C740.677246,783.198547 739.740723,786.094360 739.017029,789.677551 
	C739.008911,790.115906 739.000793,790.554321 738.212524,790.997070 
	C736.465271,791.179382 734.718018,791.361694 732.872986,791.554199 
	C730.189209,785.425171 727.810547,779.442749 724.926392,773.714844 
	C723.961182,771.797974 721.705200,770.531067 720.022644,768.311462 
	C720.018738,767.874329 720.014771,767.437134 720.543213,766.551575 
	C719.710632,763.044312 722.759644,757.688843 715.715698,756.351624 
	C714.490479,754.454529 713.108154,752.638916 712.073547,750.643005 
	C710.058228,746.755371 707.191589,744.705811 702.619629,744.949707 
	C696.366760,745.283325 690.090210,745.563110 683.836609,745.393799 
	C675.603271,745.170898 667.384766,744.155579 659.155396,744.094971 
	C650.202209,744.028992 641.244385,744.888977 632.287598,744.908081 
	C627.443298,744.918396 623.548218,745.778259 620.771973,750.210938 
	C619.208679,752.706909 616.709045,753.400818 614.997864,750.167175 
	C612.916626,746.234070 609.485718,745.105347 605.484802,745.021545 
	C600.821045,744.923889 596.128052,744.708557 591.498047,745.116028 
	C589.000183,745.335754 587.259338,747.299438 587.086365,750.576782 
	C587.086365,750.576782 587.040039,751.017029 586.388550,751.313171 
	C584.859558,754.337097 583.330566,757.361084 582.025085,759.942932 
	C580.116760,759.004272 578.063354,757.994324 575.716614,756.378540 
	C574.614929,755.155640 573.694336,753.674561 572.381042,752.753418 
	C565.973755,748.259766 558.897644,745.260071 551.009766,745.065674 
	C541.021545,744.819458 531.022522,745.009888 520.127991,745.002625 
	C515.313965,744.314758 511.933563,745.139587 512.893860,751.273438 
	C513.240112,753.485291 513.278870,755.847290 512.887268,758.038818 
	C512.600037,759.646545 511.702271,761.866150 510.482513,762.367310 
	C509.431244,762.799316 507.394470,761.390930 506.064972,760.423157 
	C504.856201,759.543091 504.012939,758.160828 502.715027,756.355347 
	C500.769409,755.031494 498.650330,753.902344 496.907562,752.350708 
	C488.419098,744.793152 477.677399,744.391846 467.305084,743.137146 
	C463.472137,742.673401 459.448334,743.549927 455.537872,744.029541 
	C451.320770,744.546814 447.124603,745.262634 442.949860,746.053528 
	C442.521393,746.134705 442.302063,747.319824 441.261261,748.071777 
	C437.003387,750.082031 436.171509,753.182068 437.414978,759.935181 
	C438.417358,765.379395 440.202789,770.887024 438.163544,776.921875 
	C435.629974,771.905579 433.416351,767.402283 431.093658,762.956055 
	C428.501129,757.993286 426.150818,752.854370 423.055847,748.218933 
	C420.461517,744.333435 404.906616,743.440857 401.094452,746.464050 
	C400.990173,746.546753 401.001099,746.774658 400.471069,746.962280 
	C400.471069,746.962280 399.983337,746.978516 399.336975,746.702332 
	C397.438507,747.479553 394.867706,747.748840 393.803955,749.156799 
	C392.322357,751.117676 391.464630,753.901733 391.321747,756.389832 
	C391.236450,757.874634 393.029724,759.467346 393.980499,761.808960 
	C393.970703,762.872192 393.960876,763.935425 393.529053,765.211975 
	C393.267914,765.409729 393.088104,765.664856 392.302979,766.097107 
M466.025696,209.670273 
	C466.025696,210.114548 466.025696,210.558807 465.452179,211.494598 
	C466.063751,215.509598 464.260376,220.265793 468.994385,223.855042 
	C468.924255,226.708008 468.593658,229.622620 472.510773,230.479660 
	C473.100922,230.608780 473.713928,232.003357 473.795349,232.861954 
	C474.267456,237.841171 475.889526,238.815170 481.698578,237.301407 
	C483.909882,238.231262 486.190002,239.027206 488.317291,240.120544 
	C492.041901,242.034790 496.388153,240.267288 498.710205,235.944290 
	C500.805176,234.626038 502.900146,233.307785 505.637482,231.913528 
	C506.092926,231.276703 506.548370,230.639877 507.506500,229.448380 
	C513.454773,227.548553 519.284058,224.983627 525.377197,223.893204 
	C536.601624,221.884430 546.518188,226.453735 555.303284,232.699387 
	C558.226746,234.777771 560.545593,236.271210 563.937622,236.681732 
	C563.746765,237.585175 563.555908,238.488602 563.311096,239.647614 
	C565.147095,239.129807 566.908142,237.982727 568.032471,238.431366 
	C572.343445,240.151535 572.181763,240.236099 575.338013,237.707779 
	C577.859619,235.687805 580.622009,233.968292 583.691223,231.826767 
	C584.225464,233.274292 584.958557,234.308258 584.906860,235.301346 
	C584.759155,238.136368 584.052979,240.950134 583.998413,243.780594 
	C583.863892,250.759155 584.605957,257.822601 583.738586,264.693207 
	C583.230957,268.713959 583.446167,274.421204 575.892517,274.443512 
	C573.299438,274.451141 569.068420,278.803406 568.471069,281.754852 
	C567.139587,288.332855 573.909363,296.613403 581.542847,299.282928 
	C582.363037,300.183929 583.183167,301.084930 584.005615,302.744873 
	C585.504517,309.152527 589.321960,313.096405 596.418396,314.236969 
	C596.688782,314.436768 596.875854,314.696564 596.990845,315.724884 
	C597.671814,316.471802 598.352783,317.218719 599.000000,318.000000 
	C599.000000,318.000000 599.036316,317.965302 599.023010,318.723663 
	C601.339355,322.756714 608.625244,325.658051 612.527222,323.508759 
	C616.387939,321.382141 620.076050,318.757599 623.369690,315.823639 
	C630.778564,309.223816 637.683472,302.047974 645.222351,295.608826 
	C648.980591,292.398834 650.051147,289.313599 647.988098,284.197937 
	C647.998596,283.138855 648.009033,282.079803 648.754028,280.928009 
	C654.147949,276.696686 660.400635,277.730927 666.426331,278.364777 
	C675.749268,279.345367 679.880981,276.168854 679.978882,267.056183 
	C680.083435,257.323517 680.001892,247.588882 680.005798,237.855103 
	C680.008850,230.211700 681.096985,229.356247 688.578491,229.260315 
	C690.618469,229.234146 693.699890,228.017700 694.436646,226.462936 
	C695.833923,223.514374 697.508667,218.876892 696.174011,216.807571 
	C692.665588,211.367813 687.618896,206.915741 683.154968,202.098083 
	C679.720886,198.391968 676.478455,194.460602 672.713135,191.123062 
	C669.164734,187.977737 666.536987,183.267258 660.311157,182.761932 
	C659.209045,182.506287 658.106995,182.250641 656.486755,181.415314 
	C653.843079,181.837585 650.480957,181.375153 648.681274,182.836685 
	C641.257812,188.865143 634.300903,195.469040 627.190186,201.881027 
	C625.648315,203.271423 624.047974,204.630066 622.702148,206.198608 
	C618.875977,210.657913 613.661499,214.226990 613.804688,221.233673 
	C613.829773,222.461441 611.415466,224.655792 609.946655,224.824066 
	C605.680420,225.312820 601.322144,224.998978 596.900391,224.193466 
	C596.364563,221.284210 595.361694,218.363800 595.430786,215.468994 
	C595.493591,212.838791 596.287537,209.960373 597.675659,207.773041 
	C598.155273,207.017349 601.391541,208.011063 602.871460,208.156754 
	C606.121338,206.629349 609.248291,205.299744 612.214172,203.676102 
	C613.047852,203.219711 613.408936,201.899872 614.322449,200.488846 
	C614.876221,200.320450 615.429993,200.152054 616.097412,200.267654 
	C616.184631,200.318939 616.271790,200.370209 616.359009,200.421509 
	C616.233887,200.275726 616.108765,200.129959 615.981506,199.254395 
	C616.319763,198.167831 616.658020,197.081253 617.746155,195.989456 
	C619.862610,194.145401 621.745300,191.881561 624.144592,190.545395 
	C629.014954,187.833130 630.205139,183.993057 629.005981,178.883057 
	C627.679443,173.230606 628.664368,172.347626 634.453979,172.867981 
	C638.771667,173.256012 643.349548,173.643097 645.689453,167.931503 
	C649.543213,164.107437 653.343811,160.227936 657.263672,156.472900 
	C662.471985,151.483566 663.124756,141.231598 657.487976,136.735779 
	C648.719604,129.742188 641.321350,121.089638 630.555725,116.417213 
	C620.776245,112.172798 611.949829,105.762627 602.242798,101.311424 
	C594.188843,97.618256 585.386108,95.591797 577.076050,92.406929 
	C573.735779,91.126778 570.673279,91.278244 567.247131,91.216850 
	C561.526611,91.114342 555.882751,88.537689 550.128357,88.143227 
	C538.636047,87.355423 527.091248,87.319496 515.566711,87.031609 
	C514.092163,86.994781 512.544128,87.084625 511.139557,87.492531 
	C497.030975,91.589760 481.747864,90.693459 468.299530,97.693352 
	C465.281525,99.264236 461.767792,99.852310 458.619415,101.210709 
	C454.485596,102.994286 450.449524,105.018402 446.440094,107.073120 
	C440.246307,110.247284 434.072540,113.466896 428.002167,116.867027 
	C427.300781,117.259895 427.311523,118.924179 426.658142,120.480293 
	C426.111969,120.658432 425.565765,120.836571 424.600067,120.510040 
	C422.802582,121.113960 420.690216,121.306908 419.266144,122.398140 
	C414.834381,125.794144 410.415436,129.284439 406.493622,133.236389 
	C404.458893,135.286758 403.482605,138.387497 402.002930,141.709991 
	C401.643341,142.795746 401.283722,143.881516 400.235077,145.123184 
	C397.747406,147.949799 399.218628,149.672897 401.926422,151.709717 
	C401.937256,152.142426 401.948090,152.575134 401.445374,153.420731 
	C401.298950,154.279556 401.152527,155.138397 401.110229,156.694229 
	C403.854492,160.631439 407.015656,164.019547 412.010681,164.991974 
	C412.010681,164.991974 412.023102,164.977890 412.081055,165.698242 
	C415.010498,169.947372 418.772736,172.654785 424.214508,171.985764 
	C428.144562,171.502594 431.768188,173.162552 432.611725,176.470276 
	C433.699158,180.734360 432.960663,185.464096 433.024933,190.767807 
	C434.337036,192.134537 436.387177,193.308716 436.804626,194.908875 
	C437.624512,198.051559 439.640198,198.047226 442.000092,198.499100 
	C442.000092,198.499100 442.006104,198.998779 442.003143,199.786987 
	C442.925720,204.952026 446.473877,206.801453 452.799164,204.999359 
	C453.864746,205.002182 454.930328,205.005020 456.076050,205.603256 
	C456.483704,205.920959 456.891327,206.238663 457.298981,206.556381 
	C457.533844,206.039917 457.768707,205.523468 458.836395,205.004196 
	C460.224426,205.001648 461.612488,204.999115 463.083466,205.640671 
	C463.725952,206.093964 464.368469,206.547272 465.051941,207.603546 
	C465.383728,208.070496 465.715546,208.537430 466.025696,209.670273 
M192.239655,577.008545 
	C188.134003,578.759338 186.528198,582.538818 187.995071,587.664429 
	C187.994904,588.107056 187.994736,588.549683 187.277054,589.127625 
	C184.743759,594.277344 182.026291,599.347290 179.748856,604.607788 
	C178.462875,607.578247 177.900833,610.862183 177.005081,614.859985 
	C177.001556,616.570862 176.998032,618.281799 176.281281,620.115784 
	C175.518005,622.074158 174.754730,624.032532 173.293076,626.110168 
	C171.433884,629.896790 169.504105,633.650818 167.738403,637.480530 
	C166.385483,640.414917 165.148865,643.411255 164.030640,646.442871 
	C163.144196,648.846130 163.062668,651.725342 161.668427,653.728088 
	C157.772156,659.324951 153.349289,664.553162 149.216339,669.989258 
	C148.584229,670.820740 148.387650,671.983276 147.315689,672.998840 
	C146.544205,673.333374 145.772720,673.667908 144.111694,673.999634 
	C136.266190,674.004211 130.369141,677.910950 129.004822,684.825500 
	C128.006180,687.465088 126.009415,690.179993 126.201614,692.729797 
	C126.716881,699.565857 128.131592,706.337830 129.311737,713.113892 
	C129.422699,713.751038 130.327118,714.597839 130.989380,714.734192 
	C135.128372,715.586426 139.295746,716.845520 143.467545,716.899475 
	C161.589783,717.133789 179.717392,716.913147 197.841721,717.042603 
	C205.117371,717.094604 212.398163,717.973694 219.660141,717.797424 
	C223.846100,717.695801 228.091217,716.523071 232.113220,715.224182 
	C233.389206,714.812073 234.030975,712.436218 235.782059,710.950989 
	C237.858627,710.635010 239.935211,710.319092 242.402130,709.823547 
	C242.675369,709.651794 242.784866,709.406860 242.770721,708.811279 
	C242.770721,708.811279 242.816879,708.534607 243.257370,707.976074 
	C243.582611,707.631042 243.907852,707.286011 244.981964,706.731750 
	C245.317413,701.194336 245.667053,695.657654 245.983841,690.119202 
	C246.244812,685.556763 244.571259,683.120483 239.666733,681.788818 
	C231.174759,679.483032 224.233963,674.234253 226.070847,664.152344 
	C226.687180,660.769531 227.766876,657.438904 228.942856,654.196106 
	C232.546936,644.258057 236.236618,634.349670 240.028214,624.481628 
	C241.488724,620.680542 243.313156,617.019287 244.663116,613.987549 
	C248.450638,613.987549 251.284607,613.987366 254.118576,613.987488 
	C276.938141,613.988403 299.757782,614.013977 322.577209,613.954773 
	C325.037933,613.948425 327.534149,613.633789 329.950531,613.147217 
	C336.941772,611.739441 344.115570,615.182373 346.136322,621.192261 
	C348.284546,627.581177 350.742767,633.865417 353.022125,640.210938 
	C354.854584,645.312439 356.021667,650.758179 358.547729,655.482483 
	C363.308105,664.385498 361.735840,672.118103 353.344971,676.755676 
	C347.649261,679.903687 341.599213,691.368958 342.465149,697.650879 
	C342.664551,699.097473 343.481689,700.451477 343.940735,701.871948 
	C344.344635,703.121765 344.824738,704.385925 344.960693,705.676514 
	C345.566711,711.428833 351.389771,717.062317 357.150452,716.947327 
	C363.442078,716.821777 369.729187,716.007996 376.013336,716.062561 
	C392.796448,716.208191 409.575989,716.827698 426.358795,716.919922 
	C435.476227,716.970093 444.595215,716.161804 453.717499,716.041077 
	C466.124451,715.876892 478.535339,715.967285 490.944061,716.036865 
	C492.718384,716.046814 494.611511,716.245056 496.239563,716.888184 
	C500.810638,718.693542 511.113708,716.409485 514.197144,712.680237 
	C515.534729,711.062500 522.932190,709.781677 524.552368,710.605042 
	C529.529480,713.134338 534.525818,715.651733 539.663147,717.822266 
	C541.016724,718.394165 542.927551,717.817932 544.546631,717.551147 
	C547.096130,717.131042 549.620605,716.049927 552.146240,716.078552 
	C566.930786,716.246643 581.712036,716.872375 596.495544,716.913086 
	C607.612854,716.943665 618.732483,716.050720 629.850098,716.071838 
	C643.134277,716.097046 656.416321,716.723206 669.701660,716.956421 
	C675.364502,717.055847 681.404236,718.326660 685.251770,711.999207 
	C685.977112,710.806213 688.524475,710.763367 690.186890,710.081726 
	C691.150574,709.686646 692.025940,709.075989 693.693054,708.130920 
	C694.086670,707.298218 694.820740,706.465332 694.820312,705.632812 
	C694.817078,698.762329 694.677673,691.891785 695.238281,684.973511 
	C695.399536,684.939758 695.571594,684.930298 695.719055,684.865295 
	C695.859436,684.803345 695.974304,684.683472 696.100403,684.589111 
	C694.741211,683.734436 693.431335,682.785828 692.013733,682.042480 
	C687.629089,679.743286 683.100952,677.692017 678.874573,675.137207 
	C677.957153,674.582703 678.065674,672.330994 677.701599,670.861023 
	C676.009155,669.124573 674.612427,667.111206 673.534851,664.939453 
	C672.600037,663.055481 672.026855,660.992126 671.058472,658.343628 
	C671.110229,657.880676 671.161926,657.417786 671.712952,656.306030 
	C671.807861,654.486633 671.984131,652.667236 671.985291,650.847778 
	C672.002991,623.707092 671.955200,596.566284 672.041504,569.425842 
	C672.055054,565.168579 672.652954,560.913147 672.986267,556.584351 
	C675.377075,555.983765 677.280823,555.096741 679.188354,555.088562 
	C702.332336,554.989380 725.478027,554.892761 748.620483,555.098999 
	C754.984436,555.155640 757.980652,558.103455 757.985291,563.021240 
	C758.013489,592.659546 758.275696,622.302490 757.779541,651.932495 
	C757.671875,658.366272 758.850647,665.936096 753.026245,671.071838 
	C748.890015,674.718994 744.099487,677.618530 739.894714,681.196777 
	C738.467957,682.410889 737.213684,684.646790 737.129211,686.473511 
	C736.809265,693.394348 736.941589,700.339539 737.021545,707.274292 
	C737.081360,712.454163 741.135620,717.280884 746.309265,717.915771 
	C749.667114,718.327881 753.117065,718.057983 756.523804,717.991455 
	C772.417297,717.681274 788.308838,717.160950 804.203491,717.044250 
	C826.709290,716.879089 849.217773,716.916992 871.724060,717.048157 
	C879.753357,717.094971 887.778320,717.870117 895.807739,717.921326 
	C900.552246,717.951599 901.561279,715.951538 900.862305,711.021667 
	C902.894348,710.164551 906.407288,712.950317 907.768127,708.219055 
	C908.401794,706.881653 909.517822,705.566956 909.582031,704.202759 
	C909.808777,699.387634 909.520142,694.548706 909.732910,689.732361 
	C909.874390,686.529114 909.096375,685.206726 905.515686,684.662659 
	C892.720520,682.718628 886.048889,674.451721 886.496155,660.577148 
	C886.662659,659.110291 886.974487,657.643555 886.974854,656.176636 
	C886.998718,571.451965 887.003601,486.727295 886.948120,402.002655 
	C886.947021,400.309387 886.211975,398.616577 885.658936,396.093170 
	C886.335449,387.632111 889.284912,380.453949 897.711975,376.004333 
	C898.904236,375.472687 900.096436,374.941071 901.860657,374.258209 
	C902.235474,374.135803 902.610352,374.013428 903.588562,374.057343 
	C904.143005,373.831818 904.697449,373.606262 906.009521,373.303925 
	C906.991333,373.101624 907.973206,372.899292 909.662598,372.320679 
	C909.770386,365.789764 909.965332,359.257935 909.893860,352.728973 
	C909.882996,351.729095 908.642639,350.742676 907.810547,349.064301 
	C906.188110,348.514404 904.565735,347.964539 902.943298,347.414642 
	C903.318359,345.723816 902.907410,343.577698 901.993591,343.336517 
	C897.915405,342.260101 893.695862,341.108704 889.523376,341.094025 
	C845.879517,340.940125 802.234863,341.000671 758.590454,340.997833 
	C756.925415,340.997742 755.244629,341.096863 753.598083,340.911224 
	C746.771240,340.141541 740.438477,342.806885 738.054138,348.778717 
	C736.717773,352.125885 737.288818,356.234589 737.000000,360.750122 
	C737.000000,361.500092 737.000000,362.250031 736.993958,363.881500 
	C736.995605,365.213196 736.938843,366.547943 737.008301,367.876099 
	C737.483765,376.972198 741.961792,382.641602 750.331604,385.051331 
	C751.389160,385.355774 752.308289,386.343597 753.150391,387.160400 
	C758.548889,392.396393 757.742981,399.507751 757.830505,405.854858 
	C758.220459,434.148224 757.908813,462.450592 758.088257,490.748199 
	C758.118042,495.440369 756.874573,497.142944 751.934814,497.102112 
	C725.302490,496.882111 698.667542,496.984192 672.006958,496.043121 
	C672.004883,464.358154 672.002869,432.673218 672.563232,400.497101 
	C672.704163,398.995728 672.845093,397.494354 673.008606,395.210510 
	C675.272156,384.486725 681.195618,377.523041 692.521484,375.873138 
	C696.045898,375.359711 697.340149,373.310944 696.812073,369.487000 
	C696.138611,364.609863 695.861755,359.596375 696.161926,354.687408 
	C696.525452,348.740051 696.287598,348.066193 690.505859,348.002808 
	C689.013000,347.986450 687.519958,347.982208 685.997925,347.209290 
	C685.702576,343.829285 683.801636,342.187103 680.467773,341.998444 
	C675.010437,341.689697 669.556519,341.053833 664.099976,341.041290 
	C626.770264,340.955536 589.440125,340.998169 552.110168,340.990295 
	C550.446594,340.989929 548.610596,341.343719 547.150024,340.774841 
	C541.566711,338.600311 536.296814,337.850677 531.454895,342.418701 
	C531.077026,342.775177 530.150024,342.479462 529.618958,342.756561 
	C527.953430,343.625610 525.843018,344.245911 524.817810,345.635895 
	C519.889709,352.317383 521.021973,360.189667 521.004517,367.802155 
	C520.988281,374.904053 525.020447,380.679596 531.485168,382.885529 
	C537.725403,385.014862 544.995056,395.038544 544.995178,401.540131 
	C544.996826,484.699310 544.995605,567.858521 544.995178,651.017700 
	C544.995117,656.223022 544.972412,660.937134 541.430237,665.821350 
	C536.879333,672.096497 531.945679,676.837402 524.265076,678.771912 
	C524.187256,680.112976 524.106873,681.499268 523.930176,684.546204 
	C519.664856,682.314148 516.296997,680.566711 512.945984,678.787292 
	C512.122131,678.349792 511.364319,677.789856 510.557678,677.317688 
	C507.082947,675.283691 503.260223,673.675781 500.192627,671.149597 
	C493.620728,665.737366 490.600739,658.018433 487.478882,650.316162 
	C480.172913,632.290894 472.472443,614.425903 465.023529,596.457947 
	C461.626526,588.263855 458.593445,579.917603 455.138062,571.749207 
	C447.723846,554.222351 440.051392,536.804504 432.665497,519.265930 
	C428.547882,509.488281 424.885162,499.520050 420.851379,489.705963 
	C415.889709,477.634399 410.824982,465.603546 405.616852,453.636871 
	C404.864777,451.908783 403.064209,450.637054 401.839081,449.100891 
	C401.839081,449.100891 401.842163,449.205658 401.934906,448.378510 
	C401.635529,446.937195 401.543549,445.412811 401.004730,444.067383 
	C395.409790,430.096649 389.738953,416.156311 384.097198,402.204285 
	C378.037506,387.218781 372.069092,372.195374 365.854340,357.274506 
	C364.753845,354.632324 362.642639,352.411133 360.841644,349.225098 
	C358.248962,348.797302 355.656250,348.369507 353.510925,347.420898 
	C354.772675,347.085968 356.034393,346.751038 357.296143,346.416107 
	C357.323242,345.862610 357.350372,345.309113 357.377472,344.755615 
	C351.212067,343.502930 345.062592,341.269562 338.878479,341.169159 
	C313.594147,340.758789 288.299194,341.015656 263.008270,340.972260 
	C260.877869,340.968597 258.725250,340.688812 256.622375,340.315308 
	C249.611221,339.070099 241.116058,345.854248 241.014114,352.948639 
	C240.935242,358.438324 240.623077,363.963409 241.105469,369.411591 
	C241.597672,374.970978 241.214493,381.189362 249.411102,382.584564 
	C252.182556,383.056305 256.234131,386.086487 256.618744,388.449158 
	C257.477356,393.723450 258.543884,399.762573 253.510025,404.231628 
	C252.822876,404.841644 252.524170,405.956726 252.193756,406.890350 
	C249.575699,414.287964 249.428268,422.232269 244.800369,429.344452 
	C241.281464,434.752289 239.879181,441.964752 240.167068,448.995087 
	C239.114471,449.908386 237.645172,450.625000 237.100876,451.778229 
	C236.134247,453.826263 235.867859,456.191833 235.058594,458.329926 
	C233.455719,462.564819 231.535828,466.682983 230.016403,470.945404 
	C228.049698,476.462738 227.825272,483.472595 224.186478,487.320282 
	C218.584351,493.244080 220.722153,500.826111 217.204269,506.972748 
	C214.290268,512.064270 212.914795,518.034973 210.845291,523.611572 
	C209.665939,526.789551 208.480209,529.965454 207.263870,533.129395 
	C205.861954,536.776062 204.343323,540.379883 203.026459,544.056458 
	C202.030502,546.836975 201.730301,549.958679 200.320953,552.481934 
	C196.206421,559.848389 192.564896,567.266052 193.006714,576.503906 
	C193.006714,576.503906 192.996368,576.997559 192.239655,577.008545 
M497.094360,255.742981 
	C497.069183,256.164246 497.043976,256.585541 496.291260,257.120453 
	C489.567291,266.252014 493.277100,283.342438 498.209106,290.836243 
	C502.792358,297.800110 508.571655,303.902679 517.932739,304.541779 
	C521.203918,304.765137 524.451294,305.495117 527.681213,306.136749 
	C545.238647,309.624695 558.333496,298.772675 563.443604,286.736237 
	C569.033630,273.569427 569.737854,259.713226 559.412170,246.826736 
	C550.451050,235.643158 539.299988,232.720337 525.246765,233.049652 
	C526.726135,233.872833 527.352051,234.221130 527.978027,234.569427 
	C513.394836,231.789139 505.050995,240.207764 497.444763,251.471970 
	C497.335846,252.684814 497.226898,253.897659 497.094360,255.742981 
M585.518372,749.408691 
	C585.518372,749.408691 585.588501,749.524048 585.518372,749.408691 
M360.588196,346.518677 
	C360.588196,346.518677 360.475006,346.586487 360.588196,346.518677 
M838.484863,816.585205 
	C838.484863,816.585205 838.419983,816.476074 838.484863,816.585205 
z"/>
<path fill="#8089A6" opacity="1.000000" stroke="none" 
	d="
M161.069855,830.855957 
	C162.911133,832.658325 164.918671,834.321289 166.565414,836.286621 
	C174.381973,845.615417 182.100174,855.026733 189.838638,864.758911 
	C189.417389,865.091248 189.010895,865.073303 188.173294,864.747803 
	C186.827271,863.626770 185.912384,862.813416 184.997467,862.000000 
	C182.861069,859.439331 180.724686,856.878662 178.253296,853.840210 
	C177.283264,852.908203 176.648209,852.453857 176.013153,851.999451 
	C176.013168,851.999451 176.000000,852.000000 175.960739,851.675598 
	C175.290161,850.897156 174.658829,850.443054 174.027496,849.988953 
	C174.027512,849.988953 174.000961,849.512268 173.991791,849.274109 
	C171.883102,846.389404 169.783585,843.742920 167.347244,841.045837 
	C165.751877,839.231140 164.493317,837.466919 162.995667,835.111572 
	C162.166153,834.017883 161.575745,833.515320 160.985336,833.012756 
	C160.985336,833.012756 160.999390,833.000000 160.998749,832.647705 
	C161.022018,831.815613 161.045944,831.335754 161.069855,830.855957 
z"/>
<path fill="#8089A6" opacity="1.000000" stroke="none" 
	d="
M205.895477,881.606079 
	C210.893204,885.981079 215.934494,890.644104 221.027237,895.648071 
	C220.618912,895.991943 220.159103,895.994873 219.355194,895.996155 
	C217.236877,894.347595 215.462677,892.700684 213.307251,890.687012 
	C212.299271,889.875793 211.672531,889.431396 211.045776,888.987000 
	C211.045776,888.987000 211.019547,888.984619 211.016998,888.968994 
	C209.863602,887.396423 208.712753,885.839417 207.253235,884.149780 
	C206.609375,883.309448 206.274200,882.601868 205.895477,881.606079 
z"/>
<path fill="#8089A6" opacity="1.000000" stroke="none" 
	d="
M189.939804,865.492798 
	C192.558273,867.667725 195.215820,870.163269 197.883255,873.035767 
	C197.392334,873.346191 196.891541,873.279846 196.065887,872.838684 
	C194.815018,871.646423 193.889038,870.828979 192.963058,870.011475 
	C191.968353,868.612183 190.973633,867.212830 189.939804,865.492798 
z"/>
<path fill="#8089A6" opacity="1.000000" stroke="none" 
	d="
M201.890472,877.726257 
	C202.799423,878.123718 203.773773,878.809631 204.665039,879.834473 
	C203.706589,879.453796 202.831223,878.734314 201.890472,877.726257 
z"/>
<path fill="#8B92A5" opacity="1.000000" stroke="none" 
	d="
M184.998398,862.369019 
	C185.912384,862.813416 186.827271,863.626770 187.868103,864.723755 
	C187.258286,864.994873 186.522552,864.982422 185.420776,864.957581 
	C185.036240,864.209473 185.017776,863.473694 184.998398,862.369019 
z"/>
<path fill="#8B92A5" opacity="1.000000" stroke="none" 
	d="
M192.979889,870.380920 
	C193.889038,870.828979 194.815018,871.646423 195.864166,872.753052 
	C194.990479,872.278259 193.993607,871.514282 192.979889,870.380920 
z"/>
<path fill="#8089A6" opacity="1.000000" stroke="none" 
	d="
M199.872742,875.641602 
	C200.226791,875.712891 200.635376,876.049927 201.046936,876.688110 
	C200.675705,876.628662 200.301483,876.268005 199.872742,875.641602 
z"/>
<path fill="#8089A6" opacity="1.000000" stroke="none" 
	d="
M289.196167,946.824585 
	C291.967896,948.169312 294.826477,949.362488 297.493164,950.890564 
	C302.893829,953.985229 308.190491,957.261475 313.649536,960.772827 
	C308.302216,958.523621 302.803436,956.029663 297.382355,953.376892 
	C294.760742,952.093933 292.284973,950.512878 289.385590,949.023193 
	C289.027985,948.979675 288.991425,948.513428 288.978149,948.031250 
	C288.964905,947.549072 288.927643,947.053711 288.995178,946.996887 
	C289.062683,946.940063 289.196167,946.824585 289.196167,946.824585 
z"/>
<path fill="#8089A6" opacity="1.000000" stroke="none" 
	d="
M273.963776,937.600342 
	C278.917938,940.249207 283.961578,943.213135 289.100677,946.500854 
	C289.196167,946.824585 289.062683,946.940063 288.711334,946.851685 
	C287.556213,946.529053 286.752411,946.294800 285.948639,946.060608 
	C281.983521,943.345520 278.018372,940.630493 273.963776,937.600342 
z"/>
<path fill="#8089A6" opacity="1.000000" stroke="none" 
	d="
M262.800232,929.456177 
	C266.410858,931.711304 270.008423,934.105469 273.661194,936.833679 
	C271.698853,936.144653 269.681274,935.121582 267.334717,934.058350 
	C266.216705,933.362305 265.427643,932.706360 264.315247,932.027466 
	C263.670532,931.912476 263.402802,931.738220 263.055603,930.971558 
	C262.882446,930.265747 262.842407,930.069885 262.802368,929.874084 
	C262.802368,929.874084 262.787109,929.595215 262.800232,929.456177 
z"/>
<path fill="#8B92A5" opacity="1.000000" stroke="none" 
	d="
M286.020081,946.394897 
	C286.752411,946.294800 287.556213,946.529053 288.643799,946.908447 
	C288.927643,947.053711 288.964905,947.549072 288.962463,947.797974 
	C288.003876,947.607605 287.047699,947.168396 286.020081,946.394897 
z"/>
<path fill="#8089A6" opacity="1.000000" stroke="none" 
	d="
M222.089233,897.163818 
	C223.821075,897.889343 225.841751,898.660889 227.448425,899.948853 
	C234.534393,905.629456 241.520279,911.435730 248.489288,917.260132 
	C249.477631,918.086182 250.173721,919.261963 251.047882,920.618408 
	C246.469727,921.807495 246.017197,916.330811 242.157257,914.901001 
	C240.812210,913.961853 239.904221,913.481567 238.996231,913.001282 
	C238.500595,912.424805 238.004959,911.848328 237.249298,911.144531 
	C235.569519,910.010437 234.149765,909.003784 232.379822,907.992004 
	C231.840881,907.449707 231.652115,906.912537 231.225067,906.211792 
	C229.879639,905.051392 228.772507,904.054565 227.335754,903.027100 
	C225.786652,901.851257 224.567154,900.705994 223.159241,899.309082 
	C222.709732,898.520813 222.448608,897.984131 222.089233,897.163818 
z"/>
<path fill="#8089A6" opacity="1.000000" stroke="none" 
	d="
M251.878937,921.624329 
	C255.222351,923.629822 258.590149,925.972656 261.986298,928.661743 
	C261.567047,929.017212 261.119415,929.026367 260.195923,928.726807 
	C258.818146,927.943237 257.916290,927.468323 257.014465,926.993408 
	C255.310745,925.316223 253.607056,923.638916 251.878937,921.624329 
z"/>
<path fill="#8B92A5" opacity="1.000000" stroke="none" 
	d="
M257.011353,927.324768 
	C257.916290,927.468323 258.818146,927.943237 259.860474,928.705994 
	C259.255402,928.992310 258.509857,928.990723 257.392212,928.984802 
	C257.016144,928.539001 257.012177,928.097595 257.011353,927.324768 
z"/>
<path fill="#8B92A5" opacity="1.000000" stroke="none" 
	d="
M238.998367,913.331543 
	C239.904221,913.481567 240.812210,913.961853 241.854767,914.737305 
	C241.246216,915.018127 240.503098,915.003723 239.388794,914.985840 
	C239.011917,914.542175 239.006210,914.102051 238.998367,913.331543 
z"/>
<path fill="#8B92A5" opacity="1.000000" stroke="none" 
	d="
M262.661652,930.011963 
	C262.842407,930.069885 262.882446,930.265747 262.933655,930.761963 
	C262.803497,930.758057 262.662231,930.453979 262.661652,930.011963 
z"/>
<path fill="#BAB9B8" opacity="1.000000" stroke="none" 
	d="
M568.975708,1024.949951 
	C566.598083,1024.643433 564.220520,1024.337036 560.966553,1024.017334 
	C552.372375,1023.981384 544.654541,1023.958679 537.051514,1023.550293 
	C538.842102,1022.782715 540.511536,1022.105225 542.194824,1022.069092 
	C550.481201,1021.890991 558.788696,1022.196228 567.055725,1021.737244 
	C577.732605,1021.144470 588.378296,1019.988953 599.030334,1019.403564 
	C599.016846,1020.169067 599.010498,1020.606750 599.004089,1021.044312 
	C598.267395,1021.045593 597.530640,1021.046814 596.045349,1021.032349 
	C594.188354,1021.658386 593.079956,1022.300049 591.971558,1022.941650 
	C590.911194,1022.954102 589.850891,1022.966553 588.160156,1022.709534 
	C586.018921,1022.634766 584.508179,1022.829468 582.997375,1023.024170 
	C581.942749,1023.028198 580.888123,1023.032227 578.984497,1023.021606 
	C575.123108,1023.847412 571.346802,1021.086365 568.975708,1024.949951 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M536.936768,1023.935974 
	C544.654541,1023.958679 552.372375,1023.981384 560.562439,1024.018066 
	C560.063232,1024.684326 559.098083,1025.892944 558.119263,1025.904053 
	C546.813171,1026.032715 535.504944,1025.999756 524.197754,1025.926514 
	C523.517273,1025.922119 522.841370,1025.210205 522.524109,1024.417114 
	C526.281128,1023.978882 529.677307,1023.950989 533.458923,1023.933228 
	C534.875122,1023.940857 535.905945,1023.938416 536.936768,1023.935974 
z"/>
<path fill="#BAB9B8" opacity="1.000000" stroke="none" 
	d="
M484.946808,1021.053223 
	C483.895569,1021.041077 482.844360,1021.029053 481.192657,1020.754272 
	C479.395721,1020.652588 478.199219,1020.813599 477.002747,1020.974609 
	C475.620422,1020.975647 474.238068,1020.976685 472.354248,1020.594116 
	C469.236267,1019.128601 466.619751,1018.046753 464.245422,1016.715149 
	C467.357971,1016.031311 470.228363,1015.597229 473.444916,1015.146240 
	C474.482422,1015.163635 475.173706,1015.197876 475.911255,1015.604126 
	C476.868744,1016.684082 477.681732,1017.750793 478.709778,1018.032471 
	C481.763275,1018.869019 484.901489,1019.396545 487.790985,1020.302673 
	C486.699219,1020.724670 485.823029,1020.888977 484.946808,1021.053223 
z"/>
<path fill="#BAB9B8" opacity="1.000000" stroke="none" 
	d="
M493.951691,1022.046631 
	C493.516937,1022.039307 493.082153,1022.031982 492.319824,1021.705200 
	C491.983429,1020.946533 491.974579,1020.507263 492.422882,1020.043640 
	C496.135376,1020.323120 499.401184,1020.938782 502.642639,1020.830017 
	C505.142883,1020.746033 507.612579,1019.752563 510.476685,1019.145874 
	C511.868988,1019.150879 512.880432,1019.173584 513.907227,1019.551270 
	C513.947510,1020.617554 513.972534,1021.328918 513.700806,1022.328735 
	C506.919891,1022.427002 500.435791,1022.236755 493.951691,1022.046631 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M494.050659,1022.425659 
	C500.435791,1022.236755 506.919891,1022.427002 514.031189,1022.317993 
	C515.098694,1022.025024 515.539062,1022.031250 515.983398,1022.369507 
	C515.993774,1023.144470 516.000061,1023.587585 516.006409,1024.030640 
	C508.586395,1026.019043 501.322174,1025.229126 494.050659,1022.425659 
z"/>
<path fill="#BAB9B8" opacity="1.000000" stroke="none" 
	d="
M516.432617,1024.026611 
	C516.000061,1023.587585 515.993774,1023.144470 516.453186,1022.351807 
	C521.004822,1022.005493 525.099915,1021.855042 529.171387,1022.099487 
	C530.503784,1022.179565 531.774597,1023.286011 533.073547,1023.923096 
	C529.677307,1023.950989 526.281128,1023.978882 522.420654,1024.024170 
	C520.257263,1024.035278 518.557983,1024.028809 516.432617,1024.026611 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M464.003235,1016.964966 
	C466.619751,1018.046753 469.236267,1019.128601 471.940125,1020.576782 
	C467.055542,1019.882080 462.083557,1018.820984 457.450928,1017.381714 
	C458.880951,1016.981750 459.971588,1016.959961 461.428589,1016.947510 
	C462.531036,1016.959534 463.267151,1016.962219 464.003235,1016.964966 
z"/>
<path fill="#BAB9B8" opacity="1.000000" stroke="none" 
	d="
M461.062256,1016.938110 
	C459.971588,1016.959961 458.880951,1016.981750 457.379211,1017.016479 
	C455.589844,1017.021240 454.211548,1017.013184 452.418457,1016.660278 
	C452.002533,1015.862183 452.001434,1015.408875 452.125671,1014.677734 
	C452.523102,1013.643188 452.795197,1012.886658 453.371063,1012.117798 
	C454.079620,1012.135315 454.484436,1012.165100 454.927979,1012.568665 
	C456.040588,1013.633057 457.118988,1014.316833 458.186981,1015.016357 
	C459.151276,1015.648010 460.104279,1016.296875 461.062256,1016.938110 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M569.426758,1024.970581 
	C571.346802,1021.086365 575.123108,1023.847412 578.588989,1023.023926 
	C575.987671,1023.690979 572.932739,1024.341064 569.426758,1024.970581 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M599.236450,1021.306641 
	C599.010498,1020.606750 599.016846,1020.169067 599.399048,1019.385071 
	C600.514771,1019.039124 601.254578,1019.039551 602.234497,1019.307129 
	C603.666138,1019.731934 604.857727,1019.889465 606.049377,1020.046997 
	C606.026306,1020.776062 606.003296,1021.505188 605.965393,1022.597534 
	C605.517090,1022.963135 605.083679,1022.965393 604.324585,1022.963440 
	C602.488953,1022.495789 600.978882,1022.032349 599.236450,1021.306641 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M491.965698,1020.067993 
	C491.974579,1020.507263 491.983429,1020.946533 491.993835,1021.716125 
	C489.708313,1021.958435 487.421265,1021.870422 485.040497,1021.417786 
	C485.823029,1020.888977 486.699219,1020.724670 488.187103,1020.291260 
	C489.854431,1020.037415 490.910065,1020.052734 491.965698,1020.067993 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M583.230835,1023.289368 
	C584.508179,1022.829468 586.018921,1022.634766 587.762451,1022.704285 
	C586.484863,1023.163940 584.974609,1023.359253 583.230835,1023.289368 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M477.207581,1021.240845 
	C478.199219,1020.813599 479.395721,1020.652588 480.798096,1020.757812 
	C479.806793,1021.184998 478.609619,1021.346069 477.207581,1021.240845 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M592.348511,1022.961548 
	C593.079956,1022.300049 594.188354,1021.658386 595.677002,1021.036743 
	C594.946594,1021.698303 593.836060,1022.339844 592.348511,1022.961548 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M452.000305,1014.955627 
	C452.001434,1015.408875 452.002533,1015.862183 452.003662,1016.655273 
	C451.033325,1016.592468 450.062958,1016.189880 449.026428,1015.455322 
	C448.960266,1015.123474 448.992981,1015.021973 449.379578,1014.985962 
	C450.510895,1014.951843 451.255615,1014.953735 452.000305,1014.955627 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M443.020020,1015.366394 
	C443.428741,1015.061035 443.886292,1015.044495 444.686798,1015.054138 
	C444.701477,1015.578857 444.373230,1016.077393 444.044983,1016.575989 
	C443.719604,1016.269104 443.394257,1015.962219 443.020020,1015.366394 
z"/>
<path fill="#8089A6" opacity="1.000000" stroke="none" 
	d="
M387.060669,993.438110 
	C393.026001,994.638550 399.068939,996.192505 405.111542,997.747864 
	C413.585205,999.928894 422.068237,1002.075195 430.521454,1004.332886 
	C431.739136,1004.658020 432.812988,1005.521851 433.751526,1006.589233 
	C433.319458,1007.115784 433.089294,1007.189514 432.859100,1007.263306 
	C432.156158,1007.227112 431.453247,1007.190918 430.216003,1006.897217 
	C424.121521,1005.418396 418.561371,1004.197083 413.001221,1002.975708 
	C405.206268,1000.342834 397.407013,997.722473 389.622406,995.059326 
	C388.751740,994.761475 387.964233,994.220520 387.060669,993.438110 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M432.902039,1007.625549 
	C433.089294,1007.189514 433.319458,1007.115784 433.893188,1006.920776 
	C434.622772,1006.808228 435.008789,1006.817017 435.861969,1007.159302 
	C441.908539,1009.038513 447.487915,1010.584351 453.067322,1012.130127 
	C452.795197,1012.886658 452.523102,1013.643188 452.125671,1014.677734 
	C451.255615,1014.953735 450.510895,1014.951843 449.394653,1014.934937 
	C445.333466,1013.626587 441.595215,1012.453308 437.974701,1010.989014 
	C436.186127,1010.265564 434.614685,1009.005188 432.902039,1007.625549 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M413.000397,1003.251343 
	C418.561371,1004.197083 424.121521,1005.418396 429.864502,1006.927490 
	C429.093079,1007.673767 428.164795,1008.441467 427.179047,1008.524231 
	C425.319336,1008.680359 423.426453,1008.441956 421.285400,1008.635620 
	C419.852051,1008.766052 418.680664,1008.628235 417.288940,1008.214966 
	C415.840607,1006.825134 414.612610,1005.710876 413.190399,1004.337280 
	C412.997345,1003.894287 412.998474,1003.710571 413.000397,1003.251343 
z"/>
<path fill="#BAB9B8" opacity="1.000000" stroke="none" 
	d="
M606.416992,1020.041199 
	C604.857727,1019.889465 603.666138,1019.731934 602.451782,1019.034180 
	C603.633484,1017.678650 604.837952,1016.863342 606.235718,1015.795288 
	C607.255310,1015.409790 608.081604,1015.277039 609.017578,1015.504761 
	C611.077454,1016.080383 613.026184,1016.310059 614.978210,1016.506165 
	C616.648682,1016.674072 618.323059,1016.803467 619.803833,1017.216675 
	C618.730652,1018.301331 617.849304,1019.119202 616.967957,1019.937012 
	C616.224487,1019.949768 615.481079,1019.962463 614.132690,1019.704834 
	C612.016907,1019.634766 610.506042,1019.835022 608.995117,1020.035339 
	C608.258301,1020.035400 607.521423,1020.035400 606.416992,1020.041199 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M617.368164,1019.912476 
	C617.849304,1019.119202 618.730652,1018.301331 620.127563,1017.220703 
	C621.073730,1016.950623 621.504272,1016.943237 621.934814,1016.935913 
	C622.703674,1016.958008 623.472473,1016.980103 624.626221,1017.008179 
	C625.007202,1017.453308 625.003357,1017.892517 624.999756,1018.660889 
	C622.589478,1019.289429 620.178955,1019.588684 617.368164,1019.912476 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M609.230225,1020.298828 
	C610.506042,1019.835022 612.016907,1019.634766 613.760925,1019.698486 
	C612.484497,1020.162354 610.974915,1020.362366 609.230225,1020.298828 
z"/>
<path fill="#B0B1B4" opacity="1.000000" stroke="none" 
	d="
M1011.081665,445.934692 
	C1011.059509,445.509613 1011.037415,445.084503 1011.288086,444.082001 
	C1011.395142,441.583984 1011.403259,439.629120 1011.032166,437.749084 
	C1009.789246,431.452911 1008.410828,425.183472 1007.084290,418.903809 
	C1008.302368,420.535767 1010.231262,422.017181 1010.614441,423.826019 
	C1012.204651,431.331696 1013.328186,438.936249 1014.057373,446.646179 
	C1012.688416,446.503448 1011.885071,446.219055 1011.081665,445.934692 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M1007.082336,418.586151 
	C1008.410828,425.183472 1009.789246,431.452911 1011.032166,437.749084 
	C1011.403259,439.629120 1011.395142,441.583984 1011.281616,443.762817 
	C1008.991455,435.302582 1006.980469,426.584167 1005.324097,417.431335 
	C1006.145874,416.996368 1006.613098,416.995758 1007.080261,416.995178 
	C1007.080322,417.419617 1007.080322,417.844055 1007.082336,418.586151 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M1011.052612,446.368286 
	C1011.885071,446.219055 1012.688416,446.503448 1013.796204,446.909485 
	C1014.101807,447.741760 1014.103027,448.452393 1014.069458,449.946564 
	C1014.993164,453.837616 1015.951660,456.945129 1016.924438,460.464172 
	C1016.948669,462.248566 1016.958679,463.621338 1016.684631,465.005066 
	C1016.211487,465.032745 1016.022461,465.049530 1015.833496,465.066284 
	C1015.389709,464.868378 1014.609680,464.740601 1014.551636,464.462311 
	C1013.326721,458.585724 1012.183044,452.692169 1011.052612,446.368286 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M1003.059570,400.950867 
	C1004.699524,405.034485 1006.339539,409.118103 1007.595825,413.609497 
	C1006.467285,414.018890 1005.722534,414.020538 1004.977722,414.022186 
	C1004.977722,414.022186 1004.956909,414.049957 1004.972656,413.642334 
	C1004.326416,410.154327 1003.664429,407.073944 1003.005310,403.613708 
	C1003.025330,402.472839 1003.042419,401.711853 1003.059570,400.950867 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M1016.968689,464.994141 
	C1016.958679,463.621338 1016.948669,462.248566 1016.954712,460.117401 
	C1016.991028,458.896149 1017.011353,458.433258 1017.031616,457.970367 
	C1017.882202,461.808411 1018.732788,465.646423 1018.974854,469.740112 
	C1017.926208,469.986816 1017.486145,469.977844 1017.036865,469.640076 
	C1017.025757,468.872528 1017.023743,468.433746 1017.269409,467.809448 
	C1017.334229,466.747345 1017.151428,465.870758 1016.968689,464.994141 
z"/>
<path fill="#B0B1B4" opacity="1.000000" stroke="none" 
	d="
M1017.020203,457.548828 
	C1017.011353,458.433258 1016.991028,458.896149 1016.940430,459.705811 
	C1015.951660,456.945129 1014.993164,453.837616 1014.077393,450.302429 
	C1018.450073,450.951141 1016.493164,454.531616 1017.020203,457.548828 
z"/>
<path fill="#B0B1B4" opacity="1.000000" stroke="none" 
	d="
M1017.046021,469.968842 
	C1017.486145,469.977844 1017.926208,469.986816 1018.697021,469.998962 
	C1019.030151,470.740509 1019.032654,471.478912 1019.026550,472.958313 
	C1019.353638,474.800049 1019.689392,475.900757 1020.025146,477.001465 
	C1020.024597,477.439240 1020.024048,477.877014 1020.027771,478.642517 
	C1019.856506,479.532532 1019.680908,480.094788 1018.926514,480.825256 
	C1017.902771,480.986786 1017.457825,480.980072 1017.012817,480.973328 
	C1017.002625,480.539398 1016.992493,480.105469 1017.236938,479.158569 
	C1017.305908,477.755096 1017.120300,476.864624 1016.934692,475.974152 
	C1016.935120,475.255341 1016.935608,474.536499 1016.957886,473.066040 
	C1017.001770,471.532532 1017.023926,470.750702 1017.046021,469.968842 
z"/>
<path fill="#B0B1B4" opacity="1.000000" stroke="none" 
	d="
M1003.045776,400.582642 
	C1003.042419,401.711853 1003.025330,402.472839 1002.742371,403.507996 
	C1001.974731,403.519989 1001.472900,403.257812 1000.971069,402.995636 
	C1000.966125,402.568024 1000.961121,402.140442 1000.973633,401.047089 
	C1000.992493,399.922516 1000.993835,399.463715 1000.996582,398.668762 
	C1001.001892,397.884338 1001.005798,397.436005 1001.009766,396.987701 
	C1001.499390,397.256805 1001.988953,397.525879 1002.739258,397.896912 
	C1003.010681,398.737366 1003.021301,399.475891 1003.045776,400.582642 
z"/>
<path fill="#B0B1B4" opacity="1.000000" stroke="none" 
	d="
M1004.964355,414.394043 
	C1005.722534,414.020538 1006.467285,414.018890 1007.582886,414.026367 
	C1007.830139,414.884918 1007.706665,415.734375 1007.331665,416.789490 
	C1006.613098,416.995758 1006.145874,416.996368 1005.328613,416.998413 
	C1004.969299,416.255249 1004.960144,415.510559 1004.964355,414.394043 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M1020.286377,476.778870 
	C1019.689392,475.900757 1019.353638,474.800049 1019.036255,473.327454 
	C1019.552307,474.155823 1020.049988,475.356049 1020.286377,476.778870 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M1000.963623,396.605164 
	C1001.005798,397.436005 1001.001892,397.884338 1000.666260,398.666046 
	C999.894287,398.998383 999.454041,398.997314 999.013672,398.996277 
	C998.429749,397.448364 997.691345,395.939850 997.285400,394.346588 
	C992.627197,376.066895 984.379883,359.153412 977.155090,341.868378 
	C972.784668,331.412598 968.103210,321.013458 962.600830,311.123322 
	C956.110046,299.456635 948.622559,288.341370 941.484619,277.039246 
	C938.429749,272.202393 935.166199,267.497375 932.372925,262.365845 
	C933.497131,262.000244 934.245789,262.001251 934.994507,262.002258 
	C936.286743,263.453522 937.578918,264.904816 938.937256,267.031799 
	C939.655701,268.493011 940.308044,269.278534 940.960327,270.064087 
	C942.108643,271.595612 943.256958,273.127167 944.658081,274.851654 
	C946.023804,277.011627 947.136719,278.978638 948.606567,280.991608 
	C949.238281,281.519684 949.513062,282.001862 949.896240,283.087677 
	C950.672241,284.791138 951.339783,285.890930 952.007324,286.990723 
	C952.330017,287.454224 952.652710,287.917725 953.033203,288.998352 
	C953.712524,290.091888 954.334106,290.568298 954.955627,291.044678 
	C954.955627,291.044678 954.984741,291.513916 955.004272,291.747986 
	C957.436584,296.294464 959.849304,300.606812 962.600098,304.988708 
	C964.713135,308.684967 966.487976,312.311615 968.614197,315.986725 
	C968.965515,316.035156 968.987976,316.513031 969.000061,316.751831 
	C969.293091,318.421844 969.305664,319.976166 969.897034,321.264923 
	C974.982605,332.348114 980.593567,343.210327 985.223694,354.477142 
	C990.871826,368.221008 995.722168,382.292694 1000.963623,396.605164 
z"/>
<path fill="#B0B1B4" opacity="1.000000" stroke="none" 
	d="
M1022.986938,535.004456 
	C1023.420837,535.010498 1023.854797,535.016541 1024.612549,535.033630 
	C1024.943115,540.705017 1024.949707,546.365356 1024.622192,552.501221 
	C1023.851685,552.982117 1023.415222,552.987549 1022.688599,552.696411 
	C1022.594604,546.601318 1022.790710,540.802917 1022.986938,535.004456 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M1022.978821,552.993042 
	C1023.415222,552.987549 1023.851685,552.982117 1024.614014,552.966248 
	C1024.819214,556.778931 1024.698608,560.602051 1023.954102,564.713745 
	C1022.892517,564.999451 1022.454834,564.996582 1022.014648,564.560303 
	C1022.334351,560.415588 1022.656555,556.704285 1022.978821,552.993042 
z"/>
<path fill="#B0B1B4" opacity="1.000000" stroke="none" 
	d="
M1022.017212,564.993713 
	C1022.454834,564.996582 1022.892517,564.999451 1023.657837,565.001099 
	C1023.987549,566.063599 1023.989624,567.127197 1023.988159,568.589111 
	C1023.261597,568.997986 1022.538513,569.008667 1021.423462,568.668030 
	C1021.359985,567.209045 1021.688599,566.101379 1022.017212,564.993713 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M1022.014648,564.560303 
	C1021.688599,566.101379 1021.359985,567.209045 1021.062134,568.689941 
	C1021.066040,572.735229 1021.039307,576.407288 1020.681152,580.541321 
	C1019.912476,581.011047 1019.475037,581.018799 1019.037659,581.026550 
	C1019.033264,554.981628 1019.028870,528.936646 1019.373657,502.433655 
	C1020.159363,501.981506 1020.595886,501.987427 1021.032471,501.993317 
	C1021.042725,504.349030 1021.052979,506.704742 1021.047241,509.936462 
	C1021.363953,513.207397 1021.696655,515.602356 1022.029419,517.997253 
	C1022.350281,523.363647 1022.671082,528.729980 1022.989380,534.550415 
	C1022.790710,540.802917 1022.594604,546.601318 1022.688599,552.696411 
	C1022.656555,556.704285 1022.334351,560.415588 1022.014648,564.560303 
z"/>
<path fill="#B0B1B4" opacity="1.000000" stroke="none" 
	d="
M1021.278992,501.808105 
	C1020.595886,501.987427 1020.159363,501.981506 1019.397217,501.963196 
	C1019.034668,497.275726 1018.997681,492.600586 1018.983276,487.458984 
	C1020.072327,488.616089 1021.138611,490.239655 1022.554077,491.935913 
	C1022.928955,494.049377 1022.954773,496.090179 1022.973206,498.569061 
	C1022.485779,499.879028 1022.005615,500.750977 1021.278992,501.808105 
z"/>
<path fill="#B0B1B4" opacity="1.000000" stroke="none" 
	d="
M1022.309570,517.724121 
	C1021.696655,515.602356 1021.363953,513.207397 1021.060425,510.376892 
	C1021.589722,512.444519 1022.089783,514.947754 1022.309570,517.724121 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M952.008179,286.615967 
	C951.339783,285.890930 950.672241,284.791138 950.011414,283.340820 
	C950.681763,284.073914 951.345398,285.157532 952.008179,286.615967 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M954.934326,290.720337 
	C954.334106,290.568298 953.712524,290.091888 953.070190,289.291382 
	C953.670654,289.443512 954.291870,289.919739 954.934326,290.720337 
z"/>
<path fill="#B0B1B4" opacity="1.000000" stroke="none" 
	d="
M934.996582,261.627258 
	C934.245789,262.001251 933.497131,262.000244 932.372986,261.999298 
	C931.997559,261.999390 931.991516,261.549896 931.988220,260.976562 
	C931.986694,259.938934 931.988464,259.474670 931.996948,259.003296 
	C932.003601,258.996185 932.019531,258.979340 932.019531,258.979340 
	C932.762695,258.987274 933.505859,258.995209 934.619507,259.006653 
	C934.992798,259.757477 934.995728,260.504822 934.996582,261.627258 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M940.969116,269.702881 
	C940.308044,269.278534 939.655701,268.493011 938.994873,267.355164 
	C939.650146,267.782440 940.314087,268.562103 940.969116,269.702881 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M1022.972961,584.979614 
	C1022.586365,587.270630 1022.199829,589.561707 1021.315918,591.723572 
	C1020.553223,591.395447 1020.287842,591.196533 1020.019165,590.569885 
	C1020.044556,588.432129 1020.073303,586.722046 1020.461487,585.009644 
	C1021.538269,584.998047 1022.255615,584.988831 1022.972961,584.979614 
z"/>
<path fill="#B0B1B4" opacity="1.000000" stroke="none" 
	d="
M1022.978516,584.582581 
	C1022.255615,584.988831 1021.538269,584.998047 1020.189087,584.998596 
	C1019.376282,584.984192 1019.195435,584.978577 1019.014648,584.972961 
	C1019.014648,583.919495 1019.014587,582.866089 1019.026123,581.419556 
	C1019.475037,581.018799 1019.912476,581.011047 1020.678955,581.001587 
	C1021.446228,581.001221 1021.884338,581.002502 1022.650513,581.001831 
	C1022.980347,582.061829 1022.982239,583.123718 1022.978516,584.582581 
z"/>
<path fill="#B0B1B4" opacity="1.000000" stroke="none" 
	d="
M1020.022400,590.997559 
	C1020.287842,591.196533 1020.553223,591.395447 1020.939087,591.817627 
	C1021.046143,593.418396 1021.032837,594.795959 1020.693726,596.590210 
	C1019.934387,597.020020 1019.500916,597.033020 1019.044800,596.633179 
	C1018.994568,595.116516 1018.966797,594.012695 1018.939087,592.908813 
	C1018.938049,592.489868 1018.937073,592.070923 1019.206421,591.329590 
	C1019.658691,591.003967 1019.840576,591.000793 1020.022400,590.997559 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M1019.067383,597.046021 
	C1019.500916,597.033020 1019.934387,597.020020 1020.693481,597.004517 
	C1021.324219,597.495789 1021.629395,597.989502 1021.921021,598.794067 
	C1021.098755,599.153259 1020.289917,599.201599 1019.258911,598.787964 
	C1019.046936,597.899353 1019.057190,597.472717 1019.067383,597.046021 
z"/>
<path fill="#B0B1B4" opacity="1.000000" stroke="none" 
	d="
M920.960327,241.031189 
	C921.386902,241.049652 921.813477,241.068085 922.556885,241.106720 
	C922.895569,241.549316 922.917542,241.971725 922.959106,243.059067 
	C923.654846,244.813751 924.330872,245.903503 925.006958,246.993256 
	C925.006958,246.993240 925.000488,246.999710 925.004517,246.996429 
	C927.317627,250.754929 929.626648,254.516708 931.977600,258.628906 
	C932.019531,258.979340 932.003601,258.996185 931.653442,259.000916 
	C930.844421,259.015472 930.385437,259.025360 929.926514,259.035217 
	C923.038513,250.207077 916.389465,241.178268 909.192444,232.609711 
	C902.555298,224.707809 895.464417,217.171326 888.325745,209.710815 
	C881.138611,202.199692 873.646545,194.980392 866.165405,187.055267 
	C866.063599,186.290024 866.084473,186.102707 866.492615,185.935745 
	C867.920776,185.969528 868.961609,185.982925 870.002441,185.996338 
	C870.544434,186.189789 871.086487,186.383255 871.834961,187.159378 
	C877.012817,192.506592 881.984253,197.271118 886.955688,202.035645 
	C887.454224,202.615112 887.952820,203.194580 888.749268,204.246246 
	C891.017212,206.490555 892.987183,208.262680 894.957214,210.034790 
	C895.616882,210.807541 896.276489,211.580322 896.999023,213.017288 
	C899.028870,215.464020 900.995911,217.246567 902.963013,219.029099 
	C903.550842,219.523590 904.138611,220.018097 904.840332,220.784561 
	C906.102173,223.331924 906.881287,225.917328 908.477356,227.816055 
	C911.492371,231.402954 914.977112,234.595001 918.607422,237.993576 
	C918.948303,238.036011 918.971741,238.025208 918.976562,238.381653 
	C919.641113,239.502472 920.300720,240.266830 920.960327,241.031189 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M1016.893433,481.269348 
	C1017.457825,480.980072 1017.902771,480.986786 1018.681885,480.997375 
	C1019.010193,482.406128 1019.004272,483.811005 1018.964844,485.638794 
	C1018.212219,484.562897 1017.493103,483.064117 1016.893433,481.269348 
z"/>
<path fill="#B0B1B4" opacity="1.000000" stroke="none" 
	d="
M1015.923279,615.921631 
	C1015.923889,615.499939 1015.924500,615.078186 1016.219788,614.086060 
	C1017.338684,611.341553 1018.162964,609.167358 1018.987183,606.993164 
	C1019.003723,607.430481 1019.020264,607.867798 1019.044983,608.632507 
	C1018.543518,611.481079 1018.033875,614.002197 1017.014038,616.637451 
	C1016.310364,616.474915 1016.116821,616.198303 1015.923279,615.921631 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M1015.573425,616.010803 
	C1016.116821,616.198303 1016.310364,616.474915 1016.726257,616.872925 
	C1016.949219,617.732361 1016.949829,618.470398 1016.932251,619.575439 
	C1016.194153,619.964905 1015.474304,619.987305 1014.393860,620.014099 
	C1014.430054,618.712280 1014.826843,617.406128 1015.573425,616.010803 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M925.006958,246.644012 
	C924.330872,245.903503 923.654846,244.813751 922.972900,243.377441 
	C923.646973,244.118851 924.326965,245.206818 925.006958,246.644012 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M1019.002197,606.751709 
	C1018.162964,609.167358 1017.338684,611.341553 1016.217285,613.769775 
	C1016.088013,610.847351 1016.255859,607.670776 1016.698242,604.239014 
	C1017.417053,603.989502 1017.861267,603.995361 1018.637817,604.001221 
	C1018.980530,604.451904 1018.990906,604.902466 1019.029480,605.690552 
	C1019.057678,606.027954 1019.017151,606.510193 1019.002197,606.751709 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M1019.044861,596.633240 
	C1019.057190,597.472717 1019.046936,597.899353 1019.042725,598.645752 
	C1018.584595,599.619019 1018.120361,600.272583 1017.333008,600.972229 
	C1017.402222,598.389465 1017.794617,595.760559 1018.562988,593.020264 
	C1018.966797,594.012695 1018.994568,595.116516 1019.044861,596.633240 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M823.498413,148.099854 
	C825.817993,150.214935 827.767395,152.293411 829.864868,155.014038 
	C830.003418,156.118240 829.993835,156.580322 829.984253,157.042389 
	C825.230957,154.084732 820.424988,151.207947 815.734680,148.153519 
	C803.958984,140.484863 792.711792,131.849075 780.379272,125.227776 
	C762.056824,115.390457 742.993042,106.931396 724.220642,97.936073 
	C716.932861,94.443886 709.579346,91.088600 702.082397,87.370628 
	C704.366699,87.686157 706.824280,88.302017 709.660461,88.941757 
	C710.769958,88.984848 711.500977,89.004066 712.658447,89.376617 
	C714.419678,90.454643 715.754272,91.179337 717.088928,91.904037 
	C718.120117,91.949272 719.151367,91.994507 720.635803,92.397644 
	C725.726868,94.836014 730.364624,96.916473 735.211060,99.256577 
	C736.282593,99.670624 737.145447,99.825027 738.008301,99.979431 
	C738.181580,99.991051 738.354797,100.002663 738.987183,100.025696 
	C739.446350,100.037109 739.845215,100.028633 739.845215,100.028633 
	C741.428467,100.842079 743.011719,101.655533 744.847412,103.083145 
	C746.066589,104.131241 747.033325,104.565163 748.000000,104.999069 
	C760.952820,111.836563 774.138550,118.273567 786.788147,125.632217 
	C797.859314,132.072678 808.311646,139.574249 819.085876,146.531815 
	C820.269470,147.296127 821.773010,147.564758 823.498413,148.099854 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M383.351013,86.977814 
	C383.975891,86.012970 384.854645,84.606560 386.066498,84.204811 
	C394.657654,81.356606 403.282135,78.595360 411.966858,76.047897 
	C417.778687,74.343132 423.706970,73.035210 429.798340,71.245361 
	C430.738495,70.938614 431.463440,70.936203 432.547913,70.900772 
	C442.970367,69.066711 452.996552,67.013504 463.107086,65.538216 
	C472.503632,64.167107 481.990051,63.412235 491.729736,62.668392 
	C485.909363,63.969433 479.786743,64.935028 473.684967,66.018150 
	C461.837830,68.121094 449.900604,69.847832 438.187897,72.543709 
	C423.583405,75.905182 409.164642,80.075554 394.673218,83.924889 
	C391.020508,84.895142 387.396362,85.972908 383.351013,86.977814 
z"/>
<path fill="#BAB9B8" opacity="1.000000" stroke="none" 
	d="
M504.014832,59.061317 
	C504.011078,58.873531 504.007355,58.685749 503.999969,58.215450 
	C505.377350,57.939922 506.758331,57.946911 508.551605,57.929913 
	C522.712646,57.603851 536.461914,57.060547 550.210022,57.086983 
	C558.950806,57.103790 567.689941,57.966728 576.409668,59.045811 
	C571.919800,60.081589 567.450134,60.521797 562.980408,60.962006 
	C562.545898,60.960835 562.111328,60.959660 561.090454,60.666977 
	C550.540833,59.922958 540.578491,59.442242 530.612244,59.067795 
	C529.750977,59.035431 528.863220,59.705685 527.987793,60.049561 
	C524.939880,60.348930 521.892029,60.648300 518.133789,60.662018 
	C512.953857,59.938019 508.484344,59.499668 504.014832,59.061317 
z"/>
<path fill="#B0B1B4" opacity="1.000000" stroke="none" 
	d="
M830.139893,157.340683 
	C829.993835,156.580322 830.003418,156.118240 830.026123,155.309479 
	C833.448425,156.957321 836.857666,158.951859 840.612671,160.994919 
	C842.356445,162.581772 843.609863,164.289566 845.178711,165.627625 
	C850.484924,170.153122 855.903076,174.547562 861.639160,178.995529 
	C862.000000,178.998932 862.000000,179.000000 862.119690,179.297852 
	C862.492065,180.398453 862.744812,181.201187 862.618042,182.003937 
	C861.478333,182.009354 860.718201,182.014740 859.958008,182.020111 
	C850.070496,173.893066 840.182983,165.766022 830.139893,157.340683 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M336.191162,106.261894 
	C339.454102,103.948814 342.751282,101.494301 346.422272,99.901054 
	C356.005493,95.741806 365.738770,91.925583 375.448151,88.062790 
	C376.948425,87.465889 378.638123,87.344971 380.646790,87.019333 
	C379.982788,87.893677 379.037598,89.043938 377.822388,89.566833 
	C364.511505,95.294563 351.160553,100.929268 337.806854,106.556961 
	C337.412689,106.723083 336.869476,106.535561 336.191162,106.261894 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M613.017090,65.453430 
	C618.667297,65.713913 624.426025,66.347221 630.627319,66.944901 
	C631.494568,66.919456 631.919373,66.929649 632.662964,66.931519 
	C637.828125,68.274429 642.654663,69.703659 647.527649,70.951073 
	C651.126099,71.872200 654.802734,72.488075 658.400635,73.411026 
	C659.022278,73.570480 659.424683,74.584328 659.993896,75.575516 
	C644.924438,72.937599 629.787842,69.935814 614.660889,66.885872 
	C614.100769,66.772919 613.635315,66.190102 613.017090,65.453430 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M528.287292,60.340561 
	C528.863220,59.705685 529.750977,59.035431 530.612244,59.067795 
	C540.578491,59.442242 550.540833,59.922958 560.764771,60.654930 
	C558.351562,60.963570 555.676941,61.045788 553.003845,61.012028 
	C544.864502,60.909237 536.725769,60.761692 528.287292,60.340561 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M563.264954,61.265472 
	C567.450134,60.521797 571.919800,60.081589 576.684937,59.359314 
	C577.721741,59.069134 578.463074,59.061024 579.573120,59.067223 
	C581.087646,59.719532 582.180786,60.494465 583.392761,60.960037 
	C584.828918,61.511696 586.367432,61.796707 587.965027,62.568932 
	C579.895508,62.483372 571.722473,62.026154 563.264954,61.265472 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M503.617828,59.050480 
	C508.484344,59.499668 512.953857,59.938019 517.723999,60.661896 
	C510.295898,61.631943 502.567139,62.316463 494.384827,62.970596 
	C495.911011,61.890625 497.890778,60.841038 499.963196,59.435051 
	C501.110870,59.065647 502.165863,59.052647 503.617828,59.050480 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M590.250793,63.252876 
	C596.266052,63.385181 602.555359,63.819820 608.932739,64.651398 
	C607.039062,65.048630 605.042908,65.202316 603.078491,65.017525 
	C598.885193,64.623070 594.708374,64.053207 590.250793,63.252876 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M682.996887,81.423706 
	C688.817810,82.876152 694.715820,84.679596 700.817139,86.784042 
	C695.038269,85.314934 689.056091,83.544823 682.996887,81.423706 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M666.970276,76.462494 
	C671.491516,77.529236 676.086365,78.953400 680.848022,80.703705 
	C676.357788,79.626534 671.700867,78.223221 666.970276,76.462494 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M1000.969299,403.408722 
	C1001.472900,403.257812 1001.974731,403.519989 1002.739502,403.887878 
	C1003.664429,407.073944 1004.326416,410.154327 1004.955322,413.643799 
	C1000.470642,411.853760 1002.270874,407.237946 1000.969299,403.408722 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M315.427246,116.038254 
	C318.476654,114.056213 321.915955,112.073860 325.729065,110.094467 
	C322.674286,112.077599 319.245728,114.057770 315.427246,116.038254 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M264.292542,147.946686 
	C265.741821,146.327164 267.552643,144.705307 269.722290,143.080170 
	C268.272095,144.699387 266.463074,146.321869 264.292542,147.946686 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M284.331757,134.973541 
	C285.437836,133.690002 286.899536,132.382568 288.724121,131.070908 
	C287.620483,132.360992 286.153931,133.655319 284.331757,134.973541 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M1017.036926,469.640076 
	C1017.023926,470.750702 1017.001770,471.532532 1016.951965,472.706665 
	C1016.412109,471.552795 1015.899902,470.006683 1015.922241,468.221008 
	C1016.645081,467.985931 1016.833435,467.990448 1017.021729,467.994934 
	C1017.023743,468.433746 1017.025757,468.872528 1017.036926,469.640076 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M1020.019165,590.569885 
	C1019.840576,591.000793 1019.658691,591.003967 1019.203491,591.015381 
	C1018.943542,589.254333 1018.956970,587.485046 1018.992554,585.344360 
	C1019.195435,584.978577 1019.376282,584.984192 1019.829590,585.000916 
	C1020.073303,586.722046 1020.044556,588.432129 1020.019165,590.569885 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M860.104858,182.313553 
	C860.718201,182.014740 861.478333,182.009354 862.732422,182.218353 
	C863.428772,182.699890 863.689087,182.886215 864.004333,183.325012 
	C864.004150,184.102524 864.006958,184.546738 863.989624,185.010803 
	C863.969543,185.030670 863.929688,185.070801 863.929688,185.070801 
	C862.703674,184.249527 861.477661,183.428253 860.104858,182.313553 
z"/>
<path fill="#8B92A5" opacity="1.000000" stroke="none" 
	d="
M161.043030,830.637939 
	C161.045944,831.335754 161.022018,831.815613 160.990448,832.655151 
	C160.316132,832.253601 159.649445,831.492249 158.966705,830.380798 
	C159.366058,830.028564 159.781464,830.026428 160.505585,830.026855 
	C160.814316,830.029541 161.016174,830.419861 161.043030,830.637939 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M662.162842,76.226662 
	C662.883179,75.833595 663.780945,75.696518 664.847290,75.831360 
	C664.124023,76.229736 663.232178,76.356194 662.162842,76.226662 
z"/>
<path fill="#B0B1B4" opacity="1.000000" stroke="none" 
	d="
M1017.269409,467.809448 
	C1016.833435,467.990448 1016.645081,467.985931 1016.173706,467.961121 
	C1015.897217,467.221039 1015.903809,466.501251 1015.871948,465.423889 
	C1016.022461,465.049530 1016.211487,465.032745 1016.684631,465.005066 
	C1017.151428,465.870758 1017.334229,466.747345 1017.269409,467.809448 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M1016.696289,476.169189 
	C1017.120300,476.864624 1017.305908,477.755096 1017.235840,478.833008 
	C1016.806030,478.135071 1016.631958,477.249664 1016.696289,476.169189 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M1008.598267,650.969604 
	C1008.764343,651.806335 1008.617004,652.715454 1008.184570,653.833862 
	C1008.027893,653.042664 1008.156311,652.042358 1008.598267,650.969604 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M929.982544,259.354218 
	C930.385437,259.025360 930.844421,259.015472 931.646790,259.007996 
	C931.988464,259.474670 931.986694,259.938934 931.965088,260.752991 
	C931.309692,260.626221 930.674133,260.149719 929.982544,259.354218 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M151.324799,258.991760 
	C151.443710,258.375732 151.888718,257.721497 152.657715,257.030151 
	C152.538101,257.646545 152.094498,258.300049 151.324799,258.991760 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M866.105286,185.915375 
	C866.084473,186.102707 866.063599,186.290024 866.010864,186.758759 
	C865.334045,186.593079 864.689209,186.145996 863.987000,185.384857 
	C863.929688,185.070801 863.969543,185.030670 864.324707,185.012115 
	C865.127075,184.987686 865.574219,184.981812 866.038696,185.211121 
	C866.055908,185.446320 866.105286,185.915375 866.105286,185.915375 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M999.048706,399.316345 
	C999.454041,398.997314 999.894287,398.998383 1000.664917,399.002167 
	C1000.993835,399.463715 1000.992493,399.922516 1000.972290,400.726379 
	C1000.330200,400.593079 999.706909,400.114777 999.048706,399.316345 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M991.711975,707.054077 
	C991.888123,707.278809 991.731873,707.562378 991.229370,707.890747 
	C991.081726,707.674866 991.247192,707.400635 991.711975,707.054077 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M1010.778931,638.087524 
	C1010.919067,638.307495 1010.745117,638.577026 1010.257629,638.895630 
	C1010.116882,638.674988 1010.290527,638.405823 1010.778931,638.087524 
z"/>
<path fill="#8B92A5" opacity="1.000000" stroke="none" 
	d="
M793.054565,946.328491 
	C793.781372,945.882629 794.508179,945.436768 795.896118,944.962158 
	C797.045410,944.354858 797.533630,943.776306 798.021851,943.197754 
	C798.021790,943.197693 798.427368,943.003845 799.021118,942.967529 
	C803.720581,940.945496 807.826355,938.959656 811.932129,936.973877 
	C811.932068,936.973816 811.940186,937.456360 811.933105,937.696899 
	C801.111267,944.625671 790.302979,951.324585 779.468384,957.980652 
	C778.659485,958.477661 777.662720,958.668823 776.366089,958.990601 
	C775.978882,958.979065 775.949463,958.949280 775.967346,958.635620 
	C776.010071,957.703186 776.034912,957.084412 776.059692,956.465637 
	C776.225769,956.376648 776.391907,956.287659 777.194641,956.105042 
	C782.905762,952.783752 787.980164,949.556152 793.054565,946.328491 
z"/>
<path fill="#8089A6" opacity="1.000000" stroke="none" 
	d="
M812.163696,936.868774 
	C807.826355,938.959656 803.720581,940.945496 799.243530,942.935547 
	C803.364014,939.695007 807.855835,936.450134 812.684143,933.559814 
	C813.006409,934.592896 812.992249,935.271423 812.978088,935.949951 
	C812.783752,936.221191 812.589478,936.492371 812.163696,936.868774 
z"/>
<path fill="#8B92A5" opacity="1.000000" stroke="none" 
	d="
M813.318726,935.928833 
	C812.992249,935.271423 813.006409,934.592896 813.028442,933.578979 
	C813.036194,933.243652 813.439819,933.006531 814.025635,932.999878 
	C815.083923,933.001038 815.556335,933.008850 816.028748,933.016663 
	C815.238953,933.980347 814.449097,934.943970 813.318726,935.928833 
z"/>
<path fill="#8B92A5" opacity="1.000000" stroke="none" 
	d="
M817.326782,930.998779 
	C817.498413,930.393677 817.979248,929.780396 818.785767,929.230042 
	C818.619690,929.858826 818.127869,930.424744 817.326782,930.998779 
z"/>
<path fill="#8089A6" opacity="1.000000" stroke="none" 
	d="
M816.050720,932.745361 
	C815.556335,933.008850 815.083923,933.001038 814.257324,932.966431 
	C814.445190,932.369080 814.987183,931.798462 815.847412,931.313110 
	C816.134705,931.756958 816.103699,932.115479 816.050720,932.745361 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M886.937744,201.687073 
	C881.984253,197.271118 877.012817,192.506592 871.997803,187.403992 
	C874.727417,189.028961 877.500610,190.991989 880.613892,193.000488 
	C882.942566,195.810135 884.931152,198.574310 886.937744,201.687073 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M894.940186,209.686279 
	C892.987183,208.262680 891.017212,206.490555 889.008911,204.377106 
	C890.954773,205.803101 892.938904,207.570435 894.940186,209.686279 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M902.943237,218.681519 
	C900.995911,217.246567 899.028870,215.464020 897.036316,213.335205 
	C898.981689,214.770599 900.952637,216.552261 902.943237,218.681519 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M866.492615,185.935745 
	C866.105286,185.915375 866.055908,185.446320 865.993652,184.892487 
	C865.290100,183.889679 864.648682,183.440689 864.007324,182.991714 
	C863.689087,182.886215 863.428772,182.699890 863.111938,182.218323 
	C862.744812,181.201187 862.492065,180.398453 862.120117,179.298157 
	C866.469727,178.797958 867.983704,182.321121 869.968628,185.644714 
	C868.961609,185.982925 867.920776,185.969528 866.492615,185.935745 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M920.951538,240.705063 
	C920.300720,240.266830 919.641113,239.502472 918.972717,238.388641 
	C919.623535,238.819092 920.283142,239.599014 920.951538,240.705063 
z"/>
<path fill="#BAB9B8" opacity="1.000000" stroke="none" 
	d="
M747.893799,104.739716 
	C747.033325,104.565163 746.066589,104.131241 745.046509,103.353912 
	C745.439087,103.005112 745.885071,102.999710 746.665527,102.996552 
	C747.262512,103.492645 747.525024,103.986496 747.893799,104.739716 
z"/>
<path fill="#BAB9B8" opacity="1.000000" stroke="none" 
	d="
M735.002441,98.996933 
	C730.364624,96.916473 725.726868,94.836014 721.022339,92.417358 
	C725.062073,93.693932 729.168518,95.308701 733.743774,97.222366 
	C734.475952,98.013161 734.739197,98.505051 735.002441,98.996933 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M735.211060,99.256577 
	C734.739197,98.505051 734.475952,98.013161 734.103882,97.263702 
	C734.745789,97.003738 735.496521,97.001343 736.622375,97.000626 
	C737.317993,97.783508 737.638428,98.564728 737.983643,99.662689 
	C737.145447,99.825027 736.282593,99.670624 735.211060,99.256577 
z"/>
<path fill="#BAB9B8" opacity="1.000000" stroke="none" 
	d="
M717.013123,91.576675 
	C715.754272,91.179337 714.419678,90.454643 713.024048,89.397484 
	C714.287842,89.793121 715.612549,90.521217 717.013123,91.576675 
z"/>
<path fill="#BAB9B8" opacity="1.000000" stroke="none" 
	d="
M740.028137,99.921761 
	C739.845215,100.028633 739.446350,100.037109 739.247437,100.037048 
	C739.347656,99.765244 739.646790,99.493500 739.945862,99.221756 
	C740.034302,99.419456 740.122681,99.617172 740.028137,99.921761 
z"/>
<path fill="#8B92A5" opacity="1.000000" stroke="none" 
	d="
M638.024902,1008.304810 
	C649.522766,1005.289001 661.215332,1002.860352 672.475342,999.127136 
	C691.944153,992.672241 711.315186,985.840515 730.394531,978.322571 
	C742.035156,973.735779 753.045471,967.552856 764.364014,962.139404 
	C765.535950,961.578918 766.880005,961.378174 768.898438,960.968262 
	C771.751648,960.266968 773.850525,959.608093 775.949463,958.949280 
	C775.949463,958.949280 775.978882,958.979065 775.993408,958.994324 
	C772.470581,961.143616 768.933167,963.277649 764.805481,965.701599 
	C759.382996,968.048584 754.512878,970.024231 749.754150,972.239502 
	C748.917053,972.629150 748.546631,974.021362 747.960693,974.950684 
	C743.530396,976.873779 739.100098,978.796814 733.954834,980.855347 
	C731.487427,981.990356 729.734863,982.989807 727.982300,983.989258 
	C727.210510,984.316467 726.438721,984.643738 725.330383,984.965210 
	C722.588257,985.514221 720.074585,985.788818 717.794006,986.668091 
	C698.418640,994.137024 678.986572,1001.357178 658.455627,1005.160645 
	C651.521179,1006.445251 644.706482,1008.376404 637.512939,1009.777588 
	C637.467407,1009.130310 637.746155,1008.717529 638.024902,1008.304810 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M748.332886,974.934326 
	C748.546631,974.021362 748.917053,972.629150 749.754150,972.239502 
	C754.512878,970.024231 759.382996,968.048584 764.589966,965.977112 
	C759.544800,968.947815 754.124939,971.932922 748.332886,974.934326 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M728.338867,983.962891 
	C729.734863,982.989807 731.487427,981.990356 733.628418,980.999573 
	C732.243103,981.984314 730.469299,982.960449 728.338867,983.962891 
z"/>
<path fill="#8B92A5" opacity="1.000000" stroke="none" 
	d="
M161.028229,833.329712 
	C161.575745,833.515320 162.166153,834.017883 162.846680,834.806580 
	C162.314880,834.610657 161.693008,834.128601 161.028229,833.329712 
z"/>
<path fill="#8B92A5" opacity="1.000000" stroke="none" 
	d="
M176.010376,852.328491 
	C176.648209,852.453857 177.283264,852.908203 177.959229,853.682495 
	C177.561523,853.996643 177.122910,853.990784 176.356018,853.978699 
	C176.020996,853.534119 176.014297,853.095764 176.010376,852.328491 
z"/>
<path fill="#8B92A5" opacity="1.000000" stroke="none" 
	d="
M174.134201,850.260620 
	C174.658829,850.443054 175.290161,850.897156 175.960968,851.669189 
	C175.413925,851.502258 174.827423,851.017273 174.134201,850.260620 
z"/>
<path fill="#8089A6" opacity="1.000000" stroke="none" 
	d="
M637.614990,1008.227539 
	C637.746155,1008.717529 637.467407,1009.130310 637.098022,1009.777832 
	C634.568970,1010.656921 632.130615,1011.301147 629.314392,1012.028320 
	C627.923767,1012.129883 626.910950,1012.148438 625.100342,1012.182373 
	C619.170898,1013.179993 614.039429,1014.162170 608.907898,1015.144348 
	C608.081604,1015.277039 607.255310,1015.409790 605.780762,1015.773926 
	C601.804871,1015.794800 598.477112,1015.584290 595.149414,1015.373779 
	C600.559387,1014.331543 605.952332,1013.188477 611.383301,1012.270508 
	C619.977112,1010.817871 628.596497,1009.516052 637.614990,1008.227539 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M609.017578,1015.504822 
	C614.039429,1014.162170 619.170898,1013.179993 624.722168,1012.229126 
	C624.123840,1013.595215 623.105774,1014.929932 622.011230,1016.600220 
	C621.504272,1016.943237 621.073730,1016.950623 620.319458,1016.953918 
	C618.323059,1016.803467 616.648682,1016.674072 614.978210,1016.506165 
	C613.026184,1016.310059 611.077454,1016.080383 609.017578,1015.504822 
z"/>
<path fill="#8089A6" opacity="1.000000" stroke="none" 
	d="
M314.024963,961.518738 
	C318.461639,963.116760 322.985352,965.016724 327.486511,966.968689 
	C344.599396,974.389771 361.708313,981.820129 378.809235,989.268799 
	C380.901428,990.180115 382.936279,991.223145 385.144135,992.528687 
	C382.165863,991.949829 378.941437,991.297791 375.934174,990.100342 
	C364.471619,985.536133 352.985962,981.008301 341.691528,976.050903 
	C333.396393,972.410095 325.374664,968.143188 317.261749,964.093750 
	C316.118896,963.523315 315.150665,962.602966 314.024963,961.518738 
z"/>
<path fill="#8B92A5" opacity="1.000000" stroke="none" 
	d="
M211.032715,889.318115 
	C211.672531,889.431396 212.299271,889.875793 212.972961,890.631226 
	C212.576706,890.954773 212.133484,890.967346 211.358414,890.976685 
	C211.024246,890.532043 211.021927,890.090698 211.032715,889.318115 
z"/>
<path fill="#FEFEFF" opacity="1.000000" stroke="none" 
	d="
M897.014893,375.974152 
	C889.284912,380.453949 886.335449,387.632111 885.534790,396.878113 
	C885.283325,399.817230 885.044983,401.971344 885.044678,404.125549 
	C885.031677,487.343750 885.035645,570.561951 885.073669,653.780151 
	C885.074646,655.911255 885.479614,658.042297 885.576416,660.339233 
	C885.420654,660.831482 885.560120,661.041260 885.883301,661.146606 
	C885.891479,661.158752 885.867249,661.184387 885.867249,661.184387 
	C886.048889,674.451721 892.720520,682.718628 905.515686,684.662659 
	C909.096375,685.206726 909.874390,686.529114 909.732910,689.732361 
	C909.520142,694.548706 909.808777,699.387634 909.582031,704.202759 
	C909.517822,705.566956 908.401794,706.881653 906.937134,708.256775 
	C903.075073,708.362488 900.044006,708.430481 896.561279,708.363525 
	C889.792419,708.196350 883.475281,708.146729 877.158081,708.134827 
	C835.763367,708.056702 794.368652,707.986633 752.729980,707.663574 
	C751.083618,707.270142 749.681152,707.127075 748.215698,706.666260 
	C748.204285,705.921570 748.255981,705.494629 748.691772,705.069397 
	C756.365479,706.836304 756.861694,706.364563 757.068542,698.804504 
	C757.194458,694.201233 757.682983,689.607849 758.420715,684.968689 
	C767.409851,684.106628 774.562683,681.251160 777.613220,671.968689 
	C778.997070,666.651733 780.880798,661.402039 780.919678,656.138672 
	C781.177673,621.249756 781.044495,586.357849 781.019653,551.466919 
	C781.018250,549.520020 780.848755,547.573242 780.727112,544.979065 
	C777.560120,544.979065 774.785706,544.979065 771.561157,544.878845 
	C767.743713,544.806030 764.376343,544.833496 760.551514,544.744263 
	C731.839233,544.624084 703.584290,544.601257 675.329590,544.675476 
	C673.934753,544.679138 672.542175,545.529541 671.054382,546.317261 
	C671.017456,547.101379 671.074768,547.553833 671.035889,548.415894 
	C671.011963,550.223450 671.084167,551.621338 671.059326,553.477844 
	C670.948975,578.329407 670.906738,602.722412 670.936340,627.115356 
	C670.948425,637.061890 671.116516,647.008362 671.213623,656.954834 
	C671.161926,657.417786 671.110229,657.880676 671.078491,659.087280 
	C672.214050,676.215576 679.387817,683.911560 694.579529,685.021301 
	C694.677673,691.891785 694.817078,698.762329 694.820312,705.632812 
	C694.820740,706.465332 694.086670,707.298218 692.880005,708.201782 
	C688.362183,708.369873 684.657532,708.467102 680.500732,708.445435 
	C665.591553,708.297180 651.134521,708.268860 636.677490,708.238098 
	C605.107361,708.170959 573.537292,708.102600 541.682983,707.773193 
	C538.744995,707.317627 536.091187,707.123535 533.339172,706.627869 
	C533.298096,705.909546 533.355164,705.492737 533.791138,705.078430 
	C541.606384,706.687439 541.851196,706.390442 542.062866,698.756104 
	C542.194031,694.024292 542.755249,689.304382 543.313965,684.423950 
	C543.808228,684.141296 544.123657,684.057800 544.792480,684.316650 
	C555.824524,684.354797 562.364258,678.529236 564.957397,666.700989 
	C565.339233,664.882446 565.604858,663.423401 565.933350,661.555420 
	C566.022766,659.769043 566.049377,658.391663 566.344299,656.698364 
	C566.715881,654.562134 566.909180,652.741760 566.909668,650.921326 
	C566.930115,574.591736 566.967041,498.262115 566.891846,421.932617 
	C566.883362,413.299835 566.230530,404.667755 565.910645,395.648834 
	C565.286438,392.844391 564.628052,390.426483 563.968567,387.632751 
	C561.484131,379.227753 556.307800,376.291077 549.585205,379.109802 
	C545.173279,377.293274 541.085449,375.648315 536.848022,373.746155 
	C536.001404,373.116760 535.304321,372.744629 534.889160,372.034912 
	C537.207214,371.273895 539.243408,370.850464 541.692688,370.341125 
	C542.070557,363.901855 542.453674,357.373016 542.869141,350.887451 
	C542.901428,350.930664 542.887573,350.823608 543.357239,350.908325 
	C555.210632,350.810059 566.594360,350.627106 578.442505,350.390442 
	C583.612061,350.372498 588.317261,350.408325 593.364380,350.666382 
	C617.845642,350.939697 641.984863,350.995209 666.124146,351.016571 
	C666.741699,351.017120 667.359619,350.642822 668.317139,350.391235 
	C669.112610,350.373566 669.568298,350.407928 670.365662,350.664673 
	C678.455872,350.804840 686.204468,350.722687 694.288452,350.938171 
	C694.728394,357.174683 694.716064,363.118958 694.987854,369.050171 
	C695.119751,371.929718 694.061584,372.853729 691.253723,373.563812 
	C677.290588,377.095093 672.169434,382.995422 671.036621,396.390869 
	C671.000366,397.867950 671.059753,398.947754 671.040344,400.488678 
	C670.952576,419.718262 670.915710,438.486725 670.943054,457.255157 
	C670.965759,472.859955 671.064026,488.464630 671.047241,504.482788 
	C670.463928,507.635956 672.153137,507.834290 674.217896,507.830292 
	C706.702393,507.767029 739.186890,507.731598 772.135925,507.792480 
	C782.521851,507.851044 780.918884,508.969238 780.993958,499.408142 
	C781.088013,487.432739 781.054993,475.455963 781.018005,463.479950 
	C780.949646,441.360657 780.838501,419.241486 780.828552,396.716187 
	C780.260864,393.547241 779.609863,390.784332 778.966675,387.653076 
	C773.409363,378.511810 771.070496,377.038391 766.029358,380.527588 
	C761.192627,378.109497 756.603088,375.913757 751.695312,373.525421 
	C750.501160,372.963226 749.625366,372.593597 748.805298,371.962708 
	C748.627441,371.456665 748.393799,371.211853 748.113525,370.659637 
	C748.140320,369.944824 748.213684,369.537415 748.647827,369.168457 
	C751.356201,369.861053 753.703857,370.515198 757.078247,371.455444 
	C757.078247,363.886658 757.078247,357.509735 757.236328,351.002838 
	C757.394348,350.872864 757.788147,350.761566 758.260742,350.861908 
	C808.688171,350.962250 858.643066,350.962250 908.955017,350.962250 
	C908.955017,358.777161 908.955017,365.737030 908.955017,372.696960 
	C907.973206,372.899292 906.991333,373.101624 905.327026,373.243164 
	C904.091370,373.418671 903.538330,373.654877 902.985229,373.891052 
	C902.610352,374.013428 902.235474,374.135803 901.237732,374.240509 
	C899.414856,374.806580 898.214844,375.390381 897.014893,375.974152 
z"/>
<path fill="#FEFEFF" opacity="1.000000" stroke="none" 
	d="
M244.233093,706.941040 
	C243.907852,707.286011 243.582611,707.631042 242.604950,708.137817 
	C238.278458,708.396790 234.604385,708.493958 230.482666,708.465454 
	C226.718735,708.307312 223.402481,708.256653 220.086090,708.245544 
	C196.043640,708.164978 172.001129,708.092468 147.750931,707.770752 
	C146.369537,707.666809 145.195862,707.809448 143.613022,707.993652 
	C142.122910,707.903442 141.041946,707.771606 139.950928,707.453308 
	C139.790695,707.009949 139.575104,706.930359 139.149277,706.710449 
	C139.042252,705.933716 139.080048,705.474487 139.501312,705.084839 
	C146.619049,706.795044 147.713837,706.209778 147.859619,700.475098 
	C147.944794,695.030518 147.976761,690.030396 148.249023,684.884216 
	C148.843750,684.617859 149.198181,684.497559 149.955688,684.565186 
	C151.988861,684.573669 153.658905,684.563232 155.242813,684.188599 
	C170.081360,680.678833 178.873932,670.659912 183.998962,656.954773 
	C189.859238,641.283569 195.410660,625.497131 201.244568,609.815857 
	C209.755692,586.938354 218.435898,564.123901 226.977844,541.257874 
	C241.454849,502.504150 255.949127,463.756561 270.269531,424.944916 
	C273.163544,417.101440 275.317230,408.984741 278.085083,400.836060 
	C278.464203,400.093140 278.570465,399.508179 278.776367,398.543091 
	C278.947235,397.120209 279.018524,396.077484 279.330017,394.743225 
	C279.617004,392.664856 279.663757,390.878052 279.778870,388.705231 
	C278.133148,380.286987 275.678192,377.754059 264.798462,377.739136 
	C264.375061,377.629669 264.202026,377.600952 263.932709,377.390411 
	C263.619720,377.008240 263.365173,376.927643 263.060974,376.990417 
	C263.049225,377.014038 262.998596,376.999176 262.990784,376.655670 
	C260.989807,375.471527 258.996613,374.630890 256.694061,373.716553 
	C255.969955,373.688873 255.555252,373.734955 255.238022,373.667786 
	C255.335510,373.554565 255.219879,373.279022 255.097961,372.983948 
	C256.696899,371.753815 258.417725,370.818756 261.054626,369.385895 
	C261.054626,363.899597 261.054626,357.493896 261.205505,350.958588 
	C261.356415,350.828949 261.739349,350.721008 262.215027,350.833435 
	C295.368805,350.971191 328.046906,350.996490 360.853149,351.384949 
	C368.681152,370.343384 376.326874,388.961273 384.098541,407.526459 
	C389.929108,421.454681 395.921692,435.315063 401.842163,449.205658 
	C401.842163,449.205658 401.839081,449.100891 401.838135,449.504272 
	C422.751068,500.258575 443.639191,550.620239 464.591431,600.955078 
	C472.948578,621.031921 481.064392,641.222961 490.020111,661.031982 
	C495.748077,673.701599 505.113617,682.846802 519.779114,684.964478 
	C520.876526,685.122986 521.829651,686.280518 522.699829,687.421692 
	C522.668152,693.896301 522.786560,699.922791 522.736694,706.286499 
	C522.445679,707.110107 522.322937,707.596558 522.128784,708.069946 
	C522.057373,708.057007 522.056641,708.202148 521.796936,708.226318 
	C521.394470,708.349976 521.251770,708.449341 520.666504,708.407654 
	C517.455627,708.369995 514.687439,708.473389 511.500885,708.452515 
	C509.049194,708.406128 507.015808,708.484070 504.558655,708.422485 
	C501.759094,708.358337 499.383270,708.433655 496.556427,708.375488 
	C494.775391,708.229065 493.445435,708.206909 492.115387,708.204529 
	C447.717743,708.126648 403.320068,708.050598 358.719177,707.740417 
	C357.756226,707.090942 356.996521,706.675354 355.504669,705.859192 
	C363.914978,706.069458 363.912201,706.069458 363.931366,698.434814 
	C363.938416,695.623779 364.037750,692.810608 363.957489,690.002319 
	C363.871704,687.001953 364.590302,685.510498 368.138397,684.741028 
	C373.959076,683.478699 379.382965,680.820068 381.681213,674.083801 
	C382.359070,670.596802 382.693787,667.292114 383.034119,663.569336 
	C382.349091,660.097473 382.029480,656.899048 380.904816,654.014465 
	C375.553497,640.289978 369.416962,626.841919 364.714386,612.905945 
	C362.113312,605.197876 360.893921,604.390320 352.092346,605.302002 
	C350.135773,605.205994 348.564728,605.196472 346.661499,605.102173 
	C345.878235,604.952332 345.427094,604.887207 344.659973,604.558838 
	C343.857544,604.203674 343.371094,604.031433 342.884613,604.031128 
	C311.474884,604.013489 280.065155,603.997437 248.655533,604.052185 
	C247.460861,604.054260 246.267395,604.752136 244.998917,605.451965 
	C244.688095,606.860168 244.451706,607.942200 243.965363,609.282104 
	C243.027496,611.413940 242.345200,613.289978 241.650757,615.161438 
	C236.380569,629.364136 230.727509,643.439148 225.941574,657.803101 
	C221.005219,672.618591 225.708603,680.794495 240.681259,684.563049 
	C243.530396,685.280273 243.993759,686.507568 243.981781,688.978882 
	C243.952759,694.965698 244.135620,700.953613 244.233093,706.941040 
M263.519623,556.288269 
	C263.367126,556.878357 263.214600,557.468506 262.545929,558.538513 
	C261.927795,560.379700 261.309662,562.220886 260.341583,564.434509 
	C260.331635,564.766479 260.321686,565.098511 259.985779,566.168030 
	C261.132080,566.688843 262.276886,567.660217 263.424896,567.664062 
	C287.816742,567.745178 312.208923,567.717590 337.533966,567.822571 
	C340.509583,567.822571 343.485199,567.822571 346.839417,567.822571 
	C342.189026,556.159729 338.016449,545.853394 333.966431,535.499146 
	C328.545990,521.641174 323.241943,507.737701 317.847473,493.869507 
	C313.584930,482.911224 309.290527,471.964935 304.919403,461.049744 
	C304.485596,459.966583 303.112000,458.216003 302.698547,458.353302 
	C301.099091,458.884369 299.289612,459.752472 298.351685,461.062897 
	C297.155853,462.733643 296.728485,464.954468 295.377808,467.387573 
	C294.920197,469.897491 294.462585,472.407440 293.526886,475.584900 
	C292.969818,477.366211 292.412720,479.147522 291.305542,481.344818 
	C290.799988,482.874054 290.294434,484.403320 289.282684,486.428650 
	C284.157562,500.609222 279.032471,514.789795 273.354950,529.248962 
	C273.248871,529.845154 273.142792,530.441406 272.554230,531.557861 
	C269.698151,539.574524 266.842102,547.591187 263.861877,555.522095 
	C263.861877,555.522095 263.944122,555.469421 263.519623,556.288269 
z"/>
<path fill="#1A38B2" opacity="1.000000" stroke="none" 
	d="
M244.607544,706.836426 
	C244.135620,700.953613 243.952759,694.965698 243.981781,688.978882 
	C243.993759,686.507568 243.530396,685.280273 240.681259,684.563049 
	C225.708603,680.794495 221.005219,672.618591 225.941574,657.803101 
	C230.727509,643.439148 236.380569,629.364136 241.650757,615.161438 
	C242.345200,613.289978 243.027496,611.413940 244.296295,609.266357 
	C245.622467,608.376038 246.367737,607.759338 247.573761,607.117676 
	C277.652832,607.078430 307.271179,607.064148 336.611908,607.346191 
	C334.520874,607.763306 332.707611,607.987122 330.894043,607.989197 
	C309.242157,608.013489 287.590210,608.004883 265.938293,608.004883 
	C263.647308,608.004883 261.356354,608.004883 259.065369,608.004883 
	C259.642242,608.766113 260.245422,608.945740 260.848724,608.946228 
	C286.997589,608.967285 313.146484,608.986206 339.295227,608.936462 
	C340.513031,608.934143 341.729614,608.301697 343.287048,607.979004 
	C344.080811,607.995239 344.534271,607.995178 344.986725,608.362183 
	C346.711365,611.275879 348.949585,613.615356 350.077087,616.403931 
	C356.085449,631.263916 363.270142,645.703735 366.684967,661.520386 
	C368.280548,668.910583 365.127136,675.895447 358.960114,680.166443 
	C356.546509,681.838074 354.917236,684.642212 352.562683,686.994019 
	C351.156128,688.208923 349.348663,689.282104 349.221954,690.526428 
	C348.827637,694.398987 348.790710,698.369019 349.241058,702.228149 
	C349.371490,703.345642 351.608490,704.217285 352.894562,705.549866 
	C352.957672,706.598694 353.005951,707.299377 353.098358,708.372437 
	C354.770081,709.171631 356.397675,709.598389 358.436340,710.038208 
	C364.412628,710.370789 369.977417,710.958496 375.543091,710.966858 
	C416.020477,711.027893 456.498077,710.972290 497.407715,710.973633 
	C500.234863,710.663330 502.629913,710.332886 505.428955,710.003052 
	C507.861694,709.528015 509.890442,709.052368 511.919189,708.576782 
	C514.687439,708.473389 517.455627,708.369995 520.799561,708.482788 
	C521.709045,708.700012 521.936218,708.534424 522.056641,708.202148 
	C522.056641,708.202148 522.057373,708.057007 522.427673,708.001587 
	C523.190186,707.306946 523.582458,706.667725 523.983276,705.559448 
	C524.331177,699.724182 524.670471,694.358032 525.174805,688.904663 
	C525.228760,688.548096 525.117798,688.278687 525.002808,687.679443 
	C524.994019,686.910278 524.989258,686.471008 525.375854,686.022827 
	C526.811035,685.995850 527.854797,685.977600 528.923584,686.362366 
	C528.298767,691.194519 527.548401,695.611816 527.024902,700.055847 
	C526.401917,705.344299 529.509216,708.189331 534.421509,710.042603 
	C539.335510,710.373840 543.786133,710.961304 548.237671,710.968018 
	C590.644592,711.032227 633.051758,711.028259 675.458313,710.889160 
	C677.292358,710.883118 679.121460,709.373474 680.952820,708.564270 
	C684.657532,708.467102 688.362183,708.369873 692.503906,708.417480 
	C692.025940,709.075989 691.150574,709.686646 690.186890,710.081726 
	C688.524475,710.763367 685.977112,710.806213 685.251770,711.999207 
	C681.404236,718.326660 675.364502,717.055847 669.701660,716.956421 
	C656.416321,716.723206 643.134277,716.097046 629.850098,716.071838 
	C618.732483,716.050720 607.612854,716.943665 596.495544,716.913086 
	C581.712036,716.872375 566.930786,716.246643 552.146240,716.078552 
	C549.620605,716.049927 547.096130,717.131042 544.546631,717.551147 
	C542.927551,717.817932 541.016724,718.394165 539.663147,717.822266 
	C534.525818,715.651733 529.529480,713.134338 524.552368,710.605042 
	C522.932190,709.781677 515.534729,711.062500 514.197144,712.680237 
	C511.113708,716.409485 500.810638,718.693542 496.239563,716.888184 
	C494.611511,716.245056 492.718384,716.046814 490.944061,716.036865 
	C478.535339,715.967285 466.124451,715.876892 453.717499,716.041077 
	C444.595215,716.161804 435.476227,716.970093 426.358795,716.919922 
	C409.575989,716.827698 392.796448,716.208191 376.013336,716.062561 
	C369.729187,716.007996 363.442078,716.821777 357.150452,716.947327 
	C351.389771,717.062317 345.566711,711.428833 344.960693,705.676514 
	C344.824738,704.385925 344.344635,703.121765 343.940735,701.871948 
	C343.481689,700.451477 342.664551,699.097473 342.465149,697.650879 
	C341.599213,691.368958 347.649261,679.903687 353.344971,676.755676 
	C361.735840,672.118103 363.308105,664.385498 358.547729,655.482483 
	C356.021667,650.758179 354.854584,645.312439 353.022125,640.210938 
	C350.742767,633.865417 348.284546,627.581177 346.136322,621.192261 
	C344.115570,615.182373 336.941772,611.739441 329.950531,613.147217 
	C327.534149,613.633789 325.037933,613.948425 322.577209,613.954773 
	C299.757782,614.013977 276.938141,613.988403 254.118576,613.987488 
	C251.284607,613.987366 248.450638,613.987549 244.663116,613.987549 
	C243.313156,617.019287 241.488724,620.680542 240.028214,624.481628 
	C236.236618,634.349670 232.546936,644.258057 228.942856,654.196106 
	C227.766876,657.438904 226.687180,660.769531 226.070847,664.152344 
	C224.233963,674.234253 231.174759,679.483032 239.666733,681.788818 
	C244.571259,683.120483 246.244812,685.556763 245.983841,690.119202 
	C245.667053,695.657654 245.317413,701.194336 244.607544,706.836426 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M528.898560,685.959351 
	C527.854797,685.977600 526.811035,685.995850 525.153320,686.097656 
	C523.976135,686.445374 523.413025,686.709473 522.849854,686.973572 
	C521.829651,686.280518 520.876526,685.122986 519.779114,684.964478 
	C505.113617,682.846802 495.748077,673.701599 490.020111,661.031982 
	C481.064392,641.222961 472.948578,621.031921 464.591431,600.955078 
	C443.639191,550.620239 422.751068,500.258575 401.793030,449.530884 
	C403.064209,450.637054 404.864777,451.908783 405.616852,453.636871 
	C410.824982,465.603546 415.889709,477.634399 420.851379,489.705963 
	C424.885162,499.520050 428.547882,509.488281 432.665497,519.265930 
	C440.051392,536.804504 447.723846,554.222351 455.138062,571.749207 
	C458.593445,579.917603 461.626526,588.263855 465.023529,596.457947 
	C472.472443,614.425903 480.172913,632.290894 487.478882,650.316162 
	C490.600739,658.018433 493.620728,665.737366 500.192627,671.149597 
	C503.260223,673.675781 507.082947,675.283691 510.557678,677.317688 
	C511.364319,677.789856 512.122131,678.349792 512.945984,678.787292 
	C516.296997,680.566711 519.664856,682.314148 523.930176,684.546204 
	C524.106873,681.499268 524.187256,680.112976 524.265076,678.771912 
	C531.945679,676.837402 536.879333,672.096497 541.430237,665.821350 
	C544.972412,660.937134 544.995117,656.223022 544.995178,651.017700 
	C544.995605,567.858521 544.996826,484.699310 544.995178,401.540131 
	C544.995056,395.038544 537.725403,385.014862 531.485168,382.885529 
	C525.020447,380.679596 520.988281,374.904053 521.004517,367.802155 
	C521.021973,360.189667 519.889709,352.317383 524.817810,345.635895 
	C525.843018,344.245911 527.953430,343.625610 529.618958,342.756561 
	C530.150024,342.479462 531.077026,342.775177 531.454895,342.418701 
	C536.296814,337.850677 541.566711,338.600311 547.150024,340.774841 
	C548.610596,341.343719 550.446594,340.989929 552.110168,340.990295 
	C589.440125,340.998169 626.770264,340.955536 664.099976,341.041290 
	C669.556519,341.053833 675.010437,341.689697 680.467773,341.998444 
	C683.801636,342.187103 685.702576,343.829285 685.633667,347.600098 
	C684.512939,347.994263 683.756470,347.997650 682.669983,348.000549 
	C681.900330,347.991241 681.460693,347.982391 680.957275,347.622986 
	C679.163025,344.425171 677.042664,342.895325 673.299683,342.908508 
	C631.010803,343.057526 588.721191,342.972504 546.432129,343.086823 
	C544.285461,343.092621 542.142151,344.342743 539.569702,345.008850 
	C537.431335,345.006866 535.720581,345.009521 533.612915,345.006866 
	C532.126465,345.263611 530.190369,345.329285 530.076965,345.817902 
	C528.817627,351.244659 522.046875,354.342224 523.910583,361.153473 
	C524.966675,365.012970 524.724182,369.239624 525.875427,373.056976 
	C526.597290,375.450623 528.820862,377.391388 530.712341,380.008026 
	C532.383301,380.008026 534.686768,380.008026 537.266479,380.208832 
	C538.744751,381.908020 539.648621,384.114166 541.205505,384.769623 
	C545.554138,386.600647 545.542419,390.753693 547.147278,394.096649 
	C547.705994,395.260345 548.963806,396.088440 549.916687,397.526672 
	C549.635742,402.985657 549.340393,407.987762 548.750000,413.245300 
	C548.611511,416.428345 548.959534,419.360687 548.888184,422.282776 
	C548.687622,430.491119 548.206482,438.693146 548.044800,446.901794 
	C547.925110,452.975586 548.056580,459.057831 548.220276,465.132446 
	C548.336121,469.430725 548.950378,473.726349 548.887085,478.016418 
	C548.763489,486.392181 548.315491,494.763031 548.021790,503.136658 
	C547.998657,503.795990 548.166077,504.459656 548.187134,505.123169 
	C548.415955,512.330627 548.837646,519.539551 548.800110,526.745972 
	C548.756531,535.121582 548.096863,543.495117 548.098572,551.869446 
	C548.100098,559.075500 548.936584,566.283020 548.898254,573.487122 
	C548.843933,583.693726 548.153259,593.896545 548.070496,604.103699 
	C548.022339,610.057190 548.908569,616.017456 548.879089,621.972168 
	C548.829346,632.010864 548.385864,642.047729 548.095337,652.527832 
	C548.076050,655.630127 548.069275,658.289978 548.031738,661.337158 
	C546.999817,665.482117 545.998657,669.239868 544.998779,672.998779 
	C545.000000,673.000000 544.997559,672.997559 544.626526,672.997314 
	C539.183533,675.890137 534.067810,678.712524 529.104797,681.781738 
	C528.587585,682.101685 528.975281,683.884888 528.937378,685.231995 
	C528.931763,685.474792 528.898560,685.959412 528.898560,685.959351 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M433.008545,189.993286 
	C432.960663,185.464096 433.699158,180.734360 432.611725,176.470276 
	C431.768188,173.162552 428.144562,171.502594 424.214508,171.985764 
	C418.772736,172.654785 415.010498,169.947372 412.412262,165.375336 
	C416.068604,166.376083 419.288147,168.244095 422.740143,168.914001 
	C430.775482,170.473312 434.631012,162.786987 440.676086,159.831650 
	C445.923523,157.266281 449.894501,152.220749 454.820374,148.840866 
	C466.588501,140.766098 479.586914,135.206451 493.411133,131.792480 
	C500.760437,129.977524 508.258331,128.545212 515.774353,127.682838 
	C524.065979,126.731491 532.656921,125.252113 540.761353,126.437523 
	C559.474976,129.174744 577.879517,133.208099 594.793518,142.814682 
	C604.167725,148.138901 613.296570,153.431732 621.033142,161.346985 
	C622.727051,162.790268 624.382935,163.885361 626.373413,165.037964 
	C627.805298,166.064301 628.902649,167.033142 630.030273,168.295105 
	C630.708679,168.724655 631.356812,168.861069 632.335693,168.997238 
	C633.107239,169.011230 633.548035,169.025467 634.070435,169.406158 
	C636.709839,171.418991 639.198975,172.813950 640.377075,168.096619 
	C641.808289,167.677322 642.899170,167.326141 644.098267,167.199158 
	C644.407410,167.703033 644.671936,167.895325 645.000000,168.000229 
	C643.349548,173.643097 638.771667,173.256012 634.453979,172.867981 
	C628.664368,172.347626 627.679443,173.230606 629.005981,178.883057 
	C630.205139,183.993057 629.014954,187.833130 624.144592,190.545395 
	C621.745300,191.881561 619.862610,194.145401 617.515015,195.692780 
	C618.179932,193.918518 619.075928,192.440933 620.106018,190.687347 
	C621.141968,189.592010 622.043762,188.772659 623.243896,187.942139 
	C623.704224,187.288330 623.866211,186.645721 624.037537,185.677307 
	C624.076416,184.917892 624.105835,184.484299 624.444580,183.983490 
	C624.832275,182.943695 624.910522,181.971130 624.962769,180.652206 
	C624.653015,179.196014 624.369385,178.086197 624.312805,176.797043 
	C625.899475,169.653824 620.210266,168.984344 615.677246,166.990479 
	C614.924500,166.636520 614.464661,166.312943 614.106079,165.722992 
	C614.014221,164.766830 613.821167,164.077026 613.628113,163.387238 
	C613.082947,163.592758 612.537842,163.798264 611.651672,163.950287 
	C606.548828,160.735931 601.879272,157.424118 597.006714,154.444229 
	C585.233521,147.244110 572.419189,142.619171 558.910522,140.061844 
	C547.883057,137.974258 536.646240,138.210251 525.512024,137.477295 
	C513.490540,136.685974 502.415100,139.344849 491.415466,142.274368 
	C474.011627,146.909500 458.196503,155.414886 444.278931,166.978455 
	C439.412659,171.021652 432.898163,174.662994 435.055664,182.882584 
	C435.700958,185.341080 437.020996,187.622452 437.613098,189.990204 
	C435.799042,189.994736 434.403809,189.994003 433.008545,189.993286 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M671.463257,656.630432 
	C671.116516,647.008362 670.948425,637.061890 670.936340,627.115356 
	C670.906738,602.722412 670.948975,578.329407 671.427246,553.388794 
	C674.022217,552.224182 676.149475,551.081726 678.282715,551.070679 
	C702.586670,550.944153 726.892578,551.123840 751.195496,550.919800 
	C760.040710,550.845642 762.747986,554.626892 762.847107,562.624084 
	C763.231018,593.582703 763.008301,624.548950 762.996033,655.512329 
	C762.992493,664.498047 759.882996,672.142395 751.955444,676.764343 
	C745.720093,680.399719 741.613464,685.379517 739.537537,692.073486 
	C737.234619,699.499329 740.151123,707.240479 745.888672,710.306152 
	C746.859619,711.074585 747.924316,711.554199 749.156250,712.343506 
	C753.004761,713.746765 756.628418,715.382751 760.383362,715.780579 
	C764.088745,716.173218 767.926392,715.051208 771.706909,715.043396 
	C805.607849,714.973267 839.508972,714.981018 873.409912,715.040100 
	C876.360962,715.045288 879.335022,715.953918 882.255127,715.767029 
	C885.296936,715.572327 889.505493,718.286194 891.384155,712.965515 
	C894.517700,712.299683 897.273621,711.678101 900.029541,711.056519 
	C901.561279,715.951538 900.552246,717.951599 895.807739,717.921326 
	C887.778320,717.870117 879.753357,717.094971 871.724060,717.048157 
	C849.217773,716.916992 826.709290,716.879089 804.203491,717.044250 
	C788.308838,717.160950 772.417297,717.681274 756.523804,717.991455 
	C753.117065,718.057983 749.667114,718.327881 746.309265,717.915771 
	C741.135620,717.280884 737.081360,712.454163 737.021545,707.274292 
	C736.941589,700.339539 736.809265,693.394348 737.129211,686.473511 
	C737.213684,684.646790 738.467957,682.410889 739.894714,681.196777 
	C744.099487,677.618530 748.890015,674.718994 753.026245,671.071838 
	C758.850647,665.936096 757.671875,658.366272 757.779541,651.932495 
	C758.275696,622.302490 758.013489,592.659546 757.985291,563.021240 
	C757.980652,558.103455 754.984436,555.155640 748.620483,555.098999 
	C725.478027,554.892761 702.332336,554.989380 679.188354,555.088562 
	C677.280823,555.096741 675.377075,555.983765 672.986267,556.584351 
	C672.652954,560.913147 672.055054,565.168579 672.041504,569.425842 
	C671.955200,596.566284 672.002991,623.707092 671.985291,650.847778 
	C671.984131,652.667236 671.807861,654.486633 671.463257,656.630432 
z"/>
<path fill="#1739BF" opacity="1.000000" stroke="none" 
	d="
M223.009583,815.994568 
	C219.831284,816.691711 216.760742,817.806885 213.870483,814.764160 
	C212.914856,813.758118 209.791855,813.132019 209.126678,813.797729 
	C204.312531,818.616089 198.521011,818.660217 192.460831,817.993286 
	C188.202499,817.524597 183.946442,817.035095 179.167068,816.495972 
	C179.167068,812.000061 179.161224,807.475037 179.175598,802.950073 
	C179.177139,802.465637 179.111649,801.766052 179.388214,801.528687 
	C186.987198,795.008972 186.744324,784.590393 191.543289,776.494507 
	C194.577255,771.376221 198.518143,764.664307 196.397614,757.060913 
	C195.523041,753.925110 196.855591,750.140259 197.379410,746.680176 
	C197.468826,746.089539 198.485687,745.307617 199.173126,745.189331 
	C208.339096,743.611633 217.761536,742.030396 226.303055,746.615234 
	C229.046265,748.087708 230.213181,752.717102 231.818680,756.038757 
	C234.405792,761.391357 236.758423,766.857178 239.210114,772.275208 
	C239.951904,772.243530 240.693695,772.211853 241.435486,772.180237 
	C241.930511,770.607483 242.905060,769.013123 242.835297,767.465820 
	C242.571243,761.609192 241.583374,755.757629 241.737686,749.927246 
	C241.781448,748.273804 244.607941,745.581604 246.465134,745.284607 
	C253.794937,744.112305 261.236481,743.385498 268.657654,743.146118 
	C272.309875,743.028320 276.025024,744.961060 279.681824,744.862183 
	C286.204742,744.685669 292.695923,743.538513 299.220551,743.092407 
	C301.072815,742.965759 302.982452,744.150635 304.887421,744.232971 
	C310.242493,744.464294 315.609009,744.499756 320.970734,744.488403 
	C325.582153,744.478699 329.888916,741.673950 334.909576,744.588013 
	C338.929504,746.921204 341.926422,749.357788 343.447327,755.222778 
	C345.601746,753.260376 347.246155,751.758301 348.895508,750.261658 
	C350.101288,749.167419 351.141357,747.511108 352.551514,747.084656 
	C360.384369,744.715332 368.251923,740.818909 376.496826,745.238831 
	C378.096924,746.096558 378.854065,748.526733 379.999786,750.616089 
	C379.999542,751.000488 379.982880,751.016968 379.655640,751.010864 
	C378.891876,750.998657 378.455322,750.992493 377.766235,750.984009 
	C377.345490,750.989075 377.177277,750.996277 377.010193,750.596375 
	C375.131042,746.309326 360.852539,745.375977 357.564941,748.955566 
	C355.981445,749.607666 354.361542,749.951843 353.655762,750.945312 
	C351.957245,753.336182 350.564697,755.971558 349.271393,758.617126 
	C347.103699,763.051514 345.858032,763.210449 342.884308,759.415710 
	C342.295319,758.664124 341.072083,758.409485 340.103699,757.682373 
	C340.066101,757.439026 340.017853,756.948181 340.018005,756.581787 
	C336.685150,746.788025 331.828857,746.261597 320.729889,747.354614 
	C318.419220,747.582214 316.233948,749.082947 313.666870,750.136475 
	C307.872314,749.829041 302.408539,749.141663 296.929779,748.987427 
	C286.722076,748.700012 276.507782,749.553894 266.291473,748.961853 
	C263.554688,748.803345 260.800873,748.937927 258.054901,748.937927 
	C258.050293,748.587280 258.045685,748.236572 258.041077,747.885925 
	C262.613068,747.885925 267.185059,747.885925 271.757050,747.885925 
	C266.592224,747.026245 261.403229,746.539307 256.365784,747.202881 
	C253.178497,747.622742 250.217255,749.758545 247.126099,751.059814 
	C247.097595,750.994995 247.130325,751.131470 246.882568,751.204468 
	C246.466782,751.565125 246.298752,751.852661 246.103073,752.388184 
	C246.075409,752.636047 245.880142,753.095154 245.622894,753.264404 
	C245.546646,754.581665 245.727661,755.729553 246.034058,757.138306 
	C246.495316,757.857605 246.831177,758.316162 247.084808,759.140747 
	C247.009933,760.008240 247.017303,760.509644 247.015991,761.478943 
	C246.284348,772.165771 248.712448,782.707275 244.192734,792.702209 
	C241.994492,789.561401 240.818512,786.297424 239.370483,783.158997 
	C235.492111,774.752991 231.346283,766.467712 227.590424,758.008545 
	C223.733597,749.321960 218.010559,745.845032 208.652435,747.044434 
	C205.000290,747.512573 201.105148,750.523254 200.920959,753.472290 
	C200.944214,756.608948 200.961197,759.298523 200.725403,762.169189 
	C200.318649,763.566711 200.164673,764.783142 199.996857,766.367554 
	C199.974197,767.471680 199.965363,768.207886 199.625015,768.963074 
	C198.873276,769.985718 198.453049,770.989502 197.976410,772.355042 
	C196.931534,775.464783 195.943069,778.212708 194.603668,781.037354 
	C192.960617,783.531128 191.376373,785.843445 190.460785,788.395630 
	C189.593994,790.812012 189.428696,793.479980 188.928772,796.323608 
	C188.604630,797.070984 188.306580,797.532104 187.658325,798.043518 
	C186.270721,800.145020 185.233322,802.196228 184.168884,804.180847 
	C184.141861,804.114258 184.285583,804.116211 183.951767,804.253845 
	C183.461975,805.066345 183.306015,805.741150 183.249542,806.497620 
	C183.349030,806.579224 183.378189,806.834900 183.060516,807.026489 
	C183.161087,808.451599 183.579330,809.685242 184.103851,811.177490 
	C184.502182,811.663452 184.794235,811.890747 185.123123,812.464539 
	C191.490189,819.034851 198.207504,813.393738 204.730804,813.288086 
	C205.236176,813.279907 205.761383,811.088318 206.181488,809.878235 
	C207.329391,806.571655 208.426071,803.247253 209.469299,800.148987 
	C214.453613,800.393188 215.446014,803.504211 215.915344,806.653809 
	C216.635239,811.484985 217.787003,815.516968 224.500443,813.918640 
	C223.737152,814.981506 223.373367,815.488037 223.009583,815.994568 
z"/>
<path fill="#253C91" opacity="1.000000" stroke="none" 
	d="
M643.990112,166.974976 
	C642.899170,167.326141 641.808289,167.677322 640.008728,168.193756 
	C637.529663,168.585922 635.759277,168.812805 633.988831,169.039688 
	C633.548035,169.025467 633.107239,169.011230 632.168213,168.751083 
	C631.113281,168.337448 630.556641,168.169708 630.000000,168.001984 
	C628.902649,167.033142 627.805298,166.064301 626.322266,164.687057 
	C624.289429,163.185364 622.642273,162.092072 620.995117,160.998779 
	C613.296570,153.431732 604.167725,148.138901 594.793518,142.814682 
	C577.879517,133.208099 559.474976,129.174744 540.761353,126.437523 
	C532.656921,125.252113 524.065979,126.731491 515.774353,127.682838 
	C508.258331,128.545212 500.760437,129.977524 493.411133,131.792480 
	C479.586914,135.206451 466.588501,140.766098 454.820374,148.840866 
	C449.894501,152.220749 445.923523,157.266281 440.676086,159.831650 
	C434.631012,162.786987 430.775482,170.473312 422.740143,168.914001 
	C419.288147,168.244095 416.068604,166.376083 412.383301,165.015152 
	C412.023102,164.977890 412.010681,164.991974 411.979736,164.638062 
	C409.949615,161.862366 407.950470,159.440567 405.822845,156.713821 
	C404.449219,155.275192 403.204071,154.141525 401.958923,153.007843 
	C401.948090,152.575134 401.937256,152.142426 401.945679,150.973969 
	C401.618011,148.481232 401.271057,146.724243 400.924133,144.967270 
	C401.283722,143.881516 401.643341,142.795746 402.316162,141.218048 
	C409.839142,134.926941 417.066650,129.149506 424.222321,123.284439 
	C424.752197,122.850113 424.765930,121.786026 425.019592,121.014709 
	C425.565765,120.836571 426.111969,120.658432 427.179810,120.209244 
	C436.919403,115.059639 445.847168,109.485710 455.431915,105.486183 
	C466.345123,100.932343 477.630066,96.823502 489.140015,94.250801 
	C506.094849,90.461037 523.473450,90.048630 540.881470,90.895523 
	C552.808350,91.475761 564.484741,92.678528 576.139893,95.714645 
	C605.864868,103.457909 632.126709,117.117111 654.191895,138.637054 
	C658.948853,143.276474 659.286743,150.941574 654.658997,155.821838 
	C651.119019,159.554977 647.547546,163.258362 643.990112,166.974976 
M625.803955,119.668190 
	C619.690857,116.289177 613.720459,112.614609 607.440613,109.580887 
	C575.476562,94.139534 541.641296,90.162804 506.722290,94.033760 
	C468.042267,98.321640 434.728241,114.367683 406.438385,140.970657 
	C402.408386,144.760406 402.421631,149.115570 406.268616,152.994720 
	C409.660278,156.414673 413.108124,159.779755 416.567963,163.131042 
	C421.856812,168.253891 425.157898,168.231537 430.451263,163.378479 
	C457.483154,138.595200 489.226898,124.391373 525.955017,123.414536 
	C565.771729,122.355545 600.067261,136.869644 629.342102,163.614563 
	C634.307068,168.150467 638.285950,168.067490 643.066284,163.290497 
	C646.597107,159.762100 650.145325,156.250137 653.623901,152.670715 
	C656.929810,149.269043 657.003479,144.327057 653.378540,141.358505 
	C644.513123,134.098526 635.446960,127.083672 625.803955,119.668190 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M738.992676,790.992676 
	C739.000793,790.554321 739.008911,790.115906 739.370239,789.305298 
	C746.350342,783.417175 746.544678,782.197449 744.216309,777.605652 
	C742.567505,774.354004 742.018860,770.544556 740.979126,766.984070 
	C740.988281,765.277832 740.997437,763.571594 741.348755,761.386353 
	C745.128052,756.939880 748.565247,752.972351 752.349792,748.970154 
	C754.465820,747.944946 756.234497,746.954407 758.003235,745.963867 
	C758.740906,745.962891 759.478577,745.961792 761.015259,745.970764 
	C766.885681,745.977966 771.957153,745.975159 777.028625,745.972351 
	C783.077209,745.646240 789.125732,745.320190 796.051514,744.995667 
	C801.951599,744.998962 806.974609,745.000671 811.817505,745.248230 
	C811.068542,745.873901 810.499634,746.253784 809.442993,746.959290 
	C811.402405,746.959290 812.717346,746.959290 814.403076,746.964478 
	C815.515259,746.974060 816.256714,746.978516 816.853027,747.228394 
	C816.142395,747.965271 815.576904,748.456726 814.581482,748.969482 
	C804.008240,749.424927 793.862366,750.228516 783.722595,750.158691 
	C777.349915,750.114868 770.925537,747.789490 764.630249,748.182800 
	C747.891663,749.228760 740.559631,764.763977 747.011292,778.683533 
	C748.835938,782.620300 751.834656,785.805908 745.365173,787.889038 
	C745.200195,787.942139 745.298035,788.811340 745.238403,789.520386 
	C745.203552,789.740784 744.926147,790.090454 744.598267,790.240601 
	C740.746826,795.704834 741.007324,802.885864 744.914062,806.206970 
	C744.992126,806.429443 745.074524,806.893677 745.045898,807.241455 
	C745.015320,808.054688 745.013306,808.520142 744.560547,808.991089 
	C741.406311,808.999329 738.702698,809.002136 735.953857,808.638062 
	C734.934875,806.203064 733.961304,804.134827 732.723511,801.845398 
	C730.565430,798.568787 728.245728,795.694702 726.853333,792.425659 
	C721.641479,780.189331 716.664551,767.850952 711.747131,755.492188 
	C709.977478,751.044495 707.618713,748.218872 702.200500,748.878662 
	C698.278198,749.356384 694.250122,749.039490 690.271667,748.957764 
	C687.441711,748.899780 685.799805,749.922729 685.932373,753.200439 
	C685.912231,753.425354 685.904419,753.876831 685.548950,753.905884 
	C683.820374,753.588440 682.447266,753.242065 681.050476,752.516357 
	C678.360962,751.092224 675.695068,750.047302 672.557617,748.988403 
	C659.051453,748.984070 646.016846,748.993835 632.567139,748.994446 
	C625.282227,749.402466 624.530273,750.131226 623.588684,757.025269 
	C620.830933,758.397095 618.410339,759.700989 615.721558,760.773071 
	C614.706909,758.528564 613.960388,756.515930 613.213867,754.503235 
	C612.031860,751.316223 610.119202,748.880066 606.394958,749.032410 
	C599.975891,749.294861 593.565125,749.760803 587.150879,750.141357 
	C587.259338,747.299438 589.000183,745.335754 591.498047,745.116028 
	C596.128052,744.708557 600.821045,744.923889 605.484802,745.021545 
	C609.485718,745.105347 612.916626,746.234070 614.997864,750.167175 
	C616.709045,753.400818 619.208679,752.706909 620.771973,750.210938 
	C623.548218,745.778259 627.443298,744.918396 632.287598,744.908081 
	C641.244385,744.888977 650.202209,744.028992 659.155396,744.094971 
	C667.384766,744.155579 675.603271,745.170898 683.836609,745.393799 
	C690.090210,745.563110 696.366760,745.283325 702.619629,744.949707 
	C707.191589,744.705811 710.058228,746.755371 712.073547,750.643005 
	C713.108154,752.638916 714.490479,754.454529 715.898010,757.053955 
	C717.390503,760.837585 718.700684,763.918762 720.010803,767.000000 
	C720.014771,767.437134 720.018738,767.874329 720.053345,768.999756 
	C721.908630,773.179932 723.859497,776.612671 725.529602,780.176941 
	C728.410522,786.324951 731.044739,792.589417 733.978577,798.710999 
	C734.391907,799.573425 736.150696,800.573059 736.858948,800.318665 
	C737.790527,799.983948 738.732544,798.534973 738.896057,797.448730 
	C739.213440,795.339600 738.991272,793.149353 738.992676,790.992676 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M672.033447,496.975159 
	C698.667542,496.984192 725.302490,496.882111 751.934814,497.102112 
	C756.874573,497.142944 758.118042,495.440369 758.088257,490.748199 
	C757.908813,462.450592 758.220459,434.148224 757.830505,405.854858 
	C757.742981,399.507751 758.548889,392.396393 753.150391,387.160400 
	C752.308289,386.343597 751.389160,385.355774 750.331604,385.051331 
	C741.961792,382.641602 737.483765,376.972198 737.008301,367.876099 
	C736.938843,366.547943 736.995605,365.213196 737.369263,363.440735 
	C738.486633,363.006866 739.228638,363.013733 739.975403,363.350403 
	C739.977844,364.120575 739.975525,364.560944 739.704773,365.240662 
	C740.286072,367.656677 741.135864,369.833282 742.230042,372.163361 
	C742.978516,372.884003 743.482544,373.451141 744.023438,374.357117 
	C745.039917,375.464325 746.019592,376.232788 747.108276,377.219543 
	C747.416931,377.710175 747.677368,377.897949 748.035156,378.355713 
	C749.715271,379.805542 751.358826,380.900940 753.001221,381.998169 
	C753.000000,382.000000 753.002502,381.996338 753.140991,382.287415 
	C754.187500,383.051117 755.095459,383.523743 756.221802,384.105377 
	C756.712463,384.413971 756.899902,384.674622 757.004272,385.362000 
	C758.332886,387.155029 759.659607,388.582428 761.248169,390.214691 
	C761.666931,391.279938 761.823853,392.140289 761.712646,393.240479 
	C761.958069,394.990143 762.471741,396.500000 762.986755,398.341675 
	C762.987183,399.115814 762.986145,399.558197 762.760864,400.146790 
	C762.690247,400.862061 762.843872,401.431030 762.999023,402.467377 
	C763.326294,409.554352 763.897766,416.172607 763.933105,422.793762 
	C764.051453,444.939087 764.099548,467.086823 763.845032,489.229858 
	C763.807495,492.494904 761.988403,495.739502 760.590332,498.995331 
	C757.788513,499.994232 755.390015,500.991333 752.523438,501.990234 
	C725.708313,501.977203 699.361267,501.962341 673.012207,501.577881 
	C672.684570,499.797211 672.359009,498.386169 672.033447,496.975159 
z"/>
<path fill="#1F3281" opacity="1.000000" stroke="none" 
	d="
M673.029175,749.002441 
	C675.695068,750.047302 678.360962,751.092224 681.067139,752.921631 
	C682.834106,757.155334 680.774597,762.369690 686.971008,764.950745 
	C686.562134,760.705627 686.233276,757.291199 685.904419,753.876831 
	C685.904419,753.876831 685.912231,753.425354 686.246094,753.224609 
	C686.788696,753.040588 686.997437,753.057312 687.284546,753.452698 
	C687.907776,755.485168 688.529846,757.118652 688.971802,758.799500 
	C689.301636,760.054199 689.909302,761.553955 689.521484,762.630432 
	C685.137085,774.799011 680.647095,786.931091 676.006226,799.003967 
	C675.338989,800.739746 674.032532,802.919922 672.494629,803.502686 
	C667.712402,805.314697 668.325806,808.188538 669.931152,811.951294 
	C668.820312,812.103455 667.879395,811.952393 667.005554,811.490112 
	C667.090881,810.764038 667.109070,810.349121 667.235352,809.543213 
	C666.146179,806.199890 664.023315,803.279785 663.919128,800.289307 
	C663.555786,789.857788 663.890503,779.401978 664.248535,768.796997 
	C666.456970,766.801270 668.415833,763.950378 669.681274,768.899414 
	C668.373047,769.944824 666.854309,770.829285 666.642761,771.958618 
	C665.053833,780.439026 663.884705,788.983459 666.758362,797.435486 
	C667.186401,798.694458 668.239990,800.488098 669.198486,800.619812 
	C670.262573,800.765991 672.136108,799.564636 672.644470,798.476807 
	C676.014587,791.264343 679.112793,783.924866 682.615417,775.903198 
	C680.345947,775.505432 679.134644,775.293152 677.620117,775.031372 
	C676.861694,774.387329 676.406494,773.792664 676.355530,773.120056 
	C681.028748,773.327881 682.395203,771.523438 681.452332,767.289673 
	C680.661804,763.740662 680.154602,760.045227 680.178467,756.418884 
	C680.210144,751.623962 677.905029,750.761902 673.670105,750.838318 
	C661.037231,751.066345 648.397461,750.911743 635.760376,750.909668 
	C626.910339,750.908264 626.913025,750.910950 625.791321,759.541504 
	C625.470276,762.011536 625.354248,764.535950 624.747803,766.935242 
	C623.557007,771.646240 625.516602,773.417419 629.976929,773.496948 
	C629.292725,774.212891 628.649475,774.618469 627.781738,774.747437 
	C623.011169,773.769348 622.135559,776.322266 623.504150,779.741882 
	C626.508728,787.249084 629.891113,794.605164 632.951111,801.636597 
	C638.073547,801.408813 639.652954,798.454224 639.856079,795.311707 
	C640.266846,788.954285 640.036804,782.552307 639.987610,776.169373 
	C639.943970,770.510010 638.625305,769.568665 633.015747,770.637451 
	C634.354309,765.636780 636.507324,761.827759 642.432190,761.186890 
	C642.432190,774.689270 642.473206,787.795471 642.363220,800.900452 
	C642.353210,802.096985 641.698853,803.876160 640.790771,804.372009 
	C637.444519,806.199158 638.692993,808.638245 639.433289,811.339844 
	C639.443848,811.572449 639.492554,812.034912 639.369629,812.244141 
	C639.163147,812.922302 639.079651,813.391296 638.696045,813.765625 
	C637.601501,813.430847 636.807129,813.190796 635.868774,812.717468 
	C635.426636,812.274597 635.128479,812.064941 634.947021,811.434937 
	C634.528137,808.665955 634.397095,806.114014 633.346741,804.024170 
	C632.225769,801.793518 629.698608,800.233093 628.717529,797.975037 
	C623.888184,786.859558 619.405029,775.594360 614.730164,764.411011 
	C608.946350,750.574524 608.915161,750.531921 593.970520,750.994812 
	C592.460815,751.041565 589.658569,752.451416 589.723511,752.900574 
	C590.030823,755.024658 591.126526,757.034729 592.005005,759.477539 
	C590.168945,765.259888 588.269043,770.650085 586.082642,776.091064 
	C584.768921,776.881775 583.741699,777.621704 582.644409,778.412170 
	C581.749573,777.252441 580.898621,776.149597 579.890930,774.707092 
	C578.280457,770.786011 576.826660,767.204651 575.703003,763.527344 
	C576.695557,763.606445 577.358032,763.781433 578.265808,764.080688 
	C578.984924,764.481934 579.458740,764.758789 579.953003,765.430054 
	C581.423462,766.885498 582.768677,768.230469 584.389465,768.828003 
	C584.852966,768.998840 586.114746,767.004150 587.026489,765.756348 
	C587.042419,765.510254 587.044495,765.017090 587.355530,764.841553 
	C590.388489,761.881592 588.241455,759.481873 587.057861,756.678345 
	C587.046326,755.914429 587.038269,755.477905 587.249634,754.882935 
	C587.322876,754.135193 587.176758,753.545898 587.037964,752.633301 
	C587.043518,751.879028 587.041748,751.447998 587.040039,751.017029 
	C587.040039,751.017029 587.086365,750.576782 587.118652,750.359070 
	C593.565125,749.760803 599.975891,749.294861 606.394958,749.032410 
	C610.119202,748.880066 612.031860,751.316223 613.213867,754.503235 
	C613.960388,756.515930 614.706909,758.528564 615.763184,761.136108 
	C617.458862,763.121216 618.844666,764.511414 620.230469,765.901611 
	C621.098450,764.325439 622.093323,762.803223 622.794189,761.156006 
	C623.356323,759.835144 623.561890,758.362610 623.925781,756.957458 
	C624.530273,750.131226 625.282227,749.402466 632.861389,749.293823 
	C646.163147,749.714966 658.755554,749.839844 671.348206,749.899841 
	C671.907104,749.902527 672.468750,749.315002 673.029175,749.002441 
z"/>
<path fill="#193ECF" opacity="1.000000" stroke="none" 
	d="
M613.986755,200.976089 
	C613.408936,201.899872 613.047852,203.219711 612.214172,203.676102 
	C609.248291,205.299744 606.121338,206.629349 602.871460,208.156754 
	C601.391541,208.011063 598.155273,207.017349 597.675659,207.773041 
	C596.287537,209.960373 595.493591,212.838791 595.430786,215.468994 
	C595.361694,218.363800 596.364563,221.284210 596.599243,224.749847 
	C594.653076,225.468552 593.008057,225.630890 591.761108,225.753952 
	C591.457703,222.978149 591.239563,220.982407 591.017456,218.660370 
	C591.025574,217.898788 591.037659,217.463531 591.407471,216.947540 
	C593.739746,212.803741 592.963989,208.571609 589.648987,206.971481 
	C588.887878,206.286850 588.438660,205.654694 587.964233,204.670685 
	C585.616089,202.556747 583.293152,200.794693 580.982056,199.017380 
	C580.993958,199.002121 580.976685,199.030045 580.837524,198.714127 
	C579.138550,197.593369 577.578796,196.788528 575.778931,195.966125 
	C575.538757,195.948547 575.058472,195.914200 574.999084,195.569489 
	C569.950073,192.532547 565.238586,188.853928 559.913269,187.351868 
	C548.556885,184.148666 537.171204,181.588959 524.895508,182.614548 
	C516.052795,183.353348 507.698547,185.282639 499.343170,187.561798 
	C495.978210,188.479691 493.084808,191.126251 489.744843,192.999176 
	C489.512909,193.012253 489.048889,193.035080 488.630432,193.022430 
	C482.791077,191.858704 480.674316,196.882462 476.636414,199.004364 
	C475.169891,198.659027 474.072754,198.315506 473.033081,197.630173 
	C473.739044,195.870453 474.387573,194.452515 475.270386,193.022827 
	C475.504639,193.011093 475.972778,192.980682 476.308655,192.853989 
	C479.107330,190.828827 481.570099,188.930359 484.267334,187.017883 
	C484.501770,187.003860 484.970795,186.977463 485.348206,186.983246 
	C486.365051,186.851913 487.106598,186.878693 487.628876,186.553436 
	C497.843689,180.191803 509.350281,177.645706 520.980164,176.141373 
	C528.224731,175.204285 535.687500,175.953903 543.375610,175.946716 
	C544.130005,175.942230 544.561096,175.937576 545.195618,176.239166 
	C552.725037,179.017960 560.255615,181.021210 567.316406,184.101456 
	C573.744812,186.905807 579.709900,190.799850 585.785645,194.372696 
	C588.641479,196.052078 591.277466,198.105179 594.306641,200.020844 
	C595.063293,200.372971 595.526367,200.693192 596.042542,201.400696 
	C599.598450,205.315918 605.624451,204.960724 609.251221,201.007202 
	C609.483337,200.988190 609.947021,200.942749 610.350952,200.959656 
	C611.832153,200.976395 612.909424,200.976242 613.986755,200.976089 
z"/>
<path fill="#1839BC" opacity="1.000000" stroke="none" 
	d="
M506.997559,800.997559 
	C505.088013,803.046997 502.844757,804.879395 501.340363,807.192322 
	C498.455719,811.627380 493.944489,812.949707 488.760529,814.668945 
	C486.098328,815.663025 484.043365,816.326599 481.988373,816.990234 
	C477.429352,817.658081 472.879944,818.811096 468.309448,818.901917 
	C455.149719,819.163574 441.978149,819.164185 428.818329,818.906067 
	C424.232117,818.816101 419.623871,817.871338 415.113586,816.906067 
	C413.404114,816.540100 411.942291,815.017029 410.367584,814.021423 
	C409.987427,814.410217 409.607269,814.799011 409.227112,815.187866 
	C409.227112,812.937927 409.227112,810.688049 409.227112,808.438171 
	C408.757690,808.306824 408.288269,808.175476 407.818817,808.044128 
	C406.926880,809.662292 405.673920,811.184509 405.213104,812.917297 
	C404.003235,817.466919 400.567383,818.853149 396.616333,818.926086 
	C385.954742,819.123047 375.286804,819.027771 364.622192,818.944702 
	C363.390930,818.935120 362.185944,818.094177 360.932220,817.944336 
	C357.293701,817.509460 354.277313,815.399536 349.920898,816.529114 
	C342.506561,818.451599 334.880951,816.419800 327.294556,813.732056 
	C329.027466,811.468201 331.017029,809.714111 333.241272,808.149597 
	C333.660065,808.904846 333.844177,809.470642 334.061005,810.410400 
	C337.610931,816.919617 345.750275,815.924866 351.061157,807.924072 
	C352.567474,809.202454 353.904022,810.525635 355.428925,811.575195 
	C356.830719,812.540161 358.435364,813.210510 360.207245,814.023560 
	C360.634430,814.046021 360.805023,814.055420 361.084137,814.452515 
	C361.708649,815.195496 362.221741,815.855957 362.741089,815.860901 
	C372.024200,815.949890 381.308563,816.007446 390.590790,815.902832 
	C391.408417,815.893616 392.212860,814.711487 393.442627,814.061035 
	C395.261902,814.038025 396.661987,814.028076 398.441833,814.000610 
	C399.463196,813.155518 400.104797,812.327942 400.763367,811.327271 
	C400.780334,811.154175 401.032379,810.914429 401.252838,810.836426 
	C401.740814,810.566284 401.910645,810.309448 401.993652,809.591797 
	C404.397278,799.319397 405.240509,798.742798 414.016907,801.243286 
	C414.035065,801.661194 414.037628,801.828552 413.741150,802.265015 
	C413.365997,805.456543 411.175659,808.789978 415.177582,811.095093 
	C415.279083,811.250793 415.172668,811.606995 415.227875,811.907715 
	C415.609711,812.427307 415.936340,812.646118 416.174011,812.884766 
	C416.085083,812.904724 416.085022,812.722412 416.294067,813.085449 
	C419.613525,813.763000 422.723938,814.077515 425.834381,814.392029 
	C425.828003,814.609619 425.821655,814.827209 425.815308,815.044800 
	C423.920654,815.044800 422.026031,815.044800 420.131409,815.044800 
	C420.170471,815.509949 420.209564,815.975098 420.248627,816.440247 
	C424.276703,815.826660 428.308990,814.682007 432.331909,814.714294 
	C439.812439,814.774170 447.295990,815.992432 454.762604,815.857788 
	C463.588196,815.698792 472.429016,814.874084 481.194550,813.784912 
	C483.923889,813.445740 486.429840,811.308960 489.402252,809.993774 
	C499.907684,805.841309 504.865540,797.569275 506.848083,787.425354 
	C507.707764,783.026611 507.533020,778.299072 507.046570,773.805542 
	C506.685699,770.471558 505.064240,767.274048 504.380096,764.015808 
	C509.835846,767.486328 510.794098,772.736084 509.839661,778.106445 
	C508.576233,785.215698 510.363403,792.708618 507.034882,799.526428 
	C506.839172,799.927368 507.001740,800.503113 506.997559,800.997559 
z"/>
<path fill="#1839BC" opacity="1.000000" stroke="none" 
	d="
M644.098267,167.199158 
	C647.547546,163.258362 651.119019,159.554977 654.658997,155.821838 
	C659.286743,150.941574 658.948853,143.276474 654.191895,138.637054 
	C632.126709,117.117111 605.864868,103.457909 576.139893,95.714645 
	C564.484741,92.678528 552.808350,91.475761 540.881470,90.895523 
	C523.473450,90.048630 506.094849,90.461037 489.140015,94.250801 
	C477.630066,96.823502 466.345123,100.932343 455.431915,105.486183 
	C445.847168,109.485710 436.919403,115.059639 427.346741,119.968597 
	C427.311523,118.924179 427.300781,117.259895 428.002167,116.867027 
	C434.072540,113.466896 440.246307,110.247284 446.440094,107.073120 
	C450.449524,105.018402 454.485596,102.994286 458.619415,101.210709 
	C461.767792,99.852310 465.281525,99.264236 468.299530,97.693352 
	C481.747864,90.693459 497.030975,91.589760 511.139557,87.492531 
	C512.544128,87.084625 514.092163,86.994781 515.566711,87.031609 
	C527.091248,87.319496 538.636047,87.355423 550.128357,88.143227 
	C555.882751,88.537689 561.526611,91.114342 567.247131,91.216850 
	C570.673279,91.278244 573.735779,91.126778 577.076050,92.406929 
	C585.386108,95.591797 594.188843,97.618256 602.242798,101.311424 
	C611.949829,105.762627 620.776245,112.172798 630.555725,116.417213 
	C641.321350,121.089638 648.719604,129.742188 657.487976,136.735779 
	C663.124756,141.231598 662.471985,151.483566 657.263672,156.472900 
	C653.343811,160.227936 649.543213,164.107437 645.344727,167.965866 
	C644.671936,167.895325 644.407410,167.703033 644.098267,167.199158 
z"/>
<path fill="#1739BF" opacity="1.000000" stroke="none" 
	d="
M815.011475,748.948242 
	C815.576904,748.456726 816.142395,747.965271 816.963562,747.012024 
	C817.421021,746.281494 817.681885,746.095642 818.001770,745.992676 
	C823.983337,750.336182 828.689880,746.912781 833.569153,743.635010 
	C834.660706,742.901794 836.437622,743.038452 837.900146,743.032104 
	C848.552124,742.986206 859.208740,742.821838 869.855042,743.071472 
	C876.700989,743.231995 883.358093,744.468140 889.309631,748.402344 
	C891.547852,749.881958 895.101562,749.899658 896.678162,751.754578 
	C901.636230,757.588135 906.436035,763.783691 903.156921,772.411316 
	C902.595642,773.888000 902.855530,775.676819 902.710693,777.670654 
	C907.258362,779.625977 908.274841,783.312439 909.443787,788.303223 
	C911.271973,796.108704 907.497009,801.870422 905.712341,808.433716 
	C905.626587,808.749146 905.511047,809.176880 905.272888,809.312500 
	C901.009460,811.739380 896.725037,814.129272 892.418152,815.977356 
	C892.922363,814.271118 893.453552,813.114563 894.188354,811.697998 
	C895.344604,810.769531 896.243958,810.003906 897.258240,809.447998 
	C905.577820,804.887695 909.484680,794.589539 904.460266,785.597595 
	C902.498901,782.087402 901.063354,778.150330 900.152405,774.230042 
	C899.471497,771.299927 900.183655,768.073914 899.981018,764.999084 
	C899.533447,758.208801 888.707886,748.379639 881.750793,748.327454 
	C881.506897,748.325623 881.260071,748.732605 880.544434,748.951843 
	C864.361938,748.965393 848.649597,748.976074 832.568420,748.990723 
	C831.538330,749.756409 830.877075,750.518188 830.195068,751.225342 
	C830.174255,751.170715 830.285889,751.205750 829.873718,751.187500 
	C828.986145,753.761292 828.510681,756.353210 827.881592,759.193726 
	C827.671204,759.988037 827.354736,760.797180 827.598877,761.037964 
	C829.589233,763.000977 830.032532,764.676453 826.841980,765.685669 
	C823.490540,762.855347 821.438843,763.597839 820.960022,767.596680 
	C820.472656,771.666931 820.356201,775.780273 819.978027,779.865540 
	C819.756836,782.255127 819.341309,784.626648 818.714111,786.713135 
	C818.277466,784.784790 817.916016,783.132324 818.040955,781.517517 
	C818.598938,774.305847 819.295715,767.105042 819.947144,759.510681 
	C819.974670,758.082397 819.997375,757.043640 820.348511,755.955139 
	C820.449707,754.603088 820.222412,753.300903 819.985596,751.763367 
	C819.976013,751.528076 819.981079,751.057129 819.852173,750.818298 
	C819.153687,750.710144 818.583984,750.840881 817.776306,750.994812 
	C817.538330,751.018005 817.060791,751.043335 817.019653,751.046143 
	C816.978455,751.048889 816.956604,750.969421 816.940186,750.648010 
	C816.286377,749.867188 815.648926,749.407715 815.011475,748.948242 
z"/>
<path fill="#173BC4" opacity="1.000000" stroke="none" 
	d="
M240.980026,448.979584 
	C239.879181,441.964752 241.281464,434.752289 244.800369,429.344452 
	C249.428268,422.232269 249.575699,414.287964 252.193756,406.890350 
	C252.524170,405.956726 252.822876,404.841644 253.510025,404.231628 
	C258.543884,399.762573 257.477356,393.723450 256.618744,388.449158 
	C256.234131,386.086487 252.182556,383.056305 249.411102,382.584564 
	C241.214493,381.189362 241.597672,374.970978 241.105469,369.411591 
	C240.623077,363.963409 240.935242,358.438324 241.014114,352.948639 
	C241.116058,345.854248 249.611221,339.070099 256.622375,340.315308 
	C258.725250,340.688812 260.877869,340.968597 263.008270,340.972260 
	C288.299194,341.015656 313.594147,340.758789 338.878479,341.169159 
	C345.062592,341.269562 351.212067,343.502930 357.377472,344.755615 
	C357.350372,345.309113 357.323242,345.862610 357.296143,346.416107 
	C356.034393,346.751038 354.772675,347.085968 353.255432,347.712036 
	C352.999969,348.003174 352.935455,348.063629 352.537415,348.051208 
	C349.754242,347.726166 347.369080,347.413513 344.992889,346.825439 
	C345.001221,346.366241 345.000610,346.182526 344.946777,345.605896 
	C343.576294,344.475708 342.262085,343.101501 340.941376,343.095276 
	C314.639130,342.970428 288.335724,342.948853 262.034149,343.132721 
	C260.346771,343.144501 258.672211,344.992371 256.630859,345.985474 
	C254.996017,345.872803 253.479706,345.254547 252.486649,345.729767 
	C245.253647,349.191010 241.800110,358.694794 243.670105,366.800140 
	C245.182358,373.354980 249.171783,377.669952 254.326767,381.066895 
	C260.261597,384.977692 261.935364,390.231079 260.195862,396.695648 
	C257.975739,404.946259 256.742950,413.409943 252.966599,421.320160 
	C249.068970,429.484436 246.441666,438.252075 243.154083,446.715149 
	C242.801315,447.623291 241.751785,448.260742 241.014160,449.013062 
	C241.002853,449.002350 240.980026,448.979584 240.980026,448.979584 
z"/>
<path fill="#193ECF" opacity="1.000000" stroke="none" 
	d="
M507.302429,800.846558 
	C507.001740,800.503113 506.839172,799.927368 507.034882,799.526428 
	C510.363403,792.708618 508.576233,785.215698 509.839661,778.106445 
	C510.794098,772.736084 509.835846,767.486328 504.375427,764.009155 
	C503.994965,764.002502 503.984314,763.994873 503.985046,763.600159 
	C503.651917,761.136169 503.318024,759.066833 502.990051,756.998291 
	C502.996002,756.999084 503.008545,757.000000 503.008545,757.000000 
	C504.012939,758.160828 504.856201,759.543091 506.064972,760.423157 
	C507.394470,761.390930 509.431244,762.799316 510.482513,762.367310 
	C511.702271,761.866150 512.600037,759.646545 512.887268,758.038818 
	C513.278870,755.847290 513.240112,753.485291 512.893860,751.273438 
	C511.933563,745.139587 515.313965,744.314758 520.776123,745.259216 
	C522.321228,745.814758 523.218262,746.113770 523.664185,746.262329 
	C521.470642,747.536621 518.865723,748.606628 516.825989,750.341309 
	C513.952759,752.784912 514.232422,757.351440 516.993896,760.410767 
	C516.954163,762.916504 516.950012,765.021240 516.861206,767.122375 
	C516.331421,779.663513 516.159668,792.236328 515.082825,804.730896 
	C514.530457,811.139587 516.658142,815.348450 523.126709,815.952087 
	C527.654053,816.374634 532.253296,816.651123 536.777283,816.348206 
	C544.875244,815.806030 552.940613,814.777771 561.341736,813.961914 
	C562.093018,813.970459 562.522217,813.969788 563.330750,813.981079 
	C564.806763,814.663696 565.903381,815.334290 567.000000,816.004883 
	C558.955994,820.031616 550.351746,820.460999 541.595642,820.011597 
	C534.983459,819.672241 528.364441,819.131409 521.753052,819.191650 
	C512.694580,819.274231 509.629120,809.108276 511.398193,802.318176 
	C511.880402,800.467407 512.509460,798.654968 513.071106,796.824951 
	C512.682312,796.572815 512.293457,796.320679 511.904602,796.068542 
	C510.472168,797.610840 509.039734,799.153198 507.302429,800.846558 
z"/>
<path fill="#1839BC" opacity="1.000000" stroke="none" 
	d="
M498.003113,251.001984 
	C505.050995,240.207764 513.394836,231.789139 527.978027,234.569427 
	C527.352051,234.221130 526.726135,233.872833 525.246765,233.049652 
	C539.299988,232.720337 550.451050,235.643158 559.412170,246.826736 
	C569.737854,259.713226 569.033630,273.569427 563.443604,286.736237 
	C558.333496,298.772675 545.238647,309.624695 527.681213,306.136749 
	C524.451294,305.495117 521.203918,304.765137 517.932739,304.541779 
	C508.571655,303.902679 502.792358,297.800110 498.209106,290.836243 
	C493.277100,283.342438 489.567291,266.252014 496.654846,257.512512 
	C496.756958,260.534882 496.053619,263.210754 496.319122,265.786713 
	C497.039246,272.772888 496.907043,280.216553 499.419495,286.566101 
	C503.733582,297.468719 513.277649,302.559052 524.735352,303.927399 
	C535.028564,305.156677 544.913391,303.200195 553.049072,296.976532 
	C562.385376,289.834442 566.823242,270.351715 562.699097,258.758514 
	C554.300598,235.150284 530.866089,232.492065 512.222900,240.550385 
	C507.504425,242.589905 504.005188,247.450134 499.621521,251.012115 
	C498.863678,251.005463 498.429993,251.000900 497.998169,250.998169 
	C498.000000,251.000000 498.003113,251.001984 498.003113,251.001984 
z"/>
<path fill="#1839BC" opacity="1.000000" stroke="none" 
	d="
M591.021362,218.986664 
	C591.239563,220.982407 591.457703,222.978149 591.761108,225.753952 
	C593.008057,225.630890 594.653076,225.468552 596.649048,225.153107 
	C601.322144,224.998978 605.680420,225.312820 609.946655,224.824066 
	C611.415466,224.655792 613.829773,222.461441 613.804688,221.233673 
	C613.661499,214.226990 618.875977,210.657913 622.702148,206.198608 
	C624.047974,204.630066 625.648315,203.271423 627.190186,201.881027 
	C634.300903,195.469040 641.257812,188.865143 648.681274,182.836685 
	C650.480957,181.375153 653.843079,181.837585 656.499146,181.985901 
	C652.120544,183.099884 648.430725,184.672195 645.234802,188.070557 
	C640.494568,193.111069 635.047485,197.479279 630.169128,202.399246 
	C623.138672,209.489655 614.694153,215.965912 616.502808,227.938934 
	C609.117554,227.977463 602.193665,227.882019 595.279663,228.099823 
	C593.848450,228.144897 592.453918,229.354462 590.749634,230.173676 
	C589.867249,230.929764 589.277710,231.539551 588.311951,232.223663 
	C587.617676,233.841675 587.036865,235.383255 587.024414,236.929443 
	C586.942566,247.070282 586.750427,257.219604 587.076416,267.350647 
	C587.256348,272.941620 585.274231,276.225281 579.333862,277.254883 
	C576.489746,277.399902 573.512329,276.312775 572.341431,280.158020 
	C570.135803,284.058685 570.816772,287.026733 574.094543,290.031738 
	C576.835022,292.544159 578.721680,295.987976 580.986389,299.019348 
	C573.909363,296.613403 567.139587,288.332855 568.471069,281.754852 
	C569.068420,278.803406 573.299438,274.451141 575.892517,274.443512 
	C583.446167,274.421204 583.230957,268.713959 583.738586,264.693207 
	C584.605957,257.822601 583.863892,250.759155 583.998413,243.780594 
	C584.052979,240.950134 584.759155,238.136368 584.906860,235.301346 
	C584.958557,234.308258 584.225464,233.274292 583.691223,231.826767 
	C580.622009,233.968292 577.859619,235.687805 575.338013,237.707779 
	C572.181763,240.236099 572.343445,240.151535 568.032471,238.431366 
	C566.908142,237.982727 565.147095,239.129807 563.311096,239.647614 
	C563.555908,238.488602 563.746765,237.585175 563.980286,236.324280 
	C564.022949,235.966827 564.058044,235.920746 564.501282,235.941284 
	C570.908691,237.245972 575.605469,234.799789 579.652344,230.774597 
	C583.521973,226.925674 587.237854,222.922195 591.021362,218.986664 
M584.410706,230.484512 
	C584.410706,230.484512 584.524780,230.415146 584.410706,230.484512 
z"/>
<path fill="#1739BF" opacity="1.000000" stroke="none" 
	d="
M234.962982,710.959045 
	C234.030975,712.436218 233.389206,714.812073 232.113220,715.224182 
	C228.091217,716.523071 223.846100,717.695801 219.660141,717.797424 
	C212.398163,717.973694 205.117371,717.094604 197.841721,717.042603 
	C179.717392,716.913147 161.589783,717.133789 143.467545,716.899475 
	C139.295746,716.845520 135.128372,715.586426 130.989380,714.734192 
	C130.327118,714.597839 129.422699,713.751038 129.311737,713.113892 
	C128.131592,706.337830 126.716881,699.565857 126.201614,692.729797 
	C126.009415,690.179993 128.006180,687.465088 129.360519,684.376465 
	C131.820709,682.958069 133.925201,681.988770 135.984070,681.371948 
	C130.127625,688.591797 129.836151,696.560425 131.969086,704.676331 
	C132.431900,706.437378 135.246231,707.580383 136.993805,709.010742 
	C137.010147,709.013306 137.039276,709.029114 137.185944,709.277344 
	C139.353546,712.670227 142.567902,712.977905 145.830490,712.981262 
	C170.931915,713.006836 196.033615,713.041260 221.134384,712.908997 
	C223.087814,712.898743 225.034683,711.642151 227.379211,710.964172 
	C228.821716,710.932495 229.869629,710.901428 231.321655,710.903076 
	C232.804855,710.943481 233.883926,710.951233 234.962982,710.959045 
z"/>
<path fill="#1739BF" opacity="1.000000" stroke="none" 
	d="
M885.696167,660.173340 
	C885.479614,658.042297 885.074646,655.911255 885.073669,653.780151 
	C885.035645,570.561951 885.031677,487.343750 885.044678,404.125549 
	C885.044983,401.971344 885.283325,399.817230 885.614990,397.293304 
	C886.211975,398.616577 886.947021,400.309387 886.948120,402.002655 
	C887.003601,486.727295 886.998718,571.451965 886.974854,656.176636 
	C886.974487,657.643555 886.662659,659.110291 886.181702,660.880737 
	C885.867249,661.184387 885.891479,661.158752 885.909668,660.978516 
	C885.918457,660.564758 885.841187,660.356445 885.696167,660.173340 
z"/>
<path fill="#253C91" opacity="1.000000" stroke="none" 
	d="
M499.945740,251.014221 
	C504.005188,247.450134 507.504425,242.589905 512.222900,240.550385 
	C530.866089,232.492065 554.300598,235.150284 562.699097,258.758514 
	C566.823242,270.351715 562.385376,289.834442 553.049072,296.976532 
	C544.913391,303.200195 535.028564,305.156677 524.735352,303.927399 
	C513.277649,302.559052 503.733582,297.468719 499.419495,286.566101 
	C496.907043,280.216553 497.039246,272.772888 496.319122,265.786713 
	C496.053619,263.210754 496.756958,260.534882 497.018616,257.455688 
	C497.043976,256.585541 497.069183,256.164246 497.413086,255.351700 
	C498.151215,254.301910 498.570648,253.643402 499.223328,252.813553 
	C499.619629,252.099564 499.782715,251.556900 499.945740,251.014221 
M552.069702,246.410706 
	C543.983826,238.609497 533.906982,238.539886 523.624817,238.884644 
	C517.959900,239.074585 513.406006,241.470047 509.193512,244.892197 
	C500.384277,252.048706 497.891205,261.670135 498.318512,272.500397 
	C498.821960,285.260010 505.729828,295.451813 516.815735,299.538605 
	C529.872498,304.351898 544.947754,301.092682 553.460571,292.479156 
	C563.353882,282.468842 564.556091,258.004303 552.069702,246.410706 
z"/>
<path fill="#173BC4" opacity="1.000000" stroke="none" 
	d="
M193.017441,576.010559 
	C192.564896,567.266052 196.206421,559.848389 200.320953,552.481934 
	C201.730301,549.958679 202.030502,546.836975 203.026459,544.056458 
	C204.343323,540.379883 205.861954,536.776062 207.263870,533.129395 
	C208.480209,529.965454 209.665939,526.789551 210.845291,523.611572 
	C212.914795,518.034973 214.290268,512.064270 217.204269,506.972748 
	C220.722153,500.826111 218.584351,493.244080 224.186478,487.320282 
	C227.825272,483.472595 228.049698,476.462738 230.016403,470.945404 
	C231.535828,466.682983 233.455719,462.564819 235.058594,458.329926 
	C235.867859,456.191833 236.134247,453.826263 237.100876,451.778229 
	C237.645172,450.625000 239.114471,449.908386 240.573547,448.987335 
	C240.980026,448.979584 241.002853,449.002350 241.009064,449.439148 
	C240.011780,453.401184 239.179153,456.986053 237.972641,460.440399 
	C234.409973,470.640533 230.853455,480.847900 226.998032,490.939148 
	C223.909760,499.022400 219.415222,506.698608 217.353287,515.004395 
	C215.563004,522.216003 211.932892,528.431641 209.547882,535.242310 
	C207.499542,541.091553 205.828659,547.073059 203.683380,553.156372 
	C202.588806,555.216064 201.801727,557.115479 201.014771,559.346008 
	C201.015106,560.118652 201.015305,560.560120 200.660797,561.056641 
	C199.212112,563.748840 198.118134,566.385925 197.021332,569.437195 
	C197.007523,571.230957 196.996521,572.610596 196.657898,574.038879 
	C195.226013,574.728516 194.121735,575.369568 193.017441,576.010559 
z"/>
<path fill="#1B359A" opacity="1.000000" stroke="none" 
	d="
M223.334244,816.001099 
	C223.373367,815.488037 223.737152,814.981506 224.500443,813.918640 
	C217.787003,815.516968 216.635239,811.484985 215.915344,806.653809 
	C215.446014,803.504211 214.453613,800.393188 209.469299,800.148987 
	C208.426071,803.247253 207.329391,806.571655 206.181488,809.878235 
	C205.761383,811.088318 205.236176,813.279907 204.730804,813.288086 
	C198.207504,813.393738 191.490189,819.034851 185.579956,812.416016 
	C190.814102,812.063538 195.645935,812.347168 200.431259,811.996460 
	C201.805115,811.895752 203.044678,809.962891 204.525497,808.681030 
	C204.578384,807.705994 204.451614,806.919922 204.196457,805.807373 
	C203.914001,805.039612 203.759933,804.598328 203.948975,803.954712 
	C204.988861,798.914001 207.889633,798.078186 212.497009,797.749573 
	C218.002518,797.356873 217.447983,801.743225 219.304855,804.471191 
	C219.133896,805.169250 219.071716,805.572449 218.880890,806.406494 
	C218.173553,810.494690 219.192795,812.264893 223.436050,812.208557 
	C239.163040,811.999695 254.894730,812.165039 270.623932,812.070740 
	C272.336273,812.060486 274.044037,811.279053 276.098267,810.659546 
	C276.430817,808.043030 277.430817,804.564697 276.215454,803.402039 
	C272.714264,800.052551 274.212280,796.225037 274.185791,792.637756 
	C274.177551,791.520264 276.242218,789.740662 277.605133,789.458557 
	C278.754181,789.220764 281.378876,790.656677 281.419128,791.449707 
	C281.606445,795.141174 282.091644,799.323425 280.615173,802.455688 
	C279.039307,805.798828 278.438171,808.156128 281.077087,811.133789 
	C281.513550,811.580872 281.767609,811.831482 282.197205,812.320679 
	C282.932709,812.734436 283.492706,812.909546 284.025818,813.319702 
	C283.998871,813.554688 283.956116,814.025818 283.561218,814.023193 
	C279.780243,814.042542 276.373566,813.837402 273.013214,814.142456 
	C268.507263,814.551453 264.047791,815.793884 259.549500,815.903259 
	C248.024261,816.183655 236.488632,816.035461 224.957153,816.058594 
	C224.524399,816.041626 224.091644,816.024658 223.334244,816.001099 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M737.000366,360.000183 
	C737.288818,356.234589 736.717773,352.125885 738.054138,348.778717 
	C740.438477,342.806885 746.771240,340.141541 753.598083,340.911224 
	C755.244629,341.096863 756.925415,340.997742 758.590454,340.997833 
	C802.234863,341.000671 845.879517,340.940125 889.523376,341.094025 
	C893.695862,341.108704 897.915405,342.260101 901.993591,343.336517 
	C902.907410,343.577698 903.318359,345.723816 902.943298,347.414642 
	C904.565735,347.964539 906.188110,348.514404 907.447021,349.491516 
	C904.074707,349.872955 901.065796,349.827148 898.229248,349.577057 
	C896.937561,348.244293 895.473694,347.115814 893.929199,345.609833 
	C890.905823,344.487640 887.965088,343.103790 885.019958,343.094269 
	C842.243958,342.955933 799.467224,342.943451 756.691223,343.078857 
	C751.490601,343.095306 746.640930,344.777527 743.729675,350.161194 
	C742.637634,351.554108 741.802551,352.766785 740.630005,354.041870 
	C740.185425,355.402466 740.078247,356.700623 739.974731,358.329346 
	C739.976685,359.100372 739.975037,359.540894 739.602661,359.990417 
	C738.488037,359.999695 737.744202,359.999939 737.000366,360.000183 
z"/>
<path fill="#1839BC" opacity="1.000000" stroke="none" 
	d="
M660.936890,183.047424 
	C666.536987,183.267258 669.164734,187.977737 672.713135,191.123062 
	C676.478455,194.460602 679.720886,198.391968 683.154968,202.098083 
	C687.618896,206.915741 692.665588,211.367813 696.174011,216.807571 
	C697.508667,218.876892 695.833923,223.514374 694.436646,226.462936 
	C693.699890,228.017700 690.618469,229.234146 688.578491,229.260315 
	C681.096985,229.356247 680.008850,230.211700 680.005798,237.855103 
	C680.001892,247.588882 680.083435,257.323517 679.978882,267.056183 
	C679.880981,276.168854 675.749268,279.345367 666.426331,278.364777 
	C660.400635,277.730927 654.147949,276.696686 648.331970,280.613831 
	C646.313232,276.354065 649.164917,276.123260 651.689697,276.052429 
	C656.998230,275.903534 662.319580,276.135010 667.622009,275.900299 
	C669.127197,275.833649 670.585083,274.696930 672.263184,273.950775 
	C672.721130,273.673004 672.880859,273.429840 673.166687,273.038940 
	C673.392578,272.956757 673.868469,272.889099 674.288208,272.914764 
	C675.469543,271.765411 676.815857,270.624969 676.887756,269.409149 
	C677.150452,264.969330 676.988220,260.504425 676.999878,255.577393 
	C677.020081,248.301865 677.101196,241.496811 677.004883,234.694305 
	C676.945496,230.496948 678.550598,228.011948 683.379089,227.953888 
	C685.829956,227.004883 687.904724,226.033936 690.010132,225.017090 
	C690.040833,224.971191 690.143188,225.012863 690.538086,224.989716 
	C691.610168,224.205643 692.706970,223.535675 692.894836,222.668716 
	C693.940063,217.844940 691.337769,214.353180 688.188354,211.231232 
	C680.974182,204.080048 673.627319,197.062164 666.456177,189.868698 
	C664.402283,187.808395 662.765137,185.332657 660.936890,183.047424 
z"/>
<path fill="#253C91" opacity="1.000000" stroke="none" 
	d="
M672.064697,274.050964 
	C670.585083,274.696930 669.127197,275.833649 667.622009,275.900299 
	C662.319580,276.135010 656.998230,275.903534 651.689697,276.052429 
	C649.164917,276.123260 646.313232,276.354065 647.964722,280.660187 
	C648.009033,282.079803 647.998596,283.138855 647.706909,284.796570 
	C646.062195,287.645691 645.038574,290.221069 643.263611,292.078552 
	C639.658264,295.851562 635.552612,299.141022 631.830017,302.809265 
	C628.342712,306.245605 625.113098,309.941895 621.716736,313.472534 
	C617.703125,317.644775 613.757324,322.004333 606.973999,320.039215 
	C604.348206,319.278534 601.683350,318.652618 599.036316,317.965302 
	C599.036316,317.965302 599.000000,318.000000 598.989502,317.662781 
	C598.312500,316.555817 597.646057,315.786072 596.979614,315.016327 
	C596.875854,314.696564 596.688782,314.436768 596.179565,313.776276 
	C591.961609,309.539032 587.982483,305.762512 584.003296,301.985962 
	C583.183167,301.084930 582.363037,300.183929 581.264648,299.151123 
	C578.721680,295.987976 576.835022,292.544159 574.094543,290.031738 
	C570.816772,287.026733 570.135803,284.058685 572.457031,280.548889 
	C573.836060,283.377930 574.067261,286.540558 575.756836,288.313385 
	C584.674500,297.670929 593.960144,306.677826 603.117249,315.807190 
	C606.732910,319.411896 611.240784,319.447357 614.873779,315.833923 
	C624.051086,306.706116 633.169556,297.518768 642.390503,288.435425 
	C645.571655,285.301819 645.910034,281.946411 643.943726,277.746826 
	C645.886353,276.237946 647.743774,274.272797 649.693665,274.176300 
	C657.134583,273.808105 664.605164,274.041992 672.064697,274.050964 
z"/>
<path fill="#1430A3" opacity="1.000000" stroke="none" 
	d="
M900.445923,711.039062 
	C897.273621,711.678101 894.517700,712.299683 890.914795,712.960205 
	C846.154724,712.999451 802.241577,713.019409 758.328491,712.958435 
	C755.214905,712.954163 752.102173,712.355896 748.989014,712.033875 
	C747.924316,711.554199 746.859619,711.074585 745.908203,710.286621 
	C746.021484,709.978271 746.060547,709.939148 746.515991,709.949585 
	C752.080505,710.300903 757.189087,710.927124 762.298828,710.937256 
	C803.000122,711.018372 843.701904,711.055054 884.402527,710.873962 
	C888.609436,710.855286 892.809631,709.326538 897.012939,708.498474 
	C900.044006,708.430481 903.075073,708.362488 906.548584,708.412415 
	C906.407288,712.950317 902.894348,710.164551 900.445923,711.039062 
z"/>
<path fill="#173BC4" opacity="1.000000" stroke="none" 
	d="
M400.958313,746.934875 
	C401.001099,746.774658 400.990173,746.546753 401.094452,746.464050 
	C404.906616,743.440857 420.461517,744.333435 423.055847,748.218933 
	C426.150818,752.854370 428.501129,757.993286 431.093658,762.956055 
	C433.416351,767.402283 435.629974,771.905579 438.163544,776.921875 
	C440.202789,770.887024 438.417358,765.379395 437.414978,759.935181 
	C436.171509,753.182068 437.003387,750.082031 441.638855,748.042603 
	C442.016449,748.013428 442.045135,748.036438 441.985474,748.425659 
	C441.920135,751.562256 441.914490,754.309631 442.181274,757.061401 
	C442.635132,757.055908 442.816528,757.045898 443.144409,757.211426 
	C443.290924,757.387024 443.313324,757.843811 442.989075,758.018066 
	C442.483032,759.117615 442.301208,760.042786 441.824341,761.186523 
	C440.204834,764.625793 441.741699,765.882996 444.803772,766.563171 
	C444.558563,770.999329 444.274689,774.992371 443.598755,778.999939 
	C441.934601,781.351807 440.662506,783.689087 439.076385,786.603271 
	C438.286987,784.959839 437.786743,783.296204 436.797455,782.008179 
	C435.398529,780.186768 433.638367,778.642822 431.759949,776.718994 
	C431.295898,774.666809 431.104584,772.875854 430.908661,770.700928 
	C429.552734,766.515442 428.403656,762.622253 426.741333,758.962158 
	C426.153656,757.668152 424.313446,756.942993 423.021088,755.629395 
	C422.985077,754.861694 422.973083,754.422607 423.140137,753.904785 
	C423.188660,753.567078 423.058075,753.308167 422.670166,752.839661 
	C420.607147,751.099792 418.801514,749.569519 416.715576,747.728516 
	C411.276276,747.256836 406.117279,747.095825 400.958313,746.934875 
M432.750793,771.824158 
	C432.583588,771.879883 432.416382,771.935669 432.249207,771.991455 
	C432.417419,772.045837 432.585602,772.100220 432.750793,771.824158 
z"/>
<path fill="#173BC4" opacity="1.000000" stroke="none" 
	d="
M636.012695,812.950745 
	C636.807129,813.190796 637.601501,813.430847 639.026245,813.815674 
	C640.100342,813.986511 640.544128,814.012573 641.077271,814.408691 
	C641.995056,815.174927 642.820129,815.905762 643.652588,815.914185 
	C650.357849,815.982117 657.067627,815.778931 663.769531,815.937561 
	C667.853821,816.034241 671.926392,816.624268 676.004272,816.992676 
	C674.557617,817.660889 673.123352,818.874268 671.662292,818.907410 
	C663.377319,819.095459 654.872986,820.163269 646.852844,818.694946 
	C637.750366,817.028381 629.074951,819.152710 620.221558,819.204712 
	C611.697998,819.254761 604.515625,817.178833 598.482971,811.232239 
	C594.412109,811.343933 595.270508,819.673157 589.280273,815.893921 
	C592.819336,813.280334 594.309692,810.320068 594.176208,805.998108 
	C594.127136,804.406555 596.201416,802.749512 597.307129,801.122314 
	C598.839905,802.478577 600.474792,803.741638 601.846863,805.244568 
	C602.292419,805.732422 601.836365,806.945557 602.161560,807.658630 
	C603.401245,810.376648 603.535950,814.178650 608.203369,814.037659 
	C608.622620,814.055420 608.790100,814.059937 609.070435,814.448242 
	C614.706238,815.294067 620.226013,816.027771 625.753723,816.093018 
	C628.172302,816.121582 630.606995,814.784119 633.374390,814.055115 
	C634.480469,813.679016 635.246582,813.314880 636.012695,812.950745 
M637.418091,815.479065 
	C637.418091,815.479065 637.518188,815.421570 637.418091,815.479065 
z"/>
<path fill="#1739BF" opacity="1.000000" stroke="none" 
	d="
M224.986069,816.468262 
	C236.488632,816.035461 248.024261,816.183655 259.549500,815.903259 
	C264.047791,815.793884 268.507263,814.551453 273.013214,814.142456 
	C276.373566,813.837402 279.780243,814.042542 283.895966,814.033081 
	C285.071014,814.055725 285.516357,814.065735 286.014587,814.416992 
	C286.601837,815.304443 287.123413,816.311707 287.672577,816.327026 
	C295.083191,816.532959 302.499420,816.706787 309.909149,816.583252 
	C310.963104,816.565674 311.990234,814.938904 313.356079,814.046509 
	C314.117645,814.035217 314.552917,814.034302 315.068359,814.396118 
	C317.336761,816.425110 319.433258,817.324951 321.358276,814.044434 
	C322.123016,814.018188 322.559937,814.002075 323.372925,813.994751 
	C324.501312,814.000732 325.253723,813.997925 326.006104,813.995117 
	C325.052673,818.666626 320.566925,818.676331 317.704926,818.765076 
	C307.770020,819.073242 297.805695,818.223267 287.852051,818.211853 
	C281.487732,818.204529 275.243439,817.720459 268.687225,818.519165 
	C255.554977,820.119141 242.090515,819.054077 228.765198,818.906677 
	C227.507812,818.892761 226.264725,817.585022 224.986069,816.468262 
z"/>
<path fill="#253C91" opacity="1.000000" stroke="none" 
	d="
M660.624023,182.904678 
	C662.765137,185.332657 664.402283,187.808395 666.456177,189.868698 
	C673.627319,197.062164 680.974182,204.080048 688.188354,211.231232 
	C691.337769,214.353180 693.940063,217.844940 692.894836,222.668716 
	C692.706970,223.535675 691.610168,224.205643 690.722900,224.656097 
	C689.906555,221.425034 690.271790,217.595337 688.531677,215.735565 
	C679.239868,205.804764 669.446106,196.341888 659.771484,186.772186 
	C656.846558,183.878983 652.915955,183.845840 650.352661,186.279099 
	C639.993225,196.113083 629.987488,206.324570 620.001404,216.543503 
	C617.489075,219.114410 618.215210,223.183929 621.169556,225.487976 
	C621.514587,229.866882 618.408447,227.190552 616.967773,227.916077 
	C614.694153,215.965912 623.138672,209.489655 630.169128,202.399246 
	C635.047485,197.479279 640.494568,193.111069 645.234802,188.070557 
	C648.430725,184.672195 652.120544,183.099884 656.758179,182.275742 
	C658.106995,182.250641 659.209045,182.506287 660.624023,182.904678 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M594.807617,1015.308472 
	C598.477112,1015.584290 601.804871,1015.794800 605.587524,1016.026611 
	C604.837952,1016.863342 603.633484,1017.678650 602.211792,1018.766968 
	C601.254578,1019.039551 600.514771,1019.039124 599.406128,1019.057251 
	C588.378296,1019.988953 577.732605,1021.144470 567.055725,1021.737244 
	C558.788696,1022.196228 550.481201,1021.890991 542.194824,1022.069092 
	C540.511536,1022.105225 538.842102,1022.782715 537.051514,1023.550293 
	C535.905945,1023.938416 534.875122,1023.940857 533.458923,1023.933167 
	C531.774597,1023.286011 530.503784,1022.179565 529.171387,1022.099487 
	C525.099915,1021.855042 521.004822,1022.005493 516.449097,1022.019897 
	C515.539062,1022.031250 515.098694,1022.025024 514.328003,1022.029541 
	C513.972534,1021.328918 513.947510,1020.617554 514.383911,1019.487671 
	C535.548767,1018.730286 556.252197,1018.391357 577.059937,1018.212646 
	C577.455750,1018.264648 577.747253,1018.156311 578.479614,1018.043762 
	C583.651611,1017.191833 588.382629,1016.344116 593.113708,1015.496338 
	C593.564392,1015.411987 594.015076,1015.327576 594.807617,1015.308472 
z"/>
<path fill="#1433AA" opacity="1.000000" stroke="none" 
	d="
M516.990356,760.007996 
	C514.232422,757.351440 513.952759,752.784912 516.825989,750.341309 
	C518.865723,748.606628 521.470642,747.536621 523.664185,746.262329 
	C523.218262,746.113770 522.321228,745.814758 521.226196,745.263916 
	C531.022522,745.009888 541.021545,744.819458 551.009766,745.065674 
	C558.897644,745.260071 565.973755,748.259766 572.381042,752.753418 
	C573.694336,753.674561 574.614929,755.155640 575.895142,757.047119 
	C576.722595,759.795959 577.371521,761.876160 578.020447,763.956421 
	C577.358032,763.781433 576.695557,763.606445 575.707031,763.487305 
	C575.380859,763.543091 575.404358,763.466553 575.560059,763.103027 
	C575.037292,762.249023 574.358704,761.758484 573.765564,761.185059 
	C573.850952,761.102173 573.729187,761.306702 573.788391,760.937134 
	C573.129272,760.133057 572.411011,759.698547 571.790771,759.204895 
	C571.888794,759.145813 571.733337,759.313904 571.804382,758.942505 
	C568.946350,752.755737 562.861816,750.862061 557.679504,750.275879 
	C546.090698,748.965027 534.308899,749.341370 522.603882,749.135925 
	C522.100220,749.127136 521.578125,750.170349 520.750244,750.865601 
	C519.565796,751.696289 518.695740,752.384949 517.567871,753.353943 
	C517.242432,755.730042 517.174744,757.825684 517.077881,759.943054 
	C517.048706,759.964722 516.990356,760.007996 516.990356,760.007996 
z"/>
<path fill="#223A9A" opacity="1.000000" stroke="none" 
	d="
M676.378235,816.991455 
	C671.926392,816.624268 667.853821,816.034241 663.769531,815.937561 
	C657.067627,815.778931 650.357849,815.982117 643.652588,815.914185 
	C642.820129,815.905762 641.995056,815.174927 641.546875,814.390747 
	C649.544006,814.002747 657.160828,814.002747 664.777649,814.002747 
	C664.771301,813.789246 664.764954,813.575684 664.758606,813.362183 
	C660.171570,813.026062 655.584595,812.689941 651.453247,812.253418 
	C656.918762,812.035767 661.928589,811.918579 666.938477,811.801331 
	C667.879395,811.952393 668.820312,812.103455 670.382385,812.068848 
	C676.887939,811.883179 682.772278,811.883179 689.305908,811.883179 
	C689.050293,808.946960 688.842529,806.560059 688.959595,803.975769 
	C689.521484,803.354065 689.847534,802.956116 689.981750,802.501526 
	C691.204834,798.359131 694.278992,797.181946 698.010254,798.120178 
	C701.590210,799.020447 703.347595,801.358826 702.141418,805.423096 
	C701.865601,806.352173 703.128662,807.737976 703.777832,809.304810 
	C705.553955,810.589233 707.235718,811.482483 708.675964,812.579834 
	C707.291870,813.184082 706.149231,813.584167 704.637085,813.992310 
	C703.530518,813.984802 702.793335,813.969299 702.056213,813.953796 
	C702.056213,813.953796 702.004883,813.999878 702.011047,813.582153 
	C701.098083,809.379639 700.179016,805.594727 699.216309,801.630066 
	C694.367371,800.171082 693.106384,800.909058 692.140259,806.178406 
	C691.962952,807.145325 691.970703,808.160095 692.003662,809.149048 
	C692.087646,811.676208 691.699097,813.582764 688.574463,814.170349 
	C685.320557,814.782288 682.184021,816.018738 678.996216,816.982056 
	C678.248230,816.984741 677.500183,816.987427 676.378235,816.991455 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M401.888550,448.792084 
	C395.921692,435.315063 389.929108,421.454681 384.098541,407.526459 
	C376.326874,388.961273 368.681152,370.343384 360.914001,351.176147 
	C360.846710,350.604187 360.832581,350.167389 360.874451,350.124847 
	C360.916290,350.082306 360.998199,349.995514 360.998199,349.995514 
	C362.642639,352.411133 364.753845,354.632324 365.854340,357.274506 
	C372.069092,372.195374 378.037506,387.218781 384.097198,402.204285 
	C389.738953,416.156311 395.409790,430.096649 401.004730,444.067383 
	C401.543549,445.412811 401.635529,446.937195 401.888550,448.792084 
z"/>
<path fill="#1839BC" opacity="1.000000" stroke="none" 
	d="
M599.029663,318.344482 
	C601.683350,318.652618 604.348206,319.278534 606.973999,320.039215 
	C613.757324,322.004333 617.703125,317.644775 621.716736,313.472534 
	C625.113098,309.941895 628.342712,306.245605 631.830017,302.809265 
	C635.552612,299.141022 639.658264,295.851562 643.263611,292.078552 
	C645.038574,290.221069 646.062195,287.645691 647.697632,285.194031 
	C650.051147,289.313599 648.980591,292.398834 645.222351,295.608826 
	C637.683472,302.047974 630.778564,309.223816 623.369690,315.823639 
	C620.076050,318.757599 616.387939,321.382141 612.527222,323.508759 
	C608.625244,325.658051 601.339355,322.756714 599.029663,318.344482 
z"/>
<path fill="#223A9A" opacity="1.000000" stroke="none" 
	d="
M607.951721,814.024414 
	C603.535950,814.178650 603.401245,810.376648 602.161560,807.658630 
	C601.836365,806.945557 602.292419,805.732422 601.846863,805.244568 
	C600.474792,803.741638 598.839905,802.478577 597.307129,801.122314 
	C596.201416,802.749512 594.127136,804.406555 594.176208,805.998108 
	C594.309692,810.320068 592.819336,813.280334 589.036865,815.611694 
	C587.927368,815.979309 586.877563,815.994995 585.129333,816.027283 
	C583.951782,816.361328 583.472534,816.678772 582.993286,816.996216 
	C582.246216,816.996277 581.499084,816.996338 580.145508,816.717590 
	C576.028931,814.968689 572.518982,813.498596 569.389526,812.004395 
	C570.273193,811.908203 570.776306,811.836121 571.626099,811.760254 
	C572.656677,811.781494 573.340515,811.806641 574.477783,811.950073 
	C578.235107,812.078735 581.539124,812.086426 584.843079,812.100098 
	C591.755310,812.128601 592.333984,811.333557 590.931702,803.968628 
	C593.261475,798.026672 594.611633,797.293335 601.194397,798.007385 
	C605.988831,798.527222 604.630920,802.319824 605.094482,805.093018 
	C605.045898,805.048035 605.172668,805.086609 604.880493,805.235962 
	C604.925354,806.582153 605.262329,807.778992 605.711121,809.389587 
	C607.224670,810.569153 608.571594,811.842102 610.038879,812.000549 
	C613.640564,812.389221 617.297546,812.264465 620.601318,812.571533 
	C616.164124,813.206055 612.057922,813.615234 607.951721,814.024414 
z"/>
<path fill="#8089A6" opacity="1.000000" stroke="none" 
	d="
M592.668701,1015.357117 
	C588.382629,1016.344116 583.651611,1017.191833 578.208618,1018.033325 
	C577.316406,1018.035522 577.136047,1018.043945 576.955627,1018.052368 
	C556.252197,1018.391357 535.548767,1018.730286 514.368591,1019.132751 
	C512.880432,1019.173584 511.868988,1019.150879 510.070740,1019.047058 
	C506.164856,1018.262390 503.045776,1017.558777 499.926666,1016.855225 
	C501.852844,1016.643738 503.781464,1016.229309 505.704865,1016.252075 
	C525.628662,1016.487610 545.552002,1017.017944 565.474548,1016.978210 
	C574.392029,1016.960388 583.307434,1015.838623 592.668701,1015.357117 
z"/>
<path fill="#1739BF" opacity="1.000000" stroke="none" 
	d="
M442.001068,748.003052 
	C442.302063,747.319824 442.521393,746.134705 442.949860,746.053528 
	C447.124603,745.262634 451.320770,744.546814 455.537872,744.029541 
	C459.448334,743.549927 463.472137,742.673401 467.305084,743.137146 
	C477.677399,744.391846 488.419098,744.793152 496.907562,752.350708 
	C498.650330,753.902344 500.769409,755.031494 502.861786,756.677673 
	C503.008545,757.000000 502.996002,756.999084 502.622375,756.994751 
	C499.855682,756.656555 497.462646,756.322754 494.780273,755.881836 
	C493.676483,755.170105 492.861938,754.565430 491.994843,753.603394 
	C490.631805,752.121155 489.476959,750.346680 487.982788,749.988770 
	C482.723877,748.729004 477.361450,747.249207 472.014313,747.150269 
	C462.033508,746.965454 452.035797,747.690186 442.045135,748.036438 
	C442.045135,748.036438 442.016449,748.013428 442.001068,748.003052 
z"/>
<path fill="#1839BC" opacity="1.000000" stroke="none" 
	d="
M564.004822,235.989410 
	C560.545593,236.271210 558.226746,234.777771 555.303284,232.699387 
	C546.518188,226.453735 536.601624,221.884430 525.377197,223.893204 
	C519.284058,224.983627 513.454773,227.548553 506.922729,229.724747 
	C505.895996,230.004196 505.453033,230.007294 504.997253,229.996933 
	C504.984467,229.983490 504.959045,229.956421 505.023132,229.617218 
	C512.096375,223.033951 520.968628,219.944550 529.659973,220.430023 
	C542.644531,221.155334 554.720825,226.034592 564.058044,235.920746 
	C564.058044,235.920746 564.022949,235.966827 564.004822,235.989410 
z"/>
<path fill="#B1B8CB" opacity="1.000000" stroke="none" 
	d="
M898.056946,349.781342 
	C901.065796,349.827148 904.074707,349.872955 907.527222,349.834412 
	C908.642639,350.742676 909.882996,351.729095 909.893860,352.728973 
	C909.965332,359.257935 909.770386,365.789764 909.308838,372.508789 
	C908.955017,365.737030 908.955017,358.777161 908.955017,350.962250 
	C858.643066,350.962250 808.688171,350.962250 758.590637,350.584839 
	C788.767334,350.151245 819.086731,350.113220 849.406006,350.031433 
	C865.230347,349.988770 881.054443,349.869080 897.173584,349.832092 
	C897.664673,349.847046 897.860840,349.814178 898.056946,349.781342 
z"/>
<path fill="#173BC4" opacity="1.000000" stroke="none" 
	d="
M702.013306,814.286255 
	C702.793335,813.969299 703.530518,813.984802 704.965820,814.006348 
	C706.102661,814.023743 706.541382,814.035156 707.083984,814.435120 
	C714.265747,815.027283 721.343445,815.241516 728.422607,815.377625 
	C728.612244,815.381287 728.818115,814.538940 729.344116,814.071960 
	C730.107300,814.010742 730.542725,813.968689 731.346924,813.939941 
	C733.143494,812.303772 734.571350,810.654358 735.999146,809.004883 
	C738.702698,809.002136 741.406311,808.999329 744.556152,808.997070 
	C745.002441,808.997620 745.013672,808.989563 745.050293,809.315552 
	C745.741638,810.082947 746.396362,810.524414 747.286011,810.973633 
	C747.520874,810.981323 747.988892,811.023865 747.827393,811.254395 
	C746.027954,811.945618 745.194519,812.619751 747.000671,813.993774 
	C744.219299,813.670105 740.122620,812.067383 738.932678,813.291138 
	C736.515869,815.776794 733.906311,816.152161 731.069275,817.316833 
	C723.745789,820.323364 716.387573,818.495300 709.087646,818.854126 
	C706.705261,818.971252 704.224670,817.419373 701.840820,816.497131 
	C701.667664,816.430176 701.917297,815.270386 702.013306,814.286255 
z"/>
<path fill="#173BC4" opacity="1.000000" stroke="none" 
	d="
M679.078979,817.370605 
	C682.184021,816.018738 685.320557,814.782288 688.574463,814.170349 
	C691.699097,813.582764 692.087646,811.676208 692.003662,809.149048 
	C691.970703,808.160095 691.962952,807.145325 692.140259,806.178406 
	C693.106384,800.909058 694.367371,800.171082 699.216309,801.630066 
	C700.179016,805.594727 701.098083,809.379639 702.031738,813.554932 
	C700.127686,814.542114 698.209106,815.138916 696.188721,815.767395 
	C696.381958,814.542969 696.596130,813.185364 696.889648,811.325317 
	C691.426270,815.798584 687.060608,821.902649 679.078979,817.370605 
z"/>
<path fill="#1839BC" opacity="1.000000" stroke="none" 
	d="
M683.000000,348.001038 
	C683.756470,347.997650 684.512939,347.994263 685.648193,347.981628 
	C687.519958,347.982208 689.013000,347.986450 690.505859,348.002808 
	C696.287598,348.066193 696.525452,348.740051 696.161926,354.687408 
	C695.861755,359.596375 696.138611,364.609863 696.812073,369.487000 
	C697.340149,373.310944 696.045898,375.359711 692.521484,375.873138 
	C681.195618,377.523041 675.272156,384.486725 672.688477,395.603760 
	C671.956299,395.995850 671.544250,395.994720 671.132141,395.993591 
	C672.169434,382.995422 677.290588,377.095093 691.253723,373.563812 
	C694.061584,372.853729 695.119751,371.929718 694.987854,369.050171 
	C694.716064,363.118958 694.728394,357.174683 693.999146,350.678833 
	C689.913635,350.022186 686.452759,349.922485 682.999939,349.519318 
	C683.005249,348.810913 683.002625,348.406006 683.000000,348.001038 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M147.989624,672.992676 
	C148.387650,671.983276 148.584229,670.820740 149.216339,669.989258 
	C153.349289,664.553162 157.772156,659.324951 161.668427,653.728088 
	C163.062668,651.725342 163.144196,648.846130 164.030640,646.442871 
	C165.148865,643.411255 166.385483,640.414917 167.738403,637.480530 
	C169.504105,633.650818 171.433884,629.896790 173.646225,626.054810 
	C173.999374,625.999451 174.003021,626.002441 173.995483,626.391602 
	C169.663528,638.528931 165.339127,650.277222 161.014038,662.269104 
	C161.013351,662.512756 161.002441,663.000000 160.657043,663.033875 
	C157.541656,666.380432 154.771667,669.693115 151.755859,673.008423 
	C151.510025,673.011108 151.018356,673.010559 150.639908,673.006836 
	C149.504181,672.999634 148.746902,672.996155 147.989624,672.992676 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M747.361877,814.003296 
	C745.194519,812.619751 746.027954,811.945618 748.206909,811.289612 
	C754.497314,812.399170 760.187866,814.274597 766.009888,814.875244 
	C774.764648,815.778442 782.847229,813.200989 789.989075,808.191528 
	C789.998108,808.633850 790.000305,808.810852 790.001343,809.322876 
	C790.000183,810.104431 790.000122,810.551025 790.000000,810.997559 
	C788.530090,812.242310 787.060181,813.487122 784.953491,814.916321 
	C783.341248,815.599731 782.376038,816.509399 781.388733,816.533997 
	C771.934509,816.769897 762.476440,816.856140 753.019531,816.985352 
	C751.254028,815.994507 749.488586,815.003662 747.361877,814.003296 
z"/>
<path fill="#8089A6" opacity="1.000000" stroke="none" 
	d="
M453.371094,1012.117798 
	C447.487915,1010.584351 441.908539,1009.038513 436.148071,1007.212585 
	C436.712494,1006.737549 437.498413,1006.278931 438.197601,1006.386169 
	C457.210266,1009.300476 476.214935,1012.267151 495.215515,1015.259521 
	C495.815765,1015.354065 496.358612,1015.813110 496.609314,1016.541626 
	C489.481964,1016.398071 482.673492,1015.815125 475.865021,1015.232178 
	C475.173706,1015.197876 474.482422,1015.163635 473.040222,1015.048340 
	C466.489288,1014.043213 460.689240,1013.119080 454.889221,1012.194946 
	C454.484436,1012.165100 454.079620,1012.135315 453.371094,1012.117798 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M738.602539,790.994873 
	C738.991272,793.149353 739.213440,795.339600 738.896057,797.448730 
	C738.732544,798.534973 737.790527,799.983948 736.858948,800.318665 
	C736.150696,800.573059 734.391907,799.573425 733.978577,798.710999 
	C731.044739,792.589417 728.410522,786.324951 725.529602,780.176941 
	C723.859497,776.612671 721.908630,773.179932 720.061646,769.327515 
	C721.705200,770.531067 723.961182,771.797974 724.926392,773.714844 
	C727.810547,779.442749 730.189209,785.425171 732.872986,791.554199 
	C734.718018,791.361694 736.465271,791.179382 738.602539,790.994873 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M472.975616,197.971985 
	C474.072754,198.315506 475.169891,198.659027 476.488464,199.299683 
	C474.808685,201.066681 472.907471,202.536545 471.003906,203.632080 
	C471.152557,199.800339 469.743317,199.705093 467.395081,201.572052 
	C465.941467,202.727722 464.466125,203.856003 463.000519,204.996582 
	C461.612488,204.999115 460.224426,205.001648 458.085388,205.002747 
	C456.888214,205.003479 456.442047,205.005661 455.995911,205.007843 
	C454.930328,205.005020 453.864746,205.002182 452.181213,204.730331 
	C448.377533,202.640457 445.191803,200.819626 442.006104,198.998779 
	C442.006104,198.998779 442.000092,198.499100 441.962006,197.882309 
	C441.619690,195.835831 441.315460,194.406143 441.356445,193.018570 
	C442.796295,194.001251 443.988861,194.850037 444.970215,195.896820 
	C453.178375,204.652145 457.415253,205.037186 466.748566,197.869247 
	C467.364410,197.396301 468.237549,197.258408 469.217346,197.243469 
	C470.621002,197.672150 471.798309,197.822067 472.975616,197.971985 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M694.908936,684.997437 
	C679.387817,683.911560 672.214050,676.215576 671.196167,659.419434 
	C672.026855,660.992126 672.600037,663.055481 673.534851,664.939453 
	C674.612427,667.111206 676.009155,669.124573 677.701599,670.861023 
	C678.065674,672.330994 677.957153,674.582703 678.874573,675.137207 
	C683.100952,677.692017 687.629089,679.743286 692.013733,682.042480 
	C693.431335,682.785828 694.741211,683.734436 696.100403,684.589111 
	C695.974304,684.683472 695.859436,684.803345 695.719055,684.865295 
	C695.571594,684.930298 695.399536,684.939758 694.908936,684.997437 
z"/>
<path fill="#1739BF" opacity="1.000000" stroke="none" 
	d="
M889.032654,816.035095 
	C885.469299,817.019348 881.939636,818.672241 878.335876,818.853638 
	C869.924438,819.276855 861.475220,819.087646 853.046936,818.897583 
	C851.715271,818.867615 850.412903,817.539978 849.491577,816.409058 
	C862.935059,816.015564 875.983826,816.025330 889.032654,816.035095 
z"/>
<path fill="#1839BC" opacity="1.000000" stroke="none" 
	d="
M672.020203,496.509155 
	C672.359009,498.386169 672.684570,499.797211 672.765625,501.752563 
	C672.349304,502.856720 672.177429,503.416504 671.785645,503.993591 
	C671.565857,504.010956 671.128845,504.069366 671.128845,504.069366 
	C671.064026,488.464630 670.965759,472.859955 670.943054,457.255157 
	C670.915710,438.486725 670.952576,419.718262 671.236755,400.598022 
	C671.750793,400.443542 671.913757,400.690857 672.000793,400.988251 
	C672.002869,432.673218 672.004883,464.358154 672.020203,496.509155 
z"/>
<path fill="#1739BF" opacity="1.000000" stroke="none" 
	d="
M380.008850,750.991760 
	C380.899414,753.509277 382.265869,755.979492 382.565033,758.572876 
	C383.278107,764.753723 383.487152,770.992676 383.948364,778.044800 
	C383.999298,780.917664 384.001648,782.953918 383.643860,784.926697 
	C382.210632,783.228149 381.137512,781.593140 380.050842,779.588867 
	C380.031189,778.480896 380.025116,777.742065 380.296265,776.747192 
	C379.726379,770.993958 378.879272,765.496765 378.031219,759.673035 
	C378.043640,758.911316 378.057068,758.476196 378.359131,757.989685 
	C378.956207,757.614014 379.264679,757.289612 379.573120,756.965271 
	C379.080811,756.626587 378.588501,756.287903 378.079346,755.586914 
	C378.073425,754.499451 378.084412,753.774353 378.402527,752.989868 
	C379.134094,752.292603 379.558502,751.654785 379.982880,751.016968 
	C379.982880,751.016968 379.999542,751.000488 380.008850,750.991760 
z"/>
<path fill="#1433AA" opacity="1.000000" stroke="none" 
	d="
M889.403564,816.027710 
	C875.983826,816.025330 862.935059,816.015564 849.428406,816.018799 
	C848.970581,816.031738 848.476746,816.024048 848.255493,815.718872 
	C848.358521,814.941650 848.682739,814.469604 849.474731,813.990845 
	C861.292725,813.970886 872.642883,813.957642 884.388489,813.947144 
	C887.850891,813.285889 890.917786,812.621948 893.984741,811.958008 
	C893.453552,813.114563 892.922363,814.271118 892.197388,815.705444 
	C891.260498,815.995605 890.517517,816.007996 889.403564,816.027710 
z"/>
<path fill="#1433AA" opacity="1.000000" stroke="none" 
	d="
M400.714691,746.948608 
	C406.117279,747.095825 411.276276,747.256836 416.421143,748.019897 
	C412.626892,748.746948 408.834290,748.732544 405.073181,749.069885 
	C403.697540,749.193298 402.396729,750.151611 400.779053,750.907654 
	C399.942383,751.760803 399.388458,752.435364 398.558075,753.399780 
	C398.196198,756.119080 398.508270,758.646729 397.937927,760.956482 
	C396.923401,765.064941 395.374573,769.041504 394.031982,772.716675 
	C393.675659,771.239685 393.336609,770.119141 393.001953,768.669006 
	C393.008087,767.899841 393.009796,767.460327 393.304565,766.982605 
	C393.715424,766.295837 393.833252,765.647217 393.951080,764.998657 
	C393.960876,763.935425 393.970703,762.872192 393.994751,761.166504 
	C394.008972,760.523987 394.018890,760.035828 394.267242,760.217407 
	C394.871582,760.963135 395.227570,761.527344 395.974152,762.710510 
	C395.974152,759.573059 395.974152,757.285706 395.982635,754.620728 
	C397.321869,751.821472 398.652588,749.400024 399.983337,746.978516 
	C399.983337,746.978516 400.471069,746.962280 400.714691,746.948608 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M475.911255,1015.604187 
	C482.673492,1015.815125 489.481964,1016.398071 496.768890,1016.896484 
	C497.917267,1016.808960 498.587158,1016.805908 499.591858,1016.829102 
	C503.045776,1017.558777 506.164856,1018.262390 509.689880,1019.064697 
	C507.612579,1019.752563 505.142883,1020.746033 502.642639,1020.830017 
	C499.401184,1020.938782 496.135376,1020.323120 492.422882,1020.043640 
	C490.910065,1020.052734 489.854431,1020.037415 488.402679,1020.033508 
	C484.901489,1019.396545 481.763275,1018.869019 478.709778,1018.032471 
	C477.681732,1017.750793 476.868744,1016.684082 475.911255,1015.604187 
z"/>
<path fill="#193ECF" opacity="1.000000" stroke="none" 
	d="
M481.001221,236.996948 
	C475.889526,238.815170 474.267456,237.841171 473.795349,232.861954 
	C473.713928,232.003357 473.100922,230.608780 472.510773,230.479660 
	C468.593658,229.622620 468.924255,226.708008 469.286255,223.414520 
	C470.050568,222.671295 470.523071,222.368591 471.244568,222.206085 
	C471.681915,222.875031 471.870300,223.403778 472.069092,224.282318 
	C473.067230,225.411530 474.054993,226.190948 475.338867,227.114777 
	C477.420776,228.841385 479.206573,230.423584 481.099304,232.225769 
	C481.404327,232.719177 481.662903,232.911102 481.995117,233.254456 
	C482.008240,233.487366 482.024841,233.953659 481.897003,234.252808 
	C481.513184,235.366959 481.257172,236.181946 481.001221,236.996948 
z"/>
<path fill="#1839BC" opacity="1.000000" stroke="none" 
	d="
M424.809814,120.762375 
	C424.765930,121.786026 424.752197,122.850113 424.222321,123.284439 
	C417.066650,129.149506 409.839142,134.926941 402.328796,140.869904 
	C403.482605,138.387497 404.458893,135.286758 406.493622,133.236389 
	C410.415436,129.284439 414.834381,125.794144 419.266144,122.398140 
	C420.690216,121.306908 422.802582,121.113960 424.809814,120.762375 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M753.065796,817.379822 
	C762.476440,816.856140 771.934509,816.769897 781.388733,816.533997 
	C782.376038,816.509399 783.341248,815.599731 784.661987,815.054016 
	C783.682617,816.327637 782.449402,818.670166 781.018005,818.798340 
	C773.200012,819.498230 765.335205,819.774048 757.481934,819.887756 
	C756.035828,819.908752 754.569214,818.515076 753.065796,817.379822 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M578.265808,764.080688 
	C577.371521,761.876160 576.722595,759.795959 576.041870,757.350037 
	C578.063354,757.994324 580.116760,759.004272 582.025085,759.942932 
	C583.330566,757.361084 584.859558,754.337097 586.714294,751.165100 
	C587.041748,751.447998 587.043518,751.879028 587.025879,752.980957 
	C587.014465,754.115051 587.022339,754.578247 587.030212,755.041382 
	C587.038269,755.477905 587.046326,755.914429 586.757568,756.582214 
	C586.655334,759.548035 586.849915,762.282532 587.044495,765.017090 
	C587.044495,765.017090 587.042419,765.510254 586.709106,765.781128 
	C583.788086,768.502258 582.041687,765.619080 579.932495,765.035706 
	C579.458740,764.758789 578.984924,764.481934 578.265808,764.080688 
z"/>
<path fill="#223A9A" opacity="1.000000" stroke="none" 
	d="
M326.236084,813.978149 
	C325.253723,813.997925 324.501312,814.000732 323.239380,813.772461 
	C322.167938,813.147949 321.606110,812.754578 321.449890,812.255249 
	C327.758942,812.278564 326.011414,808.274292 326.001648,804.871704 
	C326.095154,802.144958 325.881744,799.589600 325.668335,797.034180 
	C326.026031,796.991699 326.383759,796.949219 326.741486,796.906677 
	C326.912415,797.980652 327.083344,799.054626 327.254242,800.128540 
	C327.539124,799.704590 327.824005,799.280579 328.108887,798.856567 
	C329.036957,799.358337 330.495972,799.648743 330.795380,800.400818 
	C331.762085,802.829102 332.302094,805.427307 333.006592,807.960083 
	C331.017029,809.714111 329.027466,811.468201 326.981567,813.570007 
	C326.925232,813.917725 326.466064,813.961121 326.236084,813.978149 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M740.599976,767.072754 
	C742.018860,770.544556 742.567505,774.354004 744.216309,777.605652 
	C746.544678,782.197449 746.350342,783.417175 739.377563,788.976562 
	C739.740723,786.094360 740.677246,783.198547 741.067566,780.230957 
	C741.278503,778.627075 740.389893,776.910095 740.293274,775.227112 
	C740.139221,772.545959 740.232239,769.850708 740.599976,767.072754 
z"/>
<path fill="#1739BF" opacity="1.000000" stroke="none" 
	d="
M176.994507,619.992676 
	C176.998032,618.281799 177.001556,616.570862 177.374573,614.389282 
	C178.837204,612.305237 180.042740,610.755615 181.000336,609.065491 
	C183.068497,605.415222 185.009430,601.692871 187.003876,598.390442 
	C186.692657,600.539062 186.379959,602.297302 186.057632,604.446167 
	C186.019440,605.881592 185.990891,606.926331 185.602692,608.018799 
	C184.155014,609.773315 183.128174,611.523193 181.965271,613.177307 
	C180.005875,615.964417 181.568405,620.703857 177.025177,621.705566 
	C177.008133,620.906311 177.001328,620.449524 176.994507,619.992676 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M187.002411,598.000000 
	C185.009430,601.692871 183.068497,605.415222 181.000336,609.065491 
	C180.042740,610.755615 178.837204,612.305237 177.378372,613.961731 
	C177.900833,610.862183 178.462875,607.578247 179.748856,604.607788 
	C182.026291,599.347290 184.743759,594.277344 187.638245,589.464233 
	C188.005707,590.876709 188.011978,591.952637 188.014862,593.398315 
	C187.675125,595.178711 187.338776,596.589355 187.002411,598.000000 
z"/>
<path fill="#173BC4" opacity="1.000000" stroke="none" 
	d="
M832.007568,816.009766 
	C830.914734,817.001709 829.861755,818.794312 828.722290,818.851074 
	C822.913025,819.140381 817.079163,819.057739 811.260315,818.885498 
	C810.492798,818.862793 809.762512,817.580200 809.360107,816.448975 
	C810.806702,815.676514 811.908081,815.336304 813.402771,815.003174 
	C814.845398,815.031860 815.894714,815.053406 817.048706,815.444458 
	C819.957581,817.364014 822.721008,818.495544 825.407715,815.040161 
	C826.515930,815.027283 827.254578,815.020325 828.227173,815.284241 
	C829.643311,815.706665 830.825439,815.858215 832.007568,816.009766 
z"/>
<path fill="#1433AA" opacity="1.000000" stroke="none" 
	d="
M813.009521,814.996033 
	C811.908081,815.336304 810.806702,815.676514 809.334473,816.036377 
	C808.222717,816.043579 807.481750,816.031128 806.179810,815.737061 
	C802.079102,812.970215 798.539551,810.485107 794.749756,807.999817 
	C794.332642,808.000549 794.165833,808.001526 793.666504,808.001099 
	C792.890564,808.001465 792.447083,808.003235 791.783447,808.110840 
	C791.288940,808.414734 791.100647,808.674988 790.749390,808.996216 
	C790.500427,808.994812 790.002441,808.987793 790.002441,808.987793 
	C790.000305,808.810852 789.998108,808.633850 790.045776,807.864746 
	C791.964783,803.700989 794.455322,804.137878 797.413391,805.929565 
	C802.556030,809.044312 807.805115,811.983337 813.009521,814.996033 
z"/>
<path fill="#1739BF" opacity="1.000000" stroke="none" 
	d="
M849.006958,813.997559 
	C848.682739,814.469604 848.358521,814.941650 848.008606,815.715149 
	C843.255737,815.679321 838.528503,815.342041 833.401001,814.671631 
	C833.001648,813.889771 833.002563,813.441162 833.220764,812.732422 
	C835.620483,811.299622 837.802979,810.126953 840.058533,809.329285 
	C841.762939,810.131348 843.394287,810.558350 845.037537,811.216919 
	C845.049438,811.448425 845.055481,811.912109 845.227539,812.196899 
	C846.245361,812.661499 847.091187,812.841370 848.214844,813.036194 
	C848.677734,813.065735 848.862732,813.080261 849.026489,813.320068 
	C849.005127,813.545227 849.006897,813.997559 849.006958,813.997559 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M752.002441,749.004883 
	C748.565247,752.972351 745.128052,756.939880 741.354370,760.959961 
	C740.969971,758.962036 740.039551,755.949524 741.034973,755.036499 
	C744.396606,751.953125 748.441711,749.615051 752.506470,747.250305 
	C752.525757,748.007324 752.264099,748.506104 752.002441,749.004883 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M136.029694,681.019531 
	C133.925201,681.988770 131.820709,682.958069 129.363815,683.965393 
	C130.369141,677.910950 136.266190,674.004211 144.556458,674.374146 
	C144.999420,675.495056 144.997635,676.241394 144.663147,676.993164 
	C143.886719,676.997620 143.442963,676.996643 142.780731,676.729858 
	C139.082428,675.982483 137.046722,677.271973 136.757019,681.006714 
	C136.514633,681.013489 136.029709,681.019531 136.029694,681.019531 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M481.349884,237.149170 
	C481.257172,236.181946 481.513184,235.366959 482.234009,234.291122 
	C484.132233,234.689392 485.565582,235.348495 487.184784,236.273026 
	C489.798340,239.276123 492.012268,239.156693 494.325745,236.026276 
	C495.080719,235.994263 495.511902,235.978134 496.289062,235.973450 
	C497.096741,235.995789 497.558441,236.006683 498.020142,236.017578 
	C496.388153,240.267288 492.041901,242.034790 488.317291,240.120544 
	C486.190002,239.027206 483.909882,238.231262 481.349884,237.149170 
z"/>
<path fill="#1839BC" opacity="1.000000" stroke="none" 
	d="
M584.004456,302.365417 
	C587.982483,305.762512 591.961609,309.539032 595.962524,313.666443 
	C589.321960,313.096405 585.504517,309.152527 584.004456,302.365417 
z"/>
<path fill="#193ECF" opacity="1.000000" stroke="none" 
	d="
M817.827515,745.665833 
	C817.681885,746.095642 817.421021,746.281494 817.108765,746.766602 
	C816.256714,746.978516 815.515259,746.974060 814.348877,746.649048 
	C813.281799,745.886414 812.639709,745.444397 811.997559,745.002441 
	C806.974609,745.000671 801.951599,744.998962 796.461182,744.989990 
	C796.848328,744.092224 797.741150,742.400757 798.551392,742.439453 
	C804.274292,742.712646 809.984985,743.296753 815.685242,743.913391 
	C816.384521,743.989014 816.999329,744.844727 817.827515,745.665833 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M394.018890,760.035828 
	C394.018890,760.035828 394.008972,760.523987 393.993744,760.767761 
	C393.029724,759.467346 391.236450,757.874634 391.321747,756.389832 
	C391.464630,753.901733 392.322357,751.117676 393.803955,749.156799 
	C394.867706,747.748840 397.438507,747.479553 399.660156,746.840454 
	C398.652588,749.400024 397.321869,751.821472 395.655273,754.667847 
	C394.885895,756.740356 394.452393,758.388123 394.018890,760.035828 
z"/>
<path fill="#173BC4" opacity="1.000000" stroke="none" 
	d="
M790.001343,809.322876 
	C790.002441,808.987793 790.500427,808.994812 790.970337,808.891296 
	C791.715820,808.589966 791.903625,808.329041 792.003662,808.004944 
	C792.447083,808.003235 792.890564,808.001465 793.754944,808.169434 
	C794.450562,808.226074 794.725281,808.113037 795.000000,808.000000 
	C798.539551,810.485107 802.079102,812.970215 805.808716,815.734863 
	C804.436707,815.785889 802.783691,815.807617 801.330688,815.279297 
	C797.783630,813.989563 794.329163,812.445251 790.418701,811.000488 
	C790.000122,810.551025 790.000183,810.104431 790.001343,809.322876 
z"/>
<path fill="#1739BF" opacity="1.000000" stroke="none" 
	d="
M569.008972,812.028503 
	C572.518982,813.498596 576.028931,814.968689 579.771912,816.717651 
	C575.944519,816.670471 571.884094,816.344238 567.411865,816.011475 
	C565.903381,815.334290 564.806763,814.663696 563.489502,813.681519 
	C564.783020,811.214478 566.586365,810.296692 569.008972,812.028503 
z"/>
<path fill="#193ECF" opacity="1.000000" stroke="none" 
	d="
M441.011230,192.976471 
	C441.315460,194.406143 441.619690,195.835831 441.960815,197.632446 
	C439.640198,198.047226 437.624512,198.051559 436.804626,194.908875 
	C436.387177,193.308716 434.337036,192.134537 433.016724,190.380554 
	C434.403809,189.994003 435.799042,189.994736 437.889465,190.123932 
	C439.393463,191.160431 440.202332,192.068451 441.011230,192.976471 
z"/>
<path fill="#193ECF" opacity="1.000000" stroke="none" 
	d="
M442.004639,199.392883 
	C445.191803,200.819626 448.377533,202.640457 451.781616,204.729492 
	C446.473877,206.801453 442.925720,204.952026 442.004639,199.392883 
z"/>
<path fill="#173BC4" opacity="1.000000" stroke="none" 
	d="
M777.003479,745.559570 
	C771.957153,745.975159 766.885681,745.977966 761.384033,745.965027 
	C761.010986,745.457581 760.892700,744.828552 761.153076,744.495972 
	C763.167480,741.922424 774.755066,742.430908 777.003479,745.559570 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M405.951355,157.018753 
	C407.950470,159.440567 409.949615,161.862366 411.985046,164.631180 
	C407.015656,164.019547 403.854492,160.631439 401.420410,156.385040 
	C403.137543,156.390152 404.544434,156.704453 405.951355,157.018753 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M470.995544,222.065887 
	C470.523071,222.368591 470.050568,222.671295 469.283447,222.995605 
	C464.260376,220.265793 466.063751,215.509598 465.997375,211.354004 
	C467.033783,211.477081 467.524994,211.740753 468.013733,212.411484 
	C469.005981,215.901001 470.000763,218.983444 470.995544,222.065887 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M384.003967,784.990234 
	C384.001648,782.953918 383.999298,780.917664 383.996033,778.445557 
	C385.438660,777.037415 386.882202,776.065063 388.624359,775.409485 
	C388.617676,777.153564 388.312347,778.580872 388.005920,780.394531 
	C388.071686,783.515198 388.342651,786.443481 384.003967,784.990234 
z"/>
<path fill="#8089A6" opacity="1.000000" stroke="none" 
	d="
M792.708252,946.290161 
	C787.980164,949.556152 782.905762,952.783752 777.463989,956.019409 
	C782.185120,952.768982 787.273560,949.510376 792.708252,946.290161 
z"/>
<path fill="#193ECF" opacity="1.000000" stroke="none" 
	d="
M463.041992,205.318619 
	C464.466125,203.856003 465.941467,202.727722 467.395081,201.572052 
	C469.743317,199.705093 471.152557,199.800339 470.882599,203.897217 
	C470.179016,205.027069 469.594391,205.517456 468.664124,206.037186 
	C467.216003,206.377869 466.113495,206.689209 465.010986,207.000565 
	C464.368469,206.547272 463.725952,206.093964 463.041992,205.318619 
z"/>
<path fill="#1739BF" opacity="1.000000" stroke="none" 
	d="
M188.018265,593.028564 
	C188.011978,591.952637 188.005707,590.876709 187.996994,589.396545 
	C187.994736,588.549683 187.994904,588.107056 188.270111,587.135010 
	C190.030930,584.737549 191.516693,582.869385 193.003647,581.408081 
	C191.342636,585.552795 189.680450,589.290649 188.018265,593.028564 
z"/>
<path fill="#223A9A" opacity="1.000000" stroke="none" 
	d="
M235.372528,710.955017 
	C233.883926,710.951233 232.804855,710.943481 231.341904,710.621033 
	C230.948776,709.734619 230.939545,709.162903 230.930298,708.591187 
	C234.604385,708.493958 238.278458,708.396790 242.384705,708.417114 
	C242.816879,708.534607 242.770721,708.811279 242.581177,709.052734 
	C242.195297,709.493042 242.068665,709.729309 242.011780,710.003113 
	C239.935211,710.319092 237.858627,710.635010 235.372528,710.955017 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M193.002457,581.001221 
	C191.516693,582.869385 190.030930,584.737549 188.269821,586.802917 
	C186.528198,582.538818 188.134003,578.759338 192.619781,577.380432 
	C193.006058,578.506897 193.012222,579.261536 193.014465,580.262390 
	C193.010529,580.508667 193.002441,581.001221 193.002457,581.001221 
z"/>
<path fill="#1A38B2" opacity="1.000000" stroke="none" 
	d="
M614.154602,200.732468 
	C612.909424,200.976242 611.832153,200.976395 610.413025,200.628815 
	C613.371460,197.175171 616.671692,194.069260 619.971863,190.963348 
	C619.075928,192.440933 618.179932,193.918518 617.140137,195.695404 
	C616.658020,197.081253 616.319763,198.167831 615.990723,199.627197 
	C616.000000,200.000000 615.983765,199.983673 615.983765,199.983673 
	C615.429993,200.152054 614.876221,200.320450 614.154602,200.732468 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M720.276978,766.775757 
	C718.700684,763.918762 717.390503,760.837585 716.038940,757.380615 
	C722.759644,757.688843 719.710632,763.044312 720.276978,766.775757 
z"/>
<path fill="#1839BC" opacity="1.000000" stroke="none" 
	d="
M388.007019,780.008118 
	C388.312347,778.580872 388.617676,777.153564 388.958649,775.359497 
	C388.994293,774.992676 389.000183,774.498413 389.325562,774.207764 
	C390.091339,773.268066 390.531708,772.619080 390.972107,771.970093 
	C390.978638,771.227600 390.985199,770.485046 391.328796,769.371399 
	C392.109772,768.999695 392.553680,768.999207 392.997559,768.998657 
	C393.336609,770.119141 393.675659,771.239685 394.023376,772.941406 
	C394.032043,773.522644 394.006134,773.973145 393.662964,774.030090 
	C391.548859,776.060730 389.777954,778.034424 388.007019,780.008118 
z"/>
<path fill="#1839BC" opacity="1.000000" stroke="none" 
	d="
M498.365173,235.980927 
	C497.558441,236.006683 497.096741,235.995789 496.452118,235.676285 
	C499.165802,233.563934 502.062439,231.760178 504.959045,229.956421 
	C504.959045,229.956421 504.984467,229.983490 504.994019,230.326721 
	C505.000763,231.109802 504.997955,231.549667 504.995117,231.989532 
	C502.900146,233.307785 500.805176,234.626038 498.365173,235.980927 
z"/>
<path fill="#1739BF" opacity="1.000000" stroke="none" 
	d="
M144.995850,676.987793 
	C144.997635,676.241394 144.999420,675.495056 145.001236,674.375549 
	C145.772720,673.667908 146.544205,673.333374 147.652649,672.995728 
	C148.746902,672.996155 149.504181,672.999634 150.582794,673.346802 
	C148.934692,674.789551 146.965256,675.888672 144.995850,676.987793 
z"/>
<path fill="#8089A6" opacity="1.000000" stroke="none" 
	d="
M775.967346,958.635620 
	C773.850525,959.608093 771.751648,960.266968 769.279419,960.943420 
	C771.061340,959.405518 773.216492,957.849976 775.715698,956.380005 
	C776.034912,957.084412 776.010071,957.703186 775.967346,958.635620 
z"/>
<path fill="#1839BC" opacity="1.000000" stroke="none" 
	d="
M400.579590,145.045227 
	C401.271057,146.724243 401.618011,148.481232 401.927612,150.649780 
	C399.218628,149.672897 397.747406,147.949799 400.579590,145.045227 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M353.031769,347.972443 
	C355.656250,348.369507 358.248962,348.797302 360.919922,349.610291 
	C360.998199,349.995514 360.916290,350.082306 360.440613,350.090332 
	C357.613464,349.991211 355.261993,349.884094 352.933105,349.491394 
	C352.948944,348.825073 352.942200,348.444366 352.935455,348.063629 
	C352.935455,348.063629 352.999969,348.003174 353.031769,347.972443 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M193.018387,580.016113 
	C193.012222,579.261536 193.006058,578.506897 192.998138,577.374939 
	C192.996368,576.997559 193.006714,576.503906 193.012085,576.257202 
	C194.121735,575.369568 195.226013,574.728516 196.659149,574.405762 
	C195.664795,576.488098 194.341583,578.252075 193.018387,580.016113 
z"/>
<path fill="#193ECF" opacity="1.000000" stroke="none" 
	d="
M482.413849,816.994141 
	C484.043365,816.326599 486.098328,815.663025 488.577606,815.000916 
	C486.947693,815.667664 484.893524,816.332886 482.413849,816.994141 
z"/>
<path fill="#173BC4" opacity="1.000000" stroke="none" 
	d="
M752.349731,748.970154 
	C752.264099,748.506104 752.525757,748.007324 752.891357,747.245728 
	C754.517822,746.471497 756.040466,745.960083 757.783142,745.706238 
	C756.234497,746.954407 754.465820,747.944946 752.349731,748.970154 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M176.637894,620.054199 
	C177.001328,620.449524 177.008133,620.906311 177.021576,622.074158 
	C177.023117,623.524902 177.018036,624.264709 176.692291,625.026611 
	C175.582077,625.366699 174.792557,625.684570 174.003021,626.002441 
	C174.003021,626.002441 173.999374,625.999451 173.995422,625.995178 
	C174.754730,624.032532 175.518005,622.074158 176.637894,620.054199 
z"/>
<path fill="#1839BC" opacity="1.000000" stroke="none" 
	d="
M405.822876,156.713806 
	C404.544434,156.704453 403.137543,156.390152 401.368347,156.036530 
	C401.152527,155.138397 401.298950,154.279556 401.702148,153.214294 
	C403.204071,154.141525 404.449219,155.275192 405.822876,156.713806 
z"/>
<path fill="#1433AA" opacity="1.000000" stroke="none" 
	d="
M833.003540,812.992493 
	C833.002563,813.441162 833.001648,813.889771 833.005737,814.675049 
	C832.907715,815.333862 832.719482,815.594482 832.226807,815.901672 
	C830.825439,815.858215 829.643311,815.706665 828.431335,815.003113 
	C829.935547,813.964905 831.469543,813.478699 833.003540,812.992493 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M465.031464,207.302048 
	C466.113495,206.689209 467.216003,206.377869 468.661377,206.408783 
	C468.999817,207.494141 468.995392,208.237213 468.994446,208.987488 
	C468.997955,208.994675 468.977539,208.990311 468.610657,208.992462 
	C467.511597,208.997864 466.779480,209.001129 466.047333,209.004379 
	C465.715546,208.537430 465.383728,208.070496 465.031464,207.302048 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M737.000183,360.375153 
	C737.744202,359.999939 738.488037,359.999695 739.612549,360.370422 
	C739.985657,361.501099 739.978210,362.260834 739.970703,363.020569 
	C739.228638,363.013733 738.486633,363.006866 737.372314,363.000000 
	C737.000000,362.250031 737.000000,361.500092 737.000183,360.375153 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M672.281982,400.742676 
	C671.913757,400.690857 671.750793,400.443542 671.315491,400.136902 
	C671.059753,398.947754 671.000366,397.867950 671.036621,396.390869 
	C671.544250,395.994720 671.956299,395.995850 672.677246,395.994995 
	C672.845093,397.494354 672.704163,398.995728 672.281982,400.742676 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M466.036499,209.337326 
	C466.779480,209.001129 467.511597,208.997864 468.615967,209.331055 
	C468.664185,210.446472 468.340210,211.225449 468.016235,212.004425 
	C467.524994,211.740753 467.033783,211.477081 466.284119,211.108246 
	C466.025696,210.558807 466.025696,210.114548 466.036499,209.337326 
z"/>
<path fill="#B1B8CB" opacity="1.000000" stroke="none" 
	d="
M897.363403,375.989258 
	C898.214844,375.390381 899.414856,374.806580 900.951782,374.316101 
	C900.096436,374.941071 898.904236,375.472687 897.363403,375.989258 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M393.001953,768.669006 
	C392.553680,768.999207 392.109772,768.999695 391.332947,769.000122 
	C391.434326,768.032349 391.868652,767.064758 392.651672,766.298218 
	C393.004089,766.673157 393.007812,766.846985 393.011505,767.020813 
	C393.009796,767.460327 393.008087,767.899841 393.001953,768.669006 
z"/>
<path fill="#1839BC" opacity="1.000000" stroke="none" 
	d="
M498.990082,252.984879 
	C498.570648,253.643402 498.151215,254.301910 497.424866,255.035461 
	C497.226898,253.897659 497.335846,252.684814 497.723938,251.236969 
	C498.003113,251.001984 498.000000,251.000000 498.020660,251.294586 
	C498.357574,252.054398 498.673828,252.519638 498.990082,252.984879 
z"/>
<path fill="#1839BC" opacity="1.000000" stroke="none" 
	d="
M596.985229,315.370605 
	C597.646057,315.786072 598.312500,316.555817 599.006348,317.645599 
	C598.352783,317.218719 597.671814,316.471802 596.985229,315.370605 
z"/>
<path fill="#8089A6" opacity="1.000000" stroke="none" 
	d="
M797.710449,943.184082 
	C797.533630,943.776306 797.045410,944.354858 796.239014,944.943726 
	C796.413635,944.359497 796.906372,943.765015 797.710449,943.184082 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M505.316315,231.951523 
	C504.997955,231.549667 505.000763,231.109802 505.006836,230.340164 
	C505.453033,230.007294 505.895996,230.004196 506.671387,230.002075 
	C506.548370,230.639877 506.092926,231.276703 505.316315,231.951523 
z"/>
<path fill="#193ECF" opacity="1.000000" stroke="none" 
	d="
M456.035980,205.305542 
	C456.442047,205.005661 456.888214,205.003479 457.668976,205.004166 
	C457.768707,205.523468 457.533844,206.039917 457.298981,206.556381 
	C456.891327,206.238663 456.483704,205.920959 456.035980,205.305542 
z"/>
<path fill="#173BC4" opacity="1.000000" stroke="none" 
	d="
M393.304565,766.982605 
	C393.007812,766.846985 393.004089,766.673157 392.994995,766.238281 
	C393.088104,765.664856 393.267914,765.409729 393.740051,765.105347 
	C393.833252,765.647217 393.715424,766.295837 393.304565,766.982605 
z"/>
<path fill="#1739BF" opacity="1.000000" stroke="none" 
	d="
M583.162598,817.226624 
	C583.472534,816.678772 583.951782,816.361328 584.735474,816.044067 
	C584.470642,816.515076 583.901245,816.986023 583.162598,817.226624 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M390.654633,772.021057 
	C390.531708,772.619080 390.091339,773.268066 389.329590,773.960571 
	C389.451202,773.360107 389.894165,772.716064 390.654633,772.021057 
z"/>
<path fill="#B1B8CB" opacity="1.000000" stroke="none" 
	d="
M903.286865,373.974213 
	C903.538330,373.654877 904.091370,373.418671 904.948181,373.281555 
	C904.697449,373.606262 904.143005,373.831818 903.286865,373.974213 
z"/>
<path fill="#1739BF" opacity="1.000000" stroke="none" 
	d="
M242.206955,709.913330 
	C242.068665,709.729309 242.195297,709.493042 242.561127,709.191528 
	C242.784866,709.406860 242.675369,709.651794 242.206955,709.913330 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M585.553467,749.466370 
	C585.588501,749.524048 585.518372,749.408691 585.553467,749.466370 
z"/>
<path fill="#173BC4" opacity="1.000000" stroke="none" 
	d="
M360.531616,346.552582 
	C360.475006,346.586487 360.588196,346.518677 360.531616,346.552582 
z"/>
<path fill="#1739BF" opacity="1.000000" stroke="none" 
	d="
M838.452393,816.530640 
	C838.419983,816.476074 838.484863,816.585205 838.452393,816.530640 
z"/>
<path fill="#193ECF" opacity="1.000000" stroke="none" 
	d="
M615.991821,199.992096 
	C616.108765,200.129959 616.233887,200.275726 616.359009,200.421509 
	C616.271790,200.370209 616.184631,200.318939 616.040588,200.125671 
	C615.983765,199.983673 616.000000,200.000000 615.991821,199.992096 
z"/>
<path fill="#A3A3A2" opacity="1.000000" stroke="none" 
	d="
M454.927979,1012.568726 
	C460.689240,1013.119080 466.489288,1014.043213 472.694031,1015.065247 
	C470.228363,1015.597229 467.357971,1016.031311 464.245422,1016.715088 
	C463.267151,1016.962219 462.531036,1016.959534 461.428589,1016.947510 
	C460.104279,1016.296875 459.151276,1015.648010 458.186981,1015.016357 
	C457.118988,1014.316833 456.040588,1013.633057 454.927979,1012.568726 
z"/>
<path fill="#B0B1B4" opacity="1.000000" stroke="none" 
	d="
M864.004333,183.325012 
	C864.648682,183.440689 865.290100,183.889679 865.976440,184.657288 
	C865.574219,184.981812 865.127075,184.987686 864.344849,184.992249 
	C864.006958,184.546738 864.004150,184.102524 864.004333,183.325012 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M565.876465,396.035339 
	C566.230530,404.667755 566.883362,413.299835 566.891846,421.932617 
	C566.967041,498.262115 566.930115,574.591736 566.909668,650.921326 
	C566.909180,652.741760 566.715881,654.562134 565.958740,656.685913 
	C564.227844,655.797485 563.150879,654.605713 562.199829,653.553345 
	C562.047913,654.084717 561.782410,655.013489 561.516907,655.942322 
	C561.250549,655.900574 560.984253,655.858826 560.717896,655.817078 
	C560.717896,570.301208 560.717896,484.785309 560.717896,399.269440 
	C560.477844,399.268921 560.237854,399.268402 559.997803,399.267883 
	C559.997803,401.072723 559.997864,402.877533 559.997864,404.682373 
	C559.996948,487.610199 560.001343,570.538025 559.953491,653.465820 
	C559.952576,654.973328 559.326843,656.480469 558.963745,657.668701 
	C558.538513,656.897034 558.142273,656.444519 557.648254,655.559875 
	C557.476074,645.138794 557.339844,635.149780 557.337219,625.160767 
	C557.318481,554.892761 557.332520,484.624725 557.316467,414.356720 
	C557.315308,409.197418 557.160400,404.038177 557.127502,398.557556 
	C557.195007,397.803192 557.211975,397.370239 557.567261,396.870056 
	C558.274292,395.540497 558.642944,394.278107 559.260742,393.173523 
	C561.632080,394.232697 563.754272,395.134003 565.876465,396.035339 
z"/>
<path fill="#BDBEBF" opacity="1.000000" stroke="none" 
	d="
M772.011230,544.979065 
	C774.785706,544.979065 777.560120,544.979065 780.727112,544.979065 
	C780.848755,547.573242 781.018250,549.520020 781.019653,551.466919 
	C781.044495,586.357849 781.177673,621.249756 780.919678,656.138672 
	C780.880798,661.402039 778.997070,666.651733 777.160889,671.983032 
	C773.980164,672.324219 771.605286,672.590210 769.282227,672.466064 
	C769.619629,671.076050 769.905151,670.076233 770.538818,669.067261 
	C771.783569,668.784058 772.680115,668.509949 774.958008,667.813599 
	C772.641968,667.341431 771.794128,667.168579 770.936401,666.561584 
	C771.133728,664.131531 771.340759,662.135559 771.814514,660.027771 
	C772.070374,658.946777 772.059509,657.977661 772.048218,656.539307 
	C772.035645,619.039795 772.023438,582.009460 772.011230,544.979065 
z"/>
<path fill="#0F1E5E" opacity="1.000000" stroke="none" 
	d="
M557.076965,398.878906 
	C557.160400,404.038177 557.315308,409.197418 557.316467,414.356720 
	C557.332520,484.624725 557.318481,554.892761 557.337219,625.160767 
	C557.339844,635.149780 557.476074,645.138794 557.537354,655.886353 
	C557.572998,657.098022 557.621948,657.551025 557.500244,658.324707 
	C557.180481,660.081604 557.031372,661.517883 556.610596,663.103149 
	C556.287903,663.861694 556.236816,664.471313 555.826416,665.222046 
	C554.517395,671.061340 553.310120,676.621155 548.365784,680.462830 
	C546.977844,681.541260 545.751343,682.827393 544.450745,684.018250 
	C544.123657,684.057800 543.808228,684.141296 543.345703,684.350098 
	C543.186890,684.431458 543.057800,684.335632 542.749146,684.244202 
	C540.424744,684.531189 538.409058,684.909546 536.101257,685.097839 
	C535.553040,684.285339 535.296936,683.662964 535.405029,682.996338 
	C544.587219,680.638977 550.391235,675.296570 552.044434,666.154541 
	C552.952637,661.132263 553.899597,656.019897 553.907715,650.946655 
	C554.027954,576.223633 554.031189,501.500305 553.950806,426.777161 
	C553.939758,416.500153 553.336487,406.223694 552.998352,395.947266 
	C552.738281,388.043793 545.477722,378.250946 539.888062,377.784790 
	C539.573303,377.300903 539.313049,377.112915 538.842163,376.751892 
	C538.141113,376.317200 537.593384,376.134979 537.033203,375.627991 
	C537.013123,374.869934 537.005432,374.436646 536.997681,374.003357 
	C541.085449,375.648315 545.173279,377.293274 549.673706,379.440186 
	C553.687927,384.245728 555.949341,389.022034 555.225220,395.183197 
	C555.770813,396.661438 556.423889,397.770172 557.076965,398.878906 
z"/>
<path fill="#B8B9BC" opacity="1.000000" stroke="none" 
	d="
M780.745239,397.122253 
	C780.838501,419.241486 780.949646,441.360657 781.018005,463.479950 
	C781.054993,475.455963 781.088013,487.432739 780.993958,499.408142 
	C780.918884,508.969238 782.521851,507.851044 772.272583,507.361389 
	C771.742798,470.202850 771.540833,433.578094 771.641113,396.999695 
	C772.797241,397.501251 773.651184,397.956482 775.051147,398.702759 
	C774.513428,396.574188 774.108337,394.970642 773.816284,393.814423 
	C776.151733,394.929352 778.448486,396.025818 780.745239,397.122253 
z"/>
<path fill="#1430A3" opacity="1.000000" stroke="none" 
	d="
M746.001953,709.997864 
	C740.151123,707.240479 737.234619,699.499329 739.537537,692.073486 
	C741.613464,685.379517 745.720093,680.399719 751.955444,676.764343 
	C759.882996,672.142395 762.992493,664.498047 762.996033,655.512329 
	C763.008301,624.548950 763.231018,593.582703 762.847107,562.624084 
	C762.747986,554.626892 760.040710,550.845642 751.195496,550.919800 
	C726.892578,551.123840 702.586670,550.944153 678.282715,551.070679 
	C676.149475,551.081726 674.022217,552.224182 671.524292,552.930176 
	C671.084167,551.621338 671.011963,550.223450 671.495850,548.411255 
	C677.209778,547.996399 682.367493,547.995117 687.525269,547.995300 
	C709.820068,547.995850 732.114807,547.996704 754.409607,547.997559 
	C758.949097,547.997742 763.344299,548.569641 764.704712,553.820984 
	C765.525696,556.990356 765.961426,560.358276 765.937561,563.632385 
	C765.704468,595.702576 765.345947,627.771790 765.004395,659.841125 
	C764.914062,668.323364 762.011353,675.302795 754.412720,679.892456 
	C753.556885,680.409424 753.345520,681.993164 752.669312,683.311401 
	C751.948853,683.686707 751.394470,683.832825 750.494995,683.976929 
	C749.459839,683.983704 748.769775,683.992554 747.741699,684.033447 
	C746.934937,684.733948 746.466248,685.402344 745.870972,686.349304 
	C745.496948,687.391602 745.249512,688.155457 744.629883,689.022461 
	C743.518250,692.042053 742.162659,694.972778 742.230103,697.870422 
	C742.286377,700.281006 744.014771,702.652649 745.043701,705.401123 
	C745.410156,707.153503 745.735352,708.546326 746.060547,709.939148 
	C746.060547,709.939148 746.021484,709.978271 746.001953,709.997864 
z"/>
<path fill="#253267" opacity="1.000000" stroke="none" 
	d="
M771.338867,396.953369 
	C771.540833,433.578094 771.742798,470.202850 771.808105,507.258667 
	C739.186890,507.731598 706.702393,507.767029 674.217896,507.830292 
	C672.153137,507.834290 670.463928,507.635956 671.047241,504.482788 
	C671.128845,504.069366 671.565857,504.010956 671.869019,504.392273 
	C673.506775,505.171387 674.840942,505.914490 676.175964,505.916016 
	C704.404968,505.948578 732.634094,505.897614 761.232910,505.879578 
	C766.986267,504.257355 769.086792,500.610168 769.063660,495.023071 
	C768.943237,465.893494 769.046570,436.762970 768.960388,407.633118 
	C768.947693,403.346893 768.915100,398.917297 767.880249,394.808777 
	C765.988342,387.297668 763.430115,379.911438 754.771240,376.738190 
	C753.711121,376.328003 752.864075,376.164917 752.016602,375.716248 
	C752.015320,374.859772 752.014465,374.288879 752.013672,373.717987 
	C756.603088,375.913757 761.192627,378.109497 766.205566,380.819519 
	C767.857422,384.009552 769.085815,386.685303 770.370605,389.688477 
	C770.730957,392.328400 771.034912,394.640900 771.338867,396.953369 
z"/>
<path fill="#0F2781" opacity="1.000000" stroke="none" 
	d="
M752.835266,683.082214 
	C753.345520,681.993164 753.556885,680.409424 754.412720,679.892456 
	C762.011353,675.302795 764.914062,668.323364 765.004395,659.841125 
	C765.345947,627.771790 765.704468,595.702576 765.937561,563.632385 
	C765.961426,560.358276 765.525696,556.990356 764.704712,553.820984 
	C763.344299,548.569641 758.949097,547.997742 754.409607,547.997559 
	C732.114807,547.996704 709.820068,547.995850 687.525269,547.995300 
	C682.367493,547.995117 677.209778,547.996399 671.592041,548.001709 
	C671.074768,547.553833 671.017456,547.101379 671.513306,546.318115 
	C701.717834,545.981445 731.369324,545.975586 761.367554,546.011414 
	C766.641113,548.757080 769.050476,552.779053 769.035095,558.538513 
	C768.948608,590.928101 769.309631,623.322693 768.828918,655.705383 
	C768.656616,667.307312 765.713806,678.380249 752.835266,683.082214 
z"/>
<path fill="#2D3C85" opacity="1.000000" stroke="none" 
	d="
M748.079712,684.001404 
	C748.769775,683.992554 749.459839,683.983704 750.729553,684.230713 
	C751.951538,684.706604 752.593933,684.926636 753.144165,685.394775 
	C753.037842,685.818848 753.023682,685.994751 752.676758,686.148315 
	C751.479431,686.310730 750.614868,686.495605 749.481750,686.739807 
	C749.032654,687.102356 748.852051,687.405518 748.553101,688.154419 
	C748.392334,694.089294 748.349976,699.578491 748.307617,705.067688 
	C748.255981,705.494629 748.204285,705.921570 748.283325,707.037781 
	C749.933960,707.789368 751.453918,707.851624 752.973938,707.913940 
	C794.368652,707.986633 835.763367,708.056702 877.158081,708.134827 
	C883.475281,708.146729 889.792419,708.196350 896.561279,708.363525 
	C892.809631,709.326538 888.609436,710.855286 884.402527,710.873962 
	C843.701904,711.055054 803.000122,711.018372 762.298828,710.937256 
	C757.189087,710.927124 752.080505,710.300903 746.515991,709.949585 
	C745.735352,708.546326 745.410156,707.153503 745.049072,704.929443 
	C745.009521,699.038513 745.005798,693.978882 745.002075,688.919250 
	C745.249512,688.155457 745.496948,687.391602 746.142456,686.217651 
	C747.053650,685.205444 747.566650,684.603455 748.079712,684.001404 
z"/>
<path fill="#1D2A5E" opacity="1.000000" stroke="none" 
	d="
M753.236267,685.146667 
	C752.593933,684.926636 751.951538,684.706604 751.074585,684.232788 
	C751.394470,683.832825 751.948853,683.686707 752.669312,683.311401 
	C765.713806,678.380249 768.656616,667.307312 768.828918,655.705383 
	C769.309631,623.322693 768.948608,590.928101 769.035095,558.538513 
	C769.050476,552.779053 766.641113,548.757080 761.362183,545.734375 
	C761.009644,545.230774 761.009277,545.045837 761.008911,544.860962 
	C764.376343,544.833496 767.743713,544.806030 771.561157,544.878845 
	C772.023438,582.009460 772.035645,619.039795 771.786011,656.762939 
	C771.532104,658.350342 771.539978,659.244934 771.547852,660.139587 
	C771.340759,662.135559 771.133728,664.131531 770.714233,666.756775 
	C770.398132,667.949524 770.294373,668.512939 770.190613,669.076416 
	C769.905151,670.076233 769.619629,671.076050 769.008423,672.592041 
	C764.731873,677.017822 760.781067,680.927490 756.637939,684.934448 
	C756.445557,685.031799 756.070312,685.244202 755.718750,685.187317 
	C754.656860,685.135803 753.946533,685.141235 753.236267,685.146667 
z"/>
<path fill="#0F2277" opacity="1.000000" stroke="none" 
	d="
M680.500732,708.445435 
	C679.121460,709.373474 677.292358,710.883118 675.458313,710.889160 
	C633.051758,711.028259 590.644592,711.032227 548.237671,710.968018 
	C543.786133,710.961304 539.335510,710.373840 534.665771,709.755676 
	C535.143250,709.180603 535.839478,708.905701 536.870239,708.541199 
	C538.792297,708.312622 540.379700,708.173645 541.967163,708.034668 
	C573.537292,708.102600 605.107361,708.170959 636.677490,708.238098 
	C651.134521,708.268860 665.591553,708.297180 680.500732,708.445435 
z"/>
<path fill="#162B77" opacity="1.000000" stroke="none" 
	d="
M751.695312,373.525421 
	C752.014465,374.288879 752.015320,374.859772 751.645142,375.713806 
	C749.851013,375.660187 748.427979,375.323395 746.788147,374.878448 
	C746.301697,374.573364 746.110901,374.317535 745.957031,373.622742 
	C741.991882,365.479858 742.367737,357.730316 746.192322,349.730286 
	C747.253845,349.283783 748.109741,349.097809 749.396729,348.936432 
	C752.777283,348.640869 755.726562,348.045959 758.676575,348.041931 
	C801.375732,347.983551 844.075134,347.964478 886.774048,348.083160 
	C890.143799,348.092529 893.510498,349.191589 896.878662,349.784210 
	C881.054443,349.869080 865.230347,349.988770 849.406006,350.031433 
	C819.086731,350.113220 788.767334,350.151245 758.118042,350.484497 
	C757.788147,350.761566 757.394348,350.872864 756.789551,350.969269 
	C754.550293,351.141479 752.762939,350.833405 751.326172,351.405731 
	C750.004211,351.932404 748.159973,353.360291 748.103699,354.470245 
	C747.856323,359.341187 748.174194,364.240784 748.287109,369.130005 
	C748.213684,369.537415 748.140320,369.944824 748.014038,370.924072 
	C748.223999,371.738617 748.486816,371.981293 748.749573,372.223999 
	C749.625366,372.593597 750.501160,372.963226 751.695312,373.525421 
z"/>
<path fill="#B0B1B4" opacity="1.000000" stroke="none" 
	d="
M556.882263,662.954163 
	C557.031372,661.517883 557.180481,660.081604 557.831421,658.320068 
	C558.553040,657.992493 558.772888,657.990173 558.992676,657.987793 
	C559.326843,656.480469 559.952576,654.973328 559.953491,653.465820 
	C560.001343,570.538025 559.996948,487.610199 559.997864,404.682373 
	C559.997864,402.877533 559.997803,401.072723 559.997803,399.267883 
	C560.237854,399.268402 560.477844,399.268921 560.717896,399.269440 
	C560.717896,484.785309 560.717896,570.301208 560.717896,655.817078 
	C560.984253,655.858826 561.250549,655.900574 561.516907,655.942322 
	C561.782410,655.013489 562.047913,654.084717 562.199829,653.553345 
	C563.150879,654.605713 564.227844,655.797485 565.690430,657.001709 
	C566.049377,658.391663 566.022766,659.769043 565.535156,661.575745 
	C562.343506,662.321411 559.612854,662.637756 556.882263,662.954163 
z"/>
<path fill="#162B77" opacity="1.000000" stroke="none" 
	d="
M670.023987,350.442322 
	C669.568298,350.407928 669.112610,350.373566 667.874023,350.244934 
	C652.500244,350.129547 637.909241,350.059296 623.318481,350.104187 
	C613.219482,350.135284 603.121094,350.325226 593.022400,350.444153 
	C588.317261,350.408325 583.612061,350.372498 577.998901,350.245789 
	C570.268066,350.132324 563.443787,350.014832 556.622864,350.120422 
	C552.041931,350.191345 547.465820,350.578125 542.887573,350.823608 
	C542.887573,350.823608 542.901428,350.930664 542.439331,350.908447 
	C539.170593,351.074188 536.364075,351.262177 533.415833,351.335754 
	C533.274170,351.221313 532.992737,350.990112 533.102905,350.732788 
	C533.467285,349.978912 533.721558,349.482300 533.983154,348.990417 
	C533.990417,348.995148 533.985229,348.972260 534.431274,348.979553 
	C581.593323,348.650513 628.309265,348.314117 675.015747,347.984802 
	C675.006287,347.991821 675.018677,347.986359 675.238403,348.251709 
	C676.939453,348.960571 678.420837,349.404022 679.457275,349.919708 
	C676.016235,350.142029 673.020081,350.292175 670.023987,350.442322 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M533.557495,351.450195 
	C536.364075,351.262177 539.170593,351.074188 542.406982,350.865204 
	C542.453674,357.373016 542.070557,363.901855 541.692688,370.341125 
	C539.243408,370.850464 537.207214,371.273895 534.492615,371.918457 
	C533.728638,365.243103 533.643066,358.346649 533.557495,351.450195 
z"/>
<path fill="#B1B8CB" opacity="1.000000" stroke="none" 
	d="
M748.647827,369.168457 
	C748.174194,364.240784 747.856323,359.341187 748.103699,354.470245 
	C748.159973,353.360291 750.004211,351.932404 751.326172,351.405731 
	C752.762939,350.833405 754.550293,351.141479 756.631531,351.099243 
	C757.078247,357.509735 757.078247,363.886658 757.078247,371.455444 
	C753.703857,370.515198 751.356201,369.861053 748.647827,369.168457 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M543.155151,684.505310 
	C542.755249,689.304382 542.194031,694.024292 542.062866,698.756104 
	C541.851196,706.390442 541.606384,706.687439 533.640198,704.667542 
	C533.118835,699.294434 532.953308,694.323486 533.268860,689.383301 
	C533.340027,688.268860 535.087341,687.261475 536.208252,686.511963 
	C537.622742,688.812988 538.955750,690.409912 540.947571,687.501587 
	C541.664185,686.455322 542.355164,685.391479 543.057800,684.335632 
	C543.057800,684.335632 543.186890,684.431458 543.155151,684.505310 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M544.792480,684.316650 
	C545.751343,682.827393 546.977844,681.541260 548.365784,680.462830 
	C553.310120,676.621155 554.517395,671.061340 556.070251,665.423523 
	C558.642456,669.913086 562.128601,666.482727 564.841125,667.060425 
	C562.364258,678.529236 555.824524,684.354797 544.792480,684.316650 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M756.070312,685.244202 
	C756.070312,685.244202 756.445557,685.031799 756.935425,684.958130 
	C757.619690,684.926331 757.814148,684.968201 758.008667,685.010071 
	C757.682983,689.607849 757.194458,694.201233 757.068542,698.804504 
	C756.861694,706.364563 756.365479,706.836304 748.691772,705.069397 
	C748.349976,699.578491 748.392334,694.089294 748.852417,688.142151 
	C749.430115,687.349609 749.590210,687.015015 749.750244,686.680420 
	C750.614868,686.495605 751.479431,686.310730 752.696777,686.460876 
	C753.801331,687.299316 754.553223,687.802734 755.305115,688.306152 
	C755.560181,687.285522 755.815247,686.264832 756.070312,685.244202 
z"/>
<path fill="#12298B" opacity="1.000000" stroke="none" 
	d="
M536.063110,686.204834 
	C535.087341,687.261475 533.340027,688.268860 533.268860,689.383301 
	C532.953308,694.323486 533.118835,699.294434 533.261292,704.665100 
	C533.355164,705.492737 533.298096,705.909546 533.325134,706.951294 
	C534.451416,707.927734 535.493530,708.279236 536.535706,708.630798 
	C535.839478,708.905701 535.143250,709.180603 534.202820,709.742432 
	C529.509216,708.189331 526.401917,705.344299 527.024902,700.055847 
	C527.548401,695.611816 528.298767,691.194519 528.923584,686.362427 
	C528.898560,685.959412 528.931763,685.474792 529.299805,685.200317 
	C531.428894,684.266663 533.190002,683.607483 534.973389,682.971558 
	C534.995667,682.994812 535.040833,683.040588 535.040833,683.040588 
	C535.296936,683.662964 535.553040,684.285339 535.999634,685.319702 
	C536.190125,685.731750 536.063110,686.204834 536.063110,686.204834 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M555.332581,394.813690 
	C555.949341,389.022034 553.687927,384.245728 549.997925,379.611755 
	C556.307800,376.291077 561.484131,379.227753 563.668640,387.664978 
	C560.448059,391.403503 560.205688,384.502747 557.227112,386.826416 
	C557.790100,388.778992 558.400879,390.897369 559.011597,393.015717 
	C558.642944,394.278107 558.274292,395.540497 557.484253,396.559265 
	C556.486084,395.815002 555.909363,395.314331 555.332581,394.813690 
z"/>
<path fill="#162B77" opacity="1.000000" stroke="none" 
	d="
M533.415771,351.335754 
	C533.643066,358.346649 533.728638,365.243103 534.210754,372.256012 
	C535.304321,372.744629 536.001404,373.116760 536.848083,373.746155 
	C537.005432,374.436646 537.013123,374.869934 536.703613,375.508545 
	C535.266479,375.470398 534.146545,375.226959 532.707031,374.828125 
	C530.605225,370.695038 527.661682,366.797913 527.378418,362.716156 
	C527.112671,358.888489 529.661377,354.865448 531.308105,350.950348 
	C532.093994,350.978241 532.543396,350.984161 532.992737,350.990112 
	C532.992737,350.990112 533.274170,351.221313 533.415771,351.335754 
z"/>
<path fill="#9195A4" opacity="1.000000" stroke="none" 
	d="
M758.420715,684.968689 
	C757.814148,684.968201 757.619690,684.926331 757.127747,684.860840 
	C760.781067,680.927490 764.731873,677.017822 768.956543,672.982178 
	C771.605286,672.590210 773.980164,672.324219 776.807373,672.043945 
	C774.562683,681.251160 767.409851,684.106628 758.420715,684.968689 
z"/>
<path fill="#152669" opacity="1.000000" stroke="none" 
	d="
M760.551514,544.744202 
	C761.009277,545.045837 761.009644,545.230774 761.015381,545.692749 
	C731.369324,545.975586 701.717834,545.981445 671.607544,545.986511 
	C672.542175,545.529541 673.934753,544.679138 675.329590,544.675476 
	C703.584290,544.601257 731.839233,544.624084 760.551514,544.744202 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M771.641113,396.999695 
	C771.034912,394.640900 770.730957,392.328400 770.732422,389.481323 
	C771.633667,387.694458 772.229614,386.442169 773.050903,384.716339 
	C775.190796,385.913452 777.074829,386.967438 778.958862,388.021423 
	C779.609863,390.784332 780.260864,393.547241 780.828552,396.716187 
	C778.448486,396.025818 776.151733,394.929352 773.816284,393.814423 
	C774.108337,394.970642 774.513428,396.574188 775.051147,398.702759 
	C773.651184,397.956482 772.797241,397.501251 771.641113,396.999695 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M593.364380,350.666412 
	C603.121094,350.325226 613.219482,350.135284 623.318481,350.104187 
	C637.909241,350.059296 652.500244,350.129547 667.534302,350.296997 
	C667.359619,350.642822 666.741699,351.017120 666.124146,351.016571 
	C641.984863,350.995209 617.845642,350.939697 593.364380,350.666412 
z"/>
<path fill="#E2E2E2" opacity="1.000000" stroke="none" 
	d="
M778.966675,387.653076 
	C777.074829,386.967438 775.190796,385.913452 773.050903,384.716339 
	C772.229614,386.442169 771.633667,387.694458 770.676025,389.153870 
	C769.085815,386.685303 767.857422,384.009552 766.452759,381.041870 
	C771.070496,377.038391 773.409363,378.511810 778.966675,387.653076 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M559.260742,393.173523 
	C558.400879,390.897369 557.790100,388.778992 557.227112,386.826416 
	C560.205688,384.502747 560.448059,391.403503 563.669678,388.040771 
	C564.628052,390.426483 565.286438,392.844391 565.910645,395.648834 
	C563.754272,395.134003 561.632080,394.232697 559.260742,393.173523 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M564.957397,666.700989 
	C562.128601,666.482727 558.642456,669.913086 556.429565,665.282349 
	C556.236816,664.471313 556.287903,663.861694 556.610596,663.103149 
	C559.612854,662.637756 562.343506,662.321411 565.472290,661.984619 
	C565.604858,663.423401 565.339233,664.882446 564.957397,666.700989 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M543.357239,350.908325 
	C547.465820,350.578125 552.041931,350.191345 556.622864,350.120422 
	C563.443787,350.014832 570.268066,350.132324 577.534546,350.299561 
	C566.594360,350.627106 555.210632,350.810059 543.357239,350.908325 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M670.365662,350.664673 
	C673.020081,350.292175 676.016235,350.142029 679.840454,349.997833 
	C681.443054,349.943420 682.217529,349.883118 682.991943,349.822784 
	C686.452759,349.922485 689.913635,350.022186 693.663696,350.381226 
	C686.204468,350.722687 678.455872,350.804840 670.365662,350.664673 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M536.870239,708.541199 
	C535.493530,708.279236 534.451416,707.927734 533.423279,707.252808 
	C536.091187,707.123535 538.744995,707.317627 541.682983,707.773193 
	C540.379700,708.173645 538.792297,708.312622 536.870239,708.541199 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M752.729980,707.663574 
	C751.453918,707.851624 749.933960,707.789368 748.346313,707.355530 
	C749.681152,707.127075 751.083618,707.270142 752.729980,707.663574 
z"/>
<path fill="#B1B8CB" opacity="1.000000" stroke="none" 
	d="
M748.805298,371.962708 
	C748.486816,371.981293 748.223999,371.738617 748.060730,371.231506 
	C748.393799,371.211853 748.627441,371.456665 748.805298,371.962708 
z"/>
<path fill="#1B41D9" opacity="1.000000" stroke="none" 
	d="
M885.576416,660.339233 
	C885.841187,660.356445 885.918457,660.564758 885.901428,660.966431 
	C885.560120,661.041260 885.420654,660.831482 885.576416,660.339233 
z"/>
<path fill="#B0B1B4" opacity="1.000000" stroke="none" 
	d="
M277.812225,400.994019 
	C275.317230,408.984741 273.163544,417.101440 270.269531,424.944916 
	C255.949127,463.756561 241.454849,502.504150 226.977844,541.257874 
	C218.435898,564.123901 209.755692,586.938354 201.244568,609.815857 
	C195.410660,625.497131 189.859238,641.283569 183.998962,656.954773 
	C178.873932,670.659912 170.081360,680.678833 155.242813,684.188599 
	C153.658905,684.563232 151.988861,684.573669 150.130371,684.274231 
	C158.916595,680.596985 165.916626,674.992737 169.973801,666.234497 
	C173.382584,658.875977 176.735596,651.462524 179.564102,643.868835 
	C203.516159,579.564758 227.337402,515.211975 251.209396,450.878052 
	C257.270569,434.543457 263.372772,418.224060 269.849854,401.919739 
	C271.164001,401.627289 272.084442,401.312836 273.002441,400.999207 
	C273.000000,401.000000 272.996429,401.002380 273.235504,401.148132 
	C274.920471,401.193939 276.366333,401.093964 277.812225,400.994019 
z"/>
<path fill="#1B41D9" opacity="1.000000" stroke="none" 
	d="
M273.036743,531.037598 
	C273.142792,530.441406 273.248871,529.845154 273.990509,529.070251 
	C275.759369,527.184692 277.223694,525.608337 277.958344,523.744385 
	C279.579895,519.630066 280.795319,515.357544 282.246033,511.173676 
	C285.150696,502.796631 288.102112,494.435760 291.274963,486.052582 
	C291.515717,486.036926 291.996338,485.993805 292.155548,486.314087 
	C292.700562,488.117371 293.174530,489.584381 293.447845,491.087830 
	C293.730774,492.644043 293.402039,494.452271 294.083710,495.764282 
	C295.306152,498.117065 297.872223,499.983795 298.477936,502.403748 
	C300.054291,508.701599 304.799866,513.584534 305.327728,520.484436 
	C305.716766,525.569092 308.285370,530.513062 310.052185,535.459229 
	C311.922852,540.696350 314.318481,545.768433 315.853729,551.094482 
	C316.750946,554.207092 315.406891,557.557861 311.687164,557.757202 
	C306.517670,558.034241 301.301300,557.388916 296.102875,557.195679 
	C293.782288,557.109436 291.454651,557.097473 289.133484,557.165039 
	C283.353851,557.333191 277.545502,558.003296 271.809723,557.601074 
	C268.636902,557.378479 267.564728,558.373779 266.618652,560.983215 
	C265.523834,560.978394 264.793945,560.969788 264.051758,560.591919 
	C264.043427,559.481750 264.047424,558.740906 264.334900,557.795410 
	C267.309265,550.573914 270.096008,543.591125 272.620422,536.514771 
	C273.213837,534.851257 272.921570,532.871765 273.036743,531.037598 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M295.969910,466.938171 
	C296.728485,464.954468 297.155853,462.733643 298.351685,461.062897 
	C299.289612,459.752472 301.099091,458.884369 302.698547,458.353302 
	C303.112000,458.216003 304.485596,459.966583 304.919403,461.049744 
	C309.290527,471.964935 313.584930,482.911224 317.847473,493.869507 
	C323.241943,507.737701 328.545990,521.641174 333.966431,535.499146 
	C338.016449,545.853394 342.189026,556.159729 346.839417,567.822571 
	C343.485199,567.822571 340.509583,567.822571 337.112701,567.411133 
	C329.287262,548.486450 321.783875,530.012085 314.512817,511.446655 
	C309.420837,498.445160 304.710022,485.294647 299.661163,472.275787 
	C298.900482,470.314423 297.223206,468.708496 295.969910,466.938171 
z"/>
<path fill="#1D2A5E" opacity="1.000000" stroke="none" 
	d="
M269.456116,401.897705 
	C263.372772,418.224060 257.270569,434.543457 251.209396,450.878052 
	C227.337402,515.211975 203.516159,579.564758 179.564102,643.868835 
	C176.735596,651.462524 173.382584,658.875977 169.973801,666.234497 
	C165.916626,674.992737 158.916595,680.596985 149.727295,684.086304 
	C149.198181,684.497559 148.843750,684.617859 147.860855,684.772644 
	C145.160950,685.055908 143.089478,685.304688 141.125092,685.237488 
	C151.537872,680.083679 162.354095,675.835999 167.640427,664.572021 
	C170.946609,657.527283 174.117798,650.406372 177.000198,643.180359 
	C178.693146,638.936218 179.701340,634.418945 181.284149,629.795654 
	C186.023407,617.821716 190.619492,606.121704 194.932526,594.318298 
	C198.925415,583.390991 202.478851,572.303162 206.478851,561.378601 
	C210.181412,551.266418 214.264175,541.294006 218.095627,531.228149 
	C221.411484,522.516785 224.690445,513.789551 227.829437,505.013428 
	C229.714676,499.742645 231.300293,494.364746 233.362732,488.969971 
	C234.469513,487.267548 235.234146,485.630127 236.003143,483.620941 
	C236.016083,482.505737 236.024689,481.762268 236.387604,480.947327 
	C239.800964,472.547302 242.860031,464.218811 245.930145,455.484253 
	C245.976440,453.724457 246.011703,452.370758 246.295074,450.846619 
	C247.032913,449.795074 247.522614,448.913940 248.373779,447.958313 
	C251.143234,442.049805 253.760437,436.291687 255.909103,430.363678 
	C258.957733,421.952820 261.741089,413.442657 264.490417,404.927307 
	C267.385864,395.959442 270.002167,386.961823 261.766205,379.101868 
	C261.286438,378.643951 261.388031,377.576813 261.514465,376.842560 
	C262.206116,376.927673 262.602356,376.963409 262.998596,376.999176 
	C262.998596,376.999176 263.049225,377.014038 263.089142,377.259949 
	C263.429047,377.527954 263.729034,377.550110 264.029022,377.572266 
	C264.202026,377.600952 264.375061,377.629669 264.832275,378.101562 
	C266.957794,382.080566 268.799103,385.616394 270.590698,389.469391 
	C270.673004,390.188202 270.805023,390.589844 270.865112,391.327271 
	C270.836029,392.116730 270.878876,392.570404 270.742981,393.343018 
	C270.366516,395.105286 270.168823,396.548645 269.832733,398.364380 
	C269.614929,399.790375 269.535522,400.844055 269.456116,401.897705 
z"/>
<path fill="#D9DAD9" opacity="1.000000" stroke="none" 
	d="
M352.477875,605.388550 
	C360.893921,604.390320 362.113312,605.197876 364.714386,612.905945 
	C369.416962,626.841919 375.553497,640.289978 380.904816,654.014465 
	C382.029480,656.899048 382.349091,660.097473 382.651550,663.612183 
	C380.510956,664.381287 378.758545,664.689453 376.733948,664.891052 
	C375.708649,667.506348 374.955566,670.228088 374.136047,672.534912 
	C374.660461,664.333374 372.838196,657.077637 369.902435,649.877747 
	C363.889618,635.131470 358.263306,620.227539 352.477875,605.388550 
z"/>
<path fill="#1D2A5E" opacity="1.000000" stroke="none" 
	d="
M295.673859,467.162872 
	C297.223206,468.708496 298.900482,470.314423 299.661163,472.275787 
	C304.710022,485.294647 309.420837,498.445160 314.512817,511.446655 
	C321.783875,530.012085 329.287262,548.486450 336.646240,567.355408 
	C312.208923,567.717590 287.816742,567.745178 263.424896,567.664062 
	C262.276886,567.660217 261.132080,566.688843 260.598511,565.912354 
	C262.132141,565.714172 263.053040,565.771667 264.442169,565.887817 
	C284.243713,565.935791 303.577026,565.925171 323.301697,565.936768 
	C326.787720,565.680359 330.076233,564.692993 330.050964,561.301147 
	C330.030243,558.518494 328.105164,555.750061 327.016144,552.985962 
	C327.008087,552.996704 327.023041,552.986328 327.022858,552.620911 
	C326.680206,551.168518 326.337769,550.081482 325.972595,548.676514 
	C325.637054,547.573486 325.324249,546.788452 325.011475,545.600037 
	C324.201721,541.890686 323.843597,538.375549 322.439667,535.344849 
	C321.309601,532.905396 318.914825,531.051819 317.070374,528.700073 
	C317.059509,528.461853 317.015564,527.986938 317.016754,527.575073 
	C315.732452,523.135620 314.609161,519.047546 313.132263,515.091431 
	C307.924469,501.141449 302.595428,487.236755 297.333466,473.370392 
	C295.999847,473.990204 295.002411,474.453766 294.004974,474.917358 
	C294.462585,472.407440 294.920197,469.897491 295.673859,467.162872 
z"/>
<path fill="#1F2F70" opacity="1.000000" stroke="none" 
	d="
M496.975586,710.953552 
	C456.498077,710.972290 416.020477,711.027893 375.543091,710.966858 
	C369.977417,710.958496 364.412628,710.370789 358.430664,709.783691 
	C358.010406,709.177490 358.006927,708.838806 358.244598,708.388611 
	C358.631317,708.176147 358.776886,708.075195 358.922424,707.974243 
	C403.320068,708.050598 447.717743,708.126648 492.115387,708.204529 
	C493.445435,708.206909 494.775391,708.229065 496.561218,708.681641 
	C497.003235,709.731995 496.989380,710.342773 496.975586,710.953552 
z"/>
<path fill="#162B77" opacity="1.000000" stroke="none" 
	d="
M352.537415,348.051208 
	C352.942200,348.444366 352.948944,348.825073 352.475616,349.549866 
	C323.672607,349.941895 295.349640,349.977875 267.026855,350.066803 
	C265.263672,350.072327 263.501831,350.493286 261.739349,350.721008 
	C261.739349,350.721008 261.356415,350.828949 260.761658,350.939026 
	C259.007385,351.123840 257.838715,351.329346 256.690186,351.248566 
	C253.341507,351.012939 251.876389,352.261200 252.064468,355.834686 
	C252.308502,360.470551 252.110153,365.131622 252.043152,369.781006 
	C252.011200,371.998352 252.195969,373.894348 255.219879,373.279022 
	C255.219879,373.279022 255.335510,373.554565 255.191498,374.036591 
	C255.022583,375.012299 254.997665,375.506012 254.605194,376.003998 
	C252.485947,374.648956 249.618301,373.566895 249.198349,371.876678 
	C248.079514,367.373627 247.079758,362.474030 247.728622,357.976776 
	C248.999207,349.170349 250.965073,348.021271 259.944458,348.015594 
	C286.386383,347.998962 312.828339,348.023651 339.270142,347.963531 
	C341.175385,347.959198 343.079376,347.401367 344.983948,347.100891 
	C347.369080,347.413513 349.754242,347.726166 352.537415,348.051208 
z"/>
<path fill="#B8B9BC" opacity="1.000000" stroke="none" 
	d="
M358.719177,707.740417 
	C358.776886,708.075195 358.631317,708.176147 357.972107,708.312134 
	C356.393280,708.198975 355.328064,708.050659 354.265381,707.473267 
	C354.237976,700.389893 354.208130,693.735596 354.470276,686.954651 
	C355.501831,686.445618 356.241394,686.063293 357.349060,685.776306 
	C365.597412,684.607361 371.314758,680.583618 374.202484,672.949890 
	C374.955566,670.228088 375.708649,667.506348 376.728638,665.285645 
	C378.443085,668.613159 379.890625,671.439575 381.338135,674.266052 
	C379.382965,680.820068 373.959076,683.478699 368.138397,684.741028 
	C364.590302,685.510498 363.871704,687.001953 363.957489,690.002319 
	C364.037750,692.810608 363.938416,695.623779 363.931366,698.434814 
	C363.912201,706.069458 363.914978,706.069458 355.504669,705.859192 
	C356.996521,706.675354 357.756226,707.090942 358.719177,707.740417 
z"/>
<path fill="#1F2F70" opacity="1.000000" stroke="none" 
	d="
M374.136047,672.534912 
	C371.314758,680.583618 365.597412,684.607361 357.408569,685.445496 
	C358.277557,684.232300 359.554535,683.559448 360.613953,682.636780 
	C363.776215,679.882446 368.625244,677.540710 369.612244,674.114868 
	C372.024048,665.743530 370.303986,657.300415 366.624603,649.149841 
	C362.857483,640.804932 359.851685,632.103577 356.768127,623.467102 
	C354.576874,617.329895 353.294861,610.754761 347.042175,606.759888 
	C347.031006,606.025635 347.012329,605.606323 346.993652,605.186951 
	C348.564728,605.196472 350.135773,605.205994 352.092346,605.302002 
	C358.263306,620.227539 363.889618,635.131470 369.902435,649.877747 
	C372.838196,657.077637 374.660461,664.333374 374.136047,672.534912 
z"/>
<path fill="#162B77" opacity="1.000000" stroke="none" 
	d="
M181.017532,630.024536 
	C179.701340,634.418945 178.693146,638.936218 177.000198,643.180359 
	C174.117798,650.406372 170.946609,657.527283 167.640427,664.572021 
	C162.354095,675.835999 151.537872,680.083679 140.767273,685.439331 
	C139.895737,687.975952 139.489105,689.994690 139.010284,692.424927 
	C138.979660,694.225342 139.021240,695.614319 138.960709,697.394409 
	C138.916977,698.855713 138.975342,699.925781 138.955536,701.397461 
	C138.957535,702.871155 139.037704,703.943237 139.117859,705.015320 
	C139.080048,705.474487 139.042252,705.933716 138.924133,706.645935 
	C138.843811,706.898987 138.398819,707.045227 138.264954,707.254333 
	C137.767166,707.985352 137.403229,708.507202 137.039276,709.029114 
	C137.039276,709.029114 137.010147,709.013306 136.964142,708.640991 
	C135.907440,704.915100 134.500549,701.623596 133.962677,698.195801 
	C132.536606,689.107361 135.813583,684.416809 144.073624,681.018738 
	C154.107513,676.890869 162.745346,671.060303 167.007370,660.213257 
	C171.015060,650.013367 175.617615,640.047302 180.223450,629.990479 
	C180.664810,630.009949 180.841171,630.017273 181.017532,630.024536 
z"/>
<path fill="#0F2277" opacity="1.000000" stroke="none" 
	d="
M230.482666,708.465454 
	C230.939545,709.162903 230.948776,709.734619 230.937775,710.588379 
	C229.869629,710.901428 228.821716,710.932495 227.125153,710.710938 
	C223.535400,710.634521 220.594818,710.952271 217.653107,710.963013 
	C202.572128,711.018066 187.489639,711.110168 172.410080,710.955933 
	C162.669464,710.856262 152.930832,710.403076 143.197739,709.964966 
	C142.152786,709.918030 141.150986,708.912415 140.166016,708.154907 
	C140.202866,707.958313 139.960999,707.639771 139.960999,707.639771 
	C141.041946,707.771606 142.122910,707.903442 143.990112,708.136719 
	C145.837112,708.164673 146.897888,708.091064 147.958649,708.017456 
	C172.001129,708.092468 196.043640,708.164978 220.086090,708.245544 
	C223.402481,708.256653 226.718735,708.307312 230.482666,708.465454 
z"/>
<path fill="#1430A3" opacity="1.000000" stroke="none" 
	d="
M317.015564,527.986938 
	C317.015564,527.986938 317.059509,528.461853 317.040253,529.104370 
	C317.738495,532.070801 318.455444,534.394958 319.173615,536.718689 
	C320.128326,539.807861 321.083954,542.896729 322.016968,546.255249 
	C321.974854,546.703857 321.954987,546.882935 321.696289,547.054199 
	C321.457397,547.046448 320.980469,547.014282 320.952057,546.660400 
	C319.290985,544.199463 317.658325,542.092529 315.981689,539.621460 
	C315.629272,537.837524 315.320862,536.417664 315.263489,534.779358 
	C314.020111,529.715881 314.436859,523.954285 308.996216,520.594604 
	C304.032898,507.663391 299.081909,495.133545 293.618408,481.306702 
	C292.724731,483.889069 292.360535,484.941437 291.996338,485.993805 
	C291.996338,485.993805 291.515717,486.036926 290.964294,486.010315 
	C290.204865,485.966614 289.996857,485.949585 289.788879,485.932556 
	C290.294434,484.403320 290.799988,482.874054 291.928345,481.112976 
	C293.422821,480.049133 294.294525,479.217133 295.974091,477.614044 
	C300.875397,490.556915 305.422333,503.010895 310.341522,515.316040 
	C312.103851,519.724487 314.764771,523.773743 317.015564,527.986938 
z"/>
<path fill="#0F1E5E" opacity="1.000000" stroke="none" 
	d="
M346.661499,605.102173 
	C347.012329,605.606323 347.031006,606.025635 346.774780,606.741943 
	C346.321716,607.036377 346.143494,607.033813 345.572418,607.020264 
	C344.129456,607.006104 343.079376,607.003052 341.603210,606.995605 
	C339.747955,607.010742 338.318726,607.030334 336.889526,607.049866 
	C307.271179,607.064148 277.652832,607.078430 247.386246,606.983398 
	C247.152237,606.347595 247.566467,605.821228 248.397186,605.224609 
	C250.216965,605.201843 251.620285,605.249268 253.223053,605.496094 
	C254.273773,605.564941 255.125031,605.434387 256.440979,605.230347 
	C283.605957,605.204285 310.306274,605.251709 337.100830,605.436218 
	C337.195129,605.573303 337.653992,605.634460 337.653992,605.634460 
	C337.653992,605.634460 337.991882,605.294189 338.425629,605.220703 
	C340.898224,605.038879 342.937103,604.930481 344.975952,604.822021 
	C345.427094,604.887207 345.878235,604.952332 346.661499,605.102173 
z"/>
<path fill="#B1B8CB" opacity="1.000000" stroke="none" 
	d="
M255.097961,372.983948 
	C252.195969,373.894348 252.011200,371.998352 252.043152,369.781006 
	C252.110153,365.131622 252.308502,360.470551 252.064468,355.834686 
	C251.876389,352.261200 253.341507,351.012939 256.690186,351.248566 
	C257.838715,351.329346 259.007385,351.123840 260.610748,351.068665 
	C261.054626,357.493896 261.054626,363.899597 261.054626,369.385895 
	C258.417725,370.818756 256.696899,371.753815 255.097961,372.983948 
z"/>
<path fill="#1A38B2" opacity="1.000000" stroke="none" 
	d="
M263.973938,565.829163 
	C263.053040,565.771667 262.132141,565.714172 260.761475,565.543579 
	C260.321686,565.098511 260.331635,564.766479 260.876740,564.213379 
	C262.295959,562.981934 263.180023,561.971558 264.064087,560.961182 
	C264.793945,560.969788 265.523834,560.978394 267.089111,560.990479 
	C283.645172,560.995728 299.366211,560.937256 315.086212,561.039246 
	C319.287567,561.066528 321.150299,559.379578 321.068878,555.133484 
	C321.029785,553.096191 321.672089,551.045776 322.293579,549.215332 
	C326.957947,553.543091 324.079681,558.419678 323.746490,563.004028 
	C323.310394,563.014587 323.136078,563.022156 322.495514,563.023621 
	C304.209625,563.018372 286.389954,563.005493 268.570343,563.039062 
	C266.553833,563.042908 263.902985,562.269714 263.973938,565.829163 
z"/>
<path fill="#0F2781" opacity="1.000000" stroke="none" 
	d="
M317.016754,527.575073 
	C314.764771,523.773743 312.103851,519.724487 310.341522,515.316040 
	C305.422333,503.010895 300.875397,490.556915 295.974091,477.614044 
	C294.294525,479.217133 293.422821,480.049133 292.203369,480.904999 
	C292.412720,479.147522 292.969818,477.366211 293.765930,475.251129 
	C295.002411,474.453766 295.999847,473.990204 297.333466,473.370392 
	C302.595428,487.236755 307.924469,501.141449 313.132263,515.091431 
	C314.609161,519.047546 315.732452,523.135620 317.016754,527.575073 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M270.640381,389.152222 
	C268.799103,385.616394 266.957794,382.080566 265.082703,378.182281 
	C275.678192,377.754059 278.133148,380.286987 279.424225,388.668335 
	C276.214264,389.062378 273.427338,389.107300 270.640381,389.152222 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M139.501343,705.084839 
	C139.037704,703.943237 138.957535,702.871155 139.207291,701.184387 
	C139.379089,699.380859 139.220947,698.192078 139.062820,697.003235 
	C139.021240,695.614319 138.979660,694.225342 139.341385,692.383057 
	C140.761795,691.278992 141.778915,690.628296 143.192688,689.723816 
	C143.192688,693.415833 143.192688,696.676208 143.192688,699.936584 
	C143.372604,699.941406 143.552505,699.946289 143.732422,699.951111 
	C143.944763,696.489380 144.157120,693.027649 144.369461,689.565918 
	C144.923508,689.603210 145.477554,689.640503 146.031601,689.677795 
	C146.031601,691.768738 145.804108,693.892822 146.101974,695.940430 
	C146.350189,697.646729 147.214813,699.263245 147.806412,700.919556 
	C147.713837,706.209778 146.619049,706.795044 139.501343,705.084839 
z"/>
<path fill="#DEDEDE" opacity="1.000000" stroke="none" 
	d="
M253.023590,605.296753 
	C251.620285,605.249268 250.216965,605.201843 248.044083,605.122803 
	C246.540787,605.102783 245.807083,605.114258 245.073364,605.125793 
	C246.267395,604.752136 247.460861,604.054260 248.655533,604.052185 
	C280.065155,603.997437 311.474884,604.013489 342.884613,604.031128 
	C343.371094,604.031433 343.857544,604.203674 344.659973,604.558838 
	C342.937103,604.930481 340.898224,605.038879 338.178894,605.142517 
	C337.334473,605.191528 337.170532,605.245361 337.006561,605.299133 
	C310.306274,605.251709 283.605957,605.204285 256.078278,605.132812 
	C254.508469,605.171448 253.766037,605.234070 253.023590,605.296753 
z"/>
<path fill="#B1B8CB" opacity="1.000000" stroke="none" 
	d="
M262.215027,350.833435 
	C263.501831,350.493286 265.263672,350.072327 267.026855,350.066803 
	C295.349640,349.977875 323.672607,349.941895 352.453003,349.835419 
	C355.261993,349.884094 357.613464,349.991211 360.398743,350.132874 
	C360.832581,350.167389 360.846710,350.604187 360.785828,350.812988 
	C328.046906,350.996490 295.368805,350.971191 262.215027,350.833435 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M289.535767,486.180603 
	C289.996857,485.949585 290.204865,485.966614 290.723541,486.025940 
	C288.102112,494.435760 285.150696,502.796631 282.246033,511.173676 
	C280.795319,515.357544 279.579895,519.630066 277.958344,523.744385 
	C277.223694,525.608337 275.759369,527.184692 274.266724,528.930908 
	C279.032471,514.789795 284.157562,500.609222 289.535767,486.180603 
z"/>
<path fill="#B0B1B4" opacity="1.000000" stroke="none" 
	d="
M147.859619,700.475098 
	C147.214813,699.263245 146.350189,697.646729 146.101974,695.940430 
	C145.804108,693.892822 146.031601,691.768738 146.031601,689.677795 
	C145.477554,689.640503 144.923508,689.603210 144.369461,689.565918 
	C144.157120,693.027649 143.944763,696.489380 143.732422,699.951111 
	C143.552505,699.946289 143.372604,699.941406 143.192688,699.936584 
	C143.192688,696.676208 143.192688,693.415833 143.192688,689.723816 
	C141.778915,690.628296 140.761795,691.278992 139.413574,691.971558 
	C139.489105,689.994690 139.895737,687.975952 140.660187,685.755371 
	C143.089478,685.304688 145.160950,685.055908 147.620575,684.918701 
	C147.976761,690.030396 147.944794,695.030518 147.859619,700.475098 
z"/>
<path fill="#1A38B2" opacity="1.000000" stroke="none" 
	d="
M272.795471,531.297729 
	C272.921570,532.871765 273.213837,534.851257 272.620422,536.514771 
	C270.096008,543.591125 267.309265,550.573914 264.086975,557.806519 
	C263.555664,558.022278 263.062103,558.058594 263.062103,558.058594 
	C263.214600,557.468506 263.367126,556.878357 263.720093,555.905884 
	C263.920532,555.523499 263.986023,555.607849 263.986023,555.607849 
	C266.842102,547.591187 269.698151,539.574524 272.795471,531.297729 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M270.590698,389.469391 
	C273.427338,389.107300 276.214264,389.062378 279.355865,389.054321 
	C279.663757,390.878052 279.617004,392.664856 278.973145,394.591187 
	C276.248901,394.157257 274.121704,393.583862 272.225769,392.833923 
	C271.950348,392.102112 271.443695,391.546783 270.937042,390.991486 
	C270.805023,390.589844 270.673004,390.188202 270.590698,389.469391 
z"/>
<path fill="#B0B1B4" opacity="1.000000" stroke="none" 
	d="
M271.994537,393.010437 
	C274.121704,393.583862 276.248901,394.157257 278.732910,394.882721 
	C279.018524,396.077484 278.947235,397.120209 278.445831,398.560852 
	C276.657990,398.608002 275.300293,398.257263 273.607635,397.819977 
	C273.409943,398.849304 273.203186,399.925842 272.996429,401.002380 
	C272.996429,401.002380 273.000000,401.000000 272.740112,400.797424 
	C271.643890,399.727234 270.807526,398.859619 269.971130,397.992035 
	C270.168823,396.548645 270.366516,395.105286 271.011108,393.327698 
	C271.636871,392.999115 271.815704,393.004791 271.994537,393.010437 
z"/>
<path fill="#1433AA" opacity="1.000000" stroke="none" 
	d="
M522.699829,687.421692 
	C523.413025,686.709473 523.976135,686.445374 524.761841,686.106445 
	C524.989258,686.471008 524.994019,686.910278 525.001709,687.925049 
	C525.004700,688.500427 525.009766,688.991821 525.009766,688.991821 
	C524.670471,694.358032 524.331177,699.724182 523.805298,705.546875 
	C523.381470,705.975830 523.143555,705.957764 522.904968,705.949280 
	C522.786560,699.922791 522.668152,693.896301 522.699829,687.421692 
z"/>
<path fill="#CFCFD0" opacity="1.000000" stroke="none" 
	d="
M381.681213,674.083801 
	C379.890625,671.439575 378.443085,668.613159 377.000824,665.392090 
	C378.758545,664.689453 380.510956,664.381287 382.645966,664.030273 
	C382.693787,667.292114 382.359070,670.596802 381.681213,674.083801 
z"/>
<path fill="#0F2277" opacity="1.000000" stroke="none" 
	d="
M497.407684,710.973633 
	C496.989380,710.342773 497.003235,709.731995 497.012268,708.815063 
	C499.383270,708.433655 501.759094,708.358337 504.554321,708.662659 
	C504.995087,709.362122 505.012177,709.682190 505.024963,710.002441 
	C502.629913,710.332886 500.234863,710.663330 497.407684,710.973633 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M273.235504,401.148132 
	C273.203186,399.925842 273.409943,398.849304 273.607635,397.819977 
	C275.300293,398.257263 276.657990,398.608002 278.346222,398.940979 
	C278.570465,399.508179 278.464203,400.093140 278.085083,400.836029 
	C276.366333,401.093964 274.920471,401.193939 273.235504,401.148132 
z"/>
<path fill="#1F2F70" opacity="1.000000" stroke="none" 
	d="
M505.428955,710.003052 
	C505.012177,709.682190 504.995087,709.362122 504.978058,708.802185 
	C507.015808,708.484070 509.049194,708.406128 511.500885,708.452515 
	C509.890442,709.052368 507.861694,709.528015 505.428955,710.003052 
z"/>
<path fill="#B1B8CB" opacity="1.000000" stroke="none" 
	d="
M262.990784,376.655670 
	C262.602356,376.963409 262.206116,376.927673 261.504211,376.855957 
	C261.198578,376.820007 261.179291,376.845734 261.048126,376.547302 
	C260.298676,376.098358 259.680420,375.947815 259.082581,375.832092 
	C259.103027,375.866943 259.156952,375.806702 258.987305,375.525146 
	C258.212952,374.759125 257.608215,374.274689 257.003448,373.790283 
	C258.996613,374.630890 260.989807,375.471527 262.990784,376.655670 
z"/>
<path fill="#253C91" opacity="1.000000" stroke="none" 
	d="
M262.804016,558.298584 
	C263.062103,558.058594 263.555664,558.022278 263.803558,558.011108 
	C264.047424,558.740906 264.043427,559.481750 264.051758,560.591919 
	C263.180023,561.971558 262.295959,562.981934 261.051697,564.027222 
	C261.309662,562.220886 261.927795,560.379700 262.804016,558.298584 
z"/>
<path fill="#1F2F70" opacity="1.000000" stroke="none" 
	d="
M244.998917,605.451965 
	C245.807083,605.114258 246.540787,605.102783 247.627594,605.193054 
	C247.566467,605.821228 247.152237,606.347595 246.925507,607.008301 
	C246.367737,607.759338 245.622467,608.376038 244.546265,609.008423 
	C244.451706,607.942200 244.688095,606.860168 244.998917,605.451965 
z"/>
<path fill="#1D2A5E" opacity="1.000000" stroke="none" 
	d="
M256.694061,373.716553 
	C257.608215,374.274689 258.212952,374.759125 258.572754,375.588928 
	C257.214050,375.966980 256.100281,375.999695 254.987488,376.023163 
	C254.988464,376.013947 254.972748,375.999695 254.972748,375.999695 
	C254.997665,375.506012 255.022583,375.012299 255.094025,374.149811 
	C255.555252,373.734955 255.969955,373.688873 256.694061,373.716553 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M522.736694,706.286499 
	C523.143555,705.957764 523.381470,705.975830 523.796631,706.015991 
	C523.582458,706.667725 523.190186,707.306946 522.499146,708.014526 
	C522.322937,707.596558 522.445679,707.110107 522.736694,706.286499 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M147.750931,707.770813 
	C146.897888,708.091064 145.837112,708.164673 144.399261,708.095215 
	C145.195862,707.809448 146.369537,707.666809 147.750931,707.770813 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M521.796936,708.226318 
	C521.936218,708.534424 521.709045,708.700012 521.242188,708.623840 
	C521.251770,708.449341 521.394470,708.349976 521.796936,708.226318 
z"/>
<path fill="#C5C5C5" opacity="1.000000" stroke="none" 
	d="
M139.068970,706.963501 
	C139.575104,706.930359 139.790695,707.009949 139.950928,707.453308 
	C139.960999,707.639771 140.202866,707.958313 139.880692,707.933105 
	C139.171936,707.620361 138.785370,707.332825 138.398804,707.045227 
	C138.398819,707.045227 138.843811,706.898987 139.068970,706.963501 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M263.932709,377.390411 
	C263.729034,377.550110 263.429047,377.527954 263.100891,377.236298 
	C263.365173,376.927643 263.619720,377.008240 263.932709,377.390411 
z"/>
<path fill="#253C91" opacity="1.000000" stroke="none" 
	d="
M263.923950,555.564941 
	C263.986023,555.607849 263.920532,555.523499 263.932312,555.496460 
	C263.944122,555.469421 263.861877,555.522095 263.923950,555.564941 
z"/>
<path fill="#0F2277" opacity="1.000000" stroke="none" 
	d="
M345.965302,607.031311 
	C346.143494,607.033813 346.321716,607.036377 346.767303,607.056824 
	C353.294861,610.754761 354.576874,617.329895 356.768127,623.467102 
	C359.851685,632.103577 362.857483,640.804932 366.624603,649.149841 
	C370.303986,657.300415 372.024048,665.743530 369.612244,674.114868 
	C368.625244,677.540710 363.776215,679.882446 360.613953,682.636780 
	C359.554535,683.559448 358.277557,684.232300 357.040527,685.350159 
	C356.241394,686.063293 355.501831,686.445618 354.176636,686.925049 
	C353.394409,687.015198 353.197815,687.008362 352.983643,686.983643 
	C352.966034,686.965881 352.930725,686.930481 352.930725,686.930481 
	C354.917236,684.642212 356.546509,681.838074 358.960114,680.166443 
	C365.127136,675.895447 368.280548,668.910583 366.684967,661.520386 
	C363.270142,645.703735 356.085449,631.263916 350.077087,616.403931 
	C348.949585,613.615356 346.711365,611.275879 345.202393,608.260254 
	C345.692230,607.603516 345.874298,607.350159 345.965302,607.031311 
z"/>
<path fill="#0F2277" opacity="1.000000" stroke="none" 
	d="
M336.611908,607.346191 
	C338.318726,607.030334 339.747955,607.010742 341.695068,607.208130 
	C342.394409,607.690491 342.639008,607.869751 342.946777,607.962769 
	C341.729614,608.301697 340.513031,608.934143 339.295227,608.936462 
	C313.146484,608.986206 286.997589,608.967285 260.848724,608.946228 
	C260.245422,608.945740 259.642242,608.766113 259.065369,608.004883 
	C261.356354,608.004883 263.647308,608.004883 265.938293,608.004883 
	C287.590210,608.004883 309.242157,608.013489 330.894043,607.989197 
	C332.707611,607.987122 334.520874,607.763306 336.611908,607.346191 
z"/>
<path fill="#0F2277" opacity="1.000000" stroke="none" 
	d="
M352.879761,705.201660 
	C351.608490,704.217285 349.371490,703.345642 349.241058,702.228149 
	C348.790710,698.369019 348.827637,694.398987 349.221954,690.526428 
	C349.348663,689.282104 351.156128,688.208923 352.562683,686.994019 
	C352.930725,686.930481 352.966034,686.965881 352.983551,687.436890 
	C352.960632,693.672485 352.920197,699.437073 352.879761,705.201660 
z"/>
<path fill="#1F2F70" opacity="1.000000" stroke="none" 
	d="
M352.894562,705.549866 
	C352.920197,699.437073 352.960632,693.672485 353.001160,687.454712 
	C353.197815,687.008362 353.394409,687.015198 353.884644,687.051697 
	C354.208130,693.735596 354.237976,700.389893 353.963989,707.510498 
	C353.458160,707.984558 353.256195,707.992249 353.054230,708.000000 
	C353.005951,707.299377 352.957672,706.598694 352.894562,705.549866 
z"/>
<path fill="#0F2277" opacity="1.000000" stroke="none" 
	d="
M353.098358,708.372437 
	C353.256195,707.992249 353.458160,707.984558 353.961487,707.939575 
	C355.328064,708.050659 356.393280,708.198975 357.730957,708.423706 
	C358.006927,708.838806 358.010406,709.177490 358.019592,709.770630 
	C356.397675,709.598389 354.770081,709.171631 353.098358,708.372437 
z"/>
<path fill="#1F2F70" opacity="1.000000" stroke="none" 
	d="
M343.287048,607.979004 
	C342.639008,607.869751 342.394409,607.690491 342.121155,607.212524 
	C343.079376,607.003052 344.129456,607.006104 345.572418,607.020264 
	C345.874298,607.350159 345.692230,607.603516 345.203400,607.893250 
	C344.534271,607.995178 344.080811,607.995239 343.287048,607.979004 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M525.174805,688.904663 
	C525.009766,688.991821 525.004700,688.500427 525.005737,688.254883 
	C525.117798,688.278687 525.228760,688.548096 525.174805,688.904663 
z"/>
<path fill="#12298B" opacity="1.000000" stroke="none" 
	d="
M540.000000,378.001221 
	C545.477722,378.250946 552.738281,388.043793 552.998352,395.947266 
	C553.336487,406.223694 553.939758,416.500153 553.950806,426.777161 
	C554.031189,501.500305 554.027954,576.223633 553.907715,650.946655 
	C553.899597,656.019897 552.952637,661.132263 552.044434,666.154541 
	C550.391235,675.296570 544.587219,680.638977 535.405029,682.996338 
	C535.040833,683.040588 534.995667,682.994812 535.040039,682.637695 
	C537.269531,680.585938 539.555664,679.003235 541.603333,677.156128 
	C542.916565,675.971497 543.878235,674.397217 544.997559,672.997559 
	C544.997559,672.997559 545.000000,673.000000 545.352783,672.943115 
	C547.065186,670.011414 548.620911,667.204590 549.655090,664.217163 
	C549.934998,663.408691 548.636536,662.053894 548.062561,660.949829 
	C548.069275,658.289978 548.076050,655.630127 548.499268,652.488892 
	C549.608154,650.409302 550.890198,648.815613 550.902527,647.212280 
	C551.044006,628.728699 550.993286,610.243652 550.993347,591.759033 
	C550.993530,533.973694 551.011169,476.188293 550.911804,418.403137 
	C550.908691,416.597656 549.694519,414.794250 549.044983,412.989899 
	C549.340393,407.987762 549.635742,402.985657 549.949585,397.119934 
	C549.089294,393.476990 548.646606,390.446869 547.227356,387.978210 
	C545.191895,384.437744 542.443848,381.306915 540.000000,378.001221 
z"/>
<path fill="#1433AA" opacity="1.000000" stroke="none" 
	d="
M548.750000,413.245300 
	C549.694519,414.794250 550.908691,416.597656 550.911804,418.403137 
	C551.011169,476.188293 550.993530,533.973694 550.993347,591.759033 
	C550.993286,610.243652 551.044006,628.728699 550.902527,647.212280 
	C550.890198,648.815613 549.608154,650.409302 548.511841,652.046387 
	C548.385864,642.047729 548.829346,632.010864 548.879089,621.972168 
	C548.908569,616.017456 548.022339,610.057190 548.070496,604.103699 
	C548.153259,593.896545 548.843933,583.693726 548.898254,573.487122 
	C548.936584,566.283020 548.100098,559.075500 548.098572,551.869446 
	C548.096863,543.495117 548.756531,535.121582 548.800110,526.745972 
	C548.837646,519.539551 548.415955,512.330627 548.187134,505.123169 
	C548.166077,504.459656 547.998657,503.795990 548.021790,503.136658 
	C548.315491,494.763031 548.763489,486.392181 548.887085,478.016418 
	C548.950378,473.726349 548.336121,469.430725 548.220276,465.132446 
	C548.056580,459.057831 547.925110,452.975586 548.044800,446.901794 
	C548.206482,438.693146 548.687622,430.491119 548.888184,422.282776 
	C548.959534,419.360687 548.611511,416.428345 548.750000,413.245300 
z"/>
<path fill="#1A38B2" opacity="1.000000" stroke="none" 
	d="
M539.997314,345.013489 
	C542.142151,344.342743 544.285461,343.092621 546.432129,343.086823 
	C588.721191,342.972504 631.010803,343.057526 673.299683,342.908508 
	C677.042664,342.895325 679.163025,344.425171 680.527344,347.629639 
	C678.447021,347.986664 676.732849,347.986511 675.018677,347.986359 
	C675.018677,347.986359 675.006287,347.991821 675.203613,347.736877 
	C675.935181,347.169647 676.469482,346.857361 677.949402,345.992340 
	C675.425415,345.992340 673.857605,345.992371 672.289734,345.992340 
	C630.083984,345.991577 587.878174,346.003479 545.672485,345.948730 
	C543.780334,345.946259 541.889038,345.339020 539.997314,345.013489 
z"/>
<path fill="#1430A3" opacity="1.000000" stroke="none" 
	d="
M539.569702,345.008850 
	C541.889038,345.339020 543.780334,345.946259 545.672485,345.948730 
	C587.878174,346.003479 630.083984,345.991577 672.289734,345.992340 
	C673.857605,345.992371 675.425415,345.992340 677.949402,345.992340 
	C676.469482,346.857361 675.935181,347.169647 675.213135,347.729828 
	C628.309265,348.314117 581.593323,348.650513 534.433533,348.584137 
	C533.996460,347.125000 534.003113,346.068604 534.009766,345.012207 
	C535.720581,345.009521 537.431335,345.006866 539.569702,345.008850 
z"/>
<path fill="#1A38B2" opacity="1.000000" stroke="none" 
	d="
M533.612915,345.006866 
	C534.003113,346.068604 533.996460,347.125000 533.987488,348.576843 
	C533.985229,348.972260 533.990417,348.995148 533.634888,348.996460 
	C532.510010,349.641296 531.740784,350.284821 530.971558,350.928345 
	C529.661377,354.865448 527.112671,358.888489 527.378418,362.716156 
	C527.661682,366.797913 530.605225,370.695038 532.763184,375.187988 
	C534.423462,376.143677 535.707947,376.584076 536.992859,377.397308 
	C536.992249,378.516113 536.991272,379.262054 536.990234,380.008026 
	C534.686768,380.008026 532.383301,380.008026 530.712341,380.008026 
	C528.820862,377.391388 526.597290,375.450623 525.875427,373.056976 
	C524.724182,369.239624 524.966675,365.012970 523.910583,361.153473 
	C522.046875,354.342224 528.817627,351.244659 530.076965,345.817902 
	C530.190369,345.329285 532.126465,345.263611 533.612915,345.006866 
z"/>
<path fill="#1433AA" opacity="1.000000" stroke="none" 
	d="
M537.266541,380.208832 
	C536.991272,379.262054 536.992249,378.516113 537.326782,377.389282 
	C538.105286,377.007111 538.550354,377.005768 538.995422,377.004425 
	C539.313049,377.112915 539.573303,377.300903 539.888062,377.784790 
	C542.443848,381.306915 545.191895,384.437744 547.227356,387.978210 
	C548.646606,390.446869 549.089294,393.476990 549.935181,396.663055 
	C548.963806,396.088440 547.705994,395.260345 547.147278,394.096649 
	C545.542419,390.753693 545.554138,386.600647 541.205505,384.769623 
	C539.648621,384.114166 538.744751,381.908020 537.266541,380.208832 
z"/>
<path fill="#1433AA" opacity="1.000000" stroke="none" 
	d="
M544.626526,672.997314 
	C543.878235,674.397217 542.916565,675.971497 541.603333,677.156128 
	C539.555664,679.003235 537.269531,680.585938 535.017822,682.614502 
	C533.190002,683.607483 531.428894,684.266663 529.305420,684.957520 
	C528.975281,683.884888 528.587585,682.101685 529.104797,681.781738 
	C534.067810,678.712524 539.183533,675.890137 544.626526,672.997314 
z"/>
<path fill="#1433AA" opacity="1.000000" stroke="none" 
	d="
M548.031738,661.337158 
	C548.636536,662.053894 549.934998,663.408691 549.655090,664.217163 
	C548.620911,667.204590 547.065186,670.011414 545.351562,672.941895 
	C545.998657,669.239868 546.999817,665.482117 548.031738,661.337158 
z"/>
<path fill="#1430A3" opacity="1.000000" stroke="none" 
	d="
M675.238403,348.251709 
	C676.732849,347.986511 678.447021,347.986664 680.591125,347.980164 
	C681.460693,347.982391 681.900330,347.991241 682.669983,348.000580 
	C683.002625,348.406006 683.005249,348.810913 682.999939,349.519318 
	C682.217529,349.883118 681.443054,349.943420 680.285400,349.925629 
	C678.420837,349.404022 676.939453,348.960571 675.238403,348.251709 
z"/>
<path fill="#253C91" opacity="1.000000" stroke="none" 
	d="
M441.356445,193.018585 
	C440.202332,192.068451 439.393463,191.160431 438.308228,190.118683 
	C437.020996,187.622452 435.700958,185.341080 435.055664,182.882584 
	C432.898163,174.662994 439.412659,171.021652 444.278931,166.978455 
	C458.196503,155.414886 474.011627,146.909500 491.415466,142.274368 
	C502.415100,139.344849 513.490540,136.685974 525.512024,137.477295 
	C536.646240,138.210251 547.883057,137.974258 558.910522,140.061844 
	C572.419189,142.619171 585.233521,147.244110 597.006714,154.444229 
	C601.879272,157.424118 606.548828,160.735931 611.769897,164.218155 
	C612.820984,165.022797 613.412903,165.506073 614.004822,165.989349 
	C614.464661,166.312943 614.924500,166.636520 615.721497,167.334641 
	C618.306885,170.568909 620.555115,173.428635 622.448242,176.279633 
	C616.398621,171.735046 610.924927,166.883408 604.971191,162.718109 
	C579.548157,144.931976 551.001648,138.487579 520.375793,140.242172 
	C489.301666,142.022446 462.344910,153.619461 439.518036,174.774796 
	C435.984131,178.049881 435.951263,182.586868 439.361450,186.070755 
	C443.314911,190.109634 447.277863,194.142273 451.340332,198.070404 
	C454.343506,200.974243 459.453125,201.040726 462.534424,198.174942 
	C480.383240,181.574692 501.541229,172.099518 525.747314,170.688583 
	C541.745422,169.756088 557.363220,172.691864 570.854004,181.757614 
	C563.624817,179.866241 556.815186,177.782516 549.943115,175.929901 
	C548.404846,175.515182 546.648254,175.910507 544.992126,175.932922 
	C544.561096,175.937576 544.130005,175.942230 543.290466,175.579727 
	C540.611206,174.477921 538.380188,173.285400 536.063110,173.084045 
	C522.552429,171.910065 509.597870,174.950729 497.163696,179.700150 
	C492.910339,181.324768 486.933167,181.127563 484.970795,186.977463 
	C484.970795,186.977463 484.501770,187.003860 483.892517,187.007721 
	C480.239777,188.258347 476.021515,188.066269 475.972778,192.980682 
	C475.972778,192.980682 475.504639,193.011093 474.920044,193.034119 
	C472.553955,194.359665 470.772491,195.662170 468.990997,196.964691 
	C468.237549,197.258408 467.364410,197.396301 466.748566,197.869247 
	C457.415253,205.037186 453.178375,204.652145 444.970215,195.896820 
	C443.988861,194.850037 442.796295,194.001251 441.356445,193.018585 
z"/>
<path fill="#1E338B" opacity="1.000000" stroke="none" 
	d="
M620.106018,190.687347 
	C616.671692,194.069260 613.371460,197.175171 610.009155,200.611923 
	C609.947021,200.942749 609.483337,200.988190 608.834900,201.006775 
	C604.120789,201.021393 600.055115,201.017410 595.989441,201.013397 
	C595.526367,200.693192 595.063293,200.372971 594.269104,199.664764 
	C593.674744,198.137405 593.411499,196.998047 593.507568,195.893951 
	C595.019287,196.822632 596.075378,198.282608 597.342896,198.497879 
	C600.510193,199.035751 605.285767,200.463867 606.736450,198.956314 
	C613.014709,192.432037 622.780396,188.096573 622.980103,176.926788 
	C623.594055,176.961227 623.839905,176.968796 624.085815,176.976379 
	C624.369385,178.086197 624.653015,179.196014 624.701111,180.863434 
	C624.355530,182.297577 624.245422,183.174149 624.135254,184.050705 
	C624.105835,184.484299 624.076416,184.917892 623.785034,185.833374 
	C623.330688,186.861282 623.138123,187.407303 622.945557,187.953323 
	C622.043762,188.772659 621.141968,189.592010 620.106018,190.687347 
z"/>
<path fill="#1A38B2" opacity="1.000000" stroke="none" 
	d="
M624.312866,176.797028 
	C623.839905,176.968796 623.594055,176.961227 623.010681,176.768250 
	C622.673218,176.582825 622.803345,176.288345 622.803345,176.288361 
	C620.555115,173.428635 618.306885,170.568909 616.014404,167.365005 
	C620.210266,168.984344 625.899475,169.653824 624.312866,176.797028 
z"/>
<path fill="#1839BC" opacity="1.000000" stroke="none" 
	d="
M621.033142,161.346985 
	C622.642273,162.092072 624.289429,163.185364 625.987671,164.629547 
	C624.382935,163.885361 622.727051,162.790268 621.033142,161.346985 
z"/>
<path fill="#1839BC" opacity="1.000000" stroke="none" 
	d="
M634.070435,169.406158 
	C635.759277,168.812805 637.529663,168.585922 639.668457,168.261887 
	C639.198975,172.813950 636.709839,171.418991 634.070435,169.406158 
z"/>
<path fill="#1A38B2" opacity="1.000000" stroke="none" 
	d="
M624.444641,183.983490 
	C624.245422,183.174149 624.355530,182.297577 624.727173,181.209793 
	C624.910522,181.971130 624.832275,182.943695 624.444641,183.983490 
z"/>
<path fill="#1A38B2" opacity="1.000000" stroke="none" 
	d="
M614.106079,165.722977 
	C613.412903,165.506073 612.820984,165.022797 612.110840,164.271652 
	C612.537842,163.798264 613.082947,163.592758 613.628113,163.387238 
	C613.821167,164.077026 614.014221,164.766830 614.106079,165.722977 
z"/>
<path fill="#1839BC" opacity="1.000000" stroke="none" 
	d="
M630.030273,168.295105 
	C630.556641,168.169708 631.113281,168.337448 631.837402,168.751328 
	C631.356812,168.861069 630.708679,168.724655 630.030273,168.295105 
z"/>
<path fill="#1A38B2" opacity="1.000000" stroke="none" 
	d="
M623.243896,187.942139 
	C623.138123,187.407303 623.330688,186.861282 623.775635,186.159180 
	C623.866211,186.645721 623.704224,187.288330 623.243896,187.942139 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M749.156250,712.343506 
	C752.102173,712.355896 755.214905,712.954163 758.328491,712.958435 
	C802.241577,713.019409 846.154724,712.999451 890.537231,713.004456 
	C889.505493,718.286194 885.296936,715.572327 882.255127,715.767029 
	C879.335022,715.953918 876.360962,715.045288 873.409912,715.040100 
	C839.508972,714.981018 805.607849,714.973267 771.706909,715.043396 
	C767.926392,715.051208 764.088745,716.173218 760.383362,715.780579 
	C756.628418,715.382751 753.004761,713.746765 749.156250,712.343506 
z"/>
<path fill="#EDE5D6" opacity="1.000000" stroke="none" 
	d="
M275.753906,810.855347 
	C274.044037,811.279053 272.336273,812.060486 270.623932,812.070740 
	C254.894730,812.165039 239.163040,811.999695 223.436050,812.208557 
	C219.192795,812.264893 218.173553,810.494690 219.143829,806.313599 
	C220.102737,806.003845 220.670044,806.217773 221.212769,806.804321 
	C222.438232,808.061829 223.653137,809.645691 224.944504,809.710754 
	C230.572708,809.994080 236.224014,809.890015 241.864838,809.797668 
	C243.184845,809.776001 244.496597,809.250671 246.748627,808.747925 
	C238.093414,800.992798 236.138596,790.943909 232.670227,781.398376 
	C233.200516,780.709778 233.554230,780.355835 234.116364,780.292236 
	C236.127716,784.719116 237.930649,788.855652 239.795471,793.365662 
	C243.101715,802.205933 243.979919,802.878296 250.595734,801.600159 
	C250.595734,788.578064 250.686386,775.477356 250.446411,762.382690 
	C250.424011,761.160400 248.309937,759.976440 247.167053,758.774719 
	C246.831177,758.316162 246.495316,757.857605 246.083725,756.764282 
	C245.965378,755.118042 245.922760,754.106567 245.880127,753.095154 
	C245.880142,753.095154 246.075409,752.636047 246.331940,752.291504 
	C246.876770,751.746704 247.057388,751.474854 247.130325,751.131470 
	C247.130325,751.131470 247.097595,750.994995 247.579147,751.036255 
	C255.708771,751.025208 263.358978,750.863464 271.003082,751.013489 
	C272.521301,751.043274 275.252869,752.215881 275.283936,752.951965 
	C275.368683,754.957703 274.393402,757.042969 273.668091,759.034790 
	C273.252350,760.176453 272.001129,761.195679 271.997070,762.273254 
	C271.981384,766.447144 272.315399,770.622314 272.508972,774.587769 
	C277.174805,774.587769 280.869751,774.587769 284.613373,774.587769 
	C284.613373,770.698608 284.716492,767.205261 284.562775,763.723389 
	C284.500916,762.321655 284.307678,760.184814 283.432739,759.687683 
	C279.592133,757.505737 280.976044,754.476868 281.285278,750.922058 
	C288.500275,750.922058 295.445038,750.925537 302.389801,750.921143 
	C310.124878,750.916260 310.600800,751.537415 308.377869,759.192932 
	C307.538635,759.517273 307.017151,759.841980 306.234161,760.558594 
	C305.893097,773.975769 305.850342,787.001404 305.711212,800.026001 
	C305.670227,803.863892 307.536072,804.574646 310.810120,803.906616 
	C310.858185,804.794861 310.849915,805.304443 310.439362,805.871460 
	C308.948120,805.984070 307.859131,806.039185 306.473694,806.048218 
	C305.451416,805.686951 304.725616,805.371826 303.890198,804.854065 
	C303.627289,804.381653 303.435608,804.142639 303.236572,803.559204 
	C303.127808,802.447998 302.987976,801.711853 302.922424,800.513855 
	C302.951599,789.701172 302.906525,779.350464 302.937439,768.749756 
	C302.962128,768.333374 302.910797,768.166931 302.930664,767.626587 
	C302.947937,766.497131 302.893951,765.741516 302.946167,764.569702 
	C303.155487,762.177002 303.258698,760.200623 303.287964,758.245361 
	C303.213989,758.266479 303.362427,758.306702 303.689453,758.194458 
	C304.956299,756.755188 305.896149,755.428162 306.835999,754.101196 
	C305.538879,753.858887 304.241760,753.616577 302.629578,753.310303 
	C301.890594,753.292419 301.466766,753.338501 300.587891,753.286011 
	C296.405212,753.270386 292.677521,753.353210 288.637573,753.365479 
	C287.901978,753.324036 287.478668,753.353271 286.701233,753.287598 
	C282.906616,753.294678 282.112305,754.468567 284.788696,757.044312 
	C285.472778,757.702759 286.162567,758.355225 286.874268,759.353882 
	C287.004669,760.143127 287.110504,760.588989 287.121552,761.490845 
	C286.986389,767.273926 286.946014,772.601074 286.948303,777.962646 
	C286.990997,777.997131 286.925873,777.908875 286.465027,777.943115 
	C281.699036,778.018250 277.391357,778.153809 273.090515,778.036926 
	C271.930695,778.005493 270.342072,777.536865 269.729218,776.699097 
	C268.035980,774.384399 269.783447,759.433289 271.439056,757.078613 
	C272.063080,756.191101 271.777161,754.663696 271.544373,753.335815 
	C270.439636,753.276123 269.696533,753.308655 268.498779,753.241943 
	C263.049103,753.122009 258.052429,753.026733 253.060150,753.134277 
	C251.854813,753.160217 250.663925,753.856628 249.466400,754.244629 
	C250.054825,755.268860 250.697708,756.266296 251.217697,757.324158 
	C251.848190,758.606750 252.869492,759.940063 252.870590,761.252136 
	C252.882599,775.733093 252.729126,790.214233 252.595871,804.719604 
	C252.571274,804.743835 252.572830,804.674744 252.240097,804.781372 
	C250.819946,806.119934 249.732513,807.351929 248.645096,808.583923 
	C248.953369,809.019531 249.261642,809.455200 249.569916,809.890808 
	C256.487915,809.890808 263.406921,809.947510 270.322601,809.819580 
	C271.194458,809.803528 272.048340,808.816406 272.910522,808.279175 
	C272.301300,807.489136 271.692078,806.699097 271.042175,805.930664 
	C271.001495,805.952148 271.082672,805.923462 270.912292,805.541382 
	C270.366486,803.284424 269.816223,801.424683 269.652252,799.531494 
	C269.319458,795.688843 269.195679,791.828186 269.109253,787.661987 
	C269.193390,786.909607 269.154022,786.470215 269.376465,785.798218 
	C269.783752,785.383301 269.929321,785.200806 270.535797,785.013489 
	C274.623322,785.004822 278.261139,785.176025 281.873322,784.945801 
	C285.202942,784.733521 286.115997,786.118591 286.075226,789.263611 
	C286.024811,793.149109 286.513367,797.041565 286.823944,801.314331 
	C286.712189,802.713257 286.545532,803.728882 286.260101,804.667114 
	C286.141357,804.589722 285.915924,804.417908 285.866333,804.128296 
	C285.363190,803.485352 284.909607,803.132019 284.487549,802.327515 
	C284.519012,796.819641 284.519012,791.763000 284.519012,786.520630 
	C280.103363,786.520630 276.214447,786.520630 271.875153,786.520630 
	C271.875153,791.229004 271.825073,795.650757 271.910675,800.069946 
	C271.931824,801.163391 272.085846,802.952271 272.702850,803.221863 
	C276.380829,804.828552 275.704834,807.943054 275.753906,810.855347 
z"/>
<path fill="#1F3281" opacity="1.000000" stroke="none" 
	d="
M308.695618,759.193298 
	C310.600800,751.537415 310.124878,750.916260 302.389801,750.921143 
	C295.445038,750.925537 288.500275,750.922058 281.285278,750.922058 
	C280.976044,754.476868 279.592133,757.505737 283.432739,759.687683 
	C284.307678,760.184814 284.500916,762.321655 284.562775,763.723389 
	C284.716492,767.205261 284.613373,770.698608 284.613373,774.587769 
	C280.869751,774.587769 277.174805,774.587769 272.508972,774.587769 
	C272.315399,770.622314 271.981384,766.447144 271.997070,762.273254 
	C272.001129,761.195679 273.252350,760.176453 273.668091,759.034790 
	C274.393402,757.042969 275.368683,754.957703 275.283936,752.951965 
	C275.252869,752.215881 272.521301,751.043274 271.003082,751.013489 
	C263.358978,750.863464 255.708771,751.025208 247.607666,751.101074 
	C250.217255,749.758545 253.178497,747.622742 256.365784,747.202881 
	C261.403229,746.539307 266.592224,747.026245 271.757050,747.885925 
	C267.185059,747.885925 262.613068,747.885925 258.041077,747.885925 
	C258.045685,748.236572 258.050293,748.587280 258.054901,748.937927 
	C260.800873,748.937927 263.554688,748.803345 266.291473,748.961853 
	C276.507782,749.553894 286.722076,748.700012 296.929779,748.987427 
	C302.408539,749.141663 307.872314,749.829041 313.835754,750.406128 
	C314.674561,750.775696 315.020477,751.015076 315.287842,751.664062 
	C315.067261,754.757568 313.801819,757.677551 317.296997,759.482117 
	C317.854889,759.770264 317.891815,761.607727 317.731049,762.662109 
	C316.386780,771.477051 314.939880,780.276367 313.152649,789.241028 
	C312.539368,790.982300 312.297333,792.562927 312.047913,794.376099 
	C312.040527,794.608704 311.880737,795.045776 311.614563,795.225403 
	C309.000244,798.256714 308.176208,795.842590 307.999634,794.133911 
	C307.479736,789.103088 307.333801,784.033569 307.391693,779.047241 
	C308.445618,780.322510 309.149353,781.529785 309.853088,782.737000 
	C310.672241,782.447876 311.491425,782.158752 312.310608,781.869568 
	C312.874451,776.006653 313.450256,770.144775 313.998047,764.280396 
	C314.383087,760.158325 312.726440,759.034607 308.972900,760.524292 
	C308.832581,759.782166 308.764099,759.487732 308.695618,759.193298 
M275.012085,763.956848 
	C275.046661,765.613464 274.798431,767.345093 275.211578,768.901184 
	C275.504639,770.005066 276.690796,770.871826 277.478241,771.844360 
	C278.574432,770.954529 280.530243,770.134827 280.609650,769.161987 
	C280.874542,765.917114 280.466827,762.617249 280.316864,759.338501 
	C279.673553,759.174744 279.030243,759.011047 278.386963,758.847290 
	C277.292572,760.280457 276.198212,761.713623 275.012085,763.956848 
z"/>
<path fill="#E9E1D0" opacity="1.000000" stroke="none" 
	d="
M233.907944,780.001953 
	C233.554230,780.355835 233.200516,780.709778 232.425339,781.135864 
	C228.496292,772.959961 225.015976,764.699951 221.449570,756.477356 
	C220.956039,755.339539 220.088318,753.534912 219.306030,753.487488 
	C214.643204,753.204773 209.954559,753.347717 204.932983,753.347717 
	C207.869766,760.927979 208.130081,761.043335 204.797394,768.563538 
	C198.911682,781.844666 197.230804,796.965271 186.562485,809.061584 
	C192.149887,809.878418 196.907883,810.887207 202.375381,808.029175 
	C196.971283,803.847961 199.531387,799.595764 201.182510,795.530090 
	C202.871704,791.370605 206.663681,793.627014 209.537735,793.281372 
	C211.999451,792.985229 214.527054,793.290527 217.015091,793.148010 
	C221.827332,792.872253 221.710983,797.001282 223.067459,799.789673 
	C224.383041,802.494019 224.507309,804.951782 221.237366,806.431702 
	C220.670044,806.217773 220.102737,806.003845 219.272491,805.882812 
	C219.071716,805.572449 219.133896,805.169250 219.673889,804.399109 
	C221.501663,800.352173 220.072250,796.272400 217.124771,796.071899 
	C213.348648,795.815002 209.471664,795.578369 205.784958,796.201416 
	C204.342255,796.445312 202.955948,798.899414 202.220245,800.633789 
	C201.868454,801.463013 203.094467,802.961609 203.605850,804.157043 
	C203.759933,804.598328 203.914001,805.039612 204.126465,806.146240 
	C204.238495,807.497742 204.292160,808.183899 204.345825,808.870056 
	C203.044678,809.962891 201.805115,811.895752 200.431259,811.996460 
	C195.645935,812.347168 190.814102,812.063538 185.543121,812.069519 
	C184.794235,811.890747 184.502182,811.663452 184.218140,810.826050 
	C183.943497,809.088867 183.660843,807.961914 183.378189,806.834900 
	C183.378189,806.834900 183.349030,806.579224 183.563461,806.337524 
	C183.947113,805.435974 184.116333,804.776123 184.285583,804.116211 
	C184.285583,804.116211 184.141861,804.114258 184.484695,804.154053 
	C185.228180,804.071716 185.628845,803.949646 186.375885,803.823975 
	C192.447876,800.055847 193.054626,793.899963 194.187195,787.806152 
	C194.244232,787.649231 194.172714,787.322998 194.520813,787.144409 
	C197.975388,779.058594 201.137634,771.172424 204.128815,763.221863 
	C204.582260,762.016602 204.529495,760.438049 204.201859,759.163025 
	C203.661057,757.058716 202.705215,755.060974 202.105225,752.649170 
	C208.598083,751.891846 214.907669,751.424377 221.225555,751.236755 
	C221.866058,751.217773 222.777679,752.793823 223.189362,753.785034 
	C226.810440,762.504028 230.346619,771.258179 233.907944,780.001953 
z"/>
<path fill="#1E338B" opacity="1.000000" stroke="none" 
	d="
M234.116364,780.292236 
	C230.346619,771.258179 226.810440,762.504028 223.189362,753.785034 
	C222.777679,752.793823 221.866058,751.217773 221.225555,751.236755 
	C214.907669,751.424377 208.598083,751.891846 201.852783,752.645996 
	C201.251175,753.016418 201.082932,753.020813 200.914688,753.025269 
	C201.105148,750.523254 205.000290,747.512573 208.652435,747.044434 
	C218.010559,745.845032 223.733597,749.321960 227.590424,758.008545 
	C231.346283,766.467712 235.492111,774.752991 239.370483,783.158997 
	C240.818512,786.297424 241.994492,789.561401 244.192734,792.702209 
	C248.712448,782.707275 246.284348,772.165771 247.359314,761.673279 
	C248.139984,763.097168 248.894775,764.786377 248.938370,766.493652 
	C249.091354,772.483093 249.070938,778.480103 248.970200,784.471741 
	C248.920364,787.436218 248.947800,790.495422 248.231079,793.332642 
	C247.227890,797.303894 245.609329,797.460144 242.661819,794.529419 
	C241.914780,793.786621 240.721680,793.492554 239.733582,792.992310 
	C237.930649,788.855652 236.127716,784.719116 234.116364,780.292236 
z"/>
<path fill="#1F3281" opacity="1.000000" stroke="none" 
	d="
M239.795471,793.365723 
	C240.721680,793.492554 241.914780,793.786621 242.661819,794.529419 
	C245.609329,797.460144 247.227890,797.303894 248.231079,793.332642 
	C248.947800,790.495422 248.920364,787.436218 248.970200,784.471741 
	C249.070938,778.480103 249.091354,772.483093 248.938370,766.493652 
	C248.894775,764.786377 248.139984,763.097168 247.367996,761.205383 
	C247.017303,760.509644 247.009933,760.008240 247.084808,759.140747 
	C248.309937,759.976440 250.424011,761.160400 250.446411,762.382690 
	C250.686386,775.477356 250.595734,788.578064 250.595734,801.600159 
	C243.979919,802.878296 243.101715,802.205933 239.795471,793.365723 
z"/>
<path fill="#1B359A" opacity="1.000000" stroke="none" 
	d="
M340.141327,757.925659 
	C341.072083,758.409485 342.295319,758.664124 342.884308,759.415710 
	C345.858032,763.210449 347.103699,763.051514 349.271393,758.617126 
	C350.564697,755.971558 351.957245,753.336182 353.655762,750.945312 
	C354.361542,749.951843 355.981445,749.607666 357.582336,748.973511 
	C357.979034,748.978699 358.013611,749.014648 358.008301,749.309448 
	C358.007690,749.997681 358.012421,750.391113 357.711975,750.882080 
	C356.657898,751.676758 355.908966,752.373901 354.865082,753.224609 
	C354.488373,754.010559 354.406677,754.643005 354.311340,755.200623 
	C354.297699,755.125793 354.437042,755.186829 354.048828,755.327881 
	C353.481079,756.523438 353.301514,757.577942 353.175476,758.726746 
	C353.229034,758.821106 353.092133,758.989380 352.798950,759.200134 
	C352.385437,760.107483 352.265106,760.804016 352.147339,761.655396 
	C352.149872,761.810242 351.908813,762.004639 351.560791,762.058716 
	C349.333679,764.745972 347.454529,767.379150 345.575409,770.012329 
	C344.964996,769.822327 344.354553,769.632324 343.744141,769.442322 
	C342.543213,765.603455 341.342255,761.764526 340.141327,757.925659 
z"/>
<path fill="#1B359A" opacity="1.000000" stroke="none" 
	d="
M315.366364,751.254456 
	C315.020477,751.015076 314.674561,750.775696 314.159760,750.266663 
	C316.233948,749.082947 318.419220,747.582214 320.729889,747.354614 
	C331.828857,746.261597 336.685150,746.788025 339.630524,756.609985 
	C338.727264,757.047791 338.211639,757.090942 337.470459,756.847473 
	C336.054382,754.291016 336.616302,750.681458 332.301880,750.909485 
	C326.666412,751.207458 321.012390,751.155762 315.366364,751.254456 
z"/>
<path fill="#1F2F70" opacity="1.000000" stroke="none" 
	d="
M337.695984,757.134155 
	C338.211639,757.090942 338.727264,757.047791 339.630371,756.976379 
	C340.017853,756.948181 340.066101,757.439026 340.103699,757.682373 
	C341.342255,761.764526 342.543213,765.603455 343.744141,769.442322 
	C344.354553,769.632324 344.964996,769.822327 345.575409,770.012329 
	C347.454529,767.379150 349.333679,764.745972 351.547180,762.441833 
	C350.802551,766.573792 349.723541,770.376709 348.678650,774.190735 
	C348.712799,774.201843 348.666840,774.255249 348.392761,774.453125 
	C347.940277,775.292236 347.761902,775.933411 347.598541,776.600952 
	C347.613525,776.627380 347.589020,776.683044 347.342377,776.881714 
	C346.972137,777.692444 346.848541,778.304504 346.361816,779.011719 
	C343.231140,771.782593 340.463562,764.458374 337.695984,757.134155 
z"/>
<path fill="#1E338B" opacity="1.000000" stroke="none" 
	d="
M378.095337,753.049255 
	C378.084412,753.774353 378.073425,754.499451 378.049194,755.935181 
	C378.047394,757.110901 378.058929,757.575989 378.070465,758.041016 
	C378.057068,758.476196 378.043640,758.911316 377.727936,759.956421 
	C376.823090,766.276794 375.964691,772.027588 380.019043,777.003235 
	C380.025116,777.742065 380.031189,778.480896 380.028015,780.021851 
	C380.656342,784.230469 381.293915,787.637085 381.591492,791.034363 
	C380.796844,791.037720 380.342133,791.050293 379.716736,790.719177 
	C377.693207,781.517700 375.794037,772.669189 374.036743,763.792542 
	C373.768982,762.440002 373.648254,760.074829 374.328430,759.681824 
	C378.008423,757.555237 376.830078,754.347961 376.800537,751.252197 
	C376.873749,751.172485 377.009094,751.003540 377.009094,751.003540 
	C377.177277,750.996277 377.345490,750.989075 377.763916,751.328247 
	C378.041199,752.132874 378.068268,752.591064 378.095337,753.049255 
z"/>
<path fill="#1F3281" opacity="1.000000" stroke="none" 
	d="
M200.920959,753.472290 
	C201.082932,753.020813 201.251175,753.016418 201.671860,753.015137 
	C202.705215,755.060974 203.661057,757.058716 204.201859,759.163025 
	C204.529495,760.438049 204.582260,762.016602 204.128815,763.221863 
	C201.137634,771.172424 197.975388,779.058594 194.469482,786.736450 
	C194.364899,784.658264 194.659760,782.809448 194.954590,780.960632 
	C195.943069,778.212708 196.931534,775.464783 198.226929,772.184204 
	C199.008072,770.749023 199.482300,769.846619 199.956543,768.944153 
	C199.965363,768.207886 199.974197,767.471680 200.290863,766.220093 
	C200.725189,764.465881 200.851700,763.226990 200.978180,761.988159 
	C200.961197,759.298523 200.944214,756.608948 200.920959,753.472290 
z"/>
<path fill="#1B359A" opacity="1.000000" stroke="none" 
	d="
M357.961639,748.960815 
	C360.852539,745.375977 375.131042,746.309326 377.010193,750.596375 
	C377.009094,751.003540 376.873749,751.172485 376.450409,751.206787 
	C375.344543,751.070496 374.662018,750.899902 373.869263,750.354858 
	C368.510590,749.658569 363.262085,749.336609 358.013611,749.014648 
	C358.013611,749.014648 357.979034,748.978699 357.961639,748.960815 
z"/>
<path fill="#2D3C85" opacity="1.000000" stroke="none" 
	d="
M194.130157,787.963074 
	C193.054626,793.899963 192.447876,800.055847 186.409546,803.479065 
	C186.734055,801.422913 187.371292,799.708069 188.008545,797.993225 
	C188.306580,797.532104 188.604630,797.070984 189.216095,796.119629 
	C191.063080,793.073914 192.596619,790.518494 194.130157,787.963074 
z"/>
<path fill="#1B359A" opacity="1.000000" stroke="none" 
	d="
M194.187195,787.806152 
	C192.596619,790.518494 191.063080,793.073914 189.242203,795.833313 
	C189.428696,793.479980 189.593994,790.812012 190.460785,788.395630 
	C191.376373,785.843445 192.960617,783.531128 194.603668,781.037354 
	C194.659760,782.809448 194.364899,784.658264 194.121384,786.915039 
	C194.172714,787.322998 194.244232,787.649231 194.187195,787.806152 
z"/>
<path fill="#1B359A" opacity="1.000000" stroke="none" 
	d="
M187.658325,798.043518 
	C187.371292,799.708069 186.734055,801.422913 186.063156,803.482666 
	C185.628845,803.949646 185.228180,804.071716 184.511719,804.220642 
	C185.233322,802.196228 186.270721,800.145020 187.658325,798.043518 
z"/>
<path fill="#1E338B" opacity="1.000000" stroke="none" 
	d="
M200.725403,762.169189 
	C200.851700,763.226990 200.725189,764.465881 200.304703,765.852173 
	C200.164673,764.783142 200.318649,763.566711 200.725403,762.169189 
z"/>
<path fill="#1B359A" opacity="1.000000" stroke="none" 
	d="
M183.060516,807.026489 
	C183.660843,807.961914 183.943497,809.088867 184.111847,810.567383 
	C183.579330,809.685242 183.161087,808.451599 183.060516,807.026489 
z"/>
<path fill="#1F3281" opacity="1.000000" stroke="none" 
	d="
M245.622894,753.264404 
	C245.922760,754.106567 245.965378,755.118042 245.958344,756.503479 
	C245.727661,755.729553 245.546646,754.581665 245.622894,753.264404 
z"/>
<path fill="#1E338B" opacity="1.000000" stroke="none" 
	d="
M199.625015,768.963074 
	C199.482300,769.846619 199.008072,770.749023 198.283325,771.822388 
	C198.453049,770.989502 198.873276,769.985718 199.625015,768.963074 
z"/>
<path fill="#1433AA" opacity="1.000000" stroke="none" 
	d="
M378.402527,752.989868 
	C378.068268,752.591064 378.041199,752.132874 378.016418,751.330444 
	C378.455322,750.992493 378.891876,750.998657 379.655640,751.010864 
	C379.558502,751.654785 379.134094,752.292603 378.402527,752.989868 
z"/>
<path fill="#1B359A" opacity="1.000000" stroke="none" 
	d="
M183.951752,804.253845 
	C184.116333,804.776123 183.947113,805.435974 183.463959,806.255981 
	C183.306015,805.741150 183.461975,805.066345 183.951752,804.253845 
z"/>
<path fill="#1F3281" opacity="1.000000" stroke="none" 
	d="
M246.882568,751.204468 
	C247.057388,751.474854 246.876770,751.746704 246.359604,752.043579 
	C246.298752,751.852661 246.466782,751.565125 246.882568,751.204468 
z"/>
<path fill="#EFEBDE" opacity="1.000000" stroke="none" 
	d="
M626.132812,119.819168 
	C635.446960,127.083672 644.513123,134.098526 653.378540,141.358505 
	C657.003479,144.327057 656.929810,149.269043 653.623901,152.670715 
	C650.145325,156.250137 646.597107,159.762100 643.066284,163.290497 
	C638.285950,168.067490 634.307068,168.150467 629.342102,163.614563 
	C600.067261,136.869644 565.771729,122.355545 525.955017,123.414536 
	C489.226898,124.391373 457.483154,138.595200 430.451263,163.378479 
	C425.157898,168.231537 421.856812,168.253891 416.567963,163.131042 
	C413.108124,159.779755 409.660278,156.414673 406.268616,152.994720 
	C402.421631,149.115570 402.408386,144.760406 406.438385,140.970657 
	C434.728241,114.367683 468.042267,98.321640 506.722290,94.033760 
	C541.641296,90.162804 575.476562,94.139534 607.440613,109.580887 
	C613.720459,112.614609 619.690857,116.289177 626.132812,119.819168 
M617.150757,119.316643 
	C610.920227,116.178886 604.859131,112.632126 598.431458,109.970421 
	C559.530762,93.861656 519.886414,91.468224 479.377747,103.570221 
	C452.698334,111.540718 429.648743,125.493248 409.316772,144.289078 
	C406.988983,146.440994 407.211182,147.987915 409.296143,149.996353 
	C413.009796,153.573730 416.755371,157.138565 420.202057,160.965790 
	C422.826721,163.880249 424.723419,162.961731 427.290405,160.870941 
	C434.347321,155.123199 441.251434,149.075226 448.857819,144.134064 
	C484.164154,121.198830 522.692810,115.161217 563.554382,123.932838 
	C589.798523,129.566574 612.584290,142.233124 632.307007,160.368607 
	C634.860962,162.717056 636.862427,164.070053 639.742859,160.988480 
	C643.136169,157.358261 646.678833,153.862915 650.249329,150.404617 
	C652.671387,148.058685 652.924133,146.171951 649.953308,143.961609 
	C639.175415,135.942490 628.501099,127.784271 617.150757,119.316643 
z"/>
<path fill="#EDE5D6" opacity="1.000000" stroke="none" 
	d="
M745.074524,806.893677 
	C745.074524,806.893677 744.992126,806.429443 744.975281,805.744995 
	C744.947632,800.070496 744.936890,795.080444 744.926147,790.090454 
	C744.926147,790.090454 745.203552,789.740784 745.641113,789.418335 
	C750.238037,787.882080 752.771912,789.022278 754.210632,793.490173 
	C756.034790,799.154907 759.956116,803.063965 766.286499,804.482178 
	C767.329529,805.116150 768.160583,805.548950 768.599243,806.073486 
	C767.138367,806.105469 766.070007,806.045776 764.764282,805.712646 
	C763.572144,805.338989 762.617371,805.238770 761.377747,805.141479 
	C760.718628,805.156555 760.344238,805.168823 759.753479,805.047974 
	C759.208557,804.845337 758.880005,804.775879 758.388062,804.400269 
	C755.917969,801.213196 753.611389,798.332397 751.325195,795.197388 
	C751.227417,794.627441 751.109131,794.311584 750.998474,793.615234 
	C750.279541,790.876831 748.677795,790.128296 747.328247,792.312439 
	C744.137817,797.475708 748.138367,806.186646 754.070679,807.459717 
	C754.163879,807.546936 754.412476,807.488220 754.664001,807.770386 
	C761.716431,808.629150 768.578796,810.018311 775.297668,809.510254 
	C780.159180,809.142517 785.126831,806.670288 787.213379,801.224487 
	C787.181213,801.219299 787.224915,801.170959 787.529419,800.970581 
	C791.874146,791.957764 790.766052,785.683167 783.634155,780.600647 
	C778.665527,777.059753 773.249512,774.146545 768.014893,770.973511 
	C767.996521,770.994080 768.047241,770.973145 767.974976,770.719299 
	C767.607483,770.296997 767.312317,770.128540 766.898315,769.725403 
	C766.462769,769.351868 766.146057,769.213074 765.723267,768.715332 
	C763.188049,765.031982 763.419922,761.571472 765.299072,757.946167 
	C765.776489,757.683105 765.960876,757.446411 766.386169,757.134644 
	C768.032959,756.614868 769.355469,756.105164 770.962097,755.636475 
	C771.616211,755.593506 771.986328,755.509460 772.553772,755.708618 
	C774.784729,757.052673 776.818359,758.113586 778.887939,759.131592 
	C778.923889,759.088623 778.812927,759.103943 778.843262,759.398560 
	C779.279358,760.072754 779.685181,760.452454 780.077271,760.878296 
	C780.063660,760.924561 780.160034,760.921936 780.163818,761.262085 
	C781.044983,763.124146 781.922241,764.645935 782.843689,766.097839 
	C782.887878,766.027893 782.755005,766.126465 782.727905,766.487915 
	C783.384033,767.800659 784.067322,768.751892 784.750610,769.703186 
	C785.220825,769.180603 785.690979,768.657959 786.156860,768.079956 
	C786.152588,768.024536 786.129028,768.132629 786.311462,768.017334 
	C786.667664,767.609802 786.632812,767.345154 786.486816,766.707275 
	C786.504150,765.219421 786.424072,764.132446 786.499146,762.657959 
	C787.999023,755.862488 783.134949,755.374207 778.735718,754.071289 
	C778.132385,753.878662 777.767578,753.830139 777.229736,753.572144 
	C776.710205,753.316528 776.363525,753.270447 775.683105,753.138733 
	C774.891479,753.055603 774.433777,753.058228 773.666626,752.807312 
	C769.135864,752.983521 764.914612,753.413208 760.508057,753.838623 
	C760.322754,753.834290 760.010559,754.033997 759.659302,754.012573 
	C758.095520,754.838379 756.883057,755.685608 755.641296,756.560547 
	C755.612000,756.588196 755.534363,756.566711 755.379517,756.562134 
	C755.224731,756.557617 755.007935,756.778809 754.696289,756.861816 
	C753.990051,757.115479 753.595398,757.286194 753.142944,757.065796 
	C753.049683,756.151184 753.014221,755.627686 753.169128,754.806213 
	C765.095886,746.820740 776.733826,749.381592 788.326416,755.186035 
	C788.851379,753.586609 789.274292,752.297913 789.710327,750.969421 
	C799.008911,750.969421 807.982727,750.969421 816.956604,750.969421 
	C816.956604,750.969421 816.978455,751.048889 817.095703,751.423035 
	C817.997803,754.825928 819.291443,757.886536 814.986572,759.647034 
	C814.278992,759.936462 814.029663,761.999634 814.023132,763.245422 
	C813.976013,772.223938 813.922791,781.205688 814.118408,790.181091 
	C814.272217,797.235779 818.108765,801.236450 824.268555,801.269958 
	C830.006287,801.301147 833.672546,797.289185 833.824768,790.257019 
	C834.022583,781.115112 833.844604,771.965393 833.998596,762.821960 
	C834.045166,760.058167 833.007385,758.782532 830.299744,758.764282 
	C830.290161,758.769531 830.291870,758.745056 830.276489,758.552246 
	C830.261047,758.359497 830.080750,758.016052 830.135559,757.589172 
	C830.222229,755.176758 830.254089,753.191284 830.285889,751.205750 
	C830.285889,751.205750 830.174255,751.170715 830.645996,751.187805 
	C846.891663,751.170044 862.666565,751.035645 878.438538,751.190735 
	C881.339600,751.219177 884.346924,752.106384 887.102844,753.137817 
	C897.239685,756.931274 900.595886,766.982361 894.986572,776.187195 
	C894.585327,776.845581 894.241943,777.539246 893.823364,778.305054 
	C895.129761,779.228455 896.221985,779.963501 897.275208,780.750732 
	C907.092163,788.088623 906.423340,802.127808 895.600708,807.970642 
	C891.499512,810.184692 886.579834,810.882874 881.687805,812.219788 
	C880.882446,812.227539 880.422546,812.287537 879.540100,812.241821 
	C877.092773,812.205933 875.068054,812.275757 872.661194,812.275513 
	C871.506836,812.254333 870.734558,812.303162 869.526855,812.246338 
	C866.409668,812.211975 863.728027,812.283142 860.616821,812.282349 
	C858.449524,812.258423 856.711731,812.306458 854.549561,812.249695 
	C852.084106,812.136658 850.042908,812.128418 847.876587,811.890137 
	C847.469360,811.463074 847.187256,811.266052 846.883057,810.720337 
	C846.825745,809.908325 846.790527,809.445007 846.831787,808.660645 
	C846.862976,807.904724 846.817749,807.469849 846.835938,806.703003 
	C847.268188,805.577515 847.637085,804.783997 848.415405,803.976196 
	C849.559448,802.343811 850.905273,800.735291 850.930969,799.106018 
	C851.117126,787.286682 851.149658,775.460815 850.909363,763.643616 
	C850.876526,762.025940 849.270020,759.285645 848.037598,759.067505 
	C844.598694,758.458496 844.837219,761.447876 844.823059,763.795654 
	C844.765076,773.450562 844.712585,783.105469 844.684570,792.811035 
	C844.711121,792.861755 844.798340,792.787659 844.514709,793.044922 
	C844.067627,794.571045 843.904175,795.839783 843.516724,797.347412 
	C841.765320,800.352051 840.237976,803.117798 838.404053,806.019653 
	C834.380066,808.138428 830.867615,811.072205 826.906860,811.925964 
	C814.806335,814.534424 804.159668,811.681580 796.258728,801.421753 
	C794.077698,798.589539 792.310852,797.592102 790.370789,801.895813 
	C787.625000,807.986511 782.354492,810.964294 775.938293,812.117859 
	C765.021057,814.080872 754.828552,811.788940 745.074524,806.893677 
M788.551392,771.556824 
	C784.343506,773.121399 781.355957,772.818787 780.079712,767.512756 
	C779.461670,764.943298 777.779846,761.798584 775.645813,760.651550 
	C773.372009,759.429321 769.100830,759.090637 767.307068,760.463562 
	C764.533569,762.586609 766.829834,765.604309 769.082092,767.500793 
	C771.358704,769.417786 773.909363,771.005249 776.280212,772.815369 
	C781.935730,777.133545 787.556091,781.497803 792.671265,785.442932 
	C792.671265,780.836792 792.671265,774.919678 792.813416,768.114624 
	C792.093628,764.961609 794.811279,760.443726 789.124451,758.232788 
	C789.124451,763.121582 789.124451,767.041199 788.551392,771.556824 
M853.164307,784.499756 
	C853.163574,785.165405 853.234619,785.840271 853.151001,786.495361 
	C852.233215,793.687195 855.928223,801.698181 849.374146,807.921082 
	C849.172302,808.112671 849.497803,808.859558 849.641052,809.737244 
	C859.405701,809.737244 869.218323,810.129883 878.976746,809.573303 
	C883.929565,809.290833 889.000916,807.827148 893.627869,805.939575 
	C899.067871,803.720154 901.300232,798.871765 901.155945,793.007324 
	C901.018127,787.404785 898.045105,783.710266 893.234619,781.271606 
	C891.855042,780.572144 890.434692,779.953125 888.788025,779.182556 
	C899.344666,769.506470 895.208557,756.348572 882.579102,754.548523 
	C872.286194,753.081421 861.711975,753.559265 851.259033,753.362244 
	C850.886658,753.355225 850.072449,756.250610 850.141968,756.281738 
	C854.023804,758.022461 853.125610,761.482666 853.154724,764.528442 
	C853.215149,770.851685 853.168457,777.176025 853.164307,784.499756 
M845.131348,755.218811 
	C841.376648,750.688721 837.033508,754.094055 832.563538,753.415710 
	C833.977905,756.934387 835.844604,759.525635 835.938293,762.179382 
	C836.278442,771.818115 836.177246,781.481445 835.944031,791.128357 
	C835.773010,798.204224 831.098145,803.358337 824.966309,803.867615 
	C818.638733,804.393127 812.279480,800.371155 812.028015,793.435120 
	C811.561279,780.559021 807.386353,767.211731 814.784546,754.836365 
	C814.902344,754.639343 814.650574,754.221436 814.512085,753.671509 
	C806.796387,753.671509 799.042114,753.671509 789.872070,753.671509 
	C797.988647,760.799255 794.027039,769.123901 794.855774,776.659729 
	C795.235718,780.114807 794.950317,783.642151 794.982849,787.136597 
	C795.112122,801.003723 804.488770,809.996826 818.960327,810.140503 
	C833.557007,810.285461 841.430908,802.733826 841.818604,787.913330 
	C842.031799,779.764465 841.637817,771.594849 842.019226,763.458130 
	C842.136963,760.946106 843.909058,758.511719 845.131348,755.218811 
z"/>
<path fill="#1F3281" opacity="1.000000" stroke="none" 
	d="
M766.074585,804.281006 
	C759.956116,803.063965 756.034790,799.154907 754.210632,793.490173 
	C752.771912,789.022278 750.238037,787.882080 745.675964,789.197937 
	C745.298035,788.811340 745.200195,787.942139 745.365173,787.889038 
	C751.834656,785.805908 748.835938,782.620300 747.011292,778.683533 
	C740.559631,764.763977 747.891663,749.228760 764.630249,748.182800 
	C770.925537,747.789490 777.349915,750.114868 783.722595,750.158691 
	C793.862366,750.228516 804.008240,749.424927 814.581543,748.969482 
	C815.648926,749.407715 816.286377,749.867188 816.940186,750.648010 
	C807.982727,750.969421 799.008911,750.969421 789.710327,750.969421 
	C789.274292,752.297913 788.851379,753.586609 788.326416,755.186035 
	C776.733826,749.381592 765.095886,746.820740 752.868591,754.949585 
	C744.266418,764.198792 744.722046,776.188477 753.895447,783.954468 
	C758.555176,787.899292 764.017822,790.886597 768.770691,794.735046 
	C770.601624,796.217590 771.470337,798.888489 772.777405,801.017944 
	C770.543152,802.105652 768.308899,803.193359 766.074585,804.281006 
z"/>
<path fill="#1E338B" opacity="1.000000" stroke="none" 
	d="
M745.045898,807.241455 
	C754.828552,811.788940 765.021057,814.080872 775.938293,812.117859 
	C782.354492,810.964294 787.625000,807.986511 790.370789,801.895813 
	C792.310852,797.592102 794.077698,798.589539 796.258728,801.421753 
	C804.159668,811.681580 814.806335,814.534424 826.906860,811.925964 
	C830.867615,811.072205 834.380066,808.138428 838.736938,806.060303 
	C839.598999,805.986145 839.821533,806.007507 840.030640,806.394531 
	C840.006653,807.491577 839.996094,808.222900 839.985474,808.954224 
	C837.802979,810.126953 835.620483,811.299622 833.220764,812.732422 
	C831.469543,813.478699 829.935547,813.964905 828.197388,814.732239 
	C827.254578,815.020325 826.515930,815.027283 824.958252,815.019775 
	C821.740906,815.028564 819.342529,815.051697 816.944092,815.074890 
	C815.894714,815.053406 814.845398,815.031860 813.402771,815.003174 
	C807.805115,811.983337 802.556030,809.044312 797.413391,805.929565 
	C794.455322,804.137878 791.964783,803.700989 790.038818,807.599365 
	C782.847229,813.200989 774.764648,815.778442 766.009888,814.875244 
	C760.187866,814.274597 754.497314,812.399170 748.368408,811.059082 
	C747.988892,811.023865 747.520874,810.981323 747.228638,810.658752 
	C746.295532,809.887268 745.654602,809.438416 745.013672,808.989563 
	C745.013672,808.989563 745.002441,808.997620 745.006836,808.991577 
	C745.013306,808.520142 745.015320,808.054688 745.045898,807.241455 
z"/>
<path fill="#1E338B" opacity="1.000000" stroke="none" 
	d="
M687.206177,753.074097 
	C686.997437,753.057312 686.788696,753.040588 686.266235,752.999695 
	C685.799805,749.922729 687.441711,748.899780 690.271667,748.957764 
	C694.250122,749.039490 698.278198,749.356384 702.200500,748.878662 
	C707.618713,748.218872 709.977478,751.044495 711.747131,755.492188 
	C716.664551,767.850952 721.641479,780.189331 726.853333,792.425659 
	C728.245728,795.694702 730.565430,798.568787 732.628052,802.075195 
	C732.522400,802.946167 732.248108,803.366089 731.621704,803.818237 
	C729.854858,802.168457 727.931213,800.703552 727.106506,798.769958 
	C720.859070,784.122559 714.882507,769.359741 708.647522,754.706848 
	C707.978699,753.135132 706.196594,752.037109 704.601318,750.484009 
	C699.209839,750.426392 694.147888,750.604187 688.859375,750.977051 
	C688.157166,751.806152 687.681702,752.440125 687.206177,753.074097 
z"/>
<path fill="#1E338B" opacity="1.000000" stroke="none" 
	d="
M685.548950,753.905884 
	C686.233276,757.291199 686.562134,760.705627 686.971008,764.950745 
	C680.774597,762.369690 682.834106,757.155334 681.090759,753.300964 
	C682.447266,753.242065 683.820374,753.588440 685.548950,753.905884 
z"/>
<path fill="#1E338B" opacity="1.000000" stroke="none" 
	d="
M744.598267,790.240601 
	C744.936890,795.080444 744.947632,800.070496 744.897217,805.522461 
	C741.007324,802.885864 740.746826,795.704834 744.598267,790.240601 
z"/>
<path fill="#0F2277" opacity="1.000000" stroke="none" 
	d="
M672.557617,748.988403 
	C672.468750,749.315002 671.907104,749.902527 671.348206,749.899841 
	C658.755554,749.839844 646.163147,749.714966 633.276489,749.302979 
	C646.016846,748.993835 659.051453,748.984070 672.557617,748.988403 
z"/>
<path fill="#1430A3" opacity="1.000000" stroke="none" 
	d="
M623.588623,757.025269 
	C623.561890,758.362610 623.356323,759.835144 622.794189,761.156006 
	C622.093323,762.803223 621.098450,764.325439 620.230469,765.901611 
	C618.844666,764.511414 617.458862,763.121216 616.031372,761.367920 
	C618.410339,759.700989 620.830933,758.397095 623.588623,757.025269 
z"/>
<path fill="#223A9A" opacity="1.000000" stroke="none" 
	d="
M731.973877,803.786072 
	C732.248108,803.366089 732.522400,802.946167 732.892151,802.296387 
	C733.961304,804.134827 734.934875,806.203064 735.953857,808.638123 
	C734.571350,810.654358 733.143494,812.303772 731.336121,813.683777 
	C730.935486,813.071289 730.914429,812.728271 731.267456,812.209717 
	C731.752380,809.284790 731.863159,806.535400 731.973877,803.786072 
z"/>
<path fill="#1739BF" opacity="1.000000" stroke="none" 
	d="
M811.817505,745.248230 
	C812.639709,745.444397 813.281799,745.886414 813.978149,746.643799 
	C812.717346,746.959290 811.402405,746.959290 809.442993,746.959290 
	C810.499634,746.253784 811.068542,745.873901 811.817505,745.248230 
z"/>
<path fill="#1430A3" opacity="1.000000" stroke="none" 
	d="
M747.004883,374.986572 
	C748.427979,375.323395 749.851013,375.660187 751.645630,375.999390 
	C752.864075,376.164917 753.711121,376.328003 754.805664,377.088013 
	C758.025330,381.901245 761.962524,385.757660 763.706116,390.432251 
	C765.554382,395.387726 765.858276,401.116333 765.900940,406.514679 
	C766.105835,432.448578 765.965088,458.385071 766.014526,484.320496 
	C766.027771,491.276154 766.879150,498.366730 761.210815,503.931854 
	C760.829956,504.305664 760.966980,505.207001 760.863159,505.862976 
	C732.634094,505.897614 704.404968,505.948578 676.175964,505.916016 
	C674.840942,505.914490 673.506775,505.171387 672.088867,504.374939 
	C672.177429,503.416504 672.349304,502.856720 672.767700,502.122223 
	C699.361267,501.962341 725.708313,501.977203 752.945374,501.996277 
	C756.591797,501.879761 759.850891,502.955719 760.993652,498.993561 
	C761.988403,495.739502 763.807495,492.494904 763.845032,489.229858 
	C764.099548,467.086823 764.051453,444.939087 763.933105,422.793762 
	C763.897766,416.172607 763.326294,409.554352 763.225647,402.321899 
	C763.295654,401.139557 763.140381,400.570038 762.985168,400.000549 
	C762.986145,399.558197 762.987183,399.115814 762.993347,397.952606 
	C762.659302,395.821381 762.320007,394.411041 761.980774,393.000671 
	C761.823853,392.140289 761.666931,391.279938 761.247192,389.835693 
	C759.657104,387.833344 758.329773,386.414856 757.002441,384.996338 
	C756.899902,384.674622 756.712463,384.413971 756.168945,383.760986 
	C754.932617,382.870483 753.967590,382.433411 753.002502,381.996338 
	C753.002502,381.996338 753.000000,382.000000 752.953613,381.662170 
	C751.270996,380.216644 749.634888,379.108917 747.998718,378.001221 
	C747.677368,377.897949 747.416931,377.710175 747.109802,376.883850 
	C747.003052,375.882080 747.003967,375.434326 747.004883,374.986572 
z"/>
<path fill="#1430A3" opacity="1.000000" stroke="none" 
	d="
M745.986755,349.990723 
	C742.367737,357.730316 741.991882,365.479858 745.621704,373.622894 
	C744.881104,374.008209 744.433899,374.013275 743.986633,374.018311 
	C743.482544,373.451141 742.978516,372.884003 742.192810,371.784790 
	C741.265198,369.168945 740.619202,367.085114 739.973267,365.001282 
	C739.975525,364.560944 739.977844,364.120575 739.975403,363.350403 
	C739.978210,362.260834 739.985657,361.501099 739.983276,360.361389 
	C739.975037,359.540894 739.976685,359.100372 740.254028,358.101135 
	C740.675537,356.354736 740.821533,355.167084 740.967468,353.979431 
	C741.802551,352.766785 742.637634,351.554108 744.063232,350.167084 
	C745.098083,349.992065 745.542419,349.991394 745.986755,349.990723 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M760.590332,498.995331 
	C759.850891,502.955719 756.591797,501.879761 753.413452,501.994476 
	C755.390015,500.991333 757.788513,499.994232 760.590332,498.995331 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M757.004272,385.362000 
	C758.329773,386.414856 759.657104,387.833344 760.985413,389.630829 
	C759.659607,388.582428 758.332886,387.155029 757.004272,385.362000 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M739.704712,365.240662 
	C740.619202,367.085114 741.265198,369.168945 741.948425,371.631348 
	C741.135864,369.833282 740.286072,367.656677 739.704712,365.240662 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M748.035156,378.355713 
	C749.634888,379.108917 751.270996,380.216644 752.954834,381.660339 
	C751.358826,380.900940 749.715271,379.805542 748.035156,378.355713 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M761.712646,393.240479 
	C762.320007,394.411041 762.659302,395.821381 762.991943,397.620819 
	C762.471741,396.500000 761.958069,394.990143 761.712646,393.240479 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M744.023438,374.357117 
	C744.433899,374.013275 744.881104,374.008209 745.663696,374.002991 
	C746.110901,374.317535 746.301697,374.573364 746.788147,374.878448 
	C747.003967,375.434326 747.003052,375.882080 747.000732,376.665527 
	C746.019592,376.232788 745.039917,375.464325 744.023438,374.357117 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M753.140991,382.287415 
	C753.967590,382.433411 754.932617,382.870483 755.950562,383.651978 
	C755.095459,383.523743 754.187500,383.051117 753.140991,382.287415 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M762.760864,400.146790 
	C763.140381,400.570038 763.295654,401.139557 763.224243,401.854523 
	C762.843872,401.431030 762.690247,400.862061 762.760864,400.146790 
z"/>
<path fill="#EDE5D6" opacity="1.000000" stroke="none" 
	d="
M731.621704,803.818237 
	C731.863159,806.535400 731.752380,809.284790 730.949097,812.138428 
	C729.823792,812.286377 729.391052,812.330017 728.511353,812.259460 
	C724.064697,812.216187 720.064941,812.287170 715.624268,812.285645 
	C713.094666,812.267273 711.006042,812.321533 708.917419,812.375732 
	C707.235718,811.482483 705.553955,810.589233 703.845337,808.898804 
	C704.300842,806.771545 704.554199,805.290527 705.311096,804.141174 
	C707.176331,801.308716 705.249878,796.207642 701.967896,796.031738 
	C698.311523,795.835754 694.582214,795.612915 690.998352,796.154358 
	C689.626465,796.361694 688.106934,798.480103 687.526978,800.049866 
	C687.124512,801.139221 688.212646,802.779236 688.634766,804.173218 
	C688.842529,806.560059 689.050293,808.946960 689.305908,811.883179 
	C682.772278,811.883179 676.887939,811.883179 670.552368,811.765625 
	C668.325806,808.188538 667.712402,805.314697 672.494629,803.502686 
	C674.032532,802.919922 675.338989,800.739746 676.006226,799.003967 
	C680.647095,786.931091 685.137085,774.799011 689.521484,762.630432 
	C689.909302,761.553955 689.301636,760.054199 688.971802,758.799500 
	C688.529846,757.118652 687.907776,755.485168 687.284546,753.452698 
	C687.681702,752.440125 688.157166,751.806152 689.315308,751.035278 
	C694.975525,750.838745 699.953247,750.779053 704.930908,750.719360 
	C706.196594,752.037109 707.978699,753.135132 708.647522,754.706848 
	C714.882507,769.359741 720.859070,784.122559 727.106506,798.769958 
	C727.931213,800.703552 729.854858,802.168457 731.621704,803.818237 
M708.898560,802.523743 
	C708.712463,803.319763 708.633728,804.161865 708.316345,804.901550 
	C707.784485,806.140686 707.100525,807.314514 706.480591,808.515747 
	C707.849243,808.952820 709.208923,809.731384 710.587952,809.767273 
	C716.246765,809.914551 721.912720,809.898071 727.572693,809.781372 
	C728.569153,809.760803 729.549744,808.973206 730.537659,808.539917 
	C730.037903,807.590149 729.621887,806.583496 729.022339,805.701599 
	C727.380493,803.286743 725.183044,801.130493 724.052734,798.506958 
	C718.061890,784.601196 712.370239,770.566772 706.448181,756.630798 
	C705.885254,755.306091 704.520508,753.480347 703.398743,753.383423 
	C699.020142,753.005188 694.589539,753.229065 688.567444,753.229065 
	C694.810852,759.590515 691.188477,764.634888 689.235962,769.872803 
	C685.634583,779.533997 682.362366,789.321228 678.590454,798.913391 
	C677.565918,801.518860 675.225891,803.601074 673.528076,805.951233 
	C673.005737,806.674377 672.129517,807.615112 672.289062,808.229187 
	C672.460388,808.888367 673.636963,809.673645 674.414978,809.719177 
	C677.402283,809.893982 680.414856,809.941162 683.400208,809.752930 
	C686.039917,809.586487 686.810974,808.481201 685.333374,805.799194 
	C681.983032,799.717773 685.820496,793.245483 692.570007,793.230591 
	C693.902344,793.227661 695.235229,793.259888 696.567017,793.234131 
	C706.647827,793.039368 705.289062,792.325256 708.898560,802.523743 
z"/>
<path fill="#E9E1D0" opacity="1.000000" stroke="none" 
	d="
M517.825684,753.073547 
	C518.695740,752.384949 519.565796,751.696289 521.206299,750.943787 
	C528.941467,750.889221 535.914001,751.112671 542.869141,750.858154 
	C553.481384,750.469788 563.237122,752.650635 571.733337,759.313904 
	C571.733337,759.313904 571.888794,759.145813 571.903503,759.482788 
	C572.521851,760.315430 573.125549,760.811035 573.729187,761.306702 
	C573.729187,761.306702 573.850952,761.102173 573.835938,761.471680 
	C574.348694,762.382996 574.876526,762.924744 575.404358,763.466553 
	C575.404358,763.466553 575.380859,763.543091 575.376831,763.583191 
	C576.826660,767.204651 578.280457,770.786011 579.862549,775.167236 
	C581.378662,786.739197 578.779968,796.233276 570.994080,804.281921 
	C571.004517,804.502930 571.013062,804.945374 570.644104,804.862549 
	C562.911560,810.302795 554.511719,812.209290 545.455627,812.138428 
	C537.328613,812.074890 529.199463,812.274414 520.763550,812.213196 
	C519.637512,811.684387 518.819214,811.300354 517.971680,810.512756 
	C518.830261,807.997620 519.719604,805.886719 520.605591,803.774353 
	C521.207336,802.339661 522.306519,800.909241 522.324463,799.466248 
	C522.473206,787.515991 522.467285,775.562866 522.362122,763.611938 
	C522.351135,762.365356 521.411560,761.118652 520.869568,759.886658 
	C519.867920,757.609924 518.841675,755.343994 517.825684,753.073547 
M548.724854,809.352600 
	C552.465820,808.592346 556.342590,808.227844 559.922791,806.999207 
	C571.577148,802.999878 577.927063,793.254089 577.692749,780.352356 
	C577.475586,768.393005 570.185364,757.576294 558.386597,755.541504 
	C546.705872,753.527039 534.601685,753.958557 522.679993,753.384949 
	C522.190796,753.361450 521.668457,754.028198 520.648682,754.722656 
	C526.291565,758.762268 524.865051,764.469055 524.906311,769.803284 
	C524.955322,776.135559 525.163818,782.481201 524.843506,788.797729 
	C524.504211,795.487976 527.256836,802.805054 520.664795,808.408203 
	C520.884399,808.756531 521.104004,809.104797 521.323608,809.453125 
	C530.159058,809.453125 538.994446,809.453125 548.724854,809.352600 
z"/>
<path fill="#E9E1D0" opacity="1.000000" stroke="none" 
	d="
M663.959900,768.955383 
	C663.890503,779.401978 663.555786,789.857788 663.919128,800.289307 
	C664.023315,803.279785 666.146179,806.199890 666.839478,809.708130 
	C664.575439,810.509827 662.818298,810.941650 661.054749,810.969238 
	C653.845154,811.082458 646.633545,811.072083 639.422729,811.107300 
	C638.692993,808.638245 637.444519,806.199158 640.790771,804.372009 
	C641.698853,803.876160 642.353210,802.096985 642.363220,800.900452 
	C642.473206,787.795471 642.432190,774.689270 642.432190,761.186890 
	C636.507324,761.827759 634.354309,765.636780 632.903503,770.804626 
	C632.818787,771.340088 632.847168,771.741943 632.637878,771.836670 
	C632.151672,772.096375 631.960815,772.331177 631.535767,772.676331 
	C630.816345,772.873352 630.417114,773.029968 630.017883,773.186584 
	C625.516602,773.417419 623.557007,771.646240 624.747803,766.935242 
	C625.354248,764.535950 625.470276,762.011536 625.791321,759.541504 
	C626.913025,750.910950 626.910339,750.908264 635.760376,750.909668 
	C648.397461,750.911743 661.037231,751.066345 673.670105,750.838318 
	C677.905029,750.761902 680.210144,751.623962 680.178467,756.418884 
	C680.154602,760.045227 680.661804,763.740662 681.452332,767.289673 
	C682.395203,771.523438 681.028748,773.327881 676.081543,772.976929 
	C675.010315,772.788391 674.617371,772.664978 674.080322,772.279480 
	C673.585449,771.310181 673.234497,770.603027 672.916260,769.641541 
	C672.748413,769.144653 672.547852,768.902100 672.406250,768.662598 
	C672.465271,768.665588 672.482544,768.548767 672.432983,768.214539 
	C671.207886,766.061890 670.032349,764.243408 668.963196,762.004639 
	C669.160095,761.309631 669.250549,761.034973 669.686340,760.816895 
	C672.497009,764.024292 674.962280,767.175171 677.427551,770.326050 
	C677.961548,770.105164 678.495605,769.884338 679.029602,769.663452 
	C678.296631,764.662720 677.682190,759.638428 676.695068,754.688354 
	C676.556458,753.993347 674.595642,753.235352 673.473450,753.227783 
	C659.809265,753.135864 646.143982,753.145813 632.479797,753.246948 
	C631.312134,753.255615 629.254517,753.933289 629.128784,754.590576 
	C628.171021,759.598816 627.591003,764.679260 626.893372,769.737305 
	C627.546692,769.929260 628.200012,770.121277 628.853394,770.313293 
	C629.654602,769.096191 630.375916,767.816162 631.273743,766.675110 
	C633.087646,764.369873 634.596619,761.512268 636.950745,760.009705 
	C641.866272,756.872498 644.980530,758.925415 644.973511,764.713135 
	C644.959656,776.212280 645.093994,787.714111 644.848450,799.208130 
	C644.800781,801.438171 643.322205,803.645508 642.460327,805.846436 
	C642.129150,806.692078 641.142334,807.926941 641.385315,808.224670 
	C641.999634,808.977539 643.129150,809.695801 644.075134,809.726562 
	C648.569824,809.872803 653.072021,809.789246 657.571594,809.779053 
	C665.592712,809.760925 665.592712,809.758423 662.121460,802.894775 
	C662.622925,800.487854 662.910767,798.380005 662.955444,796.267029 
	C663.160217,786.573181 663.297180,776.877869 663.578186,767.468445 
	C663.785278,768.154297 663.872620,768.554871 663.959900,768.955383 
z"/>
<path fill="#EDE5D6" opacity="1.000000" stroke="none" 
	d="
M620.932312,812.346313 
	C617.297546,812.264465 613.640564,812.389221 610.038879,812.000549 
	C608.571594,811.842102 607.224670,810.569153 605.800171,809.058350 
	C605.575867,807.237854 605.374268,806.162231 605.172668,805.086609 
	C605.172668,805.086609 605.045898,805.048035 605.412476,804.975708 
	C606.638794,803.805176 608.448914,802.300903 608.184875,801.682922 
	C607.300476,799.613098 605.942505,796.592224 604.279419,796.241455 
	C600.478638,795.439697 596.336426,795.642639 592.448242,796.217468 
	C591.170227,796.406433 589.868652,798.867920 589.307068,800.535706 
	C588.983643,801.496277 590.119507,802.948303 590.598511,804.179077 
	C592.333984,811.333557 591.755310,812.128601 584.843079,812.100098 
	C581.539124,812.086426 578.235107,812.078735 574.595581,811.709412 
	C574.506653,810.877319 574.753235,810.404175 575.462158,809.916199 
	C579.255005,809.879333 582.608826,810.064148 585.899597,809.691956 
	C586.735901,809.597351 588.237000,807.362976 587.969543,806.735596 
	C584.240417,797.988220 587.172363,793.185913 596.460510,793.195496 
	C598.459229,793.197510 600.457947,793.187256 602.456604,793.201050 
	C609.075745,793.246704 612.901184,800.176758 609.302246,805.959961 
	C607.539429,808.792542 608.727539,809.723999 611.257935,809.777405 
	C617.584106,809.910950 623.916199,809.854004 630.243469,809.740173 
	C631.054932,809.725586 632.341492,809.062378 632.523682,808.440552 
	C632.721252,807.766235 631.987427,806.720093 631.479858,805.955566 
	C629.511108,802.990356 626.941406,800.302612 625.549377,797.097351 
	C619.783630,783.821472 614.360352,770.396851 608.824036,757.021606 
	C607.315735,753.377380 594.901733,751.219666 592.317322,754.256348 
	C591.914551,754.729614 592.250061,755.831055 592.179077,757.047424 
	C592.056335,757.997009 591.998779,758.541199 591.941162,759.085388 
	C591.126526,757.034729 590.030823,755.024658 589.723511,752.900574 
	C589.658569,752.451416 592.460815,751.041565 593.970520,750.994812 
	C608.915161,750.531921 608.946350,750.574524 614.730164,764.411011 
	C619.405029,775.594360 623.888184,786.859558 628.717529,797.975037 
	C629.698608,800.233093 632.225769,801.793518 633.346741,804.024170 
	C634.397095,806.114014 634.528137,808.665955 634.627441,811.491943 
	C633.779846,812.100586 633.368469,812.231750 632.518433,812.253906 
	C629.076904,812.213013 626.074036,812.281189 622.717407,812.276733 
	C621.886536,812.251465 621.409424,812.298889 620.932312,812.346313 
z"/>
<path fill="#1433AA" opacity="1.000000" stroke="none" 
	d="
M632.847168,771.741943 
	C632.847168,771.741943 632.818787,771.340088 632.931030,771.172913 
	C638.625305,769.568665 639.943970,770.510010 639.987610,776.169373 
	C640.036804,782.552307 640.266846,788.954285 639.856079,795.311707 
	C639.652954,798.454224 638.073547,801.408813 632.951111,801.636597 
	C629.891113,794.605164 626.508728,787.249084 623.504150,779.741882 
	C622.135559,776.322266 623.011169,773.769348 627.615234,775.109070 
	C624.854797,783.296204 631.138733,788.494751 632.728394,794.907227 
	C632.840820,795.360901 634.719238,795.933228 635.234253,795.603516 
	C636.056641,795.076965 636.768127,793.891357 636.919373,792.892578 
	C637.386230,789.807739 638.635742,786.242249 637.587708,783.678284 
	C636.262573,780.436890 640.056580,774.391602 632.968140,773.598999 
	C632.926331,772.731445 632.886719,772.236694 632.847168,771.741943 
z"/>
<path fill="#1430A3" opacity="1.000000" stroke="none" 
	d="
M672.883545,769.895874 
	C673.234497,770.603027 673.585449,771.310181 673.989197,772.685059 
	C670.520325,775.343384 667.227600,777.311462 667.931274,782.368713 
	C668.491150,786.392700 668.434998,790.502319 668.652283,794.573914 
	C669.309021,794.608826 669.965820,794.643738 670.622559,794.678650 
	C675.524597,789.147888 678.439209,782.810486 677.923340,775.080811 
	C679.134644,775.293152 680.345947,775.505432 682.615417,775.903198 
	C679.112793,783.924866 676.014587,791.264343 672.644470,798.476807 
	C672.136108,799.564636 670.262573,800.765991 669.198486,800.619812 
	C668.239990,800.488098 667.186401,798.694458 666.758362,797.435486 
	C663.884705,788.983459 665.053833,780.439026 666.642761,771.958618 
	C666.854309,770.829285 668.373047,769.944824 670.087280,768.922485 
	C671.419556,768.781616 671.951050,768.665222 672.482544,768.548767 
	C672.482544,768.548767 672.465271,768.665588 672.360596,768.915894 
	C672.465088,769.409363 672.674316,769.652649 672.883545,769.895874 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M632.970398,773.971802 
	C640.056580,774.391602 636.262573,780.436890 637.587708,783.678284 
	C638.635742,786.242249 637.386230,789.807739 636.919373,792.892578 
	C636.768127,793.891357 636.056641,795.076965 635.234253,795.603516 
	C634.719238,795.933228 632.840820,795.360901 632.728394,794.907227 
	C631.138733,788.494751 624.854797,783.296204 627.839722,775.385620 
	C628.649475,774.618469 629.292725,774.212891 629.976929,773.496948 
	C630.417114,773.029968 630.816345,772.873352 631.638794,772.962952 
	C632.364868,773.463379 632.667603,773.717590 632.970398,773.971802 
z"/>
<path fill="#173BC4" opacity="1.000000" stroke="none" 
	d="
M677.620117,775.031372 
	C678.439209,782.810486 675.524597,789.147888 670.622559,794.678650 
	C669.965820,794.643738 669.309021,794.608826 668.652283,794.573914 
	C668.434998,790.502319 668.491150,786.392700 667.931274,782.368713 
	C667.227600,777.311462 670.520325,775.343384 674.133179,772.947144 
	C674.617371,772.664978 675.010315,772.788391 675.677307,773.054932 
	C676.406494,773.792664 676.861694,774.387329 677.620117,775.031372 
z"/>
<path fill="#F5E1BC" opacity="1.000000" stroke="none" 
	d="
M592.005005,759.477539 
	C591.998779,758.541199 592.056335,757.997009 592.492493,757.211304 
	C593.221741,759.018799 594.359192,761.324219 593.789246,763.073181 
	C591.063721,771.437012 587.973145,779.687683 584.816162,787.903137 
	C582.822083,793.092224 580.482971,798.148865 578.189453,802.815247 
	C578.002991,801.754822 577.925659,801.144043 577.857422,800.176514 
	C580.700745,791.893250 583.534973,783.966736 586.369141,776.040283 
	C588.269043,770.650085 590.168945,765.259888 592.005005,759.477539 
z"/>
<path fill="#1D2A5E" opacity="1.000000" stroke="none" 
	d="
M586.082642,776.091064 
	C583.534973,783.966736 580.700745,791.893250 577.612671,800.372864 
	C577.089111,801.488281 576.819275,802.050537 576.243408,802.721436 
	C574.957092,803.277893 573.976868,803.725830 572.667114,804.093567 
	C571.886292,804.029236 571.434937,804.045105 570.983643,804.060913 
	C578.779968,796.233276 581.378662,786.739197 580.019287,775.506836 
	C580.898621,776.149597 581.749573,777.252441 582.644409,778.412170 
	C583.741699,777.621704 584.768921,776.881775 586.082642,776.091064 
z"/>
<path fill="#1D2A5E" opacity="1.000000" stroke="none" 
	d="
M672.432983,768.214539 
	C671.951050,768.665222 671.419556,768.781616 670.482117,768.875000 
	C668.415833,763.950378 666.456970,766.801270 664.248535,768.796997 
	C663.872620,768.554871 663.785278,768.154297 663.670654,767.041382 
	C663.589539,765.176575 663.535889,764.024048 663.625977,762.581665 
	C663.888245,761.876160 664.006714,761.460693 664.477966,761.077759 
	C666.172791,761.548584 667.514771,761.986816 668.856750,762.424988 
	C670.032349,764.243408 671.207886,766.061890 672.432983,768.214539 
z"/>
<path fill="#0F2781" opacity="1.000000" stroke="none" 
	d="
M650.997559,812.353821 
	C655.584595,812.689941 660.171570,813.026062 664.758606,813.362183 
	C664.764954,813.575684 664.771301,813.789246 664.777649,814.002747 
	C657.160828,814.002747 649.544006,814.002747 641.457520,814.020691 
	C640.544128,814.012573 640.100342,813.986511 639.326355,813.910400 
	C639.079651,813.391296 639.163147,812.922302 639.767090,812.294067 
	C640.542419,812.212952 640.797302,812.291016 641.308716,812.571838 
	C643.022522,812.636169 644.479980,812.497620 646.354004,812.284546 
	C648.179565,812.257996 649.588562,812.305908 650.997559,812.353821 
z"/>
<path fill="#0F2781" opacity="1.000000" stroke="none" 
	d="
M620.601318,812.571594 
	C621.409424,812.298889 621.886536,812.251465 623.033936,812.497070 
	C626.788574,812.647705 629.872803,812.505249 632.957092,812.362854 
	C633.368469,812.231750 633.779846,812.100586 634.510803,811.912354 
	C635.128479,812.064941 635.426636,812.274597 635.868774,812.717468 
	C635.246582,813.314880 634.480469,813.679016 632.902710,814.030945 
	C624.379944,814.033997 616.668823,814.049255 608.957642,814.064514 
	C608.790100,814.059937 608.622620,814.055420 608.203369,814.037659 
	C612.057922,813.615234 616.164124,813.206055 620.601318,812.571594 
z"/>
<path fill="#E2E2E2" opacity="1.000000" stroke="none" 
	d="
M641.052246,812.369019 
	C640.797302,812.291016 640.542419,812.212952 639.890015,812.084839 
	C639.492554,812.034912 639.443848,811.572449 639.433289,811.339844 
	C646.633545,811.072083 653.845154,811.082458 661.054749,810.969238 
	C662.818298,810.941650 664.575439,810.509827 666.731445,810.099060 
	C667.109070,810.349121 667.090881,810.764038 667.005554,811.490112 
	C661.928589,811.918579 656.918762,812.035767 651.453247,812.253418 
	C649.588562,812.305908 648.179565,812.257996 645.955688,812.184814 
	C643.778015,812.229431 642.415100,812.299255 641.052246,812.369019 
z"/>
<path fill="#1433AA" opacity="1.000000" stroke="none" 
	d="
M587.355530,764.841553 
	C586.849915,762.282532 586.655334,759.548035 586.760986,756.909607 
	C588.241455,759.481873 590.388489,761.881592 587.355530,764.841553 
z"/>
<path fill="#1433AA" opacity="1.000000" stroke="none" 
	d="
M579.953003,765.430054 
	C582.041687,765.619080 583.788086,768.502258 586.693115,766.027222 
	C586.114746,767.004150 584.852966,768.998840 584.389465,768.828003 
	C582.768677,768.230469 581.423462,766.885498 579.953003,765.430054 
z"/>
<path fill="#1433AA" opacity="1.000000" stroke="none" 
	d="
M587.249634,754.882935 
	C587.022339,754.578247 587.014465,754.115051 587.018616,753.304260 
	C587.176758,753.545898 587.322876,754.135193 587.249634,754.882935 
z"/>
<path fill="#253C91" opacity="1.000000" stroke="none" 
	d="
M468.013702,212.411484 
	C468.340210,211.225449 468.664185,210.446472 468.982849,209.328903 
	C468.977539,208.990311 468.997955,208.994675 469.298279,208.851593 
	C473.324860,205.410583 476.962158,202.004089 480.808472,198.852692 
	C483.402679,196.727249 486.291748,194.961655 489.048889,193.035095 
	C489.048889,193.035080 489.512909,193.012253 490.135437,193.004745 
	C492.953278,192.276932 495.179688,191.639053 497.338867,190.823242 
	C514.364380,184.390503 531.912720,183.014893 549.546997,186.938126 
	C558.284058,188.881943 566.568970,192.857742 575.058472,195.914200 
	C575.058472,195.914200 575.538757,195.948547 575.921936,196.279205 
	C577.862305,197.416595 579.419495,198.223328 580.976685,199.030045 
	C580.976685,199.030045 580.993958,199.002121 581.022217,199.360748 
	C583.363403,201.487106 585.676453,203.254837 587.989441,205.022552 
	C588.438660,205.654694 588.887878,206.286850 589.705017,207.374435 
	C590.398499,210.895996 590.724121,213.962128 591.049683,217.028259 
	C591.037659,217.463531 591.025574,217.898788 591.017456,218.660370 
	C587.237854,222.922195 583.521973,226.925674 579.652344,230.774597 
	C575.605469,234.799789 570.908691,237.245972 564.501282,235.941284 
	C554.720825,226.034592 542.644531,221.155334 529.659973,220.430023 
	C520.968628,219.944550 512.096375,223.033951 505.023132,229.617218 
	C502.062439,231.760178 499.165802,233.563934 496.106140,235.664825 
	C495.511902,235.978134 495.080719,235.994263 493.888306,236.013641 
	C491.084381,236.013794 489.041687,236.010696 486.998962,236.007614 
	C485.565582,235.348495 484.132233,234.689392 482.361847,233.991974 
	C482.024841,233.953659 482.008240,233.487366 481.899200,233.025848 
	C481.593353,232.279434 481.327423,232.093231 480.992371,232.005768 
	C479.206573,230.423584 477.420776,228.841385 475.296051,226.779419 
	C473.990967,225.510605 473.024841,224.721573 472.058685,223.932526 
	C471.870300,223.403778 471.681915,222.875031 471.244537,222.206085 
	C470.000763,218.983444 469.005981,215.901001 468.013702,212.411484 
M573.668335,198.737885 
	C562.258667,192.139816 550.015320,188.368378 536.825134,187.560059 
	C513.292786,186.118011 492.016357,191.654480 473.987305,207.489487 
	C469.426178,211.495560 469.177490,215.837646 473.393890,220.175186 
	C477.208374,224.099289 481.116516,227.935211 485.062073,231.728134 
	C488.208374,234.752762 492.431732,234.812363 495.775970,232.036011 
	C518.175293,213.440323 541.342468,213.350021 563.475281,231.772217 
	C567.393555,235.033585 571.361450,234.937347 574.954834,231.401443 
	C578.865479,227.553329 582.747375,223.674896 586.586487,219.755508 
	C590.278320,215.986526 590.033325,211.176437 585.833008,207.796814 
	C582.085938,204.781876 578.148315,202.003845 573.668335,198.737885 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M574.999084,195.569489 
	C566.568970,192.857742 558.284058,188.881943 549.546997,186.938126 
	C531.912720,183.014893 514.364380,184.390503 497.338867,190.823242 
	C495.179688,191.639053 492.953278,192.276932 490.367371,192.991669 
	C493.084808,191.126251 495.978210,188.479691 499.343170,187.561798 
	C507.698547,185.282639 516.052795,183.353348 524.895508,182.614548 
	C537.171204,181.588959 548.556885,184.148666 559.913269,187.351868 
	C565.238586,188.853928 569.950073,192.532547 574.999084,195.569489 
z"/>
<path fill="#1A38B2" opacity="1.000000" stroke="none" 
	d="
M485.348206,186.983246 
	C486.933167,181.127563 492.910339,181.324768 497.163696,179.700150 
	C509.597870,174.950729 522.552429,171.910065 536.063110,173.084045 
	C538.380188,173.285400 540.611206,174.477921 542.967163,175.579559 
	C535.687500,175.953903 528.224731,175.204285 520.980164,176.141373 
	C509.350281,177.645706 497.843689,180.191803 487.628876,186.553436 
	C487.106598,186.878693 486.365051,186.851913 485.348206,186.983246 
z"/>
<path fill="#1A38B2" opacity="1.000000" stroke="none" 
	d="
M593.148254,195.858704 
	C593.411499,196.998047 593.674744,198.137405 593.975586,199.632828 
	C591.277466,198.105179 588.641479,196.052078 585.785645,194.372696 
	C579.709900,190.799850 573.744812,186.905807 567.316406,184.101456 
	C560.255615,181.021210 552.725037,179.017960 545.195618,176.239166 
	C546.648254,175.910507 548.404846,175.515182 549.943115,175.929901 
	C556.815186,177.782516 563.624817,179.866241 570.839539,181.767517 
	C571.222046,181.668488 571.251099,181.668304 571.226257,182.075027 
	C572.596924,182.935349 573.992371,183.388931 575.555542,183.823914 
	C575.723145,183.805313 576.007935,183.986115 576.130737,184.224670 
	C576.578064,184.588547 576.902527,184.713852 577.165466,184.858002 
	C577.103943,184.876862 577.178711,184.772095 577.154297,185.130371 
	C580.183105,187.228287 583.236389,188.967941 586.232666,190.739441 
	C586.175720,190.771271 586.201782,190.643402 586.277466,190.972504 
	C586.959229,191.490005 587.565186,191.678406 588.127075,191.884644 
	C588.082947,191.902466 588.093750,191.807922 588.175537,192.083649 
	C588.588989,192.520615 588.920776,192.681854 589.187012,192.857025 
	C589.121582,192.870956 589.149353,192.740005 589.219055,193.026672 
	C589.631714,193.463150 589.974609,193.612976 590.253906,193.773438 
	C590.190247,193.784073 590.255371,193.672623 590.250854,193.948029 
	C590.491516,194.479218 590.736572,194.735001 591.264099,194.994736 
	C591.732910,194.972183 591.919312,194.945663 592.068848,194.901016 
	C592.031982,194.882889 592.083313,194.818665 592.156494,195.092468 
	C592.542969,195.555450 592.856262,195.744614 593.134521,195.923523 
	C593.099365,195.913254 593.148254,195.858704 593.148254,195.858704 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M488.630432,193.022430 
	C486.291748,194.961655 483.402679,196.727249 480.808472,198.852692 
	C476.962158,202.004089 473.324860,205.410583 469.294800,208.844391 
	C468.995392,208.237213 468.999817,207.494141 469.007019,206.379456 
	C469.594391,205.517456 470.179016,205.027069 470.884949,204.271545 
	C472.907471,202.536545 474.808685,201.066681 476.857849,199.301498 
	C480.674316,196.882462 482.791077,191.858704 488.630432,193.022430 
z"/>
<path fill="#1A38B2" opacity="1.000000" stroke="none" 
	d="
M596.042542,201.400696 
	C600.055115,201.017410 604.120789,201.021393 608.602783,201.025803 
	C605.624451,204.960724 599.598450,205.315918 596.042542,201.400696 
z"/>
<path fill="#1A38B2" opacity="1.000000" stroke="none" 
	d="
M476.308655,192.853989 
	C476.021515,188.066269 480.239777,188.258347 483.658051,187.021744 
	C481.570099,188.930359 479.107330,190.828827 476.308655,192.853989 
z"/>
<path fill="#1839BC" opacity="1.000000" stroke="none" 
	d="
M591.407471,216.947540 
	C590.724121,213.962128 590.398499,210.895996 590.016907,207.426910 
	C592.963989,208.571609 593.739746,212.803741 591.407471,216.947540 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M587.964233,204.670685 
	C585.676453,203.254837 583.363403,201.487106 581.010254,199.376007 
	C583.293152,200.794693 585.616089,202.556747 587.964233,204.670685 
z"/>
<path fill="#1A38B2" opacity="1.000000" stroke="none" 
	d="
M469.217346,197.243469 
	C470.772491,195.662170 472.553955,194.359665 474.685791,193.045868 
	C474.387573,194.452515 473.739044,195.870453 473.033081,197.630188 
	C471.798309,197.822067 470.621002,197.672150 469.217346,197.243469 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M580.837524,198.714127 
	C579.419495,198.223328 577.862305,197.416595 576.162109,196.296783 
	C577.578796,196.788528 579.138550,197.593369 580.837524,198.714127 
z"/>
<path fill="#EDE5D6" opacity="1.000000" stroke="none" 
	d="
M398.834534,753.109924 
	C399.388458,752.435364 399.942383,751.760803 401.235565,750.980591 
	C405.807434,750.887695 409.664978,751.178101 413.465851,750.836731 
	C417.468536,750.477356 419.398621,752.040955 420.863525,755.728577 
	C426.697968,770.415039 432.720612,785.031555 439.031372,799.518127 
	C439.879211,801.464355 442.768524,802.521362 444.712494,803.990173 
	C445.472595,802.032776 446.852509,800.087158 446.890015,798.116028 
	C447.099243,787.121826 446.856323,776.119141 447.055725,765.124573 
	C447.116180,761.793274 446.437225,759.312500 443.313324,757.843811 
	C443.313324,757.843811 443.290924,757.387024 443.169220,756.810425 
	C443.003754,755.171875 442.959961,754.109924 443.083832,752.796509 
	C443.546753,752.019897 443.842010,751.494751 444.591064,750.975952 
	C459.684265,751.560425 474.574921,748.829163 488.858521,754.251099 
	C488.874847,754.194275 488.763245,754.233154 488.956970,754.503540 
	C491.063843,755.899048 492.976990,757.024109 494.877747,758.136169 
	C494.865356,758.123169 494.847015,758.159241 494.996887,758.459961 
	C497.735535,762.541870 501.190002,765.997131 502.758209,770.162659 
	C508.509094,785.438110 503.490448,798.574524 488.471191,808.543030 
	C481.946075,809.788391 475.832306,811.741394 469.657410,811.959045 
	C451.813141,812.587769 433.944244,812.517944 416.085022,812.722412 
	C416.085022,812.722412 416.085083,812.904724 416.086639,812.602600 
	C415.783020,812.069336 415.477844,811.838196 415.172668,811.606995 
	C415.172668,811.606995 415.279083,811.250793 415.246216,810.709473 
	C415.277832,808.381409 415.342316,806.594604 415.716370,804.698425 
	C416.640289,804.374634 417.254639,804.160278 418.149414,804.103760 
	C418.673859,804.610962 418.917847,804.960327 418.959229,805.622437 
	C416.812042,808.975098 418.572449,809.777771 421.211731,809.806580 
	C427.134186,809.871277 433.060394,809.914612 438.978455,809.740601 
	C439.990631,809.710815 440.969879,808.561096 441.964294,807.928711 
	C441.098175,807.169006 440.232056,806.409241 439.366638,805.675415 
	C439.367371,805.701355 439.321198,805.677673 439.241577,805.344482 
	C437.980652,802.829468 436.749023,800.673096 435.629333,798.460144 
	C433.772064,794.789124 431.986786,791.081726 430.312134,787.184082 
	C430.504120,786.623840 430.406921,786.316162 430.147644,785.723999 
	C429.791473,784.843079 429.449158,784.293457 429.098175,783.534241 
	C429.089478,783.324585 428.827881,782.996277 428.841675,782.670410 
	C428.293365,781.202637 427.731232,780.060791 427.155731,778.634155 
	C426.874542,777.860840 426.606720,777.372253 426.300964,776.536621 
	C425.567627,774.763672 424.872253,773.337830 424.188171,771.634888 
	C423.954742,770.874451 423.710052,770.391113 423.395172,769.552246 
	C422.929138,768.605652 422.533325,768.014587 422.227600,767.352783 
	C422.317749,767.281982 422.243347,767.065247 422.222626,766.685425 
	C421.175262,764.077698 420.148651,761.849854 419.138916,759.446411 
	C419.155792,759.270691 418.931580,758.997986 418.917877,758.626953 
	C417.288330,750.822815 411.615326,753.446472 406.634644,753.338135 
	C405.515686,753.263489 404.760345,753.303589 403.668793,753.276672 
	C402.763794,754.085449 401.626740,755.670349 401.707794,755.735352 
	C405.564941,758.826660 403.751495,762.461914 402.751373,766.119629 
	C402.686768,766.231323 402.851349,766.430054 402.567291,766.656494 
	C402.121704,767.570068 401.960205,768.257141 401.857361,768.945068 
	C401.916016,768.945801 401.886505,768.832214 401.669922,768.934448 
	C401.217224,769.301208 401.157166,769.600952 401.126526,770.241821 
	C400.899414,770.990662 400.818909,771.433533 400.508240,772.123413 
	C399.776733,773.860962 399.275421,775.351440 398.787903,776.876465 
	C398.801697,776.910889 398.863678,776.870117 398.581238,777.094482 
	C396.158386,783.532776 394.017944,789.746826 391.918793,795.969543 
	C391.960083,795.978271 391.927185,795.900574 391.675140,796.075684 
	C391.280518,796.834656 391.137939,797.418518 390.896606,798.126953 
	C390.797913,798.251526 390.858429,798.563599 390.532776,798.626953 
	C389.779968,798.798340 389.352844,798.906372 388.789490,798.680420 
	C388.780243,796.243591 388.907288,794.140808 389.147705,791.675903 
	C390.222076,788.630066 391.183075,785.946350 392.496948,783.169434 
	C395.754547,775.829590 398.685272,768.593018 401.514618,761.317078 
	C401.739746,760.738220 401.342651,759.858704 401.098358,759.167053 
	C400.380585,757.135010 399.594086,755.127197 398.834534,753.109924 
M449.895935,753.165344 
	C448.564392,753.353760 447.232849,753.542175 446.263458,753.679382 
	C447.384644,757.216248 449.009430,760.064087 449.076202,762.947998 
	C449.361298,775.258667 449.270569,787.581177 449.092804,799.896179 
	C449.064392,801.862366 448.339752,804.156128 447.154266,805.695801 
	C444.881287,808.647766 446.181793,809.788879 448.969208,809.738647 
	C458.930542,809.559326 468.999908,809.912659 478.817932,808.549133 
	C492.802856,806.606812 501.617584,796.338318 502.166809,783.317261 
	C502.788361,768.580872 495.781891,757.982483 481.064606,754.943298 
	C471.275452,752.921692 460.915588,753.663818 449.895935,753.165344 
z"/>
<path fill="#EFE4CD" opacity="1.000000" stroke="none" 
	d="
M401.032379,810.914429 
	C401.032379,810.914429 400.780334,811.154175 400.380554,811.430664 
	C398.673676,811.937866 397.366608,812.168579 395.716431,812.317383 
	C394.908264,812.274963 394.443176,812.314331 393.737366,812.266724 
	C393.335266,812.235229 393.173920,812.290771 392.611755,812.268921 
	C391.127228,812.242126 390.043549,812.292664 388.512604,812.241821 
	C384.395355,812.210693 380.725403,812.281067 376.622986,812.276367 
	C374.434631,812.251831 372.678711,812.302307 370.475708,812.249878 
	C367.031250,812.139587 363.970795,812.490662 361.062286,811.978516 
	C359.534454,811.709412 358.272675,809.929443 356.970459,808.466309 
	C358.228516,806.009521 360.560699,803.807617 360.372986,801.847046 
	C359.761108,795.456543 358.229187,789.154175 357.051239,782.817932 
	C356.734406,782.829285 356.417572,782.840637 356.100739,782.851990 
	C353.579742,790.508667 351.058746,798.165405 348.338318,806.115356 
	C345.692200,812.959717 340.545013,814.425842 335.983307,809.498169 
	C332.926453,800.520996 329.897888,791.908569 326.911377,782.833008 
	C327.046509,781.440063 327.139618,780.510254 327.481476,779.852783 
	C330.881195,788.491577 333.885773,796.918335 337.296356,805.177490 
	C338.054779,807.014099 340.242981,808.260193 341.772888,809.778198 
	C343.100586,808.315674 344.950684,807.067078 345.654236,805.348938 
	C347.475311,800.901672 348.733582,796.227295 350.322845,791.680847 
	C352.359283,785.854980 354.499725,780.065552 356.595367,774.260498 
	C357.169312,774.298523 357.743225,774.336609 358.317139,774.374695 
	C359.832764,784.453857 361.348358,794.533081 362.546021,804.804932 
	C361.560364,806.168823 360.892639,807.340149 360.224915,808.511353 
	C361.051086,808.932373 361.874542,809.713684 362.703796,809.719910 
	C373.617859,809.802246 384.536377,809.930176 395.445038,809.674072 
	C398.417755,809.604309 399.221863,807.828430 396.244476,805.532593 
	C395.833069,804.822144 395.492767,804.385437 395.193054,803.579468 
	C396.010315,800.503296 396.786957,797.796326 397.762634,794.961182 
	C398.347809,794.341675 398.733948,793.850464 399.431213,793.307068 
	C400.529419,793.427246 401.316528,793.599548 402.000397,794.042358 
	C401.610962,794.814026 401.324829,795.315247 400.768494,796.065063 
	C399.827057,797.965637 398.772949,799.585815 398.631775,801.281982 
	C398.550323,802.260193 399.943970,803.361145 400.748291,804.800049 
	C400.936066,806.781250 401.049286,808.369263 401.083496,810.190247 
	C401.004486,810.423218 401.032379,810.914429 401.032379,810.914429 
z"/>
<path fill="#1E338B" opacity="1.000000" stroke="none" 
	d="
M416.294067,813.085449 
	C433.944244,812.517944 451.813141,812.587769 469.657410,811.959045 
	C475.832306,811.741394 481.946075,809.788391 488.517639,808.927368 
	C488.977631,809.482849 489.007812,809.738953 489.038025,809.995117 
	C486.429840,811.308960 483.923889,813.445740 481.194550,813.784912 
	C472.429016,814.874084 463.588196,815.698792 454.762604,815.857788 
	C447.295990,815.992432 439.812439,814.774170 432.331909,814.714294 
	C428.308990,814.682007 424.276703,815.826660 420.248627,816.440247 
	C420.209564,815.975098 420.170471,815.509949 420.131409,815.044800 
	C422.026031,815.044800 423.920654,815.044800 425.815308,815.044800 
	C425.821655,814.827209 425.828003,814.609619 425.834381,814.392029 
	C422.723938,814.077515 419.613525,813.763000 416.294067,813.085449 
z"/>
<path fill="#253C91" opacity="1.000000" stroke="none" 
	d="
M489.402222,809.993774 
	C489.007812,809.738953 488.977631,809.482849 488.901031,808.842346 
	C503.490448,798.574524 508.509094,785.438110 502.758209,770.162659 
	C501.190002,765.997131 497.735535,762.541870 495.390198,758.404419 
	C497.185242,758.879333 498.835175,759.571594 500.260101,760.581421 
	C501.623169,761.547546 502.752350,762.843750 503.984314,763.994873 
	C503.984314,763.994873 503.994965,764.002502 503.999634,764.009155 
	C505.064240,767.274048 506.685699,770.471558 507.046570,773.805542 
	C507.533020,778.299072 507.707764,783.026611 506.848083,787.425354 
	C504.865540,797.569275 499.907684,805.841309 489.402222,809.993774 
z"/>
<path fill="#223A9A" opacity="1.000000" stroke="none" 
	d="
M336.011597,809.862915 
	C340.545013,814.425842 345.692200,812.959717 348.718536,806.054016 
	C350.233276,803.530518 350.938965,801.216064 352.188507,799.246704 
	C352.952789,798.042114 354.552216,797.367432 355.776550,796.454773 
	C356.294403,798.193054 357.127991,799.909119 357.252533,801.675171 
	C357.419403,804.040466 357.037201,806.444397 356.890747,808.831787 
	C358.272675,809.929443 359.534454,811.709412 361.062286,811.978516 
	C363.970795,812.490662 367.031250,812.139587 370.151184,812.472168 
	C366.832703,813.201721 363.391663,813.606201 359.950684,814.010620 
	C358.435364,813.210510 356.830719,812.540161 355.428925,811.575195 
	C353.904022,810.525635 352.567474,809.202454 351.061157,807.924072 
	C345.750275,815.924866 337.610931,816.919617 334.392242,810.391357 
	C335.131042,809.953247 335.571320,809.908081 336.011597,809.862915 
z"/>
<path fill="#1F2F70" opacity="1.000000" stroke="none" 
	d="
M335.983307,809.498230 
	C335.571320,809.908081 335.131042,809.953247 334.359528,810.017334 
	C333.844177,809.470642 333.660065,808.904846 333.241241,808.149536 
	C332.302094,805.427307 331.762085,802.829102 330.795380,800.400818 
	C330.495972,799.648743 329.036957,799.358337 328.108887,798.856567 
	C327.824005,799.280579 327.539124,799.704590 327.254242,800.128540 
	C327.083344,799.054626 326.912415,797.980652 326.741486,796.906677 
	C326.383759,796.949219 326.026031,796.991699 325.668335,797.034180 
	C325.881744,799.589600 326.095154,802.144958 325.728882,804.753540 
	C324.415619,804.545898 323.682037,804.285156 322.962769,804.035767 
	C322.977051,804.047119 323.015686,804.041504 322.932983,803.599365 
	C323.605072,796.898438 324.328156,790.635254 325.183319,784.390198 
	C325.242676,783.956665 326.284424,783.657654 326.869324,783.296082 
	C329.897888,791.908569 332.926453,800.520996 335.983307,809.498230 
z"/>
<path fill="#253267" opacity="1.000000" stroke="none" 
	d="
M400.673676,804.406860 
	C399.943970,803.361145 398.550323,802.260193 398.631775,801.281982 
	C398.772949,799.585815 399.827057,797.965637 401.229675,796.115845 
	C406.942627,795.963196 411.924133,796.008301 416.951599,796.027954 
	C416.997559,796.002441 416.907471,796.051575 416.674744,796.216980 
	C416.469757,796.570740 416.497467,796.759094 416.550049,797.331238 
	C417.139069,799.124939 417.703217,800.534729 418.239563,802.284668 
	C418.097504,803.065186 417.983246,803.505554 417.869019,803.945923 
	C417.254639,804.160278 416.640289,804.374634 415.607727,804.309326 
	C414.806396,803.351746 414.423309,802.673828 414.040192,801.995911 
	C414.037628,801.828552 414.035065,801.661194 414.008972,800.852661 
	C412.176453,796.613770 408.860535,796.954529 405.737549,797.267883 
	C401.486084,797.694519 400.895782,800.981201 400.673676,804.406860 
z"/>
<path fill="#223A9A" opacity="1.000000" stroke="none" 
	d="
M393.023376,814.074097 
	C392.212860,814.711487 391.408417,815.893616 390.590790,815.902832 
	C381.308563,816.007446 372.024200,815.949890 362.741089,815.860901 
	C362.221741,815.855957 361.708649,815.195496 361.554871,814.428223 
	C372.285858,814.035645 382.654633,814.054871 393.023376,814.074097 
z"/>
<path fill="#1E338B" opacity="1.000000" stroke="none" 
	d="
M400.748291,804.800049 
	C400.895782,800.981201 401.486084,797.694519 405.737549,797.267883 
	C408.860535,796.954529 412.176453,796.613770 413.993347,800.602051 
	C405.240509,798.742798 404.397278,799.319397 401.788513,809.588684 
	C401.572479,809.981750 401.162476,809.957275 401.162476,809.957275 
	C401.049286,808.369263 400.936066,806.781250 400.748291,804.800049 
z"/>
<path fill="#0F2781" opacity="1.000000" stroke="none" 
	d="
M393.442627,814.061035 
	C382.654633,814.054871 372.285858,814.035645 361.446350,814.040649 
	C360.805023,814.055420 360.634430,814.046021 360.207214,814.023621 
	C363.391663,813.606201 366.832703,813.201721 370.598267,812.575073 
	C372.678711,812.302307 374.434631,812.251831 376.952209,812.498779 
	C381.462585,812.645203 385.211243,812.494202 388.959900,812.343201 
	C390.043549,812.292664 391.127228,812.242126 392.703217,812.402161 
	C393.195557,812.612732 393.644043,812.676208 393.644043,812.676208 
	C393.644043,812.676208 393.978058,812.353699 393.978058,812.353638 
	C394.443176,812.314331 394.908264,812.274963 395.901550,812.504761 
	C396.973785,813.188721 397.517914,813.603455 398.062042,814.018127 
	C396.661987,814.028076 395.261902,814.038025 393.442627,814.061035 
z"/>
<path fill="#1E338B" opacity="1.000000" stroke="none" 
	d="
M413.741150,802.265015 
	C414.423309,802.673828 414.806396,803.351746 415.298157,804.418762 
	C415.342316,806.594604 415.277832,808.381409 415.144714,810.553772 
	C411.175659,808.789978 413.365997,805.456543 413.741150,802.265015 
z"/>
<path fill="#223A9A" opacity="1.000000" stroke="none" 
	d="
M398.441833,814.000610 
	C397.517914,813.603455 396.973785,813.188721 396.244598,812.586609 
	C397.366608,812.168579 398.673676,811.937866 400.363586,811.603760 
	C400.104797,812.327942 399.463196,813.155518 398.441833,814.000610 
z"/>
<path fill="#223A9A" opacity="1.000000" stroke="none" 
	d="
M401.083496,810.190247 
	C401.162476,809.957275 401.572479,809.981750 401.777649,809.984863 
	C401.910645,810.309448 401.740814,810.566284 401.252838,810.836426 
	C401.032379,810.914429 401.004486,810.423218 401.083496,810.190247 
z"/>
<path fill="#1E338B" opacity="1.000000" stroke="none" 
	d="
M415.227875,811.907715 
	C415.477844,811.838196 415.783020,812.069336 416.175598,812.582642 
	C415.936340,812.646118 415.609711,812.427307 415.227875,811.907715 
z"/>
<path fill="#1F3281" opacity="1.000000" stroke="none" 
	d="
M830.309326,758.759033 
	C833.007385,758.782532 834.045166,760.058167 833.998596,762.821960 
	C833.844604,771.965393 834.022583,781.115112 833.824768,790.257019 
	C833.672546,797.289185 830.006287,801.301147 824.268555,801.269958 
	C818.108765,801.236450 814.272217,797.235779 814.118408,790.181091 
	C813.922791,781.205688 813.976013,772.223938 814.023132,763.245422 
	C814.029663,761.999634 814.278992,759.936462 814.986572,759.647034 
	C819.291443,757.886536 817.997803,754.825928 817.136902,751.420288 
	C817.060791,751.043335 817.538330,751.018005 818.103760,751.012207 
	C819.106506,751.023315 819.543823,751.040222 819.981079,751.057129 
	C819.981079,751.057129 819.976013,751.528076 819.976501,752.163940 
	C819.991333,753.868164 820.005676,754.936584 820.020020,756.004944 
	C819.997375,757.043640 819.974670,758.082397 819.580505,759.578369 
	C818.163269,761.133667 816.254517,762.199341 816.211975,763.335083 
	C815.933838,770.752502 815.554749,778.235291 816.286438,785.589478 
	C816.608887,788.829956 814.861877,794.238708 821.270386,795.131714 
	C822.380859,795.213196 823.220947,795.168945 824.295288,795.103699 
	C824.529480,795.082703 824.999390,795.063477 825.333862,795.072815 
	C826.114075,795.065735 826.559875,795.049316 827.378052,794.984985 
	C828.833374,792.570618 830.807251,790.217712 830.842957,787.835693 
	C830.988281,778.148315 830.539246,768.452026 830.309326,758.759033 
z"/>
<path fill="#253C91" opacity="1.000000" stroke="none" 
	d="
M882.033264,812.271973 
	C886.579834,810.882874 891.499512,810.184692 895.600708,807.970642 
	C906.423340,802.127808 907.092163,788.088623 897.275208,780.750732 
	C896.221985,779.963501 895.129761,779.228455 893.823364,778.305054 
	C894.241943,777.539246 894.585327,776.845581 894.986572,776.187195 
	C900.595886,766.982361 897.239685,756.931274 887.102844,753.137817 
	C884.346924,752.106384 881.339600,751.219177 878.438538,751.190735 
	C862.666565,751.035645 846.891663,751.170044 830.666809,751.242432 
	C830.877075,750.518188 831.538330,749.756409 832.866577,749.294495 
	C848.818787,749.718750 864.103943,749.853821 879.389465,749.924255 
	C879.929688,749.926758 880.472900,749.288635 881.014648,748.949097 
	C881.260071,748.732605 881.506897,748.325623 881.750793,748.327454 
	C888.707886,748.379639 899.533447,758.208801 899.981018,764.999084 
	C900.183655,768.073914 899.471497,771.299927 900.152405,774.230042 
	C901.063354,778.150330 902.498901,782.087402 904.460266,785.597595 
	C909.484680,794.589539 905.577820,804.887695 897.258240,809.447998 
	C896.243958,810.003906 895.344604,810.769531 894.188354,811.697998 
	C890.917786,812.621948 887.850891,813.285889 884.251587,813.723633 
	C883.157227,813.088989 882.595276,812.680481 882.033264,812.271973 
z"/>
<path fill="#193ECF" opacity="1.000000" stroke="none" 
	d="
M819.013184,787.006287 
	C819.341309,784.626648 819.756836,782.255127 819.978027,779.865540 
	C820.356201,775.780273 820.472656,771.666931 820.960022,767.596680 
	C821.438843,763.597839 823.490540,762.855347 826.838989,766.058167 
	C827.015991,767.498779 827.020996,768.244263 827.017944,769.459229 
	C827.008118,775.217712 827.043884,780.507080 826.994446,785.795532 
	C826.943787,791.215027 825.576294,792.024048 820.880371,789.565613 
	C820.312622,789.268494 819.639160,789.173279 819.013062,788.654724 
	C819.012024,787.885437 819.012573,787.445862 819.013184,787.006287 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M818.714111,786.713135 
	C819.012573,787.445862 819.012024,787.885437 819.039185,788.989258 
	C819.815613,790.649536 820.738831,791.565796 821.244141,792.672791 
	C821.523926,793.285706 821.104370,794.217712 821.000000,795.005920 
	C814.861877,794.238708 816.608887,788.829956 816.286438,785.589478 
	C815.554749,778.235291 815.933838,770.752502 816.211975,763.335083 
	C816.254517,762.199341 818.163269,761.133667 819.575684,759.967896 
	C819.295715,767.105042 818.598938,774.305847 818.040955,781.517517 
	C817.916016,783.132324 818.277466,784.784790 818.714111,786.713135 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M827.026001,768.989746 
	C827.020996,768.244263 827.015991,767.498779 827.014038,766.380798 
	C830.032532,764.676453 829.589233,763.000977 827.598877,761.037964 
	C827.354736,760.797180 827.671204,759.988037 828.115479,759.199463 
	C828.503052,758.956604 828.971619,758.948914 829.306885,758.931763 
	C829.858704,758.858154 830.075256,758.801636 830.291870,758.745056 
	C830.291870,758.745056 830.290161,758.769531 830.299744,758.764282 
	C830.539246,768.452026 830.988281,778.148315 830.842957,787.835693 
	C830.807251,790.217712 828.833374,792.570618 827.418213,794.593750 
	C827.684875,786.291138 828.313416,778.333557 828.799500,770.367249 
	C828.825195,769.945740 827.644836,769.450623 827.026001,768.989746 
z"/>
<path fill="#0F2277" opacity="1.000000" stroke="none" 
	d="
M880.544434,748.951904 
	C880.472900,749.288635 879.929688,749.926758 879.389465,749.924255 
	C864.103943,749.853821 848.818787,749.718750 833.235474,749.290527 
	C848.649597,748.976074 864.361938,748.965393 880.544434,748.951904 
z"/>
<path fill="#253C91" opacity="1.000000" stroke="none" 
	d="
M828.971619,758.948914 
	C828.971619,758.948914 828.503052,758.956604 828.269165,758.950928 
	C828.510681,756.353210 828.986145,753.761292 829.873718,751.187500 
	C830.254089,753.191284 830.222229,755.176758 829.901367,757.678833 
	C829.398804,758.446594 829.185181,758.697754 828.971619,758.948914 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M820.348511,755.955139 
	C820.005676,754.936584 819.991333,753.868164 819.986084,752.399231 
	C820.222412,753.300903 820.449707,754.603088 820.348511,755.955139 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M819.852173,750.818298 
	C819.543823,751.040222 819.106506,751.023315 818.341797,750.989014 
	C818.583984,750.840881 819.153687,750.710144 819.852173,750.818298 
z"/>
<path fill="#1430A3" opacity="1.000000" stroke="none" 
	d="
M344.992889,346.825439 
	C343.079376,347.401367 341.175385,347.959198 339.270142,347.963531 
	C312.828339,348.023651 286.386383,347.998962 259.944458,348.015594 
	C250.965073,348.021271 248.999207,349.170349 247.728622,357.976776 
	C247.079758,362.474030 248.079514,367.373627 249.198349,371.876678 
	C249.618301,373.566895 252.485947,374.648956 254.605194,376.003998 
	C254.972748,375.999695 254.988464,376.013947 255.041245,376.357330 
	C260.729462,380.915619 265.068115,386.388977 263.778412,393.533691 
	C262.096161,402.853119 259.141418,411.964172 256.401123,421.064575 
	C254.179718,428.441742 251.466507,435.670807 248.621201,443.035004 
	C247.529633,445.741974 246.788284,448.379486 246.046951,451.017029 
	C246.011703,452.370758 245.976440,453.724457 245.574310,455.556427 
	C243.800369,459.018616 242.393280,462.002594 240.619263,465.033752 
	C239.177032,467.726593 238.101761,470.372223 236.871490,473.352722 
	C236.132248,476.122040 235.547989,478.556519 234.605957,481.062439 
	C232.759964,484.533081 231.082306,487.863403 229.816498,491.343506 
	C224.490036,505.987823 219.315918,520.687439 214.027695,535.345764 
	C210.596893,544.855591 207.108673,554.345459 203.525299,563.798523 
	C203.063858,565.015747 201.874786,565.957214 201.051666,566.659729 
	C201.058884,564.529236 201.037186,562.765442 201.015488,561.001648 
	C201.015305,560.560120 201.015106,560.118652 201.366486,559.281616 
	C202.475677,556.922729 203.233276,554.959412 203.990875,552.996033 
	C205.828659,547.073059 207.499542,541.091553 209.547882,535.242310 
	C211.932892,528.431641 215.563004,522.216003 217.353287,515.004395 
	C219.415222,506.698608 223.909760,499.022400 226.998032,490.939148 
	C230.853455,480.847900 234.409973,470.640533 237.972641,460.440399 
	C239.179153,456.986053 240.011780,453.401184 241.020355,449.449829 
	C241.751785,448.260742 242.801315,447.623291 243.154083,446.715149 
	C246.441666,438.252075 249.068970,429.484436 252.966599,421.320160 
	C256.742950,413.409943 257.975739,404.946259 260.195862,396.695648 
	C261.935364,390.231079 260.261597,384.977692 254.326767,381.066895 
	C249.171783,377.669952 245.182358,373.354980 243.670105,366.800140 
	C241.800110,358.694794 245.253647,349.191010 252.486649,345.729767 
	C253.479706,345.254547 254.996017,345.872803 257.036560,345.983246 
	C261.712860,345.985107 265.622681,345.991150 269.532532,345.991608 
	C294.688354,345.994690 319.844177,345.996521 345.000000,345.998779 
	C345.000610,346.182526 345.001221,346.366241 344.992889,346.825439 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M344.946777,345.605896 
	C319.844177,345.996521 294.688354,345.994690 269.532532,345.991608 
	C265.622681,345.991150 261.712860,345.985107 257.397339,345.983887 
	C258.672211,344.992371 260.346771,343.144501 262.034149,343.132721 
	C288.335724,342.948853 314.639130,342.970428 340.941376,343.095276 
	C342.262085,343.101501 343.576294,344.475708 344.946777,345.605896 
z"/>
<path fill="#1433AA" opacity="1.000000" stroke="none" 
	d="
M518.000916,810.916321 
	C518.819214,811.300354 519.637512,811.684387 520.869263,812.580750 
	C521.679688,813.365967 522.081482,813.883911 522.472717,813.876038 
	C533.093811,813.662415 543.713074,813.340515 554.334717,813.181091 
	C556.558655,813.147766 558.791138,813.680725 561.019653,813.952698 
	C552.940613,814.777771 544.875244,815.806030 536.777283,816.348206 
	C532.253296,816.651123 527.654053,816.374634 523.126709,815.952087 
	C516.658142,815.348450 514.530457,811.139587 515.082825,804.730896 
	C516.159668,792.236328 516.331421,779.663513 516.861206,767.122375 
	C516.950012,765.021240 516.954163,762.916504 516.993896,760.410767 
	C516.990356,760.007996 517.048706,759.964722 517.391846,760.142090 
	C518.489380,762.372620 519.837646,764.409363 519.894958,766.481873 
	C520.136963,775.236267 520.027954,784.001587 519.976868,792.762451 
	C519.955200,796.477417 520.413391,800.089966 517.672241,803.556030 
	C516.520691,805.012146 517.817261,808.404297 518.000916,810.916321 
z"/>
<path fill="#1F3281" opacity="1.000000" stroke="none" 
	d="
M561.341736,813.961914 
	C558.791138,813.680725 556.558655,813.147766 554.334717,813.181091 
	C543.713074,813.340515 533.093811,813.662415 522.472717,813.876038 
	C522.081482,813.883911 521.679688,813.365967 521.177002,812.725525 
	C529.199463,812.274414 537.328613,812.074890 545.455627,812.138428 
	C554.511719,812.209290 562.911560,810.302795 570.673828,805.127441 
	C571.106140,805.649658 571.139893,805.824097 571.101562,806.409851 
	C571.112732,808.468750 571.196045,810.116394 571.279297,811.764038 
	C570.776306,811.836121 570.273193,811.908203 569.389526,812.004395 
	C566.586365,810.296692 564.783020,811.214478 563.110229,813.669556 
	C562.522217,813.969788 562.093018,813.970459 561.341736,813.961914 
z"/>
<path fill="#1839BC" opacity="1.000000" stroke="none" 
	d="
M503.985046,763.600159 
	C502.752350,762.843750 501.623169,761.547546 500.260101,760.581421 
	C498.835175,759.571594 497.185242,758.879333 495.240356,758.103760 
	C494.847015,758.159241 494.865356,758.123169 494.929108,757.777832 
	C495.018433,756.951355 495.044006,756.470215 495.069580,755.989014 
	C497.462646,756.322754 499.855682,756.656555 502.616455,756.993958 
	C503.318024,759.066833 503.651917,761.136169 503.985046,763.600159 
z"/>
<path fill="#1B41D9" opacity="1.000000" stroke="none" 
	d="
M499.223328,252.813553 
	C498.673828,252.519638 498.357574,252.054398 498.018829,251.292740 
	C498.429993,251.000900 498.863678,251.005463 499.621552,251.012131 
	C499.782715,251.556900 499.619629,252.099564 499.223328,252.813553 
z"/>
<path fill="#EDE5D6" opacity="1.000000" stroke="none" 
	d="
M643.900940,278.148743 
	C645.910034,281.946411 645.571655,285.301819 642.390503,288.435425 
	C633.169556,297.518768 624.051086,306.706116 614.873779,315.833923 
	C611.240784,319.447357 606.732910,319.411896 603.117249,315.807190 
	C593.960144,306.677826 584.674500,297.670929 575.756836,288.313385 
	C574.067261,286.540558 573.836060,283.377930 572.816406,280.465698 
	C573.512329,276.312775 576.489746,277.399902 579.568481,277.628235 
	C582.957275,278.116241 585.710693,278.267242 588.688232,278.430542 
	C588.688232,262.610168 588.688232,247.379761 588.688232,232.149353 
	C589.277710,231.539551 589.867249,230.929764 591.203491,230.279053 
	C602.840942,230.271713 613.734497,230.192581 624.619446,230.462402 
	C626.223083,230.502136 627.785645,232.195862 629.253052,233.133301 
	C629.138855,233.142273 628.938293,233.031631 628.643677,233.322937 
	C628.245483,247.511673 628.131104,261.409058 628.077393,275.306641 
	C628.075195,275.871063 628.645813,276.437744 629.161255,277.317322 
	C630.570129,277.775330 631.767334,277.919403 633.412109,278.177002 
	C637.206726,278.243286 640.553833,278.196014 643.900940,278.148743 
M606.963196,313.542023 
	C608.638367,313.330719 610.921387,313.749023 611.893982,312.809998 
	C620.985229,304.032532 629.865051,295.035492 638.763367,286.059631 
	C639.690308,285.124573 640.341248,283.915863 641.784424,281.911774 
	C638.215576,281.911774 635.926697,281.891907 633.638306,281.915405 
	C626.108337,281.992737 626.103455,281.998779 626.108582,274.696503 
	C626.117065,262.710388 626.067810,250.723648 626.185242,238.738571 
	C626.220093,235.173569 624.989563,233.632034 621.235718,233.725510 
	C613.416260,233.920212 605.580017,233.997498 597.767761,233.689072 
	C593.741211,233.530106 592.569824,234.938461 592.622009,238.797333 
	C592.793030,251.446091 592.523743,264.102020 592.787964,276.747620 
	C592.872009,280.771393 591.539368,282.160004 587.693604,281.952576 
	C584.231812,281.765869 580.752014,281.913208 577.280151,281.913208 
	C577.014587,282.577759 576.748962,283.242279 576.483398,283.906830 
	C586.465454,293.630402 596.447510,303.353973 606.963196,313.542023 
z"/>
<path fill="#1F3281" opacity="1.000000" stroke="none" 
	d="
M629.367310,233.124313 
	C627.785645,232.195862 626.223083,230.502136 624.619446,230.462402 
	C613.734497,230.192581 602.840942,230.271713 591.496338,230.132721 
	C592.453918,229.354462 593.848450,228.144897 595.279663,228.099823 
	C602.193665,227.882019 609.117554,227.977463 616.502808,227.938934 
	C618.408447,227.190552 621.514587,229.866882 621.627502,225.417465 
	C626.022278,225.046509 630.103394,225.046509 634.660889,225.046509 
	C634.660889,241.219910 634.660889,256.800262 634.660889,273.121094 
	C647.748901,273.121094 660.344849,273.121094 672.940796,273.121094 
	C672.880859,273.429840 672.721130,273.673004 672.263184,273.950806 
	C664.605164,274.041992 657.134583,273.808105 649.693665,274.176300 
	C647.743774,274.272797 645.886353,276.237946 643.943726,277.746826 
	C640.553833,278.196014 637.206726,278.243286 633.369629,277.787689 
	C632.259521,275.331970 631.133606,273.386993 631.102966,271.424896 
	C630.927368,260.179596 631.073608,248.929489 630.935364,237.683167 
	C630.916626,236.157349 629.914246,234.643616 629.367310,233.124313 
z"/>
<path fill="#1F3281" opacity="1.000000" stroke="none" 
	d="
M588.311951,232.223663 
	C588.688232,247.379761 588.688232,262.610168 588.688232,278.430542 
	C585.710693,278.267242 582.957275,278.116241 579.969238,277.591919 
	C585.274231,276.225281 587.256348,272.941620 587.076416,267.350647 
	C586.750427,257.219604 586.942566,247.070282 587.024414,236.929443 
	C587.036865,235.383255 587.617676,233.841675 588.311951,232.223663 
z"/>
<path fill="#1B41D9" opacity="1.000000" stroke="none" 
	d="
M584.467773,230.449829 
	C584.524780,230.415146 584.410706,230.484512 584.467773,230.449829 
z"/>
<path fill="#1430A3" opacity="1.000000" stroke="none" 
	d="
M179.958450,629.978333 
	C175.617615,640.047302 171.015060,650.013367 167.007370,660.213257 
	C162.745346,671.060303 154.107513,676.890869 144.073624,681.018738 
	C135.813583,684.416809 132.536606,689.107361 133.962677,698.195801 
	C134.500549,701.623596 135.907440,704.915100 136.947815,708.638428 
	C135.246231,707.580383 132.431900,706.437378 131.969086,704.676331 
	C129.836151,696.560425 130.127625,688.591797 135.984085,681.371948 
	C136.029709,681.019531 136.514633,681.013489 137.098175,680.974548 
	C139.454224,679.622314 141.226715,678.309021 142.999222,676.995728 
	C143.442963,676.996643 143.886719,676.997620 144.663147,676.993164 
	C146.965256,675.888672 148.934692,674.789551 150.961243,673.350525 
	C151.018356,673.010559 151.510025,673.011108 152.124298,673.003052 
	C155.493195,669.663330 158.247818,666.331665 161.002441,663.000000 
	C161.002441,663.000000 161.013351,662.512756 161.370819,662.222290 
	C162.814850,660.797180 164.371368,659.849854 164.910675,658.497009 
	C168.057129,650.603760 171.011169,642.632385 173.930817,634.651001 
	C175.089523,631.483521 175.993179,628.222778 177.012970,625.004456 
	C177.018036,624.264709 177.023117,623.524902 177.031799,622.416504 
	C181.568405,620.703857 180.005875,615.964417 181.965271,613.177307 
	C183.128174,611.523193 184.155014,609.773315 185.610992,608.419189 
	C185.862183,609.417297 185.802612,610.079529 185.619858,610.705811 
	C183.744186,617.133362 181.848526,623.554993 179.958450,629.978333 
z"/>
<path fill="#223A9A" opacity="1.000000" stroke="none" 
	d="
M138.264954,707.254333 
	C138.785370,707.332825 139.171936,707.620361 139.843842,708.129700 
	C141.150986,708.912415 142.152786,709.918030 143.197739,709.964966 
	C152.930832,710.403076 162.669464,710.856262 172.410080,710.955933 
	C187.489639,711.110168 202.572128,711.018066 217.653107,710.963013 
	C220.594818,710.952271 223.535400,710.634521 226.730560,710.711548 
	C225.034683,711.642151 223.087814,712.898743 221.134384,712.908997 
	C196.033615,713.041260 170.931915,713.006836 145.830490,712.981262 
	C142.567902,712.977905 139.353546,712.670227 137.185944,709.277344 
	C137.403229,708.507202 137.767166,707.985352 138.264954,707.254333 
z"/>
<path fill="#EFEBDE" opacity="1.000000" stroke="none" 
	d="
M552.354858,246.634033 
	C564.556091,258.004303 563.353882,282.468842 553.460571,292.479156 
	C544.947754,301.092682 529.872498,304.351898 516.815735,299.538605 
	C505.729828,295.451813 498.821960,285.260010 498.318512,272.500397 
	C497.891205,261.670135 500.384277,252.048706 509.193512,244.892197 
	C513.406006,241.470047 517.959900,239.074585 523.624817,238.884644 
	C533.906982,238.539886 543.983826,238.609497 552.354858,246.634033 
M551.220337,288.692169 
	C566.164429,271.242523 555.479797,244.623260 532.427551,242.280472 
	C519.660828,240.982971 507.268585,249.001907 503.197601,261.578247 
	C499.173553,274.009644 504.268433,287.815582 514.946411,294.122406 
	C527.870239,301.755737 541.395508,299.032501 551.220337,288.692169 
z"/>
<path fill="#1430A3" opacity="1.000000" stroke="none" 
	d="
M193.014465,580.262390 
	C194.341583,578.252075 195.664795,576.488098 196.986755,574.357178 
	C196.996521,572.610596 197.007523,571.230957 197.363892,569.398926 
	C198.806778,568.631531 199.904297,568.316406 201.008209,568.409180 
	C200.662369,570.394775 200.485626,572.037720 199.927185,573.538696 
	C197.639862,579.686523 195.459686,585.890503 192.799194,591.877869 
	C190.922882,596.100403 188.336578,600.007507 186.067261,604.055481 
	C186.379959,602.297302 186.692657,600.539062 187.003876,598.390442 
	C187.338776,596.589355 187.675125,595.178711 188.014862,593.398315 
	C189.680450,589.290649 191.342636,585.552795 193.003632,581.408081 
	C193.002441,581.001221 193.010529,580.508667 193.014465,580.262390 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M201.001831,568.001343 
	C199.904297,568.316406 198.806778,568.631531 197.366699,568.984863 
	C198.118134,566.385925 199.212112,563.748840 200.660797,561.056641 
	C201.037186,562.765442 201.058884,564.529236 201.046082,566.903320 
	C201.011597,567.513611 201.001831,568.001343 201.001831,568.001343 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M203.683380,553.156372 
	C203.233276,554.959412 202.475677,556.922729 201.366364,558.950500 
	C201.801727,557.115479 202.588806,555.216064 203.683380,553.156372 
z"/>
<path fill="#2D3C85" opacity="1.000000" stroke="none" 
	d="
M276.098267,810.659546 
	C275.704834,807.943054 276.380829,804.828552 272.702850,803.221863 
	C272.085846,802.952271 271.931824,801.163391 271.910675,800.069946 
	C271.825073,795.650757 271.875153,791.229004 271.875153,786.520630 
	C276.214447,786.520630 280.103363,786.520630 284.519012,786.520630 
	C284.519012,791.763000 284.519012,796.819641 284.329834,802.425659 
	C283.949554,803.158813 283.858795,803.383240 283.482971,803.749573 
	C282.363251,806.213013 281.628967,808.575195 280.894714,810.937317 
	C278.438171,808.156128 279.039307,805.798828 280.615173,802.455688 
	C282.091644,799.323425 281.606445,795.141174 281.419128,791.449707 
	C281.378876,790.656677 278.754181,789.220764 277.605133,789.458557 
	C276.242218,789.740662 274.177551,791.520264 274.185791,792.637756 
	C274.212280,796.225037 272.714264,800.052551 276.215454,803.402039 
	C277.430817,804.564697 276.430817,808.043030 276.098267,810.659546 
z"/>
<path fill="#EDE5D6" opacity="1.000000" stroke="none" 
	d="
M281.077087,811.133789 
	C281.628967,808.575195 282.363251,806.213013 283.520447,804.137085 
	C283.960724,804.941650 283.978058,805.460022 283.830994,806.234131 
	C283.760101,807.608398 283.853668,808.727051 284.226135,810.172424 
	C291.682007,810.298645 298.858978,810.098083 306.146240,809.825928 
	C306.256531,809.754272 306.510803,809.821594 307.017517,809.869141 
	C308.528168,809.826355 309.532104,809.736145 310.653290,809.637207 
	C310.770538,809.628479 310.935669,809.799194 311.199280,810.128906 
	C314.978973,810.291504 318.495087,810.124329 322.260010,809.847290 
	C322.964111,808.993835 323.419373,808.250183 323.821167,807.175354 
	C323.453278,806.542358 323.138794,806.240601 322.573914,805.618103 
	C322.531769,804.873047 322.740112,804.448730 322.948425,804.024414 
	C323.682037,804.285156 324.415619,804.545898 325.421936,804.924866 
	C326.011414,808.274292 327.758942,812.278564 321.044922,812.182190 
	C319.143219,812.262695 318.052094,812.310303 316.549194,812.252075 
	C314.444519,812.216492 312.751587,812.286682 310.658813,812.256042 
	C309.167023,812.210693 308.075134,812.266174 306.554993,812.238708 
	C304.104950,812.221069 302.083099,812.286316 299.675873,812.279236 
	C298.510864,812.254578 297.731262,812.302368 296.523560,812.244995 
	C293.749268,812.213379 291.403107,812.286926 288.710419,812.290405 
	C287.898315,812.270386 287.432739,812.320312 286.564087,812.275146 
	C284.781250,812.147339 283.401459,812.114685 282.021667,812.082031 
	C281.767609,811.831482 281.513550,811.580872 281.077087,811.133789 
z"/>
<path fill="#1F2F70" opacity="1.000000" stroke="none" 
	d="
M203.948975,803.954712 
	C203.094467,802.961609 201.868454,801.463013 202.220245,800.633789 
	C202.955948,798.899414 204.342255,796.445312 205.784958,796.201416 
	C209.471664,795.578369 213.348648,795.815002 217.124771,796.071899 
	C220.072250,796.272400 221.501663,800.352173 219.782684,804.104248 
	C217.447983,801.743225 218.002518,797.356873 212.497009,797.749573 
	C207.889633,798.078186 204.988861,798.914001 203.948975,803.954712 
z"/>
<path fill="#0F2277" opacity="1.000000" stroke="none" 
	d="
M306.983215,812.321655 
	C308.075134,812.266174 309.167023,812.210693 310.929749,812.470459 
	C313.387360,812.643127 315.174164,812.500549 316.960999,812.357910 
	C318.052094,812.310303 319.143219,812.262695 320.639282,812.288147 
	C321.606110,812.754578 322.167938,813.147949 322.863342,813.763611 
	C322.559937,814.002075 322.123016,814.018188 320.926422,814.019653 
	C318.440582,814.014404 316.714386,814.023865 314.988190,814.033325 
	C314.552917,814.034302 314.117645,814.035217 312.884796,814.022949 
	C303.378723,814.031799 294.670227,814.053772 285.961731,814.075806 
	C285.516357,814.065735 285.071014,814.055725 284.290894,814.035767 
	C283.956116,814.025818 283.998871,813.554688 284.335388,813.300232 
	C285.436981,812.820618 286.202057,812.595459 286.967133,812.370300 
	C287.432739,812.320312 287.898315,812.270386 289.012665,812.505249 
	C292.091492,812.643433 294.521545,812.496826 296.951630,812.350159 
	C297.731262,812.302368 298.510864,812.254578 299.964050,812.494995 
	C302.752838,812.629395 304.868011,812.475525 306.983215,812.321655 
z"/>
<path fill="#223A9A" opacity="1.000000" stroke="none" 
	d="
M286.564087,812.275146 
	C286.202057,812.595459 285.436981,812.820618 284.362305,813.065186 
	C283.492706,812.909546 282.932709,812.734436 282.197205,812.320679 
	C283.401459,812.114685 284.781250,812.147339 286.564087,812.275146 
z"/>
<path fill="#1F2F70" opacity="1.000000" stroke="none" 
	d="
M204.525497,808.681030 
	C204.292160,808.183899 204.238495,807.497742 204.254837,806.472778 
	C204.451614,806.919922 204.578384,807.705994 204.525497,808.681030 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M746.192322,349.730255 
	C745.542419,349.991394 745.098083,349.992065 744.320190,349.986877 
	C746.640930,344.777527 751.490601,343.095306 756.691223,343.078857 
	C799.467224,342.943451 842.243958,342.955933 885.019958,343.094269 
	C887.965088,343.103790 890.905823,344.487640 893.459473,345.615936 
	C891.905151,345.999268 890.740051,345.998810 889.574890,345.998779 
	C845.798767,345.997406 802.022400,345.937042 758.247070,346.120239 
	C755.149414,346.133209 752.059265,347.939423 748.965698,348.911804 
	C748.109741,349.097809 747.253845,349.283783 746.192322,349.730255 
z"/>
<path fill="#1430A3" opacity="1.000000" stroke="none" 
	d="
M749.396729,348.936401 
	C752.059265,347.939423 755.149414,346.133209 758.247070,346.120239 
	C802.022400,345.937042 845.798767,345.997406 889.574890,345.998779 
	C890.740051,345.998810 891.905151,345.999268 893.540039,345.993408 
	C895.473694,347.115814 896.937561,348.244293 898.229248,349.577057 
	C897.860840,349.814178 897.664673,349.847046 897.173584,349.832092 
	C893.510498,349.191589 890.143799,348.092529 886.774048,348.083160 
	C844.075134,347.964478 801.375732,347.983551 758.676575,348.041931 
	C755.726562,348.045959 752.777283,348.640869 749.396729,348.936401 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M740.630005,354.041870 
	C740.821533,355.167084 740.675537,356.354736 740.250366,357.770569 
	C740.078247,356.700623 740.185425,355.402466 740.630005,354.041870 
z"/>
<path fill="#EFEBDE" opacity="1.000000" stroke="none" 
	d="
M673.166687,273.038940 
	C660.344849,273.121094 647.748901,273.121094 634.660889,273.121094 
	C634.660889,256.800262 634.660889,241.219910 634.660889,225.046509 
	C630.103394,225.046509 626.022278,225.046509 621.483276,225.117020 
	C618.215210,223.183929 617.489075,219.114410 620.001404,216.543503 
	C629.987488,206.324570 639.993225,196.113083 650.352661,186.279099 
	C652.915955,183.845840 656.846558,183.878983 659.771484,186.772186 
	C669.446106,196.341888 679.239868,205.804764 688.531677,215.735565 
	C690.271790,217.595337 689.906555,221.425034 690.328003,224.679260 
	C690.143188,225.012863 690.040833,224.971191 689.606628,224.987015 
	C688.094727,225.056808 687.016968,225.110779 685.484619,225.093018 
	C681.775330,225.021286 678.520691,225.021286 675.015808,225.021286 
	C675.015808,238.678772 675.015808,251.836517 674.911621,265.394958 
	C674.494446,268.160126 674.181458,270.524597 673.868408,272.889099 
	C673.868469,272.889099 673.392578,272.956757 673.166687,273.038940 
M637.951721,237.504395 
	C637.938232,246.494965 638.156067,255.494324 637.814697,264.472443 
	C637.641479,269.027283 639.351746,270.236053 643.594604,270.078674 
	C651.077209,269.801117 658.587769,269.704895 666.063843,270.040710 
	C670.447327,270.237640 671.969788,268.890320 671.888428,264.462555 
	C671.668335,252.479111 671.805969,240.488800 671.832520,228.501282 
	C671.846924,221.983170 671.875183,221.978882 678.548401,221.943283 
	C681.098450,221.929688 683.648560,221.941071 686.872253,221.941071 
	C675.517090,210.503174 665.003906,199.913330 654.082336,188.912170 
	C643.757935,199.283630 633.166260,209.923615 622.574646,220.563599 
	C622.890930,221.035690 623.207275,221.507767 623.523621,221.979858 
	C627.151550,221.979858 630.820068,221.671982 634.385986,222.142120 
	C635.683594,222.313202 637.567322,224.228195 637.741821,225.539413 
	C638.221069,229.140747 637.936035,232.843811 637.951721,237.504395 
z"/>
<path fill="#1F3281" opacity="1.000000" stroke="none" 
	d="
M675.015808,264.994263 
	C675.015808,251.836517 675.015808,238.678772 675.015808,225.021286 
	C678.520691,225.021286 681.775330,225.021286 685.271851,225.325134 
	C684.676819,226.396637 683.839905,227.164276 683.002930,227.931931 
	C678.550598,228.011948 676.945496,230.496948 677.004883,234.694305 
	C677.101196,241.496811 677.020081,248.301865 676.716125,255.845413 
	C675.950867,259.388092 675.483337,262.191162 675.015808,264.994263 
z"/>
<path fill="#253C91" opacity="1.000000" stroke="none" 
	d="
M674.911621,265.394958 
	C675.483337,262.191162 675.950867,259.388092 676.702148,256.316956 
	C676.988220,260.504425 677.150452,264.969330 676.887756,269.409149 
	C676.815857,270.624969 675.469543,271.765411 674.288208,272.914764 
	C674.181458,270.524597 674.494446,268.160126 674.911621,265.394958 
z"/>
<path fill="#253C91" opacity="1.000000" stroke="none" 
	d="
M683.379089,227.953888 
	C683.839905,227.164276 684.676819,226.396637 685.726562,225.396866 
	C687.016968,225.110779 688.094727,225.056808 689.575928,225.032913 
	C687.904724,226.033936 685.829956,227.004883 683.379089,227.953888 
z"/>
<path fill="#1F3281" opacity="1.000000" stroke="none" 
	d="
M442.989075,758.018066 
	C446.437225,759.312500 447.116180,761.793274 447.055725,765.124573 
	C446.856323,776.119141 447.099243,787.121826 446.890015,798.116028 
	C446.852509,800.087158 445.472595,802.032776 444.712494,803.990173 
	C442.768524,802.521362 439.879211,801.464355 439.031372,799.518127 
	C432.720612,785.031555 426.697968,770.415039 420.863525,755.728577 
	C419.398621,752.040955 417.468536,750.477356 413.465851,750.836731 
	C409.664978,751.178101 405.807434,750.887695 401.518311,750.802124 
	C402.396729,750.151611 403.697540,749.193298 405.073181,749.069885 
	C408.834290,748.732544 412.626892,748.746948 416.701477,748.330688 
	C418.801514,749.569519 420.607147,751.099792 422.679932,753.072937 
	C422.947083,753.515808 422.961090,753.983521 422.961090,753.983521 
	C422.973083,754.422607 422.985077,754.861694 423.144287,755.974609 
	C425.832092,761.460632 428.372681,766.272827 430.913269,771.084961 
	C431.104584,772.875854 431.295898,774.666809 431.783264,777.083008 
	C434.058136,782.134399 436.037018,786.560425 438.047180,791.351624 
	C438.467590,792.619141 438.617523,793.828247 439.297119,794.358215 
	C440.600220,795.374390 442.204041,796.004944 443.683136,796.795410 
	C444.114014,795.500488 444.783813,794.230103 444.919403,792.904907 
	C445.119171,790.952942 444.938202,788.961914 444.937744,786.572754 
	C444.634308,783.767151 444.312561,781.376221 443.990845,778.985352 
	C444.274689,774.992371 444.558563,770.999329 444.868896,766.215088 
	C444.888092,764.955383 444.880890,764.487000 444.871490,763.698669 
	C444.263153,762.893555 443.656982,762.408325 442.947510,761.717529 
	C442.658051,761.256775 442.416443,761.075439 442.119385,760.968018 
	C442.301208,760.042786 442.483032,759.117615 442.989075,758.018066 
z"/>
<path fill="#1430A3" opacity="1.000000" stroke="none" 
	d="
M444.137268,750.969666 
	C443.842010,751.494751 443.546753,752.019897 442.816650,753.028931 
	C442.224091,754.694214 442.066437,755.875549 441.908813,757.056946 
	C441.914490,754.309631 441.920135,751.562256 441.985474,748.425659 
	C452.035797,747.690186 462.033508,746.965454 472.014313,747.150269 
	C477.361450,747.249207 482.723877,748.729004 487.982788,749.988770 
	C489.476959,750.346680 490.631805,752.121155 491.582642,753.619263 
	C490.403076,754.072754 489.583160,754.152954 488.763245,754.233154 
	C488.763245,754.233154 488.874847,754.194275 488.796143,753.884766 
	C486.085114,752.376892 483.510193,750.312256 480.810272,750.133301 
	C470.226288,749.431763 459.604034,749.236450 448.992798,749.092224 
	C447.383087,749.070374 445.756226,750.312317 444.137268,750.969666 
z"/>
<path fill="#1433AA" opacity="1.000000" stroke="none" 
	d="
M443.598785,778.999939 
	C444.312561,781.376221 444.634308,783.767151 444.574341,786.659546 
	C443.423859,789.088684 442.655090,791.016296 442.164032,792.247437 
	C440.480743,791.735779 439.248322,791.361145 438.015900,790.986572 
	C436.037018,786.560425 434.058136,782.134399 432.055939,777.344299 
	C433.638367,778.642822 435.398529,780.186768 436.797455,782.008179 
	C437.786743,783.296204 438.286987,784.959839 439.076385,786.603271 
	C440.662506,783.689087 441.934601,781.351807 443.598785,778.999939 
z"/>
<path fill="#1433AA" opacity="1.000000" stroke="none" 
	d="
M430.908630,770.700928 
	C428.372681,766.272827 425.832092,761.460632 423.168335,756.303284 
	C424.313446,756.942993 426.153656,757.668152 426.741333,758.962158 
	C428.403656,762.622253 429.552734,766.515442 430.908630,770.700928 
z"/>
<path fill="#1433AA" opacity="1.000000" stroke="none" 
	d="
M444.873688,764.018555 
	C444.880890,764.487000 444.888092,764.955383 444.830200,765.771912 
	C441.741699,765.882996 440.204834,764.625793 441.824341,761.186523 
	C442.416443,761.075439 442.658051,761.256775 442.964203,762.039795 
	C443.680725,763.051270 444.277222,763.534912 444.873688,764.018555 
z"/>
<path fill="#1F3281" opacity="1.000000" stroke="none" 
	d="
M442.181274,757.061401 
	C442.066437,755.875549 442.224091,754.694214 442.648987,753.280396 
	C442.959961,754.109924 443.003754,755.171875 443.022705,756.634827 
	C442.816528,757.045898 442.635132,757.055908 442.181274,757.061401 
z"/>
<path fill="#1433AA" opacity="1.000000" stroke="none" 
	d="
M432.752289,771.989380 
	C432.585602,772.100220 432.417419,772.045837 432.249207,771.991455 
	C432.416382,771.935669 432.583588,771.879883 432.752289,771.989380 
z"/>
<path fill="#1433AA" opacity="1.000000" stroke="none" 
	d="
M423.140137,753.904785 
	C422.961090,753.983521 422.947083,753.515808 422.937317,753.282532 
	C423.058075,753.308167 423.188660,753.567078 423.140137,753.904785 
z"/>
<path fill="#223A9A" opacity="1.000000" stroke="none" 
	d="
M609.070435,814.448242 
	C616.668823,814.049255 624.379944,814.033997 632.562744,814.042847 
	C630.606995,814.784119 628.172302,816.121582 625.753723,816.093018 
	C620.226013,816.027771 614.706238,815.294067 609.070435,814.448242 
z"/>
<path fill="#223A9A" opacity="1.000000" stroke="none" 
	d="
M637.468140,815.450317 
	C637.518188,815.421570 637.418091,815.479065 637.468140,815.450317 
z"/>
<path fill="#1B359A" opacity="1.000000" stroke="none" 
	d="
M286.014587,814.416992 
	C294.670227,814.053772 303.378723,814.031799 312.558502,814.033325 
	C311.990234,814.938904 310.963104,816.565674 309.909149,816.583252 
	C302.499420,816.706787 295.083191,816.532959 287.672577,816.327026 
	C287.123413,816.311707 286.601837,815.304443 286.014587,814.416992 
z"/>
<path fill="#1B359A" opacity="1.000000" stroke="none" 
	d="
M315.068359,814.395996 
	C316.714386,814.023865 318.440582,814.014404 320.598633,814.029785 
	C319.433258,817.324951 317.336761,816.425110 315.068359,814.395996 
z"/>
<path fill="#8B92A5" opacity="1.000000" stroke="none" 
	d="
M577.059937,1018.212646 
	C577.136047,1018.043945 577.316406,1018.035522 577.767700,1018.037598 
	C577.747253,1018.156311 577.455750,1018.264648 577.059937,1018.212646 
z"/>
<path fill="#0F2781" opacity="1.000000" stroke="none" 
	d="
M517.971680,810.512756 
	C517.817261,808.404297 516.520691,805.012146 517.672241,803.556030 
	C520.413391,800.089966 519.955200,796.477417 519.976868,792.762451 
	C520.027954,784.001587 520.136963,775.236267 519.894958,766.481873 
	C519.837646,764.409363 518.489380,762.372620 517.421021,760.120361 
	C517.174744,757.825684 517.242432,755.730042 517.567871,753.353943 
	C518.841675,755.343994 519.867920,757.609924 520.869568,759.886658 
	C521.411560,761.118652 522.351135,762.365356 522.362122,763.611938 
	C522.467285,775.562866 522.473206,787.515991 522.324463,799.466248 
	C522.306519,800.909241 521.207336,802.339661 520.605591,803.774353 
	C519.719604,805.886719 518.830261,807.997620 517.971680,810.512756 
z"/>
<path fill="#1F3281" opacity="1.000000" stroke="none" 
	d="
M571.804382,758.942505 
	C563.237122,752.650635 553.481384,750.469788 542.869141,750.858154 
	C535.914001,751.112671 528.941467,750.889221 521.520630,750.801636 
	C521.578125,750.170349 522.100220,749.127136 522.603882,749.135925 
	C534.308899,749.341370 546.090698,748.965027 557.679504,750.275879 
	C562.861816,750.862061 568.946350,752.755737 571.804382,758.942505 
z"/>
<path fill="#1F3281" opacity="1.000000" stroke="none" 
	d="
M575.560059,763.103027 
	C574.876526,762.924744 574.348694,762.382996 573.750488,761.554626 
	C574.358704,761.758484 575.037292,762.249023 575.560059,763.103027 
z"/>
<path fill="#1F3281" opacity="1.000000" stroke="none" 
	d="
M573.788391,760.937134 
	C573.125549,760.811035 572.521851,760.315430 571.805420,759.541870 
	C572.411011,759.698547 573.129272,760.133057 573.788391,760.937134 
z"/>
<path fill="#1F2F70" opacity="1.000000" stroke="none" 
	d="
M688.959595,803.975769 
	C688.212646,802.779236 687.124512,801.139221 687.526978,800.049866 
	C688.106934,798.480103 689.626465,796.361694 690.998352,796.154358 
	C694.582214,795.612915 698.311523,795.835754 701.967896,796.031738 
	C705.249878,796.207642 707.176331,801.308716 705.311096,804.141174 
	C704.554199,805.290527 704.300842,806.771545 703.750916,808.507568 
	C703.128662,807.737976 701.865601,806.352173 702.141418,805.423096 
	C703.347595,801.358826 701.590210,799.020447 698.010254,798.120178 
	C694.278992,797.181946 691.204834,798.359131 689.981750,802.501526 
	C689.847534,802.956116 689.521484,803.354065 688.959595,803.975769 
z"/>
<path fill="#0F2277" opacity="1.000000" stroke="none" 
	d="
M728.958252,812.373657 
	C729.391052,812.330017 729.823792,812.286377 730.574951,812.313965 
	C730.914429,812.728271 730.935486,813.071289 730.967407,813.670471 
	C730.542725,813.968689 730.107300,814.010742 728.875366,814.036011 
	C721.046021,814.028320 714.013062,814.037476 706.980103,814.046631 
	C706.541382,814.035156 706.102661,814.023743 705.335205,813.998291 
	C706.149231,813.584167 707.291870,813.184082 708.675964,812.579834 
	C711.006042,812.321533 713.094666,812.267273 715.953552,812.510864 
	C720.801941,812.663696 724.880127,812.518677 728.958252,812.373657 
z"/>
<path fill="#1F2F70" opacity="1.000000" stroke="none" 
	d="
M590.931702,803.968628 
	C590.119507,802.948303 588.983643,801.496277 589.307068,800.535706 
	C589.868652,798.867920 591.170227,796.406433 592.448242,796.217468 
	C596.336426,795.642639 600.478638,795.439697 604.279419,796.241455 
	C605.942505,796.592224 607.300476,799.613098 608.184875,801.682922 
	C608.448914,802.300903 606.638794,803.805176 605.461060,805.020752 
	C604.630920,802.319824 605.988831,798.527222 601.194397,798.007385 
	C594.611633,797.293335 593.261475,798.026672 590.931702,803.968628 
z"/>
<path fill="#E9E1D0" opacity="1.000000" stroke="none" 
	d="
M571.626099,811.760254 
	C571.196045,810.116394 571.112732,808.468750 571.393555,806.372986 
	C572.170715,805.341187 572.583740,804.757446 572.996704,804.173767 
	C573.976868,803.725830 574.957092,803.277893 576.359070,802.819824 
	C576.780884,802.809692 577.009766,803.009583 577.059570,803.319702 
	C577.179260,803.827026 577.249207,804.024353 577.287354,804.226440 
	C577.255432,804.231201 577.198853,804.200256 576.917053,804.254761 
	C576.414368,804.611267 576.193481,804.913391 575.736206,805.433594 
	C575.333191,807.078125 575.166504,808.504578 574.999817,809.931091 
	C574.753235,810.404175 574.506653,810.877319 574.142212,811.591064 
	C573.340515,811.806641 572.656677,811.781494 571.626099,811.760254 
z"/>
<path fill="#1F2F70" opacity="1.000000" stroke="none" 
	d="
M604.880493,805.235962 
	C605.374268,806.162231 605.575867,807.237854 605.688354,808.644653 
	C605.262329,807.778992 604.925354,806.582153 604.880493,805.235962 
z"/>
<path fill="#1F3281" opacity="1.000000" stroke="none" 
	d="
M488.956970,754.503540 
	C489.583160,754.152954 490.403076,754.072754 491.635193,753.976685 
	C492.861938,754.565430 493.676483,755.170105 494.780273,755.881836 
	C495.044006,756.470215 495.018433,756.951355 494.941498,757.790894 
	C492.976990,757.024109 491.063843,755.899048 488.956970,754.503540 
z"/>
<path fill="#223A9A" opacity="1.000000" stroke="none" 
	d="
M707.083984,814.435181 
	C714.013062,814.037476 721.046021,814.028320 728.547729,814.055176 
	C728.818115,814.538940 728.612244,815.381287 728.422607,815.377625 
	C721.343445,815.241516 714.265747,815.027283 707.083984,814.435181 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M745.050293,809.315552 
	C745.654602,809.438416 746.295532,809.887268 746.993774,810.651001 
	C746.396362,810.524414 745.741638,810.082947 745.050293,809.315552 
z"/>
<path fill="#1739BF" opacity="1.000000" stroke="none" 
	d="
M176.692291,625.026611 
	C175.993179,628.222778 175.089523,631.483521 173.930817,634.651001 
	C171.011169,642.632385 168.057129,650.603760 164.910675,658.497009 
	C164.371368,659.849854 162.814850,660.797180 161.371490,661.978638 
	C165.339127,650.277222 169.663528,638.528931 173.995483,626.391602 
	C174.792557,625.684570 175.582077,625.366699 176.692291,625.026611 
z"/>
<path fill="#1739BF" opacity="1.000000" stroke="none" 
	d="
M160.657043,663.033875 
	C158.247818,666.331665 155.493195,669.663330 152.370132,673.000366 
	C154.771667,669.693115 157.541656,666.380432 160.657043,663.033875 
z"/>
<path fill="#1433AA" opacity="1.000000" stroke="none" 
	d="
M392.144104,783.262573 
	C391.183075,785.946350 390.222076,788.630066 388.865356,791.765320 
	C387.981628,792.821411 387.493530,793.426025 386.695740,793.888062 
	C384.922394,793.173096 383.458740,792.600830 381.981537,791.782104 
	C381.967957,791.535767 381.931458,791.043640 381.931458,791.043640 
	C381.293915,787.637085 380.656342,784.230469 380.041565,780.390991 
	C381.137512,781.593140 382.210632,783.228149 383.643860,784.926697 
	C388.342651,786.443481 388.071686,783.515198 388.005920,780.394531 
	C389.777954,778.034424 391.548859,776.060730 393.674744,774.434448 
	C393.401154,777.608826 392.772614,780.435669 392.144104,783.262573 
z"/>
<path fill="#1433AA" opacity="1.000000" stroke="none" 
	d="
M380.296265,776.747192 
	C375.964691,772.027588 376.823090,766.276794 377.728912,760.282959 
	C378.879272,765.496765 379.726379,770.993958 380.296265,776.747192 
z"/>
<path fill="#1433AA" opacity="1.000000" stroke="none" 
	d="
M378.359131,757.989685 
	C378.058929,757.575989 378.047394,757.110901 378.066040,756.297546 
	C378.588501,756.287903 379.080811,756.626587 379.573120,756.965271 
	C379.264679,757.289612 378.956207,757.614014 378.359131,757.989685 
z"/>
<path fill="#0F2277" opacity="1.000000" stroke="none" 
	d="
M881.687805,812.219788 
	C882.595276,812.680481 883.157227,813.088989 883.856140,813.720947 
	C872.642883,813.957642 861.292725,813.970886 849.474731,813.990845 
	C849.006897,813.997559 849.005127,813.545227 849.413269,813.302856 
	C851.538879,812.825134 853.256409,812.589783 854.973877,812.354492 
	C856.711731,812.306458 858.449524,812.258423 860.927002,812.504395 
	C864.431885,812.649658 867.197083,812.500793 869.962280,812.351929 
	C870.734558,812.303162 871.506836,812.254333 872.949463,812.493530 
	C875.734070,812.636841 877.848389,812.492188 879.962646,812.347534 
	C880.422546,812.287537 880.882446,812.227539 881.687805,812.219788 
z"/>
<path fill="#2D3C85" opacity="1.000000" stroke="none" 
	d="
M392.496948,783.169434 
	C392.772614,780.435669 393.401154,777.608826 394.017914,774.377563 
	C394.006134,773.973145 394.032043,773.522644 394.040649,773.297974 
	C395.374573,769.041504 396.923401,765.064941 397.937927,760.956482 
	C398.508270,758.646729 398.196198,756.119080 398.558044,753.399780 
	C399.594086,755.127197 400.380585,757.135010 401.098358,759.167053 
	C401.342651,759.858704 401.739746,760.738220 401.514618,761.317078 
	C398.685272,768.593018 395.754547,775.829590 392.496948,783.169434 
z"/>
<path fill="#173BC4" opacity="1.000000" stroke="none" 
	d="
M394.267242,760.217407 
	C394.452393,758.388123 394.885895,756.740356 395.646790,755.045532 
	C395.974152,757.285706 395.974152,759.573059 395.974152,762.710510 
	C395.227570,761.527344 394.871582,760.963135 394.267242,760.217407 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M472.069092,224.282318 
	C473.024841,224.721573 473.990967,225.510605 474.999939,226.635010 
	C474.054993,226.190948 473.067230,225.411530 472.069092,224.282318 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M481.099304,232.225769 
	C481.327423,232.093231 481.593353,232.279434 481.886078,232.792938 
	C481.662903,232.911102 481.404327,232.719177 481.099304,232.225769 
z"/>
<path fill="#0F2781" opacity="1.000000" stroke="none" 
	d="
M201.008209,568.409180 
	C201.001831,568.001343 201.011597,567.513611 201.017181,567.270020 
	C201.874786,565.957214 203.063858,565.015747 203.525299,563.798523 
	C207.108673,554.345459 210.596893,544.855591 214.027695,535.345764 
	C219.315918,520.687439 224.490036,505.987823 229.816498,491.343506 
	C231.082306,487.863403 232.759964,484.533081 234.873581,481.068787 
	C235.677078,481.008698 235.855179,481.013763 236.033295,481.018799 
	C236.024689,481.762268 236.016083,482.505737 235.658493,483.678528 
	C234.546539,485.750214 233.783569,487.392609 233.020599,489.034973 
	C231.300293,494.364746 229.714676,499.742645 227.829437,505.013428 
	C224.690445,513.789551 221.411484,522.516785 218.095627,531.228149 
	C214.264175,541.294006 210.181412,551.266418 206.478851,561.378601 
	C202.478851,572.303162 198.925415,583.390991 194.932526,594.318298 
	C190.619492,606.121704 186.023407,617.821716 181.284149,629.795654 
	C180.841171,630.017273 180.664810,630.009949 180.223450,629.990479 
	C181.848526,623.554993 183.744186,617.133362 185.619858,610.705811 
	C185.802612,610.079529 185.862183,609.417297 185.970642,608.371460 
	C185.990891,606.926331 186.019440,605.881592 186.057617,604.446167 
	C188.336578,600.007507 190.922882,596.100403 192.799194,591.877869 
	C195.459686,585.890503 197.639862,579.686523 199.927185,573.538696 
	C200.485626,572.037720 200.662369,570.394775 201.008209,568.409180 
z"/>
<path fill="#1433AA" opacity="1.000000" stroke="none" 
	d="
M817.048706,815.444458 
	C819.342529,815.051697 821.740906,815.028564 824.588806,815.025696 
	C822.721008,818.495544 819.957581,817.364014 817.048706,815.444458 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M791.783447,808.110840 
	C791.903625,808.329041 791.715820,808.589966 791.219360,808.892700 
	C791.100647,808.674988 791.288940,808.414734 791.783447,808.110840 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M794.749756,807.999756 
	C794.725281,808.113037 794.450562,808.226074 794.087524,808.170776 
	C794.165833,808.001526 794.332642,808.000549 794.749756,807.999756 
z"/>
<path fill="#1F3281" opacity="1.000000" stroke="none" 
	d="
M840.044067,806.028809 
	C839.821533,806.007507 839.598999,805.986145 839.043518,805.924194 
	C840.237976,803.117798 841.765320,800.352051 843.839844,797.118042 
	C844.524109,795.362366 844.661194,794.075012 844.798340,792.787659 
	C844.798340,792.787659 844.711121,792.861755 845.021179,792.862671 
	C846.288452,792.489502 847.245667,792.115417 849.667725,791.168762 
	C849.197937,794.486206 848.852478,796.790833 848.550781,799.101257 
	C848.338501,800.726929 848.185486,802.360474 848.005981,803.990479 
	C847.637085,804.783997 847.268188,805.577515 846.652222,806.856934 
	C846.521790,807.889038 846.638550,808.435364 846.755310,808.981689 
	C846.790527,809.445007 846.825745,809.908325 846.733276,810.950256 
	C846.088867,811.656616 845.572144,811.784363 845.055481,811.912109 
	C845.055481,811.912109 845.049438,811.448425 845.038391,810.796997 
	C844.914734,808.201172 844.802063,806.256775 844.662537,803.848083 
	C842.610413,804.817078 841.327209,805.422974 840.044067,806.028809 
z"/>
<path fill="#1433AA" opacity="1.000000" stroke="none" 
	d="
M840.030640,806.394531 
	C841.327209,805.422974 842.610413,804.817078 844.662537,803.848083 
	C844.802063,806.256775 844.914734,808.201172 845.026489,810.565491 
	C843.394287,810.558350 841.762939,810.131348 840.058594,809.329285 
	C839.996094,808.222900 840.006653,807.491577 840.030640,806.394531 
z"/>
<path fill="#253C91" opacity="1.000000" stroke="none" 
	d="
M854.549561,812.249634 
	C853.256409,812.589783 851.538879,812.825134 849.434570,813.077637 
	C848.862732,813.080261 848.677734,813.065735 848.232422,812.810852 
	C847.971985,812.570557 848.001709,812.120178 848.001709,812.120178 
	C850.042908,812.128418 852.084106,812.136658 854.549561,812.249634 
z"/>
<path fill="#1433AA" opacity="1.000000" stroke="none" 
	d="
M847.876587,811.890137 
	C848.001709,812.120178 847.971985,812.570557 847.954468,812.795898 
	C847.091187,812.841370 846.245361,812.661499 845.227539,812.196899 
	C845.572144,811.784363 846.088867,811.656616 846.755371,811.298950 
	C847.187256,811.266052 847.469360,811.463074 847.876587,811.890137 
z"/>
<path fill="#1739BF" opacity="1.000000" stroke="none" 
	d="
M142.780731,676.729858 
	C141.226715,678.309021 139.454224,679.622314 137.340546,680.967773 
	C137.046722,677.271973 139.082428,675.982483 142.780731,676.729858 
z"/>
<path fill="#1839BC" opacity="1.000000" stroke="none" 
	d="
M487.184784,236.273026 
	C489.041687,236.010696 491.084381,236.013794 493.564575,236.029510 
	C492.012268,239.156693 489.798340,239.276123 487.184784,236.273026 
z"/>
<path fill="#8089A6" opacity="1.000000" stroke="none" 
	d="
M555.225220,395.183197 
	C555.909363,395.314331 556.486084,395.815002 557.145874,396.626465 
	C557.211975,397.370239 557.195007,397.803192 557.127502,398.557556 
	C556.423889,397.770172 555.770813,396.661438 555.225220,395.183197 
z"/>
<path fill="#B1B8CB" opacity="1.000000" stroke="none" 
	d="
M558.963745,657.668701 
	C558.772888,657.990173 558.553040,657.992493 558.002075,657.999512 
	C557.621948,657.551025 557.572998,657.098022 557.635010,656.318481 
	C558.142273,656.444519 558.538513,656.897034 558.963745,657.668701 
z"/>
<path fill="#9195A4" opacity="1.000000" stroke="none" 
	d="
M770.538818,669.067261 
	C770.294373,668.512939 770.398132,667.949524 770.724060,667.190857 
	C771.794128,667.168579 772.641968,667.341431 774.958008,667.813599 
	C772.680115,668.509949 771.783569,668.784058 770.538818,669.067261 
z"/>
<path fill="#9195A4" opacity="1.000000" stroke="none" 
	d="
M771.814575,660.027771 
	C771.539978,659.244934 771.532104,658.350342 771.786377,657.232056 
	C772.059509,657.977661 772.070374,658.946777 771.814575,660.027771 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M536.208252,686.511963 
	C536.063110,686.204834 536.190125,685.731750 536.291748,685.509888 
	C538.409058,684.909546 540.424744,684.531189 542.749146,684.244202 
	C542.355164,685.391479 541.664185,686.455322 540.947571,687.501587 
	C538.955750,690.409912 537.622742,688.812988 536.208252,686.511963 
z"/>
<path fill="#12298B" opacity="1.000000" stroke="none" 
	d="
M538.842163,376.751892 
	C538.550354,377.005768 538.105286,377.007111 537.326294,377.016449 
	C535.707947,376.584076 534.423462,376.143677 533.082825,375.343384 
	C534.146545,375.226959 535.266479,375.470398 536.715942,375.833313 
	C537.593384,376.134979 538.141113,376.317200 538.842163,376.751892 
z"/>
<path fill="#0F2781" opacity="1.000000" stroke="none" 
	d="
M744.629883,689.022461 
	C745.005798,693.978882 745.009521,699.038513 745.007812,704.569824 
	C744.014771,702.652649 742.286377,700.281006 742.230103,697.870422 
	C742.162659,694.972778 743.518250,692.042053 744.629883,689.022461 
z"/>
<path fill="#0F2781" opacity="1.000000" stroke="none" 
	d="
M747.741699,684.033447 
	C747.566650,684.603455 747.053650,685.205444 746.269043,685.939148 
	C746.466248,685.402344 746.934937,684.733948 747.741699,684.033447 
z"/>
<path fill="#0F2781" opacity="1.000000" stroke="none" 
	d="
M761.232910,505.879578 
	C760.966980,505.207001 760.829956,504.305664 761.210815,503.931854 
	C766.879150,498.366730 766.027771,491.276154 766.014526,484.320496 
	C765.965088,458.385071 766.105835,432.448578 765.900940,406.514679 
	C765.858276,401.116333 765.554382,395.387726 763.706116,390.432251 
	C761.962524,385.757660 758.025330,381.901245 755.018799,377.335083 
	C763.430115,379.911438 765.988342,387.297668 767.880249,394.808777 
	C768.915100,398.917297 768.947693,403.346893 768.960388,407.633118 
	C769.046570,436.762970 768.943237,465.893494 769.063660,495.023071 
	C769.086792,500.610168 766.986267,504.257355 761.232910,505.879578 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M755.718750,685.187317 
	C755.815247,686.264832 755.560181,687.285522 755.305115,688.306152 
	C754.553223,687.802734 753.801331,687.299316 753.029419,686.483276 
	C753.023682,685.994751 753.037842,685.818848 753.144165,685.394775 
	C753.946533,685.141235 754.656860,685.135803 755.718750,685.187317 
z"/>
<path fill="#ABAAA8" opacity="1.000000" stroke="none" 
	d="
M749.481750,686.739807 
	C749.590210,687.015015 749.430115,687.349609 748.970703,687.696411 
	C748.852051,687.405518 749.032654,687.102356 749.481750,686.739807 
z"/>
<path fill="#1430A3" opacity="1.000000" stroke="none" 
	d="
M531.308105,350.950317 
	C531.740784,350.284821 532.510010,349.641296 533.627563,348.991730 
	C533.721558,349.482300 533.467285,349.978912 533.102905,350.732788 
	C532.543396,350.984161 532.093994,350.978241 531.308105,350.950317 
z"/>
<path fill="#C1C1C1" opacity="1.000000" stroke="none" 
	d="
M269.832733,398.364380 
	C270.807526,398.859619 271.643890,399.727234 272.742554,400.796631 
	C272.084442,401.312836 271.164001,401.627289 269.849854,401.919739 
	C269.535522,400.844055 269.614929,399.790375 269.832733,398.364380 
z"/>
<path fill="#193CC6" opacity="1.000000" stroke="none" 
	d="
M322.005798,549.001343 
	C321.672089,551.045776 321.029785,553.096191 321.068878,555.133484 
	C321.150299,559.379578 319.287567,561.066528 315.086212,561.039246 
	C299.366211,560.937256 283.645172,560.995728 267.454071,560.986694 
	C267.564728,558.373779 268.636902,557.378479 271.809723,557.601074 
	C277.545502,558.003296 283.353851,557.333191 289.133484,557.165039 
	C291.454651,557.097473 293.782288,557.109436 296.102875,557.195679 
	C301.301300,557.388916 306.517670,558.034241 311.687164,557.757202 
	C315.406891,557.557861 316.750946,554.207092 315.853729,551.094482 
	C314.318481,545.768433 311.922852,540.696350 310.052185,535.459229 
	C308.285370,530.513062 305.716766,525.569092 305.327728,520.484436 
	C304.799866,513.584534 300.054291,508.701599 298.477936,502.403748 
	C297.872223,499.983795 295.306152,498.117065 294.083710,495.764282 
	C293.402039,494.452271 293.730774,492.644043 293.447845,491.087830 
	C293.174530,489.584381 292.700562,488.117371 292.155548,486.314087 
	C292.360535,484.941437 292.724731,483.889069 293.618408,481.306702 
	C299.081909,495.133545 304.032898,507.663391 308.993256,520.994751 
	C311.005859,526.196838 313.009155,530.597290 315.012451,534.997803 
	C315.320862,536.417664 315.629272,537.837524 316.010620,539.984985 
	C317.715881,542.813110 319.348175,544.913696 320.980469,547.014282 
	C320.980469,547.014282 321.457397,547.046448 321.711365,547.376587 
	C321.978821,548.138245 321.992310,548.569824 322.005798,549.001343 
z"/>
<path fill="#0F2781" opacity="1.000000" stroke="none" 
	d="
M248.971436,442.965576 
	C251.466507,435.670807 254.179718,428.441742 256.401123,421.064575 
	C259.141418,411.964172 262.096161,402.853119 263.778412,393.533691 
	C265.068115,386.388977 260.729462,380.915619 255.040253,376.366547 
	C256.100281,375.999695 257.214050,375.966980 258.742371,375.870483 
	C259.156952,375.806702 259.103027,375.866943 259.217041,376.136963 
	C259.947144,376.553192 260.563202,376.699463 261.179291,376.845734 
	C261.179291,376.845734 261.198578,376.820007 261.208801,376.806610 
	C261.388031,377.576813 261.286438,378.643951 261.766205,379.101868 
	C270.002167,386.961823 267.385864,395.959442 264.490417,404.927307 
	C261.741089,413.442657 258.957733,421.952820 255.909103,430.363678 
	C253.760437,436.291687 251.143234,442.049805 248.373520,447.583923 
	C248.331680,445.844574 248.651550,444.405060 248.971436,442.965576 
z"/>
<path fill="#0F2277" opacity="1.000000" stroke="none" 
	d="
M236.387604,480.947327 
	C235.855179,481.013763 235.677078,481.008698 235.231354,480.997314 
	C235.547989,478.556519 236.132248,476.122040 237.220963,473.289917 
	C238.812347,470.257019 239.899277,467.621796 240.986191,464.986572 
	C242.393280,462.002594 243.800369,459.018616 245.563263,455.962463 
	C242.860031,464.218811 239.800964,472.547302 236.387604,480.947327 
z"/>
<path fill="#12298B" opacity="1.000000" stroke="none" 
	d="
M248.621201,443.035004 
	C248.651550,444.405060 248.331680,445.844574 248.012070,447.658447 
	C247.522614,448.913940 247.032913,449.795074 246.295074,450.846619 
	C246.788284,448.379486 247.529633,445.741974 248.621201,443.035004 
z"/>
<path fill="#0F2277" opacity="1.000000" stroke="none" 
	d="
M233.362732,488.969971 
	C233.783569,487.392609 234.546539,485.750214 235.654144,484.050262 
	C235.234146,485.630127 234.469513,487.267548 233.362732,488.969971 
z"/>
<path fill="#D2D2D3" opacity="1.000000" stroke="none" 
	d="
M272.225769,392.833923 
	C271.815704,393.004791 271.636871,392.999115 271.189880,393.008789 
	C270.878876,392.570404 270.836029,392.116730 270.865112,391.327271 
	C271.443695,391.546783 271.950348,392.102112 272.225769,392.833923 
z"/>
<path fill="#253C91" opacity="1.000000" stroke="none" 
	d="
M264.442169,565.887817 
	C263.902985,562.269714 266.553833,563.042908 268.570343,563.039062 
	C286.389954,563.005493 304.209625,563.018372 322.502869,563.384644 
	C322.954376,564.472717 322.932373,565.193604 322.910339,565.914551 
	C303.577026,565.925171 284.243713,565.935791 264.442169,565.887817 
z"/>
<path fill="#0F2781" opacity="1.000000" stroke="none" 
	d="
M322.039215,545.985718 
	C321.083954,542.896729 320.128326,539.807861 319.173615,536.718689 
	C318.455444,534.394958 317.738495,532.070801 317.051086,529.342529 
	C318.914825,531.051819 321.309601,532.905396 322.439667,535.344849 
	C323.843597,538.375549 324.201721,541.890686 324.639221,545.598267 
	C323.524323,545.995239 322.781769,545.990479 322.039215,545.985718 
z"/>
<path fill="#0F2781" opacity="1.000000" stroke="none" 
	d="
M323.301697,565.936768 
	C322.932373,565.193604 322.954376,564.472717 322.969055,563.390747 
	C323.136078,563.022156 323.310394,563.014587 324.156738,562.989990 
	C325.551819,561.935364 326.734222,560.966125 326.901154,559.845642 
	C327.235840,557.599243 327.015472,555.270203 327.024200,552.975281 
	C328.105164,555.750061 330.030243,558.518494 330.050964,561.301147 
	C330.076233,564.692993 326.787720,565.680359 323.301697,565.936768 
z"/>
<path fill="#12298B" opacity="1.000000" stroke="none" 
	d="
M327.016144,552.985962 
	C327.015472,555.270203 327.235840,557.599243 326.901154,559.845642 
	C326.734222,560.966125 325.551819,561.935364 324.418488,562.987061 
	C324.079681,558.419678 326.957947,553.543091 322.293579,549.215332 
	C321.992310,548.569824 321.978821,548.138245 321.950256,547.384338 
	C321.954987,546.882935 321.974854,546.703857 322.016968,546.255249 
	C322.781769,545.990479 323.524323,545.995239 324.639160,546.001709 
	C325.324249,546.788452 325.637054,547.573486 325.733459,548.859131 
	C326.019043,550.568604 326.521057,551.777466 327.023041,552.986328 
	C327.023041,552.986328 327.008087,552.996704 327.016144,552.985962 
z"/>
<path fill="#0F2781" opacity="1.000000" stroke="none" 
	d="
M327.022858,552.620911 
	C326.521057,551.777466 326.019043,550.568604 325.756165,549.177124 
	C326.337769,550.081482 326.680206,551.168518 327.022858,552.620911 
z"/>
<path fill="#B0B1B4" opacity="1.000000" stroke="none" 
	d="
M138.960709,697.394409 
	C139.220947,698.192078 139.379089,699.380859 139.285461,700.782837 
	C138.975342,699.925781 138.916977,698.855713 138.960709,697.394409 
z"/>
<path fill="#1A38B2" opacity="1.000000" stroke="none" 
	d="
M315.263489,534.779358 
	C313.009155,530.597290 311.005859,526.196838 309.005554,521.396179 
	C314.436859,523.954285 314.020111,529.715881 315.263489,534.779358 
z"/>
<path fill="#1A38B2" opacity="1.000000" stroke="none" 
	d="
M320.952057,546.660400 
	C319.348175,544.913696 317.715881,542.813110 316.054626,540.348999 
	C317.658325,542.092529 319.290985,544.199463 320.952057,546.660400 
z"/>
<path fill="#1F2F70" opacity="1.000000" stroke="none" 
	d="
M253.223053,605.496094 
	C253.766037,605.234070 254.508469,605.171448 255.613586,605.206299 
	C255.125031,605.434387 254.273773,605.564941 253.223053,605.496094 
z"/>
<path fill="#1F2F70" opacity="1.000000" stroke="none" 
	d="
M337.100830,605.436218 
	C337.170532,605.245361 337.334473,605.191528 337.745148,605.215942 
	C337.991882,605.294189 337.653992,605.634460 337.653992,605.634460 
	C337.653992,605.634460 337.195129,605.573303 337.100830,605.436218 
z"/>
<path fill="#1D2A5E" opacity="1.000000" stroke="none" 
	d="
M261.048126,376.547302 
	C260.563202,376.699463 259.947144,376.553192 259.196594,376.102112 
	C259.680420,375.947815 260.298676,376.098358 261.048126,376.547302 
z"/>
<path fill="#EFEBDE" opacity="1.000000" stroke="none" 
	d="
M576.007935,183.986115 
	C576.007935,183.986115 575.723145,183.805313 575.369873,183.523758 
	C573.761414,182.717575 572.506226,182.192947 571.251099,181.668304 
	C571.251099,181.668304 571.222046,181.668488 571.236572,181.658600 
	C557.363220,172.691864 541.745422,169.756088 525.747314,170.688583 
	C501.541229,172.099518 480.383240,181.574692 462.534424,198.174942 
	C459.453125,201.040726 454.343506,200.974243 451.340332,198.070404 
	C447.277863,194.142273 443.314911,190.109634 439.361450,186.070755 
	C435.951263,182.586868 435.984131,178.049881 439.518036,174.774796 
	C462.344910,153.619461 489.301666,142.022446 520.375793,140.242172 
	C551.001648,138.487579 579.548157,144.931976 604.971191,162.718109 
	C610.924927,166.883408 616.398621,171.735046 622.448242,176.279633 
	C622.803345,176.288345 622.673218,176.582825 622.642639,176.741364 
	C622.780396,188.096573 613.014709,192.432037 606.736450,198.956314 
	C605.285767,200.463867 600.510193,199.035751 597.342896,198.497879 
	C596.075378,198.282608 595.019287,196.822632 593.507568,195.893951 
	C593.148254,195.858704 593.099365,195.913254 593.039062,195.665009 
	C592.680298,195.217392 592.381775,195.018036 592.083313,194.818665 
	C592.083313,194.818665 592.031982,194.882889 591.992981,194.641907 
	C591.636353,194.268555 591.318665,194.136169 590.834473,193.876221 
	C590.667969,193.748642 590.255371,193.672623 590.255371,193.672623 
	C590.255371,193.672623 590.190247,193.784073 590.165405,193.505554 
	C589.810181,193.064697 589.479797,192.902344 589.149353,192.739990 
	C589.149353,192.740005 589.121582,192.870956 589.095520,192.591461 
	C588.744202,192.143936 588.419006,191.975922 588.093750,191.807922 
	C588.093750,191.807922 588.082947,191.902466 587.989136,191.609650 
	C587.330872,191.092346 586.766296,190.867874 586.201782,190.643402 
	C586.201782,190.643402 586.175720,190.771271 586.087891,190.429565 
	C583.059631,188.315948 580.119202,186.544022 577.178711,184.772095 
	C577.178711,184.772095 577.103943,184.876862 577.059509,184.598419 
	C576.679382,184.208679 576.343628,184.097397 576.007935,183.986115 
M528.637817,167.359787 
	C538.333130,168.652298 548.234558,169.106018 557.677612,171.425400 
	C573.577698,175.330688 587.513428,183.425476 599.480164,194.692108 
	C601.884888,196.956131 603.714844,197.151886 606.081604,194.554657 
	C609.543213,190.755966 613.248169,187.163467 617.025696,183.674011 
	C619.336975,181.539062 619.072876,179.694763 616.976746,177.841049 
	C614.239563,175.420486 611.430115,173.078323 608.593872,170.773682 
	C592.099121,157.370834 573.164429,149.050659 552.428406,145.232803 
	C535.112610,142.044662 517.736816,142.644043 500.478241,146.817795 
	C478.469421,152.140305 459.423981,162.755768 442.700562,177.822601 
	C440.669403,179.652542 440.557190,181.297562 442.574432,183.267395 
	C446.502228,187.102814 450.385193,190.990555 454.139893,194.994308 
	C456.238190,197.231766 457.893524,197.016922 459.995056,194.989105 
	C462.502441,192.569687 465.171906,190.284698 467.963318,188.198608 
	C485.677704,174.960022 505.683411,168.224243 528.637817,167.359787 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M221.212753,806.804321 
	C224.507309,804.951782 224.383041,802.494019 223.067459,799.789673 
	C221.710983,797.001282 221.827332,792.872253 217.015091,793.148010 
	C214.527054,793.290527 211.999451,792.985229 209.537735,793.281372 
	C206.663681,793.627014 202.871704,791.370605 201.182510,795.530090 
	C199.531387,799.595764 196.971283,803.847961 202.375381,808.029175 
	C196.907883,810.887207 192.149887,809.878418 186.562485,809.061584 
	C197.230804,796.965271 198.911682,781.844666 204.797394,768.563538 
	C208.130081,761.043335 207.869766,760.927979 204.932983,753.347717 
	C209.954559,753.347717 214.643204,753.204773 219.306030,753.487488 
	C220.088318,753.534912 220.956039,755.339539 221.449570,756.477356 
	C225.015976,764.699951 228.496292,772.959961 232.248749,781.470581 
	C236.138596,790.943909 238.093414,800.992798 246.748627,808.747925 
	C244.496597,809.250671 243.184845,809.776001 241.864838,809.797668 
	C236.224014,809.890015 230.572708,809.994080 224.944504,809.710754 
	C223.653137,809.645691 222.438232,808.061829 221.212753,806.804321 
M210.109268,771.809509 
	C208.215729,776.818054 206.322174,781.826599 204.280243,787.227661 
	C209.470001,787.227661 213.534042,787.227661 218.084930,787.227661 
	C216.850082,783.807129 216.091660,781.091675 214.901199,778.581116 
	C213.705338,776.059082 212.079208,773.741089 210.109268,771.809509 
z"/>
<path fill="#FE9702" opacity="1.000000" stroke="none" 
	d="
M301.042908,753.384521 
	C301.466766,753.338501 301.890594,753.292419 302.642822,753.718079 
	C303.101593,755.562134 303.232025,756.934387 303.362427,758.306702 
	C303.362427,758.306702 303.213989,758.266479 302.955750,758.324829 
	C302.744995,760.584106 302.792480,762.785034 302.839966,764.985962 
	C302.893951,765.741516 302.947937,766.497131 302.774658,767.715820 
	C302.652069,768.452576 302.756744,768.726135 302.861420,768.999695 
	C302.906525,779.350464 302.951599,789.701172 302.594788,800.575256 
	C300.396027,802.805969 300.917419,803.685608 303.205536,803.934326 
	C303.435608,804.142639 303.627289,804.381653 303.859222,805.263672 
	C303.682526,806.450684 303.438446,807.521118 303.170227,807.527161 
	C297.767822,807.649597 292.361725,807.695557 286.959900,807.584717 
	C286.538971,807.576111 286.144043,806.298401 285.799500,805.314941 
	C285.879944,804.818970 285.897949,804.618408 285.915924,804.417908 
	C285.915924,804.417908 286.141357,804.589722 286.642456,804.616455 
	C287.509216,803.179871 288.239014,801.701599 288.179565,800.255798 
	C287.873047,792.802246 287.364136,785.356995 286.925873,777.908875 
	C286.925873,777.908875 286.990997,777.997131 287.322235,777.933228 
	C287.507751,772.257751 287.362061,766.646301 287.216339,761.034790 
	C287.110504,760.588989 287.004669,760.143127 287.164032,759.170044 
	C287.304626,756.889282 287.179993,755.135925 287.055359,753.382507 
	C287.478668,753.353271 287.901978,753.324036 288.780884,753.714966 
	C293.171967,753.884888 297.107422,753.634705 301.042908,753.384521 
z"/>
<path fill="#FE9702" opacity="1.000000" stroke="none" 
	d="
M269.114685,786.030762 
	C269.154022,786.470215 269.193390,786.909607 268.848511,787.889893 
	C268.200958,792.455933 268.331299,796.559021 267.555450,800.482910 
	C266.879761,803.900574 267.917664,805.394348 271.082672,805.923462 
	C271.082672,805.923462 271.001495,805.952148 270.927490,806.331299 
	C265.348236,806.983826 259.843903,807.344299 254.336395,807.401855 
	C253.758102,807.407898 253.161148,805.626709 252.572845,804.674744 
	C252.572830,804.674744 252.571274,804.743835 252.985779,804.689880 
	C253.604370,802.045837 253.932159,799.458557 253.991211,796.865173 
	C254.254105,785.323242 254.412155,773.778992 254.665024,762.236816 
	C254.801971,755.985291 255.845230,755.189026 262.358643,754.914307 
	C264.575073,754.820801 266.756195,753.889587 268.953430,753.341187 
	C269.696533,753.308655 270.439636,753.276123 271.469788,753.649780 
	C266.467773,757.321777 268.002716,762.987915 267.527069,767.599670 
	C266.489471,777.659485 266.865173,777.697144 275.077271,780.890503 
	C275.155243,780.920837 275.140778,781.188843 275.229431,781.674500 
	C273.681213,781.883850 272.055511,781.875427 270.594116,782.361206 
	C268.912598,782.920288 266.808655,783.694092 269.114685,786.030762 
z"/>
<path fill="#EFE4CD" opacity="1.000000" stroke="none" 
	d="
M337.470459,756.847534 
	C340.463562,764.458374 343.231140,771.782593 346.674194,778.863281 
	C347.429443,777.974243 347.509247,777.328613 347.589020,776.683044 
	C347.589020,776.683044 347.613525,776.627380 347.919495,776.717773 
	C348.450775,776.836670 348.676117,776.865051 348.969055,776.954956 
	C349.036713,777.016541 349.175629,777.135620 349.144745,777.533081 
	C348.979034,778.991638 348.844208,780.052795 348.691040,781.212036 
	C348.672729,781.310120 348.844696,781.411316 348.547150,781.631226 
	C347.716492,783.178162 347.183411,784.505310 346.315704,785.801514 
	C345.275970,784.737000 344.333344,783.792786 343.905304,782.654907 
	C341.624725,776.593201 339.506866,770.470459 337.269897,764.391968 
	C332.005096,750.085999 335.703888,753.789062 321.211304,753.150635 
	C321.045258,753.143311 320.864990,753.103394 320.714691,753.151489 
	C319.671936,753.485168 318.635376,753.838196 317.596863,754.184937 
	C318.023651,755.141235 318.529083,756.071350 318.859436,757.059875 
	C319.395721,758.664673 320.398804,760.384705 320.195404,761.922607 
	C318.540253,774.435608 316.793823,786.941711 314.704376,799.387573 
	C314.311646,801.727112 312.265259,803.789001 310.945251,805.939087 
	C310.908569,805.899231 310.841614,805.813965 310.841614,805.813965 
	C310.849915,805.304443 310.858185,804.794861 310.939697,803.546448 
	C311.302185,800.220276 311.591461,797.633057 311.880737,795.045776 
	C311.880737,795.045776 312.040527,794.608704 312.366943,794.151489 
	C312.970215,792.156250 313.247040,790.618347 313.523895,789.080383 
	C314.939880,780.276367 316.386780,771.477051 317.731049,762.662109 
	C317.891815,761.607727 317.854889,759.770264 317.296997,759.482117 
	C313.801819,757.677551 315.067261,754.757568 315.287842,751.664062 
	C321.012390,751.155762 326.666412,751.207458 332.301880,750.909485 
	C336.616302,750.681458 336.054382,754.291016 337.470459,756.847534 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M269.376434,785.798218 
	C266.808655,783.694092 268.912598,782.920288 270.594116,782.361206 
	C272.055511,781.875427 273.681213,781.883850 275.229431,781.674500 
	C275.140778,781.188843 275.155243,780.920837 275.077271,780.890503 
	C266.865173,777.697144 266.489471,777.659485 267.527069,767.599670 
	C268.002716,762.987915 266.467773,757.321777 271.831451,753.742004 
	C271.777161,754.663696 272.063080,756.191101 271.439056,757.078613 
	C269.783447,759.433289 268.035980,774.384399 269.729218,776.699097 
	C270.342072,777.536865 271.930695,778.005493 273.090515,778.036926 
	C277.391357,778.153809 281.699036,778.018250 286.465027,777.943115 
	C287.364136,785.356995 287.873047,792.802246 288.179565,800.255798 
	C288.239014,801.701599 287.509216,803.179871 286.761169,804.693848 
	C286.545532,803.728882 286.712189,802.713257 286.914673,800.860352 
	C286.950500,794.781250 286.950500,789.539490 286.950500,783.412659 
	C280.695740,784.007812 275.385315,784.513062 270.074890,785.018311 
	C269.929321,785.200806 269.783752,785.383301 269.376434,785.798218 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M268.498779,753.241943 
	C266.756195,753.889587 264.575073,754.820801 262.358643,754.914307 
	C255.845230,755.189026 254.801971,755.985291 254.665024,762.236816 
	C254.412155,773.778992 254.254105,785.323242 253.991211,796.865173 
	C253.932159,799.458557 253.604370,802.045837 253.010376,804.665649 
	C252.729126,790.214233 252.882599,775.733093 252.870590,761.252136 
	C252.869492,759.940063 251.848190,758.606750 251.217697,757.324158 
	C250.697708,756.266296 250.054825,755.268860 249.466400,754.244629 
	C250.663925,753.856628 251.854813,753.160217 253.060150,753.134277 
	C258.052429,753.026733 263.049103,753.122009 268.498779,753.241943 
z"/>
<path fill="#0F1E5E" opacity="1.000000" stroke="none" 
	d="
M311.614563,795.225403 
	C311.591461,797.633057 311.302185,800.220276 310.883362,803.167725 
	C307.536072,804.574646 305.670227,803.863892 305.711212,800.026001 
	C305.850342,787.001404 305.893097,773.975769 306.347717,760.782349 
	C306.722778,760.614136 307.016602,761.016785 307.019287,761.436035 
	C307.055725,763.259460 307.089447,764.663696 307.104248,766.526306 
	C307.070709,770.982849 307.056122,774.981079 307.041504,778.979248 
	C307.333801,784.033569 307.479736,789.103088 307.999634,794.133911 
	C308.176208,795.842590 309.000244,798.256714 311.614563,795.225403 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M252.240112,804.781372 
	C253.161148,805.626709 253.758102,807.407898 254.336395,807.401855 
	C259.843903,807.344299 265.348236,806.983826 270.968170,806.309753 
	C271.692078,806.699097 272.301300,807.489136 272.910522,808.279175 
	C272.048340,808.816406 271.194458,809.803528 270.322601,809.819580 
	C263.406921,809.947510 256.487915,809.890808 249.569916,809.890808 
	C249.261642,809.455200 248.953369,809.019531 248.645096,808.583923 
	C249.732513,807.351929 250.819946,806.119934 252.240112,804.781372 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M285.737030,805.610474 
	C286.144043,806.298401 286.538971,807.576111 286.959900,807.584717 
	C292.361725,807.695557 297.767822,807.649597 303.170227,807.527161 
	C303.438446,807.521118 303.682526,806.450684 303.968811,805.466309 
	C304.725616,805.371826 305.451416,805.686951 306.533752,806.424988 
	C306.763794,807.839111 306.637299,808.830383 306.510803,809.821594 
	C306.510803,809.821594 306.256531,809.754272 305.674103,809.800415 
	C298.043549,809.846252 290.995392,809.845947 283.947266,809.845642 
	C283.853668,808.727051 283.760101,807.608398 284.126221,806.197266 
	C284.969604,805.806641 285.353333,805.708557 285.737030,805.610474 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M270.912292,805.541382 
	C267.917664,805.394348 266.879761,803.900574 267.555450,800.482910 
	C268.331299,796.559021 268.200958,792.455933 268.724976,788.202820 
	C269.195679,791.828186 269.319458,795.688843 269.652252,799.531494 
	C269.816223,801.424683 270.366486,803.284424 270.912292,805.541382 
z"/>
<path fill="#EFE4CD" opacity="1.000000" stroke="none" 
	d="
M270.535797,785.013550 
	C275.385315,784.513062 280.695740,784.007812 286.950500,783.412659 
	C286.950500,789.539490 286.950500,794.781250 286.859772,800.477051 
	C286.513367,797.041565 286.024811,793.149109 286.075226,789.263611 
	C286.115997,786.118591 285.202942,784.733521 281.873322,784.945801 
	C278.261139,785.176025 274.623322,785.004822 270.535797,785.013550 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M300.587891,753.286011 
	C297.107422,753.634705 293.171967,753.884888 289.093140,753.785583 
	C292.677521,753.353210 296.405212,753.270386 300.587891,753.286011 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M287.121552,761.490845 
	C287.362061,766.646301 287.507751,772.257751 287.279541,777.898682 
	C286.946014,772.601074 286.986389,767.273926 287.121552,761.490845 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M286.701233,753.287598 
	C287.179993,755.135925 287.304626,756.889282 287.139465,758.826599 
	C286.162567,758.355225 285.472778,757.702759 284.788696,757.044312 
	C282.112305,754.468567 282.906616,753.294678 286.701233,753.287598 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M303.689453,758.194458 
	C303.232025,756.934387 303.101593,755.562134 302.957947,753.782043 
	C304.241760,753.616577 305.538879,753.858887 306.835999,754.101196 
	C305.896149,755.428162 304.956299,756.755188 303.689453,758.194458 
z"/>
<path fill="#EFE4CD" opacity="1.000000" stroke="none" 
	d="
M307.017517,809.869141 
	C306.637299,808.830383 306.763794,807.839111 306.830200,806.471069 
	C307.859131,806.039185 308.948120,805.984070 310.439362,805.871460 
	C310.841614,805.813965 310.908569,805.899231 310.757263,806.214600 
	C310.582642,807.568542 310.559326,808.607239 310.536011,809.645874 
	C309.532104,809.736145 308.528168,809.826355 307.017517,809.869141 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M302.946136,764.569702 
	C302.792480,762.785034 302.744995,760.584106 303.029694,758.303711 
	C303.258698,760.200623 303.155487,762.177002 302.946136,764.569702 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M303.236572,803.559204 
	C300.917419,803.685608 300.396027,802.805969 302.520538,801.037170 
	C302.987976,801.711853 303.127808,802.447998 303.236572,803.559204 
z"/>
<path fill="#EFE4CD" opacity="1.000000" stroke="none" 
	d="
M285.799500,805.315002 
	C285.353333,805.708557 284.969604,805.806641 284.290649,805.941528 
	C283.978058,805.460022 283.960724,804.941650 283.905884,804.035767 
	C283.858795,803.383240 283.949554,803.158813 284.298370,802.876831 
	C284.909607,803.132019 285.363190,803.485352 285.866333,804.128296 
	C285.897949,804.618408 285.879944,804.818970 285.799500,805.315002 
z"/>
<path fill="#0F1E5E" opacity="1.000000" stroke="none" 
	d="
M308.377869,759.192932 
	C308.764099,759.487732 308.832581,759.782166 308.956360,760.540894 
	C309.011658,761.005127 308.987946,761.043518 308.659363,761.035339 
	C307.892731,761.023743 307.454651,761.020264 307.016602,761.016785 
	C307.016602,761.016785 306.722778,760.614136 306.609222,760.390381 
	C307.017151,759.841980 307.538635,759.517273 308.377869,759.192932 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M302.937439,768.749756 
	C302.756744,768.726135 302.652069,768.452576 302.703430,768.089722 
	C302.910797,768.166931 302.962128,768.333374 302.937439,768.749756 
z"/>
<path fill="#1430A3" opacity="1.000000" stroke="none" 
	d="
M307.391724,779.047241 
	C307.056122,774.981079 307.070709,770.982849 307.455811,766.428589 
	C308.213501,764.262817 308.600708,762.653198 308.987946,761.043518 
	C308.987946,761.043518 309.011658,761.005127 309.028198,760.988525 
	C312.726440,759.034607 314.383087,760.158325 313.998047,764.280396 
	C313.450256,770.144775 312.874451,776.006653 312.310608,781.869568 
	C311.491425,782.158752 310.672241,782.447876 309.853088,782.737000 
	C309.149353,781.529785 308.445618,780.322510 307.391724,779.047241 
z"/>
<path fill="#1430A3" opacity="1.000000" stroke="none" 
	d="
M275.057953,763.551819 
	C276.198212,761.713623 277.292572,760.280457 278.386963,758.847290 
	C279.030243,759.011047 279.673553,759.174744 280.316864,759.338501 
	C280.466827,762.617249 280.874542,765.917114 280.609650,769.161987 
	C280.530243,770.134827 278.574432,770.954529 277.478271,771.844360 
	C276.690796,770.871826 275.504639,770.005066 275.211578,768.901184 
	C274.798431,767.345093 275.046661,765.613464 275.057953,763.551819 
z"/>
<path fill="#0F1E5E" opacity="1.000000" stroke="none" 
	d="
M313.152649,789.241028 
	C313.247040,790.618347 312.970215,792.156250 312.374329,793.918884 
	C312.297333,792.562927 312.539368,790.982300 313.152649,789.241028 
z"/>
<path fill="#EDE5D6" opacity="1.000000" stroke="none" 
	d="
M373.979492,750.729248 
	C374.662018,750.899902 375.344543,751.070496 376.377197,751.286499 
	C376.830078,754.347961 378.008423,757.555237 374.328430,759.681824 
	C373.648254,760.074829 373.768982,762.440002 374.036743,763.792542 
	C375.794037,772.669189 377.693207,781.517700 379.667603,791.129883 
	C381.059906,795.822815 380.186676,800.565125 383.779022,803.789856 
	C385.416931,802.640259 386.672913,801.758728 388.036011,801.269409 
	C388.021790,802.724365 387.900421,803.787109 387.829285,804.859619 
	C387.879547,804.869324 387.843811,804.773376 387.456787,804.764038 
	C385.098480,805.017090 383.127228,805.279358 381.086853,805.368774 
	C380.854431,804.982605 380.638428,804.875610 380.231049,804.508667 
	C378.322144,796.248291 376.024170,788.426941 374.923981,780.440552 
	C373.722168,771.716675 367.664856,763.077209 374.682129,753.376099 
	C370.207306,753.376099 366.660309,753.376099 362.752838,753.294678 
	C361.904236,753.258667 361.416107,753.304138 360.575317,753.283813 
	C358.761230,755.793396 357.299805,758.368652 355.880554,760.955750 
	C355.922791,760.967590 355.904449,760.881836 355.616699,761.096375 
	C354.509338,763.857056 353.689789,766.403259 352.911865,768.945068 
	C352.953491,768.940735 352.923401,768.862610 352.714600,768.884888 
	C352.505768,768.907166 352.106750,769.039673 351.826111,769.211975 
	C351.324677,770.220154 351.103882,771.056030 350.648285,772.113831 
	C349.823822,772.950378 349.234161,773.565002 348.644531,774.179626 
	C349.723541,770.376709 350.802551,766.573792 351.895172,762.387756 
	C351.908813,762.004639 352.149872,761.810242 352.408508,761.419189 
	C352.808807,760.348511 352.950470,759.668945 353.092163,758.989380 
	C353.092133,758.989380 353.229034,758.821106 353.463776,758.472778 
	C353.944672,757.145203 354.190857,756.166016 354.437012,755.186829 
	C354.437042,755.186829 354.297699,755.125793 354.549927,755.002075 
	C354.921478,754.275940 355.040771,753.673462 355.160095,753.071045 
	C355.908966,752.373901 356.657898,751.676758 358.171570,750.959229 
	C363.950714,750.869019 368.965118,750.799133 373.979492,750.729248 
z"/>
<path fill="#1F3281" opacity="1.000000" stroke="none" 
	d="
M373.869263,750.354858 
	C368.965118,750.799133 363.950714,750.869019 358.476746,750.861755 
	C358.012421,750.391113 358.007690,749.997681 358.008301,749.309448 
	C363.262085,749.336609 368.510590,749.658569 373.869263,750.354858 
z"/>
<path fill="#1F2F70" opacity="1.000000" stroke="none" 
	d="
M354.048828,755.327881 
	C354.190857,756.166016 353.944672,757.145203 353.410217,758.378418 
	C353.301514,757.577942 353.481079,756.523438 354.048828,755.327881 
z"/>
<path fill="#1F2F70" opacity="1.000000" stroke="none" 
	d="
M352.798950,759.200134 
	C352.950470,759.668945 352.808807,760.348511 352.405945,761.264343 
	C352.265106,760.804016 352.385437,760.107483 352.798950,759.200134 
z"/>
<path fill="#1F2F70" opacity="1.000000" stroke="none" 
	d="
M354.865112,753.224609 
	C355.040771,753.673462 354.921478,754.275940 354.563599,755.076904 
	C354.406677,754.643005 354.488373,754.010559 354.865112,753.224609 
z"/>
<path fill="#EFE4CD" opacity="1.000000" stroke="none" 
	d="
M348.678650,774.190735 
	C349.234161,773.565002 349.823822,772.950378 350.691650,772.152710 
	C350.969818,771.969727 351.054688,772.046570 351.045898,772.411743 
	C350.966156,773.502380 350.895233,774.227722 350.868622,774.949585 
	C350.912933,774.946045 350.874847,774.865723 350.632050,775.026611 
	C349.984741,775.836853 349.580200,776.486267 349.175659,777.135620 
	C349.175629,777.135620 349.036713,777.016541 348.983002,776.514160 
	C348.841797,775.426270 348.754333,774.840759 348.666840,774.255249 
	C348.666840,774.255249 348.712799,774.201843 348.678650,774.190735 
z"/>
<path fill="#EDE5D6" opacity="1.000000" stroke="none" 
	d="
M347.342377,776.881714 
	C347.509247,777.328613 347.429443,777.974243 347.037292,778.768188 
	C346.848541,778.304504 346.972137,777.692444 347.342377,776.881714 
z"/>
<path fill="#EDE5D6" opacity="1.000000" stroke="none" 
	d="
M348.392761,774.453125 
	C348.754333,774.840759 348.841797,775.426270 348.915344,776.452576 
	C348.676117,776.865051 348.450775,776.836670 347.904510,776.691406 
	C347.761902,775.933411 347.940277,775.292236 348.392761,774.453125 
z"/>
<path fill="#253267" opacity="1.000000" stroke="none" 
	d="
M387.928894,800.877197 
	C386.672913,801.758728 385.416931,802.640259 383.779022,803.789856 
	C380.186676,800.565125 381.059906,795.822815 379.838318,791.473633 
	C380.342133,791.050293 380.796844,791.037720 381.591492,791.034363 
	C381.931458,791.043640 381.967957,791.535767 381.974365,792.172791 
	C382.540710,794.584045 383.100647,796.358215 383.660553,798.132385 
	C384.177216,798.115417 384.693909,798.098450 385.210571,798.081421 
	C385.808868,796.731201 386.407196,795.380920 387.005493,794.030701 
	C387.493530,793.426025 387.981628,792.821411 388.752014,792.127380 
	C388.907288,794.140808 388.780243,796.243591 388.820465,798.672607 
	C388.987732,798.998718 388.979523,798.934937 388.587433,798.966919 
	C388.100861,799.658325 388.006378,800.317627 387.946442,800.957275 
	C387.981018,800.937500 387.928925,800.877197 387.928894,800.877197 
z"/>
<path fill="#FB9A0C" opacity="1.000000" stroke="none" 
	d="
M617.469238,119.497589 
	C628.501099,127.784271 639.175415,135.942490 649.953308,143.961609 
	C652.924133,146.171951 652.671387,148.058685 650.249329,150.404617 
	C646.678833,153.862915 643.136169,157.358261 639.742859,160.988480 
	C636.862427,164.070053 634.860962,162.717056 632.307007,160.368607 
	C612.584290,142.233124 589.798523,129.566574 563.554382,123.932838 
	C522.692810,115.161217 484.164154,121.198830 448.857819,144.134064 
	C441.251434,149.075226 434.347321,155.123199 427.290405,160.870941 
	C424.723419,162.961731 422.826721,163.880249 420.202057,160.965790 
	C416.755371,157.138565 413.009796,153.573730 409.296143,149.996353 
	C407.211182,147.987915 406.988983,146.440994 409.316772,144.289078 
	C429.648743,125.493248 452.698334,111.540718 479.377747,103.570221 
	C519.886414,91.468224 559.530762,93.861656 598.431458,109.970421 
	C604.859131,112.632126 610.920227,116.178886 617.469238,119.497589 
z"/>
<path fill="#F99A10" opacity="1.000000" stroke="none" 
	d="
M853.165039,783.999817 
	C853.168457,777.176025 853.215149,770.851685 853.154724,764.528442 
	C853.125610,761.482666 854.023804,758.022461 850.141968,756.281738 
	C850.072449,756.250610 850.886658,753.355225 851.259033,753.362244 
	C861.711975,753.559265 872.286194,753.081421 882.579102,754.548523 
	C895.208557,756.348572 899.344666,769.506470 888.788025,779.182556 
	C890.434692,779.953125 891.855042,780.572144 893.234619,781.271606 
	C898.045105,783.710266 901.018127,787.404785 901.155945,793.007324 
	C901.300232,798.871765 899.067871,803.720154 893.627869,805.939575 
	C889.000916,807.827148 883.929565,809.290833 878.976746,809.573303 
	C869.218323,810.129883 859.405701,809.737244 849.641052,809.737244 
	C849.497803,808.859558 849.172302,808.112671 849.374146,807.921082 
	C855.928223,801.698181 852.233215,793.687195 853.151001,786.495361 
	C853.234619,785.840271 853.163574,785.165405 853.165039,783.999817 
M871.672607,804.062317 
	C876.642090,804.333435 881.067200,803.396545 883.195740,798.216309 
	C884.975403,793.885010 884.681946,789.529175 881.446167,786.074219 
	C878.249512,782.660950 874.059570,782.132324 869.214600,783.633850 
	C869.214600,789.753052 869.157654,795.714478 869.285339,801.671936 
	C869.301636,802.432922 870.280579,803.173157 871.672607,804.062317 
M875.747253,759.204956 
	C869.134338,758.647339 869.134338,758.647339 869.158691,765.220886 
	C869.166016,767.207214 868.920593,769.236633 869.249939,771.168030 
	C869.595154,773.192200 870.059875,776.458618 871.230652,776.837097 
	C874.441528,777.875366 877.741516,777.080200 879.675842,773.559326 
	C882.298889,768.785095 881.179871,763.584045 875.747253,759.204956 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M845.027527,755.630249 
	C843.909058,758.511719 842.136963,760.946106 842.019226,763.458130 
	C841.637817,771.594849 842.031799,779.764465 841.818604,787.913330 
	C841.430908,802.733826 833.557007,810.285461 818.960327,810.140503 
	C804.488770,809.996826 795.112122,801.003723 794.982849,787.136597 
	C794.950317,783.642151 795.235718,780.114807 794.855774,776.659729 
	C794.027039,769.123901 797.988647,760.799255 789.872070,753.671509 
	C799.042114,753.671509 806.796387,753.671509 814.512085,753.671509 
	C814.650574,754.221436 814.902344,754.639343 814.784546,754.836365 
	C807.386353,767.211731 811.561279,780.559021 812.028015,793.435120 
	C812.279480,800.371155 818.638733,804.393127 824.966309,803.867615 
	C831.098145,803.358337 835.773010,798.204224 835.944031,791.128357 
	C836.177246,781.481445 836.278442,771.818115 835.938293,762.179382 
	C835.844604,759.525635 833.977905,756.934387 832.563538,753.415710 
	C837.033508,754.094055 841.376648,750.688721 845.027527,755.630249 
z"/>
<path fill="#FE9702" opacity="1.000000" stroke="none" 
	d="
M765.829407,769.074280 
	C766.146057,769.213074 766.462769,769.351868 766.983276,769.969910 
	C767.473816,770.623779 767.760498,770.798462 768.047241,770.973145 
	C768.047241,770.973145 767.996521,770.994080 768.023865,771.331360 
	C770.646973,773.584534 773.334412,775.389526 775.817627,777.441589 
	C779.865356,780.786499 784.894043,783.650696 787.370544,787.949524 
	C789.110535,790.969788 787.293945,796.036011 787.081421,800.189453 
	C787.064880,800.513367 787.174133,800.843628 787.224915,801.170959 
	C787.224915,801.170959 787.181213,801.219299 786.854370,801.185913 
	C783.006897,803.393616 779.561462,805.767273 775.935669,807.822815 
	C773.962891,808.941162 772.106995,808.675293 771.866516,805.803162 
	C771.913086,805.808289 771.835388,805.860596 772.091431,805.833374 
	C772.575378,805.561829 772.803223,805.317627 773.025879,805.036682 
	C773.020752,805.000000 773.074951,805.050537 773.450195,805.041504 
	C777.818054,802.414062 778.069580,800.667908 774.800537,796.775574 
	C774.044556,795.785339 773.470764,795.018250 772.821655,793.960571 
	C772.408936,793.483215 772.071594,793.296448 771.490173,793.010254 
	C770.835632,792.584534 770.425171,792.258240 770.015503,791.696411 
	C769.664185,791.343567 769.311951,791.226318 768.674683,791.047119 
	C767.932739,790.644958 767.475952,790.304626 766.976196,789.593750 
	C765.492554,788.549561 764.051758,787.876038 762.355103,787.153442 
	C761.717529,786.773438 761.335754,786.442444 760.931274,785.755249 
	C759.944092,784.930176 758.979736,784.461487 758.007690,783.996338 
	C758.000000,784.000000 758.006531,783.984741 757.982422,783.640015 
	C756.949646,782.548157 755.940918,781.800964 754.966064,781.028320 
	C755.000000,781.002808 754.945007,781.067566 754.967041,780.688477 
	C750.628418,774.337708 750.180603,767.713867 751.756836,760.729980 
	C751.783386,760.702881 751.709290,760.719482 752.035156,760.784241 
	C752.503662,760.201050 752.646301,759.553040 752.858276,758.661987 
	C753.018616,758.098206 753.109680,757.777527 753.200745,757.456909 
	C753.595398,757.286194 753.990051,757.115479 754.765259,757.069458 
	C755.275391,756.984985 755.404907,756.775818 755.534363,756.566711 
	C755.534363,756.566711 755.612000,756.588196 755.998596,756.690430 
	C757.593628,755.873108 758.802063,754.953552 760.010559,754.033997 
	C760.010559,754.033997 760.322754,753.834290 760.955200,753.897827 
	C763.699402,753.961304 765.811218,753.961304 767.943115,753.961304 
	C767.255554,755.124817 766.658752,756.134766 766.061951,757.144714 
	C765.960876,757.446411 765.776489,757.683105 764.946533,757.953857 
	C760.563538,761.075623 761.237244,766.214478 765.829407,769.074280 
z"/>
<path fill="#1F2F70" opacity="1.000000" stroke="none" 
	d="
M792.671265,769.002563 
	C792.671265,774.919678 792.671265,780.836792 792.671265,785.442932 
	C787.556091,781.497803 781.935730,777.133545 776.280212,772.815369 
	C773.909363,771.005249 771.358704,769.417786 769.082092,767.500793 
	C766.829834,765.604309 764.533569,762.586609 767.307068,760.463562 
	C769.100830,759.090637 773.372009,759.429321 775.645813,760.651550 
	C777.779846,761.798584 779.461670,764.943298 780.079712,767.512756 
	C781.355957,772.818787 784.343506,773.121399 789.139832,771.229858 
	C790.709290,770.269409 791.690308,769.635986 792.671265,769.002563 
z"/>
<path fill="#152669" opacity="1.000000" stroke="none" 
	d="
M848.415405,803.976196 
	C848.185486,802.360474 848.338501,800.726929 848.550781,799.101257 
	C848.852478,796.790833 849.197937,794.486206 849.667725,791.168762 
	C847.245667,792.115417 846.288452,792.489502 844.994629,792.812012 
	C844.712585,783.105469 844.765076,773.450562 844.823059,763.795654 
	C844.837219,761.447876 844.598694,758.458496 848.037598,759.067505 
	C849.270020,759.285645 850.876526,762.025940 850.909363,763.643616 
	C851.149658,775.460815 851.117126,787.286682 850.930969,799.106018 
	C850.905273,800.735291 849.559448,802.343811 848.415405,803.976196 
z"/>
<path fill="#EFE4CD" opacity="1.000000" stroke="none" 
	d="
M770.014648,791.931946 
	C770.425171,792.258240 770.835632,792.584534 771.602234,793.273804 
	C772.271179,793.841614 772.584045,794.046387 772.896912,794.251160 
	C773.470764,795.018250 774.044556,795.785339 774.785034,797.181519 
	C774.326050,800.223938 773.700500,802.637207 773.074951,805.050537 
	C773.074951,805.050537 773.020752,805.000000 772.763977,805.055298 
	C772.283264,805.360596 772.059326,805.610596 771.835388,805.860596 
	C771.835388,805.860596 771.913086,805.808289 771.512695,805.817627 
	C770.405457,805.878540 769.698608,805.930176 768.991699,805.981812 
	C768.160583,805.548950 767.329529,805.116150 766.286499,804.482178 
	C768.308899,803.193359 770.543152,802.105652 772.777405,801.017944 
	C771.470337,798.888489 770.601624,796.217590 768.770691,794.735046 
	C764.017822,790.886597 758.555176,787.899292 753.895447,783.954468 
	C744.722046,776.188477 744.266418,764.198792 752.678162,755.247498 
	C753.014221,755.627686 753.049683,756.151184 753.142944,757.065796 
	C753.109680,757.777527 753.018616,758.098206 752.606934,758.796265 
	C752.093933,759.688965 751.901611,760.204224 751.709290,760.719482 
	C751.709290,760.719482 751.783386,760.702881 751.418457,760.873779 
	C747.372498,769.315735 748.643616,775.855896 754.945007,781.067566 
	C754.945007,781.067566 755.000000,781.002808 755.070679,781.324829 
	C756.096375,782.426086 757.051453,783.205444 758.006531,783.984741 
	C758.006531,783.984741 758.000000,784.000000 758.130981,784.287964 
	C759.159302,785.087769 760.056702,785.599670 760.954041,786.111511 
	C761.335754,786.442444 761.717529,786.773438 762.559326,787.424255 
	C764.352600,788.484192 765.685852,789.224243 767.019104,789.964355 
	C767.475952,790.304626 767.932739,790.644958 768.765625,791.277588 
	C769.356506,791.874146 769.647522,791.994812 770.014648,791.931946 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M768.599243,806.073486 
	C769.698608,805.930176 770.405457,805.878540 771.466187,805.812500 
	C772.106995,808.675293 773.962891,808.941162 775.935669,807.822815 
	C779.561462,805.767273 783.006897,803.393616 786.886536,801.191101 
	C785.126831,806.670288 780.159180,809.142517 775.297668,809.510254 
	C768.578796,810.018311 761.716431,808.629150 755.055298,807.640808 
	C756.572571,806.047302 760.788147,811.482239 759.969910,805.181091 
	C760.344238,805.168823 760.718628,805.156555 761.664429,805.357178 
	C763.157776,805.708740 764.079712,805.847412 765.001648,805.986084 
	C766.070007,806.045776 767.138367,806.105469 768.599243,806.073486 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M787.529419,800.970581 
	C787.174133,800.843628 787.064880,800.513367 787.081421,800.189453 
	C787.293945,796.036011 789.110535,790.969788 787.370544,787.949524 
	C784.894043,783.650696 779.865356,780.786499 775.817627,777.441589 
	C773.334412,775.389526 770.646973,773.584534 768.042236,771.310791 
	C773.249512,774.146545 778.665527,777.059753 783.634155,780.600647 
	C790.766052,785.683167 791.874146,791.957764 787.529419,800.970581 
z"/>
<path fill="#FE9702" opacity="1.000000" stroke="none" 
	d="
M759.753479,805.047974 
	C760.788147,811.482239 756.572571,806.047302 754.803772,807.358643 
	C754.412476,807.488220 754.163879,807.546936 753.927856,807.110107 
	C748.426514,805.058716 749.002441,800.244019 748.250854,796.291138 
	C748.142334,795.720337 750.028015,794.770264 750.990906,793.995728 
	C751.109131,794.311584 751.227417,794.627441 751.247131,795.594727 
	C752.536072,800.011414 753.183472,804.424194 758.551514,804.706421 
	C758.880005,804.775879 759.208557,804.845337 759.753479,805.047974 
z"/>
<path fill="#FE9702" opacity="1.000000" stroke="none" 
	d="
M786.343933,763.045410 
	C786.424072,764.132446 786.504150,765.219421 786.374146,766.853394 
	C786.048645,767.639465 786.036926,767.883545 786.129028,768.132629 
	C786.129028,768.132629 786.152588,768.024536 785.822937,767.998779 
	C784.580505,767.357544 783.667786,766.742004 782.755005,766.126465 
	C782.755005,766.126465 782.887878,766.027893 782.921265,765.710754 
	C782.023071,763.903076 781.091553,762.412476 780.160034,760.921936 
	C780.160034,760.921936 780.063660,760.924561 780.038208,760.585205 
	C779.612732,759.865234 779.212830,759.484619 778.812927,759.103943 
	C778.812927,759.103943 778.923889,759.088623 778.909729,758.743164 
	C777.829102,754.957703 775.374268,754.572205 772.356384,755.425476 
	C771.986328,755.509460 771.616211,755.593506 771.148926,755.306885 
	C772.026489,754.311096 773.001221,753.685913 773.976013,753.060791 
	C774.433777,753.058228 774.891479,753.055603 775.859619,753.338867 
	C776.714172,753.677002 777.058472,753.729309 777.402710,753.781677 
	C777.767578,753.830139 778.132385,753.878662 778.803101,754.407349 
	C782.439514,756.791992 785.440857,758.988220 786.343933,763.045410 
z"/>
<path fill="#1D2A5E" opacity="1.000000" stroke="none" 
	d="
M792.742310,768.558594 
	C791.690308,769.635986 790.709290,770.269409 789.426392,770.931824 
	C789.124451,767.041199 789.124451,763.121582 789.124451,758.232788 
	C794.811279,760.443726 792.093628,764.961609 792.742310,768.558594 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M750.998474,793.615234 
	C750.028015,794.770264 748.142334,795.720337 748.250854,796.291138 
	C749.002441,800.244019 748.426514,805.058716 753.834717,807.022888 
	C748.138367,806.186646 744.137817,797.475708 747.328247,792.312439 
	C748.677795,790.128296 750.279541,790.876831 750.998474,793.615234 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M773.666626,752.807312 
	C773.001221,753.685913 772.026489,754.311096 770.864868,755.265869 
	C769.355469,756.105164 768.032959,756.614868 766.386169,757.134644 
	C766.658752,756.134766 767.255554,755.124817 767.943115,753.961304 
	C765.811218,753.961304 763.699402,753.961304 761.140503,753.902100 
	C764.914612,753.413208 769.135864,752.983521 773.666626,752.807312 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M786.499146,762.657959 
	C785.440857,758.988220 782.439514,756.791992 779.041565,754.551514 
	C783.134949,755.374207 787.999023,755.862488 786.499146,762.657959 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M765.723267,768.715332 
	C761.237244,766.214478 760.563538,761.075623 764.736938,758.045044 
	C763.419922,761.571472 763.188049,765.031982 765.723267,768.715332 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M758.388062,804.400269 
	C753.183472,804.424194 752.536072,800.011414 751.226685,795.848816 
	C753.611389,798.332397 755.917969,801.213196 758.388062,804.400269 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M772.553772,755.708618 
	C775.374268,754.572205 777.829102,754.957703 778.873779,758.786133 
	C776.818359,758.113586 774.784729,757.052673 772.553772,755.708618 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M780.163818,761.262085 
	C781.091553,762.412476 782.023071,763.903076 782.877075,765.780701 
	C781.922241,764.645935 781.044983,763.124146 780.163818,761.262085 
z"/>
<path fill="#253C91" opacity="1.000000" stroke="none" 
	d="
M869.526855,812.246338 
	C867.197083,812.500793 864.431885,812.649658 861.356506,812.576416 
	C863.728027,812.283142 866.409668,812.211975 869.526855,812.246338 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M759.659302,754.012573 
	C758.802063,754.953552 757.593628,755.873108 756.027893,756.662781 
	C756.883057,755.685608 758.095520,754.838379 759.659302,754.012573 
z"/>
<path fill="#253C91" opacity="1.000000" stroke="none" 
	d="
M879.540100,812.241821 
	C877.848389,812.492188 875.734070,812.636841 873.331543,812.563599 
	C875.068054,812.275757 877.092773,812.205933 879.540100,812.241821 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M782.727905,766.487915 
	C783.667786,766.742004 784.580505,767.357544 785.827271,768.054199 
	C785.690979,768.657959 785.220825,769.180603 784.750610,769.703186 
	C784.067322,768.751892 783.384033,767.800659 782.727905,766.487915 
z"/>
<path fill="#152669" opacity="1.000000" stroke="none" 
	d="
M844.514709,793.044922 
	C844.661194,794.075012 844.524109,795.362366 844.063843,796.879150 
	C843.904175,795.839783 844.067627,794.571045 844.514709,793.044922 
z"/>
<path fill="#EFE4CD" opacity="1.000000" stroke="none" 
	d="
M764.764282,805.712646 
	C764.079712,805.847412 763.157776,805.708740 761.949219,805.354370 
	C762.617371,805.238770 763.572144,805.338989 764.764282,805.712646 
z"/>
<path fill="#253C91" opacity="1.000000" stroke="none" 
	d="
M846.831787,808.660645 
	C846.638550,808.435364 846.521790,807.889038 846.588745,807.188843 
	C846.817749,807.469849 846.862976,807.904724 846.831787,808.660645 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M778.843262,759.398560 
	C779.212830,759.484619 779.612732,759.865234 780.051819,760.538940 
	C779.685181,760.452454 779.279358,760.072754 778.843262,759.398560 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M777.229736,753.572144 
	C777.058472,753.729309 776.714172,753.677002 776.193420,753.424561 
	C776.363525,753.270447 776.710205,753.316528 777.229736,753.572144 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M786.311462,768.017334 
	C786.036926,767.883545 786.048645,767.639465 786.276672,767.254211 
	C786.632812,767.345154 786.667664,767.609802 786.311462,768.017334 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M755.379517,756.562134 
	C755.404907,756.775818 755.275391,756.984985 755.076904,756.986450 
	C755.007935,756.778809 755.224731,756.557617 755.379517,756.562134 
z"/>
<path fill="#1F3281" opacity="1.000000" stroke="none" 
	d="
M829.306885,758.931763 
	C829.185181,758.697754 829.398804,758.446594 829.846558,758.105713 
	C830.080750,758.016052 830.261047,758.359497 830.276489,758.552246 
	C830.075256,758.801636 829.858704,758.858154 829.306885,758.931763 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M767.974976,770.719299 
	C767.760498,770.798462 767.473816,770.623779 767.102112,770.204590 
	C767.312317,770.128540 767.607483,770.296997 767.974976,770.719299 
z"/>
<path fill="#1F3281" opacity="1.000000" stroke="none" 
	d="
M704.601318,750.484009 
	C699.953247,750.779053 694.975525,750.838745 689.541931,750.840210 
	C694.147888,750.604187 699.209839,750.426392 704.601318,750.484009 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M708.838135,802.138550 
	C705.289062,792.325256 706.647827,793.039368 696.567017,793.234131 
	C695.235229,793.259888 693.902344,793.227661 692.570007,793.230591 
	C685.820496,793.245483 681.983032,799.717773 685.333374,805.799194 
	C686.810974,808.481201 686.039917,809.586487 683.400208,809.752930 
	C680.414856,809.941162 677.402283,809.893982 674.414978,809.719177 
	C673.636963,809.673645 672.460388,808.888367 672.289062,808.229187 
	C672.129517,807.615112 673.005737,806.674377 673.528076,805.951233 
	C675.225891,803.601074 677.565918,801.518860 678.590454,798.913391 
	C682.362366,789.321228 685.634583,779.533997 689.235962,769.872803 
	C691.188477,764.634888 694.810852,759.590515 688.567444,753.229065 
	C694.589539,753.229065 699.020142,753.005188 703.398743,753.383423 
	C704.520508,753.480347 705.885254,755.306091 706.448181,756.630798 
	C712.370239,770.566772 718.061890,784.601196 724.052734,798.506958 
	C725.183044,801.130493 727.380493,803.286743 729.022339,805.701599 
	C729.621887,806.583496 730.037903,807.590149 730.537659,808.539917 
	C729.549744,808.973206 728.569153,809.760803 727.572693,809.781372 
	C721.912720,809.898071 716.246765,809.914551 710.587952,809.767273 
	C709.208923,809.731384 707.849243,808.952820 706.480591,808.515747 
	C707.100525,807.314514 707.784485,806.140686 708.316345,804.901550 
	C708.633728,804.161865 708.712463,803.319763 708.838135,802.138550 
M695.064331,771.718689 
	C693.181946,776.760925 691.299500,781.803101 689.260437,787.264771 
	C694.384644,787.264771 698.494690,787.264771 703.999939,787.264771 
	C700.836243,781.218811 698.238159,776.253784 695.064331,771.718689 
z"/>
<path fill="#223A9A" opacity="1.000000" stroke="none" 
	d="
M728.511353,812.259399 
	C724.880127,812.518677 720.801941,812.663696 716.394531,812.583435 
	C720.064941,812.287170 724.064697,812.216187 728.511353,812.259399 
z"/>
<path fill="#F99A10" opacity="1.000000" stroke="none" 
	d="
M548.277344,809.402832 
	C538.994446,809.453125 530.159058,809.453125 521.323608,809.453125 
	C521.104004,809.104797 520.884399,808.756531 520.664795,808.408203 
	C527.256836,802.805054 524.504211,795.487976 524.843506,788.797729 
	C525.163818,782.481201 524.955322,776.135559 524.906311,769.803284 
	C524.865051,764.469055 526.291565,758.762268 520.648682,754.722656 
	C521.668457,754.028198 522.190796,753.361450 522.679993,753.384949 
	C534.601685,753.958557 546.705872,753.527039 558.386597,755.541504 
	C570.185364,757.576294 577.475586,768.393005 577.692749,780.352356 
	C577.927063,793.254089 571.577148,802.999878 559.922791,806.999207 
	C556.342590,808.227844 552.465820,808.592346 548.277344,809.402832 
M541.060547,801.662964 
	C542.533875,802.466858 544.069519,804.051331 545.469727,803.939575 
	C551.600708,803.449951 556.139648,800.192749 558.220825,794.358765 
	C561.387573,785.481995 561.365112,776.450500 557.969971,767.629883 
	C555.909607,762.276917 550.127930,758.802734 544.692993,758.814697 
	C541.618103,758.821472 540.282410,759.886841 541.181763,763.953613 
	C541.229919,776.262146 541.278076,788.570679 541.060547,801.662964 
z"/>
<path fill="#EDE5D6" opacity="1.000000" stroke="none" 
	d="
M572.667114,804.093567 
	C572.583740,804.757446 572.170715,805.341187 571.465698,805.961731 
	C571.139893,805.824097 571.106140,805.649658 571.042725,805.210327 
	C571.013062,804.945374 571.004517,804.502930 570.994080,804.281921 
	C571.434937,804.045105 571.886292,804.029236 572.667114,804.093567 
z"/>
<path fill="#F99A10" opacity="1.000000" stroke="none" 
	d="
M661.795837,803.191467 
	C665.592712,809.758423 665.592712,809.760925 657.571594,809.779053 
	C653.072021,809.789246 648.569824,809.872803 644.075134,809.726562 
	C643.129150,809.695801 641.999634,808.977539 641.385315,808.224670 
	C641.142334,807.926941 642.129150,806.692078 642.460327,805.846436 
	C643.322205,803.645508 644.800781,801.438171 644.848450,799.208130 
	C645.093994,787.714111 644.959656,776.212280 644.973511,764.713135 
	C644.980530,758.925415 641.866272,756.872498 636.950745,760.009705 
	C634.596619,761.512268 633.087646,764.369873 631.273743,766.675110 
	C630.375916,767.816162 629.654602,769.096191 628.853394,770.313293 
	C628.200012,770.121277 627.546692,769.929260 626.893372,769.737305 
	C627.591003,764.679260 628.171021,759.598816 629.128784,754.590576 
	C629.254517,753.933289 631.312134,753.255615 632.479797,753.246948 
	C646.143982,753.145813 659.809265,753.135864 673.473450,753.227783 
	C674.595642,753.235352 676.556458,753.993347 676.695068,754.688354 
	C677.682190,759.638428 678.296631,764.662720 679.029602,769.663452 
	C678.495605,769.884338 677.961548,770.105164 677.427551,770.326050 
	C674.962280,767.175171 672.497009,764.024292 669.656372,760.809265 
	C669.280945,760.745117 669.221436,760.736694 669.125732,760.489563 
	C668.711487,760.124329 668.392822,760.006165 667.775452,759.667114 
	C665.995911,759.402344 664.515076,759.358521 662.722046,759.234436 
	C662.058105,759.784729 661.706360,760.415222 661.252197,761.500488 
	C661.224854,774.650024 661.300049,787.344788 661.283325,800.272583 
	C661.253540,800.660767 661.315552,800.815857 661.297180,801.259155 
	C661.409790,802.095337 661.602783,802.643372 661.795837,803.191467 
z"/>
<path fill="#F2F2F2" opacity="1.000000" stroke="none" 
	d="
M661.375183,800.039490 
	C661.300049,787.344788 661.224854,774.650024 661.527466,761.499878 
	C662.273071,761.032593 662.640930,761.020630 663.004395,761.004395 
	C663.000000,761.000000 662.991577,760.991455 662.985718,761.244873 
	C663.147400,761.956055 663.314819,762.413818 663.482239,762.871582 
	C663.535889,764.024048 663.589539,765.176575 663.550781,766.756104 
	C663.297180,776.877869 663.160217,786.573181 662.955444,796.267029 
	C662.910767,798.380005 662.622925,800.487854 662.121460,802.894775 
	C661.602783,802.643372 661.409790,802.095337 661.419067,801.168396 
	C661.773193,800.468445 661.691162,800.218445 661.375183,800.039490 
z"/>
<path fill="#F2F2F2" opacity="1.000000" stroke="none" 
	d="
M663.034302,759.314636 
	C664.515076,759.358521 665.995911,759.402344 667.870178,759.916504 
	C668.582947,760.503418 668.902161,760.620056 669.221436,760.736694 
	C669.221436,760.736694 669.280945,760.745117 669.310913,760.752747 
	C669.250549,761.034973 669.160095,761.309631 668.963196,762.004639 
	C667.514771,761.986816 666.172791,761.548584 664.195068,761.052368 
	C663.370056,760.993408 663.180847,760.992432 662.991577,760.991455 
	C662.991577,760.991455 663.000000,761.000000 663.003662,760.721436 
	C663.016296,760.066772 663.025269,759.690735 663.034302,759.314636 
z"/>
<path fill="#1F3281" opacity="1.000000" stroke="none" 
	d="
M632.968140,773.598999 
	C632.667603,773.717590 632.364868,773.463379 631.958984,772.922546 
	C631.960815,772.331177 632.151672,772.096375 632.637817,771.836670 
	C632.886719,772.236694 632.926331,772.731445 632.968140,773.598999 
z"/>
<path fill="#1D2A5E" opacity="1.000000" stroke="none" 
	d="
M672.916260,769.641541 
	C672.674316,769.652649 672.465088,769.409363 672.301575,768.912842 
	C672.547852,768.902100 672.748413,769.144653 672.916260,769.641541 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M575.462158,809.916199 
	C575.166504,808.504578 575.333191,807.078125 576.024048,805.378784 
	C576.765076,804.804138 576.981995,804.502197 577.198853,804.200256 
	C577.198853,804.200256 577.255432,804.231201 577.516235,804.155518 
	C578.075745,803.886841 578.219849,803.612549 578.231995,803.252686 
	C578.254761,803.248413 578.298706,803.264893 578.298706,803.264893 
	C580.482971,798.148865 582.822083,793.092224 584.816162,787.903137 
	C587.973145,779.687683 591.063721,771.437012 593.789246,763.073181 
	C594.359192,761.324219 593.221741,759.018799 592.557617,756.805908 
	C592.250061,755.831055 591.914551,754.729614 592.317322,754.256348 
	C594.901733,751.219666 607.315735,753.377380 608.824036,757.021606 
	C614.360352,770.396851 619.783630,783.821472 625.549377,797.097351 
	C626.941406,800.302612 629.511108,802.990356 631.479858,805.955566 
	C631.987427,806.720093 632.721252,807.766235 632.523682,808.440552 
	C632.341492,809.062378 631.054932,809.725586 630.243469,809.740173 
	C623.916199,809.854004 617.584106,809.910950 611.257935,809.777405 
	C608.727539,809.723999 607.539429,808.792542 609.302246,805.959961 
	C612.901184,800.176758 609.075745,793.246704 602.456604,793.201050 
	C600.457947,793.187256 598.459229,793.197510 596.460510,793.195496 
	C587.172363,793.185913 584.240417,797.988220 587.969543,806.735596 
	C588.237000,807.362976 586.735901,809.597351 585.899597,809.691956 
	C582.608826,810.064148 579.255005,809.879333 575.462158,809.916199 
M593.995483,787.813110 
	C597.528870,787.813110 601.062195,787.813110 605.434387,787.813110 
	C602.827515,781.241760 600.678528,775.824524 597.992371,769.053162 
	C595.684814,775.216064 593.817139,779.952942 592.201294,784.774170 
	C591.940735,785.551575 592.801086,786.704651 593.995483,787.813110 
z"/>
<path fill="#223A9A" opacity="1.000000" stroke="none" 
	d="
M632.518433,812.253906 
	C629.872803,812.505249 626.788574,812.647705 623.387695,812.569702 
	C626.074036,812.281189 629.076904,812.213013 632.518433,812.253906 
z"/>
<path fill="#E9E1D0" opacity="1.000000" stroke="none" 
	d="
M576.665161,802.711243 
	C576.819275,802.050537 577.089111,801.488281 577.603638,800.729614 
	C577.925659,801.144043 578.002991,801.754822 578.189453,802.815247 
	C578.298706,803.264893 578.254761,803.248413 577.936462,803.164917 
	C577.415344,803.057495 577.212585,803.033569 577.009766,803.009583 
	C577.009766,803.009583 576.780884,802.809692 576.665161,802.711243 
z"/>
<path fill="#E9E1D0" opacity="1.000000" stroke="none" 
	d="
M662.985718,761.244873 
	C663.180847,760.992432 663.370056,760.993408 663.842224,761.019775 
	C664.006714,761.460693 663.888245,761.876160 663.625977,762.581665 
	C663.314819,762.413818 663.147400,761.956055 662.985718,761.244873 
z"/>
<path fill="#223A9A" opacity="1.000000" stroke="none" 
	d="
M641.308716,812.571838 
	C642.415100,812.299255 643.778015,812.229431 645.539124,812.259338 
	C644.479980,812.497620 643.022522,812.636169 641.308716,812.571838 
z"/>
<path fill="#EFEBDE" opacity="1.000000" stroke="none" 
	d="
M573.981567,198.929581 
	C578.148315,202.003845 582.085938,204.781876 585.833008,207.796814 
	C590.033325,211.176437 590.278320,215.986526 586.586487,219.755508 
	C582.747375,223.674896 578.865479,227.553329 574.954834,231.401443 
	C571.361450,234.937347 567.393555,235.033585 563.475281,231.772217 
	C541.342468,213.350021 518.175293,213.440323 495.775970,232.036011 
	C492.431732,234.812363 488.208374,234.752762 485.062073,231.728134 
	C481.116516,227.935211 477.208374,224.099289 473.393890,220.175186 
	C469.177490,215.837646 469.426178,211.495560 473.987305,207.489487 
	C492.016357,191.654480 513.292786,186.118011 536.825134,187.560059 
	C550.015320,188.368378 562.258667,192.139816 573.981567,198.929581 
M535.322449,190.965149 
	C528.856567,191.287842 522.295410,191.022568 515.944031,192.051941 
	C501.359711,194.415649 488.572327,200.879532 477.384125,210.557632 
	C474.280121,213.242676 474.215027,215.452393 477.341492,218.214294 
	C480.699951,221.181122 483.831573,224.427490 486.863373,227.734161 
	C489.259308,230.347336 490.991180,230.067383 494.009857,228.143555 
	C500.530304,223.987930 507.199890,219.374512 514.480347,217.238510 
	C533.055969,211.788635 550.367615,215.452515 565.451233,227.897873 
	C568.331848,230.274689 570.344482,230.582047 572.970276,227.634964 
	C576.166992,224.047180 579.651855,220.695023 583.207031,217.454865 
	C585.805786,215.086456 585.785278,213.471008 583.037292,211.080231 
	C569.567261,199.361237 554.122803,192.417450 535.322449,190.965149 
z"/>
<path fill="#253C91" opacity="1.000000" stroke="none" 
	d="
M577.154297,185.130371 
	C580.119202,186.544022 583.059631,188.315948 586.144897,190.397736 
	C583.236389,188.967941 580.183105,187.228287 577.154297,185.130371 
z"/>
<path fill="#253C91" opacity="1.000000" stroke="none" 
	d="
M571.226257,182.075027 
	C572.506226,182.192947 573.761414,182.717575 575.202209,183.542358 
	C573.992371,183.388931 572.596924,182.935349 571.226257,182.075027 
z"/>
<path fill="#253C91" opacity="1.000000" stroke="none" 
	d="
M586.277466,190.972504 
	C586.766296,190.867874 587.330872,191.092346 588.033325,191.591812 
	C587.565186,191.678406 586.959229,191.490005 586.277466,190.972504 
z"/>
<path fill="#253C91" opacity="1.000000" stroke="none" 
	d="
M589.219055,193.026657 
	C589.479797,192.902344 589.810181,193.064697 590.229126,193.494919 
	C589.974609,193.612976 589.631714,193.463150 589.219055,193.026657 
z"/>
<path fill="#253C91" opacity="1.000000" stroke="none" 
	d="
M576.130737,184.224670 
	C576.343628,184.097397 576.679382,184.208679 577.121033,184.579559 
	C576.902527,184.713852 576.578064,184.588547 576.130737,184.224670 
z"/>
<path fill="#253C91" opacity="1.000000" stroke="none" 
	d="
M588.175537,192.083649 
	C588.419006,191.975922 588.744202,192.143936 589.161011,192.577515 
	C588.920776,192.681854 588.588989,192.520615 588.175537,192.083649 
z"/>
<path fill="#253C91" opacity="1.000000" stroke="none" 
	d="
M590.250854,193.948029 
	C590.255371,193.672623 590.667969,193.748642 590.830566,194.122940 
	C590.993103,194.497238 590.981689,194.990768 590.981689,194.990768 
	C590.736572,194.735001 590.491516,194.479218 590.250854,193.948029 
z"/>
<path fill="#1E338B" opacity="1.000000" stroke="none" 
	d="
M592.156494,195.092484 
	C592.381775,195.018036 592.680298,195.217392 593.074219,195.675262 
	C592.856262,195.744614 592.542969,195.555450 592.156494,195.092484 
z"/>
<path fill="#1E338B" opacity="1.000000" stroke="none" 
	d="
M591.264099,194.994736 
	C590.981689,194.990768 590.993103,194.497238 590.997070,194.250519 
	C591.318665,194.136169 591.636353,194.268555 592.029907,194.660034 
	C591.919312,194.945663 591.732910,194.972183 591.264099,194.994736 
z"/>
<path fill="#F99A10" opacity="1.000000" stroke="none" 
	d="
M450.353546,753.167114 
	C460.915588,753.663818 471.275452,752.921692 481.064606,754.943298 
	C495.781891,757.982483 502.788361,768.580872 502.166809,783.317261 
	C501.617584,796.338318 492.802856,806.606812 478.817932,808.549133 
	C468.999908,809.912659 458.930542,809.559326 448.969208,809.738647 
	C446.181793,809.788879 444.881287,808.647766 447.154266,805.695801 
	C448.339752,804.156128 449.064392,801.862366 449.092804,799.896179 
	C449.270569,787.581177 449.361298,775.258667 449.076202,762.947998 
	C449.009430,760.064087 447.384644,757.216248 446.263458,753.679382 
	C447.232849,753.542175 448.564392,753.353760 450.353546,753.167114 
M485.082306,783.315186 
	C484.614288,779.208679 484.589874,774.989929 483.584869,771.019348 
	C481.561890,763.026733 476.050446,758.781921 469.186157,758.921387 
	C466.482605,758.976257 465.305603,759.817871 465.330109,762.698059 
	C465.427521,774.146057 465.343262,785.595581 465.337921,797.044495 
	C465.334534,804.289551 467.980804,806.057495 474.650879,802.761902 
	C477.068665,801.567322 479.746826,799.637207 480.859741,797.341492 
	C482.841797,793.253052 483.722504,788.630676 485.082306,783.315186 
z"/>
<path fill="#F89B12" opacity="1.000000" stroke="none" 
	d="
M399.120087,793.359192 
	C398.733948,793.850464 398.347809,794.341675 397.394531,795.066772 
	C395.671448,797.361877 394.359619,799.359802 393.477325,801.532166 
	C393.275543,802.028931 394.554810,803.127319 395.152466,803.948792 
	C395.492767,804.385437 395.833069,804.822144 395.902283,805.757935 
	C392.866669,807.067261 390.102203,807.877502 386.870483,808.824707 
	C387.353058,806.816101 387.598419,805.794739 387.843811,804.773376 
	C387.843811,804.773376 387.879547,804.869324 388.214600,804.859985 
	C389.319244,802.754944 390.088806,800.659241 390.858398,798.563599 
	C390.858429,798.563599 390.797913,798.251526 391.148132,797.949829 
	C391.641266,797.065613 391.784241,796.483093 391.927185,795.900574 
	C391.927185,795.900574 391.960083,795.978271 392.251221,795.836914 
	C394.649475,789.420410 396.756592,783.145264 398.863708,776.870117 
	C398.863678,776.870117 398.801697,776.910889 399.150421,776.886108 
	C399.912231,775.199707 400.325317,773.538086 400.738403,771.876465 
	C400.818909,771.433533 400.899414,770.990662 401.297760,770.103638 
	C401.812744,769.418884 401.903046,769.143066 401.886505,768.832214 
	C401.886505,768.832214 401.916016,768.945801 402.178101,768.755859 
	C402.577240,767.853943 402.714294,767.141968 402.851318,766.430054 
	C402.851349,766.430054 402.686768,766.231323 403.134766,766.061523 
	C407.804504,761.846313 404.791382,757.557556 404.005035,753.343750 
	C404.760345,753.303589 405.515686,753.263489 406.688843,753.631226 
	C407.951660,754.325195 409.059265,755.103760 409.598328,754.816345 
	C412.733612,753.144226 414.741516,754.328308 416.521362,756.944763 
	C417.093811,757.786255 418.115448,758.322205 418.931580,758.997986 
	C418.931580,758.997986 419.155792,759.270691 419.103607,759.875183 
	C420.115387,762.674866 421.179352,764.870056 422.243347,767.065247 
	C422.243347,767.065247 422.317749,767.281982 422.216492,767.752686 
	C422.565247,768.784851 423.015320,769.346313 423.465363,769.907776 
	C423.710052,770.391113 423.954742,770.874451 423.894867,771.843140 
	C424.506500,773.846863 425.422729,775.365234 426.338928,776.883606 
	C426.606720,777.372253 426.874542,777.860840 426.888733,778.811035 
	C427.366058,780.513794 428.096954,781.755066 428.827881,782.996277 
	C428.827881,782.996277 429.089478,783.324585 429.084656,783.880615 
	C429.440369,784.976257 429.800934,785.515808 430.161499,786.055420 
	C430.406921,786.316162 430.504120,786.623840 430.270325,787.614197 
	C431.725403,792.847473 433.143402,797.542847 435.121002,801.989258 
	C435.808746,803.535583 437.881165,804.466003 439.321198,805.677673 
	C439.321198,805.677673 439.367371,805.701355 439.188507,806.094971 
	C433.229340,807.173950 427.449036,807.859314 420.832397,808.643921 
	C422.182983,806.075745 423.487305,804.717346 423.201691,803.953918 
	C421.535370,799.500916 421.143005,794.342041 416.474579,791.394409 
	C415.280884,790.640747 414.640747,789.010254 413.768555,787.798645 
	C413.790771,787.814697 413.823730,787.858521 414.212616,787.896606 
	C418.060791,785.821899 417.906799,785.184631 413.058105,781.814331 
	C412.947479,781.623291 412.924042,781.182312 412.946411,780.819397 
	C412.598602,779.653259 412.228394,778.850098 411.899841,778.040283 
	C411.941498,778.033691 411.919586,778.115112 411.877625,777.904785 
	C411.726044,777.417664 411.539764,777.209595 411.122345,776.765381 
	C410.901215,776.012085 410.834564,775.563538 411.063110,774.918213 
	C412.579681,772.144348 411.194122,771.299500 408.953369,771.026550 
	C408.989838,771.006042 408.947632,771.078308 408.960266,770.733398 
	C408.684326,769.758545 408.395782,769.128601 408.107239,768.498718 
	C407.769226,769.061951 407.431213,769.625183 407.072449,770.145325 
	C407.051697,770.102234 407.146454,770.088928 406.766907,770.117554 
	C405.966736,771.468933 405.546082,772.791748 405.111115,774.059082 
	C405.096832,774.003723 405.177887,774.084473 404.819519,774.212952 
	C403.976562,776.233887 403.491974,778.126282 402.984924,780.279358 
	C402.884827,780.696167 402.807190,780.852295 402.389832,781.099243 
	C401.721069,782.515869 401.391998,783.841736 401.063843,785.387756 
	C401.064697,785.607971 400.907806,786.019531 400.754150,786.121216 
	C400.745026,786.484009 400.889557,786.745239 401.198608,787.159546 
	C401.580231,787.519165 401.793518,787.729431 402.277679,788.248413 
	C405.659058,788.920105 408.765747,789.286926 411.872437,789.653748 
	C411.824615,790.336609 411.776764,791.019531 411.728943,791.702454 
	C408.329254,791.702454 404.923889,791.596558 401.533936,791.778137 
	C400.703461,791.822571 399.923340,792.806946 399.120087,793.359192 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M413.746307,787.782593 
	C414.640747,789.010254 415.280884,790.640747 416.474579,791.394409 
	C421.143005,794.342041 421.535370,799.500916 423.201691,803.953918 
	C423.487305,804.717346 422.182983,806.075745 420.832397,808.643921 
	C427.449036,807.859314 433.229340,807.173950 439.187775,806.068970 
	C440.232056,806.409241 441.098175,807.169006 441.964294,807.928711 
	C440.969879,808.561096 439.990631,809.710815 438.978455,809.740601 
	C433.060394,809.914612 427.134186,809.871277 421.211731,809.806580 
	C418.572449,809.777771 416.812042,808.975098 419.275940,805.486450 
	C419.548615,802.987244 419.301971,800.936646 419.052856,798.605957 
	C418.794983,797.841919 418.539642,797.357971 418.168030,796.552734 
	C417.670349,796.171448 417.288910,796.111572 416.907471,796.051636 
	C416.907471,796.051575 416.997559,796.002441 417.209351,795.837646 
	C417.367188,795.466125 417.313293,795.259460 417.175232,794.726562 
	C416.711487,794.197937 416.331848,793.995667 415.833801,793.732422 
	C415.715424,793.671387 415.519287,793.851685 415.256287,793.634155 
	C414.157562,793.561829 413.321777,793.706909 412.076111,793.726013 
	C408.478699,793.657227 405.291168,793.714478 402.103668,793.771790 
	C401.316528,793.599548 400.529419,793.427246 399.431213,793.307068 
	C399.923340,792.806946 400.703461,791.822571 401.533936,791.778137 
	C404.923889,791.596558 408.329254,791.702454 411.728943,791.702454 
	C411.776764,791.019531 411.824615,790.336609 411.872437,789.653748 
	C408.765747,789.286926 405.659058,788.920105 402.735382,788.221741 
	C406.527710,787.854309 410.136993,787.818481 413.746307,787.782593 
z"/>
<path fill="#1F3281" opacity="1.000000" stroke="none" 
	d="
M444.591064,750.975952 
	C445.756226,750.312317 447.383087,749.070374 448.992798,749.092224 
	C459.604034,749.236450 470.226288,749.431763 480.810272,750.133301 
	C483.510193,750.312256 486.085114,752.376892 488.779846,753.941528 
	C474.574921,748.829163 459.684265,751.560425 444.591064,750.975952 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M439.241577,805.344482 
	C437.881165,804.466003 435.808746,803.535583 435.121002,801.989258 
	C433.143402,797.542847 431.725403,792.847473 430.129333,787.819824 
	C431.986786,791.081726 433.772064,794.789124 435.629333,798.460144 
	C436.749023,800.673096 437.980652,802.829468 439.241577,805.344482 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M398.581268,777.094482 
	C396.756592,783.145264 394.649475,789.420410 392.209930,795.828186 
	C394.017944,789.746826 396.158386,783.532776 398.581268,777.094482 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M403.668793,753.276611 
	C404.791382,757.557556 407.804504,761.846313 403.199402,765.949829 
	C403.751495,762.461914 405.564941,758.826660 401.707794,755.735352 
	C401.626740,755.670349 402.763794,754.085449 403.668793,753.276611 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M418.917877,758.626953 
	C418.115448,758.322205 417.093811,757.786255 416.521362,756.944763 
	C414.741516,754.328308 412.733612,753.144226 409.598328,754.816345 
	C409.059265,755.103760 407.951660,754.325195 407.052490,753.745972 
	C411.615326,753.446472 417.288330,750.822815 418.917877,758.626953 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M390.532745,798.626953 
	C390.088806,800.659241 389.319244,802.754944 388.164337,804.850220 
	C387.900421,803.787109 388.021790,802.724365 388.036041,801.269409 
	C387.928925,800.877197 387.981018,800.937500 388.301605,800.922668 
	C388.741302,800.250183 388.860413,799.592590 388.979523,798.934937 
	C388.979523,798.934937 388.987732,798.998718 388.956726,799.006592 
	C389.352844,798.906372 389.779968,798.798340 390.532745,798.626953 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M422.222626,766.685425 
	C421.179352,764.870056 420.115387,762.674866 419.086731,760.050903 
	C420.148651,761.849854 421.175262,764.077698 422.222626,766.685425 
z"/>
<path fill="#EFE4CD" opacity="1.000000" stroke="none" 
	d="
M419.055359,798.885986 
	C419.301971,800.936646 419.548615,802.987244 419.478546,805.173828 
	C418.917847,804.960327 418.673859,804.610962 418.149445,804.103760 
	C417.983246,803.505554 418.097504,803.065186 418.454956,802.041687 
	C418.817261,800.601013 418.936310,799.743530 419.055359,798.885986 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M426.300964,776.536621 
	C425.422729,775.365234 424.506500,773.846863 423.883606,772.120239 
	C424.872253,773.337830 425.567627,774.763672 426.300964,776.536621 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M400.508240,772.123413 
	C400.325317,773.538086 399.912231,775.199707 399.136627,776.851685 
	C399.275421,775.351440 399.776733,773.860962 400.508240,772.123413 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M428.841675,782.670410 
	C428.096954,781.755066 427.366058,780.513794 426.902130,779.095703 
	C427.731232,780.060791 428.293365,781.202637 428.841675,782.670410 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M423.395172,769.552246 
	C423.015320,769.346313 422.565247,768.784851 422.126343,767.823486 
	C422.533325,768.014587 422.929138,768.605652 423.395172,769.552246 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M430.147644,785.723999 
	C429.800934,785.515808 429.440369,784.976257 429.093323,784.090271 
	C429.449158,784.293457 429.791473,784.843079 430.147644,785.723999 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M391.675140,796.075684 
	C391.784241,796.483093 391.641266,797.065613 391.246826,797.825317 
	C391.137939,797.418518 391.280518,796.834656 391.675140,796.075684 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M402.567261,766.656494 
	C402.714294,767.141968 402.577240,767.853943 402.119446,768.755066 
	C401.960205,768.257141 402.121704,767.570068 402.567261,766.656494 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M401.669922,768.934448 
	C401.903046,769.143066 401.812744,769.418884 401.444336,769.797729 
	C401.157166,769.600952 401.217224,769.301208 401.669922,768.934448 
z"/>
<path fill="#F89B12" opacity="1.000000" stroke="none" 
	d="
M360.927979,753.349548 
	C361.416107,753.304138 361.904236,753.258667 362.989868,753.501404 
	C366.290955,755.119446 370.810486,753.610840 370.221619,759.662048 
	C369.737457,764.636963 370.984497,769.839783 371.854797,774.874084 
	C373.426086,783.963501 375.195435,793.022888 377.149048,802.036926 
	C377.393066,803.162720 379.256653,803.937439 380.369781,804.874878 
	C380.638428,804.875610 380.854431,804.982605 381.015259,805.753174 
	C380.775421,806.736023 380.552917,807.523071 380.298645,807.533508 
	C375.367157,807.735291 370.424927,808.020325 365.503815,807.820923 
	C364.582031,807.783569 363.741821,805.733521 362.863953,804.612305 
	C361.348358,794.533081 359.832764,784.453857 358.317139,774.374695 
	C357.743225,774.336609 357.169312,774.298523 356.595367,774.260498 
	C354.499725,780.065552 352.359283,785.854980 350.322845,791.680847 
	C348.733582,796.227295 347.475311,800.901672 345.654236,805.348938 
	C344.950684,807.067078 343.100586,808.315674 341.772888,809.778198 
	C340.242981,808.260193 338.054779,807.014099 337.296356,805.177490 
	C333.885773,796.918335 330.881195,788.491577 327.482727,779.692383 
	C327.235260,779.259521 327.043091,778.999451 327.035278,778.645386 
	C326.463623,777.013733 325.899750,775.736267 325.335907,774.458740 
	C325.008453,774.486267 324.681030,774.513855 324.353577,774.541382 
	C324.156677,776.045837 323.959747,777.550293 323.566711,779.416016 
	C323.299774,780.863647 323.229004,781.950012 323.049438,783.417969 
	C322.929474,784.854492 322.918274,785.909424 322.943878,786.986206 
	C322.980682,787.008118 322.931915,786.937805 322.692444,787.203125 
	C322.304840,788.645386 322.156708,789.822266 321.890625,791.327515 
	C321.614990,792.791687 321.457336,793.927490 321.174957,795.370361 
	C321.048889,796.116272 321.047546,796.555115 320.951996,797.343872 
	C320.856537,798.655884 320.855255,799.617920 320.661743,800.799500 
	C320.594879,801.531067 320.720215,802.043091 320.742981,802.791931 
	C320.745087,803.350159 320.849762,803.671692 321.038788,804.323242 
	C321.632050,804.565125 322.140991,804.476868 322.788300,804.351257 
	C322.926666,804.313965 323.015686,804.041504 323.015686,804.041504 
	C323.015686,804.041504 322.977051,804.047119 322.962738,804.035767 
	C322.740112,804.448730 322.531769,804.873047 322.679260,805.922607 
	C323.314880,806.867493 323.594727,807.187073 323.874573,807.506592 
	C323.419373,808.250183 322.964111,808.993835 321.805054,809.774414 
	C317.712677,809.807312 314.324188,809.803284 310.935669,809.799194 
	C310.935669,809.799194 310.770538,809.628479 310.653259,809.637207 
	C310.559326,808.607239 310.582642,807.568542 310.793945,806.254456 
	C312.265259,803.789001 314.311646,801.727112 314.704376,799.387573 
	C316.793823,786.941711 318.540253,774.435608 320.195404,761.922607 
	C320.398804,760.384705 319.395721,758.664673 318.859436,757.059875 
	C318.529083,756.071350 318.023651,755.141235 317.596863,754.184937 
	C318.635376,753.838196 319.671936,753.485168 320.714691,753.151489 
	C320.864990,753.103394 321.045258,753.143311 321.211304,753.150635 
	C335.703888,753.789062 332.005096,750.085999 337.269897,764.391968 
	C339.506866,770.470459 341.624725,776.593201 343.905304,782.654907 
	C344.333344,783.792786 345.275970,784.737000 346.657471,785.927002 
	C347.837463,784.526001 348.341064,782.968689 348.844696,781.411316 
	C348.844696,781.411316 348.672729,781.310120 349.024048,781.007629 
	C349.875183,778.758667 350.375000,776.812195 350.874817,774.865723 
	C350.874847,774.865723 350.912933,774.946045 351.188477,774.748657 
	C351.598206,773.570374 351.732391,772.589539 351.864807,771.313599 
	C352.010468,770.656860 352.157867,770.295105 352.470490,769.796936 
	C352.825378,769.428345 352.921265,769.162415 352.923401,768.862610 
	C352.923401,768.862610 352.953491,768.940735 353.261719,768.810059 
	C354.348083,766.080261 355.126251,763.481018 355.904449,760.881836 
	C355.904449,760.881836 355.922791,760.967590 356.209717,760.839905 
	C357.973785,758.257996 359.450897,755.803772 360.927979,753.349548 
z"/>
<path fill="#253267" opacity="1.000000" stroke="none" 
	d="
M356.970490,808.466309 
	C357.037201,806.444397 357.419403,804.040466 357.252533,801.675171 
	C357.127991,799.909119 356.294403,798.193054 355.776550,796.454773 
	C354.552216,797.367432 352.952789,798.042114 352.188507,799.246704 
	C350.938965,801.216064 350.233276,803.530518 348.917969,805.760742 
	C351.058746,798.165405 353.579742,790.508667 356.100739,782.851990 
	C356.417572,782.840637 356.734406,782.829285 357.051239,782.817932 
	C358.229187,789.154175 359.761108,795.456543 360.372986,801.847046 
	C360.560699,803.807617 358.228516,806.009521 356.970490,808.466309 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M362.546021,804.804932 
	C363.741821,805.733521 364.582031,807.783569 365.503815,807.820923 
	C370.424927,808.020325 375.367157,807.735291 380.298645,807.533508 
	C380.552917,807.523071 380.775421,806.736023 381.084320,805.926086 
	C383.127228,805.279358 385.098480,805.017090 387.456787,804.764038 
	C387.598419,805.794739 387.353058,806.816101 386.870483,808.824707 
	C390.102203,807.877502 392.866669,807.067261 395.973389,806.031677 
	C399.221863,807.828430 398.417755,809.604309 395.445038,809.674072 
	C384.536377,809.930176 373.617859,809.802246 362.703796,809.719910 
	C361.874542,809.713684 361.051086,808.932373 360.224915,808.511353 
	C360.892639,807.340149 361.560364,806.168823 362.546021,804.804932 
z"/>
<path fill="#EDE5D6" opacity="1.000000" stroke="none" 
	d="
M321.299683,795.063293 
	C321.457336,793.927490 321.614990,792.791687 322.164337,791.090454 
	C322.681305,789.329346 322.806610,788.133545 322.931915,786.937805 
	C322.931915,786.937805 322.980682,787.008118 323.222351,786.807495 
	C323.362122,785.416748 323.260193,784.226501 323.158264,783.036316 
	C323.229004,781.950012 323.299774,780.863647 323.889587,779.398865 
	C325.286804,779.013428 326.164948,779.006409 327.043091,778.999451 
	C327.043091,778.999451 327.235260,779.259521 327.234009,779.419983 
	C327.139618,780.510254 327.046509,781.440063 326.911377,782.833008 
	C326.284424,783.657654 325.242676,783.956665 325.183319,784.390198 
	C324.328156,790.635254 323.605072,796.898438 322.932983,803.599365 
	C323.015686,804.041504 322.926666,804.313965 322.370453,804.219360 
	C321.527649,804.080872 321.241028,804.037048 320.954437,803.993164 
	C320.849762,803.671692 320.745087,803.350159 320.763306,802.297607 
	C320.875458,801.237671 320.864716,800.908813 320.853943,800.580017 
	C320.855255,799.617920 320.856537,798.655884 321.162048,797.214417 
	C321.410767,796.177795 321.355225,795.620544 321.299683,795.063293 
z"/>
<path fill="#EDE5D6" opacity="1.000000" stroke="none" 
	d="
M402.000397,794.042236 
	C405.291168,793.714478 408.478699,793.657227 412.582520,793.763489 
	C414.172333,793.901917 414.845825,793.876770 415.519287,793.851685 
	C415.519287,793.851685 415.715424,793.671387 415.971191,794.016968 
	C416.571106,794.592590 416.915253,794.822693 417.259369,795.052795 
	C417.313293,795.259460 417.367188,795.466125 417.163391,795.863159 
	C411.924133,796.008301 406.942627,795.963196 401.499878,795.867249 
	C401.324829,795.315247 401.610962,794.814026 402.000397,794.042236 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M395.193054,803.579468 
	C394.554810,803.127319 393.275543,802.028931 393.477325,801.532166 
	C394.359619,799.359802 395.671448,797.361877 397.195496,795.195068 
	C396.786957,797.796326 396.010315,800.503296 395.193054,803.579468 
z"/>
<path fill="#223A9A" opacity="1.000000" stroke="none" 
	d="
M388.512604,812.241821 
	C385.211243,812.494202 381.462585,812.645203 377.384705,812.573792 
	C380.725403,812.281067 384.395355,812.210693 388.512604,812.241821 
z"/>
<path fill="#223A9A" opacity="1.000000" stroke="none" 
	d="
M393.737366,812.266724 
	C393.978058,812.353699 393.644043,812.676208 393.644043,812.676208 
	C393.644043,812.676208 393.195557,812.612732 393.104065,812.479492 
	C393.173920,812.290771 393.335266,812.235229 393.737366,812.266724 
z"/>
<path fill="#EDE5D6" opacity="1.000000" stroke="none" 
	d="
M419.052856,798.605957 
	C418.936310,799.743530 418.817261,800.601013 418.482788,801.701538 
	C417.703217,800.534729 417.139069,799.124939 416.991272,797.328003 
	C417.699921,796.918640 417.992126,796.896301 418.284302,796.874023 
	C418.539642,797.357971 418.794983,797.841919 419.052856,798.605957 
z"/>
<path fill="#EFE4CD" opacity="1.000000" stroke="none" 
	d="
M418.168030,796.552734 
	C417.992126,796.896301 417.699921,796.918640 416.966431,796.944214 
	C416.497467,796.759094 416.469757,796.570740 416.674744,796.217041 
	C417.288910,796.111572 417.670349,796.171448 418.168030,796.552734 
z"/>
<path fill="#1739BF" opacity="1.000000" stroke="none" 
	d="
M827.017944,769.459229 
	C827.644836,769.450623 828.825195,769.945740 828.799500,770.367249 
	C828.313416,778.333557 827.684875,786.291138 827.045837,794.641724 
	C826.559875,795.049316 826.114075,795.065735 825.248291,794.891602 
	C824.572571,794.842224 824.316833,794.983521 824.061035,795.124756 
	C823.220947,795.168945 822.380859,795.213196 821.270386,795.131714 
	C821.104370,794.217712 821.523926,793.285706 821.244141,792.672791 
	C820.738831,791.565796 819.815613,790.649536 819.040771,789.318970 
	C819.639160,789.173279 820.312622,789.268494 820.880371,789.565613 
	C825.576294,792.024048 826.943787,791.215027 826.994446,785.795532 
	C827.043884,780.507080 827.008118,775.217712 827.017944,769.459229 
z"/>
<path fill="#1637B6" opacity="1.000000" stroke="none" 
	d="
M824.295288,795.103760 
	C824.316833,794.983521 824.572571,794.842224 824.913879,794.882202 
	C824.999390,795.063477 824.529480,795.082703 824.295288,795.103760 
z"/>
<path fill="#12298B" opacity="1.000000" stroke="none" 
	d="
M240.619263,465.033752 
	C239.899277,467.621796 238.812347,470.257019 237.375946,472.955078 
	C238.101761,470.372223 239.177032,467.726593 240.619263,465.033752 
z"/>
<path fill="#FC9A0B" opacity="1.000000" stroke="none" 
	d="
M606.696411,313.309814 
	C596.447510,303.353973 586.465454,293.630402 576.483398,283.906830 
	C576.748962,283.242279 577.014587,282.577759 577.280151,281.913208 
	C580.752014,281.913208 584.231812,281.765869 587.693604,281.952576 
	C591.539368,282.160004 592.872009,280.771393 592.787964,276.747620 
	C592.523743,264.102020 592.793030,251.446091 592.622009,238.797333 
	C592.569824,234.938461 593.741211,233.530106 597.767761,233.689072 
	C605.580017,233.997498 613.416260,233.920212 621.235718,233.725510 
	C624.989563,233.632034 626.220093,235.173569 626.185242,238.738571 
	C626.067810,250.723648 626.117065,262.710388 626.108582,274.696503 
	C626.103455,281.998779 626.108337,281.992737 633.638306,281.915405 
	C635.926697,281.891907 638.215576,281.911774 641.784424,281.911774 
	C640.341248,283.915863 639.690308,285.124573 638.763367,286.059631 
	C629.865051,295.035492 620.985229,304.032532 611.893982,312.809998 
	C610.921387,313.749023 608.638367,313.330719 606.696411,313.309814 
z"/>
<path fill="#0F1E5E" opacity="1.000000" stroke="none" 
	d="
M629.253052,233.133286 
	C629.914246,234.643616 630.916626,236.157349 630.935364,237.683167 
	C631.073608,248.929489 630.927368,260.179596 631.102966,271.424896 
	C631.133606,273.386993 632.259521,275.331970 632.922119,277.674133 
	C631.767334,277.919403 630.570129,277.775330 629.268433,276.858154 
	C629.088684,261.733887 629.013489,247.382767 628.938354,233.031631 
	C628.938293,233.031631 629.138855,233.142273 629.253052,233.133286 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M628.643677,233.322937 
	C629.013489,247.382767 629.088684,261.733887 629.056702,276.544189 
	C628.645813,276.437744 628.075195,275.871063 628.077393,275.306641 
	C628.131104,261.409058 628.245483,247.511673 628.643677,233.322937 
z"/>
<path fill="#FC9A0B" opacity="1.000000" stroke="none" 
	d="
M550.977417,288.961426 
	C541.395508,299.032501 527.870239,301.755737 514.946411,294.122406 
	C504.268433,287.815582 499.173553,274.009644 503.197601,261.578247 
	C507.268585,249.001907 519.660828,240.982971 532.427551,242.280472 
	C555.479797,244.623260 566.164429,271.242523 550.977417,288.961426 
z"/>
<path fill="#EFE4CD" opacity="1.000000" stroke="none" 
	d="
M284.226135,810.172424 
	C290.995392,809.845947 298.043549,809.846252 305.563812,809.872070 
	C298.858978,810.098083 291.682007,810.298645 284.226135,810.172424 
z"/>
<path fill="#EFE4CD" opacity="1.000000" stroke="none" 
	d="
M311.199280,810.128967 
	C314.324188,809.803284 317.712677,809.807312 321.556183,809.884277 
	C318.495087,810.124329 314.978973,810.291504 311.199280,810.128967 
z"/>
<path fill="#223A9A" opacity="1.000000" stroke="none" 
	d="
M296.523560,812.244995 
	C294.521545,812.496826 292.091492,812.643433 289.359161,812.575317 
	C291.403107,812.286926 293.749268,812.213379 296.523560,812.244995 
z"/>
<path fill="#223A9A" opacity="1.000000" stroke="none" 
	d="
M306.554993,812.238770 
	C304.868011,812.475525 302.752838,812.629395 300.349426,812.567383 
	C302.083099,812.286316 304.104950,812.221069 306.554993,812.238770 
z"/>
<path fill="#223A9A" opacity="1.000000" stroke="none" 
	d="
M316.549194,812.252197 
	C315.174164,812.500549 313.387360,812.643127 311.329590,812.571289 
	C312.751587,812.286682 314.444519,812.216492 316.549194,812.252197 
z"/>
<path fill="#EFE4CD" opacity="1.000000" stroke="none" 
	d="
M323.821167,807.175415 
	C323.594727,807.187073 323.314880,806.867493 322.929688,806.243408 
	C323.138794,806.240601 323.453278,806.542358 323.821167,807.175415 
z"/>
<path fill="#FC9A0B" opacity="1.000000" stroke="none" 
	d="
M637.952576,237.005493 
	C637.936035,232.843811 638.221069,229.140747 637.741821,225.539413 
	C637.567322,224.228195 635.683594,222.313202 634.385986,222.142120 
	C630.820068,221.671982 627.151550,221.979858 623.523621,221.979858 
	C623.207275,221.507767 622.890930,221.035690 622.574646,220.563599 
	C633.166260,209.923615 643.757935,199.283630 654.082336,188.912170 
	C665.003906,199.913330 675.517090,210.503174 686.872253,221.941071 
	C683.648560,221.941071 681.098450,221.929688 678.548401,221.943283 
	C671.875183,221.978882 671.846924,221.983170 671.832520,228.501282 
	C671.805969,240.488800 671.668335,252.479111 671.888428,264.462555 
	C671.969788,268.890320 670.447327,270.237640 666.063843,270.040710 
	C658.587769,269.704895 651.077209,269.801117 643.594604,270.078674 
	C639.351746,270.236053 637.641479,269.027283 637.814697,264.472443 
	C638.156067,255.494324 637.938232,246.494965 637.952576,237.005493 
z"/>
<path fill="#1430A3" opacity="1.000000" stroke="none" 
	d="
M438.047180,791.351685 
	C439.248322,791.361145 440.480743,791.735779 442.164032,792.247437 
	C442.655090,791.016296 443.423859,789.088684 444.556030,787.074341 
	C444.938202,788.961914 445.119171,790.952942 444.919403,792.904907 
	C444.783813,794.230103 444.114014,795.500488 443.683136,796.795410 
	C442.204041,796.004944 440.600220,795.374390 439.297119,794.358215 
	C438.617523,793.828247 438.467590,792.619141 438.047180,791.351685 
z"/>
<path fill="#1430A3" opacity="1.000000" stroke="none" 
	d="
M444.871490,763.698669 
	C444.277222,763.534912 443.680725,763.051270 443.067535,762.245361 
	C443.656982,762.408325 444.263153,762.893555 444.871490,763.698669 
z"/>
<path fill="#F5E1BC" opacity="1.000000" stroke="none" 
	d="
M576.917114,804.254761 
	C576.981995,804.502197 576.765076,804.804138 576.260376,805.160767 
	C576.193481,804.913391 576.414368,804.611267 576.917114,804.254761 
z"/>
<path fill="#F5E1BC" opacity="1.000000" stroke="none" 
	d="
M577.059570,803.319702 
	C577.212585,803.033569 577.415344,803.057495 577.913696,803.169189 
	C578.219849,803.612549 578.075745,803.886841 577.548096,804.150818 
	C577.249207,804.024353 577.179260,803.827026 577.059570,803.319702 
z"/>
<path fill="#1E338B" opacity="1.000000" stroke="none" 
	d="
M386.695770,793.888062 
	C386.407196,795.380920 385.808868,796.731201 385.210571,798.081421 
	C384.693909,798.098450 384.177216,798.115417 383.660553,798.132385 
	C383.100647,796.358215 382.540710,794.584045 381.987976,792.419189 
	C383.458740,792.600830 384.922394,793.173096 386.695770,793.888062 
z"/>
<path fill="#FB9A0C" opacity="1.000000" stroke="none" 
	d="
M528.182312,167.365387 
	C505.683411,168.224243 485.677704,174.960022 467.963318,188.198608 
	C465.171906,190.284698 462.502441,192.569687 459.995056,194.989105 
	C457.893524,197.016922 456.238190,197.231766 454.139893,194.994308 
	C450.385193,190.990555 446.502228,187.102814 442.574432,183.267395 
	C440.557190,181.297562 440.669403,179.652542 442.700562,177.822601 
	C459.423981,162.755768 478.469421,152.140305 500.478241,146.817795 
	C517.736816,142.644043 535.112610,142.044662 552.428406,145.232803 
	C573.164429,149.050659 592.099121,157.370834 608.593872,170.773682 
	C611.430115,173.078323 614.239563,175.420486 616.976746,177.841049 
	C619.072876,179.694763 619.336975,181.539062 617.025696,183.674011 
	C613.248169,187.163467 609.543213,190.755966 606.081604,194.554657 
	C603.714844,197.151886 601.884888,196.956131 599.480164,194.692108 
	C587.513428,183.425476 573.577698,175.330688 557.677612,171.425400 
	C548.234558,169.106018 538.333130,168.652298 528.182312,167.365387 
z"/>
<path fill="#F5E1BC" opacity="1.000000" stroke="none" 
	d="
M210.375458,771.571594 
	C212.079208,773.741089 213.705338,776.059082 214.901199,778.581116 
	C216.091660,781.091675 216.850082,783.807129 218.084930,787.227661 
	C213.534042,787.227661 209.470001,787.227661 204.280243,787.227661 
	C206.322174,781.826599 208.215729,776.818054 210.375458,771.571594 
M211.544479,785.100342 
	C212.351913,785.024902 213.159348,784.949402 214.473770,784.826538 
	C213.412308,782.075439 212.464493,779.618896 211.046661,775.944153 
	C209.724060,779.792236 208.886215,782.229980 207.979080,784.869263 
	C209.067795,784.963806 209.880890,785.034424 211.544479,785.100342 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M350.632019,775.026611 
	C350.375000,776.812195 349.875183,778.758667 349.042358,780.909546 
	C348.844208,780.052795 348.979034,778.991638 349.144775,777.533081 
	C349.580200,776.486267 349.984741,775.836853 350.632019,775.026611 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M348.547150,781.631226 
	C348.341064,782.968689 347.837463,784.526001 346.992065,785.957886 
	C347.183411,784.505310 347.716492,783.178162 348.547150,781.631226 
z"/>
<path fill="#1F3281" opacity="1.000000" stroke="none" 
	d="
M308.659363,761.035339 
	C308.600708,762.653198 308.213501,764.262817 307.474731,765.970215 
	C307.089447,764.663696 307.055725,763.259460 307.019287,761.436035 
	C307.454651,761.020264 307.892731,761.023743 308.659363,761.035339 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M380.231049,804.508667 
	C379.256653,803.937439 377.393066,803.162720 377.149048,802.036926 
	C375.195435,793.022888 373.426086,783.963501 371.854797,774.874084 
	C370.984497,769.839783 369.737457,764.636963 370.221619,759.662048 
	C370.810486,753.610840 366.290955,755.119446 363.350342,753.582825 
	C366.660309,753.376099 370.207306,753.376099 374.682129,753.376099 
	C367.664856,763.077209 373.722168,771.716675 374.923981,780.440552 
	C376.024170,788.426941 378.322144,796.248291 380.231049,804.508667 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M360.575317,753.283813 
	C359.450897,755.803772 357.973785,758.257996 356.167511,760.828064 
	C357.299805,758.368652 358.761230,755.793396 360.575317,753.283813 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M355.616699,761.096375 
	C355.126251,763.481018 354.348083,766.080261 353.220093,768.814453 
	C353.689789,766.403259 354.509338,763.857056 355.616699,761.096375 
z"/>
<path fill="#EFE4CD" opacity="1.000000" stroke="none" 
	d="
M352.305267,769.933411 
	C352.157867,770.295105 352.010468,770.656860 351.655518,771.411865 
	C351.447937,771.805237 351.054688,772.046570 351.054688,772.046570 
	C351.054688,772.046570 350.969818,771.969727 350.926453,771.930786 
	C351.103882,771.056030 351.324677,770.220154 351.862305,769.438416 
	C352.179108,769.492554 352.305267,769.933411 352.305267,769.933411 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M352.470490,769.796936 
	C352.305267,769.933411 352.179108,769.492554 352.142944,769.266113 
	C352.106750,769.039673 352.505768,768.907166 352.714600,768.884888 
	C352.921265,769.162415 352.825378,769.428345 352.470490,769.796936 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M351.045898,772.411743 
	C351.054688,772.046570 351.447937,771.805237 351.657257,771.706970 
	C351.732391,772.589539 351.598206,773.570374 351.144165,774.752197 
	C350.895233,774.227722 350.966156,773.502380 351.045898,772.411743 
z"/>
<path fill="#EDE5D6" opacity="1.000000" stroke="none" 
	d="
M388.587433,798.966919 
	C388.860413,799.592590 388.741302,800.250183 388.267029,800.942383 
	C388.006378,800.317627 388.100861,799.658325 388.587433,798.966919 
z"/>
<path fill="#EFE4CD" opacity="1.000000" stroke="none" 
	d="
M871.243103,803.992676 
	C870.280579,803.173157 869.301636,802.432922 869.285339,801.671936 
	C869.157654,795.714478 869.214600,789.753052 869.214600,783.633850 
	C874.059570,782.132324 878.249512,782.660950 881.446167,786.074219 
	C884.681946,789.529175 884.975403,793.885010 883.195740,798.216309 
	C881.067200,803.396545 876.642090,804.333435 871.243103,803.992676 
M877.667175,786.346802 
	C876.071777,786.132446 874.476379,785.918030 872.650208,785.672607 
	C872.650208,791.247253 872.650208,796.354858 872.650208,801.811951 
	C876.679138,801.312805 879.992432,800.878967 881.345093,796.748718 
	C882.682678,792.664368 881.902710,789.320190 877.667175,786.346802 
z"/>
<path fill="#F5E1BC" opacity="1.000000" stroke="none" 
	d="
M876.124878,759.350342 
	C881.179871,763.584045 882.298889,768.785095 879.675842,773.559326 
	C877.741516,777.080200 874.441528,777.875366 871.230652,776.837097 
	C870.059875,776.458618 869.595154,773.192200 869.249939,771.168030 
	C868.920593,769.236633 869.166016,767.207214 869.158691,765.220886 
	C869.134338,758.647339 869.134338,758.647339 876.124878,759.350342 
M872.177551,772.841187 
	C872.922302,776.477539 875.568115,775.020020 876.276550,773.620667 
	C877.500977,771.202393 878.398376,768.112732 877.981506,765.539917 
	C877.683777,763.701843 875.012085,762.248474 873.399780,760.623413 
	C872.993958,760.961243 872.588196,761.299072 872.182434,761.636963 
	C872.182434,765.086609 872.182434,768.536316 872.177551,772.841187 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M754.967041,780.688477 
	C748.643616,775.855896 747.372498,769.315735 751.391846,760.900879 
	C750.180603,767.713867 750.628418,774.337708 754.967041,780.688477 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M773.450195,805.041504 
	C773.700500,802.637207 774.326050,800.223938 774.967163,797.404663 
	C778.069580,800.667908 777.818054,802.414062 773.450195,805.041504 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M766.976196,789.593750 
	C765.685852,789.224243 764.352600,788.484192 762.815186,787.473328 
	C764.051758,787.876038 765.492554,788.549561 766.976196,789.593750 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M757.982422,783.640015 
	C757.051453,783.205444 756.096375,782.426086 755.036743,781.350281 
	C755.940918,781.800964 756.949646,782.548157 757.982422,783.640015 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M760.931274,785.755249 
	C760.056702,785.599670 759.159302,785.087769 758.138672,784.284302 
	C758.979736,784.461487 759.944092,784.930176 760.931274,785.755249 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M752.035156,760.784241 
	C751.901611,760.204224 752.093933,759.688965 752.537598,759.039429 
	C752.646301,759.553040 752.503662,760.201050 752.035156,760.784241 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M770.015503,791.696411 
	C769.647522,791.994812 769.356506,791.874146 769.050659,791.339478 
	C769.311951,791.226318 769.664185,791.343567 770.015503,791.696411 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M772.091431,805.833374 
	C772.059326,805.610596 772.283264,805.360596 772.769165,805.091919 
	C772.803223,805.317627 772.575378,805.561829 772.091431,805.833374 
z"/>
<path fill="#F29E22" opacity="1.000000" stroke="none" 
	d="
M772.821655,793.960571 
	C772.584045,794.046387 772.271179,793.841614 771.846252,793.373291 
	C772.071594,793.296448 772.408936,793.483215 772.821655,793.960571 
z"/>
<path fill="#F5E1BC" opacity="1.000000" stroke="none" 
	d="
M695.352173,771.503723 
	C698.238159,776.253784 700.836243,781.218811 703.999939,787.264771 
	C698.494690,787.264771 694.384644,787.264771 689.260437,787.264771 
	C691.299500,781.803101 693.181946,776.760925 695.352173,771.503723 
M699.092651,784.704163 
	C698.325562,782.197021 697.558472,779.689819 696.791382,777.182678 
	C696.440369,777.218384 696.089294,777.254089 695.738220,777.289795 
	C694.860901,779.713257 693.983582,782.136780 692.922302,785.068542 
	C695.071716,785.068542 696.702209,785.068542 699.092651,784.704163 
z"/>
<path fill="#F5E1BC" opacity="1.000000" stroke="none" 
	d="
M541.298218,763.024658 
	C540.282410,759.886841 541.618103,758.821472 544.692993,758.814697 
	C550.127930,758.802734 555.909607,762.276917 557.969971,767.629883 
	C561.365112,776.450500 561.387573,785.481995 558.220825,794.358765 
	C556.139648,800.192749 551.600708,803.449951 545.469727,803.939575 
	C544.069519,804.051331 542.533875,802.466858 541.527344,801.193359 
	C542.514343,798.518188 543.034607,796.312500 543.663574,794.514221 
	C543.772278,797.035767 543.772278,799.149963 543.772278,801.408142 
	C548.719971,801.580322 552.236328,799.860291 554.355469,796.174133 
	C559.728149,786.829102 559.399963,777.061279 555.187317,767.493591 
	C553.253845,763.102356 549.113403,761.270386 543.573181,761.307434 
	C543.573181,771.312317 543.573181,781.091553 543.366821,790.526550 
	C543.160522,780.780029 543.160522,771.377808 543.160522,761.076782 
	C541.977600,762.314087 541.637878,762.669373 541.298218,763.024658 
z"/>
<path fill="#FEFEFF" opacity="1.000000" stroke="none" 
	d="
M543.554932,794.106873 
	C543.034607,796.312500 542.514343,798.518188 541.660156,800.801514 
	C541.278076,788.570679 541.229919,776.262146 541.239990,763.489136 
	C541.637878,762.669373 541.977600,762.314087 543.160522,761.076782 
	C543.160522,771.377808 543.160522,780.780029 543.441895,790.929688 
	C543.667114,792.487061 543.611023,793.296936 543.554932,794.106873 
z"/>
<path fill="#E9E1D0" opacity="1.000000" stroke="none" 
	d="
M662.722046,759.234436 
	C663.025269,759.690735 663.016296,760.066772 663.008057,760.725830 
	C662.640930,761.020630 662.273071,761.032593 661.629944,761.045105 
	C661.706360,760.415222 662.058105,759.784729 662.722046,759.234436 
z"/>
<path fill="#E9E1D0" opacity="1.000000" stroke="none" 
	d="
M661.283325,800.272583 
	C661.691162,800.218445 661.773193,800.468445 661.499512,800.880249 
	C661.315552,800.815857 661.253540,800.660767 661.283325,800.272583 
z"/>
<path fill="#E9E1D0" opacity="1.000000" stroke="none" 
	d="
M669.125732,760.489563 
	C668.902161,760.620056 668.582947,760.503418 668.168945,760.137451 
	C668.392822,760.006165 668.711487,760.124329 669.125732,760.489563 
z"/>
<path fill="#F5E1BC" opacity="1.000000" stroke="none" 
	d="
M593.569336,787.748596 
	C592.801086,786.704651 591.940735,785.551575 592.201294,784.774170 
	C593.817139,779.952942 595.684814,775.216064 597.992371,769.053162 
	C600.678528,775.824524 602.827515,781.241760 605.434387,787.813110 
	C601.062195,787.813110 597.528870,787.813110 593.569336,787.748596 
M595.556030,783.115662 
	C597.211975,784.006042 598.867981,784.896362 600.523987,785.786682 
	C600.867310,785.355713 601.210632,784.924683 601.553955,784.493652 
	C600.573853,782.057617 599.593811,779.621582 598.180908,776.109741 
	C597.111206,779.044678 596.500916,780.719116 595.556030,783.115662 
z"/>
<path fill="#FB9A0C" opacity="1.000000" stroke="none" 
	d="
M535.794800,190.982468 
	C554.122803,192.417450 569.567261,199.361237 583.037292,211.080231 
	C585.785278,213.471008 585.805786,215.086456 583.207031,217.454865 
	C579.651855,220.695023 576.166992,224.047180 572.970276,227.634964 
	C570.344482,230.582047 568.331848,230.274689 565.451233,227.897873 
	C550.367615,215.452515 533.055969,211.788635 514.480347,217.238510 
	C507.199890,219.374512 500.530304,223.987930 494.009857,228.143555 
	C490.991180,230.067383 489.259308,230.347336 486.863373,227.734161 
	C483.831573,224.427490 480.699951,221.181122 477.341492,218.214294 
	C474.215027,215.452393 474.280121,213.242676 477.384125,210.557632 
	C488.572327,200.879532 501.359711,194.415649 515.944031,192.051941 
	C522.295410,191.022568 528.856567,191.287842 535.794800,190.982468 
z"/>
<path fill="#EFE4CD" opacity="1.000000" stroke="none" 
	d="
M485.073059,783.773438 
	C483.722504,788.630676 482.841797,793.253052 480.859741,797.341492 
	C479.746826,799.637207 477.068665,801.567322 474.650879,802.761902 
	C467.980804,806.057495 465.334534,804.289551 465.337921,797.044495 
	C465.343262,785.595581 465.427521,774.146057 465.330109,762.698059 
	C465.305603,759.817871 466.482605,758.976257 469.186157,758.921387 
	C476.050446,758.781921 481.561890,763.026733 483.584869,771.019348 
	C484.589874,774.989929 484.614288,779.208679 485.073059,783.773438 
M481.976227,773.752869 
	C479.712860,764.680298 476.078308,761.381165 468.467865,761.507874 
	C468.467865,774.816711 468.467865,788.125549 468.467865,801.470215 
	C475.867493,800.714478 479.836823,797.980042 481.128387,791.282471 
	C482.174744,785.856506 481.824005,780.161194 481.976227,773.752869 
z"/>
<path fill="#F5E1BC" opacity="1.000000" stroke="none" 
	d="
M413.768555,787.798645 
	C410.136993,787.818481 406.527710,787.854309 402.460693,787.916870 
	C401.793518,787.729431 401.580231,787.519165 401.192871,786.909546 
	C400.984344,786.344055 400.946075,786.181824 400.907806,786.019531 
	C400.907806,786.019531 401.064697,785.607971 401.325928,785.179321 
	C401.967957,783.503235 402.348755,782.255859 402.729553,781.008484 
	C402.807190,780.852295 402.884827,780.696167 403.247986,780.044922 
	C404.081604,777.728088 404.629730,775.906311 405.177856,774.084473 
	C405.177887,774.084473 405.096832,774.003723 405.388397,773.879761 
	C406.168793,772.533508 406.657654,771.311218 407.146484,770.088928 
	C407.146454,770.088928 407.051697,770.102234 407.308838,770.242432 
	C408.026489,770.614502 408.487061,770.846436 408.947632,771.078308 
	C408.947632,771.078308 408.989838,771.006042 408.971619,771.355103 
	C409.558258,772.841125 410.163086,773.978027 410.767944,775.114990 
	C410.834564,775.563538 410.901215,776.012085 411.120300,776.994751 
	C411.354889,777.871277 411.570496,778.066711 411.919586,778.115112 
	C411.919586,778.115112 411.941498,778.033691 411.887451,778.384644 
	C412.196930,779.551147 412.560486,780.366699 412.924042,781.182312 
	C412.924042,781.182312 412.947479,781.623291 413.056641,782.191162 
	C413.385101,784.458862 413.604401,786.158691 413.823730,787.858521 
	C413.823730,787.858521 413.790771,787.814697 413.768555,787.798645 
M408.394348,777.372681 
	C407.084351,779.687866 405.774353,782.003052 404.189636,784.803833 
	C407.161835,784.803833 409.016052,784.803833 411.221100,784.803833 
	C410.317627,782.273010 409.554626,780.135742 408.394348,777.372681 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M414.212616,787.896606 
	C413.604401,786.158691 413.385101,784.458862 413.167297,782.382202 
	C417.906799,785.184631 418.060791,785.821899 414.212616,787.896606 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M411.063110,774.918213 
	C410.163086,773.978027 409.558258,772.841125 408.935150,771.375610 
	C411.194122,771.299500 412.579681,772.144348 411.063110,774.918213 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M404.819519,774.212952 
	C404.629730,775.906311 404.081604,777.728088 403.270447,779.784302 
	C403.491974,778.126282 403.976562,776.233887 404.819519,774.212952 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M402.389832,781.099243 
	C402.348755,782.255859 401.967957,783.503235 401.325073,784.959106 
	C401.391998,783.841736 401.721069,782.515869 402.389832,781.099243 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M406.766937,770.117554 
	C406.657654,771.311218 406.168793,772.533508 405.402679,773.935120 
	C405.546082,772.791748 405.966736,771.468933 406.766937,770.117554 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M408.960266,770.733398 
	C408.487061,770.846436 408.026489,770.614502 407.329590,770.285522 
	C407.431213,769.625183 407.769226,769.061951 408.107239,768.498718 
	C408.395782,769.128601 408.684326,769.758545 408.960266,770.733398 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M412.946411,780.819397 
	C412.560486,780.366699 412.196930,779.551147 411.845825,778.391235 
	C412.228394,778.850098 412.598602,779.653259 412.946411,780.819397 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M411.877625,777.904785 
	C411.570496,778.066711 411.354889,777.871277 411.274780,777.299500 
	C411.539764,777.209595 411.726044,777.417664 411.877625,777.904785 
z"/>
<path fill="#F79B13" opacity="1.000000" stroke="none" 
	d="
M400.754150,786.121216 
	C400.946075,786.181824 400.984344,786.344055 401.028381,786.756348 
	C400.889557,786.745239 400.745026,786.484009 400.754150,786.121216 
z"/>
<path fill="#EFE4CD" opacity="1.000000" stroke="none" 
	d="
M415.256287,793.634155 
	C414.845825,793.876770 414.172333,793.901917 412.992432,793.889526 
	C413.321777,793.706909 414.157562,793.561829 415.256287,793.634155 
z"/>
<path fill="#EFE4CD" opacity="1.000000" stroke="none" 
	d="
M417.175232,794.726562 
	C416.915253,794.822693 416.571106,794.592590 416.089600,794.077942 
	C416.331848,793.995667 416.711487,794.197937 417.175232,794.726562 
z"/>
<path fill="#EFE4CD" opacity="1.000000" stroke="none" 
	d="
M327.035278,778.645386 
	C326.164948,779.006409 325.286804,779.013428 324.085754,779.037598 
	C323.959747,777.550293 324.156677,776.045837 324.353577,774.541382 
	C324.681030,774.513855 325.008453,774.486267 325.335907,774.458740 
	C325.899750,775.736267 326.463623,777.013733 327.035278,778.645386 
z"/>
<path fill="#EFE4CD" opacity="1.000000" stroke="none" 
	d="
M322.692444,787.203125 
	C322.806610,788.133545 322.681305,789.329346 322.282318,790.762085 
	C322.156708,789.822266 322.304840,788.645386 322.692444,787.203125 
z"/>
<path fill="#EFE4CD" opacity="1.000000" stroke="none" 
	d="
M323.049469,783.417969 
	C323.260193,784.226501 323.362122,785.416748 323.185547,786.785645 
	C322.918274,785.909424 322.929474,784.854492 323.049469,783.417969 
z"/>
<path fill="#EFE4CD" opacity="1.000000" stroke="none" 
	d="
M321.174957,795.370361 
	C321.355225,795.620544 321.410767,796.177795 321.256226,796.864502 
	C321.047546,796.555115 321.048889,796.116272 321.174957,795.370361 
z"/>
<path fill="#EFE4CD" opacity="1.000000" stroke="none" 
	d="
M321.038788,804.323242 
	C321.241028,804.037048 321.527649,804.080872 322.232086,804.256653 
	C322.140991,804.476868 321.632050,804.565125 321.038788,804.323242 
z"/>
<path fill="#EFE4CD" opacity="1.000000" stroke="none" 
	d="
M320.661743,800.799500 
	C320.864716,800.908813 320.875458,801.237671 320.865906,802.060791 
	C320.720215,802.043091 320.594879,801.531067 320.661743,800.799500 
z"/>
<path fill="#1D2A5E" opacity="1.000000" stroke="none" 
	d="
M211.119232,785.102661 
	C209.880890,785.034424 209.067795,784.963806 207.979080,784.869263 
	C208.886215,782.229980 209.724060,779.792236 211.046661,775.944153 
	C212.464493,779.618896 213.412308,782.075439 214.473770,784.826538 
	C213.159348,784.949402 212.351913,785.024902 211.119232,785.102661 
z"/>
<path fill="#1F2F70" opacity="1.000000" stroke="none" 
	d="
M878.037292,786.528137 
	C881.902710,789.320190 882.682678,792.664368 881.345093,796.748718 
	C879.992432,800.878967 876.679138,801.312805 872.650208,801.811951 
	C872.650208,796.354858 872.650208,791.247253 872.650208,785.672607 
	C874.476379,785.918030 876.071777,786.132446 878.037292,786.528137 
z"/>
<path fill="#1D2A5E" opacity="1.000000" stroke="none" 
	d="
M872.179993,772.413574 
	C872.182434,768.536316 872.182434,765.086609 872.182434,761.636963 
	C872.588196,761.299072 872.993958,760.961243 873.399780,760.623413 
	C875.012085,762.248474 877.683777,763.701843 877.981506,765.539917 
	C878.398376,768.112732 877.500977,771.202393 876.276550,773.620667 
	C875.568115,775.020020 872.922302,776.477539 872.179993,772.413574 
z"/>
<path fill="#1D2A5E" opacity="1.000000" stroke="none" 
	d="
M698.712646,784.886353 
	C696.702209,785.068542 695.071716,785.068542 692.922302,785.068542 
	C693.983582,782.136780 694.860901,779.713257 695.738220,777.289795 
	C696.089294,777.254089 696.440369,777.218384 696.791382,777.182678 
	C697.558472,779.689819 698.325562,782.197021 698.712646,784.886353 
z"/>
<path fill="#1F2F70" opacity="1.000000" stroke="none" 
	d="
M543.663574,794.514221 
	C543.611023,793.296936 543.667114,792.487061 543.648193,791.273987 
	C543.573181,781.091553 543.573181,771.312317 543.573181,761.307434 
	C549.113403,761.270386 553.253845,763.102356 555.187317,767.493591 
	C559.399963,777.061279 559.728149,786.829102 554.355469,796.174133 
	C552.236328,799.860291 548.719971,801.580322 543.772278,801.408142 
	C543.772278,799.149963 543.772278,797.035767 543.663574,794.514221 
M555.629089,774.460327 
	C554.816162,773.020630 554.123352,771.493591 553.157471,770.165161 
	C552.131714,768.754395 550.846863,767.532043 549.675110,766.227356 
	C548.126831,767.945129 545.319092,769.617310 545.255066,771.388733 
	C545.017090,777.966736 545.445007,784.604004 546.180481,791.156433 
	C546.356934,792.729248 548.475037,794.499573 550.125183,795.276306 
	C550.740662,795.565979 553.449463,793.440735 553.693604,792.134338 
	C554.733398,786.569946 555.207825,780.899841 555.629089,774.460327 
z"/>
<path fill="#1D2A5E" opacity="1.000000" stroke="none" 
	d="
M595.723328,782.754639 
	C596.500916,780.719116 597.111206,779.044678 598.180908,776.109741 
	C599.593811,779.621582 600.573853,782.057617 601.553955,784.493652 
	C601.210632,784.924683 600.867310,785.355713 600.523987,785.786682 
	C598.867981,784.896362 597.211975,784.006042 595.723328,782.754639 
z"/>
<path fill="#1F3281" opacity="1.000000" stroke="none" 
	d="
M482.034607,774.169067 
	C481.824005,780.161194 482.174744,785.856506 481.128387,791.282471 
	C479.836823,797.980042 475.867493,800.714478 468.467865,801.470215 
	C468.467865,788.125549 468.467865,774.816711 468.474548,761.505615 
	C476.078308,761.381165 479.712860,764.680298 482.034607,774.169067 
M478.614777,790.694031 
	C480.220520,782.719177 481.279572,774.800415 475.912476,766.557861 
	C473.769379,768.475708 470.525787,769.977417 470.318085,771.821167 
	C469.636597,777.869934 469.702972,784.075989 470.269379,790.148193 
	C470.441559,791.994263 473.162506,793.602600 474.716156,795.319824 
	C475.910004,794.021790 477.103851,792.723816 478.614777,790.694031 
z"/>
<path fill="#1D2A5E" opacity="1.000000" stroke="none" 
	d="
M408.592987,777.685547 
	C409.554626,780.135742 410.317627,782.273010 411.221100,784.803833 
	C409.016052,784.803833 407.161835,784.803833 404.189636,784.803833 
	C405.774353,782.003052 407.084351,779.687866 408.592987,777.685547 
z"/>
<path fill="#1430A3" opacity="1.000000" stroke="none" 
	d="
M555.758789,774.864319 
	C555.207825,780.899841 554.733398,786.569946 553.693604,792.134338 
	C553.449463,793.440735 550.740662,795.565979 550.125183,795.276306 
	C548.475037,794.499573 546.356934,792.729248 546.180481,791.156433 
	C545.445007,784.604004 545.017090,777.966736 545.255066,771.388733 
	C545.319092,769.617310 548.126831,767.945129 549.675171,766.227417 
	C550.846863,767.532043 552.131714,768.754395 553.157471,770.165161 
	C554.123352,771.493591 554.816162,773.020630 555.758789,774.864319 
M547.998352,782.278442 
	C547.998352,785.058411 547.998352,787.838440 547.998352,791.207214 
	C553.901917,787.407837 555.878235,778.717468 552.690247,773.748962 
	C552.224060,773.022339 551.015015,772.098755 550.444153,772.266785 
	C549.532532,772.535156 548.328735,773.562683 548.203125,774.410767 
	C547.865417,776.690674 548.032959,779.045410 547.998352,782.278442 
z"/>
<path fill="#1433AA" opacity="1.000000" stroke="none" 
	d="
M478.456238,791.059937 
	C477.103851,792.723816 475.910004,794.021790 474.716125,795.319824 
	C473.162506,793.602600 470.441559,791.994263 470.269379,790.148193 
	C469.702972,784.075989 469.636597,777.869934 470.318085,771.821167 
	C470.525787,769.977417 473.769379,768.475708 475.912476,766.557861 
	C481.279572,774.800415 480.220520,782.719177 478.456238,791.059937 
z"/>
<path fill="#1839BC" opacity="1.000000" stroke="none" 
	d="
M547.999146,781.824463 
	C548.032959,779.045410 547.865417,776.690674 548.203125,774.410767 
	C548.328735,773.562683 549.532532,772.535156 550.444153,772.266785 
	C551.015015,772.098755 552.224060,773.022339 552.690247,773.748962 
	C555.878235,778.717468 553.901917,787.407837 547.998352,791.207214 
	C547.998352,787.838440 547.998352,785.058411 547.999146,781.824463 
z"/>

	

</g>
</svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
